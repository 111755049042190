import React, { Fragment } from 'react';

const USER_GUIDE_Snippets = () => (
  <Fragment>
    <h1 id="snippets-in-avap™-dev-studio">Snippets in AVAP™ Dev Studio</h1>
    <p>
      Code snippets are templates that make it easier to enter repeating code
      patterns, such as loops or conditional-statements.
    </p>
    <p>
      In AVAP<sup>TM</sup> Dev Studio, snippets appear in IntelliSense
      &#40;Ctrl+Space&#41; mixed with other suggestions, as well as in a
      dedicated snippet picker &#40;<strong>Insert Snippet</strong> in the
      Command Palette&#41;. There is also support for tab-completion: Enable it
      with <code>&quot;editor.tabCompletion&quot;: &quot;on&quot;</code>, type a{' '}
      <strong>snippet prefix</strong> &#40;trigger text&#41;, and press Tab to
      insert a snippet.
    </p>
    <p>
      The snippet syntax follows the TextMate snippet syntax with the exceptions
      of &#39;interpolated shell code&#39; and the use of \u; both are not
      supported.
    </p>
    <h2 id="built-in-snippets">Built-in snippets</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio has built-in snippets for a number of
      languages such as: JavaScript, TypeScript, Markdown, and PHP.
    </p>
    <p>
      You can see the available snippets for a language by running the{' '}
      <strong>Insert Snippet</strong> command in the Command Palette to get a
      list of the snippets for the language of the current file. However, keep
      in mind that this list also includes user snippets that you have defined,
      and any snippets provided by extensions you have installed.
    </p>
    <h2 id="install-snippets-from-the-marketplace">
      Install snippets from the Marketplace
    </h2>
    <p>
      Many extensions on the AVAP<sup>TM</sup> Dev Studio Marketplace include
      snippets. You can search for extensions that contains snippets in the
      Extensions view &#40;Ctrl+Shift+X&#41; using the
      @category:&quot;snippets&quot; filter.
    </p>
    <p>
      If you find an extension you want to use, install it, then restart AVAP
      <sup>TM</sup> Dev Studio and the new snippets will be available.
    </p>
    <h2 id="create-your-own-snippets">Create your own snippets</h2>
    <p>
      You can easily define your own snippets without any extension. To create
      or edit your own snippets, select <strong>Configure User Snippets</strong>{' '}
      under <strong>File</strong> &gt; <strong>Preferences</strong>, and then
      select the language &#40;by language identifier&#41; for which the
      snippets should appear, or the <strong>New Global Snippets file</strong>{' '}
      option if they should appear for all languages. AVAP<sup>TM</sup> Dev
      Studio manages the creation and refreshing of the underlying snippets
      file&#40;s&#41; for you.
    </p>
    <p>
      Snippets files are written in JSON, support C-style comments, and can
      define an unlimited number of snippets. Snippets support most TextMate
      syntax for dynamic behavior, intelligently format whitespace based on the
      insertion context, and allow easy multiline editing.
    </p>
    <p>Below is an example of a for loop snippet for JavaScript:</p>

    <pre className="">
      <code className="">
        // in file 'Code/User/snippets/javascript.json'
        <br />
        &#123;
        <br />` `"For Loop": &#123;
        <br />` `"prefix": ["for", "for-const"],
        <br />` `"body": ["for &#40;const $&#123;2:element&#125; of
        $&#123;1:array&#125;&#41; &#123;", "\t$0", "&#125;"],
        <br />` `"description": "A for loop."
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>In the example above:</p>
    <ul>
      <li>
        &quot;For Loop&quot; is the snippet name. It is displayed via
        IntelliSense if no description is provided.
      </li>
      <li>
        <code>prefix</code> defines one or more trigger words that display the
        snippet in IntelliSense. Substring matching is performed on prefixes, so
        in this case, &quot;fc&quot; could match &quot;for-const&quot;.
      </li>
      <li>
        <code>body</code> is one or more lines of content, which will be joined
        as multiple lines upon insertion. Newlines and embedded tabs will be
        formatted according to the context in which the snippet is inserted.
      </li>
      <li>
        <code>description</code> is an optional description of the snippet
        displayed by IntelliSense.
      </li>
    </ul>
    <p>
      Additionally, the <code>body</code> of the example above has three
      placeholders &#40;listed in order of traversal&#41;:{' '}
      <code>$&#123;1:array&#125;</code>, <code>$&#123;2:element&#125;</code>,
      and <code>$0</code>. You can quickly jump to the next placeholder with
      Tab, at which point you may edit the placeholder or jump to the next one.
      The string after the colon : &#40;if any&#41; is the default text, for
      example <code>element</code> in <code>$&#123;2:element&#125;</code>.
      Placeholder traversal order is ascending by number, starting from one;
      zero is an optional special case that always comes last, and exits snippet
      mode with the cursor at the specified position.
    </p>
    <h3 id="file-template-snippets">File template snippets</h3>
    <p>
      You can add the isFileTemplate attribute to your snippet&#39;s definition
      if the snippet is intended to populate or replace a file&#39;s contents.
      File template snippets are displayed in a dropdown when you run the{' '}
      <strong>Snippets: Populate File from Snippet</strong> command in a new or
      existing file.
    </p>
    <h2 id="snippet-scope">Snippet scope</h2>
    <p>
      Snippets are scoped so that only relevant snippets are suggested. Snippets
      can be scoped by either:
    </p>
    <ol>
      <li>
        the <strong>language&#40;s&#41;</strong> to which snippets are scoped
        &#40;possibly all&#41;
      </li>
      <li>
        the <strong>project&#40;s&#41;</strong> to which snippets are scoped
        &#40;probably all&#41;
      </li>
    </ol>
    <h4 id="language-snippet-scope">Language snippet scope</h4>
    <p>
      Every snippet is scoped to one, several, or all
      &#40;&quot;global&quot;&#41; languages based on whether it is defined in:
    </p>
    <ol>
      <li>
        a <strong>language</strong> snippet file
      </li>
      <li>
        a <strong>global</strong> snippet file
      </li>
    </ol>
    <p>
      Single-language user-defined snippets are defined in a specific
      language&#39;s snippet file &#40;for example javascript.json&#41;, which
      you can access by language identifier through{' '}
      <strong>Snippets: Configure User Snippets</strong>. A snippet is only
      accessible when editing the language for which it is defined.
    </p>
    <p>
      Multi-language and global user-defined snippets are all defined in
      &quot;global&quot; snippet files &#40;JSON with the file suffix
      .code-snippets&#41;, which is also accessible through{' '}
      <strong>Snippets: Configure User Snippets</strong>. In a global snippets
      file, a snippet definition may have an additional scope property that
      takes one or more language identifiers, which makes the snippet available
      only for those specified languages. If no scope property is given, then
      the global snippet is available in <strong>all</strong> languages.
    </p>
    <p>
      Most user-defined snippets are scoped to a single language, and so are
      defined in a language-specific snippet file.
    </p>
    <h4 id="project-snippet-scope">Project snippet scope</h4>
    <p>
      You can also have a global snippets file &#40;JSON with file suffix
      .code-snippets&#41; scoped to your project. Project-folder snippets are
      created with the <strong>New Snippets file for &#39;&#39;...</strong>{' '}
      option in the <strong>Snippets: Configure User Snippets</strong> dropdown
      menu and are located at the root of the project in a .vscode folder.
      Project snippet files are useful for sharing snippets with all users
      working in that project. Project-folder snippets are similar to global
      snippets and can be scoped to specific languages through the scope
      property.
    </p>
    <h2 id="snippet-syntax">Snippet syntax</h2>
    <p>
      The body of a snippet can use special constructs to control cursors and
      the text being inserted. The following are supported features and their
      syntaxes:
    </p>
    <h4 id="tabstops">Tabstops</h4>
    <p>
      With tabstops, you can make the editor cursor move inside a snippet. Use
      $1, $2 to specify cursor locations. The number is the order in which
      tabstops will be visited, whereas $0 denotes the final cursor position.
      Multiple occurrences of the same tabstop are linked and updated in sync.
    </p>
    <h4 id="placeholders">Placeholders</h4>
    <p>
      Placeholders are tabstops with values, like $&#123;1:foo&#125;. The
      placeholder text will be inserted and selected such that it can be easily
      changed. Placeholders can be nested, like $&#123;1:another
      $&#123;2:placeholder&#125;&#125;.
    </p>
    <h4 id="choice">Choice</h4>
    <p>
      Placeholders can have choices as values. The syntax is a comma-separated
      enumeration of values, enclosed with the pipe-character, for example
      $&#123;1|one,two,three|&#125;. When the snippet is inserted and the
      placeholder selected, choices will prompt the user to pick one of the
      values.
    </p>
    <h4 id="variables">Variables</h4>
    <p>
      With $name or $&#123;name:default&#125;, you can insert the value of a
      variable. When a variable isn&#39;t set, its <strong>default</strong> or
      the empty string is inserted. When a variable is unknown &#40;that is, its
      name isn&#39;t defined&#41; the name of the variable is inserted and it is
      transformed into a placeholder.
    </p>
    <p>The following variables can be used:</p>
    <ul>
      <li>TM_SELECTED_TEXT The currently selected text or the empty string</li>
      <li>TM_CURRENT_LINE The contents of the current line</li>
      <li>
        TM_CURRENT_WORD The contents of the word under cursor or the empty
        string
      </li>
      <li>TM_LINE_INDEX The zero-index based line number</li>
      <li>TM_LINE_NUMBER The one-index based line number</li>
      <li>TM_FILENAME The filename of the current document</li>
      <li>
        TM_FILENAME_BASE The filename of the current document without its
        extensions
      </li>
      <li>TM_DIRECTORY The directory of the current document</li>
      <li>TM_FILEPATH The full file path of the current document</li>
      <li>
        RELATIVE_FILEPATH The relative &#40;to the opened workspace or
        folder&#41; file path of the current document
      </li>
      <li>CLIPBOARD The contents of your clipboard</li>
      <li>WORKSPACE_NAME The name of the opened workspace or folder</li>
      <li>WORKSPACE_FOLDER The path of the opened workspace or folder</li>
      <li>CURSOR_INDEX The zero-index based cursor number</li>
      <li>CURSOR_NUMBER The one-index based cursor number</li>
    </ul>
    <p>For inserting the current date and time:</p>
    <ul>
      <li>CURRENT_YEAR The current year</li>
      <li>CURRENT_YEAR_SHORT The current year&#39;s last two digits</li>
      <li>
        CURRENT_MONTH The month as two digits &#40;example &#39;02&#39;&#41;
      </li>
      <li>
        CURRENT_MONTH_NAME The full name of the month &#40;example
        &#39;July&#39;&#41;
      </li>
      <li>
        CURRENT_MONTH_NAME_SHORT The short name of the month &#40;example
        &#39;Jul&#39;&#41;
      </li>
      <li>
        CURRENT_DATE The day of the month as two digits &#40;example
        &#39;08&#39;&#41;
      </li>
      <li>
        CURRENT_DAY_NAME The name of day &#40;example &#39;Monday&#39;&#41;
      </li>
      <li>
        CURRENT_DAY_NAME_SHORT The short name of the day &#40;example
        &#39;Mon&#39;&#41;
      </li>
      <li>CURRENT_HOUR The current hour in 24-hour clock format</li>
      <li>CURRENT_MINUTE The current minute as two digits</li>
      <li>CURRENT_SECOND The current second as two digits</li>
      <li>CURRENT_SECONDS_UNIX The number of seconds since the Unix epoch</li>
      <li>
        CURRENT_TIMEZONE_OFFSET The current UTC time zone offset as +HH:MM or
        -HH:MM &#40;example -07:00&#41;.
      </li>
    </ul>
    <p>For inserting random values:</p>
    <ul>
      <li>RANDOM 6 random Base-10 digits</li>
      <li>RANDOM_HEX 6 random Base-16 digits</li>
      <li>UUID A Version 4 UUID</li>
    </ul>
    <p>For inserting line or block comments, honoring the current language:</p>
    <ul>
      <li>BLOCK_COMMENT_START Example output: in PHP /* or in HTML &lt;!--</li>
      <li>BLOCK_COMMENT_END Example output: in PHP */ or in HTML --&gt;</li>
      <li>LINE_COMMENT Example output: in PHP //</li>
    </ul>
    <p>
      The snippet below inserts /* Hello World */ in JavaScript files and in
      HTML files:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"hello": &#123;
        <br />` `"scope": "javascript,html",
        <br />` `"prefix": "hello",
        <br />` `"body": "$BLOCK\_COMMENT\_START Hello World
        $BLOCK\_COMMENT\_END"
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <h4 id="variable-transforms">Variable transforms</h4>
    <p>
      Transformations allow you to modify the value of a variable before it is
      inserted. The definition of a transformation consists of three parts:
    </p>
    <ol>
      <li>
        A regular expression that is matched against the value of a variable, or
        the empty string when the variable cannot be resolved.
      </li>
      <li>
        A &quot;format string&quot; that allows to reference matching groups
        from the regular expression. The format string allows for conditional
        inserts and simple modifications.
      </li>
      <li>Options that are passed to the regular expression.</li>
    </ol>
    <p>
      The following example inserts the name of the current file without its
      ending, so from foo.txt it makes foo.
    </p>

    <pre className="">
      <code className="">
        $&#123;TM\_FILENAME/&#40;.\*&#41;\\..+$/$1/&#125;
        <br />` `| | | |<br />` `| | | |-&gt; no options
        <br />` `| | |<br />` `| | |-&gt; references the contents of the first
        <br />` `| | capture group
        <br />` `| |<br />` `| |-&gt; regex to capture everything before
        <br />` `| the final `.suffix`
        <br />` `|
        <br />` `|-&gt; resolves to the filename
      </code>
    </pre>
    <h4 id="placeholder-transform">Placeholder-Transform</h4>
    <p>
      Like a Variable-Transform, a transformation of a placeholder allows
      changing the inserted text for the placeholder when moving to the next tab
      stop. The inserted text is matched with the regular expression and the
      match or matches - depending on the options - are replaced with the
      specified replacement format text. Every occurrence of a placeholder can
      define its own transformation independently using the value of the first
      placeholder. The format for Placeholder-Transforms is the same as for
      Variable-Transforms.
    </p>
    <h4 id="transform-examples">Transform examples</h4>
    <p>
      The examples are shown within double quotes, as they would appear inside a
      snippet body, to illustrate the need to double escape certain characters.
      Sample transformations and the resulting output for the filename
      example-123.456-TEST.js.
    </p>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Example</strong>
          </th>
          <th align="center">
            <strong>Output</strong>
          </th>
          <th align="center">
            <strong>Explanation</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">&quot;$&#123;TM_FILENAME/[\.]/_/&#125;&quot;</td>
          <td align="center">example-123_456-TEST.js</td>
          <td align="center">Replace the first . with _</td>
        </tr>
        <tr>
          <td align="center">&quot;$&#123;TM_FILENAME/[\.-]/_/g&#125;&quot;</td>
          <td align="center">example_123_456_TEST_js</td>
          <td align="center">Replace each . or - with _</td>
        </tr>
        <tr>
          <td align="center">
            &quot;$&#123;TM_FILENAME/&#40;.*&#41;/$&#123;1:/upcase&#125;/&#125;&quot;
          </td>
          <td align="center">EXAMPLE-123.456-TEST.JS</td>
          <td align="center">Change to all uppercase</td>
        </tr>
        <tr>
          <td align="center">
            &quot;$&#123;TM_FILENAME/[^0-9^a-z]//gi&#125;&quot;
          </td>
          <td align="center">example123456TESTjs</td>
          <td align="center">Remove non-alphanumeric characters</td>
        </tr>
      </tbody>
    </table>
    <h4 id="grammar">Grammar</h4>
    <p>
      Below is the EBNF &#40;extended Backus-Naur form&#41; for snippets. With \
      &#40;backslash&#41;, you can escape $, &#125;, and . Within choice
      elements, the backslash also escapes comma and pipe characters. Only the
      characters required to be escaped can be escaped, so $ should not be
      escaped within these constructs and neither $ or &#125; should be escaped
      inside choice constructs.
    </p>

    <pre className="">
      <code className="">
        any ::= tabstop | placeholder | choice | variable | text
        <br />
        tabstop ::= '$' int
        <br />` `| '$&#123;' int '&#125;'
        <br />` `| '$&#123;' int transform '&#125;'
        <br />
        placeholder ::= '$&#123;' int ':' any '&#125;'
        <br />
        choice ::= '$&#123;' int '|' text &#40;',' text&#41;\* '|&#125;'
        <br />
        variable ::= '$' var | '$&#123;' var '&#125;'
        <br />` `| '$&#123;' var ':' any '&#125;'
        <br />` `| '$&#123;' var transform '&#125;'
        <br />
        transform ::= '/' regex '/' &#40;format | text&#41;+ '/' options
        <br />
        format ::= '$' int | '$&#123;' int '&#125;'
        <br />` `| '$&#123;' int ':' '/upcase' | '/downcase' | '/capitalize' |
        '/camelcase' | '/pascalcase' '&#125;'
        <br />` `| '$&#123;' int ':+' if '&#125;'
        <br />` `| '$&#123;' int ':?' if ':' else '&#125;'
        <br />` `| '$&#123;' int ':-' else '&#125;' | '$&#123;' int ':' else
        '&#125;'
        <br />
        regex ::= JavaScript Regular Expression value &#40;ctor-string&#41;
        <br />
        options ::= JavaScript Regular Expression option &#40;ctor-options&#41;
        <br />
        var ::= [\_a-zA-Z] [\_a-zA-Z0-9]\*
        <br />
        int ::= [0-9]+
        <br />
        text ::= .\*
        <br />
        if ::= text
        <br />
        else ::= text
      </code>
    </pre>
    <h2 id="using-textmate-snippets">Using TextMate snippets</h2>
    <p>
      You can also use existing TextMate snippets &#40;.tmSnippets&#41; with
      AVAP<sup>TM</sup> Dev Studio. See the Using TextMate Snippets topic in our
      Extension API section to learn more.
    </p>
    <h2 id="assign-keybindings-to-snippets">Assign keybindings to snippets</h2>
    <p>
      You can create custom keybindings to insert specific snippets. Open
      keybindings.json &#40;
      <strong>Preferences: Open Keyboard Shortcuts File</strong>&#41;, which
      defines all your keybindings, and add a keybinding passing
      &quot;snippet&quot; as an extra argument:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"key": "cmd+k 1",
        <br />` `"command": "editor.action.insertSnippet",
        <br />` `"when": "editorTextFocus",
        <br />` `"args": &#123;
        <br />` `"snippet": "console.log&#40;$1&#41;$0"
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>
      The keybinding will invoke the <strong>Insert Snippet</strong> command but
      instead of prompting you to select a snippet, it will insert the provided
      snippet. You define the custom keybinding as usual with a keyboard
      shortcut, command ID, and optional when clause context for when the
      keyboard shortcut is enabled.
    </p>
    <p>
      Also, instead of using the snippet argument value to define your snippet
      inline, you can reference an existing snippet by using the langId and name
      arguments. The langId argument selects the language for which the snippet
      denoted by name is inserted, e.g the sample below selects the myFavSnippet
      that&#39;s available for csharp-files.
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"key": "cmd+k 1",
        <br />` `"command": "editor.action.insertSnippet",
        <br />` `"when": "editorTextFocus",
        <br />` `"args": &#123;
        <br />` `"langId": "csharp",
        <br />` `"name": "myFavSnippet"
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <h2 id="next-steps">Next steps</h2>
    <ul>
      <li>
        Command Line - AVAP<sup>TM</sup> Dev Studio has a rich command-line
        interface to open or diff files and install extensions.
      </li>
      <li>
        Extension API - Learn about other ways to extend AVAP<sup>TM</sup> Dev
        Studio.
      </li>
      <li>
        Snippet Guide - You can package snippets for use in AVAP<sup>TM</sup>{' '}
        Dev Studio.
      </li>
    </ul>
    <h2 id="common-questions">Common questions</h2>
    <h3 id="what-if-i-want-to-use-existing-textmate-snippets-from-a-tmsnippet-file">
      What if I want to use existing TextMate snippets from a .tmSnippet file?
    </h3>
    <p>
      You can easily package TextMate snippets files for use in AVAP
      <sup>TM</sup> Dev Studio. See Using TextMate Snippets in our Extension API
      documentation.
    </p>
    <h3 id="how-do-i-have-a-snippet-place-a-variable-in-the-pasted-script">
      How do I have a snippet place a variable in the pasted script?
    </h3>
    <p>
      To have a variable in the pasted script, you need to escape the
      &#39;$&#39; of the <code>$variable</code> name so that it isn&#39;t parsed
      by the snippet expansion phase.
    </p>

    <pre className="">
      <code className="">
        "VariableSnippet":&#123;
        <br />` `"prefix": "\_Var",
        <br />` `"body": "\\$MyVar = 2",
        <br />` `"description": "A basic snippet that places a variable into
        script with the $ prefix"
        <br />` `&#125;
      </code>
    </pre>
    <p>This results in the pasted snippet as:</p>

    <pre className="">
      <code className="">$MyVar = 2</code>
    </pre>
    <h3 id="can-i-remove-snippets-from-intellisense">
      Can I remove snippets from IntelliSense?
    </h3>
    <p>
      Yes, you can hide specific snippets from showing in IntelliSense
      &#40;completion list&#41; by selecting the{' '}
      <strong>Hide from IntelliSense</strong> button to the right of snippet
      items in the <strong>Insert Snippet</strong> command dropdown.
    </p>
    <p>
      You can still select the snippet with the <strong>Insert Snippet</strong>{' '}
      command but the hidden snippet won&#39;t be displayed in IntelliSense.
    </p>
  </Fragment>
);
export default USER_GUIDE_Snippets;
