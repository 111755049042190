import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Inter_networks_movement = () => 
  {
    const codes ={
      Retun: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": true, 
          "currency_data": { 
            "abbreviation": <string>, 
            "suffix": <string>,
             "format": <string>, 
             "symbol": <string>, 
             "prefix": <string>, 
             "decimals": <decimal>, 
             "id": <integer> 
            }, 
            "elapsed": <float>, 
            "raw_import": <decimal>, 
            "currency": <string>,
            "commission_user_card": <decimal>,
            "codtran": <string>, 
            "user_commission": <decimal>, 
            "amount": <string> 
          }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": false, 
          "level": <string>, 
          "message": <string>, 
          "error": <string> 
        }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requestsurl = "https://grayghosttoken.mooo.com/ws/movimientos.py/inter_networks_movement?id_sesion=675-HVxrqR1K6Qgq4I5ROd8pdw==&user_id=95&country_code=ES&id_canal=1&amount=1&concept=envio&target_network=7&id_origen=212&phone_dst=643340526"
        payload={}
        headers = { '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'}
        response = requests.request("GET", url, headers=headers, data=payload)
        print(response.text)`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requestsurl = "https://grayghosttoken.mooo.com/ws/movimientos.py/inter_networks_movement?id_sesion=675-HVxrqR1K6Qgq4I5ROd8pdw==&user_id=95&country_code=ES&id_canal=1&amount=1&concept=envio&target_network=7&id_origen=212&phone_dst=643340526"
          payload={}
          headers = { '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'}
          response = requests.request("GET", url, headers=headers, data=payload)
          print(response.text)`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
               'method': 'GET',
               'url': 'https://grayghosttoken.mooo.com/ws/movimientos.py/inter_networks_movement?id_sesion=675-HVxrqR1K6Qgq4I5ROd8pdw==&user_id=95&country_code=ES&id_canal=1&amount=1&concept=envio&target_network=7&id_origen=212&phone_dst=643340526',
               'headers': {
               '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'
               }
              };
              request(options, function (error, response) {
               if (error) throw new Error(error);
               console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var myHeaders = new Headers();
              myHeaders.append("101ObexApiKey", "KcM6Vr7EfNnKScEi00jdritBzNjWAOkz");
              
              var requestOptions = {
               method: 'GET',
               headers: myHeaders,
               redirect: 'follow'
              };
              
              fetch("https://grayghosttoken.mooo.com/ws/movimientos.py/inter_networks_movement?id_sesion=675-HVxrqR1K6Qgq4I5ROd8pdw==&user_id=95&country_code=ES&id_canal=1&amount=1&concept=envio&target_network=7&id_origen=212&phone_dst=643340526", requestOptions)
               .then(response => response.text())
               .then(result => console.log(result))
               .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request GET 'https://grayghosttoken.mooo.com/ws/movimientos.py/inter_networks_movement?id_sesion=675-HVxrqR1K6Qgq4I5ROd8pdw==&user_id=95&country_code=ES&id_canal=1&amount=1&concept=envio&target_network=7&id_origen=212&phone_dst=643340526' \--header '101ObexApiKey: KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="inter-networks-movement">Inter Networks Movement</h1>
        <h2 id="wsmovimientospyinter_networks_movement">
          ws/movimientos.py/inter_networks_movement
        </h2>
        <h3 id="receives">Receives</h3>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <div className='overflow-auto'>
        <table>
          <thead>
            <tr>
              <th align="center">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center">country_code</td>
              <td align="center">
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
              <td align="center">ES</td>
            </tr>
            <tr>
              <td align="center">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
              <td align="center">34242-7M94x6WvLh7-b19HC6PM_g==</td>
            </tr>
            <tr>
              <td align="center">user_id</td>
              <td align="center">Int</td>
              <td align="center">Yes</td>
              <td align="center">Identifier of the user making the request</td>
              <td align="center">N/A</td>
              <td align="center">414</td>
            </tr>
            <tr>
              <td align="center">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session to &#39;Access Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
              <td align="center">328621ee3acda7a582338c5bba444c3094cd6f29</td>
            </tr>
            <tr>
              <td align="center">channel_id</td>
              <td align="center">Int</td>
              <td align="center">Yes</td>
              <td align="center">Channel of the app</td>
              <td align="center">N/A</td>
              <td align="center">1</td>
            </tr>
            <tr>
              <td align="center">amount</td>
              <td align="center">Int</td>
              <td align="center">Yes</td>
              <td align="center">Amount sent</td>
              <td align="center">N/A</td>
              <td align="center">10000000</td>
            </tr>
            <tr>
              <td align="center">concept</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">Concept of what is sent</td>
              <td align="center">N/A</td>
              <td align="center">Test</td>
            </tr>
            <tr>
              <td align="center">target_network</td>
              <td align="center">Int</td>
              <td align="center">Yes</td>
              <td align="center">Transfer destination network</td>
              <td align="center">N/A</td>
              <td align="center">7</td>
            </tr>
            <tr>
              <td align="center">phone_dst</td>
              <td align="center">Int</td>
              <td align="center">Yes</td>
              <td align="center">Recipient&#39;s phone</td>
              <td align="center">N/A</td>
              <td align="center">643340526</td>
            </tr>
          </tbody>
        </table>
        </div>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.Retun}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>currency_data:</code> Contains the different details of the
            currency used in the operation.
          </li>
          <li>
            <code>abbreviation:</code> The abbreviated name of the currency
            &#40;EUR, MXN&#41;.
          </li>
          <li>
            <code>suffix:</code> Indicates the suffix that is applied in the
            currency format &#40;pesos, euros&#41;.
          </li>
          <li>
            <code>format:</code> The full format that applies to the currency, it
            includes the suffix and the prefix.
          </li>
          <li>
            <code>symbol:</code> The symbol associated to the currency &#40;€, ¢,
            $&#41;.
          </li>
          <li>
            <code>prefix:</code> The prefix that is applied in the currency format.
          </li>
          <li>
            <code>decimals:</code> The maximum number of decimal places that will be
            included in the currency format.
          </li>
          <li>
            <code>id:</code> Identifier of the currency in BBDD.
          </li>
          <li>
            <code>elapsed:</code> Operation execution time.
          </li>
          <li>
            <code>rae_amount:</code> Negative transaction amount without applying
            the format.
          </li>
          <li>
            <code>currency:</code> Short name of the currency used in the operation,
            it matches the abbreviation.
          </li>
          <li>
            <code>comission_user_card:</code> Commission that would be applied if
            the payment is made by card.
          </li>
          <li>
            <code>codtran:</code> Transaction code that identifies the executed
            operation.
          </li>
          <li>
            <code>user_comission:</code> Commission that will be applied to the
            user.
          </li>
          <li>
            <code>amount:</code> Negative transaction amount applying the format.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
        </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>level:</code> Error importance level.
          </li>
          <li>
            <code>message:</code> Error message.
          </li>
          <li>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          The purpose of this service is to send money between blockchain networks,
          for this there must be liquidity.
        </p>
        </div>
        <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
        </div>
        
        </div>
        </div>
      </Fragment>
      
    );
  }
export default Inter_networks_movement;
