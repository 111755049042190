import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';

const Cancel_Transaction_EN = () => 
 {
  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{  
        "status": true, 
        "resultado":{
            "timestamp": "11/10/2019 12:00:00", 
            "transaction": "8960b3b3ea0a64274fe3f0e2ee31d9b0", 
          }, 
          "elapsed": <float>
        }`,
      copied: false
    },
    answe_ko1: {
      title:"Answer ok",
      type: "json",
      code: `{
          "status": false, 
          "level": <str>, 
          "message": <str>, 
          "transaction": <str>, 
          "error": { 
            "code": "***", 
            "message": "************" }
          }`,
      copied: false
    }
  };
  return(
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
      <h2 id="cancel-transaction">Cancel transaction</h2>
      <p>
        By means of this EndPoint, all operations related to the indicated
        transaction will be reversed
        <br></br>
        <span className="label post">POST:</span>
        <span> </span>{' '}
        <code>URL_BASE + /ws/movimientos.py/anularcomprartarjeta</code>
        <br></br>
      </p>
      <h3 id="receives">Receives:</h3>
      <table>
        <thead>
          <tr>
            <th align="center">Parameter</th>
            <th align="center">Obligatory</th>
            <th align="center">Format</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
            <th align="center">Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="center">country_code</td>
            <td align="center">Yes</td>
            <td align="center">
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                ISO 3166
              </a>{' '}
              alpha-2
            </td>
            <td align="center">Country code in which the user resides</td>
            <td align="center">N/A</td>
            <td align="center">ES &#40;Spain&#41;</td>
          </tr>
          <tr>
            <td align="center">user_id</td>
            <td align="center">Yes</td>
            <td align="center">Numeric</td>
            <td align="center">
              Identifier of the user account making the requesr.
            </td>
            <td align="center">N/A</td>
            <td align="center">2345, 8764, 95473</td>
          </tr>
          <tr>
            <td align="center">channel_id</td>
            <td align="center">No</td>
            <td align="center">Numeric</td>
            <td align="center">Channel from which the request is made.</td>
            <td align="center">1 &#40;Web&#41;</td>
            <td align="center">
              1 &#40;Web&#41;, 2 &#40;SMS&#41;, 3 &#40;Retail&#41;
            </td>
          </tr>
          <tr>
            <td align="center">session_id</td>
            <td align="center">
              Yes, if the signature parameter is not reported
            </td>
            <td align="center">Alphanumeric</td>
            <td align="center">
              Obtained by identifying the user with ID user_id on the platform
            </td>
            <td align="center">N/A</td>
            <td align="center">7e4d31c91cbb7fe04b7148323d9825a8</td>
          </tr>
          <tr>
            <td align="center">signature</td>
            <td align="center">
              Yes, if the id_session parameter is not reported
            </td>
            <td align="center">Alphanumeric</td>
            <td align="center">
              Allows you to sign the petition, making it unnecessary to log in
            </td>
            <td align="center">N/A</td>
            <td align="center">10</td>
          </tr>
          <tr>
            <td align="center">transaction</td>
            <td align="center">Yes</td>
            <td align="center">Alphanumeric</td>
            <td align="center">Credit / debit card name</td>
            <td align="center">N/A</td>
            <td align="center">61c25b83adfae0ce7cc587736140a226</td>
          </tr>
        </tbody>
      </table>
      <p>
        <br></br>
      </p>
      <h3 id="business-logic">Business Logic:</h3>
      <p>
        This section details some particularities related to this service that it
        is advisable to take into account.
      </p>
      <ol>
        <li>
          By indicating the <code>transaction</code> provided that it is a period
          of no more than 90 days, the amount associated with the transaction will
          be refunded, provided that said transaction belongs to the user
          indicated with the parameter <code>user_id</code>.
        </li>
      </ol>
      </div>

      <div className='col-md-5'>
      <h3 id="example-request">Example request:</h3>
  
      <pre className="language-bash">
        <code className="language-bash">
          /ws/movimientos.py/anularcomprartarjeta?{' '}
          <span className="token punctuation">\</span>
          <br /> <span className="token assign-left variable">user_id</span>
          <span className="token operator">=</span>
          <span className="token number">12345</span>{' '}
          <span className="token punctuation">\</span>
          <br /> <span className="token operator">&amp;</span>
          <span className="token assign-left variable">country_code</span>
          <span className="token operator">=</span>MX{' '}
          <span className="token punctuation">\</span>
          <br /> <span className="token operator">&amp;</span>
          <span className="token assign-left variable">transaction</span>
          <span className="token operator">=</span>
          61c25b83adfae0ce7cc587736140a226{' '}
          <span className="token punctuation">\</span>
          <br /> <span className="token operator">&amp;</span>
          <span className="token assign-left variable">signature</span>
          <span className="token operator">=</span>
          cc30e3efc7159bb10b910512ca441664c1578a4d
        </code>
      </pre>
      <h3 id="example-ok-answer">Example OK answer:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
      <h3 id="example-ko-answer">Example KO answer:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
      
      </div>
      </div>
    </Fragment>
  );
 }
export default Cancel_Transaction_EN;
