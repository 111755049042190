import React from "react";
import Introduction from "../../newViews/Docs/0_Introduction";
import Billing_System from "../../newViews/Docs/1_Billing_System";
import Add_Ons from "../../newViews/Docs/2_Add_Ons";
import Currencies_And_Fx_Exchange from "../../newViews/Docs/3_Currencies_And_Fx_Exchange";
import Communities from "../../newViews/Docs/4_Communities";
import Marketplace from "../../newViews/Docs/5_Marketplace";
import How_To_Work_With_Point_Redemption from "../../newViews/Docs/6_How_To_Work_With_Point_Redemption";
import Plan_Wallets from "../../newViews/Docs/7_Plan_Wallets";
import Encrypted_Nodes_Or_Projects from "../../newViews/Docs/8_Encrypted_Nodes_Or_Projects";
import Accesos_EN from "../../newViews/Docs/1_tools/0_Accesos_EN/Accesos_EN";


const routerDocs =
{
    "showFirst":{
        "id": "Introduction",
        "links": [
            {
                "path": "Introduction",
                "title": "Introduction",
                "view":<Introduction/>
            }
        ],
        "title": "Introduction"
    },
    "menus": [
        {
            "id": "facturation system",
            "links": [
                {
                    "path": "Facturation_System_EN",
                    "title": "Billing System: Plans and Transactions1",
                    "view":<Billing_System/>
                }
            ],
            "title": "FACTURATION SYSTEM"
        },
        {
            "id": "add ons",
            "links": [
                {
                    "path": "Add_Ons_EN",
                    "title": "Add-Ons",
                    "view":<Add_Ons/>
                }
            ],
            "title": "ADD ONS"
        },
        {
            "id": "currencies and fx exchange",
            "links": [
                {
                    "path": "Currencies_FX_Exchange_EN",
                    "title": "Currencies and FX Exchange",
                    "view":<Currencies_And_Fx_Exchange/>
                }
            ],
            "title": "CURRENCIES AND FX EXCHANGE"
        },
        {
            "id": "communities",
            "links": [
                {
                    "path": "Communities_EN",
                    "title": "Communities",
                    "view":<Communities/>
                }
            ],
            "title": "COMMUNITIES"
        },
        {
            "id": "marketplace",
            "links": [
                {
                    "path": "Marketplace_EN",
                    "title": "Marketplace",
                    "view":<Marketplace/>
                }
            ],
            "title": "MARKETPLACE"
        },
        {
            "id": "loyalty",
            "links": [
                {
                    "path": "Loyalty_Points_Exchange_EN",
                    "title": "How to Work with Point Redemption",
                    "view":<How_To_Work_With_Point_Redemption/>
                }
            ],
            "title": "LOYALTY"
        },
        {
            "id": "wallets",
            "links": [
                {
                    "path": "Plans_Wallet_EN",
                    "title": "Plan Wallets",
                    "view":<Plan_Wallets/>
                }
            ],
            "title": "WALLETS"
        },
        {
            "id": "encrypted nodes",
            "links": [
                {
                    "path": "Ciphered_Nodes_EN",
                    "title": "Encrypted Nodes or Projects",
                    "view":<Encrypted_Nodes_Or_Projects/>
                }
            ],
            "title": "ENCRYPTED NODES"
        },
        // {
        //     "id": "tools",
        //     "links": [
        //         {
        //             "path": "accesos_EN",
        //             "title": "ACCESOS EN",
        //             "view":<Accesos_EN/>
        //         }
        //     ],
        //     "title": "accesos_EN"
        // }
    ],
    "basePath": "docs/",
    "titleNav": "TITULO QUE TU QUIERAS"
};

export default routerDocs;