import React, { Fragment } from 'react';

const USER_GUIDE_CommandLineInterface = () => (
  <Fragment>
    <h1 id="command-line-interface-cli">
      Command Line Interface &#40;CLI&#41;
    </h1>
    <p>
      AVAP<sup>TM</sup> Dev Studio has a powerful command-line interface
      built-in that lets you control how you launch the editor. You can open
      files, install extensions, change the display language, and output
      diagnostics through command-line options &#40;switches&#41;.
    </p>
    <p>
      If you are looking for how to run command-line tools inside AVAP
      <sup>TM</sup> Dev Studio, see the Integrated Terminal.
    </p>
    <h2 id="command-line-help">Command line help</h2>
    <p>
      To get an overview of the AVAP<sup>TM</sup> Dev Studio command-line
      interface, open a terminal or command prompt and type{' '}
      <code>code --help</code>. You will see the version, usage example, and
      list of command line options.
    </p>
    <h2 id="launching-from-command-line">Launching from command line</h2>
    <p>
      You can launch AVAP<sup>TM</sup> Dev Studio from the command line to
      quickly open a file, folder, or project. Typically, you open AVAP
      <sup>TM</sup> Dev Studio within the context of a folder. To do this, from
      an open terminal or command prompt, navigate to your project folder and
      type <code>code .</code>:
    </p>
    <blockquote>
      <p>
        <strong>Note:</strong> Users on macOS must first run a command &#40;
        <strong>
          Shell Command: Install &#39;code&#39; command in <code>PATH</code>
        </strong>
        &#41; to add AVAP<sup>TM</sup> Dev Studio executable to the{' '}
        <code>PATH</code> environment variable. Read the macOS setup guide for
        help.
      </p>
    </blockquote>
    <p>
      Windows and Linux installations should add the AVAP<sup>TM</sup> Dev
      Studio binaries location to your system path. If this isn&#39;t the case,
      you can manually add the location to the <code>Path</code> environment
      variable &#40;$PATH on Linux&#41;. For example, on Windows, the default
      AVAP<sup>TM</sup> Dev Studio binaries location is{' '}
      <code>AppData\Local\Programs\Microsoft</code> AVAP<sup>TM</sup> Dev
      Studio\bin. To review platform-specific setup instructions, see Setup.
    </p>
    <blockquote>
      <p>
        <strong>Insiders:</strong> If you are using the AVAP<sup>TM</sup> Dev
        Studio Insiders preview, you launch your Insiders build with
        code-insiders.
      </p>
    </blockquote>
    <h2 id="core-cli-options">Core CLI options</h2>
    <p>
      Here are optional arguments you can use when starting AVAP<sup>TM</sup>{' '}
      Dev Studio at the command line via code:
    </p>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Argument</strong>
          </th>
          <th align="center">
            <strong>Description</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">
            <code>-h</code> or <code>--help</code>
          </td>
          <td align="center">Print usage</td>
        </tr>
        <tr>
          <td align="center">
            <code>-v</code> or <code>--version</code>
          </td>
          <td align="center">
            Print AVAP&lt;&gt;TM&lt;/&gt; Dev Studio version &#40;for example,
            1.22.2&#41;, GitHub commit ID, and architecture &#40;for example,
            x64&#41;.
          </td>
        </tr>
        <tr>
          <td align="center">
            <code>-n</code> or <code>--new-window</code>
          </td>
          <td align="center">
            Opens a new session of AVAP&lt;&gt;TM&lt;/&gt; Dev Studio instead of
            restoring the previous session &#40;default&#41;.
          </td>
        </tr>
        <tr>
          <td align="center">
            <code>-r</code> or <code>--reuse-window</code>
          </td>
          <td align="center">
            Forces opening a file or folder in the last active window.
          </td>
        </tr>
        <tr>
          <td align="center">
            <code>-g</code> or <code>--goto</code>
          </td>
          <td align="center">
            When used with <em>file:line&#123;:character&#125;</em>, opens a
            file at a specific line and optional character position. This
            argument is provided since some operating systems permit : in a file
            name.
          </td>
        </tr>
        <tr>
          <td align="center">
            <code>-d</code> or <code>--diff</code> &lt;&gt; &lt;&gt;
          </td>
          <td align="center">
            Open a file difference editor. Requires two file paths as arguments.
          </td>
        </tr>
        <tr>
          <td align="center">
            <code>-m</code> or <code>--merge</code> &lt;&gt; &lt;&gt; &lt;&gt;
            &lt;&gt;
          </td>
          <td align="center">
            Perform a three-way merge by providing paths for two modified
            versions of a file, the common origin of both modified versions, and
            the output file to save merge results.
          </td>
        </tr>
        <tr>
          <td align="center">
            <code>-w</code> or <code>--wait</code>
          </td>
          <td align="center">
            Wait for the files to be closed before returning.
          </td>
        </tr>
        <tr>
          <td align="center">
            <code>--locale &lt;locale&gt;</code>
          </td>
          <td align="center">
            Set the display language &#40;locale&#41; for the AVAP<sup>TM</sup>{' '}
            Dev Studio session. &#40;for example, <code>en-US</code> or{' '}
            <code>zh-TW</code>&#41;
          </td>
        </tr>
      </tbody>
    </table>
    <h2 id="opening-files-and-folders">Opening Files and Folders</h2>
    <p>
      Sometimes you will want to open or create a file. If the specified file
      does not exist, AVAP<sup>TM</sup> Dev Studio will create them for you
      along with any new intermediate folders:
    </p>

    <pre className="">
      <code className="">
        code index.html style.css documentation\readme.md
      </code>
    </pre>
    <p>
      For both files and folders, you can use absolute or relative paths.
      Relative paths are relative to the current directory of the command prompt
      where you run <code>code</code>.
    </p>
    <p>
      If you specify more than one file at the command line, AVAP<sup>TM</sup>{' '}
      Dev Studio will open only a single instance.
    </p>
    <p>
      If you specify more than one folder at the command line, AVAP<sup>TM</sup>{' '}
      Dev Studio will create a Multi-root Workspace including each folder.
    </p>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Argument</strong>
          </th>
          <th align="center">
            <strong>Description</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">
            <code>file</code>
          </td>
          <td align="center">
            Name of a file to open. If the file doesn&#39;t exist, it will be
            created and marked as edited. You can specify multiple files by
            separating each file name with a space.
          </td>
        </tr>
        <tr>
          <td align="center">
            <code>file:line[:character]</code>
          </td>
          <td align="center">
            Used with the -g argument. Name of a file to open at the specified
            line and optional character position.
          </td>
        </tr>
        <tr>
          <td align="center">
            <code>folder</code>
          </td>
          <td align="center">
            Name of a folder to open. You can specify multiple folders and a new
            Multi-root Workspace is created.
          </td>
        </tr>
      </tbody>
    </table>
    <h2 id="select-a-profile">Select a profile</h2>
    <p>
      You can launch AVAP<sup>TM</sup> Dev Studio with a specific profile via
      the <code>--profile</code> command-line interface option. You pass the
      name of the profile after the -<code>-profile</code> argument and open a
      folder or a workspace using that profile. The command line below opens the{' '}
      <code>web-sample</code> folder with the &quot;Web Development&quot;
      profile:
    </p>
    <p>
      <code>
        code ~/projects/web-sample --profile &quot;Web Development&quot;
      </code>
    </p>
    <p>
      If the profile specified does not exist, a new empty profile with the
      given name is created.
    </p>
    <h2 id="working-with-extensions">Working with extensions</h2>
    <p>
      You can install and manage AVAP<sup>TM</sup> Dev Studio extensions from
      the command line.
    </p>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Argument</strong>
          </th>
          <th align="center">
            <strong>Description</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">--install-extension</td>
          <td align="center">
            Install an extension. Provide the full extension name
            publisher.extension as an argument. Use --force argument to avoid
            prompts.
          </td>
        </tr>
        <tr>
          <td align="center">--uninstall-extension</td>
          <td align="center">
            Uninstall an extension. Provide the full extension name
            publisher.extension as an argument.
          </td>
        </tr>
        <tr>
          <td align="center">--disable-extensions</td>
          <td align="center">
            Disable all installed extensions. Extensions will still be visible
            in the <strong>Disabled</strong> section of the Extensions view but
            they will never be activated.
          </td>
        </tr>
        <tr>
          <td align="center">--list-extensions</td>
          <td align="center">List the installed extensions.</td>
        </tr>
        <tr>
          <td align="center">--show-versions</td>
          <td align="center">
            Show versions of installed extensions, when using --list-extensions
          </td>
        </tr>
        <tr>
          <td align="center">--enable-proposed-api</td>
          <td align="center">
            Enables proposed api features for an extension. Provide the full
            extension name publisher.extension as an argument.
          </td>
        </tr>
      </tbody>
    </table>
    <h2 id="advanced-cli-options">Advanced CLI options</h2>
    <p>
      There are several CLI options that help with reproducing errors and
      advanced setup.
    </p>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Argument</strong>
          </th>
          <th align="center">
            <strong>Description</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">--extensions-dir</td>
          <td align="center">
            Set the root path for extensions.Overridden in Portable Mode by the
            data folder.
          </td>
        </tr>
        <tr>
          <td align="center">--user-data-dir</td>
          <td align="center">
            Specifies the directory that user data is kept in, useful when
            running as root.Overridden in Portable Mode by the data folder.
          </td>
        </tr>
        <tr>
          <td align="center">-s, --status</td>
          <td align="center">
            Print process usage and diagnostics information.
          </td>
        </tr>
        <tr>
          <td align="center">-p, --performance</td>
          <td align="center">
            Start with the <strong>Developer: Startup Performance</strong>{' '}
            command enabled.
          </td>
        </tr>
        <tr>
          <td align="center">--disable-gpu</td>
          <td align="center">Disable GPU hardware acceleration.</td>
        </tr>
        <tr>
          <td align="center">--verbose</td>
          <td align="center">Print verbose output &#40;implies --wait&#41;.</td>
        </tr>
        <tr>
          <td align="center">--prof-startup</td>
          <td align="center">Run CPU profiler during startup.</td>
        </tr>
        <tr>
          <td align="center">--upload-logs</td>
          <td align="center">
            Uploads logs from current session to a secure endpoint.
          </td>
        </tr>
        <tr>
          <td align="center">
            <strong>Multi-root</strong>
          </td>
          <td align="center"></td>
        </tr>
        <tr>
          <td align="center">--add</td>
          <td align="center">
            Add folder&#40;s&#41; to the last active window for a multi-root
            workspace.
          </td>
        </tr>
      </tbody>
    </table>
    <h4 id="create-remote-tunnel">Create remote tunnel</h4>
    <p>
      AVAP<sup>TM</sup> Dev Studio integrates with other remote environments to
      become even more powerful and flexible. Our goal is to provide a cohesive
      experience that allows you to manage both local and remote machines from
      one, unified CLI.
    </p>
    <p>
      The AVAP<sup>TM</sup> Dev Studio Remote - Tunnels extension lets you
      connect to a remote machine, like a desktop PC or VM, via a secure tunnel.
      Tunneling securely transmits data from one network to another. You can
      then securely connect to that machine from anywhere, without the
      requirement of SSH.
    </p>
    <p>
      We&#39;ve built functionality into the code CLI that will initiate tunnels
      on remote machines. You can run:
    </p>

    <pre className="">
      <code className="">code tunnel</code>
    </pre>
    <p>
      to create a tunnel on your remote machine. You may connect to this machine
      through a web or desktop AVAP<sup>TM</sup> Dev Studio client.
    </p>
    <p>
      You can review the other tunneling commands by running code tunnel -help:
    </p>
    <p>
      As you may need to run the CLI on a remote machine that can&#39;t install
      AVAP<sup>TM</sup> Dev Studio Desktop, the CLI is also available for
      standalone install on the AVAP<sup>TM</sup> Dev Studio download page.
    </p>
    <p>
      For more information on Remote Tunnels, you can review the Remote Tunnels
      documentation.
    </p>
    <h2 id="open-avap™-dev-studio">Open AVAP™ Dev Studio</h2>
    <p>
      You can also open projects and files using the platform&#39;s URL handling
      mechanism. Use the following URL formats to:
    </p>
    <p>Open a project</p>

    <pre className="">
      <code className="">
        vscode://file/&#123;full path to project&#125;/
        <br />
        <br />
        vscode://file/c:/myProject/
      </code>
    </pre>
    <p>Open a file</p>

    <pre className="">
      <code className="">
        vscode://file/&#123;full path to file&#125;
        <br />
        <br />
        vscode://file/c:/myProject/package.json
      </code>
    </pre>
    <p>Open a file to line and column</p>

    <pre className="">
      <code className="">
        vscode://file/&#123;full path to file&#125;:line:column
        <br />
        <br />
        vscode://file/c:/myProject/package.json:5:10
      </code>
    </pre>
    <p>
      You can use the URL in applications such as browsers or file explorers
      that can parse and redirect the URL. For example, on Windows, you could
      pass a vscode:// URL directly to the Windows Explorer or to the command
      line as start vscode://&#123;full path to file&#125;.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: If you are using AVAP<sup>TM</sup> Dev Studio
        Insiders builds, the URL prefix is vscode-insiders://.
      </p>
    </blockquote>
    <h2 id="next-steps">Next steps</h2>
    <p>Read on to find out about:</p>
    <ul>
      <li>
        Integrated Terminal - Run command-line tools from inside AVAP
        <sup>TM</sup> Dev Studio.
      </li>
      <li>
        Basic Editing - Learn the basics of the AVAP<sup>TM</sup> Dev Studio
        editor.
      </li>
      <li>
        Code Navigation - AVAP<sup>TM</sup> Dev Studio lets you quickly
        understand and move through your source code.
      </li>
    </ul>
    <h2 id="common-questions">Common questions</h2>
    <h4 id="code-is-not-recognized-as-an-internal-or-external-command">
      &#39;code&#39; is not recognized as an internal or external command
    </h4>
    <p>
      Your OS cannot find the AVAP<sup>TM</sup> Dev Studio binary code on its
      path. The AVAP<sup>TM</sup> Dev Studio Windows and Linux installations
      should have installed AVAP<sup>TM</sup> Dev Studio on your path. Try
      uninstalling and reinstalling AVAP<sup>TM</sup> Dev Studio. If code is
      still not found, consult the platform-specific setup topics for Windows
      and Linux.
    </p>
    <p>
      On macOS, you need to manually run the{' '}
      <strong>Shell Command: Install &#39;code&#39; command in PATH</strong>{' '}
      command &#40;available through the <strong>Command Palette</strong>{' '}
      Ctrl+Shift+P&#41;. Consult the macOS specific setup topic for details.
    </p>
    <h4 id="how-do-i-get-access-to-a-command-line-terminal-from-within-avap-tm-dev-studio">
      How do I get access to a command line &#40;terminal&#41; from within AVAP
      TM Dev Studio?
    </h4>
    <p>
      AVAP<sup>TM</sup> Dev Studio has an Integrated Terminal where you can run
      command-line tools from within AVAP<sup>TM</sup> Dev Studio.
    </p>
    <h4 id="can-i-specify-the-settings-location-for-avapsuptmsup-dev-studio-in-order-to-have-a-portable-version">
      Can I specify the settings location for AVAP<sup>TM</sup> Dev Studio in
      order to have a portable version?
    </h4>
    <p>
      Not directly through the command line, but AVAP<sup>TM</sup> Dev Studio
      has a Portable Mode, which lets you keep settings and data in the same
      location as your installation, for example, on a USB drive.
    </p>
    <h4 id="how-do-i-detect-when-a-shell-was-launched-by-avap-tm-dev-studio">
      How do I detect when a shell was launched by AVAP TM Dev Studio?
    </h4>
    <p>
      When AVAP<sup>TM</sup> Dev Studio starts up, it may launch a shell in
      order to source the &quot;shell environment&quot; to help set up tools.
      This will launch an <strong>interactive login</strong> shell and fetch its
      environment. Depending on your shell setup, this may cause problems. For
      example, it may be unexpected that the shell is launched as an interactive
      session, which AVAP<sup>TM</sup> Dev Studio needs in order to try to align{' '}
      <code>$PATH</code> with the exact value in a user created terminal.
    </p>
    <p>
      Whenever AVAP<sup>TM</sup> Dev Studio launches this initial shell, AVAP
      <sup>TM</sup> Dev Studio sets the variable{' '}
      <code>VSCODE\_RESOLVING\_ENVIRONMENT</code>to 1. If your shell or user
      scripts need to know if they are being run in the context of this shell,
      you can check the <code>VSCODE\_RESOLVING\_ENVIRONMENT</code> value.
    </p>
  </Fragment>
);
export default USER_GUIDE_CommandLineInterface;
