import React, { Fragment } from 'react';

const SETUP_Linux = () => (
  <Fragment>
    <h1 id="avap™-dev-studio-on-linux">AVAP™ Dev Studio on Linux</h1>
    <h3 id="installation">Installation</h3>
    <p>
      See the Download AVAP<sup>TM</sup> Dev Studio page for a complete list of
      available installation options.
    </p>
    <p>
      By downloading and using AVAP<sup>TM</sup> Dev Studio, you agree to the
      license terms and privacy statement.
    </p>
    <h4 id="debian-and-ubuntu-based-distributions">
      Debian and Ubuntu based distributions
    </h4>
    <p>
      The easiest way to install AVAP<sup>TM</sup> Dev Studio for Debian/Ubuntu
      based distributions is to download and install the .deb package
      &#40;64-bit&#41;, either through the graphical software center if it&#39;s
      available, or through the command line with:
    </p>

    <pre className="">
      <code className="">
        sudo apt install ./&lt;file&gt;.deb
        <br />
        <br /> # If you're on an older Linux distribution, you will need to run
        this instead:
        <br /> # sudo dpkg -i &lt;file&gt;.deb
        <br /> # sudo apt-get install -f # Install dependencies
      </code>
    </pre>
    <p>
      Note that other binaries are also available on the AVAP<sup>TM</sup> Dev
      Studio download page.
    </p>
    <p>
      Installing the .deb package will automatically install the apt repository
      and signing key to enable auto-updating using the system&#39;s package
      manager. Alternatively, the repository and key can also be installed
      manually with the following script:
    </p>

    <pre className="">
      <code className="">
        sudo apt-get install wget gpg
        <br />
        wget -qO- https://packages.microsoft.com/keys/microsoft.asc | gpg
        --dearmor &gt; packages.microsoft.gpg
        <br />
        sudo install -D -o root -g root -m 644 packages.microsoft.gpg
        /etc/apt/keyrings/packages.microsoft.gpg
        <br />
        sudo sh -c 'echo "deb [arch=amd64,arm64,armhf
        signed-by=/etc/apt/keyrings/packages.microsoft.gpg]
        https://packages.microsoft.com/repos/code stable main" &gt;
        /etc/apt/sources.list.d/AVAPDevStudio.list'
        <br />
        rm -f packages.microsoft.gpg
      </code>
    </pre>
    <p>Then update the package cache and install the package using:</p>

    <pre className="">
      <code className="">
        sudo apt install apt-transport-https
        <br />
        sudo apt update
        <br />
        sudo apt install code # or code-insiders
      </code>
    </pre>
    <h4 id="rhel-fedora-and-centos-based-distributions">
      RHEL, Fedora, and CentOS based distributions
    </h4>
    <p>
      We currently ship the stable 64-bit AVAP<sup>TM</sup> Dev Studio in a yum
      repository, the following script will install the key and repository:
    </p>

    <pre className="">
      <code className="">
        sudo rpm --import https://packages.microsoft.com/keys/microsoft.asc
        <br />
        sudo sh -c 'echo -e "[code]\nname=Visual Studio
        Code\nbaseurl=https://packages.microsoft.com/yumrepos/vscode\nenabled=1\ngpgcheck=1\ngpgkey=https://packages.microsoft.com/keys/microsoft.asc"
        &gt; /etc/yum.repos.d/vscode.repo'
      </code>
    </pre>
    <p>
      Then update the package cache and install the package using dnf
      &#40;Fedora 22 and above&#41;:
    </p>

    <pre className="">
      <code className="">
        dnf check-update
        <br />
        sudo dnf install code # or code-insiders
      </code>
    </pre>
    <p>
      Or on older versions using <strong>yum</strong>:
    </p>

    <pre className="">
      <code className="">
        yum check-update
        <br />
        sudo yum install code # or code-insiders
      </code>
    </pre>
    <p>
      Due to the manual signing process and the system we use to publish, the
      yum repo may lag behind and not get the latest version of VS Code
      immediately.
    </p>
    <h4 id="snap">Snap</h4>
    <p>
      AVAP<sup>TM</sup> Dev Studio is officially distributed as a Snap package
      in the Snap Store:
    </p>
    <p>
      <img
        src="http://101obex.static.mooo.com/02-B_snap-store.png"
        alt="SnapStore"
      />
    </p>
    <p>You can install it by running:</p>

    <pre className="">
      <code className="">
        sudo snap install --classic code # or code-insiders
      </code>
    </pre>
    <p>
      Once installed, the Snap daemon will take care of automatically updating
      AVAP<sup>TM</sup> Dev Studio in the background. You will get an in-product
      update notification whenever a new update is available.
    </p>
    <p>
      Note: If snap isn&#39;t available in your Linux distribution, please check
      the following Installing snapd guide, which can help you get that set up.
    </p>
    <p>Learn more about snaps from the official Snap Documentation.</p>
    <h4 id="opensuse-and-sle-based-distributions">
      openSUSE and SLE-based distributions
    </h4>
    <p>
      The yum repository above also works for openSUSE and SLE-based systems,
      the following script will install the key and repository:
    </p>

    <pre className="">
      <code className="">
        sudo rpm --import https://packages.microsoft.com/keys/microsoft.asc
        <br />
        sudo sh -c 'echo -e "[code]\nname=Visual Studio
        Code\nbaseurl=https://packages.microsoft.com/yumrepos/vscode\nenabled=1\ntype=rpm-md\ngpgcheck=1\ngpgkey=https://packages.microsoft.com/keys/microsoft.asc"
        &gt; /etc/zypp/repos.d/vscode.repo'
      </code>
    </pre>
    <p>Then update the package cache and install the package using:</p>

    <pre className="">
      <code className="">
        sudo zypper refresh
        <br />
        sudo zypper install code
      </code>
    </pre>
    <h4 id="aur-package-for-arch-linux">AUR package for Arch Linux</h4>
    <p>
      There is a community-maintained Arch User Repository package for AVAP
      <sup>TM</sup> DS.
    </p>
    <p>
      To get more information about the installation from the AUR, please
      consult the following wiki entry: Install AUR Packages.
    </p>
    <h4 id="nix-package-for-nixos-or-any-linux-distribution-using-nix-package-manager">
      Nix package for NixOS &#40;or any Linux distribution using Nix package
      manager&#41;
    </h4>
    <p>
      There is a community maintained AVAP<sup>TM</sup> Dev Studio Nix package
      in the nixpkgs repository. In order to install it using Nix, set
      allowUnfree option to true in your config.nix and execute:
    </p>

    <pre className="">
      <code className="">nix-env -i vscode</code>
    </pre>
    <h4 id="installing-rpm-package-manually">
      Installing .rpm package manually
    </h4>
    <p>
      The AVAP<sup>TM</sup> Dev Studio .rpm package &#40;64-bit&#41; can also be
      manually downloaded and installed, however, auto-updating won&#39;t work
      unless the repository above is installed. Once downloaded it can be
      installed using your package manager, for example with dnf:
    </p>

    <pre className="">
      <code className="">sudo dnf install &lt;file&gt;.rpm</code>
    </pre>
    <p>
      Note that other binaries are also available on the AVAP<sup>TM</sup> Dev
      Studio download page.
    </p>
    <h3 id="updates">Updates</h3>
    <p>
      AVAP<sup>TM</sup> Dev Studio ships monthly and you can see when a new
      release is available by checking the release notes. If the AVAP
      <sup>TM</sup> DS repository was installed correctly, then your system
      package manager should handle auto-updating in the same way as other
      packages on the system.
    </p>
    <p>
      Note: Updates are automatic and run in the background for the Snap
      package.
    </p>
    <h3 id="nodejs">Node.js</h3>
    <p>
      Node.js is a popular platform and runtime for easily building and running
      JavaScript applications. It also includes npm, a Package Manager for
      Node.js modules. You&#39;ll see Node.js and npm mentioned frequently in
      our documentation and some optional VS Code tooling requires Node.js
      &#40;for example, the AVAP<sup>TM</sup> Dev Studio extension
      generator&#41;.
    </p>
    <p>
      If you&#39;d like to install Node.js on Linux, see Installing Node.js via
      package manager to find the Node.js package and installation instructions
      tailored to your Linux distribution. You can also install and support
      multiple versions of Node.js by using the Node Version Manager.
    </p>
    <p>
      To learn more about JavaScript and Node.js, see our Node.js tutorial,
      where you&#39;ll learn about running and debugging Node.js applications
      with VS Code.
    </p>
    <h3 id="setting-avap-tm-dev-studio-as-the-default-text-editor">
      Setting AVAP TM Dev Studio as the default text editor
    </h3>
    <h4 id="xdg-open">xdg-open</h4>
    <p>
      You can set the default text editor for text files &#40;text/plain&#41;
      that is used by xdg-open with the following command:
    </p>

    <pre className="">
      <code className="">xdg-mime default code.desktop text/plain</code>
    </pre>
    <h4 id="debian-alternatives-system">Debian alternatives system</h4>
    <p>
      Debian-based distributions allow setting a default editor using the Debian
      alternatives system, without concern for the MIME type. You can set this
      by running the following and selecting code:
    </p>

    <pre className="">
      <code className="">
        sudo update-alternatives --set editor /usr/bin/code
      </code>
    </pre>
    <p>
      If AVAP<sup>TM</sup> Dev Studio doesn&#39;t show up as an alternative to
      editor, you need to register it:
    </p>

    <pre className="">
      <code className="">
        sudo update-alternatives --install /usr/bin/editor editor $&#40;which
        code&#41; 10
      </code>
    </pre>
    <h3 id="windows-as-a-linux-developer-machine">
      Windows as a Linux developer machine
    </h3>
    <p>
      Another option for Linux development with AVAP<sup>TM</sup> Dev Studio is
      to use a Windows machine with the Windows Subsystem for Linux
      &#40;WSL&#41;.
    </p>
    <h4 id="windows-subsystem-for-linux">Windows Subsystem for Linux</h4>
    <p>
      With WSL, you can install and run Linux distributions on Windows. This
      enables you to develop and test your source code on Linux while still
      working locally on a Windows machine. WSL supports Linux distributions
      such as Ubuntu, Debian, SUSE, and Alpine available from the Microsoft
      Store.
    </p>
    <p>
      When coupled with the WSL extension, you get full AVAP<sup>TM</sup> Dev
      Studio editing and debugging support while running in the context of a
      Linux distro on WSL.
    </p>
    <p>
      See the Developing in WSL documentation to learn more or try the Working
      in WSL introductory tutorial.
    </p>
    <h3 id="next-steps">Next steps</h3>
    <p>
      Once you have installed AVAP<sup>TM</sup> Dev Studio, these topics will
      help you learn more about it:
    </p>
    <ul>
      <li>
        Additional Components - Learn how to install Git, Node.js, TypeScript,
        and tools like Yeoman.
      </li>
      <li>
        User Interface - A quick orientation to AVAP<sup>TM</sup> Dev Studio.
      </li>
      <li>
        User/Workspace Settings - Learn how to configure VS Code to your
        preferences through settings.
      </li>
    </ul>
    <h3 id="common-questions">Common questions</h3>
    <h4 id="azure-vm-issues">Azure VM Issues</h4>
    <p>I&#39;m getting a &quot;Running without the SUID sandbox&quot; error?</p>
    <p>You can safely ignore this error.</p>
    <h4 id="debian-and-moving-files-to-trash">
      Debian and moving files to trash
    </h4>
    <p>
      If you see an error when deleting files from the AVAP<sup>TM</sup> Dev
      Studio Explorer on the Debian operating system, it might be because the
      trash implementation that AVAP<sup>TM</sup> Dev Studio is using is not
      there.
    </p>
    <p>Run these commands to solve this issue:</p>

    <pre className="">
      <code className="">sudo apt-get install gvfs libglib2.0-bin</code>
    </pre>
    <h4 id="conflicts-with-avap™-ds-packages-from-other-repositories">
      Conflicts with AVAP™ DS packages from other repositories
    </h4>
    <p>
      Some distributions, for example Pop!_OS provide their own{' '}
      <code>code</code> package. To ensure the official AVAP<sup>TM</sup> Dev
      Studio repository is used, create a file named{' '}
      <code>/etc/apt/preferences.d/code</code> with the following content:
    </p>

    <pre className="">
      <code className="">
        Package: code
        <br />
        Pin: origin "packages.microsoft.com"
        <br />
        Pin-Priority: 9999
      </code>
    </pre>
    <h4 id="avap-tm-dev-studio-is-unable-to-watch-for-file-changes-in-this-large-workspace-error-enospc">
      &quot;AVAP TM Dev Studio is unable to watch for file changes in this large
      workspace&quot; &#40;error ENOSPC&#41;
    </h4>
    <p>
      When you see this notification, it indicates that the AVAP<sup>TM</sup>{' '}
      Dev Studio file watcher is running out of file handles that are needed to
      implement file watching. Most often this can happen when opening a
      workspace that is large and contains many files. Before adjusting platform
      limits, make sure that potentially large folders, such as Python{' '}
      <code>.venv</code>, are added to the <code>files.watcherExclude</code>{' '}
      setting &#40;more details below&#41;. It is also possible that other
      running applications consume so many file handles that none are left for
      AVAP<sup>TM</sup> Dev Studio to use. In that case it might help to close
      these other applications.
    </p>
    <p>The current limit can be viewed by running:</p>

    <pre className="">
      <code className="">cat /proc/sys/fs/inotify/max_user_watches</code>
    </pre>
    <p>
      The limit can be increased to its maximum by editing{' '}
      <code>/etc/sysctl.conf</code> &#40;except on Arch Linux, read below&#41;
      and adding this line to the end of the file:
    </p>

    <pre className="">
      <code className="">fs.inotify.max_user_watches=524288</code>
    </pre>
    <p>
      The new value can then be loaded in by running <code>sudo sysctl -p</code>
      .
    </p>
    <p>
      While 524,288 is the maximum number of files that can be watched, if
      you&#39;re in an environment that is particularly memory constrained, you
      may want to lower the number. Each file watch takes up 1080 bytes, so
      assuming that all 524,288 watches are consumed, that results in an upper
      bound of around 540 MiB.
    </p>
    <p>
      Arch-based distros &#40;including Manjaro&#41; require you to change a
      different file; follow these steps instead.
    </p>
    <p>
      Another option is to exclude specific workspace directories from the AVAP
      <sup>TM</sup> DS file watcher with the <code>files.watcherExclude</code>{' '}
      setting. The default for <code>files.watcherExclude</code> excludes{' '}
      <code>node_modules</code> and some folders under .git, but you can add
      other directories that you don&#39;t want AVAP<sup>TM</sup> DS to track.
    </p>

    <pre className="">
      <code className="">
        "files.watcherExclude": &#123;
        <br /> "**/.git/objects/**": true,
        <br /> "**/.git/subtreev-cache/**": true,
        <br /> "**/node_modules/*/**": true
        <br /> &#125;
      </code>
    </pre>
    <h4 id="i-cant-see-chinese-characters-in-ubuntu">
      I can&#39;t see Chinese characters in Ubuntu
    </h4>
    <p>
      We&#39;re working on a fix. In the meantime, open the application menu,
      then choose File &gt; Preferences &gt; Settings. In the Text Editor &gt;
      Font section, set &quot;Font Family&quot; to{' '}
      <code>Droid Sans Mono, Droid Sans Fallback.</code> If you&#39;d rather
      edit the <code>settings.json</code> file directly, set{' '}
      <code>editor.fontFamily</code> as shown:
    </p>

    <pre className="">
      <code className="">
        "editor.fontFamily": "Droid Sans Mono, Droid Sans Fallback"
      </code>
    </pre>
    <h4 id="package-git-is-not-installed">Package git is not installed</h4>
    <p>
      This error can appear during installation and is typically caused by the
      package manager&#39;s lists being out of date. Try updating them and
      installing again:
    </p>

    <pre className="">
      <code className="">
        # For .deb
        <br />
        sudo apt-get update
        <br />
        <br /># For .rpm &#40;Fedora 21 and below&#41;
        <br />
        sudo yum check-update
        <br />
        <br /># For .rpm &#40;Fedora 22 and above&#41;
        <br />
        sudo dnf check-update
      </code>
    </pre>
    <h4 id="the-code-bin-command-does-not-bring-the-window-to-the-foreground-on-ubuntu">
      The code bin command does not bring the window to the foreground on Ubuntu
    </h4>
    <p>
      Running <code>code.</code> on Ubuntu when AVAP<sup>TM</sup> Dev Studio is
      already open in the current directory will not bring AVAP<sup>TM</sup> Dev
      Studio into the foreground. This is a feature of the OS which can be
      disabled using <code>ccsm</code>.
    </p>

    <pre className="">
      <code className="">
        # Install
        <br />
        sudo apt-get update
        <br />
        sudo apt-get install compizconfig-settings-manager
        <br />
        <br /># Run
        <br />
        ccsm
      </code>
    </pre>
    <p>
      Under <strong>General</strong> &gt; <strong>General Options</strong> &gt;{' '}
      <strong>Focus &amp; Raise Behaviour</strong>, set &quot;Focus Prevention
      Level&quot; to &quot;Off&quot;. Remember this is an OS-level setting that
      will apply to all applications, not just VS Code.
    </p>
    <h4 id="cannot-install-deb-package-due-to-etcaptsourceslistdvscodelist-no-such-file-or-directory">
      Cannot install .deb package due to
      &quot;/etc/apt/sources.list.d/vscode.list: No such file or directory&quot;
    </h4>
    <p>
      This can happen when <code>sources.list.d</code> doesn&#39;t exist or you
      don&#39;t have access to create the file. To fix this, try manually
      creating the folder and an empty <code>vscode.list</code> file:
    </p>

    <pre className="">
      <code className="">
        sudo mkdir /etc/apt/sources.list.d
        <br />
        sudo touch /etc/apt/sources.list.d/vscode.list
      </code>
    </pre>
    <h4 id="cannot-move-or-resize-the-window-while-x-forwarding-a-remote-window">
      Cannot move or resize the window while X forwarding a remote window
    </h4>
    <p>
      If you are using X forwarding to use AVAP<sup>TM</sup> DS remotely, you
      will need to use the native title bar to ensure you can properly
      manipulate the window. You can switch to using it by setting{' '}
      <code>window.titleBarStyle</code> to <code>native</code>.
    </p>
    <h4 id="using-the-custom-title-bar">Using the custom title bar</h4>
    <p>
      The custom title bar and menus were enabled by default on Linux for
      several months. The custom title bar has been a success on Windows, but
      the customer response on Linux suggests otherwise. Based on feedback, we
      have decided to make this setting opt-in on Linux and leave the native
      title bar as the default.
    </p>
    <p>
      The custom title bar provides many benefits including great theming
      support and better accessibility through keyboard navigation and screen
      readers. Unfortunately, these benefits do not translate as well to the
      Linux platform. Linux has a variety of desktop environments and window
      managers that can make the AVAP<sup>TM</sup> DS theming look foreign to
      users. For users needing the accessibility improvements, we recommend
      enabling the custom title bar when running in accessibility mode using a
      screen reader. You can still manually set the title bar with the{' '}
      <strong>Window: Title Bar Style</strong> &#40;
      <code>window.titleBarStyle</code>&#41; setting.
    </p>
    <h4 id="broken-cursor-in-editor-with-display-scaling-enabled">
      Broken cursor in editor with display scaling enabled
    </h4>
    <p>
      Due to an upstream issue #14787 with Electron, the mouse cursor may render
      incorrectly with scaling enabled. If you notice that the usual text cursor
      is not being rendered inside the editor as you would expect, try falling
      back to the native menu bar by configuring the setting{' '}
      <code>window.titleBarStyle</code> to <code>native</code>.
    </p>
    <h4 id="repository-changed-its-origin-value">
      Repository changed its origin value
    </h4>
    <p>If you receive an error similar to the following:</p>

    <pre className="">
      <code className="">
        E: Repository '...' changed its 'Origin' value from '...' to '...'
        <br />
        N: This must be accepted explicitly before updates for this repository
        can be applied. See apt-secure&#40;8&#41; manpage for details.
      </code>
    </pre>
    <p>
      Use<code>apt</code> instead of <code>apt-get</code> and you will be
      prompted to accept the origin change:
    </p>
    <p>
      <code>sudo apt update</code>
    </p>
  </Fragment>
);
export default SETUP_Linux;
