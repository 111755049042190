import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Servicio_de_edicion_de_perfiles_GET_EN = () => 
 {
  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{ "status": <boolean>, 
      "codtran": <string>, 
      "result": { "editor": 
        { "menu": [ 
          { "name": <string>, 
          "type_of_action_id": <integer>, 
          "options": [ <accion> ] } ], 
          "available": [ { "type_of_action_id": <integer>, 
          "point_summary": <string>, 
          "editorprofiles_profiles": [ <string> ], 
          "action_description": <string>, 
          "action_id": <integer>, 
          "name": <string>, 
          "editorprofiles_ignore": <boolean> } ], 
          "profile_id": <integer> } }, "elapsed": <float> }`,
      copied: false
    },
    answe_ko1: {
      title:"Answer ko",
      type: "json",
      code: `{ 
      "status": <boolean>, 
      "level": <string>, 
      "message": <string>, 
      "error": <string> 
    }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: `import requests url = "URL_BASE/ws/admin.py/editorperfiles?country_code=MX&user_id=4532&session_id=397-GuC6yCVxioNNrCTqpxgCgA==&profile_id=136" 
      payload = {} 
      files = {} 
      headers= {} 
      response = requests.request("GET", url, headers=headers, data = payload, files = files) 
      print(response.text.encode('utf8'))`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/admin.py/editorperfiles?country_code=MX&user_id=4532&session_id=397-GuC6yCVxioNNrCTqpxgCgA==&profile_id=136" 
        payload = {} 
        files = {} 
        headers= {} 
        response = requests.request("GET", url, headers=headers, data = payload, files = files) 
        print(response.text.encode('utf8'))`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: `var request = require('request');
            var options = {
            'method': 'GET',
            'url': 'URL_BASE/ws/admin.py/editorperfiles?country_code=MX&user_id=4532&session_id=397-GuC6yCVxioNNrCTqpxgCgA==&profile_id=136',
            'headers': {
            },
            formData: {
            }
            };
            request(options, function (error, response) { 
            if (error) throw new Error(error);
            console.log(response.body);
            });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: `var formdata = new FormData();
            var requestOptions = {
            method: 'GET',
            body: formdata,
            redirect: 'follow'
            };
            fetch("URL_BASE/ws/admin.py/editorperfiles?country_code=MX&user_id=4532&session_id=397-GuC6yCVxioNNrCTqpxgCgA==&profile_id=136", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: `curl --location --request GET 'URL_BASE/ws/admin.py/editorperfiles?country_code=MX&user_id=4532&session_id=397-GuC6yCVxioNNrCTqpxgCgA%3D%3D&profile_id=136'`
      }
    ]
  }
  return(
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
      <h1 id="modify-profile-editing-panel">
        Modify Profile &#40;Editing Panel&#41;
      </h1>
      <p>
        This service is used to modify the available actions for a certain
        profile.
      </p>
      <p>
        <span className="label get">GET:</span>
        <span> </span> <code>URL_BASE + /ws/admin.py/editorperfiles</code>
      </p>
      <h2 id="recevies">Recevies:</h2>
      <p>
        All parameters are sent in the querystring of the call, so a percentage
        encoding for URI &#40;also known as URL encoding&#41; must be applied.
      </p>
      <table>
        <thead>
          <tr>
            <th align="left">Parameter</th>
            <th align="center">Format</th>
            <th align="center">Obligatory</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="left">country_code</td>
            <td align="center">
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                ISO 3166 alpha-2
              </a>
            </td>
            <td align="center">Yes</td>
            <td align="center">Country code in which the user resides</td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">user_id</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">Identifier of the user making the request</td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">session_id</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send signature to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Obtained by identifying the user with ID user_id on the platform
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">signature</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send session_id to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Enables you to sign the request without having to log in
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">profile_id</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">
              Identifier of the profile to modify &#40;obtained from{' '}
              <code>/ws/util.py/perfiles</code>&#41;
            </td>
            <td align="center">N/A</td>
          </tr>
        </tbody>
      </table>
      <h2 id="returns">Returns:</h2>
      <p>
        Depending on the result of the operation, this service can return two
        different JSON:
      </p>
      <h3 id="answer-json-ok">Answer JSON OK:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
      <h2 id="where">Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;
        </li>
        <li>
          <code>codtran:</code> Transaction code that identifies the executed
          operation
        </li>
        <li>
          <code>result:</code> Answer to the executed service
        </li>
        <li>
          <code>editor:</code> Object that contains the actions assigned to the
          profile, and those that can be added
        </li>
        <li>
          <code>menu:</code> List with the different categories &#40;Types of
          action&#41;
        </li>
        <li>
          <code>name:</code> Name of the category
        </li>
        <li>
          <code>type_of_action_id:</code> Category identifier &#40;Type of
          action&#41;
        </li>
        <li>
          <code>options:</code> List of actions that belong to this category of
          the profile
        </li>
        <li>
          <code>available:</code> List of actions that are not associated with the
          profile
        </li>
        <li>
          <code>type_of_action_id:</code> Category identifier &#40;Type of
          action&#41;
        </li>
        <li>
          <code>point_summary:</code> Name of the entry point on the platform
        </li>
        <li>
          <code>editorprofiles_profiles:</code> List of user roles that can see
          this action
        </li>
        <li>
          <code>action_description:</code> Descriptive text of the action.
        </li>
        <li>
          <code>action_id:</code> Identifier of the action
        </li>
        <li>
          <code>name:</code> Name of the action
        </li>
        <li>
          <code>editorprofiles_ignore:</code> Boolean that indicates whether this
          action should be displayed in the editor or not.
        </li>
        <li>
          <code>elapsed:</code> Execution operation time.
        </li>
      </ul>
      <h3 id="answer-json-ko">Answer JSON KO:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
      <h2 id="where-1">Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li>
          <code>level:</code> Error importance level.
        </li>
        <li>
          <code>message:</code> Error message.
        </li>
        <li>
          <code>error:</code> Sole error code.
        </li>
      </ul>
      <h2 id="business-logic">Business logic:</h2>
      <p>
        In order to run this service it is necessary to do it from a user logged
        into the system who has an administrator profile.With this endpoint all
        the data is obtained to show the editing panel of the selected profile.
      </p>
      </div>

      <div className='col-md-5'>
      <h2 id="example-requests">Example requests:</h2>
      <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
      
      </div>
      </div>
    </Fragment>
  );
 }
export default Servicio_de_edicion_de_perfiles_GET_EN;
