import React, { Fragment } from 'react';

const USER_GUIDE_Refactoring = () => (
  <Fragment>
    <h1 id="refactoring">Refactoring</h1>
    <p>
      Source code refactoring can improve the quality and maintainability of
      your project by restructuring your code while not modifying the runtime
      behavior. AVAP<sup>TM</sup> Dev Studio supports refactoring operations
      &#40;refactorings&#41; such as Extract Method and Extract Variable to
      improve your code base from within your editor.
    </p>
    <p>
      For example, a common refactoring used to avoid duplicating code &#40;a
      maintenance headache&#41; is the Extract Method refactoring, where you
      select source code that you&#39;d like to reuse elsewhere and pull it out
      into its own shared method.
    </p>
    <p>
      Refactorings are provided by a language service and AVAP<sup>TM</sup> Dev
      Studio has built-in support for TypeScript and JavaScript refactoring
      through the TypeScript language service. Refactoring support for other
      programming languages is provided through AVAP<sup>TM</sup> Dev Studio
      extensions that contribute language services. The UI and commands for
      refactoring are the same across languages, and in this topic we&#39;ll
      demonstrate refactoring support with the TypeScript language service.
    </p>
    <h2 id="code-actions--quick-fixes-and-refactorings">
      Code Actions = Quick Fixes and refactorings
    </h2>
    <p>
      In AVAP<sup>TM</sup> Dev Studio, Code Actions can provide both
      refactorings and Quick Fixes for detected issues &#40;highlighted with red
      squiggles&#41;. An available Code Action is announced by a lightbulb near
      the source code when the cursor is on a squiggle or selected text region.
      Clicking on the Code Action lightbulb or using the{' '}
      <strong>Quick Fix</strong> command Ctrl+. will display Quick Fixes and
      refactorings.
    </p>
    <p>
      If you&#39;d just like to see refactorings without Quick Fixes, you can
      use the <strong>Refactor</strong> command &#40;Ctrl+Shift+R&#41;.
    </p>
    <blockquote>
      <p>
        <strong>Note:</strong> If you prefer to not see the Code Action
        lightbulb in your editor, you can disable lightbulbs with the{' '}
        <code>editor.lightbulb.enable setting</code>. You can still open Quick
        Fixes through <strong>Quick Fix</strong> command and Ctrl+. keyboard
        shortcut.
      </p>
    </blockquote>
    <h2 id="refactoring-actions">Refactoring actions</h2>
    <h3 id="extract-method">Extract Method</h3>
    <p>
      Select the source code you&#39;d like to extract and then click on the
      lightbulb in the gutter or press &#40;Ctrl+.&#41; to see available
      refactorings. Source code fragments can be extracted into a new method, or
      into a new function at various different scopes. During the extract
      refactoring, you will be prompted to provide a meaningful name.
    </p>
    <h3 id="extract-variable">Extract Variable</h3>
    <p>
      TypeScript language service provides <strong>Extract to const</strong>{' '}
      refactoring to create a new local variable for the currently selected
      expression:
    </p>
    <p>
      When working with classes, you can also extract a value to a new property.
    </p>
    <h2 id="rename-symbol">Rename symbol</h2>
    <p>
      Renaming is a common operation related to refactoring source code and AVAP
      <sup>TM</sup> Dev Studio has a separate <strong>Rename Symbol</strong>{' '}
      command &#40;F2&#41;. Some languages support rename symbol across files.
      Press F2 and then type the new desired name and press<code>Enter</code>.
      All usages of the symbol will be renamed, across files.
    </p>
    <h2 id="keybindings-for-code-actions">Keybindings for Code Actions</h2>
    <p>
      The <code>editor.action.codeAction</code> command lets you configure
      keybindings for specific Code Actions. This keybinding, for example,
      triggers the <strong>Extract function</strong> refactoring Code Actions:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"key": "ctrl+shift+r ctrl+e",
        <br />` `"command": "editor.action.codeAction",
        <br />` `"args": &#123;
        <br />` `"kind": "refactor.extract.function"
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>
      Code Action kinds are specified by extensions using the enhanced{' '}
      <code>CodeActionProvided</code> API. Kinds are hierarchical, so{' '}
      <code>&quot;kind&quot;: &quot;refactor&quot;</code> will show all
      refactoring Code Actions, whereas <code>&quot;kind&quot;</code>:{' '}
      <code>&quot;refactor.extract.function&quot;</code> will only show{' '}
      <strong>Extract function</strong> refactorings.
    </p>
    <p>
      Using the above keybinding, if only a single{' '}
      <code>&quot;refactor.extract.function&quot;</code> Code Action is
      available, it will be automatically applied. If multiple{' '}
      <strong>Extract function</strong> Code Actions are available, we bring up
      a context menu to select them:
    </p>
    <p>
      You can also control how/when Code Actions are automatically applied using
      the <code>apply</code> argument:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"key": "ctrl+shift+r ctrl+e",
        <br />` `"command": "editor.action.codeAction",
        <br />` `"args": &#123;
        <br />` `"kind": "refactor.extract.function",
        <br />` `"apply": "first"
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>
      Valid values for <code>&quot;apply&quot;</code>:
    </p>
    <ul>
      <li>
        &quot;first&quot; - Always automatically apply the first available Code
        Action.
      </li>
      <li>
        &quot;ifSingle&quot; - Default. Automatically apply the Code Action if
        only one is available. Otherwise, show the context menu.
      </li>
      <li>
        &quot;never&quot; - Always show the Code Action context menu, even if
        only a single Code Action is available.
      </li>
    </ul>
    <p>
      When a Code Action keybinding is configured with &quot;preferred&quot;:
      true, only preferred Quick Fixes and refactorings are shown. A preferred
      Quick Fix addresses the underlying error, while a preferred refactoring is
      the most common refactoring choice. For example, while multiple
      refactor.extract.constant refactorings may exist, each extracting to a
      different scope in the file, the preferred refactor.extract.constant
      refactoring is the one that extracts to a local variable.
    </p>
    <p>
      This keybinding uses &quot;preferred&quot;: true to create a refactoring
      that always tries to extract the selected source code to a constant in the
      local scope:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"key": "shift+ctrl+e",
        <br />` `"command": "editor.action.codeAction",
        <br />` `"args": &#123;
        <br />` `"kind": "refactor.extract.constant",
        <br />` `"preferred": true,
        <br />` `"apply": "ifSingle"
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <h2 id="extensions-with-refactorings">Extensions with refactorings</h2>
    <p>
      You can find extensions that support refactoring by looking in the AVAP
      <sup>TM</sup> Dev Studio Marketplace. You can go to the Extensions view
      &#40;Ctrl+Shift+X&#41; and type &#39;refactor&#39; in the search box. You
      can then sort by install count or ratings to see which extensions are
      popular.
    </p>
    <blockquote>
      <p>
        Tip: The extensions shown above are dynamically queried. Click on an
        extension tile above to read the description and reviews to decide which
        extension is best for you.
      </p>
    </blockquote>
    <h2 id="next-steps">Next steps</h2>
    <ul>
      <li>
        Intro Video - Code Editing - Watch an introductory video on code editing
        features.
      </li>
      <li>
        Code Navigation - AVAP<sup>TM</sup> Dev Studio lets you move quickly
        through your source code.
      </li>
      <li>
        Debugging - Learn about debugging with AVAP<sup>TM</sup> Dev Studio.
      </li>
    </ul>
    <h2 id="common-questions">Common questions</h2>
    <h3 id="why-dont-i-see-any-lightbulbs-when-there-are-errors-in-my-code">
      Why don&#39;t I see any lightbulbs when there are errors in my code?
    </h3>
    <p>
      Lightbulbs &#40;Code Actions&#41; are only shown when your cursor hovers
      over the text showing the error. Hovering over the text will show the
      error description, but you need to move the cursor or select text to see
      lightbulbs for Quick Fixes and refactorings.
    </p>
  </Fragment>
);
export default USER_GUIDE_Refactoring;
