import React, { Fragment } from 'react';

const SETUP_Enterprise = () => (
  <Fragment>
    <h1 id="enterprise-support">Enterprise Support</h1>
    <p>
      AVAP™ Dev Studio 2024 can be used as a development tool for enterprise
      teams of all sizes. As an IT admin, you can configure AVAP™ DS 2024 to
      achieve consistency and compatibility across your organization.
    </p>
    <h2 id="network-common-hostnames">Network: Common hostnames</h2>
    <p>
      A handful of features within AVAP™ DS 2024 require network communication
      to work, such as the auto-update mechanism, querying and installing
      extensions, and telemetry. For these features to work properly in a proxy
      environment, you must have the product correctly configured.
    </p>
    <p>Refer to the network common hostnames list for the required domains.</p>
    <h2 id="group-policy-on-windows">Group Policy on Windows</h2>
    <p>
      System administrators need a way to control default software settings
      across all client machines in their organization. Group Policy is a client
      solution that gives administrators flexibility to implement the behavior
      for each of the available policies and settings.
    </p>
    <p>
      AVAP™ Dev Studio 2024 now has support for Windows Registry-based Group
      Policy. Starting from AVAP™ DS 2024 version 1.69, each release will ship
      with a <code>policies</code> directory containing ADMX template files that
      can be added to the following path:{' '}
      <code>C:\Windows\PolicyDefinitions.</code>
    </p>
    <p>
      Once the policy definitions are installed, admins can use the Local Group
      Policy Editor to manage the policy values.
    </p>
    <p>
      Policies can be set both at the Computer level and the User level. If both
      are set, Computer level will take precedence. When a policy value is set,
      the value overrides the AVAP™ Dev Studio 2024 setting value configured at
      any level &#40;default, user, workspace, etc.&#41;.
    </p>
    <h2 id="additional-policies">Additional Policies</h2>
    <p>
      The goal is to promote current AVAP™ Dev Studio 2024 2024 settings as
      Policies and closely follow existing settings, so that the naming and
      behavior are consistent. If there are requests to enact more policies,
      please open an issue in the AVAP™ Dev Studio 2024 2024 GitHub repository.
      The team will determine if there is already a corresponding setting for
      the behavior or if a new setting should be created to control the desired
      behavior.
    </p>
  </Fragment>
);
export default SETUP_Enterprise;
