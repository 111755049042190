import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';

const Envio_de_regalo_EN = () => {


  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status":true,
        "signed":"<boolean>",
        "invoice":"<integer>",
        "currency_data":{
           "abbreviation":"<string>",
           "suffix":"<string>",
           "format":"<string>",
           "symbol":"<string>",
           "prefix":"<string>",
           "decimals":"<decimal>",
           "id":"<integer>"
        },
        "elapsed":"<float>",
        "raw_amount":"<decimal>",
        "currency":"<string>",
        "commission_user_card":"<decimal>",
        "codtran":"<string>",
        "user_commission":"<decimal>",
        "amount":"<string>"
     }`,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
    url = "URL_BASE/ws/movimientos.py/enviosderegalo"
    payload = {
    'country_code': 'MX',
    'user_id': '4532',
    'session_id': '419-C_F3Mp3pYrUrHRQOGhkwxg==',
    'country_code_dst': 'MX',
    'phone_dst': '7229063245',
    'sender': 'Lorem ipsum dolor sit amet.',
    'amount': '12.50',
    'currency_code': 'MXN'
    }
    files = []
    headers= {}
    response = requests.request("POST", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
    url = "URL_BASE/ws/movimientos.py/enviosderegalo"
    payload = {
    'country_code': 'MX',
    'user_id': '4532',
    'session_id': '419-C_F3Mp3pYrUrHRQOGhkwxg==',
    'country_code_dst': 'MX',
    'phone_dst': '7229063245',
    'sender': 'Lorem ipsum dolor sit amet.',
    'amount': '12.50',
    'currency_code': 'MXN'
    }
    files = []
    headers= {}
    response = requests.request("POST", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
  var options = {
  'method': 'POST',
  'url': 'URL_BASE/ws/movimientos.py/enviosderegalo',
  'headers': {},
  formData: {
  'country_code': 'MX',
  'user_id': '4532',
  'session_id': '419-C_F3Mp3pYrUrHRQOGhkwxg==',
  'country_code_dst': 'MX',
  'phone_dst': '7229063245',
  'sender': 'Lorem ipsum dolor sit amet.',
  'amount': '12.50',
  'currency_code': 'MXN'
  }
  };
  request(options, function (error, response) { 
  if (error) throw new Error(error);
  console.log(response.body);
  });`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var formdata = new FormData();
  formdata.append("country_code", "MX");
  formdata.append("user_id", "4532");
  formdata.append("session_id", "419-C_F3Mp3pYrUrHRQOGhkwxg==");
  formdata.append("country_code_dst", "MX");
  formdata.append("phone_dst", "7229063245");
  formdata.append("sender", "Lorem ipsum dolor sit amet.");
  formdata.append("amount", "12.50");
  formdata.append("currency_code", "MXN");
  var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
  };
  fetch("URL_BASE/ws/movimientos.py/enviosderegalo", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request POST 'URL_BASE/ws/movimientos.py/enviosderegalo' 
  --form 'country_code=MX' 
  --form 'user_id=4532' 
  --form 'session_id=419-C_F3Mp3pYrUrHRQOGhkwxg==' 
  --form 'country_code_dst=MX' 
  --form 'phone_dst=7229063245' 
  --form 'sender=Lorem ipsum dolor sit amet.' 
  --form 'amount=12.50' 
  --form 'currency_code=MXN'`
      }
    ]
  }

  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 >Send to Different User</h1>
          <p>
            This service is used to send money to a user with a sufficient balance
            available on the platform.
          </p>
          <p>
            <span className="label post">POST:</span>
            <span> </span> <code>URL_BASE + /ws/movimientos.py/enviosderegalo</code>
          </p>
          <h2 >Receives:</h2>
          <p>
            All the parameters that the service receives must be indicated in the body
            of the request.
          </p>
          <div className='overflow-auto'>

            <table>
              <thead>
                <tr>
                  <th align="left">Parameter</th>
                  <th align="center">Format</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Description</th>
                  <th align="center">Default value</th>
                  <th align="center">Example</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="left">country_code</td>
                  <td align="center">
                    <a href="https://www.iso.org/glossary-for-iso-3166.html">
                      ISO 3166 alpha-2
                    </a>
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">Country code in which the user resides</td>
                  <td align="center">N/A</td>
                  <td align="center"></td>
                </tr>
                <tr>
                  <td align="left">user_id</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">
                    Identifier of the user making the request &#40;Admin or
                    Affiliate&#41;
                  </td>
                  <td align="center">N/A</td>
                  <td align="center"></td>
                </tr>
                <tr>
                  <td align="left">session_id</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send signature to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Obtained by identifying the user with ID user_id on the platform
                  </td>
                  <td align="center">N/A</td>
                  <td align="center"></td>
                </tr>
                <tr>
                  <td align="left">signature</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send session_id to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Enables you to sign the request without having to log in
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
                </tr>
                <tr>
                  <td align="left">country_code_dst</td>
                  <td align="center">
                    ISO 3166 alpha-2 &#40;
                    <a href="https://www.iso.org/glossary-for-iso-3166.html">
                      https://www.iso.org/glossary-for-iso-3166.html
                    </a>
                    &#41;
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">Gift destination country code</td>
                  <td align="center">N/A</td>
                  <td align="center">ES &#40;Spain&#41;</td>
                </tr>
                <tr>
                  <td align="left">phone_dst</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">
                    Telephone number of the user to whom the money is to be sent
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">7229063245</td>
                </tr>
                <tr>
                  <td align="left">sender</td>
                  <td align="center">String</td>
                  <td align="center">Yes</td>
                  <td align="center">Sender User Name</td>
                  <td align="center"></td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="left">amount</td>
                  <td align="center">Decimal</td>
                  <td align="center">Yes</td>
                  <td align="center">
                    Amount to send as a gift to the user indicated with
                    &#39;phone_dst&#39;
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">12.50</td>
                </tr>
                <tr>
                  <td align="left">currency_code</td>
                  <td align="center">String</td>
                  <td align="center">No</td>
                  <td align="center">
                    Currency with which the operation will be carried out.
                    &#39;Annex&#39;
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">
                    MXN &#40;peso mexicano&#41;, EUR &#40;Paises Europeos&#41;
                  </td>
                </tr>
                <tr>
                  <td align="left">category</td>
                  <td align="center">String</td>
                  <td align="center">No</td>
                  <td align="center">Name of the gift category</td>
                  <td align="center">N/A</td>
                  <td align="center"></td>
                </tr>
                <tr>
                  <td align="left">store</td>
                  <td align="center">String</td>
                  <td align="center">No</td>
                  <td align="center">
                    Name of the store to which the gift is directed
                  </td>
                  <td align="center">N/A</td>
                  <td align="center"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>
          <h3 >Answer JSON OK:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h2 >Where:</h2>
          <ul>
            <li>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li>
              <code>signed:</code> Shows if the signature is successfully processed
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li>
              <code>invoice:</code> Invoice number associated to the operation
            </li>
            <li>
              <code>currency_data:</code> Contains the different details of the
              currency used in the operation.
            </li>
            <li>
              <code>abbreviation:</code> The abbreviated name of the currency&#40;EUR,
              MXN&#41;.
            </li>
            <li>
              <code>suffix:</code> Indicates the suffix that is applied in the
              currency format &#40;pesos, euros&#41;.
            </li>
            <li>
              <code>format:</code> The full format that applies to the currency, it
              includes the suffix and the prefix.
            </li>
            <li>
              <code>symbol:</code> The symbol associated to the currency&#40;€, ¢,
              $&#41;.
            </li>
            <li>
              <code>prefix:</code> The prefix that is applied in the currency format.
            </li>
            <li>
              <code>decimals:</code> The maximum number of decimal places that will be
              included in the currency format.
            </li>
            <li>
              <code>id:</code> Identifier of the currency in BBDD.
            </li>
            <li>
              <code>elapsed:</code> Operation execution time.
            </li>
            <li>
              <code>raw_amount:</code> Negative transaction amount without applying
              the format.
            </li>
            <li>
              <code>currency:</code> Short name of the currency used in the operation,
              it matches the abbreviation.
            </li>
            <li>
              <code>commission_user_card:</code> Commission that would be applied if
              the payment is made by card.
            </li>
            <li>
              <code>codtran:</code> Transaction code that identifies the executed
              operation.
            </li>
            <li>
              <code>user_commission:</code> Commission that will be applied to the
              user.
            </li>
            <li>
              <code>amount:</code> Negative transaction amount applying the format.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 >Business logic:</h2>
          <p>
            This service, through the &#39;phone_dst&#39;, searches for the user to
            whom the gift will be sent. It delivers the amount to you at your default
            source of funds.
          </p>

        </div>
        <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>

        </div>

      </div>

    </Fragment>
  );
}
export default Envio_de_regalo_EN;
