import React, { Fragment } from 'react';

const USER_GUIDE_Accesibility = () => (
  <Fragment>
    <h1 id="accessibility">Accessibility</h1>
    <p>
      AVAP<sup>TM</sup> Dev Studio has many features to help make the editor
      accessible to all users. Zoom levels and High Contrast colors improve
      editor visibility, keyboard-only navigation supports use without a mouse,
      and the editor is optimized for screen readers.
    </p>
    <h2 id="zoom">Zoom</h2>
    <p>
      You can adjust the zoom level in AVAP<sup>TM</sup> Dev Studio with the{' '}
      <strong>View</strong> &gt; <strong>Appearance</strong> &gt;{' '}
      <strong>Zoom</strong> commands. Each <strong>Zoom</strong> command
      increases or decreases the zoom level by 20 percent.
    </p>
    <ul>
      <li>
        <strong>View</strong> &gt; <strong>Appearance</strong> &gt;{' '}
        <strong>Zoom In</strong> &#40;Ctrl+=&#41; - increase the zoom level.
      </li>
      <li>
        <strong>View</strong> &gt; <strong>Appearance</strong> &gt;{' '}
        <strong>Zoom Out</strong> &#40;Ctrl+-&#41; - decrease the zoom level.
      </li>
      <li>
        <strong>View</strong> &gt; <strong>Appearance</strong> &gt;{' '}
        <strong>Reset Zoom</strong> &#40;Ctrl+Numpad0&#41; - reset the zoom
        level to 0.
      </li>
    </ul>
    <blockquote>
      <p>
        <strong>Note</strong>: If you are using a magnifier, hold down the{' '}
        <code>Alt</code> key while viewing the hover to move the cursor over the
        hover.
      </p>
    </blockquote>
    <h3 id="persisted-zoom-level">Persisted zoom level</h3>
    <p>
      When you adjust the zoom level with the <strong>View</strong> &gt;{' '}
      <strong>Zoom In / Out</strong> commands, the zoom level is persisted in
      the <code>window.zoomLevel</code> setting. The default value is 0 and each
      increment/decrement changes the zoom level by 20 percent.
    </p>
    <h2 id="accessibility-help">Accessibility help</h2>
    <p>
      The command <strong>Open Accessibility Help</strong> Alt+F1 opens a help
      menu based on the current context. It currently applies to the editor,
      terminal, notebook, chat view, and inline chat features.
    </p>
    <p>
      You can dismiss the accessibility help menu or open additional
      documentation from within the help menu.
    </p>
    <h2 id="high-contrast-theme">High Contrast theme</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio supports a High Contrast color theme on all
      platforms. Use <strong>File</strong> &gt; <strong>Preferences</strong>{' '}
      &gt; <strong>Theme</strong> &gt; <strong>Color Theme</strong> &#40;Ctrl+K
      Ctrl+T&#41; to display the <strong>Select Color Theme</strong> dropdown
      and select the <strong>High Contrast</strong> theme.
    </p>
    <h2 id="color-vision-accessibility">Color vision accessibility</h2>
    <p>
      You can search for extensions in{' '}
      <strong>AVAP™ Dev Studio Marketplace</strong> that are compatible with
      color vision deficiency. Use the Extensions view Ctrl+Shift+X and search
      for &quot;color blind&quot; to populate relevant options.
    </p>
    <p>
      Once you have installed a color theme from the Marketplace, you can change
      the color theme with <strong>File</strong> &gt;{' '}
      <strong>Preferences</strong> &gt; <strong>Theme</strong> &gt;{' '}
      <strong>Color Theme</strong> Ctrl+K Ctrl+T.
    </p>
    <h3 id="recommended-themes-for-color-vision-accessibility">
      Recommended themes for color vision accessibility
    </h3>
    <ul>
      <li>
        GitHub - Accessible to most forms of color blindness and matches the
        themes in GitHub&#39;s settings.
      </li>
      <li>Gotthard - Optimized for approximately 20 programming languages.</li>
      <li>
        Blinds - Created for people with deuteranopia, featuring a high contrast
        color ratio.
      </li>
      <li>Greative - Considers both color blindness and light sensitivity.</li>
      <li>
        Pitaya Smoothie - Accessible to most forms of color blindness and
        compliant with WCAG 2.1 criteria for color contrast.
      </li>
    </ul>
    <h2 id="customizing-warning-colors">Customizing warning colors</h2>
    <p>
      The default Color Theme for AVAP<sup>TM</sup> Dev Studio is{' '}
      <strong>Dark+</strong>. However, you can customize both the theme and
      property colors in the user interface.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: Go to Customizing a Color Theme for more
        information about overriding the colors in your current theme.
      </p>
    </blockquote>
    <p>
      To customize the error and warning squiggles, go to <strong>File</strong>{' '}
      &gt; <strong>Preferences</strong> &gt; <strong>Settings</strong> for user
      settings. Search for &quot;color customizations&quot; to find the{' '}
      <strong>Workbench: Color Customizations</strong> setting, and open your
      user settings.json file by selecting{' '}
      <strong>Edit in settings.json</strong>.
    </p>
    <p>
      In your <code>settings.json</code> file, nest the following code inside
      the outermost curly braces. You can assign a color to each object by
      entering a hex code.
    </p>

    <pre className="">
      <code className="">
        "workbench.colorCustomizations": &#123;
        <br />` `"editorError.foreground": "#ffef0f",
        <br />` `"editorWarning.foreground": "#3777ff"
        <br />
        &#125;
      </code>
    </pre>
    <p>
      In the following example, the warning color is applied when a comma is
      missing after a JSON item.
    </p>
    <ul>
      <li>
        <code>editorError.foreground</code> - Overrides the wavy line beneath an
        error.
      </li>
      <li>
        <code>editorWarning.foreground</code> - Overrides the wavy line beneath
        a warning.
      </li>
      <li>
        <code>editorError.background</code> - Overrides the highlight color of
        an error.
      </li>
      <li>
        <code>editorWarning.background</code> - Overrides the highlight color of
        a warning.
      </li>
    </ul>
    <p>
      Assigning a color to the background of <code>editorError</code> and
      editorWarning also helps to identify potential issues. The color that you
      choose will highlight the respective error or warning. The colors shown in
      the preceding example, <code>#ffef0f</code> &#40;yellow&#41; and{' '}
      <code>#37777ff</code> &#40;blue&#41;, are more accessible to individuals
      with common forms of color vision deficiencies.
    </p>
    <h3 id="selecting-accessible-colors">Selecting accessible colors</h3>
    <p>
      The accessibility of colors is subjective to the type of anomalous
      trichromacy &#40;color blindness&#41;. The level of severity ranges per
      person and can be divided into four condition types:
    </p>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Condition</strong>
          </th>
          <th align="center">
            <strong>Type</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">Deuteranopia</td>
          <td align="center">
            Reduced sensitivity to green light. It is the most common form of
            color blindness.
          </td>
        </tr>
        <tr>
          <td align="center">Protanopia</td>
          <td align="center">Reduced sensitivity to red light.</td>
        </tr>
        <tr>
          <td align="center">Tritanopia</td>
          <td align="center">
            Reduced sensitivity to blue light. This condition is considered
            rare.
          </td>
        </tr>
        <tr>
          <td align="center">Monochromia</td>
          <td align="center">
            The inability to see all colors, also referred to as achromatopsia.
            More information about the rarest form of color blindness:
            Foundation for Fighting Blindness.
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      One of the best approaches to selecting the best colors for a specific
      condition is to apply complementary colors. These are colors located
      opposite of one another on a color wheel.
    </p>
    <p>
      <strong>Note</strong>: For more information on finding complementary
      colors, access the color blind simulator and interactive color wheel at
      Adobe Color.
    </p>
    <h2 id="dim-unfocused-editors-and-terminals">
      Dim unfocused editors and terminals
    </h2>
    <p>
      Unfocused views can be dimmed to make it clearer where typed input will
      go. This is especially useful when working with multiple editor groups or
      terminals. Turn on this feature by setting &quot;
      <code>accessibility.dimUnfocused.enabled</code>&quot;: <code>true</code>.
      You can control the dimness level with{' '}
      <code>accessibility.dimUnfocused.opacity,</code> which takes the opacity
      fraction from 0.2 to 1 &#40;default 0.75&#41;.
    </p>
    <h2 id="keyboard-navigation">Keyboard navigation</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio provides an exhaustive list of commands in
      the <strong>Command Palette</strong> &#40;Ctrl+Shift+P&#41; so that you
      can use AVAP<sup>TM</sup> Dev Studio without a mouse. Press Ctrl+Shift+P,
      then type a command name &#40;for example &#39;git&#39;&#41; to filter the
      list of commands.
    </p>
    <p>
      AVAP<sup>TM</sup> Dev Studio also has many preset keyboard shortcuts for
      commands.
    </p>
    <p>
      You can also set your own keyboard shortcuts. <strong>File</strong> &gt;{' '}
      <strong>Preferences</strong> &gt; <strong>Keyboard Shortcuts</strong>{' '}
      &#40;Ctrl+K Ctrl+S&#41; opens the Keyboard Shortcuts editor, where you can
      discover and modify key bindings for AVAP<sup>TM</sup> Dev Studio actions.
      See Key Bindings for more details on customizing or adding your own
      keyboard shortcuts.
    </p>
    <p>
      For quick navigation across the workbench, we recommend using{' '}
      <strong>Focus Next Part</strong> &#40;F6&#41; and{' '}
      <strong>Focus Previous Part</strong> &#40;Shift+F6&#41; commands.
    </p>
    <h3 id="anchor-selection">Anchor selection</h3>
    <p>
      To make it easier to start and end selections with the keyboard, there are
      four commands: <strong>Set Selection Anchor</strong> &#40;Ctrl+K
      Ctrl+B&#41;, <strong>Select From Anchor to Cursor</strong> &#40;Ctrl+K
      Ctrl+K&#41;, <strong>Cancel Selection Anchor</strong> &#40;Escape&#41; and{' '}
      <strong>Go to Selection Anchor</strong>.
    </p>
    <h2 id="tab-navigation">Tab navigation</h2>
    <p>
      You can use the Tab key to navigate between UI controls in AVAP
      <sup>TM</sup> Dev Studio. Use Shift+Tab to tab in reverse order. As you
      tab through the UI controls, an indicator will appear around each UI
      element when it has focus.
    </p>
    <p>
      All elements in the workbench support tab navigation. To avoid having too
      many tab stops, workbench toolbars and tab lists each have only one. Once
      a toolbar or a tab list has focus, you can use the arrow keys to navigate
      within them.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: Tab navigation goes in the visually natural
        order, with the exception of WebViews &#40;like Markdown preview&#41;.
        For WebViews, we recommend using the F6 and Shift+F6 commands to
        navigate between the WebViews and the rest of the workbench.
        Alternatively, you can use one of many Focus Editor commands.
      </p>
    </blockquote>
    <h2 id="tab-trapping">Tab trapping</h2>
    <p>
      By default, pressing Tab within a source code file inserts the Tab
      character &#40;or spaces depending on your indentation setting&#41; and
      does not leave the open file. You can toggle Tab trapping with Ctrl+M, and
      subsequent Tab keys will move focus out of the file. When default Tab
      trapping is off, you will see a <strong>Tab moves focus</strong> indicator
      in the Status Bar.
    </p>
    <p>
      Tab trapping also exists in the integrated terminal. The default behavior
      for the feature can be configured with <code>editor.tabFocusMode</code>.
    </p>
    <p>
      You can also toggle Tab trapping on and off from the{' '}
      <strong>Command Palette</strong> &#40;Ctrl+Shift+P&#41; with the{' '}
      <strong>Toggle Tab Key Moves Focus</strong> action.
    </p>
    <p>
      Read-only files never trap the Tab key. The{' '}
      <strong>Integrated Terminal</strong> panel respects the Tab trapping mode
      and can be toggled with Ctrl+M.
    </p>
    <h2 id="screen-readers">Screen readers</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio supports screen readers in the editor using a
      strategy based on text pagination. The following screen readers have been
      tested:
    </p>
    <ul>
      <li>Windows: NVDA and JAWS</li>
      <li>macOS: VoiceOver</li>
      <li>Linux: Orca</li>
    </ul>
    <p>
      For NVDA, we recommend that you stay in focus mode and use the hotkeys to
      navigate, instead of using browse mode.
    </p>
    <p>
      The <strong>Go to Next/Previous Error or Warning</strong> actions &#40;F8
      and Shift+F8&#41; allow screen readers to announce the error and warning
      messages.
    </p>
    <p>
      When suggestions pop up, they are announced to screen readers. Navigate
      the suggestions using Ctrl+Up and Ctrl+Down and dismiss them with
      Shift+Escape. If suggestions get in your way, you can turn them off with
      the <code>editor.quickSuggestions setting</code>.
    </p>
    <p>
      In the diff view pane, the <strong>Go to Next/Previous Difference</strong>{' '}
      actions &#40;F7 and Shift+F7&#41; will show the Accessible Diff Viewer
      with diffs presented in a unified patch format. Navigate through the
      unchanged, inserted, or deleted lines with Up and Down. Press Enter to
      return focus to the modified pane of the diff editor at the selected line
      number &#40;or the closest line number that still exists, if a deleted
      line is selected&#41;. Use Escape or Shift+Escape to dismiss the
      Accessible Diff Viewer.
    </p>
    <h2 id="accessible-view">Accessible View</h2>
    <p>
      Run the command <strong>Open Accessible View</strong> Alt+F2 to display an
      Accessible View to inspect content character by character, line by line.
      The Accessible View is currently available for hovers, notifications,
      Jupyter notebook output, and chat responses.
    </p>
    <h2 id="screen-reader-mode">Screen reader mode</h2>
    <p>
      When AVAP<sup>TM</sup> Dev Studio detects that a screen reader is being
      used, it goes into an optimized screen reader mode for UI such as the
      editor and Integrated Terminal. The Status bar will display{' '}
      <strong>Screen Reader Optimized</strong> in the lower right. You can exit
      screen reader mode by clicking on the display text or using the{' '}
      <strong>Toggle Screen Reader Accessibility Mode</strong> command.
    </p>
    <p>
      Some features, such as folding and minimap &#40;code overview&#41;, are
      disabled when in screen reader mode. You can control whether AVAP
      <sup>TM</sup> Dev Studio uses screen reader mode with the{' '}
      <strong>Editor: Accessibility Support</strong> setting &#40;
      <code>editor.accessibilitySupport</code>&#41; and the values are on, off,
      or the default auto that automatically detects a screen reader through
      querying the platform.
    </p>
    <h2 id="input-control-and-result-navigation">
      Input control and result navigation
    </h2>
    <p>
      Navigating between an input control &#40;for example, search or filter
      input&#41; and its results is consistent across the Extensions view, the
      Keyboard Shortcuts editor, and the Comments, Problems, and Debug Console
      panels using &#40;Ctrl+Down&#41; and &#40;Ctrl+Up&#41;.
    </p>
    <h2 id="terminal-accessibility">Terminal accessibility</h2>
    <p>
      You can display terminal accessibility help via Alt+F1, which describes
      useful tips when using a screen reader. One tip is to use Alt+F2 to access
      the buffer in the terminal. This will automatically enter the screen
      reader&#39;s browse mode, depending on your screen reader, for an
      accessible view of the entire terminal buffer.
    </p>
    <p>
      Use <code>editor.tabFocusMode</code> to control whether the terminal
      receives the Tab key instead of the workbench, similar to the editor.
    </p>
    <h3 id="shell-integration">Shell integration</h3>
    <p>
      The terminal has a feature called shell integration that enables many
      additional features that are not found in other terminals. When using a
      screen reader, the Run Recent Command and Go to Recent Directory features
      are particularly useful.
    </p>
    <p>
      Another shell integration powered command,{' '}
      <strong>Go to Symbol in Accessible View</strong> &#40;Ctrl+Shift+O&#41;,
      lets you navigate between terminal commands similar to{' '}
      <strong>Go to Symbol in Editor...</strong> navigation in the editor.
    </p>
    <h3 id="minimum-contrast-ratio">Minimum contrast ratio</h3>
    <p>
      Set <code>terminal.integrated.minimumContrastRatio</code> to a number
      between 1 and 21 to adjust text color luminance until the desired contrast
      ratio is met or pure white &#40;<code>#FFFFFF</code>&#41; black &#40;
      <code>#000000</code>&#41; is hit.
    </p>
    <p>
      Note that the <code>terminal.integrated.minimumContrastRatio</code>{' '}
      setting will not apply to <code>powerline</code> characters.
    </p>
    <h2 id="status-bar-accessibility">Status bar accessibility</h2>
    <p>
      Once focus is in the Status bar via <strong>Focus Next Part</strong>{' '}
      &#40;F6&#41;, you can use arrow navigation to move focus between Status
      bar entries.
    </p>
    <h3 id="diff-editor-accessibility">Diff editor accessibility</h3>
    <p>
      There is an Accessible Diff Viewer in the Diff editor that presents
      changes in a unified patch format. You can navigate between changes with{' '}
      <strong>Go to Next Difference</strong> &#40;F7&#41; and{' '}
      <strong>Go to Previous Difference</strong> &#40;Shift+F7&#41;. Navigate
      lines with the arrow keys and press Enter to jump back in the Diff editor
      and the selected line.
    </p>
    <h2 id="debugger-accessibility">Debugger accessibility</h2>
    <p>
      The AVAP<sup>TM</sup> Dev Studio debugger UI is user accessible and has
      the following features:
    </p>
    <ul>
      <li>
        Debug state changes are read out &#40;for example, &#39;started&#39;,
        &#39;breakpoint hit&#39;, &#39;terminated&#39;, ...&#41;.
      </li>
      <li>All debug actions are keyboard accessible.</li>
      <li>
        Both the Run and Debug view and Debug Console support Tab navigation.
      </li>
      <li>Debug hover is keyboard accessible &#40;Ctrl+K Ctrl+I&#41;.</li>
      <li>
        Keyboard shortcuts can be created to set focus to each debugger area.
      </li>
    </ul>
    <h2 id="audio-cues">Audio Cues</h2>
    <p>
      Audio cues indicate if the current line has certain markers such as:
      errors, warnings, breakpoints, folded text regions or inline suggestions.
    </p>
    <p>
      They are played when the primary cursor changes its line or the first time
      a marker is added to the current line. Audio cues are enabled
      automatically when a screen reader is attached, but can also be controlled
      by the settings <code>audioCues.\*</code>.
    </p>
    <p>
      The command <strong>Help: List Audio Cues</strong> lists all available
      audio cues, lets you hear each audio cue as you move through the list, and
      review which cues are currently enabled.
    </p>
    <p>
      Aria alerts also inform screen reader and braille users that certain
      markers have been hit. The command <strong>Help: List Alerts</strong>{' '}
      lists all available alerts and informs the user of which are available and
      which are enabled.
    </p>
    <h2 id="hover-accessibility">Hover accessibility</h2>
    <p>
      Some hovers cannot be hovered normally, which makes them hard to use with
      screen magnifiers. To work around this, hold the <code>Alt</code> or{' '}
      <code>Option</code> key while a hover is active to &quot;lock&quot; it in
      place so that it won&#39;t hide when hovered. Release the key to unlock
      the hover.
    </p>
    <h2 id="current-known-issues">Current known issues</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio has some known accessibility issues,
      depending on the platform. For the full list, go to AVAP<sup>TM</sup> Dev
      Studio accessibility issues.
    </p>
    <h3 id="macos">macOS</h3>
    <p>The editor includes screen reader support for VoiceOver.</p>
    <h3 id="linux">Linux</h3>
    <p>
      AVAP<sup>TM</sup> Dev Studio works well with the Orca screen reader. If
      Orca in your Linux distribution does not read the editor content:
    </p>
    <ul>
      <li>
        Make sure to have the setting &quot;
        <code>editor.accessibilitySupport</code>&quot;: &quot;on&quot; in AVAP
        <sup>TM</sup> Dev Studio. You can do this using settings, or by running
        the <strong>Show Accessibility Help</strong> command and pressing Ctrl+E
        to turn on accessibilitySupport.
      </li>
      <li>
        If Orca is still silent, try setting{' '}
        <code>ACCESSIBILITY\_ENABLED=1</code> as an environment variable.
      </li>
    </ul>
    <p>
      After you enable that setting, AVAP<sup>TM</sup> Dev Studio should work
      with the Orca screen reader.
    </p>
    <h2 id="next-steps">Next steps</h2>
    <p>Read on to find out about:</p>
    <ul>
      <li>
        AVAP<sup>TM</sup> Dev Studio User Interface - A quick orientation to
        AVAP<sup>TM</sup> Dev Studio.
      </li>
      <li>
        Basic Editing - Learn about the powerful AVAP<sup>TM</sup> Dev Studio
        editor.
      </li>
      <li>Code Navigation - Move quickly through your source code.</li>
    </ul>
  </Fragment>
);
export default USER_GUIDE_Accesibility;
