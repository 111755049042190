import React, { Fragment } from 'react';

const Cli = () => 
    
   {
     return(
  <Fragment>
    <h1 id="índice">Índice:</h1>
    <ul>
      <li>
        <a href="#info">info</a>
      </li>
      <li>
        <a href="#init">init</a>
      </li>
      <li>
        <a href="#reset">reset</a>
      </li>
      <li>
        <a href="#help">help</a>
      </li>
      <li>
        <a href="#request_api">request_api</a>
      </li>
      <li>
        <a href="#clone">clone</a>
      </li>
      <li>
        <a href="#init_repo">init_repo</a>
      </li>
      <li>
        <a href="#push">push</a>
      </li>
      <li>
        <a href="#log">log</a>
      </li>
      <li>
        <a href="#checkout">checkout</a>
      </li>
      <li>
        <a href="#publish">publish</a>
      </li>
    </ul>
    <h2 id="info">info</h2>
    <p>
      <strong>Descripción:</strong> Obtiene información detallada sobre tu token
      de desarrollador y los proyectos asociados.
    </p>
    <p>
      <strong>Uso:</strong> <code>101OBeXcli info</code>
    </p>
    <p>
      <strong>Resultado:</strong>
    </p>

    <pre className="language-yaml">
      <code className="language-yaml">
        101OBeXcli &#40;c&#41; 2022 101OBeX Corporation.
        <br />
        ========================================
        <br />
        <br />
        Selected project<span className="token punctuation">:</span>{' '}
        <span className="token number">356</span>
        <br />
        &#40;<span className="token punctuation">?</span>&#41; =
        <span className="token punctuation">&gt;</span> Your Token Information
        <span className="token punctuation">:</span>
        <br />
        <br />
        <span className="token key atrule">Organizations</span>
        <span className="token punctuation">:</span>
        <br />
        <br /> <span className="token key atrule">Name</span>
        <span className="token punctuation">:</span> NuevaOrganizacion
        <br /> <span className="token key atrule">Description</span>
        <span className="token punctuation">:</span> Nueva organizacion de
        pruebas
        <br /> <span className="token key atrule">Name</span>
        <span className="token punctuation">:</span> My Organization
        <br /> <span className="token key atrule">Description</span>
        <span className="token punctuation">:</span> My Organization
        <br />
        <br />
        <span className="token key atrule">Projects</span>
        <span className="token punctuation">:</span>
        <br />
        <br /> Project ID<span className="token punctuation">:</span>{' '}
        <span className="token number">356</span>
        <span className="token punctuation">,</span>
        <br /> Auth Token<span className="token punctuation">:</span>{' '}
        477cdd08bf4af541b033c61d6cf9828e6172fdde6e0f68e68f36301415cb0499
        <br /> Project ID<span className="token punctuation">:</span>{' '}
        <span className="token number">453</span>
        <span className="token punctuation">,</span>
        <br /> Auth Token<span className="token punctuation">:</span>{' '}
        98b7e32e148ba44989a1fd21047d0e614a1a657080ca794a6dd8bba7e78e6518
        <br /> Project ID<span className="token punctuation">:</span>{' '}
        <span className="token number">384</span>
        <span className="token punctuation">,</span>
        <br /> Auth Token<span className="token punctuation">:</span>{' '}
        a206867228f633913f63776b8932459b3bc50a9de263d98746562b98d6dff778
        <br /> Project ID<span className="token punctuation">:</span>{' '}
        <span className="token number">512</span>
        <span className="token punctuation">,</span>
        <br /> Auth Token<span className="token punctuation">:</span>{' '}
        98ef1c0f59a0c2e9d4781b520991690173978fc23adcfc51f016e119eb22d50f
        <br /> Project ID<span className="token punctuation">:</span>{' '}
        <span className="token number">277</span>
        <span className="token punctuation">,</span>
        <br /> Auth Token<span className="token punctuation">:</span>{' '}
        708affbb5282d819688d97cfc4dfbe7e128137a218ec1dd52ae50baaa25fd291
        <br /> Project ID<span className="token punctuation">:</span>{' '}
        <span className="token number">157</span>
        <span className="token punctuation">,</span>
        <br /> Auth Token<span className="token punctuation">:</span>{' '}
        0ff91979a180160b7a10dd239afb446ce6bf84872e09591249ca6d7136db47e4
        <br />
        <br />
        <span className="token key atrule">Teams</span>
        <span className="token punctuation">:</span>
        <br />
        <br />
        &#40;<span className="token punctuation">?</span>&#41; =
        <span className="token punctuation">&gt;</span> This information contains
        your account info<span className="token punctuation">,</span>
        <br /> your organizations<span className="token punctuation">
          ,
        </span>{' '}
        teams and projects authorizations.```
        <br />
        <br />
        <span className="token important">**Descripción:**</span>
        <br />
        Proporciona una descripción general de 101OBeXcli y su propósito.
        <br />
        Explica el significado y la función del token de desarrollador y los
        tokens de proyecto.
        <br />
        Ofrece un ejemplo de cómo utilizar el token del proyecto en una
        solicitud HTTP.
        <br />
        Incluye un enlace para obtener más información sobre los servicios de la
        API de 101OBeX.
        <br />
        <br />
        <span className="token important">**Notas:**</span>
        <br />
        Esta sección proporciona una visión general útil para nuevos usuarios y
        desarrolladores que deseen entender cómo utilizar 101OBeXcli y acceder a
        los servicios de la API de 101OBeX.
        <br />
        <br />
        <br />
        <br />
        <span className="token comment">## init</span>
        <br />
        <br />
        <span className="token important">**Descripción:**</span> Inicia el
        proceso de identificación del desarrollador.
        <br />
        <br />
        <span className="token important">**Uso:**</span> `101OBeXcli init`
        <br />
        <br />
        <span className="token important">**Instrucciones:**</span>
        <br />
        1. Ejecuta el comando `101OBeXcli init`.
        <br />
        2. Se abrirá automáticamente una URL en tu navegador web predeterminado.
        Si no se abre automáticamente
        <span className="token punctuation">,</span> copia y pega la URL
        proporcionada en tu navegador.
        <br />
        3. Inicia sesión con tu cuenta de desarrollador en la página web que se
        abre.
        <br />
        4. Después de iniciar sesión correctamente
        <span className="token punctuation">,</span> se te mostrará un código
        único en la página web.
        <br />
        5. Copia este código y pégalo en la línea de comandos donde se está
        ejecutando el comando `101OBeXcli init`.
        <br />
        6. Presiona Enter para completar el proceso de identificación.
        <br />
        <br />
        <span className="token important">**Ejemplo:**</span>
        <br />
        <br />
        ```bash
        <br />$ 101OBeXcli init
        <br />
        Abriendo URL de identificación en tu navegador web predeterminado
        <span className="token punctuation">...</span>
        <br />
        Por favor<span className="token punctuation">,</span> inicia sesión con
        tu cuenta de desarrollador.
        <br />
        Una vez iniciada la sesión<span className="token punctuation">
          ,
        </span>{' '}
        copia el código proporcionado y pégalo aquí
        <span className="token punctuation">:</span> ```
        <br />
        <br />
        <span className="token important">**Notas:**</span>
        <br />
        <br /> Asegúrate de completar el proceso de identificación dentro de un
        tiempo limitado<span className="token punctuation">,</span> ya que el
        código generado puede expirar después de un período especificado.
        <br /> Si experimentas algún problema durante el proceso de
        identificación<span className="token punctuation">,</span> asegúrate de
        verificar tu conexión a internet y que estás utilizando las credenciales
        correctas de tu cuenta de desarrollador. Si el problema persiste
        <span className="token punctuation">,</span> ponte en contacto con el
        soporte técnico para obtener ayuda adicional.
        <br />
        <br />
        <br />
        <span className="token important">**Instrucciones:**</span>
        <br />
        1. Ejecuta el comando `101OBeXcli reset`.
        <br />
        2. Se te solicitará una confirmación para borrar la información.
        <br />
        3. Confirma la operación escribiendo "yes" &#40;sin comillas&#41; y
        presionando Enter.
        <br />
        <br />
        <span className="token important">**Ejemplo:**</span>
        <br />
        <br />
        ```bash
        <br />$ 101OBeXcli reset
        <br />
        ¿Estás seguro de que deseas borrar la información de autenticación y
        restablecer la configuración<span className="token punctuation">
          ?
        </span>{' '}
        Esto eliminará tu token de desarrollador y cualquier otra información
        almacenada localmente. &#40;yes/no&#41;
        <span className="token punctuation">:</span> yes
        <br />
        Información borrada con éxito. La configuración ha sido restablecida.```
        <br />
        <br />
        <span className="token important">**Notas:**</span>
        <br />
        <span className="token punctuation">-</span> Al utilizar este comando
        <span className="token punctuation">,</span> se eliminará
        permanentemente la información de autenticación almacenada localmente.
        Esto significa que tendrás que volver a iniciar sesión y obtener un
        nuevo token de desarrollador utilizando el comando `init`.
        <br />
        <span className="token punctuation">-</span> Ten cuidado al usar este
        comando<span className="token punctuation">,</span> ya que no hay forma
        de deshacer la operación una vez completada.
        <br />
        <span className="token punctuation">-</span> Si estás experimentando
        problemas de autenticación o seguridad
        <span className="token punctuation">,</span> este comando puede ser útil
        para restablecer tu configuración a un estado limpio.
        <br />
        <br />
        <br />
        <span className="token important">**Descripción:**</span> Obtiene
        información detallada sobre el uso de 101OBeXcli y cómo consumir los
        servicios de la API de 101OBeX.
        <br />
        <br />
        <span className="token important">**Uso:**</span> `101OBeXcli help`
        <br />
        <br />
        <span className="token important">**Resultado:**</span>
        <br />
        <br />
        ```vbnet
        <br />
        101OBeXcli &#40;c&#41; 2022 101OBeX Corporation.
        <br />
        ========================================
        <br />
        <br />
        Welcome to 101OBeXcli<span className="token punctuation">,</span> the
        client that gives you access to 101OBeX API Services.
        <br />
        <br />
        This client is intended to manage your 101OBeX Developer token. 101OBeX
        client<span className="token punctuation">,</span> gives you the info
        you need to consume safely 101OBeX API Services
        <span className="token punctuation">,</span> such the organizations you
        collaborate<span className="token punctuation">,</span> the teams you
        belong to<span className="token punctuation">,</span> and the projects
        in which you can collaborate<span className="token punctuation">,</span>{' '}
        give you the identification with which you can consume them.
        <br />
        <br />
        The Token<span className="token punctuation">:</span>
        <br />
        =========
        <br />
        This token gives you access to 101OBeX API Services. You need to
        identify with the email where you received the Developer invitation from
        an organization that has access to 101OBeX API services. The 101OBeX
        Token is generated from a Google OAUTH2 authentication
        <span className="token punctuation">,</span> which ensures only the
        developer can access to the services shared with him.
        <br />
        <br />
        The Projects Tokens
        <br />
        ===================
        <br />
        Each project the developer can access has a personal token
        <span className="token punctuation">,</span> which allows him to use it
        with the APIs allowed to him. When the Developer wants to use any
        project<span className="token punctuation">,</span> he must inform his
        personal project token in the headers of the HTTP request.
        <br />
        <br />
        <span className="token key atrule">Example</span>
        <span className="token punctuation">:</span>
        <br />
        'curl https<span className="token punctuation">:</span>//api.101obex.com
        <span className="token punctuation">:</span>8000/ws/util.py/paises
        <br /> <span className="token punctuation">-</span>H{' '}
        <span className="token punctuation">&#123;</span>
        <br /> "101ObexToken"<span className="token punctuation">:</span>
        <span className="token string">
          "4ce2162aeda64ae638516cf5aa25e70fcce43adb6c8f22dadcd7be79c312bd76"
        </span>
        <br /> <span className="token punctuation">&#125;</span>'<br />
        <br />
        You can get more information about 101OBeX API Services on
        <span className="token punctuation">:</span>
        <br />
        <br /> https<span className="token punctuation">:</span>
        //developers.101obex.com
        <br />
        <br />
        <span className="token important">**Descripción:**</span>
        <br />
        <span className="token punctuation">-</span> Proporciona una descripción
        general de 101OBeXcli y su propósito.
        <br />
        <span className="token punctuation">-</span> Explica el significado y la
        función del token de desarrollador y los tokens de proyecto.
        <br />
        <span className="token punctuation">-</span> Ofrece un ejemplo de cómo
        utilizar el token del proyecto en una solicitud HTTP.
        <br />
        <span className="token punctuation">-</span> Incluye un enlace para
        obtener más información sobre los servicios de la API de 101OBeX.
        <br />
        <br />
        <span className="token important">**Notas:**</span>
        <br />
        <span className="token punctuation">-</span> Esta sección proporciona
        una visión general útil para nuevos usuarios y desarrolladores que
        deseen entender cómo utilizar 101OBeXcli y acceder a los servicios de la
        API de 101OBeX.
        <br />
        <br />
        <br />
        <br />
        <span className="token important">**Descripción:**</span> Envía una
        solicitud al administrador de la organización para obtener acceso a un
        servicio al que el desarrollador no tiene permisos con el token de
        identificación proporcionado.
        <br />
        <br />
        <span className="token important">**Uso:**</span> `101OBeXcli
        request_api token`
        <br />
        <br />
        <span className="token important">**Instrucciones:**</span>
        <br />
        <span className="token punctuation">-</span> Ejecuta el comando
        `101OBeXcli request_api` seguido del token de identificación que
        generaste.
        <br />
        <span className="token punctuation">-</span> Este comando enviará una
        solicitud al administrador de la organización para obtener acceso al
        servicio específico.
        <br />
        <br />
        <span className="token important">**Ejemplo:**</span>
        <br />
        <br />
        ```bash
        <br />$ 101OBeXcli request_api
        4ce2162aeda64ae638516cf5aa25e70fcce43adb6c8f22dadcd7be79c312bd76
        <br />
        Solicitud de acceso enviada al administrador de la organización. Espera
        su respuesta.
        <br />
        <br />
        <br />
        <br />
        <br />
        <span className="token important">**Descripción:**</span> Descarga la
        estructura de archivos que compone un API específico desde el
        repositorio de APIs virtuales en los servidores AVS.
        <br />
        <br />
        <span className="token important">**Uso:**</span> `101OBeXcli clone api`
        <br />
        <br />
        <span className="token important">**Instrucciones:**</span>
        <br />
        <span className="token punctuation">-</span> Ejecuta el comando
        `101OBeXcli clone` seguido del nombre o ID del API que deseas clonar.
        <br />
        <span className="token punctuation">-</span> Este comando descargará
        toda la estructura de archivos del API especificado desde el repositorio
        de APIs virtuales en los servidores AVS que tienen la funcionalidad de
        API Catalog.
        <br />
        <br />
        <span className="token important">**Ejemplo:**</span>
        <br />
        <br />
        ```bash
        <br />$ 101OBeXcli clone MyAPI
        <br />
        Clonando API 'MyAPI' desde el repositorio de APIs virtuales en los
        servidores AVS.
        <br />
        Descarga completada. La estructura de archivos del API 'MyAPI' está
        ahora disponible localmente.
      </code>
    </pre>
    <p>
      <strong>Notas:</strong>
    </p>
    <ul>
      <li>
        Este comando es útil para obtener una copia local de un API específico
        para propósitos de desarrollo o pruebas.
      </li>
      <li>
        Asegúrate de tener acceso adecuado y los permisos necesarios para clonar
        el API especificado.
      </li>
      <li>
        La estructura de archivos descargada contendrá todos los archivos
        relacionados con el API, como especificaciones de OpenAPI, archivos de
        configuración, scripts, etc.
      </li>
    </ul>
    <h2 id="init_repo">init_repo</h2>
    <p>
      <strong>Descripción:</strong>{' '}
    </p>
    <p>
      Inicializa un nuevo repositorio de API en el catálogo de APIs virtuales
      almacenado en el API Catalog de AVS.
    </p>
    <p>Uso: 101OBeXcli init_repo </p>
    <p>
      Parámetros: api: Nombre o identificador único del API que se está
      inicializando. entrypoint: Nombre del archivo principal que se ejecutará
      al consumir el API una vez publicado. endpoint: URL del endpoint al que se
      debe llamar para consumir el API.
      <strong>Instrucciones:</strong>
      Ejecuta el comando 101OBeXcli init_repo seguido del nombre o ID del API,
      el nombre del entrypoint y la URL del endpoint. Este comando creará una
      nueva entrada en el catálogo de APIs virtuales almacenado en el API
      Catalog de AVS con la información proporcionada.
      <strong>Ejemplo:</strong>
    </p>

    <pre className="language-bash">
      <code className="language-bash">
        $ 101OBeXcli init_repo MyAPI main.py https://api.example.com/myapi
        <br />
        Inicializando nuevo repositorio de API{' '}
        <span className="token string">'MyAPI'</span> en el catálogo de APIs
        virtuales en el API Catalog de AVS.
        <br />
        Entrypoint configurado como{' '}
        <span className="token string">'main.py'</span> y endpoint configurado
        como{' '}
        <span className="token string">'https://api.example.com/myapi'</span>
        <span className="token builtin class-name">.</span>
        <br />
        Repositorio de API <span className="token string">'MyAPI'</span>{' '}
        inicializado con éxito.
      </code>
    </pre>
    <p>
      <strong>init_repo</strong>
    </p>
    <p>
      <strong>Notas:</strong>
    </p>
    <ul>
      <li>
        Asegúrate de tener los permisos necesarios para crear una nueva entrada
        en el catálogo de APIs virtuales.
      </li>
      <li>
        El entrypoint especificado debe ser el archivo principal que se
        ejecutará al consumir el API una vez publicado.
      </li>
      <li>
        El endpoint especificado es la URL a la que los usuarios deberán llamar
        para consumir el API. Asegúrate de que sea una URL válida y accesible.
      </li>
    </ul>
    <p>
      <strong>push</strong>
    </p>
    <p>
      Descripción: Sube los archivos ubicados en el directorio actual al
      repositorio de APIs virtuales en el catálogo de API del servicio API
      Catalog del servidor AVS que está proporcionando servicio a la
      organización. Uso: 101OBeXcli push Parámetros: api: Nombre o identificador
      único del API al que se van a subir los archivos. message: Mensaje
      descriptivo que acompañará la actualización de los archivos, indicando los
      cambios realizados. Instrucciones: Ejecuta el comando 101OBeXcli push
      seguido del nombre o ID del API y un mensaje descriptivo. Este comando
      subirá los archivos ubicados en el directorio actual al repositorio de
      APIs virtuales en el catálogo de API del servicio API Catalog del servidor
      AVS correspondiente a la organización. Ejemplo:
    </p>
    <p>
      ```bash $ 101OBeXcli push MyAPI &quot;Actualización de la documentación de
      la API&quot; Subiendo archivos al repositorio de API &#39;MyAPI&#39; en el
      catálogo de API del servicio API Catalog del servidor AVS. Archivos
      subidos con éxito. Actualización completa.
    </p>
    <p>Notas:</p>
    <ul>
      <li>
        Asegúrate de tener los permisos necesarios para subir archivos al
        repositorio del API especificado.
      </li>
      <li>
        El mensaje descriptivo es opcional pero se recomienda incluirlo para
        documentar los cambios realizados.
      </li>
      <li>
        Este comando es útil para actualizar los archivos del API con los
        cambios realizados localmente antes de publicar la nueva versión del
        API.
      </li>
    </ul>
    <h2 id="log">log</h2>

    <pre className="">
      <code className="">
        Descripción: Obtiene un listado de las distintas subidas de archivos al
        repositorio del API virtual especificado.
        <br />
        Uso: 101OBeXcli log <br />
        Parámetros:
        <br /> api: Nombre o identificador único del API del que se quiere
        consultar el listado de actualizaciones. Este parámetro se obtiene del
        nombre del directorio actual.
        <br />
        Instrucciones:
        <br /> Asegúrate de que estás ubicado en el directorio correspondiente
        al API del que quieres consultar el log de actualizaciones.
        <br /> Ejecuta el comando 101OBeXcli log seguido del nombre o ID del
        API.
        <br /> Este comando devolverá un listado de las distintas subidas de
        archivos al repositorio del API virtual.
        <br />
        Ejemplo:
        <br />
        <br />
        bash
        <br />
        <br />$ cd MyAPI
        <br />$ 101OBeXcli log MyAPI
        <br />
        Consultando el log de actualizaciones para el API 'MyAPI'...
        <br />- Commit ID: 1a2b3c4d, Mensaje: "Actualización de la
        documentación", Autor: usuario1, Fecha: 2024-05-01
        <br />- Commit ID: 5e6f7g8h, Mensaje: "Corrección de errores en el
        endpoint", Autor: usuario2, Fecha: 2024-04-30
        <br />- Commit ID: 9i8j7k6l, Mensaje: "Añadida nueva funcionalidad de
        búsqueda", Autor: usuario3, Fecha: 2024-04-29
        <br />
        <br />
        Notas:
        <br /> Asegúrate de estar ubicado en el directorio correcto antes de
        ejecutar el comando log.
        <br /> La información devuelta incluye el ID del commit, el mensaje
        asociado, el autor y la fecha de la actualización.
        <br /> Este comando es útil para revisar el historial de cambios y
        actualizaciones realizadas en el API virtual.
      </code>
    </pre>
    <h2 id="checkout">checkout</h2>

    <pre className="">
      <code className="">
        Descripción: Descarga los archivos correspondientes a un commit
        específico del repositorio del API virtual.
        <br />
        Uso: 101OBeXcli checkout <br />
        Parámetros:
        <br /> commit_id: Identificador único del commit del que se desea
        obtener el código.
        <br />
        Instrucciones:
        <br /> Ejecuta el comando 101OBeXcli checkout seguido del ID del commit
        del que se desea obtener el código.
        <br /> Este comando descargará los archivos correspondientes al commit
        especificado del repositorio del API virtual.
        <br />
        Ejemplo:
        <br />
        <br />
        bash
        <br />
        <br />$ 101OBeXcli checkout 1a2b3c4d
        <br />
        Descargando archivos correspondientes al commit '1a2b3c4d' del
        repositorio del API virtual...
        <br />
        Archivos descargados con éxito. Código en el estado del commit
        '1a2b3c4d' obtenido.
        <br />
        <br />
        **Notas:**
        <br /> - Asegúrate de tener el ID del commit correcto antes de ejecutar
        el comando checkout.
        <br /> - Este comando es útil para obtener el código en el estado en que
        se subió en un commit específico, lo que puede ser útil para revisar
        versiones anteriores del código o para solucionar problemas específicos.
      </code>
    </pre>
    <h2 id="publish">publish</h2>

    <pre className="">
      <code className="">
        Descripción: Solicita la publicación del API virtual en el ambiente
        especificado.
        <br />
        Uso: 101OBeXcli publish <br />
        Parámetros:
        <br /> api: Nombre o identificador único del API que se va a publicar.
        <br /> ambient: Ambiente en el que se va a publicar el API. Puede ser
        "local", "staging" o "live", aunque puede haber más ambientes definidos
        por el API Publisher.
        <br />
        Instrucciones:
        <br /> Ejecuta el comando 101OBeXcli publish seguido del nombre o ID del
        API y el nombre del ambiente en el que se va a publicar.
        <br /> Este comando solicitará la publicación del API en el ambiente
        especificado.
        <br />
        Ejemplo:
        <br />
        <br />
        bash
        <br />
        <br />$ 101OBeXcli publish MyAPI staging
        <br />
        Solicitando la publicación del API 'MyAPI' en el ambiente de staging...
        <br />
        La publicación del API 'MyAPI' en el ambiente de staging ha sido
        solicitada con éxito.
      </code>
    </pre>
    <p>
      <strong>Notas:</strong>
    </p>

    <pre className="">
      <code className="">
        - Asegúrate de tener los permisos necesarios para solicitar la
        publicación del API en el ambiente especificado.
        <br />- La disponibilidad del API en el ambiente solicitado dependerá
        del proceso de despliegue configurado para dicho ambiente.
        <br />- Este comando es útil para gestionar el ciclo de vida del API,
        publicándolo en diferentes ambientes según sea necesario.
      </code>
    </pre>
  </Fragment>
);
   }
export default Cli;
