import React, { Fragment } from 'react';

const SOURCE_CONTROL_IntroductionToGit = () => (
  <Fragment>
    <h1 id="introduction-to-git-in-avap™-dev-studio">
      Introduction to Git in AVAP™ Dev Studio
    </h1>
    <p>
      Want to easily manage your source code and collaborate with others? Git
      and GitHub are the tools you need! And with AVAP<sup>TM</sup> Dev Studio,
      you can set up and use them in a snap. Even if you&#39;re a beginner, AVAP
      <sup>TM</sup> Dev Studio&#39;s user-friendly interface guides you through
      common Git actions like pushing and pulling code, creating and merging
      branches, and committing code changes. And if you&#39;re a pro, you&#39;ll
      love the ability to perform Git actions directly within the editor, saving
      you time and effort compared to using the Git command line. Plus, the
      seamless workflow between AVAP<sup>TM</sup> Dev Studio and Git means you
      can stay in your editor and get more done.
    </p>
    <h2 id="set-up-git-in-avap™-dev-studio">Set up Git in AVAP™ Dev Studio</h2>
    <p>
      To use Git and GitHub in AVAP<sup>TM</sup> Dev Studio, first make sure you
      have Git installed on your computer. If Git is missing, the{' '}
      <strong>Source Control</strong> view shows instructions on how to install
      it. Make sure to restart AVAP<sup>TM</sup> Dev Studio afterwards.
    </p>
    <p>
      Additionally you can sign into AVAP<sup>TM</sup> Dev Studio with your
      GitHub account in the <strong>Accounts</strong> menu in the lower right of
      the Activity bar to enables additional features like Settings Sync, but
      also cloning and publishing repositories from GitHub.
    </p>
    <h2 id="open-a-git-repository">Open a Git repository</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio provides several ways to get started in a Git
      repository, from local to remote cloud-powered environments like GitHub
      Codespaces.
    </p>
    <h3 id="clone-a-repository-locally">Clone a repository locally</h3>
    <p>
      To clone a repository from GitHub, execute the <strong>Git: Clone</strong>{' '}
      command or select the <strong>Clone Repository</strong> button in the{' '}
      <strong>Source Control</strong> view. If you clone from GitHub, AVAP
      <sup>TM</sup> Dev Studio will prompt you to authenticate with GitHub. This
      allows you to search all available repositories and clone private
      repositories. For other Git providers, enter the repository URL and select{' '}
      <strong>Clone</strong> and pick a folder. AVAP<sup>TM</sup> Dev Studio
      opens the folder once the repository is cloned on your local machine.
    </p>
    <h3 id="initialize-a-repository-in-a-local-folder">
      Initialize a repository in a local folder
    </h3>
    <p>
      To initialize a new local repository, pick an existing or new folder on
      your computer and open it in AVAP<sup>TM</sup> Dev Studio. In the{' '}
      <strong>Source Control</strong> view, select the{' '}
      <strong>Initialize Repository</strong> button. This creates a new Git
      repository in the current folder, allowing you to start tracking code
      changes.
    </p>
    <h4 id="publish-local-repository-to-github">
      Publish local repository to GitHub
    </h4>
    <p>
      Once you have a local Git repository set up, you can publish it to GitHub.
      This will create a new repository on your GitHub account, and push your
      local code to the remote repository. Having your source code on a remote
      repository is a great way to back up your code, collaborate with others,
      and automate your workflow with GitHub Actions.
    </p>
    <p>
      Use the <strong>Publish to GitHub</strong> command button in the{' '}
      <strong>Source Control</strong> view. You can then choose a name and
      description for the repository, and whether to make it public or private.
      Once the repository has been created, AVAP<sup>TM</sup> Dev Studio will
      push your local code to the remote repository. Your code is now backed up
      on GitHub, and you can start collaborating with others with commits and
      pull requests.
    </p>
    <h3 id="open-a-github-repository-in-a-codespace">
      Open a GitHub repository in a codespace
    </h3>
    <p>
      GitHub Codespaces let you open a GitHub repository in a full configured
      cloud-based development environment, allowing you to develop in a browser
      without having to install any software on your local computer. GitHub
      Codespaces allows free usage for individuals, which makes it easy to get
      started working on open source projects.
    </p>
    <p>
      Install the GitHub Codespaces extension into AVAP<sup>TM</sup> Dev Studio
      and sign in with GitHub. Run the{' '}
      <strong>Codespaces: Create New Codespace</strong> command and pick the
      repository and branch you want to open. The new codespace will open in a
      new window.
    </p>
    <p>
      Alternatively, you can also start with a template from the GitHub&#39;s
      Codespaces site. If you already have a codespace open in your browser, you
      can open it in your AVAP<sup>TM</sup> Dev Studio Desktop by running the{' '}
      <strong>
        Codespaces: Open in AVAP<sup>TM</sup> Dev Studio Desktop
      </strong>{' '}
      command. You can learn more about GitHub Codespaces, including
      customization such as forwarding ports, in the Developing in a codespace
      documentation.
    </p>
    <h3 id="open-a-github-repository-remotely">
      Open a GitHub repository remotely
    </h3>
    <p>
      AVAP<sup>TM</sup> Dev Studio&#39;s remote repository support allows you to
      browse and edit a GitHub repository without cloning it to your local
      computer. This is useful for quickly making changes to a remote repository
      without having to clone the entire codebase to your machine.
    </p>
    <p>
      First install the GitHub Repositories extension. Run the command{' '}
      <strong>Remote Repositories: Open Remote Repository...</strong> or use the{' '}
      <strong>Open Remote Repository</strong> button the Explorer view. Search
      and select the GitHub repository that you want to open.
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> If you need to execute code or run terminal
        commands, you can seamlessly switch from a remote repository to a
        codespace with the command <strong>Continue Working on</strong>.
      </p>
    </blockquote>
    <h2 id="staging-and-committing-code-changes">
      Staging and committing code changes
    </h2>
    <p>
      Once you have a Git repository set up, you can start tracking code changes
      by staging and committing your newly created and edited code.
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> Commit your changes early and often. This will
        make it easier to revert back to previous versions of your code if
        needed.
      </p>
    </blockquote>
    <p>
      To stage a file, select the <strong>+</strong> &#40;plus&#41; icon next to
      the file in the <strong>Source Control</strong> view. This will add the
      file to the <strong>Staged Changes</strong> section, indicating that it
      will be included in the next commit. Staged changes can also be discarded
      by selecting the <strong>âˆ’</strong> &#40;minus&#41; icon next to the
      file.
    </p>
    <p>
      To commit your staged changes, type a commit message in the upper text box
      and select the <strong>Commit</strong> button. This saves your changes to
      the local Git repository, allowing you to revert to previous versions of
      your code if needed. You can navigate through and review all local file
      changes and commits in the <strong>Timeline</strong> view available in the
      bottom of the Explorer.
    </p>
    <h2 id="pushing-and-pulling-remote-changes">
      Pushing and pulling remote changes
    </h2>
    <p>
      Once you have made commits to your local Git repository, you can push them
      to the remote repository. The <strong>Sync Changes</strong> button
      indicates how many commits are going to be pushed and pulled. Selecting
      the <strong>Sync Changes</strong> button downloads &#40;pull&#41; any new
      remote commits and uploads &#40;push&#41; new local commits to the remote
      repository.
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> You can enable the <strong>Git: Autofetch</strong>{' '}
        setting to always get an up-to-date remote commit indicator.
      </p>
    </blockquote>
    <p>
      Push and pull can also be performed individually by using their respective
      commands.
    </p>
    <h2 id="using-branches">Using branches</h2>
    <p>
      In Git, branches allow you to work on multiple versions of your codebase
      simultaneously. This is useful for experimenting with new features or
      making large code changes without affecting the main codebase.
    </p>
    <p>
      The branch indicator in the Status bar shows the current branch and lets
      you switch to new and existing branches. To create a new branch, select
      the branch indicator and choose to create it from the current branch or
      another local one. Type a name for the new branch, and confirm. AVAP
      <sup>TM</sup> Dev Studio creates a new branch and switches to it, allowing
      you to make changes to your code without affecting the main branch.
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> If you use the GitHub Pull Requests and Issues
        extension, you can create a branch directly from an issue, which gets
        you started working in a new local branch and automatically prefills the
        pull request for you.
      </p>
    </blockquote>
    <p>
      To push the branch to the remote repository, select{' '}
      <strong>Publish Branch</strong> in the <strong>Source Control</strong>{' '}
      view. This will create a new branch on the remote repository, allowing you
      to collaborate with others in that branch.
    </p>
    <h3 id="creating-and-reviewing-github-pull-requests">
      Creating and reviewing GitHub pull requests
    </h3>
    <p>
      In Git and GitHub, pull requests &#40;PRs&#41; are a way for collaborators
      to review and merge code changes from separate branches into the main
      branch. This allows teams to review and approve code changes before they
      are incorporated into the main codebase, ensuring that only high-quality
      changes are merged.
    </p>
    <p>
      To use pull requests in AVAP<sup>TM</sup> Dev Studio, you need to install
      the GitHub Pull Requests and Issues extension. This extension adds PR and
      issue tracking functionality to AVAP<sup>TM</sup> Dev Studio, allowing you
      to create, review, and merge PRs from within the editor.
    </p>
    <p>
      To create a PR, make sure you are on a separate branch from the main
      branch, and push your code changes to the remote repository. In the{' '}
      <strong>Source Control</strong> view, select the{' '}
      <strong>Create Pull Request</strong> button. This will open the PR
      creation form, where you can enter a title and description for the PR, and
      choose which branch to merge the changes into. Select{' '}
      <strong>Create</strong> to create the PR.
    </p>
    <p>
      To review a PR, select the <strong>Review Pull Request</strong> button in
      the <strong>Source Control</strong> view, and select the PR you want to
      review. This will open the PR in a new editor window, where you can review
      the code changes and leave comments. Once you are satisfied with the code
      changes, you can select the <strong>Merge</strong> button to merge the PR
      into the targeted branch.
    </p>
    <p>
      Learn more about pull requests in AVAP<sup>TM</sup> Dev Studio&#39;s
      GitHub documentation
    </p>
    <h2 id="using-git-in-the-built-in-terminal">
      Using Git in the built-in terminal
    </h2>
    <p>
      As all Git state is kept in the local repository, you can easily switch
      between AVAP<sup>TM</sup> Dev Studio&#39;s UI, the built-in
      terminal/docs/terminal/basics, or external tools like GitHub Desktop. You
      can also set up AVAP<sup>TM</sup> Dev Studio as your default Git editor,
      allowing you to use AVAP<sup>TM</sup> Dev Studio to edit commit messages
      and other Git-related files.
    </p>
    <h3 id="git-bash-on-windows">Git Bash on Windows</h3>
    <p>
      Git Bash is a popular shell environment for Windows that provides a
      Unix-like command-line interface for working with Git and other
      command-line tools. AVAP<sup>TM</sup> Dev Studio&#39;s integrated terminal
      supports Git Bash as a shell, allowing you to seamlessly integrate Git
      Bash into your development workflow. Installing Git on your Windows
      machine will also install Git Bash, if it wasn&#39;t deselected during the
      installation steps.
    </p>
    <p>
      Start by opening <strong>View</strong> &gt; <strong>Terminal</strong>{' '}
      &#40;Ctrl+`&#41;. Click on the dropdown arrow next to the + icon in the
      terminal panel to pick a new shell to open. If Git Bash is installed, it
      will be shown in the list. You can toggle between different terminals and
      shells in the Terminal sidebar. With Git Bash configured in AVAP
      <sup>TM</sup> Dev Studio, you can now use all of your favorite Git
      commands directly from the terminal in your code editor.
    </p>
    <p>
      If you want to set Git Bash as your default shell, open the Terminal
      dropdown &#40;next to the + icon&#41; and select{' '}
      <strong>Select Default Profile</strong>. This will open a list of
      available shells, including Git Bash. Selecting Git Bash will set it as
      your default shell, and all future terminals will be opened with Git Bash.
      More advanced tips are available in the terminal
      documentation]&#40;/docs/terminal/basics&#41;.
    </p>
  </Fragment>
);
export default SOURCE_CONTROL_IntroductionToGit;
