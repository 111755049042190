import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';

const Datos_cuenta_EN = () => 
 {
  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{ "status": <boolean>, 
      "data": { "is_certified": <boolean>, 
      "certification_data": { "codtran": <string> }, 
      "blacklist": <boolean>, 
      "affiliate_id": <integer>, 
      "blocked": <boolean>, 
      "active": <boolean>, 
      "id": <integer>, 
      "affiliate_alias": <string>, 
      "state": <string>, 
      "pending": <boolean>, 
      "currency": <string>, 
      "prefix": <string>, 
      "phone": <string>, 
      "origin_of_funds": [ { <Origen_de_Fondos> } ], 
      "user": { "date_of_birth": <string>, 
      "ocupation": <integer>, 
      "nick": <string>, 
      "other_data": {}, 
      "e_mail": <string>, 
      "surname2": <string>, 
      "gender": <integer>, 
      "alias": <string>, 
      "surname1": <string>, 
      "profile_id": <integer>, 
      "profile_name": <string>, 
      "name": <string>, 
      "language_id": <integer>, 
      "nationality": <string>, 
      "state": <string>, 
      "id": <integer> } }, 
      "elapsed": <float>}`,
      copied: false
    },
    answe_ko1: {
      title:"Answer ok",
      type: "json",
      code: `{ "status": <boolean>, 
      "level": <string>, 
      "message": <string>, 
      "error": <string> }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: `import requests url = "URL_BASE/ws/admin.py/datoscuenta?country_code=MX&user_id=4532&session_id=406-dwr5sTs_m29rnbzw9_miJQ==&phone=7229063245" 
      payload = {} files = {} headers= {} response = requests.request("GET", url, headers=headers, data = payload, files = files) 
      print(response.text.encode('utf8'))`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/admin.py/datoscuenta?country_code=MX&user_id=4532&session_id=406-dwr5sTs_m29rnbzw9_miJQ==&phone=7229063245" 
        payload = {} files = {} headers= {} response = requests.request("GET", url, headers=headers, data = payload, files = files) 
        print(response.text.encode('utf8'))`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: ` var request = require('request');
            var options = {
            'method': 'GET',
            'url': 'URL_BASE/ws/admin.py/datoscuenta?country_code=MX&user_id=4532&session_id=406-dwr5sTs_m29rnbzw9_miJQ==&phone=7229063245',
            'headers': {
            },
            formData: {}
            };
            request(options, function (error, response) { 
            if (error) throw new Error(error);
            console.log(response.body);
            });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: `var formdata = new FormData();
            var requestOptions = {
            method: 'GET',
            body: formdata,
            redirect: 'follow'
            };
            fetch("URL_BASE/ws/admin.py/datoscuenta?country_code=MX&user_id=4532&session_id=406-dwr5sTs_m29rnbzw9_miJQ==&phone=7229063245", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: `curl --location --request GET 'URL_BASE/ws/admin.py/datoscuenta?country_code=MX&user_id=4532&session_id=406-dwr5sTs_m29rnbzw9_miJQ%3D%3D&phone=7229063245'`
      }
    ]
  }
  return(
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
      <h1 id="account-data">Account Data</h1>
      <p>This service is used to obtain the data of a user.</p>
      <p>
        <span className="label get">GET:</span>
        <span> </span> <code>URL_BASE + /ws/admin.py/datoscuenta</code>
      </p>
      <h2 id="receives">Receives:</h2>
      <p>
        All parameters are sent in the querystring of the call, so a percentage
        encoding for URI must be applied &#40;aka URL encoding&#41;.
      </p>
      <table>
        <thead>
          <tr>
            <th align="left">Parameter</th>
            <th align="center">Format</th>
            <th align="center">Obligatory</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="left">country_code</td>
            <td align="center">
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                ISO 3166 alpha-2
              </a>
            </td>
            <td align="center">Yes</td>
            <td align="center">Country code in which the user resides</td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">user_id</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">
              Identifier of the user making the request &#40;Admin or
              Affiliate&#41;
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">session_id</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send signature to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Obtained by identifying the user with ID user_id on the platform
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">signature</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send session_id to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Enables you to sign the request without having to log in
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">phone</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">
              Telephone number of the user from whom you require the information
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">send_wallets</td>
            <td align="center">Boolean</td>
            <td align="center">No</td>
            <td align="center">
              It allows you to select whether the user&#39;s source of funds is
              included in the response of the service
            </td>
            <td align="center">true</td>
          </tr>
        </tbody>
      </table>
      <h2 id="returns">Returns:</h2>
      <p>
        Depending on the result of the operation, this service can return two
        different JSON:
      </p>
      <h3 id="answer-json-ok">Answer JSON OK:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
        </div>
      <h2 id="where">Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;
        </li>
        <li>
          <code>data:</code> Object containing all user data.
        </li>
        <li>
          <code>is_certified:</code> Indicates whether the user is certified or
          not.
        </li>
        <li>
          <code>certification_data:</code> they are an object that contains the
          transaction code associated with the user&#39;s certification.
        </li>
        <li>
          <code>blacklist:</code> Indicates if the user is on the platform
          blacklist.
        </li>
        <li>
          <code>affiliate_id:</code> User&#39;s affiliate identifier.
        </li>
        <li>
          <code>blocked:</code> Indicates whether the user&#39;s account is locked
          or not.
        </li>
        <li>
          <code>active:</code> InIndicates whether the user&#39;s account is
          active or not.
        </li>
        <li>
          <code>id:</code> User account identifier.
        </li>
        <li>
          <code>affiliate_alias:</code> User&#39;s affiliate name.
        </li>
        <li>
          <code>state:</code> Name of the state the user is in.
        </li>
        <li>
          <code>pending:</code> Indicates whether the user is pending activation
          or not.
        </li>
        <li>
          <code>currency:</code> Indicates the abbreviation of the currency used
          by the user.
        </li>
        <li>
          <code>prefix:</code> Indicates the prefix of the user&#39;s phone.
        </li>
        <li>
          <code>phone:</code> User&#39;s phone number.
        </li>
        <li>
          <code>origin_of_funds:</code> List with the origin of the user&#39;s
          funds. View structure on the endpoint <code>origin_of_funds</code>.
        </li>
        <li>
          <code>user:</code> Object that contains the user&#39;s personal data.
        </li>
        <li>
          <code>date_of_birth:</code> Date of birth in format yyyy-mm-dd.
        </li>
        <li>
          <code>ocupation:</code> Identifier of the user&#39;s occupation.
        </li>
        <li>
          <code>nick:</code> User&#39;s nickname.
        </li>
        <li>
          <code>other_data:</code> Other user data.
        </li>
        <li>
          <code>e_mail:</code> Email address.
        </li>
        <li>
          <code>surname2:</code> User&#39;s maternal last name.
        </li>
        <li>
          <code>gender:</code> User gender identifier.
        </li>
        <li>
          <code>alias:</code> User name.
        </li>
        <li>
          <code>surname1:</code> User&#39;s paternal last name.
        </li>
        <li>
          <code>profile_id:</code> User profile identifier.
        </li>
        <li>
          <code>profile_name:</code> Name associated with the profile that the
          user has.
        </li>
        <li>
          <code>name:</code> User name.
        </li>
        <li>
          <code>language_id:</code> User language identifier.
        </li>
        <li>
          <code>nationality:</code> User nationality.
        </li>
        <li>
          <code>id:</code> User identifier.
        </li>
        <li>
          <code>elapsed:</code> Operation execution time.
        </li>
      </ul>
      <h3 id="answer-json-ko">Answer JSON KO:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
      <h2 id="where-1">Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li>
          <code>level:</code> Error importance level.
        </li>
        <li>
          <code>message:</code> Error message.
        </li>
        <li>
          <code>error:</code> Sole error code.
        </li>
      </ul>
      <h2 id="business-logic">Business logic:</h2>
      <p>
        In order to execute this service, it is necessary to do it from a user
        logged into the system who has an affiliate profile, and is the affiliate
        of the user from whom the data will be obtained. With this endpoint all
        the required user data is obtained.
      </p>
      </div>

      <div className='col-md-5'>
      <h2 id="example-requests">Example requests:</h2>
      <h3 id="python---requests">Python - Requests:</h3>
  
      <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
      
      </div>
      </div>
    </Fragment>
  );
 }
export default Datos_cuenta_EN;
