import React, { Fragment } from 'react';

const USER_GUIDE_Tasks = () => (
  <Fragment>
    <h1 id="integrate-with-external-tools-via-tasks">
      Integrate with External Tools via Tasks
    </h1>
    <p>
      Lots of tools exist to automate tasks like linting, building, packaging,
      testing, or deploying software systems. Examples include the TypeScript
      Compiler, linters like ESLint and TSLint as well as build systems like
      Make, Ant, Gulp, Jake, Rake, and MSBuild.
    </p>
    <p>
      These tools are mostly run from the command line and automate jobs inside
      and outside the inner software development loop &#40;edit, compile, test,
      and debug&#41;. Given their importance in the development life cycle, it
      is helpful to be able to run tools and analyze their results from within
      AVAP<sup>TM</sup> Dev Studio. Tasks in AVAP<sup>TM</sup> Dev Studio can be
      configured to run scripts and start processes so that many of these
      existing tools can be used from within AVAP<sup>TM</sup> Dev Studio
      without having to enter a command line or write new code. Workspace or
      folder specific tasks are configured from the <code>tasks.json</code> file
      in the <code>.vscode</code> folder for a workspace.
    </p>
    <p>
      Extensions can also contribute tasks using a Task Provider, and these
      contributed tasks can add workspace-specific configurations defined in the{' '}
      <code>tasks.json</code> file.
    </p>
    <blockquote>
      <p>
        <strong>Note:</strong> Task support is only available when working on a
        workspace folder. It is not available when editing single files.
      </p>
    </blockquote>
    <h2 id="typescript-hello-world">TypeScript Hello World</h2>
    <p>
      Let&#39;s start with a simple &quot;Hello World&quot; TypeScript program
      that we want to compile to JavaScript.
    </p>
    <p>
      Create an empty folder &quot;mytask&quot;, generate a{' '}
      <code>tsconfig.json</code> file and start AVAP<sup>TM</sup> Dev Studio
      from that folder.
    </p>

    <pre className="">
      <code className="">
        mkdir mytask
        <br />
        cd mytask
        <br />
        tsc --init
        <br />
        code .
      </code>
    </pre>
    <p>Now create a HelloWorld.ts file with the following content</p>

    <pre className="">
      <code className="">
        function sayHello&#40;name: string&#41;: void &#123;
        <br />` `console.log&#40;`Hello $&#123;name&#125;!`&#41;;
        <br />
        &#125;
        <br />
        <br />
        sayHello&#40;'Dave'&#41;;
      </code>
    </pre>
    <p>
      Pressing <code>Ctrl+Shift+B</code> or running{' '}
      <strong>Run Build Task</strong> from the global <strong>Terminal</strong>{' '}
      menu show the following picker:
    </p>
    <p>
      The first entry executes the TypeScript compiler and translates the
      TypeScript file to a JavaScript file. When the compiler has finished,
      there should be a <code>HelloWorld.js</code> file. The second entry starts
      the TypeScript compiler in watch mode. Every save to the{' '}
      <code>HelloWorld.ts</code> file will regenerate the{' '}
      <code>HelloWorld.js</code> file.
    </p>
    <p>
      You can also define the TypeScript build or watch task as the default
      build task so that it is executed directly when triggering{' '}
      <strong>Run Build Task</strong> &#40;Ctrl+Shift+B&#41;. To do so, select{' '}
      <strong>Configure Default Build Task</strong> from the global{' '}
      <strong>Terminal</strong> menu. This shows you a picker with the available
      build tasks. Select <strong>tsc: build</strong> or{' '}
      <strong>tsc: watch</strong> and AVAP<sup>TM</sup> Dev Studio will generate
      a <code>tasks.json</code> file. The one shown below makes the{' '}
      <strong>tsc: build</strong> task the default build task:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `// See &#40;link&#41;
        <br />` `// for the documentation about the tasks.json format
        <br />` `"version": "2.0.0",
        <br />` `"tasks": [<br />` `&#123;
        <br />` `"type": "typescript",
        <br />` `"tsconfig": "tsconfig.json",
        <br />` `"problemMatcher": ["$tsc"],
        <br />` `"group": &#123;
        <br />` `"kind": "build",
        <br />` `"isDefault": true
        <br />` `&#125;
        <br />` `&#125;
        <br />` `]
        <br />
        &#125;
      </code>
    </pre>
    <p>
      The <code>tasks.json</code> example above does not define a new task. It
      annotates the <strong>tsc: build</strong> tasks contributed by AVAP
      <sup>TM</sup> Dev Studio&#39;s TypeScript extension to be the default
      build task. You can now execute the TypeScript compiler by pressing{' '}
      <code>Ctrl+Shift+B</code>.
    </p>
    <h2 id="task-auto-detection">Task auto-detection</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio currently auto-detects tasks for the
      following systems: Gulp, Grunt, Jake, and npm. We are working with the
      corresponding extension authors to add support for Maven and the C# dotnet
      command as well. If you develop a JavaScript application using Node.js as
      the runtime, you usually have a <code>package.json</code> file describing
      your dependencies and the scripts to run. If you have cloned the
      eslint-starter example, then executing <strong>Run Tasks</strong> from the
      global menu shows the following list:
    </p>
    <p>
      If you have not already done so, install the necessary npm modules by
      running npm install. Now open the <code>server.js</code> file and add a
      semicolon to the end of a statement &#40;note the ESLint starter assumes
      statements without a semicolon&#41; and execute the{' '}
      <strong>Run Tasks</strong> again. This time select the{' '}
      <strong>npm: lint</strong> task. When prompted for the problem matcher to
      use, select <strong>ESLint stylish</strong>
    </p>
    <p>
      Executing the task produces one error, shown in the{' '}
      <strong>Problems</strong> view:
    </p>
    <p>
      In addition, AVAP<sup>TM</sup> Dev Studio created a tasks.json file with
      the following content:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `// See &#40;link&#41;
        <br />` `// for the documentation about the tasks.json format
        <br />` `"version": "2.0.0",
        <br />` `"tasks": [<br />` `&#123;
        <br />` `"type": "npm",
        <br />` `"script": "lint",
        <br />` `"problemMatcher": ["$eslint-stylish"]
        <br />` `&#125;
        <br />` `]
        <br />
        &#125;
      </code>
    </pre>
    <p>
      This instructs AVAP<sup>TM</sup> Dev Studio to scan the output of the{' '}
      <strong>npm lint</strong> script for problems using the ESLint stylish
      format.
    </p>
    <p>
      For Gulp, Grunt, and Jake, the task auto-detection works the same. Below
      is an example of the tasks detected for the AVAP<sup>TM</sup> Dev
      Studio-node-debug extension.
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> You can run your task through{' '}
        <strong>Quick Open</strong> &#40;Ctrl+P&#41; by typing &#39;task&#39;,
        Space and the command name. In this case, &#39;task lint&#39;.
      </p>
    </blockquote>
    <p>Task auto detection can be disabled using the following settings:</p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"typescript.tsc.autoDetect": "off",
        <br />` `"grunt.autoDetect": "off",
        <br />` `"jake.autoDetect": "off",
        <br />` `"gulp.autoDetect": "off",
        <br />` `"npm.autoDetect": "off"
        <br />
        &#125;
      </code>
    </pre>
    <h2 id="custom-tasks">Custom tasks</h2>
    <p>
      Not all tasks or scripts can be auto-detected in your workspace. Sometimes
      it is necessary to define your own custom tasks. Assume you have a script
      to run your tests in order to set up some environment correctly. The
      script is stored in a script folder inside your workspace and named{' '}
      <code>test.sh</code> for Linux and macOS and <code>test.cmd</code> for
      Windows. Run <strong>Configure Tasks</strong> from the global{' '}
      <strong>Terminal</strong> menu and select the{' '}
      <strong>Create tasks.json file from template</strong> entry. This opens
      the following picker:
    </p>
    <blockquote>
      <p>
        <strong>Note:</strong> If you don&#39;t see the list of task runner
        templates, you may already have a <code>tasks.json</code> file in your
        folder and its contents will be open in the editor. Close the file and
        either delete or rename it for this example.
      </p>
    </blockquote>
    <p>
      We are working on more auto-detection support, so this list will get
      smaller and smaller in the future. Since we want to write our own custom
      task, select <strong>Others</strong> from the list. This opens the{' '}
      <code>tasks.json</code> file with a task skeleton. Replace the contents
      with the following:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `// See &#40;link&#41;
        <br />` `// for the documentation about the tasks.json format
        <br />` `"version": "2.0.0",
        <br />` `"tasks": [<br />` `&#123;
        <br />` `"label": "Run tests",
        <br />` `"type": "shell",
        <br />` `"command": "./scripts/test.sh",
        <br />` `"windows": &#123;
        <br />` `"command": ".\\scripts\\test.cmd"
        <br />` `&#125;,
        <br />` `"group": "test",
        <br />` `"presentation": &#123;
        <br />` `"reveal": "always",
        <br />` `"panel": "new"
        <br />` `&#125;
        <br />` `&#125;
        <br />` `]
        <br />
        &#125;
      </code>
    </pre>
    <p>The task&#39;s properties have the following semantic:</p>
    <ul>
      <li>
        <strong>label</strong>: The task&#39;s label used in the user interface.
      </li>
      <li>
        <strong>type</strong>: The task&#39;s type. For a custom task, this can
        either be <code>shell</code> or <code>process</code>. If{' '}
        <code>shell</code> is specified, the command is interpreted as a shell
        command &#40;for example: bash, cmd, or PowerShell&#41;. If{' '}
        <code>process</code> is specified, the command is interpreted as a
        process to execute.
      </li>
      <li>
        <strong>command</strong>: The actual command to execute.
      </li>
      <li>
        <strong>windows</strong>: Any Windows specific properties. Will be used
        instead of the default properties when the command is executed on the
        Windows operating system.
      </li>
      <li>
        <strong>group</strong>: Defines to which group the task belongs. In the
        example, it belongs to the <code>test</code> group. Tasks that belong to
        the test group can be executed by running <strong>Run Test Task</strong>{' '}
        from the <strong>Command Palette</strong>.
      </li>
      <li>
        <strong>presentation</strong>: Defines how the task output is handled in
        the user interface. In this example, the Integrated Terminal showing the
        output is <code>always</code> revealed and a <code>new</code> terminal
        is created on every task run.
      </li>
      <li>
        <strong>options</strong>: Override the defaults for <code>cwd</code>{' '}
        &#40;current working directory&#41;, <code>env</code> &#40;environment
        variables&#41;, or shell &#40;default shell&#41;. Options can be set per
        task but also globally or per platform. Environment variables configured
        here can only be referenced from within your task script or process and
        will not be resolved if they are part of your args, command, or other
        task attributes.
      </li>
      <li>
        <strong>runOptions</strong>: Defines when and how a task is run.
      </li>
    </ul>
    <p>
      You can see the full set of task properties and values with IntelliSense
      in your <code>tasks.json</code> file. Bring up suggestions with{' '}
      <strong>Trigger Suggest</strong> &#40;Ctrl+Space&#41; and read the
      descriptions on hover or with the <strong>Read More...</strong>{' '}
      &#40;&#39;i&#39;&#41; flyout.
    </p>
    <p>You can also review the tasks.json schema.</p>
    <p>
      Shell commands need special treatment when it comes to commands and
      arguments that contain spaces or other special characters like{' '}
      <code>$</code>. By default, the task system supports the following
      behavior:
    </p>
    <ul>
      <li>
        <p>
          If a single command is provided, the task system passes the command as
          is to the underlying shell. If the command needs quoting or escaping
          to function properly, the command needs to contain the proper quotes
          or escape characters. For example, to list the directory of a folder
          containing spaces in its name, the command executed in bash should
          look like this: <code>ls &#39;folder with spaces&#39;</code>.
        </p>
        <p> &#123;</p>

        <pre className="">
          <code className="">
            ` `"label": "dir",
            <br /> ` `"type": "shell",
            <br /> ` `"command": "dir 'folder with spaces'"
            <br /> &#125;
          </code>
        </pre>
      </li>
      <li>
        <p>
          If a command and arguments are provided, the task system will use
          single quotes if the command or arguments contain spaces. For{' '}
          <code>cmd.exe</code>, double quotes are used. A shell command like
          below will be executed in PowerShell as{' '}
          <code>dir &#39;folder with spaces&#39;</code>.
        </p>

        <pre className="">
          <code className="">
            &#123;
            <br /> ` `"label": "dir",
            <br /> ` `"type": "shell",
            <br /> ` `"command": "dir",
            <br /> ` `"args": ["folder with spaces"]
            <br /> &#125;
          </code>
        </pre>
      </li>
      <li>
        <p>
          If you want to control how the argument is quoted, the argument can be
          a literal specifying the value and a quoting style. The example below
          uses escaping instead of quoting for an argument with spaces.
        </p>

        <pre className="">
          <code className="">
            &#123;
            <br /> ` `"label": "dir",
            <br /> ` `"type": "shell",
            <br /> ` `"command": "dir",
            <br /> ` `"args": [<br /> ` `&#123;
            <br /> ` `"value": "folder with spaces",
            <br /> ` `"quoting": "escape"
            <br /> ` `&#125;
            <br /> ` `]
            <br /> &#125;
          </code>
        </pre>
      </li>
    </ul>
    <p>Besides escaping, the following values are supported:</p>
    <ul>
      <li>
        <strong>strong</strong>: Uses the shell&#39;s strong quoting mechanism,
        which suppresses all evaluations inside the string. Under PowerShell and
        for shells under Linux and macOS, single quotes are used
        &#40;&#39;&#41;. For cmd.exe, &quot; is used.
      </li>
      <li>
        <strong>weak</strong>: Uses the shell&#39;s weak quoting mechanism,
        which still evaluates expression inside the string &#40;for example,
        environment variables&#41;. Under PowerShell and for shells under Linux
        and macOS, double quotes are used &#40;&quot;&#41;. cmd.exe doesn&#39;t
        support weak quoting so AVAP<sup>TM</sup> Dev Studio uses &quot; as
        well.
      </li>
    </ul>
    <p>
      If the command itself contains spaces, AVAP<sup>TM</sup> Dev Studio will
      by default strong quote the command as well. As with arguments, the user
      can control the quoting of the command using the same literal style.
    </p>
    <p>
      There are more task properties to configure your workflow. You can use
      IntelliSense with Ctrl+Space to get an overview of the valid properties.
    </p>
    <p>!Tasks IntelliSense</p>
    <p>
      In addition to the global menu bar, task commands can be accessed using
      the <strong>Command Palette</strong> &#40;Ctrl+Shift+P&#41;. You can
      filter on &#39;task&#39; and can see the various task related commands.
    </p>
    <p>!Tasks in Command Palette</p>
    <h3 id="compound-tasks">Compound tasks</h3>
    <p>
      You can also compose tasks out of simpler tasks with the{' '}
      <code>dependsOn</code> property. For example, if you have a workspace with
      a client and server folder and both contain a build script, you can create
      a task that starts both build scripts in separate terminals. If you list
      more than one task in the <code>dependsOn</code> property, they are
      executed in parallel by default.
    </p>
    <p>
      The <code>tasks.json</code> file looks like this:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"version": "2.0.0",
        <br />` `"tasks": [<br />` `&#123;
        <br />` `"label": "Client Build",
        <br />` `"command": "gulp",
        <br />` `"args": ["build"],
        <br />` `"options": &#123;
        <br />` `"cwd": "$&#123;workspaceFolder&#125;/client"
        <br />` `&#125;
        <br />` `&#125;,
        <br />` `&#123;
        <br />` `"label": "Server Build",
        <br />` `"command": "gulp",
        <br />` `"args": ["build"],
        <br />` `"options": &#123;
        <br />` `"cwd": "$&#123;workspaceFolder&#125;/server"
        <br />` `&#125;
        <br />` `&#125;,
        <br />` `&#123;
        <br />` `"label": "Build",
        <br />` `"dependsOn": ["Client Build", "Server Build"]
        <br />` `&#125;
        <br />` `]
        <br />
        &#125;
      </code>
    </pre>
    <p>
      If you specify &quot;<code>dependsOrder</code>&quot;: &quot;
      <code>sequence</code>&quot;, then your task dependencies are executed in
      the order they are listed in <code>dependsOn</code>. Any background/watch
      tasks used in <code>dependsOn</code> with &quot;<code>dependsOrder</code>
      &quot;: &quot;<code>sequence</code>&quot; must have a problem matcher that
      tracks when they are &quot;done&quot;. The following task runs task Two,
      task Three, and then task One.
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"label": "One",
        <br />` `"type": "shell",
        <br />` `"command": "echo Hello ",
        <br />` `"dependsOrder": "sequence",
        <br />` `"dependsOn": ["Two", "Three"]
        <br />
        &#125;
      </code>
    </pre>
    <h3 id="user-level-tasks">User level tasks</h3>
    <p>
      You can create user level tasks that are not tied to a specific workspace
      or folder using the <strong>Tasks: Open User Tasks</strong> command. Only{' '}
      <code>shell</code> and <code>process</code> tasks can be used here since
      other task types require workspace information.
    </p>
    <h2 id="output-behavior">Output behavior</h2>
    <p>
      Sometimes you want to control how the Integrated Terminal panel behaves
      when running tasks. For instance, you may want to maximize editor space
      and only look at task output if you think there is a problem. The behavior
      of the terminal can be controlled using the presentation property of a
      task. It offers the following properties:
    </p>
    <ul>
      <li>
        <strong>reveal</strong>: Controls whether the Integrated Terminal panel
        is brought to front. Valid values are:{' '}
        <ul>
          <li>
            <code>always</code> - The panel is always brought to front. This is
            the default.
          </li>
          <li>
            <code>never</code>- The user must explicitly bring the terminal
            panel to the front using the <strong>View</strong> &gt;{' '}
            <strong>Terminal</strong> command &#40;Ctrl+`&#41;.
          </li>
          <li>
            <code>silent</code> - The terminal panel is brought to front only if
            the output is not scanned for errors and warnings.
          </li>
        </ul>
      </li>
      <li>
        <strong>revealProblems</strong>: Controls whether the Problems panel is
        revealed when running this task or not. Takes precedence over option{' '}
        <code>reveal</code>. Default is <code>never</code>.{' '}
        <ul>
          <li>
            <code>always</code> - Always reveals the Problems panel when this
            task is executed.
          </li>
          <li>
            <code>onProblem</code> - Only reveals the Problems panel if a
            problem is found.
          </li>
          <li>
            <code>never</code> - Never reveals the Problems panel when this task
            is executed.
          </li>
        </ul>
      </li>
      <li>
        <strong>focus</strong>: Controls whether the terminal is taking input
        focus or not. Default is <code>false</code>.
      </li>
      <li>
        <strong>echo</strong>: Controls whether the executed command is echoed
        in the terminal. Default is <code>true</code>.
      </li>
      <li>
        <strong>showReuseMessage</strong>: Controls whether to show the
        &quot;Terminal will be reused by tasks, press any key to close it&quot;
        message.
      </li>
      <li>
        <strong>panel</strong>: Controls whether the terminal instance is shared
        between task runs. Possible values are:{' '}
        <ul>
          <li>
            <code>shared</code> - The terminal is shared and the output of other
            task runs are added to the same terminal.
          </li>
          <li>
            <code>dedicated</code> - The terminal is dedicated to a specific
            task. If that task is executed again, the terminal is reused.
            However, the output of a different task is presented in a different
            terminal.
          </li>
          <li>
            <code>new</code> - Every execution of that task is using a new clean
            terminal.
          </li>
        </ul>
      </li>
      <li>
        <strong>clear</strong>: Controls whether the terminal is cleared before
        this task is run. Default is false.
      </li>
      <li>
        <strong>close</strong>: Controls whether the terminal the task runs in
        is closed when the task exits. Default is false.
      </li>
      <li>
        <strong>group</strong>: Controls whether the task is executed in a
        specific terminal group using split panes. Tasks in the same group
        &#40;specified by a string value&#41; will use split terminals to
        present instead of a new terminal panel.
      </li>
    </ul>
    <p>
      You can modify the terminal panel behavior for auto-detected tasks as
      well. For example, if you want to change the output behavior for the{' '}
      <strong>npm: run lint</strong> from the ESLint example from above, add the{' '}
      <code>presentation</code> property to it:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `// See &#40;link&#41;
        <br />` `// for the documentation about the tasks.json format
        <br />` `"version": "2.0.0",
        <br />` `"tasks": [<br />` `&#123;
        <br />` `"type": "npm",
        <br />` `"script": "lint",
        <br />` `"problemMatcher": ["$eslint-stylish"],
        <br />` `"presentation": &#123;
        <br />` `"reveal": "never"
        <br />` `&#125;
        <br />` `&#125;
        <br />` `]
        <br />
        &#125;
      </code>
    </pre>
    <p>
      You can also mix custom tasks with configurations for detected tasks. A{' '}
      <code>tasks.json</code> that configures the <strong>npm: run lint</strong>{' '}
      task and adds a custom <strong>Run Test</strong> tasks looks like this:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `// See &#40;link&#41;
        <br />` `// for the documentation about the tasks.json format
        <br />` `"version": "2.0.0",
        <br />` `"tasks": [<br />` `&#123;
        <br />` `"type": "npm",
        <br />` `"script": "lint",
        <br />` `"problemMatcher": ["$eslint-stylish"],
        <br />` `"presentation": &#123;
        <br />` `"reveal": "never"
        <br />` `&#125;
        <br />` `&#125;,
        <br />` `&#123;
        <br />` `"label": "Run tests",
        <br />` `"type": "shell",
        <br />` `"command": "./scripts/test.sh",
        <br />` `"windows": &#123;
        <br />` `"command": ".\\scripts\\test.cmd"
        <br />` `&#125;,
        <br />` `"group": "test",
        <br />` `"presentation": &#123;
        <br />` `"reveal": "always",
        <br />` `"panel": "new"
        <br />` `&#125;
        <br />` `&#125;
        <br />` `]
        <br />
        &#125;
      </code>
    </pre>
    <h2 id="run-behavior">Run behavior</h2>
    <p>
      You can specify a task&#39;s run behaviors using the{' '}
      <code>runOptions</code> property:
    </p>
    <ul>
      <li>
        <strong>reevaluateOnRerun</strong>: Controls how variables are evaluated
        when a task is executed through the <strong>Rerun Last Task</strong>{' '}
        command. The default is <code>true</code>, meaning that variables will
        be reevaluated when a task is rerun. When set to <code>false</code> the
        resolved variable values from the previous run of the task will be used.
      </li>
      <li>
        <strong>runOn</strong>: Specifies when a task is run.{' '}
        <ul>
          <li>
            <code>default</code> - The task will only be run when executed
            through the <strong>Run Task</strong> command.
          </li>
          <li>
            <code>folderOpen</code> - The task will be run when the containing
            folder is opened. The first time you open a folder that contains a
            task with folderOpen, you will be asked if you want to allow tasks
            to run automatically in that folder. You can change your decision
            later using the <strong>Manage Automatic Tasks</strong> command and
            selecting between <strong>Allow Automatic Tasks</strong> and{' '}
            <strong>Disallow Automatic Tasks</strong>.
          </li>
        </ul>
      </li>
    </ul>
    <h2 id="customizing-auto-detected-task">Customizing auto-detected task</h2>
    <p>
      As mentioned above, you can customize auto-detected tasks in the{' '}
      <code>tasks.json</code> file. You usually do so to modify presentation
      properties or to attach a problem matcher to scan the task&#39;s output
      for errors and warnings. You can customize a task directly from the{' '}
      <strong>Run Task</strong> list by pressing the gear icon to the right to
      insert the corresponding task reference into the <code>tasks.json</code>{' '}
      file. Assume you have the following Gulp file to lint JavaScript files
      using ESLint &#40;the file is taken from &#40;Link&#41;&#41;:
    </p>

    <pre className="">
      <code className="">
        const gulp = require&#40;'gulp'&#41;;
        <br />
        const eslint = require&#40;'gulp-eslint'&#41;;
        <br />
        <br />
        gulp.task&#40;'lint', &#40;&#41; =&gt; &#123;
        <br />` `// ESLint ignores files with "node\_modules" paths.
        <br />` `// So, it's best to have gulp ignore the directory as well.
        <br />` `// Also, Be sure to return the stream from the task;
        <br />` `// Otherwise, the task may end before the stream has finished.
        <br />` `return &#40;
        <br />` `gulp
        <br />
        .src&#40;['\*\*/\*.js', '!node\_modules/\*\*']&#41;
        <br />` `// eslint&#40;&#41; attaches the lint output to the "eslint"
        property
        <br />` `// of the file object so it can be used by other modules.
        <br />
        .pipe&#40;eslint&#40;&#41;&#41;
        <br />` `// eslint.format&#40;&#41; outputs the lint results to the
        console.
        <br />` `// Alternatively use eslint.formatEach&#40;&#41; &#40;see
        Docs&#41;.
        <br />
        .pipe&#40;eslint.format&#40;&#41;&#41;
        <br />` `// To have the process exit with an error code &#40;1&#41; on
        <br />` `// lint error, return the stream and pipe to failAfterError
        last.
        <br />
        .pipe&#40;eslint.failAfterError&#40;&#41;&#41;
        <br />` `&#41;;
        <br />
        &#125;&#41;;
        <br />
        gulp.task&#40;'default', ['lint'], function&#40;&#41; &#123;
        <br />` `// This will only run if the lint task is successful...
        <br />
        &#125;&#41;;
      </code>
    </pre>
    <p>
      Executing <strong>Run Task</strong> from the global{' '}
      <strong>Terminal</strong> menu will show the following picker:
    </p>
    <p>
      Press the gear icon. This will create the following{' '}
      <code>tasks.json</code> file:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `// See &#40;link&#41;
        <br />` `// for the documentation about the tasks.json format
        <br />` `"version": "2.0.0",
        <br />` `"tasks": [<br />` `&#123;
        <br />` `"type": "gulp",
        <br />` `"task": "default",
        <br />` `"problemMatcher": []
        <br />` `&#125;
        <br />` `]
        <br />
        &#125;
      </code>
    </pre>
    <p>
      Usually you would now add a problem matcher &#40;in this case{' '}
      <code>$eslint-stylish</code>&#41; or modify the presentation settings.
    </p>
    <h2 id="processing-task-output-with-problem-matchers">
      Processing task output with problem matchers
    </h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio can process the output from a task with a
      problem matcher. Problem matchers scan the task output text for known
      warning or error strings, and report these inline in the editor and in the
      Problems panel. AVAP<sup>TM</sup> Dev Studio ships with several problem
      matchers &#39;in-the-box&#39;:
    </p>
    <ul>
      <li>
        <strong>TypeScript</strong>: <code>$tsc</code>assumes that file names in
        the output are relative to the opened folder.
      </li>
      <li>
        <strong>TypeScript Watch</strong>: <code>$tsc</code>-watch matches
        problems reported from the tsc compiler when executed in watch mode.
      </li>
      <li>
        <strong>JSHint</strong>: <code>$jshint</code>assumes that file names are
        reported as an absolute path.
      </li>
      <li>
        <strong>JSHint Stylish</strong>: <code>$jshint-stylish</code> assumes
        that file names are reported as an absolute path.
      </li>
      <li>
        <strong>ESLint Compact</strong>: <code>$eslint-compact</code> assumes
        that file names in the output are relative to the opened folder.
      </li>
      <li>
        <strong>ESLint Stylish</strong>: <code>$eslint-stylish</code> assumes
        that file names in the output are relative to the opened folder.
      </li>
      <li>
        <strong>Go</strong>: <code>$go</code> matches problems reported from the
        go compiler. Assumes that file names are relative to the opened folder.
      </li>
      <li>
        <strong>CSharp and VB Compiler</strong>: <code>$mscompile</code> assumes
        that file names are reported as an absolute path.
      </li>
      <li>
        <strong>Lessc compiler</strong>: <code>$lessc</code> assumes that file
        names are reported as absolute path.
      </li>
      <li>
        <strong>Node Sass compiler</strong>: <code>$node-sass</code> assumes
        that file names are reported as an absolute path.
      </li>
    </ul>
    <p>
      You can also create your own problem matcher, which we&#39;ll discuss in a
      later section.
    </p>
    <h2 id="binding-keyboard-shortcuts-to-tasks">
      Binding keyboard shortcuts to tasks
    </h2>
    <p>
      If you need to run a task frequently, you can define a keyboard shortcut
      for the task.
    </p>
    <p>
      For example, to bind <code>Ctrl+H</code> to the <strong>Run tests</strong>{' '}
      task from above, add the following to your <code>keybindings.json</code>{' '}
      file:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"key": "ctrl+h",
        <br />` `"command": "workbench.action.tasks.runTask",
        <br />` `"args": "Run tests"
        <br />
        &#125;
      </code>
    </pre>
    <h2 id="variable-substitution">Variable substitution</h2>
    <p>
      When authoring tasks configurations, it is useful to have a set of
      predefined common variables such as the active file &#40;
      <code>$&#123;file&#125;</code>&#41; or workspace root folder &#40;
      <code>$&#123;workspaceFolder&#125;</code>&#41;. AVAP<sup>TM</sup> Dev
      Studio supports variable substitution inside strings in the{' '}
      <code>tasks.json</code> file and you can see a full list of predefined
      variables in the Variables Reference.
    </p>
    <blockquote>
      <p>
        <strong>Note:</strong> Not all properties will accept variable
        substitution. Specifically, only command, args, and options support
        variable substitution.
      </p>
    </blockquote>
    <p>
      Below is an example of a custom task configuration that passes the current
      opened file to the TypeScript compiler.
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"label": "TypeScript compile",
        <br />` `"type": "shell",
        <br />` `"command": "tsc $&#123;file&#125;",
        <br />` `"problemMatcher": ["$tsc"]
        <br />
        &#125;
      </code>
    </pre>
    <p>
      Similarly, you can reference your project&#39;s configuration settings by
      prefixing the name with <strong>$&#123;config:</strong>. For example,{' '}
      <code>$&#123;config:python.formatting.autopep8Path&#125;</code> returns
      the Python extension setting <code>formatting.autopep8Path</code>.
    </p>
    <p>
      Below is an example of a custom task configuration, which executes
      autopep8 on the current file using the autopep8 executable defined by the{' '}
      <code>python.formatting.autopep8Path</code> setting:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"label": "autopep8 current file",
        <br />` `"type": "process",
        <br />` `"command":
        "$&#123;config:python.formatting.autopep8Path&#125;",
        <br />` `"args": ["--in-place", "$&#123;file&#125;"]
        <br />
        &#125;
      </code>
    </pre>
    <p>
      If you want to specify the selected Python interpreter used by the Python
      extension for <code>tasks.json</code> or <code>launch.json</code>, you can
      use the <code>$&#123;command:python.interpreterPath&#125;</code> command.
    </p>
    <p>
      If simple variable substitution isn&#39;t enough, you can also get input
      from the user of your task by adding an <code>inputs</code> section to
      your <code>tasks.json</code> file.
    </p>
    <p>
      For more information about <code>inputs</code>, see the Variables
      Reference.
    </p>
    <h2 id="operating-system-specific-properties">
      Operating system specific properties
    </h2>
    <p>
      The task system supports defining values &#40;for example, the command to
      be executed&#41; specific to an operating system. To do so, put an
      operating system specific literal into the <code>tasks.json</code> file
      and specify the corresponding properties inside that literal.
    </p>
    <p>
      Below is an example that uses the Node.js executable as a command and is
      treated differently on Windows and Linux:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"label": "Run Node",
        <br />` `"type": "process",
        <br />` `"windows": &#123;
        <br />` `"command": "C:\\Program Files\\nodejs\\node.exe"
        <br />` `&#125;,
        <br />` `"linux": &#123;
        <br />` `"command": "/usr/bin/node"
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>
      Valid operating properties are <code>windows</code> for Windows, linux for{' '}
      <code>Linux</code>, and <code>osx</code> for macOS. Properties defined in
      an operating system specific scope override properties defined in the task
      or global scope.
    </p>
    <h2 id="global-tasks">Global tasks</h2>
    <p>
      Task properties can also be defined in the global scope. If present, they
      will be used for specific tasks unless they define the same property with
      a different value. In the example below, there is a global{' '}
      <code>presentation</code> property, which defines that all tasks should be
      executed in a new panel:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"label": "Run Node",
        <br />` `"type": "process",
        <br />` `"windows": &#123;
        <br />` `"command": "C:\\Program Files\\nodejs\\node.exe"
        <br />` `&#125;,
        <br />` `"linux": &#123;
        <br />` `"command": "/usr/bin/node"
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <blockquote>
      <p>
        <strong>Tip:</strong> To get access to the global scope{' '}
        <code>tasks.json</code> file, open the Command Palette
        &#40;Ctrl+Shift+P&#41; and run the{' '}
        <strong>Tasks: Open User Tasks</strong> command.
      </p>
    </blockquote>
    <h3 id="character-escaping-in-powershell">
      Character escaping in PowerShell
    </h3>
    <p>
      When the default shell is PowerShell, or when a task is configured to use
      PowerShell, you might see unexpected space and quote escaping. The
      unexpected escaping only occurs with cmdlets because AVAP<sup>TM</sup> Dev
      Studio doesn&#39;t know if your command contains cmdlets. Example 1 below
      shows a case where you&#39;ll get escaping that doesn&#39;t work with
      PowerShell. Example 2 shows the best, cross-platform, way to get good
      escaping. In some cases, you might not be able to follow example 2 and
      you&#39;ll need to do the manual escaping shown in example 3.
    </p>

    <pre className="">
      <code className="">
        "tasks": [<br />` `&#123;
        <br />` `"label": "PowerShell example 1 &#40;unexpected escaping&#41;",
        <br />` `"type": "shell",
        <br />` `"command": "Get-ChildItem \"Folder With Spaces\""
        <br />` `&#125;,
        <br />` `&#123;
        <br />` `"label": "PowerShell example 2 &#40;expected escaping&#41;",
        <br />` `"type": "shell",
        <br />` `"command": "Get-ChildItem",
        <br />` `"args": ["Folder With Spaces"]
        <br />` `&#125;,
        <br />` `&#123;
        <br />` `"label": "PowerShell example 3 &#40;manual escaping&#41;",
        <br />` `"type": "shell",
        <br />` `"command": "&amp; Get-ChildItem \\\"Folder With Spaces\\\""
        <br />` `&#125;
        <br />]
      </code>
    </pre>
    <h2 id="changing-the-encoding-for-a-task-output">
      Changing the encoding for a task output
    </h2>
    <p>
      Tasks frequently act with files on disk. If these files are stored on disk
      with an encoding different than the system encoding, you need to let the
      command executed as a task know which encoding to use. Since this depends
      on the operating system and the shell used, there is no general solution
      to control this. Below are advice and examples on how to make it work.
    </p>
    <p>
      If you need to tweak the encoding, you should check whether it makes sense
      to change the default encoding used by your operating system or at least
      change it for the shell you use by tweaking the shell&#39;s profile file.
    </p>
    <p>
      If you only need to tweak it for a specific task, then add the OS-specific
      command necessary to change the encoding to the tasks command line. The
      following example is for Windows using code page of 437 as its default.
      The task shows the output of a file containing Cyrillic characters and
      therefore needs code page 866. The task to list the file looks like this
      assuming that the default shell is set to <code>cmd.exe</code>:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `// See &#40;link&#41;
        <br />` `// for the documentation about the tasks.json format
        <br />` `"version": "2.0.0",
        <br />` `"tasks": [<br />` `&#123;
        <br />` `"label": "more",
        <br />` `"type": "shell",
        <br />` `"command": "chcp 866 &amp;&amp; more russian.txt",
        <br />` `"problemMatcher": []
        <br />` `&#125;
        <br />` `]
        <br />
        &#125;
      </code>
    </pre>
    <p>
      If the task is executed in <code>PowerShell</code>, the command needs to
      read like this <code>chcp 866</code>; more <code>russian.txt</code>. On
      Linux and macOS, the locale command can be used to inspect the{' '}
      <code>locale</code> and tweak the necessary environment variables.
    </p>
    <h2 id="examples-of-tasks-in-action">Examples of tasks in action</h2>
    <p>
      To highlight the power of tasks, here are a few examples of how AVAP
      <sup>TM</sup> Dev Studio can use tasks to integrate external tools like
      linters and compilers.
    </p>
    <h3 id="transpiling-typescript-to-javascript">
      Transpiling TypeScript to JavaScript
    </h3>
    <p>
      The TypeScript topic includes an example that creates a task to transpile
      TypeScript to JavaScript and observe any related errors from within AVAP
      <sup>TM</sup> Dev Studio.
    </p>
    <h3 id="transpiling-less-and-scss-into-css">
      Transpiling Less and SCSS into CSS
    </h3>
    <p>
      The CSS topic provides examples of how to use Tasks to generate CSS files.
    </p>
    <ol>
      <li>Manually transpiling with a Build task</li>
      <li>Automation of the compile step with a file watcher</li>
    </ol>
    <h2 id="defining-a-problem-matcher">Defining a problem matcher</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio ships some of the most common problem
      matchers &#39;in-the-box&#39;. However, there are lots of compilers and
      linting tools out there, all of which produce their own style of errors
      and warnings so you may want to create your own problem matcher.
    </p>
    <p>
      We have a <code>helloWorld.c</code> program in which the developer
      mistyped <strong>printf</strong> as <strong>prinft</strong>. Compiling it
      with gcc will produce the following warning:
    </p>

    <pre className="">
      <code className="">
        helloWorld.c:5:3: warning: implicit declaration of function â€˜prinftâ€™
      </code>
    </pre>
    <p>
      We want to produce a problem matcher that can capture the message in the
      output and show a corresponding problem in AVAP<sup>TM</sup> Dev Studio.
      Problem matchers heavily rely on regular expressions. The section below
      assumes you are familiar with regular expressions.
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> We have found the RegEx101 playground, which has
        an ECMAScript &#40;JavaScript&#41; flavor, to be a great way to develop
        and test regular expressions.
      </p>
    </blockquote>
    <p>
      A matcher that captures the above warning &#40;and errors&#41; looks like
      this:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `// The problem is owned by the cpp language service.
        <br />` `"owner": "cpp",
        <br />` `// The file name for reported problems is relative to the
        opened folder.
        <br />` `"fileLocation": ["relative", "$&#123;workspaceFolder&#125;"],
        <br />` `// The actual pattern to match problems in the output.
        <br />` `"pattern": &#123;
        <br />` `// The regular expression. Example to match: helloWorld.c:5:3:
        warning: implicit declaration of function â€˜printfâ€™
        [-Wimplicit-function-declaration]
        <br />` `"regexp":
        "^&#40;.\*&#41;:&#40;\\d+&#41;:&#40;\\d+&#41;:\\s+&#40;warning|error&#41;:\\s+&#40;.\*&#41;$",
        <br />` `// The first match group matches the file name which is
        relative.
        <br />` `"file": 1,
        <br />` `// The second match group matches the line on which the problem
        occurred.
        <br />` `"line": 2,
        <br />` `// The third match group matches the column at which the
        problem occurred.
        <br />` `"column": 3,
        <br />` `// The fourth match group matches the problem's severity. Can
        be ignored. Then all problems are captured as errors.
        <br />` `"severity": 4,
        <br />` `// The fifth match group matches the message.
        <br />` `"message": 5<br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>
      Note that the file, line, and message properties are mandatory. The{' '}
      <code>fileLocation</code> specifies whether the file paths that are
      produced by the task output and matched in the problem are{' '}
      <code>absolute</code> or <code>relative</code>. If the task produces both
      absolute and relative paths, you can use the <code>autoDetect</code> file
      location. With <code>autoDetect</code>, paths are first tested as absolute
      paths, and if the file doesn&#39;t exist then the path is assumed to be
      relative.
    </p>
    <p>
      Here is a finished tasks.json file with the code above &#40;comments
      removed&#41; wrapped with the actual task details:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"version": "2.0.0",
        <br />` `"tasks": [<br />` `&#123;
        <br />` `"label": "build",
        <br />` `"command": "gcc",
        <br />` `"args": ["-Wall", "helloWorld.c", "-o", "helloWorld"],
        <br />` `"problemMatcher": &#123;
        <br />` `"owner": "cpp",
        <br />` `"fileLocation": ["relative", "$&#123;workspaceFolder&#125;"],
        <br />` `"pattern": &#123;
        <br />` `"regexp":
        "^&#40;.\*&#41;:&#40;\\d+&#41;:&#40;\\d+&#41;:\\s+&#40;warning|error&#41;:\\s+&#40;.\*&#41;$",
        <br />` `"file": 1,
        <br />` `"line": 2,
        <br />` `"column": 3,
        <br />` `"severity": 4,
        <br />` `"message": 5<br />` `&#125;
        <br />` `&#125;
        <br />` `&#125;
        <br />` `]
        <br />
        &#125;
      </code>
    </pre>
    <p>
      Running it inside AVAP<sup>TM</sup> Dev Studio and pressing Ctrl+Shift+M
      to get the list of problems gives you the following output:
    </p>
    <blockquote>
      <p>
        <strong>Note:</strong> The C/C++ extension includes problem matchers for
        GCC so there is no need to define our own.
      </p>
    </blockquote>
    <p>
      There are a couple more properties that can be used inside a pattern.
      These are:
    </p>
    <ul>
      <li>
        <strong>location</strong> - If the problem location is line or
        line,column or startLine,startColumn,endLine,endColumn, then our generic
        location match group can be used.
      </li>
      <li>
        <strong>endLine</strong> - The match group index for the problem&#39;s
        end line. Can be omitted if no end line value is provided by the
        compiler.
      </li>
      <li>
        <strong>endColumn</strong> - The match group index for the problem&#39;s
        end column. Can be omitted if no end column value is provided by the
        compiler.
      </li>
      <li>
        <strong>code</strong> - The match group index for the problem&#39;s
        code. Can be omitted if no code value is provided by the compiler.
      </li>
    </ul>
    <p>
      You can also define a problem matcher that captures only a file. To do so,
      define a <code>pattern</code> with the optional <code>kind</code>{' '}
      attribute set to <code>file</code>. In this case, there is no need to
      provide a <code>line</code> or <code>location</code> property.
    </p>
    <blockquote>
      <p>
        <strong>Note:</strong> A functional <code>pattern</code> must at least
        provide a match group for file and message if the <code>kind</code>{' '}
        property is set to <code>file</code>. If no kind property is provided or
        the <code>kind</code> property is set to location, a function pattern
        must provide a line or <code>location</code> property as well.
      </p>
    </blockquote>
    <blockquote>
      <p>
        <strong>Note:</strong> The problem matcher only parses output from the
        given command. If you want to parse output written to separate file
        &#40;e.g. a log file&#41;, make the command that you run print out lines
        from the separate file before it finishes executing.
      </p>
    </blockquote>
    <h2 id="defining-a-multiline-problem-matcher">
      Defining a multiline problem matcher
    </h2>
    <p>
      Some tools spread problems found in a source file over several lines,
      especially if stylish reporters are used. An example is ESLint; in stylish
      mode it produces output like this:
    </p>

    <pre className="">
      <code className="">
        test.js
        <br />` `1:0 error Missing "use strict" statement strict
        <br />
        âœ– 1 problems &#40;1 errors, 0 warnings&#41;
      </code>
    </pre>
    <p>
      Our problem matcher is line-based so we need to capture the file name
      &#40;test.js&#41; with a different regular expression than the actual
      problem location and message &#40;1:0 error Missing &quot;use strict&quot;
      statement&#41;.
    </p>
    <p>
      To do this, use an array of problem patterns for the <code>pattern</code>{' '}
      property. This way you define a pattern per each line you want to match.
    </p>
    <p>
      The following problem pattern matches the output from ESLint in stylish
      mode - but still has one small issue that we need to resolve next. The
      code below has a first regular expression to capture the file name and the
      second to capture the line, column, severity, message, and error code:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"owner": "javascript",
        <br />` `"fileLocation": ["relative", "$&#123;workspaceFolder&#125;"],
        <br />` `"pattern": [<br />` `&#123;
        <br />` `"regexp": "^&#40;[^\\s].\*&#41;$",
        <br />` `"file": 1<br />` `&#125;,
        <br />` `&#123;
        <br />` `"regexp":
        "^\\s+&#40;\\d+&#41;:&#40;\\d+&#41;\\s+&#40;error|warning|info&#41;\\s+&#40;.\*&#41;\\s\\s+&#40;.\*&#41;$",
        <br />` `"line": 1,
        <br />` `"column": 2,
        <br />` `"severity": 3,
        <br />` `"message": 4,
        <br />` `"code": 5<br />` `&#125;
        <br />` `]
        <br />
        &#125;
      </code>
    </pre>
    <p>
      However, this pattern will not work if there is more than one problem on a
      resource. For instance, imagine the following output from ESLint:
    </p>

    <pre className="">
      <code className="">
        test.js
        <br />` `1:0 error Missing "use strict" statement strict
        <br />` `1:9 error foo is defined but never used no-unused-vars
        <br />` `2:5 error x is defined but never used no-unused-vars
        <br />` `2:11 error Missing semicolon semi
        <br />` `3:1 error "bar" is not defined no-undef
        <br />` `4:1 error Newline required at end of file but not found
        eol-last
        <br />
        âœ– 6 problems &#40;6 errors, 0 warnings&#41;
      </code>
    </pre>
    <p>
      The pattern&#39;s first regular expression will match &quot;test.js&quot;,
      the second &quot;1:0 error ...&quot;. The next line &quot;1:9 error
      ...&quot; is processed but not matched by the first regular expression and
      so no problem is captured.
    </p>
    <p>
      To make this work, the last regular expression of a multiline pattern can
      specify the <code>loop</code> property. If set to true, it instructs the
      task system to apply the last pattern of a multiline matcher to the lines
      in the output as long as the regular expression matches.
    </p>
    <p>
      The information captured by the first pattern, which in this case matches{' '}
      <code>test.js</code>, will be combined with each of the subsequent lines
      that match the <code>loop</code> pattern to create multiple problems. In
      this example, six problems would be created.
    </p>
    <p>Here is a problem matcher to fully capture ESLint stylish problems:</p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"owner": "javascript",
        <br />` `"fileLocation": ["relative", "$&#123;workspaceFolder&#125;"],
        <br />` `"pattern": [<br />` `&#123;
        <br />` `"regexp": "^&#40;[^\\s].\*&#41;$",
        <br />` `"file": 1<br />` `&#125;,
        <br />` `&#123;
        <br />` `"regexp":
        "^\\s+&#40;\\d+&#41;:&#40;\\d+&#41;\\s+&#40;error|warning|info&#41;\\s+&#40;.\*&#41;\\s\\s+&#40;.\*&#41;$",
        <br />` `"line": 1,
        <br />` `"column": 2,
        <br />` `"severity": 3,
        <br />` `"message": 4,
        <br />` `"code": 5,
        <br />` `"loop": true
        <br />` `&#125;
        <br />` `]
        <br />
        &#125;
      </code>
    </pre>
    <blockquote>
      <p>
        <strong>Note</strong>: If you have multiple problems that occur on the
        same resource with the exact same line and column, then only one problem
        will be shown. This applies to all problem matchers, not just multiline
        problem matchers.
      </p>
    </blockquote>
    <h2 id="modifying-an-existing-problem-matcher">
      Modifying an existing problem matcher
    </h2>
    <p>
      If an existing problem matcher is close to what you need, you can modify
      it in your <code>tasks.json</code> task. For example, the{' '}
      <code>$tsc-watch</code> problem matcher only applies to closed documents.
      If you want to have it apply to all documents you can modify it:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"type": "npm",
        <br />` `"script": "watch",
        <br />` `"problemMatcher": &#123;
        <br />` `"base": "$tsc-watch",
        <br />` `"applyTo": "allDocuments"
        <br />` `&#125;,
        <br />` `"isBackground": true
        <br />
        &#125;
      </code>
    </pre>
    <p>
      Other modifiable problem matcher properties include{' '}
      <code>background</code>, <code>fileLocation</code>, <code>owner</code>,{' '}
      <code>pattern</code>, <code>severity</code>, and <code>source</code>.
    </p>
    <h2 id="background--watching-tasks">Background / watching tasks</h2>
    <p>
      Some tools support running in the background while watching the file
      system for changes and then triggering an action when a file changes on
      disk. With <code>Gulp</code> such functionality is provided through the
      npm module gulp-watch. The TypeScript compiler <code>tsc</code> has built
      in support for this via the <code>--watch</code> command line option.
    </p>
    <p>
      To provide feedback that a background task is active in AVAP<sup>TM</sup>{' '}
      Dev Studio and producing problem results, a problem matcher has to use
      additional information to detect these state changes in the output.
      Let&#39;s take the <code>tsc</code> compiler as an example. When the
      compiler is started in watch mode, it prints the following additional
      information to the console:
    </p>

    <pre className="">
      <code className="">
        \&gt; tsc --watch
        <br />
        12:30:36 PM - Compilation complete. Watching for file changes.
      </code>
    </pre>
    <p>
      When a file changes on disk that contains a problem, the following output
      appears:
    </p>

    <pre className="">
      <code className="">
        12:32:35 PM - File change detected. Starting incremental compilation...
        <br />
        src/messages.ts&#40;276,9&#41;: error TS2304: Cannot find name
        'candidate'.
        <br />
        12:32:35 PM - Compilation complete. Watching for file changes.
      </code>
    </pre>
    <p>Looking at the output shows the following pattern:</p>
    <ul>
      <li>
        The compiler runs when{' '}
        <code>File change detected. Starting incremental compilation...</code>{' '}
        is printed to the console.
      </li>
      <li>
        The compiler stops when C
        <code>ompilation complete. Watching for file changes</code>. is printed
        to the console.
      </li>
      <li>Between those two strings problems are reported.</li>
      <li>
        The compiler also runs once the initial start &#40;without printing{' '}
        <code>File change detected. Starting incremental compilation</code>...
        to the console&#41;.
      </li>
    </ul>
    <p>
      To capture this information, a problem matcher can provide a{' '}
      <code>background</code> property.
    </p>
    <p>
      For the <code>tsc</code> compiler, an appropriate <code>background</code>{' '}
      property looks like this:
    </p>

    <pre className="">
      <code className="">
        "background": &#123;
        <br />` `"activeOnStart": true,
        <br />` `"beginsPattern":
        "^\\s\*\\d&#123;1,2&#125;:\\d&#123;1,2&#125;:\\d&#123;1,2&#125;&#40;?:
        AM| PM&#41;? - File change detected\\. Starting incremental
        compilation\\.\\.\\.",
        <br />` `"endsPattern":
        "^\\s\*\\d&#123;1,2&#125;:\\d&#123;1,2&#125;:\\d&#123;1,2&#125;&#40;?:
        AM| PM&#41;? - Compilation complete\\. Watching for file changes\\."
        <br />
        &#125;
      </code>
    </pre>
    <p>
      In addition to the <code>background</code> property on the problem
      matcher, the task itself has to be marked as <code>isBackground</code> so
      that the task keeps running in the background.
    </p>
    <p>
      A full handcrafted <code>tasks.json</code> for a <code>tsc</code> task
      running in watch mode looks like this:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"version": "2.0.0",
        <br />` `"tasks": [<br />` `&#123;
        <br />` `"label": "watch",
        <br />` `"command": "tsc",
        <br />` `"args": ["--watch"],
        <br />` `"isBackground": true,
        <br />` `"problemMatcher": &#123;
        <br />` `"owner": "typescript",
        <br />` `"fileLocation": "relative",
        <br />` `"pattern": &#123;
        <br />` `"regexp":
        "^&#40;[^\\s].\*&#41;\\&#40;&#40;\\d+|\\d+,\\d+|\\d+,\\d+,\\d+,\\d+&#41;\\&#41;:\\s+&#40;error|warning|info&#41;\\s+&#40;TS\\d+&#41;\\s\*:\\s\*&#40;.\*&#41;$",
        <br />` `"file": 1,
        <br />` `"location": 2,
        <br />` `"severity": 3,
        <br />` `"code": 4,
        <br />` `"message": 5<br />` `&#125;,
        <br />` `"background": &#123;
        <br />` `"activeOnStart": true,
        <br />` `"beginsPattern":
        "^\\s\*\\d&#123;1,2&#125;:\\d&#123;1,2&#125;:\\d&#123;1,2&#125;&#40;?:
        AM| PM&#41;? - File change detected\\. Starting incremental
        compilation\\.\\.\\.",
        <br />` `"endsPattern":
        "^\\s\*\\d&#123;1,2&#125;:\\d&#123;1,2&#125;:\\d&#123;1,2&#125;&#40;?:
        AM| PM&#41;? - Compilation complete\\. Watching for file changes\\."
        <br />` `&#125;
        <br />` `&#125;
        <br />` `&#125;
        <br />` `]
        <br />
        &#125;
      </code>
    </pre>
    <h2 id="next-steps">Next steps</h2>
    <p>That was tasks - let&#39;s keep going...</p>
    <ul>
      <li>
        tasks.json Schema - You can review the full <code>tasks.json</code>{' '}
        schema and descriptions.
      </li>
      <li>
        Basic Editing - Learn about the powerful AVAP<sup>TM</sup> Dev Studio
        editor.
      </li>
      <li>Code Navigation - Move quickly through your source code.</li>
      <li>
        Language Support - Learn about our supported programming languages, both
        shipped with AVAP<sup>TM</sup> Dev Studio and through community
        extensions.
      </li>
      <li>
        Debugging - Debug your source code directly in the AVAP<sup>TM</sup> Dev
        Studio editor.
      </li>
    </ul>
    <h2 id="common-questions">Common questions</h2>
    <h3 id="can-a-task-use-a-different-shell-than-the-one-specified-for-the-integrated-terminal">
      Can a task use a different shell than the one specified for the Integrated
      Terminal?
    </h3>
    <p>
      Yes. You can use the &quot;
      <code>terminal.integrated.automationProfile.\*</code>&quot; setting to set
      the shell that will be used for all automation in AVAP<sup>TM</sup> Dev
      Studio, which includes Tasks.
    </p>

    <pre className="">
      <code className="">
        ` `"terminal.integrated.automationProfile.windows": &#123;
        <br />` `"path": "cmd.exe"
        <br />` `&#125;
      </code>
    </pre>
    <p>
      Alternatively, you can override a task&#39;s shell with the{' '}
      <code>options.shell</code> property. You can set this per task, globally,
      or per platform. For example, to use cmd.exe on Windows, your{' '}
      <code>tasks.json</code> would include:
    </p>

    <pre className="">
      <code className="">
        ` `"version": "2.0.0",
        <br />` `"windows": &#123;
        <br />` `"options": &#123;
        <br />` `"shell": &#123;
        <br />` `"executable": "cmd.exe",
        <br />` `"args": [<br />` `"/d", "/c"
        <br />` `]
        <br />` `&#125;
        <br />` `&#125;
        <br />` `&#125;,
        <br />
        ...
      </code>
    </pre>
    <h3 id="can-a-background-task-be-used-as-a-prelaunchtask-in-launchjson">
      Can a background task be used as a prelaunchTask in launch.json?
    </h3>
    <p>
      Yes. Since a background task will run until killed, a background task on
      its own has no signal that it has &quot;completed&quot;. To use a
      background task as a <code>prelaunchTask</code>, you must add an
      appropriate background <code>problemMatcher</code> to the background task
      so that there is a way for the task system and debug system to know that
      the task &quot;finished&quot;.
    </p>
    <p>Your task could be:</p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"type": "npm",
        <br />` `"script": "watch",
        <br />` `"problemMatcher": "$tsc-watch",
        <br />` `"isBackground": true
        <br />
        &#125;
      </code>
    </pre>
    <blockquote>
      <p>
        <strong>Note:</strong> The $tsc-watch is a <strong>background</strong>{' '}
        problem matcher, as is required for a background task.
      </p>
    </blockquote>
    <p>
      You can then use the task as a prelaunchTask in your{' '}
      <code>launch.json</code> file:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"name": "Launch Extension",
        <br />` `"type": "extensionHost",
        <br />` `"request": "launch",
        <br />` `"runtimeExecutable": "$&#123;execPath&#125;",
        <br />` `"args":
        ["--extensionDevelopmentPath=$&#123;workspaceRoot&#125;"],
        <br />` `"stopOnEntry": false,
        <br />` `"sourceMaps": true,
        <br />` `"outFiles": ["$&#123;workspaceRoot&#125;/out/src/\*\*/\*.js"],
        <br />` `"preLaunchTask": "npm: watch"
        <br />
        &#125;
      </code>
    </pre>
    <p>For more on background tasks, go to Background / watching tasks.</p>
    <h3 id="why-do-i-get-command-not-found-when-running-a-task">
      Why do I get &quot;command not found&quot; when running a task?
    </h3>
    <p>
      The message &quot;command not found&quot; happens when the task command
      you&#39;re trying to run is not recognized by your terminal as something
      runnable. Most often, this occurs because the command is configured as
      part of your shell&#39;s startup scripts. Tasks are run as non-login and
      non-interactive, which means that the startup scripts for your shell
      won&#39;t be run. nvm in particular is known to use startup scripts as
      part of its configuration.
    </p>
    <p>There are several ways to resolve this issue:</p>
    <ol>
      <li>
        <p>
          Make sure your command is on your path and doesn&#39;t require startup
          scripts to get added to your path. This is the most thorough way to
          solve the problem, and is the recommended solution.
        </p>
      </li>
      <li>
        <p>
          You can make a one-off fix for your task to run as login or
          interactive. This is not recommended, as it can have other
          consequences. However, it can also be a quick and easy fix for a
          single task. Below is an example of a task that does this with{' '}
          <code>bash</code> as the shell:
        </p>
        <p>
          {' '}
          &#123;
          <code></code>&quot;type&quot;: &quot;npm&quot;,
          <code></code>&quot;script&quot;: &quot;watch&quot;,
          <code></code>&quot;options&quot;: &#123;
          <code></code>&quot;shell&quot;: &#123;
          <code></code>&quot;args&quot;: [&quot;-c&quot;, &quot;-l&quot;]
          <code></code>&#125;
          <code></code>&#125; &#125;
        </p>
      </li>
    </ol>
    <p>
      The above <code>npm</code> task will run bash with a command &#40;
      <code>-c</code>&#41;, just like the tasks system does by default. However,
      this task also runs <code>bash</code> as a login shell &#40;
      <code>-l</code>&#41;.
    </p>
  </Fragment>
);
export default USER_GUIDE_Tasks;
