import React, { FunctionComponent, useState, useEffect } from 'react';
import {  Modal, Button, Container, Row, Col, Form, Alert, Spinner, Image } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import ilustra_cambio from '../../assets/img/recover_password.png';
import iconEmail from '../../../src/assets/img/newDashboard/Asset_icono_confirm_email.png'
import iconEmailBlue from '../../../src/assets/img/newDashboard/Asset_icono_confirm_email_blue.png'
import "./style.css"

type passwordRecoveryProps = {
  show: boolean,
  handleClose: any,
  isBlue?: boolean
}

const defaultProps = {
  isBlue: true
}

const PasswordRecoveryModal:FunctionComponent<passwordRecoveryProps> = props => {

  props = {...defaultProps, ...props}
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const handleEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
  }




  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      event.stopPropagation();
      setLoading(true);
      setSuccess('');
      setError('');// Limpiamos los posibles errores

      console.log(`EMAIL A RECUPERAR ${email}`)
      const result = await ObexRequestHandler.post('/request_reset_password', { email });
      console.log(`ERESULTADO ${result}`)
      const { success, data, message } = result;
      if (success){
        setSuccess(data);
      } else {
        setError(message);
      }
    } catch (error) {
      console.error('ERROR REQUESTING RESET PASSWORD ', error);
      setError(error.message || error);
    }
    setLoading(false);
    setTimeout(function() { 
      setError('');
      setSuccess('');
      }.bind(this), 2000)
    setTimeout(function() { 
      const path = '/login';
      window.location.href = path;
      }.bind(this), 4000)

  };

  return(
    <Modal dialogClassName="addons-dialog w-75 obex-dialog py-3 obex-dialog" style={{ borderRadius:'30px' }} centered 
      show={props.show} 
      onHide={props.handleClose}
      animation={false}>
        
      
      <Modal.Header closeButton className="border-0 pb-0"  >
        <Modal.Title className="text-center mx-auto" style={{ borderBottom:'20px' }}>
          <Col md="12" className="addons-title">
            <span style={{ color:'black', marginLeft:'00px' }}>Recover your password</span>
          </Col>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <Row>
          <Col md="5" className="my-3 mx-auto" style={{ textAlign:'center', height:'50%', maxHeight:'50%' }}>
            <Image style={{height:'150px'}} src={props.isBlue ? iconEmailBlue : iconEmail} fluid />
          </Col>
          </Row> 


        <Row style={{marginBottom:'10px'}}>
          <Col md="10" className="mx-auto">
            <Row>
              <Col md="12" className="mb-3" style={{ textAlign: 'center' }}>
                <span className="text-dialog" style={{ color:'black' }}>
                Enter the email address associated with your account, and we'll send you a link to reset your password or resend your activation email.
                </span>
              </Col>
            </Row>
            <Form className="obex-form payment-method-form" onSubmit={handleSubmit} style={{ marginLeft:'10px' }}>
  <Form.Row className="align-items-center mb-4">
    <Col md="8" style={{display:"contents"}}>
      <input onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('Please enter a registered email address')} style={{ borderColor:`${props.isBlue ? '#147bce' :'#316952'}`, borderRadius:"10px", backgroundColor:"#efefef", border:"none", padding:"5px", width:'65%',paddingLeft:'10px'}} required type="text" className="" placeholder="email" onChange={handleEmail} />
    </Col>
    <Col md="4" className="text-right">
      <button type="submit" className={`${props.isBlue?'custom-button-blue':'custom-button-green'} textBold `} style={{ display: 'inline-flex', alignItems: 'center', marginLeft:'10px' }}>
        Continue
        {success && <FontAwesomeIcon icon={faCheck} style={{ marginLeft: '8px' }} />}
        {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
      </button>
    </Col>
  </Form.Row>
</Form>
          </Col>
          <Col md="12" className="text-center">
            <span>Don't have an account? <a href="/Register" style={!props.isBlue ? {color:"#316952"}:{color:"#147bce"}}>Sign up</a></span>
          </Col>
        </Row>
        {success.length > 0 && (
          <Row>
            <Col>
            {/*
              <Alert variant='success'>
                {success}
              </Alert>
            */}
            </Col>
          </Row>)}
        {error.length > 0 && (
          <Row>
            <Col>
              <Alert variant='danger'>
                {error}
              </Alert>
            </Col>
          </Row>)}
      </Modal.Body>
    </Modal>
  )
}

export default PasswordRecoveryModal;