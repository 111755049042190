import React, { Fragment } from 'react';

const USER_GUIDE_ExtensionMarketplace = () => (
  <Fragment>
    <h1 id="extension-marketplace">Extension Marketplace</h1>
    <p>
      <strong>
        Increase the power of AVAP<sup>TM</sup> Dev Studio through Extensions
      </strong>
    </p>
    <p>
      The features that AVAP<sup>TM</sup> Dev Studio includes out-of-the-box are
      just the start. AVAP<sup>TM</sup> Dev Studio extensions let you add
      languages, debuggers, and tools to your installation to support your
      development workflow. AVAP<sup>TM</sup> Dev Studio&#39;s rich
      extensibility model lets extension authors plug directly into the AVAP
      <sup>TM</sup> Dev Studio UI and contribute functionality through the same
      APIs used by AVAP<sup>TM</sup> Dev Studio. This article explains how to
      find, install, and manage AVAP<sup>TM</sup> Dev Studio extensions from the
      AVAP<sup>TM</sup> Dev Studio Marketplace.
    </p>
    <h2 id="browse-for-extensions">Browse for extensions</h2>
    <p>
      You can browse and install extensions from within AVAP<sup>TM</sup> Dev
      Studio. Bring up the Extensions view by clicking on the Extensions icon in
      the <strong>Activity Bar</strong> on the side of AVAP<sup>TM</sup> Dev
      Studio or the <strong>View: Extensions</strong> command
      &#40;Ctrl+Shift+X&#41;.
    </p>
    <p>
      This will show you a list of the most popular AVAP<sup>TM</sup> Dev Studio
      extensions on the AVAP<sup>TM</sup> Dev Studio Marketplace.
    </p>
    <p>
      Each extension in the list includes a brief description, the publisher,
      the download count, and a five star rating. You can select the extension
      item to display the extension&#39;s details page where you can learn more.
    </p>
    <p>
      <strong>Note:</strong> If your computer&#39;s Internet access goes through
      a proxy server, you will need to configure the proxy server. See Proxy
      server support for details.
    </p>
    <h2 id="install-an-extension">Install an extension</h2>
    <p>
      To install an extension, select the <strong>Install</strong> button. Once
      the installation is complete, the <strong>Install</strong> button will
      change to the <strong>Manage</strong> gear button.
    </p>
    <h3 id="find-and-install-an-extension">Find and install an extension</h3>
    <p>
      For example, let&#39;s install the popular TODO Highlight extension. This
      extension highlights text like &#39;TODO:&#39; and &#39;FIXME:&#39; in
      your source code so you can quickly find undone sections.
    </p>
    <p>
      In the Extensions view &#40;<code>Ctrl+Shift+X</code>&#41;, type
      &#39;todo&#39; in the search box to filter the Marketplace offerings to
      extensions with &#39;todo&#39; in the title or metadata. You should see
      the <strong>TODO Highlight</strong> extension in the list.
    </p>
    <p>
      An extension is uniquely identified by its publisher and extension IDs. If
      you select the <strong>TODO Highlight</strong> extension, you will see the
      Extension details page, where you can find the extension ID, in this case,
      wayou.vscode-todo-highlight. Knowing the extension ID can be helpful if
      there are several similarly named extensions.
    </p>
    <p>
      Select the <strong>Install</strong> button, and AVAP<sup>TM</sup> Dev
      Studio will download and install the extension from the Marketplace. When
      the installation is complete, the <strong>Install</strong> button will be
      replaced with a <strong>Manage</strong> gear button.
    </p>
    <p>
      To see the TODO Highlight extension in action, open any source code file
      and add the text &#39;TODO:&#39; and you will see the text highlighted.
    </p>
    <p>
      The TODO Highlight extension contributes the commands,{' '}
      <strong>TODO-Highlight: List highlighted annotations</strong> and{' '}
      <strong>TODO-Highlight: Toggle highlight</strong>, that you can find in
      the Command Palette &#40;Ctrl+Shift+P&#41;. The{' '}
      <strong>TODO-Highlight: Toggle highlight</strong> command lets you quickly
      disable or enable highlighting.
    </p>
    <p>
      The extension also provides settings for tuning its behavior, which you
      can find in the Settings editor &#40;Ctrl+,&#41;. For example, you might
      want the text search to be case insensitive and you can uncheck the{' '}
      <strong>Todohighlight: Is Case Sensitive</strong> setting.
    </p>
    <p>
      If an extension doesn&#39;t provide the functionality you want, you can
      always <strong>Uninstall</strong> the extension from the{' '}
      <strong>Manage</strong> button context menu.
    </p>
    <p>
      This has been just one example of how to install and use an extension. The
      AVAP<sup>TM</sup> Dev Studio Marketplace has thousands of extensions
      supporting hundreds of programming languages and tasks. Everything from
      full featured language support for [Java], [Python], [Go], and [C++] to
      simple extensions that [create GUIDs], change the [color theme], or add
      [virtual pets] to the editor.
    </p>
    <h3 id="extension-details">Extension details</h3>
    <p>
      On the extension details page, you can read the extension&#39;s README and
      review the extension&#39;s:
    </p>
    <ul>
      <li>
        <strong>Feature Contributions</strong> - The extension&#39;s additions
        to AVAP<sup>TM</sup> Dev Studio such as settings, commands and keyboard
        shortcuts, language grammars, debugger, etc.
      </li>
      <li>
        <strong>Changelog</strong> - The extension repository CHANGELOG if
        available.
      </li>
      <li>
        <strong>Dependencies</strong> - Lists if the extension depends on any
        other extensions.
      </li>
    </ul>
    <p>
      If an extension is an Extension Pack, the <strong>Extension Pack</strong>{' '}
      section will display which extensions will be installed when you install
      the pack.{' '}
      <a href="/api/references/extension-manifest#_extension-packs">
        Extension Packs
      </a>{' '}
      bundle separate extensions together so they can be easily installed at one
      time.
    </p>
    <h3 id="extensions-view-filter-and-commands">
      Extensions view filter and commands
    </h3>
    <p>
      You can filter the Extensions view with the{' '}
      <strong>Filter Extensions</strong> context menu.
    </p>
    <p>There are filters to show:</p>
    <ul>
      <li>The list of outdated extensions that can be updated</li>
      <li>The list of currently enabled/disabled extensions</li>
      <li>The list of recommended extensions based on your workspace</li>
      <li>The list of globally popular extensions</li>
    </ul>
    <p>
      You can sort the extension list by <strong>Install Count</strong>,{' '}
      <strong>Rating</strong>, <strong>Name</strong>,{' '}
      <strong>Published Date</strong>, or <strong>Updated Date</strong> in
      either ascending or descending order. You can learn more about extension
      search filters <a href="#_extensions-view-filters">below</a>.
    </p>
    <p>
      You can run additional Extensions view commands via the ...{' '}
      <strong>View and More Actions</strong> button.
    </p>
    <p>
      Through this context menu you can control extension updates, enable or
      disable all extensions, and use the{' '}
      <a href="https://code.visualstudio.com/blogs/2021/02/16/extension-bisect">
        Extension Bisect
      </a>{' '}
      utility to isolate problematic extension behavior.
    </p>
    <h3 id="search-for-an-extension">Search for an extension</h3>
    <p>
      You can clear the Search box at the top of the Extensions view and type in
      the name of the extension, tool, or programming language you&#39;re
      looking for.
    </p>
    <p>
      For example, typing &#39;python&#39; will bring up a list of Python
      language extensions:
    </p>
    <p>
      If you know the exact identifier for an extension you&#39;re looking for,
      you can use the @id: prefix, for example @id:vue.volar. Additionally, to
      filter or sort results, you can use the{' '}
      <a href="#_extensions-view-filters">filter</a> and{' '}
      <a href="#_sorting">sort</a> commands, detailed below.
    </p>
    <h2 id="manage-extensions">Manage extensions</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio makes it easy to manage your extensions. You
      can install, disable, update, and uninstall extensions through the
      Extensions view, the <strong>Command Palette</strong> &#40;commands have
      the <strong>Extensions:</strong> prefix&#41; or command-line switches.
    </p>
    <h3 id="list-installed-extensions">List installed extensions</h3>
    <p>
      By default, the Extensions view will show the extensions you currently
      have installed, and all extensions that are recommended for you. You can
      use the <strong>Extensions: Focus on Installed View</strong> command,
      available in the <strong>Command Palette</strong> &#40;Ctrl+Shift+P&#41;
      or in the <strong>More Actions</strong> &#40;...&#41; dropdown menu &gt;{' '}
      <strong>Views</strong> &gt; <strong>Installed</strong>, to clear any text
      in the search box and show the list of all installed extensions, which
      includes those that have been disabled.
    </p>
    <h3 id="uninstall-an-extension">Uninstall an extension</h3>
    <p>
      To uninstall an extension, select the <strong>Manage</strong> gear button
      at the right of an extension entry and then choose{' '}
      <strong>Uninstall</strong> from the dropdown menu. This will uninstall the
      extension and prompt you to reload AVAP<sup>TM</sup> Dev Studio.
    </p>
    <h3 id="disable-an-extension">Disable an extension</h3>
    <p>
      If you don&#39;t want to permanently remove an extension, you can instead
      temporarily disable the extension by clicking the gear button at the right
      of an extension entry. You can disable an extension globally or just for
      your current Workspace. You will be prompted to reload AVAP<sup>TM</sup>{' '}
      Dev Studio after you disable an extension.
    </p>
    <p>
      If you want to quickly disable all installed extensions, there is a{' '}
      <strong>Disable All Installed Extensions</strong> command in the{' '}
      <strong>Command Palette</strong> and <strong>More Actions</strong>{' '}
      &#40;...&#41; dropdown menu.
    </p>
    <p>
      Extensions remain disabled for all AVAP<sup>TM</sup> Dev Studio sessions
      until you re-enable them.
    </p>
    <h3 id="enable-an-extension">Enable an extension</h3>
    <p>
      Similarly if you have disabled an extension &#40;it will be in the{' '}
      <strong>Disabled</strong> section of the list and marked{' '}
      <strong>
        <em>Disabled</em>
      </strong>
      &#41;, you can re-enable it with the <strong>Enable</strong> or{' '}
      <strong>Enable &#40;Workspace&#41;</strong> commands in the dropdown menu.
    </p>
    <p>
      There is also an <strong>Enable All Extensions</strong> command in the{' '}
      <strong>More Actions</strong> &#40;...&#41; dropdown menu.
    </p>
    <h3 id="extension-auto-update">Extension auto-update</h3>
    <p>
      AVAP<sup>TM</sup> Dev Studio checks for extension updates and installs
      them automatically. After an update, you will be prompted to reload AVAP
      <sup>TM</sup> Dev Studio. If you&#39;d rather update your extensions
      manually, you can disable auto-update with the{' '}
      <strong>Disable Auto Updating Extensions</strong> command that sets the
      extensions.autoUpdate <a href="/docs/getstarted/settings">setting</a> to
      false.
    </p>
    <p>
      If you don&#39;t want AVAP<sup>TM</sup> Dev Studio to even check for
      updates, you can set the extensions.autoCheckUpdates setting to false.
    </p>
    <h3 id="update-an-extension-manually">Update an extension manually</h3>
    <p>
      If you have extensions auto-update disabled, you can quickly look for
      extension updates by using the <strong>Show Outdated Extensions</strong>{' '}
      command that uses the @updates filter. This will display any available
      updates for your currently installed extensions.
    </p>
    <p>
      Select the <strong>Update</strong> button for the outdated extension. The
      update will be installed, and you&#39;ll be prompted to reload AVAP
      <sup>TM</sup> Dev Studio. You can also update all your outdated extensions
      at one time with the <strong>Update All Extensions</strong> command.
    </p>
    <p>
      If you also have automatic checking for updates disabled, you can use the{' '}
      <strong>Check for Extension Updates</strong> command to check which of
      your extensions can be updated.
    </p>
    <h2 id="recommended-extensions">Recommended extensions</h2>
    <p>
      You can see a list of recommended extensions using{' '}
      <strong>Show Recommended Extensions</strong>, which sets the @recommended{' '}
      <a href="#_extensions-view-filters">filter</a>. Extension recommendations
      can either be:
    </p>
    <ul>
      <li>
        <strong>Workspace Recommendations</strong> - Recommended by other users
        of your current workspace.
      </li>
      <li>
        <strong>Other Recommendations</strong> - Recommended based on recently
        opened files.
      </li>
    </ul>
    <p>
      See the section below to learn how to{' '}
      <a href="#_workspace-recommended-extensions">contribute</a>{' '}
      recommendations for other users in your project.
    </p>
    <h3 id="ignoring-recommendations">Ignoring recommendations</h3>
    <p>
      To dismiss a recommendation, select on the extension item to open the
      Details page and then select the <strong>Manage</strong> gear button to
      display the context menu. Select the{' '}
      <strong>Ignore Recommendation</strong> menu item. Ignored recommendations
      will no longer be recommended to you.
    </p>
    <h2 id="configuring-extensions">Configuring extensions</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio extensions may have very different
      configurations and requirements. Some extensions contribute settings to
      AVAP<sup>TM</sup> Dev Studio, which can be modified in the Settings
      editor. Other extensions may have their own configuration files.
      Extensions may also require installation and setup of additional
      components like compilers, debuggers, and command-line tools. Consult the
      extension&#39;s README &#40;visible in the Extensions view details
      page&#41; or go to the extension page on the AVAP<sup>TM</sup> Dev Studio
      Marketplace &#40;click on the extension name in the details page&#41;.
      Many extensions are open source and have a link to their repository on
      their Marketplace page.
    </p>
    <h2 id="command-line-extension-management">
      Command line extension management
    </h2>
    <p>
      To make it easier to automate and configure AVAP<sup>TM</sup> Dev Studio,
      it is possible to list, install, and uninstall extensions from the command
      line. When identifying an extension, provide the full name of the form
      publisher.extension, for example ms-python.python.
    </p>
    <p>Example:</p>

    <pre className="">
      <code className="">
        code --extensions-dir &lt;dir&gt;
        <br />
        <br /> Set the root path for extensions.
        <br />
        <br />
        code --list-extensions
        <br />
        <br /> List the installed extensions.
        <br />
        <br />
        code --show-versions
        <br />
        <br /> Show versions of installed extensions, when using
        --list-extension.
        <br />
        <br />
        code --install-extension &#40;&lt;extension-id&gt; |
        &lt;extension-vsix-path&gt;&#41;
        <br />
        <br /> Installs an extension.
        <br />
        <br />
        code --uninstall-extension &#40;&lt;extension-id&gt; |
        &lt;extension-vsix-path&gt;&#41;
        <br />
        <br /> Uninstalls an extension.
        <br />
        <br />
        code --enable-proposed-api &#40;&lt;extension-id&gt;&#41;
        <br />
        <br /> Enables proposed API features for extensions. Can receive one or
        more extension IDs to enable individually.
      </code>
    </pre>
    <p>
      You can see the extension ID on the extension details page under the
      Marketplace Info.
    </p>
    <h2 id="extensions-view-filters">Extensions view filters</h2>
    <p>
      The Extensions view search box supports filters to help you find and
      manage extensions. You may have seen filters such as @installed and
      @recommended if you used the commands{' '}
      <strong>Show Installed Extensions</strong> and{' '}
      <strong>Show Recommended Extensions</strong>. Also, there are filters
      available to let you sort by popularity or ratings and search by category
      &#40;for example &#39;Linters&#39;&#41; and tags &#40;for example
      &#39;node&#39;&#41;. You can see a complete listing of all filters and
      sort commands by typing @ in the extensions search box and navigating
      through the suggestions:
    </p>
    <p>Here are the Extensions view filters:</p>
    <ul>
      <li>
        <code>@builtin</code> - Show extensions that come with AVAP<sup>TM</sup>{' '}
        Dev Studio. Grouped by type &#40;Programming Languages, Themes,
        etc.&#41;.
      </li>
      <li>
        <code>@disabled</code> - Show disabled installed extensions.
      </li>
      <li>
        <code>@installed</code> - Show installed extensions.
      </li>
      <li>
        <code>@outdated</code> - Show outdated installed extensions. A newer
        version is available on the Marketplace.
      </li>
      <li>
        <code>@enabled</code> - Show enabled installed extensions. Extensions
        can be individually enabled/disabled.
      </li>
      <li>
        <code>@recommended</code> - Show recommended extensions. Grouped as
        Workspace specific or general use.
      </li>
      <li>
        <code>@category</code> - Show extensions belonging to specified
        category. Below are a few of supported categories. For a complete list,
        type @category and follow the options in the suggestion list:{' '}
        <ul>
          <li>@category:themes</li>
          <li>@category:formatters</li>
          <li>@category:linters</li>
          <li>@category:snippets</li>
        </ul>
      </li>
    </ul>
    <p>
      These filters can be combined as well. For example: Use @installed
      @category:themes to view all installed themes.
    </p>
    <p>
      If no filter is provided, the Extensions view displays the currently
      installed and recommended extensions.
    </p>
    <h3 id="sorting">Sorting</h3>
    <p>
      You can sort extensions with the @sort filter, which can take the
      following values:
    </p>
    <ul>
      <li>
        <code>installs</code> - Sort by Marketplace installation count, in
        descending order.
      </li>
      <li>
        <code>name</code> - Sort alphabetically by extension name.
      </li>
      <li>
        <code>publishedDate</code> - Sort by extension published date.
      </li>
      <li>
        <code>rating</code> - Sort by Marketplace rating &#40;1-5 stars&#41;, in
        descending order.
      </li>
      <li>
        <code>updateDate</code> - Sort by extension last update name.
      </li>
    </ul>
    <h3 id="categories-and-tags">Categories and tags</h3>
    <p>
      Extensions can set <strong>Categories</strong> and <strong>Tags</strong>{' '}
      describing their features.
    </p>
    <p>You can filter on category and tag by using category: and tag:.</p>
    <p>
      Supported categories are:{' '}
      <code>
        [Azure, Data Science, Debuggers, Education, Extension Packs, Formatters,
        Keymaps, Language Packs, Linters, Machine Learning, Notebooks, Others,
        Programming Languages, SCM Providers, Snippets, Testing, Themes,
        Visualization]
      </code>
      . They can be accessed through IntelliSense in the extensions search box:
    </p>
    <p>
      Note that you must surround the category name in quotes if it is more than
      one word &#40;for example, <code>category:&quot;SCM Providers&quot;</code>
      &#41;.
    </p>
    <p>
      Tags may contain any string and are not provided by IntelliSense, so
      review the Marketplace to find helpful tags.
    </p>
    <h2 id="install-from-a-vsix">Install from a VSIX</h2>
    <p>
      You can manually install a AVAP<sup>TM</sup> Dev Studio extension packaged
      in a <code>.vsix</code> file. Using the <strong>Install from VSIX</strong>{' '}
      command in the Extensions view command dropdown, or the{' '}
      <strong>Extensions: Install from VSIX</strong> command in the{' '}
      <strong>Command Palette</strong>, point to the <code>.vsix</code> file.
    </p>
    <p>
      You can also install using the AVAP<sup>TM</sup> Dev Studio{' '}
      <code>--install-extension</code> command-line switch providing the path to
      the <code>.vsix</code> file.
    </p>

    <pre className="">
      <code className="">code --install-extension myextension.vsix</code>
    </pre>
    <p>
      You may provide the <code>--install-extension</code> multiple times on the
      command line to install multiple extensions at once.
    </p>
    <p>
      If you&#39;d like to learn more about packaging and publishing extensions,
      see our Publishing Extensions article in the Extension API.
    </p>
    <h2 id="workspace-recommended-extensions">
      Workspace recommended extensions
    </h2>
    <p>
      A good set of extensions can make working with a particular workspace or
      programming language more productive and you&#39;d often like to share
      this list with your team or colleagues. You can create a recommended list
      of extensions for a workspace with the{' '}
      <strong>
        Extensions: Configure Recommended Extensions &#40;Workspace Folder&#41;
      </strong>{' '}
      command.
    </p>
    <p>
      In a single folder workspace, the command creates an{' '}
      <code>extensions.json</code> file located in the workspace{' '}
      <code>.vscode</code> folder where you can add a list of extensions
      identifiers &#40;&#123;publisherName&#125;.&#123;extensionName&#125;&#41;.
    </p>
    <p>
      In a multi-root workspace, the command will open your{' '}
      <code>.code-workspace</code> file where you can list extensions under{' '}
      <code>extensions.recommendations</code>. You can still add extension
      recommendations to individual folders in a multi-root workspace by using
      the{' '}
      <strong>
        Extensions: Configure Recommended Extensions &#40;Workspace Folder&#41;
      </strong>{' '}
      command.
    </p>
    <p>
      An example <code>extensions.json</code> could be:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />
        <br />` `"recommendations": ["dbaeumer.vscode-eslint",
        "esbenp.prettier-vscode"]
        <br />
        <br />
        &#125;
      </code>
    </pre>
    <p>which recommends a linter extension and a code formatter extension.</p>
    <p>
      An extension is identified using its publisher name and extension
      identifier <code>publisher.extension</code>. You can see the name on the
      extension&#39;s detail page. AVAP<sup>TM</sup> Dev Studio will provide you
      with auto-completion for installed extensions inside these files.
    </p>
    <p>
      AVAP<sup>TM</sup> Dev Studio prompts a user to install the recommended
      extensions when a workspace is opened for the first time. The user can
      also review the list with the{' '}
      <strong>Extensions: Show Recommended Extensions</strong> command.
    </p>
    <h2 id="next-steps">Next steps</h2>
    <p>Here are a few topics you may find interesting...</p>
    <ul>
      <li>
        Extension API&#40;/api&#41; - Start learning about the AVAP<sup>TM</sup>{' '}
        Dev Studio extension API.
      </li>
      <li>
        Your First Extension - Try creating a simple Hello World extension.
      </li>
      <li>
        Publishing to the Marketplace - Publish your own extension to the AVAP
        <sup>TM</sup> Dev Studio Marketplace.
      </li>
    </ul>
    <h2 id="common-questions">Common questions</h2>
    <h3 id="where-are-extensions-installed">Where are extensions installed?</h3>
    <p>
      Extensions are installed in a per user extensions folder. Depending on
      your platform, the location is in the following folder:
    </p>
    <ul>
      <li>
        <strong>Windows</strong> %USERPROFILE%.vscode\extensions
      </li>
      <li>
        <strong>macOS</strong> ~/.vscode/extensions
      </li>
      <li>
        <strong>Linux</strong> ~/.vscode/extensions
      </li>
    </ul>
    <p>
      You can change the location by launching AVAP<sup>TM</sup> Dev Studio with
      the --extensions-dir command-line{' '}
      <a href="/docs/editor/command-line">option</a>.
    </p>
    <h3 id="whenever-i-try-to-install-any-extension-i-get-a-connect-etimedout-error">
      Whenever I try to install any extension, I get a connect ETIMEDOUT error
    </h3>
    <p>
      You may see this error if your machine is going through a proxy server to
      access the Internet. See the Proxy server support section in the setup
      topic for details.
    </p>
    <h3 id="can-i-download-an-extension-directly-from-the-marketplace">
      Can I download an extension directly from the Marketplace?
    </h3>
    <p>
      Some users prefer to download an extension once from the Marketplace and
      then install it multiple times from a local share. This is useful when
      there are connectivity concerns or if your development team wants to use a
      fixed set of extensions.
    </p>
    <p>
      To download an extension, navigate to the details page for the specific
      extension within the Marketplace. On that page, there is a{' '}
      <strong>Download Extension</strong> link in the <strong>Resources</strong>{' '}
      section, which is located on the right-hand side of the page.
    </p>
    <p>
      Once downloaded, you can then install the extension via the{' '}
      <strong>Install from VSIX</strong> command in the Extensions view command
      dropdown.
    </p>
    <h3 id="can-i-stop-avapsuptmsup-dev-studio-from-providing-extension-recommendations">
      Can I stop AVAP<sup>TM</sup> Dev Studio from providing extension
      recommendations?
    </h3>
    <p>
      Yes, if you would prefer to not have AVAP<sup>TM</sup> Dev Studio display
      extension recommendations in the Extensions view or through notifications,
      you can modify the following settings:
    </p>
    <ul>
      <li>
        <code>extensions.showRecommendationsOnlyOnDemand</code> - Set to true to
        remove the <strong>RECOMMENDED</strong> section.
      </li>
      <li>
        <code>extensions.ignoreRecommendations</code> - Set to true to silence
        extension recommendation notifications.
      </li>
    </ul>
    <p>
      The <strong>Show Recommended Extensions</strong> command is always
      available if you want to see recommendations.
    </p>
    <h3 id="can-i-trust-extensions-from-the-marketplace">
      Can I trust extensions from the Marketplace?
    </h3>
    <p>
      The Marketplace runs a virus scan on each extension package that&#39;s
      published to ensure its safety. The virus scan is run for each new
      extension and for each extension update. Until the scan is all clear, the
      extension won&#39;t be published in the Marketplace for public usage.
    </p>
    <p>
      The Marketplace also prevents extension authors from name-squatting on
      official publishers such as Microsoft and RedHat.
    </p>
    <p>
      If a malicious extension is reported and verified, or a vulnerability is
      found in an extension dependency:
    </p>
    <ol>
      <li>The extension is removed from the Marketplace.</li>
      <li>
        The extension is added to a kill list so that if it has been installed,
        it will be automatically uninstalled by AVAP<sup>TM</sup> Dev Studio.
      </li>
    </ol>
    <p>
      The Marketplace also provides you with resources to make an informed
      decision about the extensions you install:
    </p>
    <ul>
      <li>
        <strong>Ratings &amp; Review</strong> - Read what others think about the
        extension.
      </li>
      <li>
        <strong>Q &amp; A</strong> - Review existing questions and the level of
        the publisher&#39;s responsiveness. You can also engage with the
        extension&#39;s publisher&#40;s&#41; if you have concerns.
      </li>
      <li>
        <strong>Issues, Repository, and License</strong> - Check if the
        publisher has provided these and if they have the support you expect.
      </li>
    </ul>
    <p>
      If you do see an extension that looks suspicious, you can report the
      extension to the Marketplace with the <strong>Report Abuse</strong> link
      at the bottom of the extension <strong>More Info</strong> section.
    </p>
  </Fragment>
);
export default USER_GUIDE_ExtensionMarketplace;
