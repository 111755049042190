import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Usuarios_con_saldo_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": <boolean>,
         "data": [ [ <string> ] ], 
         "number_of_results": <integer>, 
         "totals": [ <string> ], 
         "columns": [ <string> ], 
         "result_limit": <integer> 
         "elapsed": <float> 
        }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": <boolean>, 
          "level": <string>, 
          "message": <string>, 
          "error": <string> 
        }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: ` import requests url = "URL_BASE/ws/util.py/affiliates?country_code=MX&id_usuario=4532&id_sesion=413-JRdxDQvO-zPMcwLeptmOyA==" 
      payload = {} 
      files = {} 
      headers= {} 
      response = requests.request("GET", url, headers=headers, data = payload, files = files) 
      print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: ` import requests url = "URL_BASE/ws/admin.py/usuariosconsaldo"
           payload = {'country_code': 'MX', 'user_id': '4532', 'session_id': '442-4tXk7omYJZt7PGRAaToHVQ==', 'profile': '123'} 
           files = [] 
           headers= {} 
           response = requests.request("POST", url, headers=headers, data = payload, files = files) 
           print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'POST',
              'url': 'URL_BASE/ws/admin.py/usuariosconsaldo',
              'headers': {},
              formData: {
              'country_code': 'MX',
              'user_id': '4532',
              'session_id': '442-4tXk7omYJZt7PGRAaToHVQ==',
              'profile': '123'
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var formdata = new FormData();
              formdata.append("country_code", "MX");
              formdata.append("user_id", "4532");
              formdata.append("session_id", "442-4tXk7omYJZt7PGRAaToHVQ==");
              formdata.append("profile", "123");
              var requestOptions = {
              method: 'POST',
              body: formdata,
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/admin.py/usuariosconsaldo", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request POST 'URL_BASE/ws/admin.py/usuariosconsaldo' \ --form 'country_code=MX' \ --form 'user_id=4532' \ --form 'session_id=442-4tXk7omYJZt7PGRAaToHVQ==' \ --form 'profile=123'`
        }
      ]
    }
  
    return(

      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="collection-of-balances">Collection of Balances</h1>
        <p>
          List of all profiles, with electronic wallets and the aggregate balance of
          all users who have the mentioned profile.
        </p>
        <p>
          <span className="label post">POST:</span>
          <span> </span> <code>URL_BASE + /ws/admin.py/usuariosconsaldo</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters the service receives, must be indicated in the request
          body.
        </p>
        <div className='overflow-auto'>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
              <td align="center">ES &#40;Spain&#41;</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Identifier of the administrator user making the request
              </td>
              <td align="center">N/A</td>
              <td align="center">4532</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
              <td align="center">385-XadgYoWixbzN_wqWc3Yyog==</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sing the request without having to log in
              </td>
              <td align="center">N/A</td>
              <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
            </tr>
            <tr>
              <td align="left">profile</td>
              <td align="center">Integer</td>
              <td align="center">No</td>
              <td align="center">
                Identifier of the profile for which we want to know the aggregate
                balance of the users who have the mentioned profile
              </td>
              <td align="center">N/A</td>
              <td align="center">123</td>
            </tr>
          </tbody>
        </table>
        </div>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;
          </li>
          <li>
            <code>data:</code> A list of lists in which each one contains the values
            for each of the columns.
          </li>
          <li>
            <code>number_of_results:</code> Number of results that appear in
            &#39;data&#39;
          </li>
          <li>
            <code>totals:</code> List with the sum of the values of each of the
            columns. Has the value <code>null</code> for fields that cannot be
            added.
          </li>
          <li>
            <code>columns:</code> Name of each of the values found in each of the
            great list.
          </li>
          <li>
            <code>result_limit:</code> Maximum number of results that come in the
            query
          </li>
          <li>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>level:</code> Error importance level.
          </li>
          <li>
            <code>message:</code> Error message.
          </li>
          <li>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          To be able to run this service, it is necessary to do it from a user
          logged into the system having an administrator profile. With this service,
          the aggregate of the balances of each profile of the system is returned,
          as long as no filter is applied.
        </p>
        </div>

<div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
        </div>
      </Fragment>
    );
  }
export default Usuarios_con_saldo_EN;
