import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Pago_diferido_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": <boolean>, 
          "codtran": <string>, 
          "currency_data": { 
            "abbreviation": <string>, 
            "suffix": <string>, "format": <string>,
             "simbolo": <string>, 
             "prefix": <string>, 
             "decimals": <decimal>, 
             "id": <integer> 
            }, "raw_amount": <decimal>, 
            "currency": <string>, 
            "commission_user_card": <decimal>, 
            "user_commission": <decimal>, 
            "amount": <string> "elapsed": 
            <float> 
          }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: ` 
          { 
            "status": false,
           "level": <string>, 
           "message": <string>, 
           "error": <string> 
          }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/pagodiferido.py/pagodiferido"
         payload = {'country_code': 'MX', 'user_id': '4532', 'session_id': '423-yUYOoPNao_8-jV9OI5SVDQ==', 'phone': '7229063245', 'amount': '12.50', 'concept': 'Lorem ipsum dolor sit amet.', 'buyer_country': 'MX', 'pin': '1234', 'currency': 'MXN', 'order_data': '{"xxxxxxx":"xxxx", "yyyyyyy":"yyyy"}', 'entry_data': '{"url":"https://developer.101obex.com/"}'}
          files = [] 
          headers= {} 
          response = requests.request("POST", url, headers=headers, data = payload, files = files) 
        print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests url = "URL_BASE/ws/pagodiferido.py/pagodiferido"
          payload = {'country_code': 'MX', 'user_id': '4532', 'session_id': '423-yUYOoPNao_8-jV9OI5SVDQ==', 'phone': '7229063245', 'amount': '12.50', 'concept': 'Lorem ipsum dolor sit amet.', 'buyer_country': 'MX', 'pin': '1234', 'currency': 'MXN', 'order_data': '{"xxxxxxx":"xxxx", "yyyyyyy":"yyyy"}', 'entry_data': '{"url":"https://developer.101obex.com/"}'}
           files = [] 
           headers= {} 
           response = requests.request("POST", url, headers=headers, data = payload, files = files) 
         print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'POST',
              'url': 'URL_BASE/ws/pagodiferido.py/pagodiferido',
              'headers': {},
              formData: {
              'country_code': 'MX',
              'user_id': '4532',
              'session_id': '423-yUYOoPNao_8-jV9OI5SVDQ==',
              'phone': '7229063245',
              'amount': '12.50',
              'concept': 'Lorem ipsum dolor sit amet.',
              'buyer_country': 'MX',
              'pin': '1234',
              'currency': 'MXN',
              'order_data': '{"xxxxxxx":"xxxx", "yyyyyyy":"yyyy"}',
              'entry_data': '{"url":"https://developer.101obex.com/"}'
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var formdata = new FormData();
              formdata.append("country_code", "MX");
              formdata.append("user_id", "4532");
              formdata.append("session_id", "423-yUYOoPNao_8-jV9OI5SVDQ==");
              formdata.append("phone", "7229063245");
              formdata.append("amount", "12.50");
              formdata.append("concept", "Lorem ipsum dolor sit amet.");
              formdata.append("buyer_country", "MX");
              formdata.append("pin", "1234");
              formdata.append("currency", "MXN");
              formdata.append("order_data", "{\"xxxxxxx\":\"xxxx\", \"yyyyyyy\":\"yyyy\"}");
              formdata.append("entry_data", "{\"url\":\"https://developer.101obex.com/\"}");
              var requestOptions = {
              method: 'POST',
              body: formdata,
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/pagodiferido.py/pagodiferido", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request POST 'URL_BASE/ws/pagodiferido.py/pagodiferido' \ --form 'country_code=MX' \ --form 'user_id=4532' \ --form 'session_id=423-yUYOoPNao_8-jV9OI5SVDQ==' \ --form 'phone=7229063245' \ --form 'amount=12.50' \ --form 'concept=Lorem ipsum dolor sit amet.' \ --form 'buyer_country=MX' \ --form 'pin=1234' \ --form 'currency=MXN' \ --form 'order_data={"xxxxxxx":"xxxx", "yyyyyyy":"yyyy"}' \ --form 'entry_data={"url":"https://developer.101obex.com/"}'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="buy-via-credit">Buy via Credit</h1>
        <p>This service is used to buy using credit &#40;delayed&#41;.</p>
        <p>
          <span className="label post">POST:</span>
          <span> </span> <code>URL_BASE + /ws/pagodiferido.py/pagodiferido</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <div className='overflow-auto'>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
              <td align="center">ES &#40;Spain&#41;</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Identifier of the user making the request &#40;Affiliate&#41;
              </td>
              <td align="center">N/A</td>
              <td align="center">4532</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
              <td align="center">385-XadgYoWixbzN_wqWc3Yyog==</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
              <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
            </tr>
            <tr>
              <td align="left">phone</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Telephone number of the user who makes the purchase
              </td>
              <td align="center">N/A</td>
              <td align="center">7229063245</td>
            </tr>
            <tr>
              <td align="left">buyer_country</td>
              <td align="center">
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">
                Country code in which the user who makes the purchase resides
              </td>
              <td align="center">N/A</td>
              <td align="center">ES &#40;Spain&#41;</td>
            </tr>
            <tr>
              <td align="left">pin</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">User Pin/Password &#40;Affiliate&#41;</td>
              <td align="center">N/A</td>
              <td align="center">1234</td>
            </tr>
            <tr>
              <td align="left">concept</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">Object associated to the request&#39;</td>
              <td align="center">N/A</td>
              <td align="center">&quot;Lorem ipsum dolor sit amet.&quot;</td>
            </tr>
            <tr>
              <td align="left">amount</td>
              <td align="center">Decimal</td>
              <td align="center">Yes</td>
              <td align="center">
                Amount to send to the user indicated with &#39;telefono_dst&#39;
              </td>
              <td align="center">N/A</td>
              <td align="center">12.50</td>
            </tr>
            <tr>
              <td align="left">currency</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                Currency with which the &#39;Annex&#39; operation will be carried
                out
              </td>
              <td align="center">N/A</td>
              <td align="center">
                MXN &#40;Mexican peso&#41;, EUR &#40;European Countries&#41;
              </td>
            </tr>
            <tr>
              <td align="left">order_data</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                JSON with all the necessary parameters to indicate in the callback
                of the operation
              </td>
              <td align="center">N/A</td>
              <td align="center">
                &#39;&#123;&quot;xxxxxxx&quot;:&quot;xxxx&quot;,
                &quot;yyyyyyy&quot;:&quot;yyyy&quot;&#125;&#39;
              </td>
            </tr>
            <tr>
              <td align="left">entry_data</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">JSON with the calling url for the callback</td>
              <td align="center">N/A</td>
              <td align="center">
                &#39;&#123;&quot;url&quot;: &quot;
                <a href='https://developer.101obex.com/"%7D&#39;'>
                  https://developer.101obex.com/&quot;&#125;&#39;
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>currency_data:</code> Contains the different details of the
            currency used in the operation.
          </li>
          <li>
            <code>abbreviation:</code> The abbreviated name of the currency
            &#40;EUR, MXN&#41;.
          </li>
          <li>
            <code>suffix:</code> Indicates the suffix that is applied in the
            currency format &#40;pesos, euros&#41;.
          </li>
          <li>
            <code>format:</code> The full format that applies to the currency, it
            includes the suffix and the prefix.
          </li>
          <li>
            <code>simbolo:</code> The symbol associated to the currency &#40;€, ¢,
            $&#41;.
          </li>
          <li>
            <code>prefix:</code> The prefix that is applied in the currency format.
          </li>
          <li>
            <code>decimals:</code> The maximum number of decimal places that will be
            included in the currency format.
          </li>
          <li>
            <code>id:</code> Identifier of the currency in BBDD.
          </li>
          <li>
            <code>raw_amount:</code> Negative transaction amount without applying
            the format.
          </li>
          <li>
            <code>currency:</code> Short name of the currency used in the operation,
            it matches the abbreviation.
          </li>
          <li>
            <code>commission_user_card:</code> Commission that would be applied if
            the payment is made by card.
          </li>
          <li>
            <code>codtran:</code> Transaction code that identifies the executed
            operation.
          </li>
          <li>
            <code>user_commission:</code> Commission that would be applied if the
            payment is made by card.
          </li>
          <li>
            <code>amount:</code> Negative transaction amount applying the format.
          </li>
          <li>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>level:</code> Error importance level.
          </li>
          <li>
            <code>message:</code> Error message.
          </li>
          <li>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          By means of this service we can make a bought on credit for a product
          &#40;making the payment at another time&#41;, to complete the operation,
          it is necessary for the buyer user to sign the operation with the
          signature endpoint or the sms, indicating the otp received by sms.
          <br></br>
          When several days have passed, a GET request is made to the URL indicated
          in the <code>input_data</code> sending the parameters indicated in the{' '}
          <code>order_data</code>, in addition to:
        </p>
        <ul>
          <li>
            <code>OK:</code> &amp;sign=&lt; signature with the user&#39;s private
            key to certify the response &gt;&amp;status=true&amp;message=La
            operación se ha realizado con éxito
          </li>
          <li>
            <code>KO:</code> &amp;codtran:&lt; initial transaction code
            &gt;&amp;tipo_pago=denegado_diferido&amp;message=No ha sido posible
            realizar el cobro diferido&amp;status=false
          </li>
        </ul>
        </div>

      <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
        </div>
      </Fragment>
    );
  }
export default Pago_diferido_EN;
