import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, InputGroup, Alert, Spinner, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/img/newAvapCloud/AVAP Cloud_Business_Logo.png';
import logo2 from '../../assets/img/newAvapCloud/AVAP_Cloud_Professional-logo.png';
import foto_professional from '../../assets/img/newAvapCloud/Foto_Cloud_latina.png';
import foto_cloud from '../../assets/img/newAvapCloud/Foto_Cloud_business.png';
import './Avapcloud.css';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ErrorMessage, Field, Formik } from 'formik';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { useHistory } from 'react-router-dom';
import PaymentConfirmation from '../../components/Modals/paymentConfirmation';
import AddCreditCard from '../../components/Modals/addCreditCard';
import SessionService from '../../services/SessionsService';
import SessionStorageHandler from '../../handlers/SessionStorageHandler';
import { firstMonthPrice } from '../../services/AmountService';
import ReactGA from 'react-ga';
import config from '../../configs/appConfig.json';

import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'


const Avapcloud = () => {

  const analyticsTags = [
    'G-KKCW4SZLW4',

  ];


  const analytics = Analytics({
    app: 'Avap Cloud',
    plugins: [
      googleTagManager({
        containerId: 'GTM-W6MGQTXW'
      })
    ]
  })


  //ReactGA.initialize('UA-170480071-2');


  let { token } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [selectedOption, setSelectedOption] = useState('NO_AVAP_ID');
  const [reviewText, setReviewText] = useState('Registra tus datos y crea tu cuenta');
  const [error, setError] = useState('');
  const [totalPrice, setTotalPrice] = useState('');
  const [proratedPrice, setProratedPrice] = useState(0);
  const [plansName, setPlansName] = useState('Cloud Bussines');
  const [signin, setSignin] = useState(false)
  const [formTouched, setFormTouched] = useState(false);
  const [formTouched2, setFormTouched2] = useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [card_id_stripe, setCardID] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cards_, setCards] = useState([]);
  const [newadded_card, setAddedCard] = useState('');
  const [susCription, setSusCription] = useState(2);
  const [annualPayment, setAnnualPayment] = useState(false);
  const [TotalPriceMonthly, settotalPriceMonthly] = useState(0)
  const [TotalPriceYear, setTotalPriceYear] = useState(0)
  const [ExtraProjectsPlan, setextraProjectsPlan] = useState(0);
  const [ProjectDesc, setprojectDesc] = useState('');
  const [ProjectPriceMonthly, setprojectPriceMonthly] = useState(0);
  const [ProjectPriceYear, setprojectPriceYear] = useState(0);
  const [showResume, setShowResume] = useState(false);
  const [formObject, setFormObject] = useState({})



  const [monthlyPrice, setMonthlyPrice] = useState(0)


  const [success, setSuccess] = useState('');
  const [parte, setParte] = useState('AVAP_ID')
  const history = useHistory();
  const startPayment = () => {
    setLoading(true);
    setSuccess('');
    setError('');
  }
  

  const handleSwitch = () => {
    const yy = !annualPayment;
    setAnnualPayment(!annualPayment);
    const p = yy ? (firstMonthPrice(monthlyPrice) + (monthlyPrice * (9))) : firstMonthPrice(monthlyPrice);
    setProratedPrice(p)
    setTotalPrice(yy ? (monthlyPrice*10).toFixed(2) : (monthlyPrice).toFixed(2))
  }


  const onCloseAction = () => {

    setShow(false);
  }

  const OnSuccesAction = () =>{
    
    setShow(false)
    if (!error) {
      history.push(`/verify_cloud_signup/${susCription == 2 ? 'bussines':'professional'}`);
    }
    setShow(false);
  }

  const makePayment = async () => {
    try {
      startPayment();
      setLoading(true);
      
      const payload = { card_stripe_id: card_id_stripe, annual: annualPayment };
      const result = await ObexRequestHandler.post('/signup_avapcloud', payload);
      // const payload = { card_stripe_id: 5 };
      // const result = await ObexRequestHandler.post('/extradevelopers/buy', payload);
      const { success, message, data } = result;
      if (!success) throw { message };
      setSuccess(data);
      OnSuccesAction();
      /*setTimeout(() => {
        onSuccess();
      }, 5000);*/ // TODO MIRAR SI HABILITAR ESTO
    } catch (error) {
      setError(error.message || error);
      console.error('ERROR BUYING EXTRA PROJECTS SLOTS ', error);
    }
    setSignin(false);
    setLoading(false);
  }
  const handleClick = (option) => {
    setSelectedOption(option);
    setError('');
    if (option === 'AVAP_ID') {
      setReviewText('Registra tus datos y crea tu cuenta');
    } else if (option === 'NO_AVAP_ID') {
      setReviewText('Registra tus datos y crea tu cuenta');
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const changePass = (e) => {
    setPassword(e.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const formatCardNumber = (value) => {
    const cleaned = value.replace(/\D+/g, '');
    const match = cleaned.match(/.{1,4}/g);
    return match ? match.join(' ') : '';
  };

  const handleKeyDown = (event) => {
    if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete'  && event.key !== 'Tab') {
      event.preventDefault();
    }
  };

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .required('correo electrónico requerido')
      .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'correo electrónico incorrecto'),
    password: Yup.string()
      .required('contraseña requerida')
      .matches(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])([^\s]){8,200}$/, 'La contraseña debe tener 8 caracteres e incluir al menos una letra y un número'),
    terms: Yup.boolean()
      .oneOf([true], 'acepta los terminos')
      .required('acepta los terminos'),
  });

  const SignupSchema2 = Yup.object().shape({
    email: Yup.string()
      .required('email requerido')
      .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, 'email incorrecto'),
    password: Yup.string()
      .required('password requerido')
      .matches(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])([^\s]){8,200}$/, 'La contraseña debe tener 8 caracteres e incluir al menos una letra y un número'),
    cardNumber: Yup.string()
      .required('Se requiere una numero de tarjeta')
    // .matches(/^\d{13,18}$/, 'Must have more than 13 numbers or less than 18 numbers')
    // .min(13, 'Card number invalid')
    // .max(18, 'Card number invalid')
    ,
    month: Yup.number()
      .required('mes requerido')
      .min(1, 'mes invalido')
      .max(12, 'mes invalido'),
      
    year: Yup.date()
    .required('año requerido')
    .min(
      new Date().getFullYear(),
      'año invalido'
    ),
    cvv: Yup.string()
      .required('cvv requerido')
      .min(3, 'cvv invalido'),
    terms: Yup.boolean()
      .oneOf([true], 'acepta los terminos')
      .required('acepta los terminos'),

  });

  const post_login_avapCloud = async (valueForm) => {
    setLoading(true);
    
    try {
      const result = await SessionService.login(valueForm.email, valueForm.password, false);

    if (!result.error)
      {

        setError(result.error);
        getCards();

        setShow(true);

      }else{
        if (result.error === 'sesion_existente' || result.status) {
          SessionStorageHandler.setUserSessionData(result.id_sesion, result.id_usuario);
          setShow(true);
  
        } else {
          if (result.error === 'cuenta_bloqueada') {
            setError('Su cuenta ha sido bloqueada');
          } 
          throw 'Usuario o contraseña incorrecta';
        }
  
      }

      // const result = (await ObexRequestHandler.post('/unique_signin', {
      //   email: valueForm.email,
      //   password: valueForm.password

      // }));
      // console.log(result);
      // if (result.success == false) {
      //   setError(result.message);
      //   setTimeout((): void => setError(''), 5000);
      // } else {
      //   console.log('Login success');
      //   setShow(true);
      // }
    } catch (error) {
      console.error('ERROR POST', error);
      setError(error.message);
      setTimeout((): void => setError(''), 5000);

    }
  };
  // const post_login_avapCloud = async (valueForm) => {
  //   try {
  //     const result = (await ObexRequestHandler.post('/signup_avapcloud', {
  //       email: valueForm.email,
  //       password: valueForm.password

  //     }));
  //     if (result.success == false) {
  //       setError(result.message.replace('Cannot signup.: ', ''))
  //       setTimeout((): void => setError(''), 5000);
  //     } else {
  //       console.log('Login success');
  //     }
  //   } catch (error) {
  //     console.error('ERROR POST', error);
  //     setError(error);
  //     setTimeout((): void => setError(''), 5000);

  //   }
  // };

  const post_signup_avapcloud = async (valueForm) => {
    try {
      
      setSignin(true);
      const result = (await ObexRequestHandler.post('/signup_avapcloud', {
        suscriptionId: susCription,
        email: valueForm.email,
        password: valueForm.password,
        cardNumber: valueForm.cardNumber,
        cardMonthExp: valueForm.month,
        cardYearExp: valueForm.year,
        cardCvv: valueForm.cvv,
        annual: annualPayment
      }));
      setSignin(false);
      setLoading(false);
      if (result.success == false) {
        setError(result.message.replace('Cannot signup.: ', ''));
        setTimeout((): void => setError(''), 5000);

      } else {
        console.log('Login success');
        history.push(`/verify_cloud_signup/${susCription == 2 ? 'bussines':'professional'}`);

      }
      setSignin(false);
    } catch (error) {
      console.error('ERROR POST', error);
      setError(error.message);
      setTimeout((): void => setError(''), 5000);

      setSignin(false)
    }
    setSignin(false);
  };
  const getPricingPlan = async (id) => {
    try {
      const pricing = (
        await ObexRequestHandler.get(`/pricing_plan?id=${id}`, {}, true)
      ).data || {};
      console.log(pricing);
      setTotalPrice(annualPayment ? pricing.subscription_monthly_price * 10 : pricing.subscription_monthly_price);
      setMonthlyPrice(pricing.subscription_monthly_price);
      

      const p = annualPayment ? (firstMonthPrice(pricing.amount) + (pricing.amount * (9))) : firstMonthPrice(pricing.amount);
      setProratedPrice(p);
      setPlansName(pricing.subscription_name)
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
    }
  }

  const getCards = async () => {
    try {
      const result = (await ObexRequestHandler.get('/cards', {}, true)).data || {};
      const { defaultCard, cards } = result;
      const pmCards = [];
      if (cards) {
        for (const c of cards) {
          const isDefault = c.id === defaultCard;
          c.defaultCard = isDefault;
          isDefault ? pmCards.unshift(c) : pmCards.push(c);
          if(cards_.some(e => e.id == c.id)){
            //console.log('exists');
          }else{
            setAddedCard(c.id);
          }
        }
      }
      setCards(pmCards);
  
    } catch (error) {
      console.error('ERROR FETCHING CARDS ', error);
      throw error;
    }
  }





  useEffect(() => {

    if (config.url === 'https://acl.avapcloud.mooo.com') analytics.page()
    console.log(window.location.pathname.toString());
    if (window.location.pathname.toString() === '/professional') token = 'professional';
    if (window.location.pathname.toString() === '/business') token = 'business';

    if (token==='professional') {
      setSusCription(1)
      getPricingPlan(1);
      
    }
    else {
      
      getPricingPlan(2);
      setSusCription(2);
    }

    analyticsTags.forEach(tag => {
      ReactGA.initialize(tag);
    });

    


    if (config.url != 'https://avs-primary-pre.101obex.mooo.com'){
      ReactGA.initialize('G-KKCW4SZLW4');
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Cloud Sign In Page' });}

  });

  const handleModal = () => {
    setShowModal(!showModal);
    console.log('HANDLE MODAL')
    getCards();
  };
  

  const handleCard = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    if (value=='addCard') {
      handleModal();
    } else {
      setCardID(value);

    }
    console.log(value);
    e.preventDefault();
    e.stopPropagation();
  
  }

  return (
    <div className="page-container">
      <div className={`left ${selectedOption === 'AVAP_ID' ? '' : 'marginTopWhenChange'}`}>
        <div className="form">
          <div style={{ minHeight:selectedOption === 'AVAP_ID' ? '0':'-24px' }}></div>
          <img
            src={token == 'professional' || window.location.pathname == '/professional' ? logo2: logo}
            style={{ marginTop: '-42px', marginRight: '250px', width: '70%', maxWidth: '100%' }}
            alt="Logo"
          />
          <div className="div1">
            <div className="t1">Suscríbete hoy mismo</div>
            <div className="p1">
              {reviewText}
            </div>
          </div>
          {/* Botones de selección */}
          <div style={{ marginBottom: '20px' }}>
            <div style={{ width: '100%', display:'flex' }}>
              <button
                className={selectedOption === 'NO_AVAP_ID' ? 'buttonAVAPselect' : 'selected-buttonAVAPselect'}
                onClick={() => handleClick('NO_AVAP_ID')}
                style={{ width:'100%', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              >No tengo AVAP ID
                
              </button>
              <button
                className={selectedOption === 'AVAP_ID' ? 'buttonAVAPselect' : 'selected-buttonAVAPselect'}
                onClick={() => handleClick('AVAP_ID')}
                style={{ width:'100%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                disabled={signin}
              >Tengo AVAP ID
              </button>

            </div>
          </div>

          {/* Formularios basados en la selección */}
          
          {selectedOption === 'AVAP_ID' && (

            <Formik
              initialValues={{
                email: '',
                password: '',
                terms: false
              }}
              validationSchema={SignupSchema}
              onSubmit={(values, { setSubmitting }) => {
                // handleContinueClick2(values);
                post_login_avapCloud(values)
                console.log(values);

                setSubmitting(false); // Esto es para volver a habilitar el botón después de enviar
              }}
            >
              {({ isSubmitting, errors, touched, handleSubmit, handleBlur }) => (
                <div>
                  <Field
                    placeholder="Correo electrónico"
                    className="inputs"
                    name="email"
                    required
                    disabled={signin}
                    onBlur={(e) => {
                      handleBlur(e);
                      setFormTouched(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                    }}
                    style={{ width: '100%', }}
                  />
                  {false && errors.email && touched.email && (
                    <div className="mt-2 msgError">{errors.email}</div>
                  )}
                  <InputGroup className="mt-3" >
                    <Field
                      required
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      disabled={signin}
                      placeholder="Contraseña"
                      className="inputs borderRadiusLeftLogin form-control"
                      style={{ paddingLeft: '10px', height: '54px' }}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFormTouched(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                      }}

                    />
                    <InputGroup.Append>
                      <Button
                        onClick={handleShowPassword}
                        variant="outline-secondary"
                        style={{
                          borderTopRightRadius: 5,
                          borderBottomRightRadius: 5,
                          borderTop: '1px solid #ced4da',
                          borderRight: '1px solid #ced4da',
                          borderBottom: '1px solid #ced4da',
                          borderLeft: '1px solid #ffff',
                          height: '100%',
                        }}
                      >
                        {showPassword ? (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            style={{ color: '#AFAFAF' }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEye}
                            style={{ color: '#AFAFAF' }}
                          />
                        )}
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                  {false && errors.password && touched.password && (
                    <div className="mt-2 msgError">
                      {errors.password}
                    </div>
                  )}
                <div style={{ position:'absolute', width:'34.2vw' }}>
                  {(errors.email != undefined && touched.email)
                  && (
                    <Alert style={{ transform:'translateY(130px)', marginTop:'80px', zIndex: 999 }} variant='danger mt-3'>
                      {errors.email}
                    </Alert>
                  )}

                  {(errors.password != undefined && touched.password)
                  && (
                    <Alert style={{ transform:'translateY(130px)', zIndex: 999 }} variant='danger mt-3'>
                      {errors.password}
                    </Alert>
                  )}


                  {/*}
                  <div style={{ marginTop:'15px', height:'54px', width:'100%', borderColor:'#ced4da', borderWidth:'1px', borderStyle:'solid', borderRadius:'5px', display:'flex' }}>
                  <div className='divI3' style={{ width:'94%', marginTop:'auto', marginBottom:'auto', paddingLeft:'15px' }}>Pago Anual</div>
                  
                  <Form className="obex-form" style={{ width:'5%', marginTop:'auto', marginBottom:'auto', marginLeft:'0%' }}>
                <Form.Row >
         
                    <Form.Check 
                      type="switch"
                      id="custom-switch"
                      label=""
                      onChange={handleSwitch}
                      className="obex-switch"
                    />
   
                </Form.Row>
              </Form>

                  </div>
                  
*/}
</div>
                  <div style={{ display: 'flex', marginTop: '10px' }} className="checkbox-containerAVAP">
                  <div> 
                    <div style={{display:'flex'}}>
                    <div style={{ marginLeft: '10px' }}>
                      <Field disabled={signin} type="checkbox" name="terms" id="" onBlur={(e) => {
                        handleBlur(e);
                        setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                      }} />
                    </div>
                   
                    <div className="fontText1">
                    Acepto los <a href='http://legal.101obex.com/licenses/cloud'>Términos y Condiciones</a> del servicio y he leido la <a href='http://legal.101obex.com/licenses/privacy'>Política de Privacidad</a>.
                    </div>
                  </div>
                    <div className="fontText1">
                      * No se realizará ningún cargo a la tarjeta hasta concluir el periodo de prueba.
                    </div>
                    </div>
                  </div>

                  
                  {false && errors.terms && touched.terms && (
                    <div className="mt-2 msgError">{errors.terms}</div>
                  )}
                  
                  {/* <Button type="submit" className=" custom-button" style={{ display: "block", width: "40%" }} onClick={() => handleSubmit()} disabled={isSubmitting}>
                    Continue
                  </Button> */}

                  {error!='' &&
                  <Alert style={{zIndex: 999, position:'absolute', width:'34%'}} variant='danger mt-3'>
                      {error}
                    </Alert>}

                  <button type="button" className="custom-button-blue textBold"
                    onClick={() => {setError('');setSuccess('');handleSubmit()}}
                    disabled={signin || isSubmitting || Object.keys(errors).length > 0 || !formTouched}
                    style={{ marginTop: selectedOption === 'AVAP_ID' ? '40px':'0px', marginBottom: selectedOption === 'AVAP_ID' ? '110px':'0px' }}>
                    Continuar
                  </button>

                </div>
              )}
                
            </Formik>
            
          )}



          {selectedOption === 'NO_AVAP_ID' && (
            <Formik
              initialValues={{
                email: '',
                password: '',
                cardNumber: '',
                month: '',
                year: '',
                cvv: '',
                terms: false
              }}
              validationSchema={SignupSchema2}
              onSubmit={(values, { setSubmitting }) => {

                if (values.month.length<2)  values.month = '0'+values.month;
                //post_signup_avapcloud(values);
                setShowResume(true);
                console.log('xxxxxxx');
                console.log(values);
                console.log('xxxxxxx');
                setFormObject(values);

                setSubmitting(false); // Esto es para volver a habilitar el botón después de enviar
              }}
            >
              {({ values, isSubmitting, errors, touched, handleSubmit, handleBlur, setFieldValue }) => (
                <div>
                  <Field
                    placeholder="Correo electrónico"
                    className="inputs"
                    name="email"
                    required
                    disabled={signin}
                    style={{ width: '100%' }}
                    onBlur={(e) => {
                      handleBlur(e);
                      setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                    }}

                  />

                  {false && errors.email && touched.email && (
                    <div className="mt-2 msgError">{errors.email}</div>
                  )}
                  <InputGroup className="mt-3">
                    <Field
                      required
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Contraseña"
                      name="password"
                      disabled={signin}
                      className="inputs borderRadiusLeftLogin  form-control"
                      style={{ paddingLeft: '10px', height: '54px' }}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                      }}

                    />
                    <InputGroup.Append>
                      <Button
                        onClick={handleShowPassword}
                        variant="outline-secondary"
                        style={{
                          borderTopRightRadius: 5,
                          borderBottomRightRadius: 5,
                          borderTop: "1px solid #ced4da",
                          borderRight: "1px solid #ced4da",
                          borderBottom: "1px solid #ced4da",
                          borderLeft: "1px solid #ffff",
                          height: "100%",
                        }}
                      >
                        {showPassword ? (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            style={{ color: "#AFAFAF" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEye}
                            style={{ color: "#AFAFAF" }}
                          />
                        )}
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>

                  {false &&errors.password && touched.password && (
                    <div className="mt-2 msgError">{errors.password}</div>
                  )}
                  <Field
                    name="cardNumber"
                    render={({ field }) => (
                      <input
                        {...field}
                        placeholder="Número de tarjeta"
                        disabled={signin}
                        className="inputs mt-3"
                        inputMode="numeric"
                        minLength="13"
                        maxLength="19"
                        required
                        style={{ width: "100%" }}
                        value={values.cardNumber}
                        onChange={(e) => {
                          const formattedValue = formatCardNumber(e.target.value);
                          setFieldValue('cardNumber', formattedValue);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                          setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                        }}
                      />
                    )}
                  />


                  {false && errors.cardNumber && touched.cardNumber && (
                    <div className="mt-2 msgError">{errors.cardNumber}</div>
                  )}

                  <div style={{ display: "flex", width: "60%" }} className="mt-3">
                    <div style={{ width: "40", display: "flex" }}>
                      <Field disabled={signin}
                        className="inputsCVV"
                        type="text"
                        placeholder="Mes"
                        name="month"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        onKeyDown={handleKeyDown}
                        maxLength="2"
                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, width: "50%", borderRight: "none", marginBottom: "0" }}
                        onBlur={(e) => {
                          handleBlur(e);
                          setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                        }} />
                      <div id="divided" className="d-flex align-items-center " style={{ marginLeft: "-2px" }}>
                        <div className="line border" style={{ height: "70%" }}></div>
                      </div>

                      <Field disabled={signin} className="inputsCVV" type="text" placeholder="Año" name="year" inputMode="numeric" pattern="[0-9]*" onKeyDown={handleKeyDown} minLength="4" maxLength="4" style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, width: "50%", borderLeft: "none", marginBottom: "0" }} onBlur={(e) => {
                        handleBlur(e);
                        setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                      }} />

                    </div>

                    <div style={{ width: "100%", marginLeft: "15px" }}>
                      <Field disabled={signin} className="inputsCVV" placeholder="CVV" name='cvv' minLength="3" maxLength="4" inputMode="numeric"
                        pattern="[0-9]*" onKeyDown={handleKeyDown} style={{ width: "60%", marginBottom: "0" }} onBlur={(e) => {
                          handleBlur(e);
                          setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                        }} />
                    </div>
                  </div>

                  {false && errors.month && touched.month && (
                    <div className="mt-2 msgError">{errors.month}</div>
                  )}


                  {false && errors.year && touched.year && (
                    <div className="mt-2 msgError">{errors.year}</div>
                  )}

                  {false && errors.cvv && touched.cvv && (
                    <div className="mt-2 msgError">{errors.cvv}</div>
                  )}


{/*}
<div style={{ marginTop:'15px', height:'54px', width:'100%', borderColor:'#ced4da', borderWidth:'1px', borderStyle:'solid', borderRadius:'5px', display:'flex' }}>
                  <div className='divI3' style={{ width:'94%', marginTop:'auto', marginBottom:'auto', paddingLeft:'15px' }}>Pago Anual</div>
                  
                  <Form className="obex-form" style={{ width:'5%', marginTop:'auto', marginBottom:'auto', marginLeft:'0%' }}>
                <Form.Row >
         
                    <Form.Check 
                      type="switch"
                      id="custom-switch"
                      label=""
                      onChange={handleSwitch}
                      className="obex-switch"
                    />
   
                </Form.Row>
              </Form>

                  </div>

*/}
                  <div style={{ display: 'flex', marginTop: '10px' }} className="checkbox-containerAVAP">
                  <div> 
                    <div style={{display:'flex'}}>
                    <div style={{ marginLeft: '10px' }}>
                      <Field disabled={signin} type="checkbox" name="terms" id="" onBlur={(e) => {
                        handleBlur(e);
                        setFormTouched2(true); // Marca el formulario como tocado cuando se desenfoque cualquier campo
                      }} />
                    </div>
                   
                    <div className="fontText1">
                    Acepto los <a href='http://legal.101obex.com/licenses/cloud'>Términos y Condiciones</a> del servicio y he leido la <a href='http://legal.101obex.com/licenses/privacy'>Política de Privacidad</a>.
                    </div>
                    </div>
                    <div className="fontText1">
                      * No se realizará ningún cargo a la tarjeta hasta concluir el periodo de prueba.
                    </div>
                    </div>
                  </div>
                  
                  {false && errors.terms && touched.terms && (
                    <div className="mt-2 msgError">{errors.terms}</div>
                  )}
                <div style={{position:'absolute', width:'33.4vw'}}>
                  {(errors.email != undefined && touched.email)
                  && (
                    <Alert style={{zIndex: 999}}variant='danger mt-3'>
                      {errors.email}
                    </Alert>
                  )}

                  {(errors.password != undefined && touched.password)
                  && (
                    <Alert style={{zIndex: 999}}variant='danger mt-3'>
                      {errors.password}
                    </Alert>
                  )}


                  {(errors.cardNumber != undefined && touched.cardNumber)
                  && (
                    <Alert style={{zIndex: 999}}variant='danger mt-3'>
                      {errors.cardNumber}
                    </Alert>
                  )}

                {(errors.month != undefined && touched.month)
                  && (
                    <Alert style={{zIndex: 999}}variant='danger mt-3'>
                      {errors.month}
                    </Alert>
                  )}
                {(errors.year != undefined && touched.year)
                  && (
                    <Alert style={{zIndex: 999}}variant='danger mt-3'>
                      {errors.year}
                    </Alert>
                  )}
                  {(errors.cvv != undefined && touched.cvv)
                  && (
                    <Alert style={{zIndex: 999}}variant='danger mt-3'>
                      {errors.cvv}
                    </Alert>
                  )}
                  {(errors.terms != undefined && touched.terms)
                  && (
                    <Alert style={{zIndex: 999}}variant='danger mt-3'>
                      {errors.terms}
                    </Alert>
                  )}
            </div>



                  <button type="button"
                    className="custom-button-blue textBold"
                    onClick={() => { if (!signin) {setError('');setSuccess('');handleSubmit();handleSubmit()}}}
                    disabled={signin || isSubmitting || Object.keys(errors).length > 0 || !formTouched2}
                    style={{ marginTop: '40px', marginBottom: '140px' }}>

                    Continuar {signin && <Spinner animation="border" size="sm" style={{ color: '#147bce' }} />}
                  </button>
                  {error!='' &&
                  <Alert style={{ zIndex: 999, marginTop:'-100px' }} variant='danger mt-3'>
                      {error}
                    </Alert>}
                
                </div>
              )}

            </Formik>
          )}
{show &&

<Modal dialogClassName="addons-dialog w-75 obex-dialog"
  show={show}
  onHide={onCloseAction}
  animation={false}>
  <Modal.Header closeButton className="border-0 pb-0">
    <Modal.Title className="text-center w-100">
      <Row>
        <Col className="addons-title">
          <span >Suscribete a AVAP Cloud {token === 'professional'  || window.location.pathname == '/professional' ? 'Profesional':'Business'}</span>
        </Col>
      </Row>
      <Row>
        <Col md="10" className="mx-auto text-center modal-subtitle">
          <span className="text-center d-block">Por favor confirma tu compra y procede a pagar tu nueva suscripcion</span>
        </Col>
      </Row>
    </Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <PaymentConfirmation
      actionSubmit={makePayment}
      handleGoBack={() => {
        // console.log("BACK");
        setShow(false);
      }}
      handleAnnualPayment={null}
      nameServiceSelected="AVAP Cloud Business"
      amountMonth={parseInt(totalPrice)}
      amountYear={(parseInt(totalPrice) * 10) / 12}
      processingPayment={loading}
      success={success}
      error={error}
      handleCard={handleCard}
      isBlue={true}
      cards={cards_}
      prorated={true}
      addedCard={newadded_card}
      spanish={true}
      />
  </Modal.Body>
</Modal>  
}
    {showModal && <AddCreditCard show={showModal} handleClose={handleModal}  spanish={true}/>}
          {/* <div style={{ marginTop: "20px" }}>
            <button type="button" className="buttonAVAP" onClick={handleOpenModal}>
              Continuar
            </button>
          </div> */}
        </div>
      </div>



      
      <div className={showResume ? 'right':'right-2'} style={{ justifyContent: 'end !important' }}>



      { showResume ?
        <div className="formI">
          <h2 className="hI" style={{ marginTop: '0px' }}>Your Products</h2>
          <div className="divI1">
            <div className="divI2 boxShadowCardBottom">{plansName === 'Professional'  || window.location.pathname == '/professional' ? 'AVAP Cloud Professional': plansName }</div>
            <div className="divI3">
              <div>Tx 5,000. One project</div>
              <div>${parseInt(totalPrice).toFixed(2)} {(annualPayment ? 'USD/año': 'USD/mes')}</div>
            </div>
          </div>
          {/*}
          <div className="divI1">
            <div className="divI2 boxShadowCardBottom">Add Ons</div>
            <div className="divI3">
              <div>One more project</div>
              <div>24€/month</div>
            </div>
          </div>
          {*/}
          <div className="divI1">
            <div className="divI4 boxShadowCardBottom">
              <div style={{ color: "#147BCE", fontFamily: "Regular Intro Bold Alt" }}>
                Total Price
              </div>
              <div style={{ fontFamily: "Regular Intro Bold Alt", color: "black" }}>
                ${parseInt(totalPrice).toFixed(2)} USD
              </div>
            </div>
            <div className="divI3">
              You’ll pay this amount plus any overages on the first day of the month. You’ll pay a prorated price of ${parseInt(proratedPrice.toString()).toFixed(2)} USD today
            </div>
          </div>
          <div style={{  display:'flex'}}>
          
          <div style={{ width:'50%' }}>*Taxes may apply</div> <div style={{width:'50%', textAlign:'end'}}>
            <button onClick={()=>{post_signup_avapcloud(formObject);}} style={{ alignSelf:'flex-end' }} 
          className='custom-button-blue textBold'>Check out {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}</button></div>
            </div>
        {/*}
          <Form className="obex-form">
                <Form.Row>
                  <Col md="6">
                    <Form.Check 
          
                      type="switch"
                      id="custom-switch"
                      label="Annual Payment"
                      onChange={handleSwitch}
                      className="obex-switch"
                    />
                    </Col>
                    <Col md="6" className="text-right">
                      <span>*Taxes may apply</span>
                    </Col>
                </Form.Row>
              </Form>
*/}

        </div>

        : <>
        <div id="principal">
            
          <div style={{ 
            padding:'10px', 
            marginTop:'20px', 
            marginLeft:'80px', 
            marginRight:'0px', 
            backgroundColor:'#d9d9d9',
            borderTopLeftRadius:'10px',
            borderBottomLeftRadius:'10px', 
            display:'flex'
            }}>
            
            <div style={{ width:'80%' }}>
              <div className='landingFont2 textBold' style={{ color:'black' }}>
                Tecnología AVAP +
              </div>

              <div className='landingFont2 textBold' style={{ color:'black' }}>
                Infraestructura Premium
              </div>
            </div>
            
            <div style={{ lineHeight:'25px', width:'20%', borderLeftStyle:'solid', borderLeftWidth:'1px', borderLeftColor:'white', paddingLeft:'20px' }}>

            <div className='textBold landingFont2'>30 DÍAS</div>
            <div className='textBold'>de prueba</div>
            <div className='textBold landingFont2' style={{color:'#4a76b8'}}>GRATIS</div>

            </div>
          
          </div>

          <div style={{ 
            padding:'10px', 
            marginTop:'20px', 
            marginLeft:'80px', 
            marginRight:'80px', 
            backgroundColor:'transparent',
            borderTopLeftRadius:'10px',
            borderBottomLeftRadius:'10px', 
            display:'flex'
            }}>

            <div className='textBold landingFont3b' style={{color:'black', fontSize:''}}>Impulsa tu carrera con nuestra plataforma de desarrollo y gestión completa del ciclo de vida de APIs</div>


            </div>


            <div style={{width:'100%', display:'flex'}}>
      
              <div style={{width:'50%', justifyContent:'center', textAlign:'center', marginTop:'50px'}}>
              <img
            src={token == 'professional'  || window.location.pathname == '/professional'? foto_professional: foto_cloud}
            style={{ marginLeft:'auto', marginRight:'auto', marginTop: '42px', width: '70%', maxWidth: '100%' }}
            alt="Logo"
          />
              </div>

              {token == 'professional' || window.location.pathname == '/professional' &&
              <div style={{width:'50%', textAlign:'start'}}>

                <div className='landingFont3b textBold' style={{ color:'#0473bf', marginTop:'22px', paddingRight:'80px', marginBottom:'20px', fontSize:'1vw'}}>Acerca de tu carrera con AVAP Cloud </div>
                <div style={{paddingLeft:'10px', paddingRight:'80px', fontSize:'1vw'}}>
                  <li>Infraestructura y tecnologia avanzada.</li>
                  <li>Desarrollos con nivel profesional.</li>
                  <li>Acceso a herramientas para diseñar, desplegar, asegurar y monitorear tus APIs.</li>
                  <li>Crecimiento profesional</li>
                </div>

                <div className='landingFont3b textBold' style={{ color:'#0473bf', marginTop:'42px', paddingRight:'80px',fontSize:'1vw' , marginBottom:'20px'}}>Ventajas AVAP Cloud </div>
                <div style={{paddingLeft:'10px', paddingRight:'80px', fontSize:'1vw'}}>
                  <li>Diseño eficiente de APIs virtuales.</li>
                  <li>Seguridad robusta.</li>
                  <li>Monitoreo en tiempo real.</li>
                  <li>Escalabilidad flexibilizada.</li>
                  <li>Adaptable a las necesidades del mercado.</li>
                  <li>Virtualizacion de APIs</li>
                </div>

              

              </div>
} 
{(token != 'professional' && window.location.pathname !== '/professional') &&
              <div style={{width:'50%', textAlign:'start'}}>

                <div className='landingFont3b textBold' style={{  color:'#0473bf', marginTop:'22px', paddingRight:'80px', marginBottom:'20px', fontSize:'1vw' }}>AVAP Cloud: Aliado tecnologico para competir en</div>
                <div style={{paddingLeft:'10px', paddingRight:'60px', fontSize:'1vw'}}>
                  <li>Infraestructura y tecnologia avanzada.</li>
                  <li>Acceso a tecnología e
                  infrastructura de nivel corporativo.</li>
                  <li>Foco en proyectos.</li>
                  <li>Monitoreo constante.</li>

                  <li>Escalabilidad a tu medida.</li>
                  <li>Aumento de productividad.</li>
                  <li>Reducción de tiempos de lanzamiento de productos.</li>
                  <li>Protección completa.</li>
                  <li>Acceso a herramienta para diseñar, desplegar, asegurar y monitorear tus APIs.</li>
                  <li>Crecimiento profesional.</li>
                </div>

              

              </div>
} 
              

            </div>
            <div style={{ marginTop:'40px', display:'flex', justifyContent:'center' }}>

              { token == 'professional'  || window.location.pathname == '/professional' &&
            <button style={{ 
              justifyContent:'center', 
              textAlign:'center', 
              height:'40px', 
              paddingLeft:'15px', 
              paddingRight:'15px', 
              backgroundColor:'#0473bf', 
              color:'white', 
              marginTop:'auto', 
              marginBottom:'auto',
              borderRadius:'10px',
              boxShadow: 'none',
              borderColor:'transparent',
              fontSize:'1vw'
              }} className='textBold'>¡Recursos corporativos a precio de freelance!</button>

      }

{ (token != 'professional' &&  window.location.pathname !== '/professional' ) &&
            <button style={{ 
              justifyContent:'center', 
              textAlign:'center', 
              height:'20px', 
              paddingLeft:'15px', 
              paddingRight:'15px', 
              backgroundColor:'transparent', 
              color:'transparent', 
              marginTop:'auto', 
              marginBottom:'auto',
              borderRadius:'10px',
              boxShadow: 'none',
              borderColor:'transparent',
              fontSize:'40px'
              }} className='textBold'>¡Recurs</button>

      }


              <div style={{width:'20px', minWidth:'20px'}}></div>

              <div className='landingFont1 textBold' style={{color:'#0473bf', width:'150px', textAlign:'center', marginLeft:'10px', marginRight:'10px', textAlignLast:'end'}}>${annualPayment ? monthlyPrice*10 : monthlyPrice}</div>
              <div style={{ width:'40px', maxWidth:'40px', lineHeight:'18px', paddingTop:'20px' }} >USD {annualPayment ? 'anual':'mensual'}</div>
              

</div>

{ (token!='professional'  &&  window.location.pathname !== '/professional' )&&
<div style={{ height:'1px', marginLeft:'50%', width:'50%', backgroundColor:'#d9d9d9' }}></div>
}


</div>
        </>
        }
      </div>







      <Modal show={isModalOpen} onHide={handleCloseModal} centered dialogClassName="custom-modal">
        <Modal.Header closeButton style={{ borderBottom: "0px" }}>
          <div style={{ display: "ruby" }}>
            <Modal.Title style={{ color: "black", fontFamily: "Regular Intro Bold Alt" }}>
              Select Your Add-ons
            </Modal.Title>
            <div style={{ textAlign: "center", color: "black" }}>
              Select your add-ons and continue to checkout. Not sure? you can add in-add at a later time.
            </div>
          </div>
        </Modal.Header>
        <Modal.Body style={{ borderBottom: "0px" }}>
          <div className="">
            <div className="text-center" style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
              <div className="modaldiv1">
                <div style={{ placeContent: "center" }}>Select Plan</div>
                <div style={{ color: "black", fontFamily: "Regular Intro Bold Alt", placeContent: "center" }}>Start Up</div>
              </div>
              <div className="modaldiv1">
                <div style={{ placeContent: "center" }}>Estimated monthly cost:</div>
                <div style={{ color: "black", fontFamily: "Regular Intro Bold Alt", placeContent: "center" }}>29.00€*</div>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }} className="modaldiv2">
              <div style={{ width: "40%" }}>
                <div style={{ color: "black", fontFamily: "Regular Intro Bold Alt" }}>Add one project</div>
                <div>3 days included with your plan. Gain further insight with additional history and API access.</div>
              </div>
              <div style={{ width: "20%", placeContent: "center" }}>
                <button className="buttonAVAP" style={{ width: "100%" }}>continue</button>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }} className="modaldiv2">
              <div style={{ width: "40%" }}>
                <div style={{ color: "black", fontFamily: "Regular Intro Bold Alt" }}>Add one project</div>
                <div>3 days included with your plan. Gain further insight with additional history and API access.</div>
              </div>
              <div style={{ width: "40%", display: "flex" }}>
                <div style={{ width: "50%", placeContent: "center" }}>
                  <div style={{ color: "black", fontFamily: "Regular Intro Bold Alt" }}>5.00€/mo*</div>
                </div>
                <div style={{ width: "50%", placeContent: "center" }}>
                  <button className="buttonAVAP" style={{ width: "100%" }}>continue</button>
                </div>
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "0px" }}>
          <div style={{ textAlign: "center", width: "100%" }}>
            <button className="buttonAVAP" onClick={handleCloseModal} style={{ marginBottom: "20px", width: "50%" }}>Continue to Checkout</button>
            <div>*Taxes and overages may apply</div>
          </div>
        </Modal.Footer>
        
      </Modal>

    </div>
  );
};

export default Avapcloud;
