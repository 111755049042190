import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../components/DisplayCodeKo/DisplayCodeKo';

const Obtener_Shake_v2_3_EN = () => {

  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status": <boolean>,
        "result": {
            "phrase": <string>,
            "author": <string>,
            "subtitle": <string>,
            "type": <string>,
            "id": <integer>
        },
        "elapsed": <float>
    }`,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
    url = "URL_BASE/ws/shakev2.py/obtener?country_code=MX"
    payload = {}
    headers= {}
    response = requests.request("GET", url, headers=headers, data = payload)
    print(response.text.encode('utf8'))`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
    url = "URL_BASE/ws/shakev2.py/obtener?country_code=MX"
    payload = {}
    headers= {}
    response = requests.request("GET", url, headers=headers, data = payload)
    print(response.text.encode('utf8'))`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
  var options = {
  'method': 'GET',
  'url': 'URL_BASE/ws/shakev2.py/obtener?country_code=MX',
  'headers': {}
  };
  request(options, function (error, response) { 
  if (error) throw new Error(error);
  console.log(response.body);
  });`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var requestOptions = {
  method: 'GET',
  redirect: 'follow'
  };
  fetch("URL_BASE/ws/shakev2.py/obtener?country_code=MX", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request GET 'URL_BASE/ws/shakev2.py/obtener?country_code=MX'`
      }
    ]
  }

  return (
    <Fragment>

      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 >Obtain Shake v2 3</h1>
          <p>This service is used to obtain a quote of the day.</p>
          <p>
            <span className="label get">GET:</span>{' '}
            <code>URL_BASE + /ws/shake.py/obtener</code>
          </p>
          <h2 >Receives:</h2>
          <p>
            All parameters are sent in the querystring of the call, so a percentage
            encoding for URI must be applied &#40;aka URL encoding&#41;.
          </p>
          <table>
            <thead>
              <tr>
                <th align="left">Parameter</th>
                <th align="center">Format</th>
                <th align="center">Obligatory</th>
                <th align="center">Description</th>
                <th align="center">Default value</th>
                <th align="center">Example</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="left">country_code</td>
                <td align="center">
                  ISO 3166 alpha-2 &#40;
                  <a href="https://www.iso.org/glossary-for-iso-3166.html">
                    https://www.iso.org/glossary-for-iso-3166.html
                  </a>
                  &#41;
                </td>
                <td align="center">Yes</td>
                <td align="center">Country code in which the user resides</td>
                <td align="center">N/A</td>
                <td align="center">ES &#40;Spain&#41;</td>
              </tr>
              <tr>
                <td align="left">language</td>
                <td align="center">in &#40;English&#41;, is &#40;Spanish&#41;</td>
                <td align="center">No</td>
                <td align="center">Language in which to get the phrase</td>
                <td align="center">is</td>
                <td align="center">in &#40;English&#41;, is &#40;Spanish&#41;</td>
              </tr>
              <tr>
                <td align="left">type</td>
                <td align="center">String</td>
                <td align="center">No</td>
                <td align="center">
                  Filter phrases by type &#40;donation, gift, phrase&#41;
                </td>
                <td align="center">N/A</td>
                <td align="center">phrase</td>
              </tr>
              <tr>
                <td align="left">affiliate_id</td>
                <td align="center">Integer</td>
                <td align="center">No</td>
                <td align="center">Affiliate identifier to use as a filter</td>
                <td align="center">N/A</td>
                <td align="center">4532</td>
              </tr>
            </tbody>
          </table>
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>
          <h3 >Answer JSON OK:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h2>Where:</h2>
          <ul>
            <li>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li>
              <code>result:</code>{' '}
              <blockquote>
                <ul>
                  <li>
                    <code>phrase:</code> Quote phrase
                  </li>
                  <li>
                    <code>author:</code> Author of the phrase
                  </li>
                  <li>
                    <code>subtitle:</code> Subtitle of the phrase
                  </li>
                  <li>
                    <code>type:</code> Type of phrase &#40;donation, gift, phrase&#41;
                  </li>
                  <li>
                    <code>id:</code> Quote phrase identifier
                  </li>
                </ul>
              </blockquote>
            </li>
            <li>
              <code>elapsed:</code> Operation execution time.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 >Business logic:</h2>
          <p>With this service you get a quote phrase.</p>

        </div>
        <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>

        </div>

      </div>


    </Fragment>
  );
}
export default Obtener_Shake_v2_3_EN;
