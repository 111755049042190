import React, { Fragment } from 'react';

const Add_Ons = () => (
  <Fragment>
    <h1 >Add-Ons</h1>
    <p>
      Add-ons are collections of attributes or features that can be added to
      your project. They allow for personalization, adaptation to your needs,
      and optimization of usage. You can activate add-ons in different processes
      throughout the acquisition of a plan or the life of a project. You can
      also find in the Setting section an Add-on chapter in the settings section
      dedicated exclusively to the administration of these components.
    </p>
    <p>
      Currently, the following <em>Add-ons</em> are available:
    </p>
    <h1 >
      <strong>
        <em>Project slot</em>
      </strong>
    </h1>
    <img src="./image1.png" width="200" />

    <p>
      <br></br>
    </p>
    <p>
      Allows you to add a new empty slot to later activate a project and start
      working with it. Plans have a defined limit for projects and active slots.
      This add-on allows expansion to the maximum permitted slots.
    </p>
    <h1 >
      <strong>
        <em>Requests</em>
      </strong>
    </h1>
    <img src="./image2.png" width="200" />

    <p>
      <br></br>
    </p>
    <p>
      Expand the volume of monthly requests in your plan and manage the total
      set of requests for each of your projects. The volume of requests
      available in a plan can never exceed the maximum request capacity
      established in that plan.
    </p>
    <h1 >
      <strong>
        <em>Storage</em>
      </strong>
    </h1>
    <img src="./image3.png" width="200" />

    <p>
      Plans have a predefined storage capacity. For example, a Business plan has
      a maximum storage capacity of 2 Teras and a default storage of 1 Gb. This
      means that the storage can be increased from the default 1 Gb to 2 Teras
      maximum, but no more. If more storage is required, it will be necessary to
      upgrade the plan.
    </p>
    <p>
      If your project or set of project exceed the maximum storage allowed for
      the plan you have, you will need to upgrade the your plan.
    </p>
    <h1 >
      <strong>
        <em>Support</em>
      </strong>
    </h1>
    <img src="./image4.png" width="200" />

    <p>
      <br></br>
    </p>
    <p>
      Access to professional support through the 101OBeX platform&#39;s suite of
      engineers.
    </p>
    <p>
      We recommend reviewing the Pricing document for details about the pricing
      configuration of the entire Add-on catalog. If a project or node reaches
      the limit in any of its properties or configurations, the requests will
      begin to return. To prevent this situation from causing problems in your
      projects, 101OBeX is configured to support up to 10% more in each of the
      configurations during the next 24 hours from the moment any of the limits
      are exceeded. After this period, applications will begin to be given back.
    </p>
    <p>
      To further prevent such scenarios, 101OBeX employs an alarm system. This
      system sends notifications when specific properties approach predefined
      thresholds, granting you control over your project&#39;s growth at all
      times.
    </p>
  </Fragment>
);
export default Add_Ons;
