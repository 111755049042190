import React, { Fragment } from 'react';

const USER_GUIDE_Profiles = () => (
  <Fragment>
    <h1 id="profiles-in-avap-tm-dev-studio">Profiles in AVAP TM Dev Studio</h1>
    <p>
      AVAP<sup>TM</sup> Dev Studio has hundreds of settings, thousands of
      extensions, and innumerable ways to adjust the UI layout to customize the
      editor. AVAP<sup>TM</sup> Dev Studio <strong>Profiles</strong> let you
      create sets of customizations and quickly switch between them or share
      them with others. This topic explains how to create, modify, export, and
      import profiles.
    </p>
    <h2 id="create-a-profile">Create a Profile</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio treats your current configuration as the{' '}
      <strong>Default Profile</strong>. As you modify settings, install
      extensions, or change UI layout by moving views, these customizations are
      tracked in the Default Profile.
    </p>
    <p>
      To create a new profile, you can use the <strong>File</strong> &gt;{' '}
      <strong>Preferences</strong> &gt; <strong>Profiles</strong> &gt;{' '}
      <strong>Create Profile</strong> menu item.
    </p>
    <p>
      You can create a new profile based on the current profile &#40;
      <strong>Profiles: Create from Current Profiles</strong>&#41; or create an
      Empty Profile. An Empty Profile includes no user customizations
      &#40;settings, extensions, snippets, etc.&#41;.
    </p>
    <p>
      You can also access the Profile command menu via the{' '}
      <strong>Manage</strong> gear button in the lower right of the Activity
      bar.
    </p>
    <p>
      The <strong>Profiles: Create Profile</strong> command is also available in
      the Command Palette &#40;Ctrl+Shift+P&#41;.
    </p>
    <p>
      You can choose to create a new profile by copying from a Profile Template
      or from an existing profile or create an Empty Profile in the Profile
      creation dialog.
    </p>
    <h3 id="partial-profile">Partial Profile</h3>
    <p>
      A partial profile allows you to customize only a subset of configurations
      &#40;settings, keyboard shortcuts, snippets, tasks and extensions&#41; and
      use the rest of the configurations from the Default Profile. For example,
      you can create a profile with all configurations except for keyboard
      shortcuts, and AVAP<sup>TM</sup> Dev Studio will apply the keyboard
      shortcuts from the Default Profile when this profile is active.
    </p>
    <h3 id="check-the-current-profile">Check the current profile</h3>
    <p>
      The current profile name is displayed in several places in the AVAP
      <sup>TM</sup> Dev Studio UI:
    </p>
    <ul>
      <li>Title bar</li>
      <li>
        <strong>File</strong> &gt; <strong>Preferences</strong> &gt;{' '}
        <strong>Profiles</strong>
      </li>
      <li>
        <strong>Manage</strong> gear button hover
      </li>
    </ul>
    <p>
      If you are still using the Default Profile, no profile name is displayed.
    </p>
    <p>
      The <strong>Manage</strong> gear button displays a badge with the first
      two letters of the active profile so you can quickly check which profile
      you are running.
    </p>
    <h3 id="configure-a-profile">Configure a profile</h3>
    <p>
      You can configure a profile just as you would normally change any AVAP
      <sup>TM</sup> Dev Studio configuration. You can install/uninstall/disable
      extensions, change settings, and adjust the editor&#39;s UI layout
      &#40;for example, moving and hiding views&#41; like normal. These changes
      are stored in your currently active profile.
    </p>
    <h3 id="workspace-associations">Workspace associations</h3>
    <p>
      When you create or select an existing profile, it is associated with the
      current workspace and whenever you open that folder, the workspace&#39;s
      profile is active. If you open another folder, the profile switches to
      that folder&#39;s associated profile if one has been set or remains on the
      last used profile.
    </p>
    <h2 id="managing-profiles">Managing profiles</h2>
    <h3 id="switch-profiles">Switch profiles</h3>
    <p>
      You can quickly switch between profiles with the{' '}
      <strong>Profiles: Switch Profile</strong> command in the Command Palette,
      which presents a dropdown listing your available profiles.
    </p>
    <p>
      You can also switch profiles by selecting a profile from the list
      displayed in the Profiles menus, available via the <strong>Manage</strong>{' '}
      gear button or <strong>File</strong> &gt; <strong>Preferences</strong>{' '}
      &gt; <strong>Profiles</strong>.
    </p>
    <h3 id="edit-a-profile">Edit a profile</h3>
    <p>
      You can edit an existing profile via the <strong>Edit</strong> command in
      the Profiles menu.
    </p>
    <h3 id="delete-a-profile">Delete a profile</h3>
    <p>
      You can delete a profile via the <strong>Delete Profile</strong> command.
      The <strong>Delete Profile</strong> drop down lets you select which
      profile&#40;s&#41; to delete.
    </p>
    <h2 id="profile-contents">Profile contents</h2>
    <p>
      The <strong>Profiles: Show Contents</strong> command &#40;available in the
      Command Palette or Profiles menus&#41; brings up the Profiles view, where
      you can review the customizations for the profile.
    </p>
    <p>A profile can include:</p>
    <ul>
      <li>
        Settings - In a profile-specific <code>settings.json</code> file.
      </li>
      <li>
        Extensions - The list of extensions included in the current profile.
      </li>
      <li>
        UI state - View layout &#40;positions&#41;, visible views and actions.
      </li>
      <li>
        Keybindings - In a profile-specific <code>keybindings.json</code> file.
      </li>
      <li>
        Snippets - In a profile-specific <code>&#123;language&#125;.json</code>{' '}
        files.
      </li>
      <li>
        User Tasks - In a profile-specific <code>tasks.json</code> file.
      </li>
    </ul>
    <p>
      When you create a new profile based on the Default Profile, the
      profile-specific configuration files are populated from your user
      configuration files. Workspace-specific settings are not automatically
      included in a new profile.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: Unchecking an extension in the Profiles view does
        not remove or disable the extension from the current profile but does
        remove the extension if you export the profile.
      </p>
    </blockquote>
    <h3 id="applying-a-setting-to-all-profiles">
      Applying a setting to all profiles
    </h3>
    <p>
      A setting can be applied to all profiles using the{' '}
      <strong>Apply Setting to all Profiles</strong> action available in the
      Settings editor.
    </p>
    <p>
      This will apply the value of the setting to all profiles. Any updates to
      this setting from any profile will be applied to all profiles. You can
      always revert this by unchecking the{' '}
      <strong>Apply Setting to all Profiles</strong> action.
    </p>
    <h3 id="applying-an-extension-to-all-profiles">
      Applying an extension to all profiles
    </h3>
    <p>
      An extension can be applied to all profiles by clicking on the{' '}
      <strong>Apply Extension to all Profiles</strong> action in the Extensions
      view.
    </p>
    <p>
      This will make this extension available in all profiles. You can always
      revert this by unchecking the{' '}
      <strong>Apply Extension to all Profiles</strong> action.
    </p>
    <h2 id="share-profiles">Share Profiles</h2>
    <h3 id="export">Export</h3>
    <p>
      You can export a profile in order to save it or share it with others. The{' '}
      <strong>Export Profile</strong> command displays the Profiles view with
      the contents of the active profile and an <strong>Export</strong> button.
      You can unselect various elements of the profile such as extensions or
      configuration files before you export the profile.
    </p>
    <p>
      When you select <strong>Export</strong>, you are prompted for the profile
      name and whether you want to export to a GitHub gist or your local file
      system.
    </p>
    <h4 id="save-as-a-github-gist">Save as a GitHub gist</h4>
    <p>
      After you save a profile to GitHub &#40;you&#39;ll be prompted to log into
      GitHub&#41;, a dialog gives you the option to <strong>Copy Link</strong>{' '}
      so you can share your profile gist URL with others. The URL includes an
      autogenerated GUID and has the format &#40;LINK&#41;. The GitHub gist is
      marked as <strong>Secret</strong>, so only those with the link can see the
      gist.
    </p>
    <p>
      If you launch the profile URL, it opens AVAP<sup>TM</sup> Dev Studio for
      the Web &#40;vscode.dev&#41; with the Profiles view open and the imported
      profile contents displayed. You can unselect profile elements if you wish
      and you need to manually <strong>Install Extensions</strong> &#40;via the
      download cloud button&#41; if you want to continue using that profile in
      vscode.dev.
    </p>
    <p>
      You also have the option to{' '}
      <strong>
        Import Profile in AVAP<sup>TM</sup> Dev Studio
      </strong>
      , which opens AVAP<sup>TM</sup> Dev Studio Desktop with the profile&#39;s
      contents displayed and an <strong>Import Profile</strong> button.
    </p>
    <p>
      You can review your gists at &#40;LINK&#41;. From your GitHub gist page
      you can rename, delete, or copy the GUID of a gist.
    </p>
    <h4 id="save-as-a-local-file">Save as a local file</h4>
    <p>
      If you chose to save the profile as a local file, a{' '}
      <strong>Save Profile</strong> dialog lets you place the file on your local
      machine. A profile is persisted in a file with the extension{' '}
      <code>.code-profile</code>.
    </p>
    <h3 id="import">Import</h3>
    <p>
      To import an existing profile, run the <strong>Import Profiles</strong>{' '}
      command. You are prompted for the URL of a GitHub gist or the file
      location of a profile via an <strong>Import Profile</strong> dialog. Once
      you have selected the profile, the Profiles view opens and displays the
      profile to import. You can unselect some profile elements if you don&#39;t
      want to import them. Select the <strong>Import Profile</strong> button and
      you will now be using the imported profile.
    </p>
    <h2 id="uses-for-profiles">Uses for Profiles</h2>
    <p>
      Profiles are a great way to customize AVAP<sup>TM</sup> Dev Studio to
      better fit your needs. In this section, we look at some common use cases
      for profiles.
    </p>
    <p>
      Since profiles are remembered per workspace, they are a great way to
      customize AVAP<sup>TM</sup> Dev Studio for a specific programming
      language. For example, you can create a JavaScript frontend profile that
      includes the extensions, settings, and customizations you use for
      JavaScript development in one workspace, and have a Python backend profile
      that includes the extensions, settings, and customizations you use for
      Python development in another workspace. Using this approach, you can
      easily switch between workspaces and always have AVAP<sup>TM</sup> Dev
      Studio configured the right way.
    </p>
    <h3 id="demos">Demos</h3>
    <p>
      When doing a demo, you can use a profile to set up a specific
      configuration for your demo. For example, you can create a profile with a
      specific set of extensions and settings like zoom level, font size, and
      color theme. By doing this, a demo will not mess up your normal AVAP
      <sup>TM</sup> Dev Studio setup and you can customize AVAP<sup>TM</sup> Dev
      Studio for better visibility during your presentation.
    </p>
    <h3 id="education">Education</h3>
    <p>
      Profiles can be used to customize AVAP<sup>TM</sup> Dev Studio for
      students to ease the use in a classroom setting. Profiles allow educators
      to quickly share a customized AVAP<sup>TM</sup> Dev Studio setup with
      students. For example, educators can create a profile with a specific set
      of extensions and settings needed for a computer science class and then
      share that profile with students.
    </p>
    <h3 id="report-avapsuptmsup-dev-studio-issues">
      Report AVAP<sup>TM</sup> Dev Studio issues
    </h3>
    <p>
      One use of an Empty Profile is to reset your editor when you want to
      report an issue with AVAP<sup>TM</sup> Dev Studio. An Empty Profile
      disables all extensions and modified settings so you can quickly see if
      the issue is due to an extension, a setting, or is in AVAP<sup>TM</sup>{' '}
      Dev Studio core.
    </p>
    <h2 id="profile-templates">Profile Templates</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio comes with a predefined set of profile
      templates that you can use to customize AVAP<sup>TM</sup> Dev Studio for
      your specific workflow. To create a new profile based on a template,
      select a Profile Template when going through the{' '}
      <strong>Create Profile</strong> flow.
    </p>
    <h3 id="python-profile-template">Python Profile Template</h3>
    <p>
      The Python profile is a good starting point for Python development. It
      comes with Python specific snippets and has the following extensions:
    </p>
    <ul>
      <li>autoDocstring - Generate Python docstrings automatically.</li>
      <li>Black Formatter - Formatting support using the black formatter.</li>
      <li>Docker - Create, manage, and debug containerized applications.</li>
      <li>Even Better TOML - Fully-featured TOML support.</li>
      <li>
        Python - IntelliSense, linting, formatting, debugging, refactoring.
      </li>
      <li>
        Python Environment Manager - Manage Python environments and packages.
      </li>
      <li>
        Remote Development extension pack - Supports SSH, WSL, and Dev
        Containers.
      </li>
      <li>Ruff - Integrates the Ruff Python linter.</li>
    </ul>
    <p>This profile also sets the following settings:</p>

    <pre className="">
      <code className="">
        ` `"python.analysis.autoImportCompletions": true,
        <br />` `"python.analysis.fixAll": ["source.unusedImports"],
        <br />` `"editor.defaultFormatter": "ms-python.black-formatter"
      </code>
    </pre>
    <h3 id="data-science-profile-template">Data Science Profile Template</h3>
    <p>
      The Data Science profile is a good starting point for all data and
      notebook work. It comes with specific snippets and has the following
      extensions:
    </p>
    <ul>
      <li>
        Data Wrangler - Data cleaning and preparation for tabular datasets.
      </li>
      <li>Black Formatter - Formatting support using the black formatter.</li>
      <li>
        Jupyter - Use Jupyter notebooks within AVAP<sup>TM</sup> Dev Studio.
      </li>
      <li>
        Python - IntelliSense, linting, formatting, debugging, refactoring.
      </li>
      <li>
        Dev Containers - Create custom development environments inside a Docker
        container.
      </li>
      <li>GitHub Copilot - Your AI pair programmer.</li>
    </ul>
    <p>This profile also sets the following settings:</p>

    <pre className="">
      <code className="">
        ` `"[python]": &#123;
        <br />` `"editor.defaultFormatter": "ms-python.black-formatter",
        <br />` `"editor.formatOnType": true,
        <br />` `"editor.formatOnSave": true
        <br />` `&#125;,
        <br />` `"editor.inlineSuggest.enabled": true,
        <br />` `"editor.lineHeight": 17,
        <br />` `"breadcrumbs.enabled": false,
        <br />` `"files.autoSave": "afterDelay",
        <br />` `"jupyter.themeMatplotlibPlots": true,
        <br />` `"jupyter.widgetScriptSources": [<br />` `"unpkg.com",
        <br />` `"jsdelivr.com"
        <br />` `],
        <br />` `"notebook.experimental.outputScrolling": true,
        <br />` `// "notebook.outline.showCodeCells": true,
        <br />` `"files.exclude": &#123;
        <br />` `"\*\*/.csv": true,
        <br />` `"\*\*/.parquet": true,
        <br />` `"\*\*/.pkl": true,
        <br />` `"\*\*/.xls": true
        <br />` `&#125;
      </code>
    </pre>
    <h3 id="doc-writer-profile-template">Doc Writer Profile Template</h3>
    <p>
      The Doc Writer profile is a good lightweight setup for writing
      documentation. It comes with the following extensions:
    </p>
    <ul>
      <li>Code Spell Checker - Spelling checker for source code.</li>
      <li>
        Markdown Checkboxes - Adds checkbox support to the AVAP<sup>TM</sup> Dev
        Studio built-in Markdown Preview.
      </li>
      <li>
        Markdown Emoji - Adds emoji syntax support to Markdown Preview and
        notebook Markdown cells.
      </li>
      <li>
        Markdown Footnotes - Adds ^footnote syntax support to the Markdown
        Preview.
      </li>
      <li>
        Markdown Preview GitHub Styling - Use GitHub styling in the Markdown
        Preview.
      </li>
      <li>
        Markdown Preview Mermaid Support - Mermaid diagrams and flowcharts.
      </li>
      <li>Markdown yaml Preamble - Renders YAML front matter as a table.</li>
      <li>
        markdownlint - Markdown linting and style checking for AVAP<sup>TM</sup>{' '}
        Dev Studio.
      </li>
    </ul>
    <p>This profile also sets the following settings:</p>

    <pre className="">
      <code className="">
        ` `"workbench.colorTheme": "Default Light+ Experimental",
        <br />` `"editor.minimap.enabled": false,
        <br />` `"breadcrumbs.enabled": false,
        <br />` `"editor.glyphMargin": false,
        <br />` `"explorer.decorations.badges": false,
        <br />` `"explorer.decorations.colors": false,
        <br />` `"editor.fontLigatures": true,
        <br />` `"files.autoSave": "afterDelay",
        <br />` `"git.enableSmartCommit": true,
        <br />` `"window.commandCenter": true,
        <br />` `"editor.renderWhitespace": "none",
        <br />` `"workbench.editor.untitled.hint": "hidden",
        <br />` `"markdown.validate.enabled": true,
        <br />` `"markdown.updateLinksOnFileMove.enabled": "prompt",
        <br />` `"workbench.startupEditor": "none"
      </code>
    </pre>
    <h3 id="nodejs-profile-template">Node.js Profile Template*</h3>
    <p>
      The Node.js profile is a good starting point for all Node.js work. It
      comes with the following extensions:
    </p>
    <ul>
      <li>
        ESLint - Integrates ESLint JavaScript into AVAP<sup>TM</sup> Dev Studio.
      </li>
      <li>
        Dev Containers - Create custom development environments inside a Docker
        container.
      </li>
      <li>Docker - Create, manage, and debug containerized applications.</li>
      <li>DotENV - Support for dotenv file syntax.</li>
      <li>
        EditorConfig for AVAP<sup>TM</sup> Dev Studio - EditorConfig Support for
        AVAP<sup>TM</sup> Dev Studio.
      </li>
      <li>
        JavaScript &#40;ES6&#41; code snippets - Code snippets for JavaScript in
        ES6 syntax.
      </li>
      <li>Jest - Use Facebook&#39;s jest testing framework.</li>
      <li>
        Microsoft Edge Tools for AVAP<sup>TM</sup> Dev Studio - Use the
        Microsoft Edge Tools from within AVAP<sup>TM</sup> Dev Studio.
      </li>
      <li>npm Intellisense - Autocomplete npm modules in import statements.</li>
      <li>Prettier - Code formatter - Code formatter using Prettier.</li>
      <li>
        Rest Client - REST Client for AVAP<sup>TM</sup> Dev Studio.
      </li>
      <li>YAML - YAML language support with built-in Kubernetes syntax.</li>
    </ul>
    <p>This profile comes with the following settings:</p>

    <pre className="">
      <code className="">
        ` `"editor.formatOnPaste": true,
        <br />` `"git.autofetch": true,
        <br />` `"[markdown]": &#123;
        <br />` `"editor.wordWrap": "on"
        <br />` `&#125;,
        <br />` `"[json]": &#123;
        <br />` `"editor.defaultFormatter": "esbenp.prettier-vscode"
        <br />` `&#125;,
        <br />` `"[jsonc]": &#123;
        <br />` `"editor.defaultFormatter": "vscode.json-language-features"
        <br />` `&#125;,
        <br />` `"[html]": &#123;
        <br />` `"editor.defaultFormatter": "esbenp.prettier-vscode"
        <br />` `&#125;,
        <br />` `"[javascript]": &#123;
        <br />` `"editor.defaultFormatter": "esbenp.prettier-vscode"
        <br />` `&#125;,
        <br />` `"[typescript]": &#123;
        <br />` `"editor.defaultFormatter": "esbenp.prettier-vscode"
        <br />` `&#125;
      </code>
    </pre>
    <h3 id="angular-profile-template">Angular Profile Template</h3>
    <p>
      The Angular profile is a good starting point for all Angular work. It
      comes with the following extensions:
    </p>
    <ul>
      <li>Angular Language Service - Editor services for Angular templates.</li>
      <li>
        Angular Schematics - Integrate Angular schematics &#40;CLI
        commands&#41;.
      </li>
      <li>
        angular2-switcher - Easily navigate to
        <code>typescript|template|style</code> in angular2 project.
      </li>
      <li>
        Dev Containers - Create custom development environments inside a Docker
        container.
      </li>
      <li>
        EditorConfig for AVAP<sup>TM</sup> Dev Studio - EditorConfig Support for
        AVAP<sup>TM</sup> Dev Studio.
      </li>
      <li>
        ESLint - Integrates ESLint JavaScript into AVAP<sup>TM</sup> Dev Studio.
      </li>
      <li>
        JavaScript &#40;ES6&#41; code snippets - Code snippets for JavaScript in
        ES6 syntax.
      </li>
      <li>Jest - Use Facebook&#39;s jest testing framework.</li>
      <li>
        Material Icon Theme - Material Design Icons for AVAP<sup>TM</sup> Dev
        Studio.
      </li>
      <li>
        Microsoft Edge Tools for AVAP<sup>TM</sup> Dev Studio - Use the
        Microsoft Edge Tools from within AVAP<sup>TM</sup> Dev Studio.
      </li>
      <li>
        Playwright Test for VSCode - Run Playwright tests in AVAP<sup>TM</sup>{' '}
        Dev Studio.
      </li>
      <li>Prettier - Code formatter - Code formatter using Prettier.</li>
      <li>
        Rest Client - REST Client for AVAP<sup>TM</sup> Dev Studio.
      </li>
      <li>YAML - YAML language support with built-in Kubernetes syntax.</li>
    </ul>
    <p>This profile sets the following settings:</p>

    <pre className="">
      <code className="">
        ` `"editor.formatOnPaste": true,
        <br />` `"git.autofetch": true,
        <br />` `"[markdown]": &#123;
        <br />` `"editor.wordWrap": "on"
        <br />` `&#125;,
        <br />` `"[json]": &#123;
        <br />` `"editor.defaultFormatter": "esbenp.prettier-vscode"
        <br />` `&#125;,
        <br />` `"[jsonc]": &#123;
        <br />` `"editor.defaultFormatter": "vscode.json-language-features"
        <br />` `&#125;,
        <br />` `"[html]": &#123;
        <br />` `"editor.defaultFormatter": "esbenp.prettier-vscode"
        <br />` `&#125;,
        <br />` `"[javascript]": &#123;
        <br />` `"editor.defaultFormatter": "esbenp.prettier-vscode"
        <br />` `&#125;,
        <br />` `"[typescript]": &#123;
        <br />` `"editor.defaultFormatter": "esbenp.prettier-vscode"
        <br />` `&#125;,
        <br />` `"workbench.iconTheme": "material-icon-theme"
      </code>
    </pre>
    <h3 id="java-general-profile-template">Java General Profile Template</h3>
    <p>
      The Java General profile is a good starting point for all Java work. It
      customizes the layout to improve the Java experience and comes with the
      following extensions from the Extension Pack for Java:
    </p>
    <ul>
      <li>Debugger for Java - A lightweight Java debugger.</li>
      <li>IntelliCode - AI-assisted development.</li>
      <li>
        IntelliCode API Usage Examples - Provides code examples for over 100K
        different APIs.
      </li>
      <li>
        Language Support for Java&#40;TM&#41; by Red Hat - Fundamental Java
        language support, Linting, Intellisense, formatting, refactoring.
      </li>
      <li>Maven for Java - Manage Maven projects and builds.</li>
      <li>
        Project Manager for Java - Manage Java projects within AVAP<sup>TM</sup>{' '}
        Dev Studio.
      </li>
      <li>Test Runner for Java - Run and debug JUnit or TestNG test cases.</li>
    </ul>
    <h3 id="java-spring-profile-template">Java Spring Profile Template</h3>
    <p>
      The Java Spring profile is a good starting point for all Java and Spring
      developers. It builds on the Java General profile and add the following
      extensions from the Spring Boot Extension Pack:
    </p>
    <ul>
      <li>
        Spring Boot Dashboard - Provides Spring Boot live data visualization and
        observation in your running Spring applications.
      </li>
      <li>Spring Boot Tools - Rich language support for Spring Boot files.</li>
      <li>
        Spring Initializr Java Support - Scaffold and generate Spring Boot Java
        projects.
      </li>
    </ul>
    <p>This profile sets the following settings:</p>

    <pre className="">
      <code className="">
        ` `"[java]": &#123;
        <br />` `"editor.defaultFormatter": "redhat.java"
        <br />` `&#125;,
        <br />` `"boot-java.rewrite.reconcile": true
      </code>
    </pre>
    <h2 id="command-line">Command line</h2>
    <p>
      You can launch AVAP<sup>TM</sup> Dev Studio with a specific profile via
      the <code>--profile</code> command-line interface option. You pass the
      name of the profile after the <code>--profile</code> argument and open a
      folder or a workspace using that profile. The command line below opens the{' '}
      <code>web-sample</code> folder with the &quot;Web Development&quot;
      profile:
    </p>
    <p>
      <code>
        code ~/projects/web-sample --profile &quot;Web Development&quot;
      </code>
    </p>
    <p>
      If the profile specified does not exist, a new empty profile with the
      given name is created.
    </p>
    <h2 id="common-questions">Common Questions</h2>
    <h3 id="where-are-profiles-kept">Where are profiles kept?</h3>
    <p>
      Profiles are stored under your User configurations similar to your user
      settings and keybindings.
    </p>
    <ul>
      <li>
        <strong>Windows</strong> <code>%APPDATA%\Code\User\profiles</code>
      </li>
      <li>
        <strong>macOS</strong>{' '}
        <code>$HOME/Library/Application\ Support/Code/User/profiles</code>
      </li>
      <li>
        <strong>Linux</strong> <code>$HOME/.config/Code/User/profiles</code>
      </li>
    </ul>
    <p>
      If you are using the Insiders version, the intermediate folder name is{' '}
      <code>Code - Insiders</code>.
    </p>
    <h3 id="where-is-the-ui-state-globalstatejson-file">
      Where is the UI State globalState.json file?
    </h3>
    <p>
      If you expand the <strong>UI State</strong> node in the Profiles view,
      there is a <code>globalState.json</code> entry. This is an in-memory JSON
      representation of your profile&#39;s UI State, describing the visibility
      and layout of various AVAP<sup>TM</sup> Dev Studio UI elements. The file
      does not actually exist on disk and is just a JSON view of the underlying
      global state storage.
    </p>
    <h3 id="what-is-a-temporary-profile">What is a Temporary Profile?</h3>
    <p>
      A Temporary Profile is a profile that is not saved across AVAP
      <sup>TM</sup> Dev Studio sessions. You create a Temporary Profile via the{' '}
      <strong>Profiles: Create a Temporary Profile</strong> command in the
      Command Palette. The Temporary Profile starts as an Empty Profile and has
      an automatically generated name &#40;such as <strong>Temp 1</strong>&#41;.
      You can modify the profile settings and extensions, use the profile for
      the lifetime of your AVAP<sup>TM</sup> Dev Studio session, but it will be
      deleted once you close AVAP<sup>TM</sup> Dev Studio.
    </p>
    <p>
      Temporary Profiles are useful if you want to try a new configuration or
      test an extension without modifying your default or existing profile.
      Restarting AVAP<sup>TM</sup> Dev Studio reenables the current profile for
      your workspace.
    </p>
    <h3 id="how-can-i-remove-the-profile-from-my-project">
      How can I remove the profile from my project?
    </h3>
    <p>
      You can set your project back to the Default Profile. If you&#39;d like to
      remove all profile workspace associations, you can use the{' '}
      <strong>Developer: Reset Workspace Profiles Associations</strong>, which
      will set all local folders currently assigned a profile back to the
      Default Profile. <strong>Reset Workspace Profiles Associations</strong>{' '}
      does not delete any existing profiles.
    </p>
    <h3 id="do-profiles-sync-across-machines-via-settings-sync">
      Do profiles sync across machines &#40;via Settings Sync&#41;?
    </h3>
    <p>
      Yes, you can use Settings Sync to move your profiles across various
      machines. With Setting Sync enabled and <strong>Profiles</strong> checked
      in the <strong>Settings Sync: Configure</strong> drop down, all your
      created profiles are available.
    </p>
    <h3 id="why-are-some-settings-not-exported-when-exporting-a-profile">
      Why are some settings not exported when exporting a profile?
    </h3>
    <p>
      When exporting profiles, machine-specific settings are not included
      because these setting would not be applicable on another machine. For
      example, settings that point to local paths are not included.
    </p>
  </Fragment>
);
export default USER_GUIDE_Profiles;
