import React, { Fragment } from 'react';

const TERMINAL_Appearance = () => (
  <Fragment>
    <h1 id="terminal-appearance">Terminal Appearance</h1>
    <p>
      The look of AVAP<sup>TM</sup> Dev Studio&#39;s terminal can be customized
      extensively.
    </p>
    <p>
      <a href="https://marketplace.visualstudio.com/items?itemName=tyriar.theme-sapphire">
        *Sapphire
      </a>{' '}
      <em>
        theme with custom <a href="https://starship.rs/">Starship</a> prompt
        using the font <a href="https://sourcefoundry.org/hack/">Hack</a> with{' '}
        <a href="https://www.nerdfonts.com/">Nerd Font</a> symbols
      </em>
    </p>
    <h2 id="text-style">Text style</h2>
    <p>Text in the terminal can be customized with the following settings:</p>
    <ul>
      <li>
        <code>terminal.integrated.fontFamily:</code>The font family to use, this
        takes a string in the format that fontFamily in CSS takes. For example,
        &quot;&#39;Fira Code&#39;, monospace&quot; will configure Fira Code as
        the primary font and monospace as the fallback when it lacks glyphs.
      </li>
      <li>
        <code>terminal.integrated.fontSize:</code>Changes the font size of text
        in the terminal.
      </li>
      <li>
        <code>terminal.integrated.letterSpacing:</code> Configures additional
        horizontal spacing between characters in pixels.
      </li>
      <li>
        <code>terminal.integrated.lineHeight:</code> Configures additional
        spacing vertical between characters as a multiplier of the regular line
        height. For example, 1.1 will add 10% additional vertical space.
      </li>
      <li>
        <code>terminal.integrated.fontWeight:</code> Configures the font weight
        of &quot;normal&quot; text.
      </li>
      <li>
        <code>terminal.integrated.fontWeightBold:</code> Configures the font
        weight of &quot;bold&quot; text.
      </li>
    </ul>
    <h3 id="powerline-symbols-and-nerd-fonts">
      Powerline symbols and Nerd Fonts
    </h3>
    <p>
      <a href="https://powerline.readthedocs.io">Powerline</a> fonts are special
      patched fonts that contain additional characters that can be used in the
      terminal. AVAP<sup>TM</sup> Dev Studio&#39;s terminal{' '}
      <a href="#_custom-glyphs">
        renders some of the Powerline symbols without needing to configure a
        font
      </a>
      , but if more glyphs are desired, configure a Powerline font with the font
      family setting. Powerline fonts typically end in &quot; for
      Powerline&quot;, the following setting is an example of how to configure a
      DejaVu Sans Mono that has been patched:
    </p>

    <pre className="">
      <code className="">
        "editor.fontFamily": "'DejaVu Sans Mono for Powerline'"
      </code>
    </pre>
    <p>
      Nerd Fonts work the same and typically have a &quot; NF&quot; suffix, the
      following is an example of how to configure Hack&#39;s nerd fonts variant:
    </p>

    <pre className="">
      <code className="">"terminal.integrated.fontFamily": "'Hack NF'"</code>
    </pre>
    <h2 id="terminal-cursor">Terminal cursor</h2>
    <p>
      The terminal cursor style and whether it blinks can be customized with the
      following settings:
    </p>
    <ul>
      <li>
        <code>terminal.integrated.cursorStyle:</code> Defines the shape of the
        cursor, can be block, line or underline.
      </li>
      <li>
        <code>terminal.integrated.cursorWidth:</code> How wide in pixels the
        cursor should be when the cursor style is set to line.
      </li>
      <li>
        <code>terminal.integrated.cursorBlinking:</code> Whether the cursor
        should blink when the terminal is focused.
      </li>
      <li>
        <code>terminal.integrated.cursorStyleInactive:</code> Defines the shape
        of the cursor, can be outline, block, line, underline or none.
      </li>
    </ul>
    <h2 id="customizing-tabs">Customizing tabs</h2>
    <p>
      Terminal tabs appear on the right of the terminal view when there are two
      or more terminals by default, showing the active terminal in the view
      header when there is only one.
    </p>
    <h3 id="visibility">Visibility</h3>
    <p>
      The default visibility is designed to save horizontal space, but may not
      be desirable. How tabs are presented can be configured with the following
      settings:
    </p>
    <ul>
      <li>
        <code>terminal.integrated.tabs.hideCondition:</code> When to hide the
        tabs to the right, set to &quot;never&quot; to always show them.
      </li>
      <li>
        <code>terminal.integrated.tabs.showActiveTerminal:</code> When to show
        the active terminal in the terminal view header.
      </li>
      <li>
        <code>terminal.integrated.tabs.showActions:</code> When to show the
        active terminal&#39;s actions in the view header.
      </li>
      <li>
        <code>terminal.integrated.tabs.location:</code> Whether the tabs should
        be shown on the left or right of the terminal.
      </li>
      <li>
        <code>terminal.integrated.tabs.enabled:</code> Whether to use tabs,
        disabling will show the original dropdown view.
      </li>
    </ul>
    <h3 id="tab-text">Tab text</h3>
    <p>The text on each tab is determined by the following settings:</p>
    <ul>
      <li>
        <code>terminal.integrated.tabs.title:</code> Tab title.
      </li>
      <li>
        <code>terminal.integrated.tabs.description:</code> Text that appears to
        the right of the title.
      </li>
      <li>
        <code>terminal.integrated.tabs.separator:</code> Separator character
        between the title and description.
      </li>
    </ul>
    <p>
      By default, the title displays what the shell&#39;s detected process name.
    </p>
    <p>
      Other terminals often display the escape sequence sent by the shell as the
      title, which can be configured with:
    </p>

    <pre className="">
      <code className="">
        "terminal.integrated.tabs.title": "$&#123;sequence&#125;"
      </code>
    </pre>
    <h3 id="icons">Icons</h3>
    <p>
      Each terminal has an associated icon that is determined by its{' '}
      <a href="/docs/terminal/profiles">terminal profile</a>. The default icon
      and its color, which will be used if not defined in a profile, can be
      configured with the terminal.integrated.tabs.defaultIcon and
      terminal.integrated.tabs.defaultColor settings.
    </p>
    <h3 id="status">Status</h3>
    <p>
      A terminal&#39;s &quot;status&quot;, if any, is signified by an icon that
      appears on the right of the tab. Some statuses involve animation. If this
      is distracting the animation can be disabled with:
    </p>

    <pre className="">
      <code className="">
        "terminal.integrated.tabs.enableAnimation": false
      </code>
    </pre>
    <h3 id="visual-bell">Visual bell</h3>
    <p>
      When the terminal&#39;s bell is triggered, a yellow bell icon is briefly
      shown. This can be disabled with terminal.integrated.enableBell and the
      duration can be configured with terminal.integrated.bellDuration.
    </p>
    <h2 id="terminal-colors">Terminal colors</h2>
    <p>
      While the terminal is capable of displaying true color, programs commonly
      use 8 ANSI colors &#40;black, red, green, yellow, blue, magenta, cyan and
      white&#41; and bright variants of each. These ANSI colors are determined
      by the active <a href="/docs/getstarted/themes">color theme</a>, but they
      can also be configured independently from the theme with the{' '}
      <a href="/docs/getstarted/themes#_workbench-colors">
        workbench.colorCustomizations
      </a>{' '}
      setting.
    </p>
    <p>
      Whether bold text uses the normal ANSI colors or the bright variant can be
      configured with the terminal.integrated.drawBoldTextInBrightColors
      setting.
    </p>
    <h3 id="minimum-contrast-ratio">Minimum contrast ratio</h3>
    <p>
      Terminals often have contrast issues due to some conflict with dark/light
      themes, ANSI colors or shells/programs running, and more.
    </p>
    <p>
      The minimum contrast ratio feature solves this problem by either
      increasing or reducing the luminance of the text&#39;s foreground color
      until a 4.5:1 contrast ratio is met.
    </p>
    <p>
      One downside of this is that colored text may sometimes lose some of its
      saturation. This feature can be configured or disabled to get the original
      colors with:
    </p>

    <pre className="">
      <code className="">"terminal.integrated.minimumContrastRatio": 1</code>
    </pre>
    <h2 id="gpu-acceleration">GPU acceleration</h2>
    <p>
      The terminal features three different renderers, each of which have
      different trade offs:
    </p>
    <ul>
      <li>WebGL renderer - True GPU acceleration.</li>
      <li>
        Canvas renderer - GPU acceleration by using the{' '}
        <a href="https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D">
          CanvasRenderingContext2D web API
        </a>
        , slower than WebGL but faster than DOM.
      </li>
      <li>
        DOM renderer - A fallback renderer that&#39;s much slower but has great
        compatibility.
      </li>
    </ul>
    <p>
      GPU acceleration driven by the WebGL renderer is enabled in the terminal
      by default. This helps the terminal work faster and display at a high FPS
      by significantly reducing the time the CPU spends rendering each frame.
    </p>
    <p>
      When on Linux VMs, browsers that don&#39;t support WebGL, or machines with
      outdated drivers, WebGL may not work properly.
    </p>
    <p>
      The default terminal.integrated.gpuAcceleration value of &quot;auto&quot;
      tries the WebGL, canvas, and DOM renderers sequentially, settling at the
      first without detected issues.
    </p>
    <p>
      Sometimes this detection doesn&#39;t work and requires manual
      intervention, setting terminal.integrated.gpuAcceleration to
      &quot;off&quot; typically resolves rendering-related problems like these
      at the cost of performance.
    </p>
    <h3 id="custom-glyphs">Custom glyphs</h3>
    <p>
      When <a href="#_gpu-acceleration">GPU acceleration</a> is enabled, custom
      rendering, rather than the font, improves how some characters display in
      the terminal. These include box drawing characters
      &#40;U+2500-U+257F&#41;, block elements &#40;U+2580-U+259F&#41; and a
      subset of Powerline symbols &#40;U+E0B0-U+E0BF&#41;. This means that the
      configured font does not need to support these characters as well as
      having the characters draw pixel perfect and stretch to the size of the
      entire cell.
    </p>
    <p>
      Below are some examples of these characters with custom line height and
      letter spacing configured. Notice how there are no gaps between cells
      thanks to the custom glyphs:
    </p>

    <pre className="">
      <code className="">
        This feature can be disabled by setting
        "terminal.integrated.customGlyphs": false.
      </code>
    </pre>
    <h2 id="customizing-your-prompt">Customizing your prompt</h2>
    <p>
      Most shells allow extensive customization of the terminal prompt. This is
      done by configuring your shell outside AVAP<sup>TM</sup> Dev Studio,
      typically by modifying the $PS1 variable, setting a $PROMPT_COMMAND or
      installing a plugin.
    </p>
    <p>
      Some prompts like <a href="https://starship.rs/">Starship</a> and{' '}
      <a href="https://ohmyposh.dev/">oh-my-posh</a> show things like git status
      and work with most shells, so they&#39;re a good choice when starting out.
    </p>
    <h2 id="common-questions">Common questions</h2>
    <h3 id="why-is-my-terminal-showing-a-multi-colored-triangle-or-a-black-rectangle">
      Why is my terminal showing a multi-colored triangle or a black rectangle?
    </h3>
    <p>
      The terminal can have problems with GPU accelerated rendering in some
      environments. For example, you might see a big multi-colored triangle
      instead of text. This is typically caused by driver/VM graphics issues and
      the same also happens in Chromium. Work around these issues by launching
      code with the --disable-gpu flag or by using the setting
      &quot;terminal.integrated.gpuAcceleration&quot;: &quot;off&quot; to avoid
      using the canvas in the terminal. See the{' '}
      <a href="#_gpu-acceleration">GPU acceleration</a> section for more
      information.
    </p>
    <h3 id="why-are-the-colors-in-the-terminal-not-correct">
      Why are the colors in the terminal not correct?
    </h3>
    <p>
      The <a href="#_minimum-contrast-ratio">minimum contrast ratio feature</a>{' '}
      can cause colors to not be displayed as expected. It can be disabled with:
    </p>

    <pre className="">
      <code className="">"terminal.integrated.minimumContrastRatio": 1</code>
    </pre>
    <p>
      Background on why this is the default is explained in{' '}
      <a href="https://github.com/microsoft/vscode/issues/146406#issuecomment-1084825473">
        vscode#146406
      </a>
      .
    </p>
    <p>``</p>
  </Fragment>
);
export default TERMINAL_Appearance;
