import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Retirar_a_Tarjeta_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": <boolean>, 
          "signed": <boolean>, 
          "currency_data": { 
            "abbreviation": <string>, 
            "suffix": <string>, 
            "format": <string>, 
            "symbol": <string>, 
            "prefix": <string>, 
            "decimals": <decimal>, "id": <integer> }
            , "elapsed": <float>, 
            "raw_amount": <decimal>,
             "currency": <string>, 
             "commission_user_card": <decimal>, 
             "codtran": <string>, "user_commission": <decimal>, 
             "amount": <string> 
            }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": false, 
          "level": <string>, 
          "message": <string>, 
          "error": <string> 
        }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/movimientos.py/retirarbanco" 
        payload = { 'user_id': '78', 'session_id': '389-rOD4YFFvzmPF1tzjL6viFg==', 'amount': '12.50', 'pin': '2312', 'country_code': 'MX', 'beneficiary': 'Nombre de prueba', 'concept': 'Concepto de prueba', 'type_of_shippment': 'CARD', 'card': '5540500001000004' } 
        files = [] 
        headers= {} 
        response = requests.request("POST", url, headers=headers, data = payload, files = files) 
        print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests url = "URL_BASE/ws/movimientos.py/retirarbanco" 
          payload = { 'user_id': '78', 'session_id': '389-rOD4YFFvzmPF1tzjL6viFg==', 'amount': '12.50', 'pin': '2312', 'country_code': 'MX', 'beneficiary': 'Nombre de prueba', 'concept': 'Concepto de prueba', 'type_of_shippment': 'CARD', 'card': '5540500001000004' } 
          files = [] 
          headers= {} 
          response = requests.request("POST", url, headers=headers, data = payload, files = files) 
          print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'POST',
              'url': 'URL_BASE/ws/movimientos.py/retirarbanco',
              'headers': {
              },
              formData: {
              'user_id': '78',
              'session_id': '389-rOD4YFFvzmPF1tzjL6viFg==',
              'amount': '12.50',
              'pin': '2312',
              'country_code': 'MX',
              'beneficiary': 'Nombre de prueba',
              'concept': 'Concepto de prueba',
              'type_of_shippment': 'CARD',
              'card': '5540500001000004'
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var formdata = new FormData();
              formData.append("user_id", "78");
              formdata.append("session_id", "389-rOD4YFFvzmPF1tzjL6viFg==");
              formdata.append("amount", "12.50");
              formdata.append("pin", "2312");
              formdata.append("country_code", "MX");
              formdata.append("beneficiary", "Nombre de prueba");
              formdata.append("concept", "Concepto de prueba");
              formdata.append("type_of_shippment", "CARD");
              formdata.append("card", "5540500001000004");
              var requestOptions = {
              method: 'POST',
              body: formdata,
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/movimientos.py/retirarbanco", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request POST 'URL_BASE/ws/movimientos.py/retirarbanco' \ --form 'user_id=78' \ --form 'session_id=389-rOD4YFFvzmPF1tzjL6viFg==' \ --form 'amount=12.50' \ --form 'pin=2312' \ --form 'country_code=MX' \ --form 'beneficiary=Nombre de prueba' \ --form 'concept=Concepto de prueba' \ --form 'type_of_shippment=CARD' \ --form 'card=5540500001000004'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="withdrawal-to-credit-card">Withdrawal to Credit Card</h1>
        <p>
          This service is used to withdraw money from a wallet to a credit card.
        </p>
        <p>
          <span className="label post">POST:</span>
          <span> </span> <code>URL_BASE + /ws/movimientos.py/retirarbanco</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  ISO 3166 alpha-2
                </a>
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">Identifier of the user making the request</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">amount</td>
              <td align="center">Decimal</td>
              <td align="center">Yes</td>
              <td align="center">Amount to be sent to the user</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">pin</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">User pin</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">beneficiary</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">Name of the beneficary of the operation.</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">concept</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">Text associated with the operation</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">type_of_shippment</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                It has the value <code>CARD</code>.
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">card</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                Credit card number in which we want to make the deposit.
              </td>
              <td align="center">N/A</td>
            </tr>
          </tbody>
        </table>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>signed:</code> Shows if the signature has been made &#40;true&#41;
            or not &#40;false&#41;.
          </li>
          <li>
            <code>currency_data:</code> Contains the different details of the
            currency used for the operation.
          </li>
          <li>
            <code>abbreviation:</code>The abbreviated name of the currency &#40;EUR,
            MXN&#41;.
          </li>
          <li>
            <code>suffix:</code> The suffix that is applied in the currency format
            &#40;pesos, euros&#41;.
          </li>
          <li>
            <code>format:</code> The full format that is applied to the currency, it
            includes the suffix and the prefix.
          </li>
          <li>
            <code>symbol:</code> The symbol associated to the currency &#40;€, ¢,
            $&#41;.
          </li>
          <li>
            <code>prefix:</code> The prefix that is applied in the format of the
            currency.
          </li>
          <li>
            <code>decimals:</code> The maximum number of decimal places to be
            included in the currency format.
          </li>
          <li>
            <code>id:</code> Currency identifier in BBDD.
          </li>
          <li>
            <code>elapsed:</code> Operation execution time.
          </li>
          <li>
            <code>raw_amount:</code> Amount of the operation in negative without
            applying the format.
          </li>
          <li>
            <code>currency:</code> Short name of the currency used in the operation,
            it matches the abbreviation.
          </li>
          <li>
            <code>commission_user_card:</code> Commission that would be applied if
            the payment is made by card.
          </li>
          <li>
            <code>codtran:</code> Transaction code that identifies the executed
            operation.
          </li>
          <li>
            <code>user_commission:</code> Commission that will be applied to the
            user.
          </li>
          <li>
            <code>amount:</code> Negative transaction amount with the applied
            format.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>level:</code> Error importance level.
          </li>
          <li>
            <code>message:</code> Error message.
          </li>
          <li>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          By means of this service a Interbanking Electronic Payment System to the
          introduced data is made.{' '}
        </p>
        </div>

      <div className='col-md-5'>
        <h2 id="exaple-requests">Exaple requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
        </div>
      </Fragment>
    );
  }
export default Retirar_a_Tarjeta_EN;
