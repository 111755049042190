import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DevDashboardLayout';
import DashRouters from '../../configs/routers/dev-dashboard-routers.json';
import { Row, Col, Alert, Card, Button  } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';


type IncomeProps = {
};


const DeveloperPersonalContent:FunctionComponent<IncomeProps> = ({ }) => {
  const history = useHistory();
  return(
    <DashLayout sider={DashRouters} active={'developers'} sublinks={[]}>
      <Row>
        <Col md="9" className="mx-auto">
          
          <Row>
            <Col>
              <h1 className="mb-3 mt-5">Staging Projects</h1>
            </Col>
          </Row>
          <Row className="income">
            <Col>
              <Card className="mb-3 obex-card">
                <Card.Body className="p-0 income-detail-card">
                  <Card.Text className="px-3 pt-2 mb-2">
                    <Row>
                      <Col md="8">
                        <a onClick={()=>{history.push(`/developers_personal/staging_projects`)}}>Projects Info</a>
                      </Col>
                      <Col md="4" className="text-right">
                      <span> <FontAwesomeIcon cursor="pointer" icon={faExternalLinkSquareAlt} size="1x" onClick={()=>{history.push(`/developers_personal/staging_projects`)}}/></span>
                      </Col>
                    </Row>
                  </Card.Text>
                  
                  </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1 className="my-3">Production Projects</h1>
            </Col>
          </Row>
          <Row className="income">
            <Col>
              <Card className="mb-3 obex-card">
                <Card.Body className="p-0 income-detail-card">
                  <Card.Text className="px-3 pt-2 mb-2">
                    <Row>
                      <Col md="8">
                        <a onClick={()=>{history.push(`/developers_personal/apikeys`)}}>API Keys</a>
                      </Col>
                      <Col md="4" className="text-right">
                      <span> <FontAwesomeIcon cursor="pointer" icon={faExternalLinkSquareAlt} size="1x" onClick={()=>{history.push(`/developers_personal/apikeys`)}}/></span>
                      </Col>
                    </Row>
                  </Card.Text>
                  <Card.Text className="px-3 pt-2 mb-2">
                    <Row>
                      <Col md="8">
                        <a onClick={()=>{history.push(`/developers_personal/logs`)}}>Project Logs</a>
                      </Col>
                      <Col md="4" className="text-right">
                      <span> <FontAwesomeIcon cursor="pointer" icon={faExternalLinkSquareAlt} size="1x" onClick={()=>{history.push(`/developers_personal/logs`)}}/></span>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1 className="my-3">SandBox</h1>
            </Col>
          </Row>
          <Row className="income">
            <Col>
              <Card className="income-detail-card mb-3 obex-card">
                <Card.Body className="p-0">
                  <Card.Text className="px-3 pt-2 mb-2">
                    <Row>
                      <Col md="8">
                        <a onClick={()=>{history.push(`/developers_personal/sandbox`)}}>
                          Here you can test all the API Products.
                        </a>
                      </Col>
                      <Col md="4" className="text-right">
                      <span> <FontAwesomeIcon cursor="pointer" icon={faExternalLinkSquareAlt} size="1x" onClick={()=>{history.push(`/developers_personal/sandbox`)}}/></span>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>

              </Card>
            </Col>
          </Row>
          //
          <Row>
            <h1 className="my-3">API Selecctor</h1>
          </Row>
          <Row className="income">
            <Col>
              <Card>
              </Card>
            </Col>
          </Row>
          //
        </Col>
      </Row>
    </DashLayout>
  )
}

export default DeveloperPersonalContent;