import React, { Fragment } from 'react';

const TERMINAL_TerminalBasics = () => (
  <Fragment>
    <h1 id="terminal-basics">Terminal Basics</h1>
    <p>
      AVAP<sup>TM</sup> Dev Studio includes a full featured integrated terminal
      that starts at the root of your workspace. It provides integration with
      the editor to support features like links and error detection. The
      integrated terminal can run commands such as mkdir and git just like a
      standalone terminal.
    </p>
    <p>You can open a terminal as follows:</p>
    <ul>
      <li>
        From the menu, use the <strong>Terminal</strong> &gt;{' '}
        <strong>New Terminal</strong> or <strong>View</strong> &gt;{' '}
        <strong>Terminal</strong> menu commands.
      </li>
      <li>
        From the <strong>Command Palette</strong>{' '}
        <code>&#40;Ctrl+Shift+P&#41;</code>, use the{' '}
        <strong>View: Toggle Terminal</strong> command.
      </li>
      <li>
        In the Explorer, you can use the{' '}
        <strong>Open in Integrated Terminal</strong> context menu command to
        open a new terminal from a folder.
      </li>
      <li>To toggle the terminal panel, use the `Ctrl+`` keyboard shortcut.</li>
      <li>
        To create a new terminal, use the `Ctrl+Shift+`` keyboard shortcut.
      </li>
    </ul>
    <p>
      AVAP<sup>TM</sup> Dev Studio&#39;s terminal has additional functionality
      called shell integration that tracks where commands are run with
      decorations on the left of a command and in the scrollbar:
    </p>
    <blockquote>
      <p>
        <strong>Note:</strong> If you prefer to work outside AVAP<sup>TM</sup>{' '}
        Dev Studio, open an external terminal with the <code>Ctrl+Shift+C</code>{' '}
        keyboard shortcut
      </p>
    </blockquote>
    <h2 id="terminal-shells">Terminal shells</h2>
    <p>
      The integrated terminal can use various shells installed on your machine,
      with the default being pulled from your system defaults. Shells are
      detected and presented in the terminal profiles dropdown.
    </p>
    <p>
      You can learn more about configuring terminal shells in the terminal
      profiles article.
    </p>
    <h2 id="managing-terminals">Managing terminals</h2>
    <p>
      The terminal tabs UI is on the right side of the terminal view. Each
      terminal has an entry with its name, icon, color, and group decoration
      &#40;if any&#41;.
    </p>
    <p>
      Add terminal instances by selecting the <strong>+</strong> icon on the
      top-right of the <strong>TERMINAL</strong> panel, selecting a profile from
      the terminal dropdown, or by triggering the <code>Ctrl+Shift+</code>{' '}
      command. This action creates another entry in the tab list associated with
      that terminal.
    </p>
    <p>
      Remove terminal instances by hovering a tab and selecting the{' '}
      <strong>Trash Can</strong> button, selecting a tab item and pressing
      Delete, using <strong>Terminal: Kill the Active Terminal Instance</strong>{' '}
      command, or via the right-click context menu.
    </p>
    <p>
      Navigate between terminal groups using focus next{' '}
      <code>Ctrl+PageDown</code> and focus previous <code>Ctrl+PageUp</code>.
    </p>
    <p>
      Icons may appear to the right of the terminal title on the tab label when
      a terminal&#39;s status changes. Some examples are a bell &#40;macOS&#41;
      and for tasks, displaying a check mark when there are no errors and an X
      otherwise. Hover the icon to read status information, which may contain
      actions.
    </p>
    <h3 id="groups-split-panes">Groups &#40;split panes&#41;</h3>
    <p>
      Place multiple terminals side-by-side and create a group by splitting a
      terminal:
    </p>
    <ul>
      <li>
        Hover over a entry in the list of terminals on the right and select the
        inline split button.
      </li>
      <li>
        Right-click the context menu and selecting the <strong>Split</strong>{' '}
        menu option.
      </li>
      <li>
        <code>Alt</code> and click on a tab, the <strong>+</strong> button, or
        the single tab on the terminal panel.
      </li>
      <li>Trigger the Ctrl+Shift+5 command.</li>
    </ul>
    <blockquote>
      <p>
        <strong>Tip:</strong> The working directory for the new terminal depends
        on the terminal.integrated.splitCwd setting.
      </p>
    </blockquote>
    <p>
      Navigate between terminals in a group by focusing the previous pane,{' '}
      <code>Alt+Left</code>, or the next pane, <code>Alt+Right</code>.
    </p>
    <p>
      Dragging and dropping tabs in the list rearranges them. Dragging a tab
      into the main terminal area allows moving a terminal from one group to
      another.
    </p>
    <p>
      Moving a terminal into its own group can be done with the{' '}
      <strong>Terminal: Unsplit Terminal</strong> command through the Command
      Palette or in the right-click context menu.
    </p>
    <h2 id="terminals-in-editor-area">Terminals in editor area</h2>
    <p>
      You can open terminals in the editor area &#40;terminal editors&#41; with
      the <strong>Terminal: Create New Terminal in Editor Area</strong> command,
      the{' '}
      <strong>Terminal: Create New Terminal in Editor Area to the Side</strong>{' '}
      command, or by dragging a terminal from the terminal view into the editor
      area. Terminal editors are presented like regular editor tabs:
    </p>
    <p>
      You can have terminal editors on either side or arranged in multiple
      dimensions using the editor group layout system, e.g. PowerShell and WSL
      terminals stacked to the right of file editors:
    </p>
    <p>
      The <code>terminal.integrated.defaultLocation</code> setting can change
      the default <code>view</code> or <code>editor</code> area terminal
      location.
    </p>
    <h2 id="navigating-the-buffer">Navigating the buffer</h2>
    <p>
      The content in the terminal is called the buffer, with the section right
      above the bottom viewport being called &quot;scrollback&quot;. The amount
      of scrollback kept is determined by the terminal.integrated.scrollback{' '}
      <a href="/docs/getstarted/settings">setting</a> and defaults to 1000
      lines.
    </p>
    <p>
      There are various commands available to navigate around the terminal
      buffer:
    </p>
    <ul>
      <li>
        Scroll up a line - <code>Ctrl+Alt+PageUp</code>
      </li>
      <li>
        Scroll down a line - <code>Ctrl+Alt+PageDown</code>
      </li>
      <li>
        Scroll up a page - <code>Shift+PageUp</code>
      </li>
      <li>
        Scroll down a page - <code>Shift+PageDown</code>
      </li>
      <li>
        Scroll to the top - <code>Ctrl+Home</code>
      </li>
      <li>
        Scroll to the bottom - <code>Ctrl+End</code>
      </li>
    </ul>
    <p>
      <strong>Command</strong> navigation is also available &#40;see shell
      integration&#41;:
    </p>
    <ul>
      <li>
        Scroll to the previous command - <code>Ctrl+Up</code>
      </li>
      <li>
        Scroll to the next command - <code>Ctrl+Down</code>
      </li>
    </ul>
    <p>
      Scrolling will happen instantaneously, but can be configured to animate
      over a short duration with the{' '}
      <code>terminal.integrated.smoothScrolling</code> setting.
    </p>
    <h2 id="links">Links</h2>
    <p>
      The terminal features sophisticated link detection with editor integration
      and even extension contributed link handlers. Hover over a link to display
      an underline, then hold the <code>Ctrl/Cmd</code> key and click.
    </p>
    <p>
      These built-in link handlers are used in the following priority order:
    </p>
    <ul>
      <li>
        URIs/URLs: Links that look like URIs, such as{' '}
        <code>https://code.visualstudio.com</code>, vscode://path/to/file or{' '}
        <code>file://path/to/file</code> will open using the standard handler
        for the protocol. For example, <code>https</code> links will open the
        browser.
      </li>
    </ul>
    <ul>
      <li>
        File links: Links to files that have been verified to exist on the
        system. These will open the file in a new editor tab and support many
        common line/column formats such as{' '}
        <code>file:1:2, file:line 1, column 2</code>.
      </li>
    </ul>
    <ul>
      <li>
        Folder links: Links to folders are similar to file links but will open a
        new AVAP<sup>TM</sup> Dev Studio window at the folder.
      </li>
    </ul>
    <ul>
      <li>
        Word links: Fallback link type that uses the{' '}
        <code>terminal.integrated.wordSeparators</code> setting. The setting
        defines word boundaries and make nearly all text into words. Activating
        a word link searches the workspace for the word. If there is a single
        result it will open, otherwise it will present the search results. Word
        links are considered &quot;low confidence&quot; and will not show an
        underline or tooltip unless you hold the <code>Ctrl</code>/
        <code>Cmd</code> key. They also have limited support for line and column
        suffixes.
      </li>
    </ul>
    <p>
      The <strong>Open Detected Link</strong> command &#40;
      <code>Ctrl+Shift+O</code>&#41; can be used to access links via the
      keyboard:
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> If link verification causes performance issues,
        like in high latency remote environments, disable it via the{' '}
        <code>terminal.integrated.enableFileLinks</code> setting.
      </p>
    </blockquote>
    <h3 id="extensions-handling-links">Extensions handling links</h3>
    <p>
      Extensions can contribute <strong>link providers</strong> which allow the
      extension to define what happens when clicked. An example of this is the
      GitLens extension detecting Git branch links.
    </p>
    <h3 id="keyboard-accessibility">Keyboard accessibility</h3>
    <p>
      Links are keyboard accessible through several commands that open links
      based on the type of link.
    </p>
    <ul>
      <li>
        <strong>Terminal: Open Last Local File Link</strong> - Opens the most
        recent local file link. No default keybinding.
      </li>
      <li>
        <strong>Terminal: Open Last URL link</strong> - Opens the most recent
        URI/URL link. No default keybinding.
      </li>
      <li>
        <strong>Terminal: Open Detected Link...</strong> - Opens a searchable
        Quick Pick with all detected links, including word links. The default
        keybinding is <code>Ctrl/Cmd+Shift+O</code>, which is the same as the{' '}
        <strong>Go to Symbol in Editor</strong> keyboard shortcut.
      </li>
    </ul>
    <h2 id="copy--paste">Copy &amp; paste</h2>
    <p>The keybindings for copy and paste follow platform standards:</p>
    <ul>
      <li>
        Linux: <code>Ctrl+Shift+C</code> and <code>Ctrl+Shift+V</code>;
        selection paste is available with <code>Shift+Insert</code>
      </li>
      <li>
        macOS: <code>Cmd+C</code> and <code>Cmd+V</code>
      </li>
      <li>
        Windows: <code>Ctrl+C</code> and <code>Ctrl+V</code>
      </li>
    </ul>
    <p>
      Copying is done automatically on selection when{' '}
      <code>terminal.integrated.copyOnSelection</code> is enabled.
    </p>
    <p>
      By default, there is a warning when pasting multiple lines, which can be
      disabled with the{' '}
      <code>terminal.integrated.enableMultiLinePasteWarning</code> setting. This
      is only done when the shell does not support &quot;bracketed paste
      mode&quot;. When that mode is enabled, the shell is indicating that it can
      handle multiple line pasting.
    </p>
    <h2 id="using-the-mouse">Using the mouse</h2>
    <h3 id="right-click-behavior">Right-click behavior</h3>
    <p>The right-click behavior differs based on the platform:</p>
    <ul>
      <li>Linux: Show the context menu.</li>
      <li>
        macOS: Select the word under the cursor and show the context menu.
      </li>
      <li>
        Windows: Copy and drop selection if there is a selection, otherwise
        paste.
      </li>
    </ul>
    <p>
      This can be configured using the terminal.integrated.rightClickBehavior
      setting. The options are:
    </p>
    <ul>
      <li>
        <code>default</code> - Show the context menu.
      </li>
      <li>
        <code>copyPaste</code> - Copy when there is a selection, otherwise
        paste.
      </li>
      <li>
        <code>paste</code> - Paste on right-click.
      </li>
      <li>
        <code>selectWord</code> - Select the word under the cursor and show the
        context menu.
      </li>
      <li>
        <code>nothing</code> - Do nothing and pass event to terminal.
      </li>
    </ul>
    <h3 id="reposition-the-cursor-with-alt">Reposition the cursor with Alt</h3>
    <p>
      <code>Alt</code> and left-click will reposition the cursor to underneath
      the mouse. This works by simulating arrow keystrokes, which may not work
      reliably for some shells or programs. This feature can be disabled with
      the <code>terminal.integrated.altClickMovesCursor</code> setting.
    </p>
    <h3 id="mouse-events-mode">Mouse events mode</h3>
    <p>
      When applications running in the terminal turn on mouse events mode, such
      as Vim mouse mode, mouse interaction is sent to the application instead of
      the terminal. This means that clicking and dragging will no longer create
      a selection. Terminal selection can be forced by holding the{' '}
      <code>Alt</code> key on Windows and Linux, this can also be done with the{' '}
      <code>Option</code> key on macOS but requires enabling the{' '}
      <code>terminal.integrated.macOptionClickForcesSelection</code> setting
      first.
    </p>
    <h2 id="find">Find</h2>
    <p>
      The integrated terminal has find functionality that can be triggered with{' '}
      <code>Ctrl+F</code>.
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> <code>Ctrl+F</code> can be sent to the shell by
        removing the <code>workbench.action.terminal.focusFind</code> command
        from commands to skip shell.
      </p>
    </blockquote>
    <h2 id="run-selected-text">Run selected text</h2>
    <p>
      To use the <code>runSelectedText</code> command, select text in an editor
      and run the command{' '}
      <strong>Terminal: Run Selected Text in Active Terminal</strong> via the{' '}
      <strong>Command Palette</strong> <code>&#40;Ctrl+Shift+P&#41;</code>, the
      terminal will attempt to run the selected text. If no text is selected in
      the active editor, the entire line that the cursor is on will run in the
      terminal.
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> Also run the active file using the command{' '}
        <code>workbench.action.terminal.runActiveFile</code>.
      </p>
    </blockquote>
    <h2 id="maximizing-the-terminal">Maximizing the terminal</h2>
    <p>
      The terminal view can be maximized by clicking the maximize panel size
      button with the upwards chevron icon. This will temporarily hide the
      editors and maximize the panel. This is useful to temporarily focus on a
      large amount of output. Some developers use AVAP<sup>TM</sup> Dev Studioe
      as a standalone terminal by opening a new window, maximizing the panel,
      and hiding the side bar.
    </p>
    <p>
      Note that the panel can only be maximized if its alignment option is set
      to <strong>Center</strong>.
    </p>
    <h2 id="select-all">Select all</h2>
    <p>
      There is a <strong>Terminal: Select All</strong> command, which is bound
      to Cmd+A on macOS, but does not have a default keybinding on Windows and
      Linux as it may conflict with shell hotkeys. To use <code>Ctrl+A</code> to
      select all, add this custom keybinding:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"key": "ctrl+a",
        <br />` `"command": "workbench.action.terminal.selectAll",
        <br />` `"when": "terminalFocus &amp;&amp; !isMac"
        <br />
        &#125;,
      </code>
    </pre>
    <h2 id="drag-and-drop-file-paths">Drag and drop file paths</h2>
    <p>
      Dragging a file into the terminal will input the path into the terminal,
      with escaping to match the active shell.
    </p>
    <h2 id="automating-terminals-with-tasks">
      Automating terminals with tasks
    </h2>
    <p>
      The Tasks feature can be used to automate the launching of terminals, for
      example, the following <code>.vscode/tasks.json</code> file will launch a
      Command Prompt and PowerShell terminal in a single terminal group when the
      window starts:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"version": "2.0.0",
        <br />` `"presentation": &#123;
        <br />` `"echo": false,
        <br />` `"reveal": "always",
        <br />` `"focus": false,
        <br />` `"panel": "dedicated",
        <br />` `"showReuseMessage": true
        <br />` `&#125;,
        <br />` `"tasks": [<br />` `&#123;
        <br />` `"label": "Create terminals",
        <br />` `"dependsOn": [<br />` `"First",
        <br />` `"Second"
        <br />` `],
        <br />` `// Mark as the default build task so cmd/ctrl+shift+b will
        create them
        <br />` `"group": &#123;
        <br />` `"kind": "build",
        <br />` `"isDefault": true
        <br />` `&#125;,
        <br />` `// Try start the task on folder open
        <br />` `"runOptions": &#123;
        <br />` `"runOn": "folderOpen"
        <br />` `&#125;
        <br />` `&#125;,
        <br />` `&#123;
        <br />` `// The name that shows up in terminal tab
        <br />` `"label": "First",
        <br />` `// The task will launch a shell
        <br />` `"type": "shell",
        <br />` `"command": "",
        <br />` `// Set the shell type
        <br />` `"options": &#123;
        <br />` `"shell": &#123;
        <br />` `"executable": "cmd.exe",
        <br />` `"args": []
        <br />` `&#125;
        <br />` `&#125;,
        <br />` `// Mark as a background task to avoid the spinner animation on
        the terminal tab
        <br />` `"isBackground": true,
        <br />` `"problemMatcher": [],
        <br />` `// Create the tasks in a terminal group
        <br />` `"presentation": &#123;
        <br />` `"group": "my-group"
        <br />` `&#125;
        <br />` `&#125;,
        <br />` `&#123;
        <br />` `"label": "Second",
        <br />` `"type": "shell",
        <br />` `"command": "",
        <br />` `"options": &#123;
        <br />` `"shell": &#123;
        <br />` `"executable": "pwsh.exe",
        <br />` `"args": []
        <br />` `&#125;
        <br />` `&#125;,
        <br />` `"isBackground": true,
        <br />` `"problemMatcher": [],
        <br />` `"presentation": &#123;
        <br />` `"group": "my-group"
        <br />` `&#125;
        <br />` `&#125;
        <br />` `]
        <br />
        &#125;
      </code>
    </pre>
    <p>
      This file could be committed to the repository to share with other
      developers or created as a user task via the{' '}
      <code>workbench.action.tasks.openUserTasks</code> command.
    </p>
    <h2 id="working-directory">Working directory</h2>
    <p>
      By default, the terminal will open at the folder that is opened in the
      Explorer. The <code>terminal.integrated.cwd</code> setting allows
      specifying a custom path to open instead:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"terminal.integrated.cwd": "/home/user"
        <br />
        &#125;
      </code>
    </pre>
    <p>
      Split terminals on Windows will start in the directory that the parent
      terminal started with. On macOS and Linux, split terminals will inherit
      the current working directory of the parent terminal. This behavior can be
      changed using the <code>terminal.integrated.splitCwd</code> setting:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"terminal.integrated.splitCwd": "workspaceRoot"
        <br />
        &#125;
      </code>
    </pre>
    <p>
      There are also extensions available that give more options such as{' '}
      <a href="https://marketplace.visualstudio.com/items?itemName=Tyriar.vscode-terminal-here">
        Terminal Here
      </a>
      .
    </p>
    <h2 id="fixed-dimension-terminals">Fixed dimension terminals</h2>
    <p>
      The <strong>Terminal: Set Fixed Dimensions</strong> command allows
      changing the number of columns and rows that the terminal and it&#39;s
      backing pseudoterminal uses. This will add scroll bars when necessary,
      which may lead to an unpleasant UX and is generally not recommended, but
      it is a common ask on Windows in particular for reading logs or long lines
      when paging tools aren&#39;t available.
    </p>
    <p>
      You can also right-click on a terminal tab and select{' '}
      <strong>Toggle Size to Content Width</strong> <code>&#40;Alt+Z&#41;</code>{' '}
      to resize the number of terminal columns to the largest wrapped line in
      the terminal.
    </p>
    <h2 id="next-steps">Next steps</h2>
    <p>
      The basics of the terminal have been covered in this document. Read on to
      find out more about:
    </p>
    <ul>
      <li>
        Tasks - Tasks let you integrate with external tools and leverage the
        terminal heavily.
      </li>
      <li>
        Mastering AVAP<sup>TM</sup> Dev Studio&#39;s Terminal - An external blog
        with plenty of power user tips for the terminal.
      </li>
      <li>
        Explore terminal commands by browsing the keyboard shortcuts within AVAP
        <sup>TM</sup> Dev Studio &#40;
        <strong>Preferences: Open Keyboard Shortcuts</strong> then search on
        &#39;terminal&#39;&#41;.
      </li>
    </ul>
    <h2 id="common-questions">Common questions</h2>
    <h3 id="im-having-problems-launching-the-terminal">
      I&#39;m having problems launching the terminal
    </h3>
    <p>
      There&#39;s a dedicated troubleshooting guide for these sorts of problems.
    </p>
    <h3 id="how-do-i-create-an-admin-terminal">
      How do I create an Admin terminal?
    </h3>
    <p>
      The integrated terminal shell is running with the permissions of AVAP
      <sup>TM</sup> Dev Studio. If you need to run a shell command with elevated
      &#40;administrator&#41; or different permissions, use platform utilities
      such as <code>runas.exe</code> within a terminal.
    </p>
    <p>
      You can learn more about customizing terminals via terminal profiles in
      Configuring Profiles.
    </p>
    <h3 id="can-i-add-a-keyboard-shortcut-for-the-explorers-open-in-integrated-terminal-command">
      Can I add a keyboard shortcut for the Explorer&#39;s Open in Integrated
      Terminal command?
    </h3>
    <p>
      You can open new terminals for specific folders from the Explorer via the{' '}
      <strong>Open in Integrated Terminal</strong> context menu command.
    </p>
    <p>
      By default, there is no keyboard shortcut associated with{' '}
      <strong>Open in Integrated Terminal</strong> but you can add your own via
      the Keyboard Shortcuts editor <code>&#40;Ctrl+K Ctrl+S&#41;</code> to add
      a keybinding to your keybindings.json.
    </p>
    <p>
      The <code>keybindings.json</code> example below adds the keyboard shortcut{' '}
      <code>Ctrl+T</code> for openInTerminal.
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"key": "ctrl+t",
        <br />` `"command": "openInTerminal",
        <br />` `"when": "filesExplorerFocus"
        <br />
        &#125;
      </code>
    </pre>
    <h3 id="why-is-nvm-complaining-about-a-prefix-option-when-the-integrated-terminal-is-launched">
      Why is nvm complaining about a prefix option when the integrated terminal
      is launched?
    </h3>
    <p>
      nvm &#40;Node Version Manager&#41; users often see this error for the
      first time inside AVAP<sup>TM</sup> Dev Studio&#39;s integrated terminal:
    </p>

    <pre className="">
      <code className="">
        nvm is not compatible with the npm config "prefix" option: currently set
        to "/usr/local"
        <br />
        Run `npm config delete prefix` or `nvm use --delete-prefix v8.9.1
        --silent` to unset it
      </code>
    </pre>
    <p>
      This is mostly a macOS problem and does not happen in external terminals.
      The typical reasons for this are the following:
    </p>
    <ul>
      <li>
        <code>npm</code> was globally installed using another instance of{' '}
        <code>node</code> that is somewhere in your path &#40;
        <code>such as /usr/local/bin/npm</code>&#41;.
      </li>
      <li>
        To get the development tools on the <code>$PATH</code>, AVAP
        <sup>TM</sup> Dev Studio will launch a bash login shell on startup. This
        means that your ~/.bash_profile has already run and when an integrated
        terminal launches, it will run <strong>another</strong> login shell,
        reordering the $PATH potentially in unexpected ways.
      </li>
    </ul>
    <p>
      To resolve this issue, you need to track down where the old{' '}
      <code>npm</code> is installed and remove both it and its out-of-date
      node_modules. Find the <code>nvm</code> initialization script and run{' '}
      <code>which npm</code> before it runs, which should print the path when
      you launch a new terminal.
    </p>
    <p>
      Once you have the path to npm, find the old node_modules by resolving the
      symlink by running a command something like this:
    </p>

    <pre className="">
      <code className="">ls -la /usr/local/bin | grep "np[mx]"</code>
    </pre>
    <p>This will give you the resolved path at the end:</p>

    <pre className="">
      <code className="">
        ... npm -&gt; ../lib/node\_modules/npm/bin/npm-cli.js
        <br />
        ... npx -&gt; ../lib/node\_modules/npm/bin/npx-cli.js
      </code>
    </pre>
    <p>
      From there, removing the files and relaunching AVAP<sup>TM</sup> Dev
      Studio should fix the issue:
    </p>

    <pre className="">
      <code className="">
        rm /usr/local/bin/npm /usr/local/lib/node\_modules/npm/bin/npm-cli.js
        <br />
        rm /usr/local/bin/npx /usr/local/lib/node\_modules/npm/bin/npx-cli.js
      </code>
    </pre>
    <h3 id="why-does-macos-make-a-ding-sound-when-i-resize-terminal-split-panes">
      Why does macOS make a ding sound when I resize terminal split panes?
    </h3>
    <p>
      The keybindings ⌃⌘← and ⌃⌘→ are the defaults for resizing individual split
      panes in the terminal. While they work, they also cause a system
      &quot;invalid key&quot; sound to play due to an issue in Chromium. The{' '}
      <a href="https://github.com/microsoft/vscode/issues/44070#issuecomment-799716362">
        recommended workaround
      </a>{' '}
      is to tell macOS to no-op for these keybindings by running this in your
      terminal:
    </p>

    <pre className="">
      <code className="">
        mkdir -p ~/Library/KeyBindings
        <br />
        cat &gt; ~/Library/KeyBindings/DefaultKeyBinding.dict &lt;&lt;EOF
        <br />
        &#123;
        <br />` `"^@\UF701" = "noop";
        <br />` `"^@\UF702" = "noop";
        <br />` `"^@\UF703" = "noop";
        <br />
        &#125;
        <br />
        EOF
      </code>
    </pre>
    <h3 id="im-having-problems-with-the-terminal-rendering-what-can-i-do">
      I&#39;m having problems with the terminal rendering. What can I do?
    </h3>
    <p>
      By default, the integrated terminal will render using GPU acceleration on
      most machines. Typically when there are rendering problems it&#39;s an
      issue of something in your hardware/OS/drivers not playing nicely with the
      GPU renderer. The first thing to try is to disable GPU acceleration,
      trading off rendering speed for DOM-based rendering, which is more
      reliable:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"terminal.integrated.gpuAcceleration": "off"
        <br />
        &#125;
      </code>
    </pre>
    <p>See the GPU acceleration section for more information.</p>
    <h3 id="i-see-1-or-201-when-i-paste-something">
      I see 1~ or [201~ when I paste something
    </h3>
    <p>
      This normally means that the program/shell running inside the terminal
      requested to turn on &quot;bracketed paste mode&quot; but something
      doesn&#39;t support it properly. To work around this, you could run{' '}
      <code>printf &quot;\e[?2004l&quot;</code> to disable it for that session
      or add the following to your <code>~/.inputrc</code>file:
    </p>

    <pre className="">
      <code className="">set enable-bracketed-paste off</code>
    </pre>
    <p>
      Alternatively, bracketed paste mode can be forced to ignore the
      shell&#39;s request by turning it off with this setting:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"terminal.integrated.ignoreBracketedPasteMode": true
        <br />
        &#125;
      </code>
    </pre>
    <h3 id="ctrla-ctrlr-output-a-r-on-zsh">
      Ctrl+A, Ctrl+R output ^A, ^R on zsh
    </h3>
    <p>
      This can happen if zsh is in Vim mode instead of Emacs mode, due to
      setting <code>$EDITOR</code> or <code>$VISUAL</code> to <code>vi</code>/
      <code>vim</code> in your init scripts.
    </p>
    <p>To work around this, you have two options:</p>
    <ul>
      <li>
        Ensure that you don&#39;t set <code>$EDITOR</code> to{' '}
        <code>vi&#40;m&#41;</code>. However, this isn&#39;t an option if you
        want your Git editor to work.
      </li>
      <li>
        Add <code>bindkey -e</code> to your init script to set Emacs explicitly.
      </li>
    </ul>
    <h3 id="how-can-i-configure-cmd-to-map-to-ctrlc-like-macos-built-in-terminal">
      How can I configure Cmd+. to map to Ctrl+C like macOS&#39; built-in
      terminal?
    </h3>
    <p>
      The macOS default terminal uses <code>Cmd+</code>. to perform the same as{' '}
      <code>Ctrl+C</code>. To get this behavior in AVAP<sup>TM</sup> Dev Studio,
      add this custom keybinding:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"key": "cmd+.",
        <br />` `"command": "workbench.action.terminal.sendSequence",
        <br />` `"when": "terminalFocus",
        <br />` `"args": &#123; "text": "\u0003" &#125;
        <br />
        &#125;
      </code>
    </pre>
    <h3 id="why-are-the-colors-in-the-terminal-not-correct">
      Why are the colors in the terminal not correct?
    </h3>
    <p>
      One of our accessibility features we enable by default is to ensure a
      minimum contrast ratio of at least 4.5 is met for the foreground text.
      This feature ensures that text is readable regardless of the shell and
      theme used which is not possible otherwise. To disable this feature, you
      can set:
    </p>

    <pre className="">
      <code className="">"terminal.integrated.minimumContrastRatio": 1</code>
    </pre>
    <p>See the minimum contrast ratio section for more information.</p>
  </Fragment>
);
export default TERMINAL_TerminalBasics;
