import React, { Fragment } from 'react';

const Introduction = () => (
  <Fragment>
    <h1 >101OBeX Cli</h1>
    <h1 >Introduction</h1>
    <p>
      This tool is designed to enable developers to work with the 101OBeX API.
      With this tool, developers can retrieve information about their API
      privileges, quotas, API Token, and more..
    </p>
    <h1 >Getting Started</h1>
    <p>
      To begin, developers need to initialize their token using the
      &#39;init&#39; parameter. This process involves authenticating through the
      Google OAuth API to obtain the API token, which is stored locally on their
      computer. Once the token is initialized, developers can use the
      &#39;info&#39; parameter to access details about their API privileges,
      projects, teams, and access token. Finally, developers have the option to
      remove all downloaded information from their computer using the
      &#39;clean&#39; parameter.
    </p>
    <h1 >Releases:</h1>
    <ul>
      <li>
        <a href="https://github.com/101OBeXCorp/101obexcli/releases">
          https://github.com/101OBeXCorp/101obexcli/releases
        </a>
      </li>
    </ul>
    <h1 >Last release:</h1>
    <p>Mac:</p>
    <ul>
      <li>
        <a href="https://github.com/101OBeXCorp/101obexcli/releases/download/prerelease-staging/101obexcli-macosx.zip">
          https://github.com/101OBeXCorp/101obexcli/releases/download/prerelease-staging/101obexcli-macosx.zip
        </a>
      </li>
    </ul>
    <p>Linux:</p>
    <ul>
      <li>
        <a href="https://github.com/101OBeXCorp/101obexcli/releases/download/prerelease/101obexcli.-.linux.zip">
          https://github.com/101OBeXCorp/101obexcli/releases/download/prerelease/101obexcli.-.linux.zip
        </a>
      </li>
    </ul>
    <p>Win32:</p>
    <ul>
      <li>
        <a href="https://github.com/101OBeXCorp/101obexcli/releases/download/prerelease/101obexcli-win32.zip">
          https://github.com/101OBeXCorp/101obexcli/releases/download/prerelease/101obexcli-win32.zip
        </a>
      </li>
    </ul>
  </Fragment>
);
export default Introduction;
