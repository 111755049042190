import React, { Fragment } from 'react';

const SETUP_Network = () => (
  <Fragment>
    <h1 id="network-connections-in-avap™-dev-studio-2024">
      Network Connections in AVAP™ Dev Studio 2024
    </h1>
    <p>
      AVAP™ Dev Studio 2024 is built on top of Electron and benefits from all
      the networking stack capabilities of Chromium. This also means that AVAP™
      Dev Studio 2024 users get much of the networking support available in
      Google Chrome.
    </p>
    <h2 id="common-hostnames">Common hostnames</h2>
    <p>
      A handful of features within AVAP™ Dev Studio 2024 require network
      communication to work, such as the auto-update mechanism, querying and
      installing extensions, and telemetry. For these features to work properly
      in a proxy environment, you must have the product correctly configured.
    </p>
    <p>
      If you are behind a firewall that needs to allow specific domains used by
      AVAP™ Dev Studio 2024, here&#39;s the list of hostnames you should allow
      communication to go through:
    </p>
    <ul>
      <li>
        <code>update.code.visualstudio.com</code> - AVAP™ Dev Studio 2024
        download and update server
      </li>
      <li>
        <code>code.visualstudio.com</code> - AVAP™ Dev Studio 2024 documentation
      </li>
      <li>
        <code>go.microsoft.com</code> - Microsoft link forwarding service
      </li>
      <li>
        <code>vscode.blob.core.windows.net</code> - AVAP™ Dev Studio 2024 blob
        storage, used for remote server
      </li>
      <li>
        <code>marketplace.visualstudio.com</code> - AVAP™ Dev Studio 2024
        Marketplace
      </li>
      <li>
        <code>*.gallerycdn.vsassets.io</code> - AVAP™ Dev Studio 2024
        Marketplace
      </li>
      <li>
        <code>*.gallery.vsassets.io</code> - AVAP™ Dev Studio 2024 Marketplace
      </li>
      <li>
        <code>rink.hockeyapp.net</code> - Crash reporting service
      </li>
      <li>
        <code>bingsettingssearch.trafficmanager.net</code> - In-product settings
        search
      </li>
      <li>
        <code>vscode.search.windows.net</code> - In-product settings search
      </li>
      <li>
        <code>raw.githubusercontent.com</code> - GitHub repository raw file
        access
      </li>
      <li>
        <code>vsmarketplacebadges.dev</code> - -{' '}
        <code>*.gallery.vsassets.io</code> - AVAP™ Dev Studio 2024 Marketplace
        badge service
      </li>
      <li>
        <code>az764295.vo.msecnd.net</code> - -{' '}
        <code>*.gallery.vsassets.io</code> - AVAP™ Dev Studio 2024 download CDN
        &#40;deprecated&#41;
      </li>
      <li>
        <code>vscode.download.prss.microsoft.com</code> - -{' '}
        <code>*.gallery.vsassets.io</code> - AVAP™ Dev Studio 2024 download CDN
      </li>
      <li>
        <code>download.visualstudio.microsoft.com</code> - -{' '}
        <code>*.gallery.vsassets.io</code> - AVAP™ Dev Studio 2024 download
        server, provides dependencies for some VS Code extensions &#40;C++,
        C#&#41;
      </li>
      <li>
        <code>vscode-sync.trafficmanager.net</code> - -{' '}
        <code>*.gallery.vsassets.io</code> - AVAP™ Dev Studio 2024 Settings Sync
        service
      </li>
      <li>
        <code>vscode-sync-insiders.trafficmanager.net</code> - -{' '}
        <code>*.gallery.vsassets.io</code> - AVAP™ Dev Studio 2024 Settings Sync
        service &#40;Insiders&#41;
      </li>
      <li>
        <code>vscode.dev</code> - Used when logging in with GitHub or Microsoft
        for an extension or Settings Sync
      </li>
      <li>
        <code>*.vscode-unpkg.net</code> - Used when loading web extensions
      </li>
      <li>
        <code>default.exp-tas.com</code> - - <code>*.gallery.vsassets.io</code>{' '}
        - AVAP™ Dev Studio 2024 Experiment Service, used to provide experimental
        user experiences
      </li>
    </ul>
    <h2 id="proxy-server-support">Proxy server support</h2>
    <p>
      AVAP™ Dev Studio 2024 has exactly the same proxy server support as Google
      Chromium. Here&#39;s a snippet from Chromium&#39;s documentation:
    </p>

    <pre className="">
      <code className="">
        "The Chromium network stack uses the system network settings so that
        users and administrators can control the network settings of all
        applications easily. The network settings include: <br />
        <br /> - proxy settings
        <br /> - SSL/TLS settings
        <br /> - certificate revocation check settings
        <br /> - certificate and private key stores"
      </code>
    </pre>
    <p>
      This means that your proxy settings should be picked up automatically.
    </p>
    <p>
      Otherwise, you can use the following command-line arguments to control
      your proxy settings:
    </p>

    <pre className="">
      <code className="">
        # Disable proxy
        <br />
        --no-proxy-server
        <br />
        <br /># Manual proxy address
        <br />
        --proxy-server=&lt;scheme&gt;=&lt;uri&gt;[:&lt;port&gt;][;...] |
        &lt;uri&gt;[:&lt;port&gt;] | "direct://"
        <br />
        <br /># Manual PAC address
        <br />
        --proxy-pac-url=&lt;pac-file-url&gt;
        <br />
        <br /># Disable proxy per host
        <br />
        --proxy-bypass-list=&#40;&lt;trailing_domain&gt;|&lt;ip-address&gt;&#41;[:&lt;port&gt;][;...]
      </code>
    </pre>
    <p>
      To learn more about these command-line arguments, see Chromium Network
      Settings.
    </p>
    <h4 id="authenticated-proxies">Authenticated proxies</h4>
    <p>
      Authenticated proxies should work seamlessly within AVAP™ Dev Studio 2024
      with the addition of PR #22369.
    </p>
    <p>The authentication methods supported are:</p>
    <ul>
      <li>Basic</li>
      <li>Digest</li>
      <li>NTLM</li>
      <li>Negotiate</li>
    </ul>
    <p>
      When using AVAP™ Dev Studio 2024 behind an authenticated HTTP proxy, the
      following authentication popup should appear:
    </p>
    <p>
      <img src="http://101obex.static.mooo.com/02-F_login.png" alt="proxy" />
    </p>
    <p>
      Note that SOCKS5 proxy authentication support isn&#39;t implemented yet;
      you can follow the issue in Chromium&#39;s issue tracker.
    </p>
    <p>
      See Chromium HTTP authentication to read more about HTTP proxy
      authentication within AVAP™ Dev Studio 2024.
    </p>
    <h4 id="ssl-certificates">SSL certificates</h4>
    <p>
      Often HTTPS proxies rewrite SSL certificates of the incoming requests.
      Chromium was designed to reject responses which are signed by certificates
      which it doesn&#39;t trust. If you hit any SSL trust issues, there are a
      few options available for you:
    </p>
    <ul>
      <li>
        Since Chromium uses the OS&#39;s certificate trust infrastructure, the
        preferred option is to add your proxy&#39;s certificate to your OS&#39;s
        trust chain. See the Chromium Root Certificate Policy documentation to
        learn more.
      </li>
      <li>
        If your proxy runs in <code>localhost</code>, you can always try the
        --allow-insecure-localhost command-line flag.
      </li>
      <li>
        If all else fails, you can tell AVAP™ Dev Studio 2024 to ignore all
        certificate errors using the --ignore-certificate-errors command-line
        flag. <strong>Warning:</strong> This is <strong>dangerous</strong> and{' '}
        <strong>not recommended</strong>, since it opens the door to security
        issues.
      </li>
    </ul>
    <h4 id="legacy-proxy-server-support">Legacy proxy server support</h4>
    <p>
      Extensions don&#39;t benefit yet from the same proxy support that AVAP™
      Dev Studio 2024 supports. You can follow this issue&#39;s development in
      GitHub.
    </p>
    <p>
      Similarly to extensions, a few other AVAP™ Dev Studio 2024 features
      don&#39;t yet fully support proxy networking, namely the CLI interface.
      The CLI interface is what you get when running{' '}
      <code>code --install-extension vscodevim.vim</code> from a command prompt
      or terminal. You can follow this issue&#39;s development in GitHub.
    </p>
    <p>
      Due to both of these constraints, the <code>http.proxy</code>,{' '}
      <code>http.proxyStrictSSL</code> and <code>http.proxyAuthorization</code>
      variables are still part of AVAP™ Dev Studio&#39;s settings, yet they are
      only respected in these two scenarios.
    </p>
    <h4 id="troubleshooting">Troubleshooting</h4>
    <p>
      Here are some helpful links that might help you troubleshoot networking
      issues in AVAP™ Dev Studio 2024:
    </p>
    <ul>
      <li>Network Settings</li>
      <li>Debugging problems with the network proxy</li>
      <li>Configuring a SOCKS proxy server in Chrome</li>
      <li>Proxy settings and fallback &#40;Windows&#41;</li>
    </ul>
  </Fragment>
);
export default SETUP_Network;
