import React, { Fragment } from 'react';

const SETUP_Uninstall = () => (
  <Fragment>
    <h1 id="uninstall-avap™-dev-studio-2024">
      Uninstall AVAP™ Dev Studio 2024
    </h1>
    <p>
      The steps for uninstalling AVAP™ Dev Studio will depend on your platform
      &#40;Windows, macOS, or Linux&#41; and the install option that you used.
      For example on Windows, you can use the System or User Windows Installers
      or download a <code>.zip</code> file and extract the contents anywhere on
      your machine.
    </p>
    <p>
      In general, you would uninstall AVAP™ Dev Studio as you would any other
      desktop application and follow your platform&#39;s recommended flow for
      uninstalling software. Specific platform guidance is provided below as
      well as how to completely clean up any remaining AVAP™ Dev Studio
      configuration files.
    </p>
    <h3 id="windows">Windows</h3>
    <h4 id="windows-installer">Windows Installer</h4>
    <p>
      If you installed AVAP™ Dev Studio via the Windows Installer, either the
      User or System version, use the installer to remove AVAP™ Dev Studio.
    </p>
    <ul>
      <li>
        <p>Start menu</p>
        <ul>
          <li>
            Search for <strong>Add or Remove Programs</strong> and find AVAP™
            Dev Studio in the <strong>Apps &gt; Apps &amp; features</strong>{' '}
            list.
          </li>
          <li>
            Select <strong>Uninstall</strong> from the actions dropdown on the
            right side &#40;three vertical dots&#41;.
          </li>
          <li>Follow the prompts to uninstall AVAP™ Dev Studio.</li>
        </ul>
      </li>
      <li>
        <p>Control Panel</p>
        <ul>
          <li>
            Under <strong>Programs</strong>, select the{' '}
            <strong>Uninstall a program</strong> link.
          </li>
          <li>
            Find the AVAP™ Dev Studio entry, right-click, and select the{' '}
            <strong>Uninstall</strong> command.
          </li>
          <li>Follow the prompts to uninstall AVAP™ Dev Studio.</li>
        </ul>
      </li>
    </ul>
    <h4 id="zip-file-installation">.zip file installation</h4>
    <p>
      If you installed AVAP™ Dev Studio on Windows by downloading and extracting
      one of the .zip files found on the AVAP™ Dev Studio website, you can
      uninstall by deleting the folder where you extracted the .zip contents.
    </p>
    <h3 id="macos">macOS</h3>
    <p>
      To uninstall AVAP™ Dev Studio on macOS, open Finder and go to
      Applications. Right-click on the AVAP™ Dev Studio application and select
      Move to Trash.
    </p>
    <h3 id="linux">Linux</h3>
    <p>
      To uninstall AVAP™ Dev Studio on Linux, you should use your package
      manager&#39;s uninstall or remove option. The exact command line will
      differ depending on which package manager you used &#40;for example,{' '}
      <code>apt-get</code>, <code>rpn</code>, <code>dnf</code>, <code>yum</code>
      , etc.&#41;.
    </p>
    <p>The names for the AVAP™ Dev Studio packages are:</p>
    <ul>
      <li>
        <code>code</code> - AVAP™ Dev Studio Stable release
      </li>
      <li>
        <code>code-insiders</code> - AVAP™ Dev Studio Insiders release
      </li>
    </ul>
    <p>
      For example, if you installed AVAP™ Dev Studio via the Debian package
      &#40;<code>.deb</code>&#41; and <code>apt-get</code> package manager, you
      would run:
    </p>

    <pre className="">
      <code className="">sudo apt-get remove code</code>
    </pre>
    <p>where code is the name of the AVAP™ Dev Studio Debian package.</p>
    <h3 id="clean-uninstall">Clean uninstall</h3>
    <p>
      If you want to remove all user data after uninstalling AVAP™ Dev Studio
      2024, you can delete the user data folders <code>Code</code> and{' '}
      <code>.vscode</code>. This will return you to the state before you
      installed AVAP™ Dev Studio 2024. This can also be used to reset all
      settings if you don&#39;t want to uninstall AVAP™ Dev Studio 2024.
    </p>
    <p>The folder locations will vary depending on your platform:</p>
    <ul>
      <li>
        <strong>Windows</strong> -{' '}
        <code>Delete %APPDATA%\Code and %USERPROFILE%\.vscode</code>.
      </li>
      <li>
        <strong>macOS</strong> - Delete{' '}
        <code>$HOME/Library/Application Support/Code</code> and{' '}
        <code>~/.vscode</code>.
      </li>
      <li>
        <strong>Linux</strong> - Delete <code>$HOME/.config/Code</code> and{' '}
        <code>~/.vscode</code>.
      </li>
    </ul>
    <h3 id="next-steps">Next steps</h3>
    <ul>
      <li>
        Setup overview - General information about AVAP™ Dev Studio 2024 setup
        and updates.
      </li>
      <li>
        Windows setup - Details and common questions about installing AVAP™ Dev
        Studio 2024 on Windows.
      </li>
      <li>
        macOS setup - AVAP™ Dev Studio 2024 is available for both Intel and
        Apple silicon macOS machines.
      </li>
      <li>
        Linux setup - Learn about the different AVAP™ Dev Studio 2024 packages
        available for Linux.
      </li>
    </ul>
  </Fragment>
);
export default SETUP_Uninstall;
