import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../components/DisplayCodeKo/DisplayCodeKo';

const Logs_del_sistema_EN = () => {

  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
        "fecha": <string>,
        "level": <string>,
        "args": {},
        "body": {},
        "point_of_entry": <string>,
        "method": <string>,
        "msg_node": <string>,
        "node": <string>,
        "country_code": <string>,
        "channel_id": <string>,
        "channel_name": <string>,
        "operation": <string>,
        "action_name": <string>,
        "operation_details": {
            "transaction_status": <string>,
            "codtran": <string>,
            "satisfactory": <boolean>
        },
        "geolocation": {
            "latitude": <integer>,
            "longitude": <integer>
        },
        "exception": {
            "msg_exception": <string>,
            "line": <integer>,
            "error": <string>
        }
    }`,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
    url = "URL_BASE/ws/util.py/logs?tfile=nodo_mexico.log"
    payload = {}
    headers= {}
    response = requests.request("GET", url, headers=headers, data = payload)
    print(response.text.encode('utf8'))`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
    url = "URL_BASE/ws/util.py/logs?tfile=nodo_mexico.log"
    payload = {}
    headers= {}
    response = requests.request("GET", url, headers=headers, data = payload)
    print(response.text.encode('utf8'))`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
  var options = {
  'method': 'GET',
  'url': 'URL_BASE/ws/util.py/logs?time=1&            file=nodo_mexico.log',
  'headers': {}
  };
  request(options, function (error, response) { 
  if (error) throw new Error(error);
  console.log(response.body);
  });`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var requestOptions = {
  method: 'GET',
  redirect: 'follow'
  };
  fetch("URL_BASE/ws/util.py/logs?time=1&            
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request GET 'URL_BASE/ws/util.py/logs?time=1`
      }
    ]
  }


  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 >System Logs</h1>
          <p>
            This service is used to obtain the logs of the system during the indicated
            time.
          </p>
          <p>
            <span className="label get">GET:</span>
            <span> </span> <code>URL_BASE + /ws/util.py/logs</code>
          </p>
          <h2 >Receives:</h2>
          <p>
            All parameters are sent in the querystring of the call, so a percentage
            encoding for URI must be applied &#40;aka URL encoding&#41;.
          </p>
          <table>
            <thead>
              <tr>
                <th align="left">Parameter</th>
                <th align="center">Format</th>
                <th align="center">Obligatory</th>
                <th align="center">Description</th>
                <th align="center">Default value</th>
                <th align="center">Example</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="left">time</td>
                <td align="center">Integer</td>
                <td align="center">Yes</td>
                <td align="center">
                  Time in minutes that we want to obtain from the logs
                </td>
                <td align="center">N/A</td>
                <td align="center">1</td>
              </tr>
              <tr>
                <td align="left">file</td>
                <td align="center">String</td>
                <td align="center">Yes</td>
                <td align="center">Name of the log file we want to read</td>
                <td align="center">N/A</td>
                <td align="center">nodo_mexico.log</td>
              </tr>
            </tbody>
          </table>
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>
          <h3 >Answer JSON OK:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h2 >Where:</h2>
          <ul>
            <li>
              <code>fecha:</code> timestampt of the trace
            </li>
            <li>
              <code>level:</code> Trace security level.
            </li>
            <li>
              <code>args:</code> Request input arguments.
            </li>
            <li>
              <code>body:</code> Body of he request.
            </li>
            <li>
              <code>point_of_entry:</code> Service entry point.
            </li>
            <li>
              <code>method:</code> Name of the method in which the log trace is
              launched.
            </li>
            <li>
              <code>msg_node:</code> Log trace message.
            </li>
            <li>
              <code>node:</code> Name of the node where the trace occurs.
            </li>
            <li>
              <code>country_code:</code> Country code of the trace.
            </li>
            <li>
              <code>channel_id:</code> Channel identifier from which the request was
              received.
            </li>
            <li>
              <code>channel_name:</code> Name of the channel from which the request
              was received.
            </li>
            <li>
              <code>operation:</code> Request endpoint.
            </li>
            <li>
              <code>action_name:</code> Name of the executed action.
            </li>
            <li>
              <code>operation_details:</code> Service answer.
            </li>
            <li>
              <code>geolocation:</code> Location in latitude / longitude.
            </li>
            <li>
              <code>latitude:</code> Latitude where the user is.
            </li>
            <li>
              <code>longitude:</code> Longitude the user is in.
            </li>
            <li>
              <code>exception:</code> Details of the exception if it occurs.
            </li>
            <li>
              <code>msg_exception:</code> Exception message.
            </li>
            <li>
              <code>line:</code> Line of code in which the exception occurs.
            </li>
            <li>
              <code>error:</code> Trace of the thrown exception.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 >Business logic:</h2>
          <p>
            This service returns the log traces of the indicated time, obtaining them
            from the indicated log file.
          </p>

        </div>
        <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>

        </div>

      </div>


    </Fragment>
  );
}
export default Logs_del_sistema_EN;
