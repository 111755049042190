import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Servicio_de_revision_de_origenes_de_fondos_obtener_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
        "status": <boolean>, 
        "result": { 
          "count": <integer>, 
          "limit": <integer>, 
          "accounts": [ { 
            "is_certified": 
            <boolean>, 
            "certification_data": { 
              "codtran": <string> 
            }, 
            "blacklist": <boolean>, 
            "affiliate_id": <integer>, 
            "blocked": <boolean>, 
            "active": <boolean>, 
            "id": <integer>, 
            "affiliate_alias": <string>, 
            "state": <string>, 
            "pending": <boolean>, 
            "currency": <string>, 
            "prefix": <string>, 
            "phone": <string>, 
            "origin_of_funds": [ { <Origen_de_Fondos> } ], 
            "users": { 
              "date_of_birth": <string>, 
              "ocupation": <integer>, 
              "nick": <string>, 
              "other_data": {}, 
              "email": <string>, 
              "surname2": <string>, 
              "gender": <integer>, 
              "alias": <string>, 
              "surname1": <string>, 
              "profile_id": <integer>, 
              "profile_name": <string>, 
              "name": <string>, 
              "language_id": <integer>, 
              "nationality": <string>, 
              "state": <string>, 
              "id": <integer> } }, ], 
              "page": <integer>, 
              "total_pages": <integer> }, 
              "elapsed": <float> 
            }`,
        copied: false
      },
      answe_ko2: {
        title:"Answer ko",
        type: "json",
        code: `{ "status": <boolean>, 
        "level": <string>, 
        "message": <string>, 
        "error": <string> 
      }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: ` import requests url = "URL_BASE/ws/util.py/affiliates?country_code=MX&id_usuario=4532&id_sesion=413-JRdxDQvO-zPMcwLeptmOyA==" 
      payload = {} 
      files = {} 
      headers= {} 
      response = requests.request("GET", url, headers=headers, data = payload, files = files) 
      print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: ` import requests url = "URL_BASE/ws/admin.py/revisarorigendefondos?country_code=MX&user_id=4532&session_id=397-GuC6yCVxioNNrCTqpxgCgA==&command=listado" 
      payload = {} 
      files = {} 
      headers= {} 
      response = requests.request("GET", url, headers=headers, data = payload, files = files) 
      print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: ` var request = require('request');
    var options = {
    'method': 'GET',
    'url': 'URL_BASE/ws/admin.py/revisarorigendefondos?country_code=MX&user_id=4532&session_id=397-GuC6yCVxioNNrCTqpxgCgA==&command=listado',
    'headers': {},
    formData: {}
    };
    request(options, function (error, response) { 
    if (error) throw new Error(error);
    console.log(response.body);
    });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: ` var formdata = new FormData();
    var requestOptions = {
    method: 'GET',
    body: formdata,
    redirect: 'follow'
    };
    fetch("URL_BASE/ws/admin.py/revisarorigendefondos?country_code=MX&user_id=4532&session_id=397-GuC6yCVxioNNrCTqpxgCgA==&command=listado", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request GET 'URL_BASE/ws/admin.py/revisarorigendefondos?country_code=MX&user_id=4532&session_id=397-GuC6yCVxioNNrCTqpxgCgA%3D%3D&command=listado'`
        }
      ]
    }
    return(
      <Fragment>
         <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="obtain-source-of-funds-review">Obtain Source of Funds Review</h1>
        <p>
          This service is used to obtain the user data and approve their source of
          funds or reject it.{' '}
        </p>
        <p>
          <span className="label get">GET:</span>
          <span> </span> <code>URL_BASE + /ws/admin.py/revisarorigendefondos</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All parameters are sent in the querystring of the call, so a percentage
          encoding for URI must be applied &#40;aka URL encoding&#41;.
        </p>
        <div className='overflow-auto'>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
              <td align="center">ES &#40;Spain&#41;</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">Identifier of the user making the request</td>
              <td align="center">N/A</td>
              <td align="center">4532</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
              <td align="center">385-XadgYoWixbzN_wqWc3Yyog==</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
              <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
            </tr>
            <tr>
              <td align="left">command</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">It must have the listing value</td>
              <td align="center">N/A</td>
              <td align="center">List</td>
            </tr>
            <tr>
              <td align="left">page</td>
              <td align="center">Integer</td>
              <td align="center">No</td>
              <td align="center">Page number requested</td>
              <td align="center">1</td>
              <td align="center">1</td>
            </tr>
            <tr>
              <td align="left">limit</td>
              <td align="center">Integer</td>
              <td align="center">No</td>
              <td align="center">Number of results per page</td>
              <td align="center">10</td>
              <td align="center">10</td>
            </tr>
            <tr>
              <td align="left">text</td>
              <td align="center">String</td>
              <td align="center">No</td>
              <td align="center">
                It can containe name, surname, surname1, surname2, nick or phone
                number
              </td>
              <td align="center">N/A</td>
              <td align="center">7229063245</td>
            </tr>
          </tbody>
        </table>
        </div>
        <p>
          <br></br>
        </p>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <p>
          <br></br>
        </p>
        <h2 id="where">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>result:</code> Object containing the service answer.
          </li>
          <li>
            <code>count:</code> Number of records that come within the{' '}
            <code>accounts</code> object.
          </li>
          <li>
            <code>limit:</code> Maximum number of records that can come for each
            page.
          </li>
          <li>
            <code>accounts:</code> List of user accounts.
          </li>
          <li>
            <code>is_certified:</code> Indicates whether the user is certified or
            not.
          </li>
          <li>
            <code>certification_data:</code> An object that contains the transaction
            code associated with the user&#39;s certification.
          </li>
          <li>
            <code>blacklist:</code> Indicates if the user is on the platform
            blacklist.
          </li>
          <li>
            <code>affiliate_id:</code> User&#39;s affiliate identifier.
          </li>
          <li>
            <code>blocked:</code> Indicates whether the user&#39;s account is locked
            or not.
          </li>
          <li>
            <code>active:</code> Indicates whether the user&#39;s account is
            activated or not.
          </li>
          <li>
            <code>id:</code> User account identifier.
          </li>
          <li>
            <code>affiliate_alias:</code> User&#39;s affiliate name.
          </li>
          <li>
            <code>state:</code> Name of the state the user is in.
          </li>
          <li>
            <code>pending:</code> Indicates whether the user is pending activation
            or not.
          </li>
          <li>
            <code>currency:</code> Indicates the abbreviation of the currency used
            by the user.
          </li>
          <li>
            <code>prefix:</code> Indicates the prefix of the user&#39;s phone.
          </li>
          <li>
            <code>phone:</code> User&#39;s phone number.
          </li>
          <li>
            <code>origin_of_funds:</code> List with the origin of the user&#39;s
            funds. View structure on the endpoint
            <code>origin_of_funds</code>.
          </li>
          <li>
            <code>users:</code> Object that contains the user&#39;s personal data.
          </li>
          <li>
            <code>date_of_birth:</code> Birth date in format yyyy-mm-dd.
          </li>
          <li>
            <code>ocupation:</code> User occupation identifier.
          </li>
          <li>
            <code>nick:</code> User nickname
          </li>
          <li>
            <code>other_data:</code> Other user data.
          </li>
          <li>
            <code>email:</code> Email address.
          </li>
          <li>
            <code>surname2:</code> User&#39;s maternal last name.
          </li>
          <li>
            <code>gender:</code> User gender identifier.
          </li>
          <li>
            <code>alias:</code> User name.
          </li>
          <li>
            <code>surname1:</code> User&#39;s paternal last name.
          </li>
          <li>
            <code>profile_id:</code> User profile identifier.
          </li>
          <li>
            <code>profile_name:</code> Name associated with the profile that the
            user has.
          </li>
          <li>
            <code>name:</code> User name.
          </li>
          <li>
            <code>language_id:</code> User language identifier
          </li>
          <li>
            <code>nationality:</code> User nationality.
          </li>
          <li>
            <code>id:</code> User identifier.
          </li>
          <li>
            <code>page:</code> Current page number of results.
          </li>
          <li>
            <code>total_pages:</code> Number of pages available to request.
          </li>
          <li>
            <code>elapsed:</code> Operation execution.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko2}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>level:</code> Error importance level.
          </li>
          <li>
            <code>message:</code> Error message.
          </li>
          <li>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          In order to execute this service, it has to be done from a logged in user
          who has an administrator profile.
        </p>
        </div>

      <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        </div>
        </div>
      </Fragment>
    );
  }
export default Servicio_de_revision_de_origenes_de_fondos_obtener_EN;
