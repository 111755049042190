import React, { Fragment } from 'react';

const USER_GUIDE_Emmet = () => (
  <Fragment>
    <h1 id="emmet-in-avap™-dev-studio-2024">Emmet in AVAP™ Dev Studio 2024</h1>
    <p>
      Support for Emmet snippets and expansion is built right into AVAP
      <sup>TM</sup> Dev Studio, <strong>no extension required</strong>. Emmet
      2.0 has support for the majority of the Emmet Actions including expanding
      Emmet abbreviations and snippets.
    </p>
    <h2 id="how-to-expand-emmet-abbreviations-and-snippets">
      How to expand Emmet abbreviations and snippets
    </h2>
    <p>
      Emmet abbreviation and snippet expansions are enabled by default in{' '}
      <code>html</code>, <code>haml</code>, <code>pug</code>, <code>slim</code>,{' '}
      <code>jsx</code>, <code>xml</code>, <code>xsl</code>, <code>css</code>,{' '}
      <code>scss</code>, <code>sass</code>, <code>less</code> and{' '}
      <code>stylus</code> files, as well as any language that inherits from any
      of the above like <code>handlebars</code> and <code>php</code>.
    </p>
    <p>
      When you start typing an Emmet abbreviation, you will see the abbreviation
      displayed in the suggestion list. If you have the suggestion documentation
      fly-out open, you will see a preview of the expansion as you type. If you
      are in a stylesheet file, the expanded abbreviation shows up in the
      suggestion list sorted among the other CSS suggestions.
    </p>
    <h3 id="using-tab-for-emmet-expansions">Using Tab for Emmet expansions</h3>
    <p>
      If you want to use the Tab key for expanding the Emmet abbreviations, add
      the following setting:
    </p>

    <pre className="">
      <code className="">"emmet.triggerExpansionOnTab": true</code>
    </pre>
    <p>
      This setting allows using the Tab key for indentation when text is not an
      Emmet abbreviation.
    </p>
    <h3 id="emmet-when-quicksuggestions-are-disabled">
      Emmet when quickSuggestions are disabled
    </h3>
    <p>
      If you have disabled the <code>editor.quickSuggestions setting</code>, you
      won&#39;t see suggestions as you type. You can still trigger suggestions
      manually by pressing <code>Ctrl+Space</code> and see the preview.
    </p>
    <h3 id="disable-emmet-in-suggestions">Disable Emmet in suggestions</h3>
    <p>
      If you don&#39;t want to see Emmet abbreviations in suggestions at all,
      then use the following setting:
    </p>

    <pre className="">
      <code className="">"emmet.showExpandedAbbreviation": "never"</code>
    </pre>
    <p>
      You can still use the command <strong>Emmet: Expand Abbreviation</strong>{' '}
      to expand your abbreviations. You can also bind any keyboard shortcut to
      the command id <code>editor.emmet.action.expandAbbreviation</code> as
      well.
    </p>
    <h3 id="emmet-suggestion-ordering">Emmet suggestion ordering</h3>
    <p>
      To ensure Emmet suggestions are always on top in the suggestion list, add
      the following settings:
    </p>

    <pre className="">
      <code className="">
        "emmet.showSuggestionsAsSnippets": true,
        <br />
        "editor.snippetSuggestions": "top"
      </code>
    </pre>
    <h2 id="emmet-abbreviations-in-other-file-types">
      Emmet abbreviations in other file types
    </h2>
    <p>
      To enable the Emmet abbreviation expansion in file types where it is not
      available by default, use the <code>emmet.includeLanguages setting</code>.
      Make sure to use language identifiers for both sides of the mapping, with
      the right side being the language identifier of an Emmet supported
      language &#40;see the list above&#41;.
    </p>
    <p>For example:</p>

    <pre className="">
      <code className="">
        "emmet.includeLanguages": &#123;
        <br />` `"javascript": "javascriptreact",
        <br />` `"razor": "html",
        <br />` `"plaintext": "pug"
        <br />
        &#125;
      </code>
    </pre>
    <p>
      Emmet has no knowledge of these new languages, and so there might be Emmet
      suggestions showing up in non HTML/CSS contexts. To avoid this, you can
      use the following setting.
    </p>

    <pre className="">
      <code className="">
        "emmet.showExpandedAbbreviation": "inMarkupAndStylesheetFilesOnly"
      </code>
    </pre>
    <blockquote>
      <p>
        <em>Note:*</em> If you used <code>emmet.syntaxProfiles</code> previously
        to map new file types, from AVAP<sup>TM</sup> Dev Studio 1.15 onwards
        you should use the setting <code>emmet.includeLanguages instead</code>.{' '}
        <code>emmet.syntaxProfiles</code> is meant for customizing the final
        output only.
      </p>
    </blockquote>
    <h2 id="emmet-with-multi-cursors">Emmet with multi-cursors</h2>
    <p>You can use most of the Emmet actions with multi-cursors as well:</p>
    <h2 id="using-filters">Using filters</h2>
    <p>
      Filters are special post-processors that modify the expanded abbreviation
      before it is output to the editor. There are 2 ways to use filters; either
      globally through the <code>emmet.syntaxProfiles</code> setting or directly
      in the current abbreviation.
    </p>
    <p>
      Below is an example of the first approach using the{' '}
      <code>emmet.syntaxProfiles</code> setting to apply the <code>bem</code>{' '}
      filter for all the abbreviations in HTML files:
    </p>

    <pre className="">
      <code className="">
        "emmet.syntaxProfiles": &#123;
        <br />` `"html": &#123;
        <br />` `"filters": "bem"
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>
      To provide a filter for just the current abbreviation, append the filter
      to your abbreviation. For example, div#page|c will apply the comment
      filter to the div#page abbreviation.
    </p>
    <h3 id="bem-filter-bem">BEM filter &#40;bem&#41;</h3>
    <p>
      If you use the Block Element Modifier &#40;BEM&#41; way of writing HTML,
      then <code>bem</code> filters are very handy for you to use. To learn more
      about how to use <code>bem</code> filters, read BEM filter in Emmet.
    </p>
    <p>
      You can customize this filter by using the{' '}
      <code>bem.elementSeparator</code> and <code>bem.modifierSeparator</code>{' '}
      preferences as documented in Emmet Preferences.
    </p>
    <h3 id="comment-filter-c">Comment filter &#40;c&#41;</h3>
    <p>
      This filter adds comments around important tags. By default,
      &quot;important tags&quot; are those tags with id and/or class attribute.
    </p>
    <p>
      For example <code>div&gt;div#page&gt;p.title+p|c</code> will be expanded
      to:
    </p>

    <pre className="">
      <code className="">
        &lt;div&gt;
        <br />` `&lt;div id="page"&gt;
        <br />` `&lt;p className="title"&gt;&lt;/p&gt;
        <br />` `&lt;!-- /.title --&gt;
        <br />` `&lt;p&gt;&lt;/p&gt;
        <br />` `&lt;/div&gt;
        <br />` `&lt;!-- /#page --&gt;
        <br />
        &lt;/div&gt;
      </code>
    </pre>
    <p>
      You can customize this filter by using the{' '}
      <code>filter.commentTrigger</code>, <code>filter.commentAfter</code>and{' '}
      <code>filter.commentBefore</code> preferences as documented in Emmet
      Preferences.
    </p>
    <p>
      The format for the <code>filter.commentAfter</code> preference is
      different in AVAP<sup>TM</sup> Dev Studio Emmet 2.0.
    </p>
    <p>For example, instead of:</p>

    <pre className="">
      <code className="">
        "emmet.preferences": &#123;
        <br />` `"filter.commentAfter": "\n&lt;!-- /&lt;%= attr&#40;'id',
        '#'&#41; %&gt;&lt;%= attr&#40;'class', '.'&#41; %&gt; --&gt;"
        <br />
        &#125;
      </code>
    </pre>
    <p>
      in AVAP<sup>TM</sup> Dev Studio, you would use a simpler:
    </p>

    <pre className="">
      <code className="">
        "emmet.preferences": &#123;
        <br />` `"filter.commentAfter": "\n&lt;!-- /[#ID][.CLASS] --&gt;"
        <br />
        &#125;
      </code>
    </pre>
    <h3 id="trim-filter-t">Trim filter &#40;t&#41;</h3>
    <p>
      This filter is applicable only when providing abbreviations for the{' '}
      <strong>Emmet: Wrap with Abbreviation</strong> command. It removes line
      markers from wrapped lines.
    </p>
    <h2 id="using-custom-emmet-snippets">Using custom Emmet snippets</h2>
    <p>
      Custom Emmet snippets need to be defined in a json file named{' '}
      <code>snippets.json</code>. The <code>emmet.extensionsPath</code> setting
      should have the path to the directory containing this file.
    </p>
    <p>
      Below is an example for the contents of this <code>snippets.json</code>{' '}
      file.
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"html": &#123;
        <br />` `"snippets": &#123;
        <br />` `"ull": "ul&gt;li[id=$&#123;1&#125; class=$&#123;2&#125;]\*2&#123;
        Will work with html, pug, haml and slim &#125;",
        <br />` `"oll": "&lt;ol&gt;&lt;li id=$&#123;1&#125; class=$&#123;2&#125;&gt;
        Will only work in html &lt;/ol&gt;",
        <br />` `"ran": "&#123; Wrap plain text in curly braces &#125;"
        <br />` `&#125;
        <br />` `&#125;,
        <br />` `"css": &#123;
        <br />` `"snippets": &#123;
        <br />` `"cb": "color: black",
        <br />` `"bsd": "border: 1px solid $&#123;1:red&#125;",
        <br />` `"ls": "list-style: $&#123;1&#125;"
        <br />` `&#125;
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>
      Authoring of Custom Snippets in Emmet 2.0 via the{' '}
      <code>snippets.json</code> file differs from the old way of doing the same
      in a few ways:
    </p>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Topic</strong>
          </th>
          <th align="center">
            <strong>Old Emmet</strong>
          </th>
          <th align="center">
            <strong>Emmet 2.0</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">Snippets vs Abbreviations</td>
          <td align="center">
            Supports both in 2 separate properties called snippets and
            abbreviations
          </td>
          <td align="center">
            The 2 have been combined into a single property called snippets. See
            default HTML snippets and CSS snippets
          </td>
        </tr>
        <tr>
          <td align="center">CSS snippet names</td>
          <td align="center">Can contain :</td>
          <td align="center">
            Do not use : when defining snippet names. It is used to separate
            property name and value when Emmet tries to fuzzy match the given
            abbreviation to one of the snippets.
          </td>
        </tr>
        <tr>
          <td align="center">CSS snippet values</td>
          <td align="center">Can end with ;</td>
          <td align="center">
            Do not add ; at end of snippet value. Emmet will add the trailing ;
            based on the file type &#40;css/less/scss vs sass/stylus&#41; or the
            emmet preference set for css.propertyEnd, sass.propertyEnd,
            stylus.propertyEnd
          </td>
        </tr>
        <tr>
          <td align="center">Cursor location</td>
          <td align="center">$&#123;cursor&#125; or `</td>
          <td align="center">` can be used</td>
        </tr>
      </tbody>
    </table>
    <h3 id="html-emmet-snippets">HTML Emmet snippets</h3>
    <p>
      HTML custom snippets are applicable to all other markup flavors like{' '}
      <code>haml</code> or <code>pug</code>. When snippet value is an
      abbreviation and not actual HTML, the appropriate transformations can be
      applied to get the right output as per the language type.
    </p>
    <p>
      For example, for an unordered list with a list item, if your snippet value
      is ul&gt;li, you can use the same snippet in <code>html</code>,{' '}
      <code>haml</code>, <code>pug</code> or <code>slim</code>, but if your
      snippet value is <code>&lt;ul&gt;&lt;li&gt;&lt;/li&gt;&lt;/ul&gt;</code>,
      then it will work only in <code>html</code> files.
    </p>
    <p>
      If you want a snippet for plain text, then surround the text with
      &#123;&#125;.
    </p>
    <h3 id="css-emmet-snippets">CSS Emmet snippets</h3>
    <p>
      Values for CSS Emmet snippets should be a complete property name and value
      pair.
    </p>
    <p>
      CSS custom snippets are applicable to all other stylesheet flavors like{' '}
      <code>scss</code>, <code>less</code> or <code>sass</code>. Therefore,
      don&#39;t include a trailing ; at the end of the snippet value. Emmet will
      add it as needed based on whether the language requires it.
    </p>
    <p>
      Do not use : in the snippet name. : is used to separate property name and
      value when Emmet tries to fuzzy match the abbreviation to one of the
      snippets.
    </p>
    <h3 id="tab-stops-and-cursors-in-custom-snippets">
      Tab stops and cursors in custom snippets
    </h3>
    <p>
      The syntax for tab stops in custom Emmet snippets follows the Textmate
      snippets syntax.
    </p>
    <ul>
      <li>
        Use <code>$&#123;1&#125;</code>, <code>$&#123;2&#125;</code> for tab
        stops and <code>$&#123;1:placeholder&#125;</code> for tab stops with
        placeholders.
      </li>
      <li>
        Previously, | or $&#123;cursor&#125; was used to denote the cursor
        location in the custom Emmet snippet. This is no longer supported. Use{' '}
        <code>$&#123;1&#125;</code> instead.
      </li>
    </ul>
    <h2 id="emmet-configuration">Emmet configuration</h2>
    <p>
      Below are Emmet settings that you can use to customize your Emmet
      experience in AVAP<sup>TM</sup> Dev Studio.
    </p>
    <ul>
      <li>
        <p>
          <code>emmet.includeLanguages</code>
        </p>
        <p>
          Use this setting to add mapping between the language of your choice
          and one of the Emmet supported languages to enable Emmet in the former
          using the syntax of the latter. Make sure to use language IDs for both
          sides of the mapping.
        </p>
        <p>For example:</p>

        <pre className="">
          <code className="">
            "emmet.includeLanguages": &#123;
            <br />` `"javascript": "javascriptreact",
            <br />` `"plaintext": "pug"
            <br />
            &#125;
          </code>
        </pre>
      </li>
      <li>
        <p>
          <code>emmet.excludeLanguages</code>
        </p>
        <p>
          If there is a language where you do not want to see Emmet expansions,
          add it in this setting which takes an array of language ID strings.
        </p>
      </li>
      <li>
        <p>
          <code>emmet.syntaxProfiles</code>
        </p>
        <p>
          See Emmet Customization of output profile to learn how you can
          customize the output of your HTML abbreviations.
        </p>
        <p>For example:</p>

        <pre className="">
          <code className="">
            "emmet.syntaxProfiles": &#123;
            <br />` `"html": &#123;
            <br />` `"attr\_quotes": "single"
            <br />` `&#125;,
            <br />` `"jsx": &#123;
            <br />` `"self\_closing\_tag": true
            <br />` `&#125;
            <br />
            &#125;
          </code>
        </pre>
      </li>
      <li>
        <p>
          <code>emmet.variables</code>
        </p>
        <p>Customize variables used by Emmet snippets.</p>
        <p>For example:</p>
        <p> &quot;emmet.variables&quot;: &#123;</p>

        <pre className="">
          <code className="">
            ` `"lang": "de",
            <br />` `"charset": "UTF-16"
            <br />
            &#125;
          </code>
        </pre>
      </li>
      <li>
        <p>
          <code>emmet.showExpandedAbbreviation</code>
        </p>
        <p>
          Controls the Emmet suggestions that show up in the
          suggestion/completion list.
        </p>
      </li>
    </ul>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Setting Value</strong>
          </th>
          <th align="center">
            <strong>Description</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">
            <code>never</code>
          </td>
          <td align="center">
            Never show Emmet abbreviations in the suggestion list for any
            language.
          </td>
        </tr>
        <tr>
          <td align="center">
            <code>inMarkupAndStylesheetFilesOnly</code>
          </td>
          <td align="center">
            Show Emmet suggestions only for languages that are purely markup and
            stylesheet based &#40;&#39;html&#39;, &#39;pug&#39;, &#39;slim&#39;,
            &#39;haml&#39;, &#39;xml&#39;, &#39;xsl&#39;, &#39;css&#39;,
            &#39;scss&#39;, &#39;sass&#39;, &#39;less&#39;,
            &#39;stylus&#39;&#41;.
          </td>
        </tr>
        <tr>
          <td align="center">
            <code>always</code>
          </td>
          <td align="center">
            Show Emmet suggestions in all Emmet supported modes as well as the
            languages that have a mapping in the emmet.includeLanguages setting.
          </td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p>
        <em>Note:*</em> In the <code>always</code> mode, the new Emmet
        implementation is not context aware. For example, if you are editing a
        JavaScript React file, you will get Emmet suggestions not only when
        writing markup but also while writing JavaScript.
      </p>
    </blockquote>
    <ul>
      <li>
        <p>
          <code>emmet.showAbbreviationSuggestions</code>
        </p>
        <p>
          Shows possible emmet abbreviations as suggestions. It is{' '}
          <code>true</code> by default.
        </p>
        <p>
          For example, when you type <code>li</code>, you get suggestions for
          all emmet snippets starting with <code>li</code> like{' '}
          <code>link</code>, <code>link:css</code> , <code>link:favicon</code>{' '}
          etc. This is helpful in learning Emmet snippets that you never knew
          existed unless you knew the Emmet cheatsheet by heart.
        </p>
        <p>
          Not applicable in stylesheets or when{' '}
          <code>emmet.showExpandedAbbreviation</code> is set to never.
        </p>
      </li>
      <li>
        <p>
          <code>emmet.extensionsPath</code>
        </p>
        <p>
          Provide the location of the directory that houses the{' '}
          <code>snippets.json</code> file which in turn has your custom
          snippets.
        </p>
      </li>
      <li>
        <p>
          <code>emmet.triggerExpansionOnTab</code>
        </p>
        <p>
          Set this to true to enable expanding Emmet abbreviations with Tab key.
          We use this setting to provide the appropriate fallback to provide
          indentation when there is no abbreviation to expand.
        </p>
      </li>
      <li>
        <p>
          <code>emmet.showSuggestionsAsSnippets</code>
        </p>
        <p>
          If set to true, then Emmet suggestions will be grouped along with
          other snippets allowing you to order them as per{' '}
          <code>editor.snippetSuggestions</code> setting. Set this to true and{' '}
          <code>editor.snippetSuggestions</code> to <code>top</code>, to ensure
          that Emmet suggestions always show up on top among other suggestions.
        </p>
      </li>
      <li>
        <p>
          <code>emmet.preferences</code>
        </p>
        <p>
          You can use this setting to customize Emmet as documented in Emmet
          Preferences. The below customizations are currently supported:
        </p>
        <ul>
          <li>
            <code>css.propertyEnd</code>
          </li>
          <li>
            <code>css.valueSeparator</code>
          </li>
          <li>
            <code>sass.propertyEnd</code>
          </li>
          <li>
            <code>sass.valueSeparator</code>
          </li>
          <li>
            <code>stylus.propertyEnd</code>
          </li>
          <li>
            <code>stylus.valueSeparator</code>
          </li>
          <li>
            <code>css.unitAliases</code>
          </li>
          <li>
            <code>css.intUnit</code>
          </li>
          <li>
            <code>css.floatUnit</code>
          </li>
          <li>
            <code>bem.elementSeparator</code>
          </li>
          <li>
            <code>bem.modifierSeparator</code>
          </li>
          <li>
            <code>filter.commentBefore</code>
          </li>
          <li>
            <code>filter.commentTrigger</code>
          </li>
          <li>
            <code>filter.commentAfter</code>
          </li>
          <li>
            <code>format.noIndentTags</code>
          </li>
          <li>
            <code>format.forceIndentationForTags</code>
          </li>
          <li>
            <code>profile.allowCompactBoolean</code>
          </li>
          <li>
            <code>css.fuzzySearchMinScore</code>
          </li>
        </ul>
      </li>
    </ul>
    <p>
      The format for the <code>filter.commentAfter</code> preference is
      different and simpler in Emmet 2.0.
    </p>
    <p>For example, instead of the older format</p>

    <pre className="">
      <code className="">
        "emmet.preferences": &#123;
        <br />` `"filter.commentAfter": "\n&lt;!-- /&lt;%= attr&#40;'id',
        '#'&#41; %&gt;&lt;%= attr&#40;'class', '.'&#41; %&gt; --&gt;"
        <br />
        &#125;
      </code>
    </pre>
    <p>you would use</p>

    <pre className="">
      <code className="">
        "emmet.preferences": &#123;
        <br />` `"filter.commentAfter": "\n&lt;!-- /[#ID][.CLASS] --&gt;"
        <br />
        &#125;
      </code>
    </pre>
    <p>
      If you want support for any of the other preferences as documented in
      Emmet Preferences, please log a feature request.
    </p>
    <h2 id="next-steps">Next steps</h2>
    <p>
      Emmet is just one of the great web developer features in AVAP<sup>TM</sup>{' '}
      Dev Studio. Read on to find out about:
    </p>
    <ul>
      <li>
        HTML - AVAP<sup>TM</sup> Dev Studio supports HTML with IntelliSense,
        closing tags, and formatting.
      </li>
      <li>CSS - We offer rich support for CSS, SCSS and Less.</li>
    </ul>
    <h2 id="troubleshooting">Troubleshooting</h2>
    <h3 id="custom-tags-do-not-get-expanded-in-the-suggestion-list">
      Custom tags do not get expanded in the suggestion list
    </h3>
    <p>
      Custom tags when used in an expression like <code>MyTag&gt;YourTag</code>{' '}
      or <code>MyTag.someclass</code> do show up in the suggestion list. But
      when these are used on their own like <code>MyTag</code>, they do not
      appear in the suggestion list. This is designed so to avoid noise in the
      suggestion list as every word is a potential custom tag.
    </p>
    <p>
      Add the following setting to enable expanding of Emmet abbreviations using
      tab which will expand custom tags in all cases.
    </p>

    <pre className="">
      <code className="">"emmet.triggerExpansionOnTab": true</code>
    </pre>
    <h3 id="my-html-snippets-ending-with--do-not-work">
      My HTML snippets ending with + do not work
    </h3>
    <p>
      HTML snippets ending with <code>+</code> like <code>select+</code> and{' '}
      <code>ul+</code> from the Emmet cheatsheet are not supported. This is a
      known issue in Emmet 2.0 Issue: emmetio/html-matcher#1. A workaround is to
      create your own custom Emmet snippets for such scenarios.
    </p>
    <h3 id="abbreviations-are-failing-to-expand">
      Abbreviations are failing to expand
    </h3>
    <p>
      First, check if you&#39;re using custom snippets &#40;if there is a{' '}
      <code>snippets.json</code> file being picked up by the{' '}
      <code>emmet.extensionsPath</code>setting&#41;. The format of custom
      snippets changed in AVAP<sup>TM</sup> Dev Studio release 1.53. Instead of
      using | to indicate where the cursor position is, use tokens such as{' '}
      <code>$&#123;1&#125;</code>, <code>$&#123;2&#125;</code>, etc. instead.
      The default CSS snippets file from the <code>emmetio/emmet</code>{' '}
      repository shows examples of the new cursor position format.
    </p>
    <p>If abbreviations are still failing to expand:</p>
    <ul>
      <li>Check the builtin extensions to see if Emmet has been disabled.</li>
      <li>
        Try restarting the extension host by running the{' '}
        <strong>Developer: Restart Extension Host</strong> &#40;
        <code>workbench.action.restartExtensionHost</code>&#41; command in the
        Command Palette.
      </li>
    </ul>
    <h3 id="where-can-i-set-all-the-preferences-as-documented-in-emmet-preferences">
      Where can I set all the preferences as documented in Emmet preferences?
    </h3>
    <p>
      You can set the preferences using the setting{' '}
      <code>emmet.preferences</code>. Only a subset of the preferences that are
      documented in Emmet preferences can be customized. Please read the
      preferences section under Emmet configuration.
    </p>
    <h3 id="any-tips-and-tricks">Any tips and tricks?</h3>
    <p>Of course!</p>
    <ul>
      <li>
        In CSS abbreviations, when you use :, the left part is used to fuzzy
        match with the CSS property name and the right part is used to match
        with CSS property value. Take full advantage of this by using
        abbreviations like <code>pos:f</code>, <code>trf:rx</code>,{' '}
        <code>fw:b</code>, etc.
      </li>
      <li>Explore all other Emmet features as documented in Emmet Actions.</li>
      <li>
        Don&#39;t hesitate to create your own custom Emmet snippets.rkdown
        Viewer and Editor
      </li>
    </ul>
  </Fragment>
);
export default USER_GUIDE_Emmet;
