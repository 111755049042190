import React, { FunctionComponent, useState, useEffect } from 'react'; 
import { Row, Col, Card } from 'react-bootstrap';
import { parseToCoin, firstMonthPrice } from '../../services/AmountService';

type ProductResumeProps = {
    title: string,
    priceMonthly: number,
    priceYear: number,
    prorated: boolean,
    annualPayment: boolean,
    letMonths: number,
    ProratedPrice : number,
    isBlue?: boolean,
    spanish: boolean

}

const ProductResumeCard:FunctionComponent<ProductResumeProps> = (props:ProductResumeProps) => {

  const { title, priceMonthly, priceYear, prorated, annualPayment, letMonths, ProratedPrice, spanish } = props;

  const [ priceProduct, setPriceProduct ] = useState(0);
  const [ proratedPrice, setProratedPrice ] = useState(0);
  const  [onetime, setOnetime] = useState(false);

  useEffect(() => {
    if (priceMonthly==0) {setOnetime(true);setPriceProduct(priceYear)} else {
    const showPrice = annualPayment ? ((priceYear * letMonths)) : priceMonthly;
    setPriceProduct(showPrice)
    if (prorated) {
      const p = annualPayment ? (firstMonthPrice(priceYear) + (priceYear * (letMonths-1))) : firstMonthPrice(priceMonthly);
      setProratedPrice(p);
    }    else setProratedPrice(showPrice)
  }
  }, [annualPayment])

  return(
    <Card className="product-detail-card mb-3">
      <Card.Body className="p-3">
        <Card.Title>
          <Row>
            <Col md="6"><span className={`title-product titlesPaymentConfirmation ${props.isBlue?'blue':'green'}`}>{title}</span></Col>
            <Col md="6" className="text-right">
              {onetime ?
              <span><span className="product-price">{parseToCoin(priceProduct)}</span>{spanish ? '/una vez':'/one time'}</span>
              : <span><span className="product-price">{annualPayment ? parseToCoin(priceMonthly*(letMonths)) : parseToCoin(priceMonthly)}</span>{annualPayment ? (spanish ? '/año':'/year') : (spanish ? '/mes':'/month')}</span>
              }
            </Col>
          </Row>
        </Card.Title>
        <div className="border-top my-2"></div>
        {onetime ?
        <Card.Text className="text-right">
          {spanish ? "Un pago hoy" :"Today's one time payment."}
        </Card.Text>
        : <Card.Text className="text-left">
        {spanish ? 'Vas a pagar el total, mas comisiones, el primer dia de cada mes. ':'You will pay full price, plus fees, on the first day of every month.'}
        {spanish ? 'Para tu primera compra, vas a pagar ':'For your first purchase, you will pay a' } {prorated ? (spanish ? '':'prorated'): ''} {!spanish ? 'price of': (prorated ? 'un precio prorrateado de ':'un precio de ')} <span className="product-price">{ProratedPrice != null ? parseToCoin(ProratedPrice) : parseToCoin(proratedPrice)}</span >.
      </Card.Text>
      }
      </Card.Body>
    </Card>
  );
}

export default ProductResumeCard;