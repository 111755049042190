import React, { Fragment } from 'react';

const OVERVIEW = () => (
  <Fragment>
    <h1 id="getting-started">Getting Started</h1>
    <p>
      AVAP<sup>TM</sup> Dev Studio 2024 is a lightweight but powerful source
      code editor which runs on your desktop and is available for Windows, macOS
      and Linux. It comes with built-in support for JavaScript, TypeScript and
      Node.js and has a rich ecosystem of extensions for other languages and
      runtimes &#40;such as C++, C#, Java, Python, PHP, Go, .NET&#41;. Begin
      your journey with VS Code with these introductory videos.
    </p>
    <h3 id="avap-tm-code-in-action">AVAP TM Code in Action</h3>
    <blockquote>
      <h4 id="slide-de-imágenes">Slide de Imágenes</h4>
    </blockquote>
    <h2 id="top-extensions">Top Extensions</h2>
    <p>
      Enable additional languages, themes, debuggers, commands, and more. AVAP
      <sup>TM</sup> Dev Studio&#39;s growing community shares their secret sauce
      to improve your workflow.
    </p>
    <h2 id="first-steps">First Steps</h2>
    <p>
      To get the most out of AVAP<sup>TM</sup> Dev Studio, start by reviewing a
      few introductory topics:
    </p>
    <p>
      Intro Videos - Begin your journey with AVAP Code through these
      introductory videos. Setup - Install AVAP<sup>TM</sup> Dev Studio for your
      platform and configure the tool set for your development needs. User
      Interface - Introduction to the basic UI, commands, and features of the
      AVAP<sup>TM</sup> Dev Studio editor. Settings - Customize AVAP
      <sup>TM</sup> Dev Studio for how you like to work. Languages - Learn about
      AVAP<sup>TM</sup> Dev Studio&#39;s support for your favorite programming
      languages. Node.js - This tutorial gets you quickly running and debugging
      a Node.js web app. Tips and Tricks - Jump right in with Tips and Tricks to
      become a AVAP<sup>TM</sup> Dev Studio power user. Azure - AVAP
      <sup>TM</sup> Dev Studio is great for deploying your web applications to
      the cloud. Extension API - Learn how to write a AVAP<sup>TM</sup> Dev
      Studio extension. Why AVAP<sup>TM</sup> Dev Studio? - Read about the
      design philosophy and architecture of AVAP<sup>TM</sup> Dev Studio.
    </p>
    <h2 id="keyboard-shortcuts">Keyboard Shortcuts</h2>
    <p>
      Increase your productivity with AVAP<sup>TM</sup> Dev Studio&#39;s
      keyboard shortcuts.
    </p>
    <p>
      Keyboard Shortcut Reference Sheet - Learn the commonly used keyboard
      shortcuts. Keymap Extensions - Change AVAP<sup>TM</sup> Dev Studio&#39;s
      keyboard shortcuts to match another editor. Customize Keyboard Shortcuts -
      Modify the default keyboard shortcuts.
    </p>
    <h2 id="downloads">Downloads</h2>
    <p>
      Download AVAP<sup>TM</sup> Dev Studio - Quickly find the appropriate
      install for your platform &#40;Windows, macOS and Linux&#41;
    </p>
    <h2 id="privacy">Privacy</h2>
    <p>
      By default, AVAP<sup>TM</sup> Dev Studio auto-updates to new versions, and
      collects usage data and crash report information. You may opt out of these
      defaults by disabling them as instructed below:
    </p>
    <p>
      How do I disable auto update? How do I disable crash reporting? How do I
      disable usage reporting?
    </p>
  </Fragment>
);
export default OVERVIEW;