import React, { Fragment } from 'react';

const Plan_Wallets = () => (
  <Fragment>
    <h1 >Plan Wallets</h1>
    <p>
      Plan Wallets All the existing plans in 101OBeX have a wallet, that will be
      used as a rounded account to discount each of the transactional services
      that are operated.
    </p>
    <p>
      IT IS IMPORTANT TO CLARIFY THAT THE WALLET THAT MAINTAINS THE BALANCES OF
      THE NODES OR PROJECTS DOES NOT CHARGE THE MONTHLY SUBSCRIPTION, NOR THE
      ADD-ONS, NOR THE PLUGINS THAT MAY BE ACTIVE. THE PURPOSE OF THE WALLET OF
      EACH SUBSCRIPTION IS TO PREFUND THE BALANCE WITH WHICH THE TRANSACTIONAL
      SERVICES OF EACH OF THE PROJECTS WILL BE SERVED.
    </p>
    <p>
      THE SUBSCRIPTION WALLET IS NOT A BANK ACCOUNT AND THEREFORE THE PRE-FUNDED
      MONEY CANNOT BE WITHDRAWN AND MUST BE SPENT/CONSUMED IN 101OBeX SERVICES.
    </p>
    <p>
      Adding funds to the wallet is a straightforward process. Access the
      Dashboard, then click the + icon within the Balance section. This action
      will initiate the procedure for adding funds.
    </p>
  </Fragment>
);
export default Plan_Wallets;
