import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Servicio_de_revision_de_origenes_de_fondos_rechazar_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{
           "status": <boolean>, 
            "codtran": "174e275960c20a9ef65a0c46db54121d", 
            "result": { "origin_id": 113, "command": "rechazar" }, 
            "elapsed": <float> 
          }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": <boolean>, 
        "level": <string>, 
        "message": <string>,
         "error": <string> 
        }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/admin.py/revisarorigendefondos"
         payload = {'country_code': 'MX', 'user_id': '4532', 'session_id': '439-InjyJc39dwPumPisHS5xgw==', 'command': 'rechazar', 'origin_id': '113'} 
         files = [] 
         headers= {} 
         response = requests.request("POST", url, headers=headers, data = payload, files = files) 
         print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: ` import requests url = "URL_BASE/ws/admin.py/revisarorigendefondos"
          payload = {'country_code': 'MX', 'user_id': '4532', 'session_id': '439-InjyJc39dwPumPisHS5xgw==', 'command': 'rechazar', 'origin_id': '113'} 
          files = [] 
          headers= {} 
          response = requests.request("POST", url, headers=headers, data = payload, files = files) 
          print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'POST',
              'url': 'URL_BASE/ws/admin.py/revisarorigendefondos',
              'headers': {},
              formData: {
              'country_code': 'MX',
              'user_id': '4532',
              'session_id': '439-InjyJc39dwPumPisHS5xgw==',
              'command': 'rechazar',
              'origin_id': '113'
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var formdata = new FormData();
              formdata.append("country_code", "MX");
              formdata.append("user_id", "4532");
              formdata.append("session_id", "439-InjyJc39dwPumPisHS5xgw==");
              formdata.append("command", "rechazar");
              formdata.append("origin_id", "113");
              var requestOptions = {
              method: 'POST',
              body: formdata,
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/admin.py/revisarorigendefondos", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request POST 'URL_BASE/ws/admin.py/revisarorigendefondos' \ --form 'country_code=MX' \ --form 'user_id=4532' \ --form 'session_id=439-InjyJc39dwPumPisHS5xgw==' \ --form 'command=rechazar' \ --form 'origin_id=113'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="fund-source-review-service-reject">
          Fund Source Review Service &#40;Reject&#41;
        </h1>
        <p>This service is used to reject a source of funds. </p>
        <p>
          <span className="label post">POST:</span>
          <span> </span> <code>URL_BASE + /ws/admin.py/revisarorigendefondos</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <div className='overflow-auto'>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
              <td align="center">ES &#40;Spain&#41;</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">Identifier of the user making the requestN/A</td>
              <td align="center">4532</td>
              <td align="center"></td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
              <td align="center">385-XadgYoWixbzN_wqWc3Yyog==</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in session
              </td>
              <td align="center">N/A</td>
              <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
            </tr>
            <tr>
              <td align="left">command</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">It must contain the value rejected</td>
              <td align="center">N/A</td>
              <td align="center">reject</td>
            </tr>
            <tr>
              <td align="left">origin_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">Identifier of the source of funds to reject</td>
              <td align="center">N/A</td>
              <td align="center">113</td>
            </tr>
          </tbody>
        </table>
        </div>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSONs
        </p>
        <h3 id="answers-json-ok">Answers JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>codtran:</code> Transaction code that identifies the executed
            operation.{' '}
          </li>
          <li>
            <code>result:</code> Object containing the service answer.
          </li>
          <li>
            <code>origin_id:</code> Identifier of the source of funds to reject.
          </li>
          <li>
            <code>command:</code> Command executed in the service.
          </li>
          <li>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>level:</code> Error importance level.
          </li>
          <li>
            <code>message:</code> Error message.
          </li>
          <li>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic</h2>
        <p>
          In order to run this service, it is necessary to do it from a user logged
          into the system who has an administrator profile. When a source of funds
          is rejected, an email is sent to the user with the result of the review of
          their source of funds, and this source of funds is logically deleted.
        </p>
        </div>

      <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
        </div>
      </Fragment>
    );
  }
export default Servicio_de_revision_de_origenes_de_fondos_rechazar_EN;
