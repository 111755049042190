import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Saldo_de_la_cuenta_EN = () => 
 {
  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{
         "status": <boolean>,
          "currency": <string>,
           "amount": <string>,
            "elapsed": <float>
           }`,
      copied: false
    },
    answe_ko1: {
      title:"Answer ok",
      type: "json",
      code: `{ 
        "status": false,
         "level": <string>,
          "message": <string>, 
          "error": <string>
         }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: `import requests url = "URL_BASE/ws/cuentas.py/saldo?country_code=MX&user_id=78&session_id=388-481WXSHIEDX7ba5MWcFXCA==" 
      payload = {} 
      headers= {}
       response = requests.request("GET", url, headers=headers, data = payload)
        print(response.text.encode('utf8'))`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/cuentas.py/saldo?country_code=MX&user_id=78&session_id=388-481WXSHIEDX7ba5MWcFXCA==" 
        payload = {} 
        headers= {}
         response = requests.request("GET", url, headers=headers, data = payload)
          print(response.text.encode('utf8'))`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: `var request = require('request');
            var options = {
            'method': 'GET',
            'url': 'URL_BASE/ws/cuentas.py/saldo?country_code=MX&user_id=78&session_id=388-481WXSHIEDX7ba5MWcFXCA==',
            'headers': {}
            };
            request(options, function (error, response) { 
            if (error) throw new Error(error);
            console.log(response.body);
            });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: `var requestOptions = {
              method: 'GET',
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/cuentas.py/saldo?country_code=MX&user_id=78&session_id=388-481WXSHIEDX7ba5MWcFXCA==", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: `curl --location --request GET 'URL_BASE/ws/cuentas.py/saldo?country_code=MX&user_id=78&session_id=388-481WXSHIEDX7ba5MWcFXCA%3D%3D'`
      }
    ]
  }
  return(
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
      <h1 id="account-balance">Account Balance</h1>
      <p>This service is used to obtain the account balance.</p>
      <p>
        <span className="label get">GET:</span>
        <span> </span> <code>URL_BASE + /ws/cuentas.py/saldo</code>
      </p>
      <h2 id="receives">Receives:</h2>
      <p>
        All parameters are sent in the call&#39;s querystring, so a percentage
        encoding for URI must be applied &#40;aka URL encoding&#41;.
      </p>
      <div className='overflow-auto'>
      <table>
        <thead>
          <tr>
            <th align="left">Parameter</th>
            <th align="center">Format</th>
            <th align="center">Obligatory</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
            <th align="center">Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="left">country_code</td>
            <td align="center">
              ISO 3166 alpha-2 &#40;
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                https://www.iso.org/glossary-for-iso-3166.html
              </a>
              &#41;
            </td>
            <td align="center">Yes</td>
            <td align="center">Country code in which the user resides</td>
            <td align="center">N/A</td>
            <td align="center">ES &#40;Spain&#41;</td>
          </tr>
          <tr>
            <td align="left">user_id</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">
              Identifier of the user making the balance request
            </td>
            <td align="center">N/A</td>
            <td align="center">4532</td>
          </tr>
          <tr>
            <td align="left">session_id</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send signature to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Obtained by identifying the user with ID user_id on the platform
            </td>
            <td align="center">N/A</td>
            <td align="center">385-XadgYoWixbzN_wqWc3Yyog==</td>
          </tr>
          <tr>
            <td align="left">signature</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send session_id to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Enables you to sign the request without having to log in
            </td>
            <td align="center">N/A</td>
            <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
          </tr>
          <tr>
            <td align="left">phone</td>
            <td align="center">Integer</td>
            <td align="center">No</td>
            <td align="center">
              Phone number of the user whose balance is wanted to be checked
            </td>
            <td align="center">N/A</td>
            <td align="center">7229063245</td>
          </tr>
        </tbody>
      </table>
      </div>
      <h2 id="returns">Returns:</h2>
      <p>
        Depending on the result of the operation, this service can return two
        different JSON:
      </p>
      <h3 id="answer-json-ok">Answer JSON OK:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
      <h2 id="where">Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li>
          <code>currency:</code> Abbreviation of the currency in which the balance
          is.
        </li>
        <li>
          <code>amount:</code> Amount in the currency format, which reflects the
          user&#39;s balance.
        </li>
        <li>
          <code>elapsed:</code> Operation execution time.
        </li>
      </ul>
      <h3 id="answer-json-ko">Answer JSON KO:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
      <h2 id="where-1">Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li>
          <code>level:</code> Error importance level.
        </li>
        <li>
          <code>message:</code> Error message.
        </li>
        <li>
          <code>error:</code> Sole error code.
        </li>
      </ul>
       
      <h2 id="business-logic">Business logic:</h2>
      <p>
        With this service we obtain the balance of the indicated user. If we do
        not indicate the phone number, the balance of the user who makes the
        request will be returned. Users who are allowed to know the balance of
        another user are its affiliates.
      </p>
      </div>

      <div className='col-md-5'>
      <h2 id="example-requests">Example requests:</h2>
      <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
  
     
      </div>
      </div>
    </Fragment>
  );
 }
export default Saldo_de_la_cuenta_EN;
