import React, { Fragment } from 'react';

const SETUP_RaspberryPi = () => (
  <Fragment>
    <h1 id="avap™-dev-studio-2024-on-raspberry-pi">
      AVAP™ Dev Studio 2024 on Raspberry Pi
    </h1>
    <p>You can run AVAP™ Dev Studio 2024 on Raspberry Pi devices.</p>
    <p>
      <img
        src="http://101obex.static.mooo.com/02-E_RPi.png"
        alt="LogoRaspberry"
      />
    </p>
    <p>
      By downloading and using AVAP™ Dev Studio 2024, you agree to the license
      terms and privacy statement.
    </p>
    <h2 id="installation">Installation</h2>
    <p>
      AVAP™ Dev Studio 2024 is officially distributed via the Raspberry Pi OS
      &#40;previously called Raspbian&#41; APT repository, in both 32-bit and
      64-bit variants.
    </p>
    <p>You can install it by running:</p>
    <p>| sudo apt update | | sudo apt install code |</p>
    <h3 id="running-avap™-dev-studio-2024">Running AVAP™ Dev Studio 2024</h3>
    <p>
      After installing the AVAP™ Dev Studio 2024 package, you can run AVAP™ Dev
      Studio 2024 by typing <code>code</code> in a terminal or launching it via
      the <strong>Programming</strong> menu.
    </p>
    <h2 id="updates">Updates</h2>
    <p>
      Your Raspberry Pi should handle updating AVAP™ Dev Studio 2024 in the same
      way as other packages on the system:
    </p>
    <p>| sudo apt update | | sudo apt upgrade code |</p>
    <p>
      You can always check when a new release is available in our Updates page.
    </p>
    <h2 id="system-requirements">System requirements</h2>
    <p>
      AVAP™ Dev Studio 2024 is supported on these Raspberry Pi models running a
      32-bit or 64-bit version of Raspberry Pi OS:
    </p>
    <ul>
      <li>Raspberry Pi 3 Model B/B+</li>
      <li>Raspberry Pi 4 Model B</li>
      <li>Raspberry Pi 400</li>
    </ul>
    <p>
      While 1 GB of memory &#40;RAM&#41; meets the minimum system requirements,
      users will benefit from installing AVAP™ Dev Studio 2024 on a Raspberry Pi
      4 with more memory.
    </p>
    <p>
      First-generation Raspberry Pi modules and Raspberry Pi Zero are not
      supported as they only include an ARMv6 CPU.
    </p>
    <h3 id="workaround-for-poor-performance">
      Workaround for poor performance
    </h3>
    <p>
      AVAP™ Dev Studio 2024 on Raspberry Pi 4 may be slow with the default
      setup. A workaround is to disable hardware &#40;GPU&#41; acceleration in
      AVAP™ DS 2024:
    </p>
    <ol>
      <li>
        Open the AVAP™ Dev Studio 2024 <code>argv.json</code> file using the
        Preferences: Configure Runtime Arguments command.
      </li>
      <li>
        Set <code>&quot;disable-hardware-acceleration&quot;: true.</code>
      </li>
      <li>Restart AVAP™ Dev Studio 2024.</li>
    </ol>
    <p>
      The <code>&quot;disable-hardware-acceleration&quot;: true</code> runtime
      argument switch has the effect of passing the <code>--disable-gpu</code>{' '}
      command-line argument on AVAP™ Dev Studio 2024 startup.
    </p>
    <h2 id="next-steps">Next steps</h2>
    <p>
      Once you have installed AVAP™ Dev Studio 2024, these topics will help you
      learn more about it:
    </p>
    <ul>
      <li>
        Additional Components - Learn how to install Git, Node.js, TypeScript,
        and tools like Yeoman.
      </li>
      <li>User Interface - A quick orientation to AVAP™ Dev Studio 2024.</li>
      <li>
        User/Workspace Settings - Learn how to configure AVAP™ Dev Studio 2024
        to your preferences through settings.
      </li>
    </ul>
  </Fragment>
);
export default SETUP_RaspberryPi;
