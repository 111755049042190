import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Solicitar_subida_de_nivel_KYC_EN = () => 
  {
    const codes ={
      answe_ok_ko: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": true, 
          "result": <boolean>, 
          "validation": { 
            "<field_id>": <string>, 
          }, 
          "elapsed": <float> 
        }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": <boolean>, 
          "nivel": <string>, 
          "message": <string>, 
          "error": <string> }`,
        copied: false
      },
      answe_ko2: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": <boolean>, 
          "state": <integer>, 
          "level_kyc": <integer>,
          "message": <string>, 
          "elapsed": <float> 
        }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/users.py/acreditar_nivel_kyc" 
        payload = { 'session_id': '354-GeJOmP6DWifU1xMzAebHlg==', 'user_id': '4532', 'country_code': 'MX', 'kyc_level_field': '{"street_national_residency": "test","outside_number_national_residency": "1","interior_number_national_residency": "1","colony_national_residency": "test","delegation_national_residency": "test","city_national_residency": "test","postal_code_national_residency": "test","country_id_national_residency": "85"}' } 
        files = [ ('digital_id', open('test.jpg','rb')) ] 
        headers = { 'Content-Type': 'application/json' } 
        response = requests.request("POST", url, headers=headers, data = payload, files = files) 
        print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests url = "URL_BASE/ws/users.py/acreditar_nivel_kyc" 
          payload = { 'session_id': '354-GeJOmP6DWifU1xMzAebHlg==', 'user_id': '4532', 'country_code': 'MX', 'kyc_level_field': '{"street_national_residency": "test","outside_number_national_residency": "1","interior_number_national_residency": "1","colony_national_residency": "test","delegation_national_residency": "test","city_national_residency": "test","postal_code_national_residency": "test","country_id_national_residency": "85"}' } 
          files = [ ('digital_id', open('test.jpg','rb')) ] 
          headers = { 'Content-Type': 'application/json' } 
          response = requests.request("POST", url, headers=headers, data = payload, files = files) 
          print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var fs = require('fs');
              var options = {
              'method': 'POST',
              'url': 'URL_BASE/ws/users.py/acreditar_nivel_kyc',
              'headers': {
              'Content-Type': 'application/json'
              },
              formData: {
              'session_id': '354-GeJOmP6DWifU1xMzAebHlg==',
              'user_id': '4532',
              'country_code': 'MX',
              'kyc_level_field': '{"street_national_residency": "test","outside_number_national_residency": "1","interior_number_national_residency": "1","colony_national_residency": "test","delegation_national_residency": "test","city_national_residency": "test","postal_code_national_residency": "test","country_id_national_residency": "85"}',
              'digital_id': {
              'value': fs.createReadStream('test.jpg'),
              'options': {
              'filename': '2019-08-30-11-46-20-1200x800.jpg',
              'contentType': null
              }
              }
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              var formdata = new FormData();
              formdata.append("session_id", "354-GeJOmP6DWifU1xMzAebHlg==");
              formdata.append("user_id", "4532");
              formdata.append("country_code", "MX");
              formdata.append("kyc_level_field", "{\"street_national_residency\": \"test\",\"outside_number_national_residency\": \"1\",\"interior_number_national_residency\": \"1\",\"colony_national_residency\": \"test\",\"delegation_national_residency\": \"test\",\"city_national_residency\": \"test\",\"postal_code_national_residency\": \"test\",\"country_id_national_residency\": \"85\"}");
              formdata.append("digital_id", fileInput.files[0], "test.jpg");
              var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: formdata,
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/users.py/acreditar_nivel_kyc", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request POST 'URL_BASE/ws/users.py/acreditar_nivel_kyc' \ --header 'Content-Type: application/json' \ --form 'session_id=354-GeJOmP6DWifU1xMzAebHlg==' \ --form 'user_id=4532' \ --form 'country_code=MX' \ --form 'kyc_level_field={"street_national_residency": "test","outside_number_national_residency": "1","interior_number_national_residency": "1","colony_national_residency": "test","delegation_national_residency": "test","city_national_residency": "test","postal_code_national_residency": "test","country_id_national_residency": "85"}' \ --form 'digital_id=@test.jpg'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="kyc-level-upgrade-request">KYC Level Upgrade Request</h1>
        <p>
          This service is used to validate the introduced data and to carry out a
          KYC level upgrade &#40;Know Your Customer&#41;.
        </p>
        <p>
          <span className="label post">POST:</span>
          <span> </span> <code>URL_BASE + /ws/users.py/acreditar_nivel_kyc</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  ISO 3166 alpha-2
                </a>
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">Identifier of the user making the request</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">kyc_level_field</td>
              <td align="center">JSON</td>
              <td align="center">Yes</td>
              <td align="center">
                Object with each of the fields filled in the form, according to the
                following structure: <code>&#123;id&lt;field&gt;: valor&#125;</code>
              </td>
              <td align="center">N/A</td>
            </tr>
          </tbody>
        </table>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-okko">Answer JSON OK/KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok_ko}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>result:</code> Shows if the operation has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>validation:</code> JSON with the id of the fields, and the error
            message of the validation of that field. If the process has been
            correct, the JSON will come empty.
          </li>
          <li>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <p>
          For this service, there are two possible KO response JSONs, one generic
          and the other to avoid two upload requests in process, which indicates
          that the status of that request is in progress.
        </p>
        <h3 id="standard-json-ko-answer">Standard JSON KO answer:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>nivel:</code> Error importance level.
          </li>
          <li>
            <code>message:</code> Error message.
          </li>
          <li>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h3 id="specific-json-ko-answer">Specific JSON KO answer:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko2}/>
          </div>
        <h2 id="where-2">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>state:</code> Identifier of the state in which the level_kyc
            upgrade is located.
          </li>
          <li>
            <code>level_kyc:</code> Level intended to access after completing the
            process of acceptance of the request.
          </li>
          <li>
            <code>message:</code> Error message.
          </li>
          <li>
            <code>elapsed:</code> Operation execution time.{' '}
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          With this endpoint of requesting the KYC level upgrade, the user is
          allowed access to be able to make movements for a greater amount of money.
          This created request remains in a pending state until an administrator
          reviews the request and accepts or rejects it. At that moment the upgrade
          to the next level of KYC will be effective.
        </p>
        </div>

      <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
      </div>
      </Fragment>
    );
  }
export default Solicitar_subida_de_nivel_KYC_EN;
