import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Obtener_Campos_Alta_Cliente_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "questions": { 
            "list_of_questions": [
               { "id": <integer>, 
               "text": <string> } ] },
               "fields": [ 
                [ { 
                  "divided": <string>, 
                  "help_text": <string> 
                  "options": [ [ <string>, <string> ] ], 
                  "id": <string>, "type": <string>, 
                  "longitude": <integer>, "field_label": <string> 
                } ], [] ], 
                "legal_conditions": {
                   "links": [ 
                    { "text": <string>,  "link": <string> } ], 
                    "legal_conditions": { "id": <integer>, "legal_text": <string> } } }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": false, 
          "level": <string>, 
          "message": <string>, 
          "error": <string> 
        }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/users.py/campos_alta_cliente?country_code=MX&user_id=4532&session_id=410-GvUO9y9b3ll_gQXTlYxH9Q==&affiliate_id=4323" 
        payload = {} 
        headers= {} 
        response = requests.request("GET", url, headers=headers, data = payload) 
        print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests url = "URL_BASE/ws/users.py/campos_alta_cliente?country_code=MX&user_id=4532&session_id=410-GvUO9y9b3ll_gQXTlYxH9Q==&affiliate_id=4323" 
          payload = {} 
          headers= {} 
          response = requests.request("GET", url, headers=headers, data = payload) 
          print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'GET',
              'url': 'URL_BASE/ws/users.py/campos_alta_cliente?country_code=MX&user_id=4532&session_id=410-GvUO9y9b3ll_gQXTlYxH9Q==&affiliate_id=4323',
              'headers': {}
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var requestOptions = {
                method: 'GET',
                redirect: 'follow'
                };
                fetch("URL_BASE/ws/users.py/campos_alta_cliente?country_code=MX&user_id=4532&session_id=410-GvUO9y9b3ll_gQXTlYxH9Q==&affiliate_id=4323", requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request GET 'URL_BASE/ws/users.py/campos_alta_cliente?country_code=MX&user_id=4532&session_id=410-GvUO9y9b3ll_gQXTlYxH9Q%3D%3D&affiliate_id=4323'`
        }
      ]
    }
  
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="obtain-user-registration">Obtain User Registration</h1>
        <p>
          This service is used to obtain the necessary fields to register a client.
        </p>
        <p>
          <span className="label get">GET:</span>{' '}
          <code>URL_BASE + /ws/users.py/campos_alta_cliente</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All parameters are sent in the querystring of the call, so a percentage
          encoding for URI must be applied &#40;aka URL encoding&#41;.
        </p>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  ISO 3166 alpha-2
                </a>
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">Identifier of the user making the request</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">affiliate_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Affiliate identifier, to obtain the specific registration fields for
                that affiliate
              </td>
              <td align="center">N/A</td>
            </tr>
          </tbody>
        </table>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li>
            <code>questions:</code> Container object for the different system
            security questions.
          </li>
          <li>
            <code>list_of_questions:</code> List of security questions to answer
            them.
          </li>
          <li>
            <code>id:</code> Identifier of the security question. &#40;Necessary to
            be able to send each question with its answer&#41;.
          </li>
          <li>
            <code>text:</code> Security answer.
          </li>
          <li>
            <code>fields:</code> List of lists with each of the fields that will
            make up the form to register users.
          </li>
          <li>
            <code>divided:</code> The value <code>&quot; 1 &quot;</code> or{' '}
            <code>&quot; &quot;</code>, and indicates if the field is displayed at
            50% of size with respect to the rest of the form fields.
          </li>
          <li>
            <code>help_text:</code> Message to display in the fields as tooltip
          </li>
          <li>
            <code>id:</code> Html identifier of the field.
          </li>
          <li>
            <code>type:</code> Contains the value{' '}
            <code>&quot;texto_numerico&quot;</code>,{' '}
            <code>&quot;free_text&quot;</code>,{' '}
            <code>&quot;multiple_selection&quot;</code>,{' '}
            <code>&quot;date&quot;</code>, <code>&quot;archive&quot;</code>.
            Indicates the type of data that the field accepts.
          </li>
          <li>
            <code>longitude:</code> Max length of the data that the field supports.
          </li>
          <li>
            <code>field_label:</code> Text to display in the label of each field of
            the form.
          </li>
          <li>
            <code>options:</code> Only available for fields of type{' '}
            <code>&quot;multiple_selection&quot;</code>. List that contains a list
            for each element of the drop-down, indicating the value of the field in
            the first element of each list, and the text in the second.
          </li>
          <li>
            <code>legal_conditions:</code> Containing object of the legal conditions
            of the system, and those of the affiliate.
          </li>
          <li>
            <code>links:</code> List with links to affiliate conditions
          </li>
          <li>
            <code>text:</code> Name of the link of the legal conditions.
          </li>
          <li>
            <code>link:</code> Link to the affiliate&#39;s legal conditions.
          </li>
          <li>
            <code>legal_conditions:</code> Active legal conditions in the system.
          </li>
          <li>
            <code>id:</code> Identifier of the active legal conditions.
            &#40;Necessary to accept them&#41;.
          </li>
          <li>
            <code>legal_text:</code> Text of the active legal condition in the
            system.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>level:</code> Error importance level.
          </li>
          <li>
            <code>message:</code> Error message.
          </li>
          <li>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          By means of this service, the necessary data is obtained to be able to set
          up a registration form with custom fields and to be able through this
          endpoint to accept the legal conditions and answer the security questions
          in a single step.
        </p>
        </div>

      <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
       
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
       
        </div>
        </div>
      </Fragment>
    );
  }
export default Obtener_Campos_Alta_Cliente_EN;
