import React, { Fragment } from 'react';

const SOURCE_CONTROL_FAQ = () => (
  <Fragment>
    <h1 id="source-control-faq">Source Control FAQ</h1>
    <p>
      This topic answers frequently asked questions about using Git source
      control and GitHub in AVAP<sup>TM</sup> Dev Studio.
    </p>
    <h2 id="git">Git</h2>
    <h3 id="how-to-revert-or-undo-a-git-commit">
      How to revert or undo a Git commit?
    </h3>
    <p>
      Revert your last commit with the <strong>Git: Undo Last Commit</strong>{' '}
      command. This will reset your branch to the state right before you did the
      commit, including all changes. The command is also available as menu in{' '}
      <strong>More Actions</strong> ... under <strong>Commit</strong> on the top
      of the Source Control view.
    </p>
    <h3 id="how-to-rename-a-local-branch">How to rename a local branch?</h3>
    <p>
      The <strong>Git: Rename Branch…</strong> command will prompt you for the
      new name.
    </p>
    <h3 id="how-to-i-undo-a-git-add-before-committing">
      How to I undo a git add before committing?
    </h3>
    <p>
      Added files listed in the <strong>Staged Changes</strong> can be unstaged
      with the <strong>-</strong> icon or by drag-and-drop.
    </p>
    <h3 id="how-to-i-edit-the-most-recent-commit-message">
      How to I edit the most recent commit message?
    </h3>
    <p>
      To update the commit message for the last local commit use the{' '}
      <strong>Git: Commit Staged &#40;Amend&#41;</strong> command. It will open
      an editor to edit and save the last message. Make sure that no other
      changes are staged, as they would be included with the commit.
    </p>
    <h3 id="i-initialized-my-repo-but-the-actions-in-the--menu-are-all-grayed-out">
      I initialized my repo but the actions in the ... menu are all grayed out
    </h3>
    <p>
      To <strong>push, pull, and sync</strong> you need to have a Git origin set
      up. You can get the required URL from the repository host. Once you have
      that URL, you need to add it to the Git settings by running a couple of
      command-line actions. For example:
    </p>

    <pre className="">
      <code className="">
        \&gt; git remote add origin https://github.com/&lt;repo owner&gt;/&lt;repo
        name&gt;.git
        <br />
        \&gt; git push -u origin main
      </code>
    </pre>
    <h3 id="my-team-is-using-team-foundation-version-control-tfvc-instead-of-git-what-should-i-do">
      My team is using Team Foundation Version Control &#40;TFVC&#41; instead of
      Git. What should I do?
    </h3>
    <p>Use the Azure Repos extension and this will light up TFVC support.</p>
    <h3 id="why-do-the-pull-push-and-sync-actions-never-finish">
      Why do the Pull, Push and Sync actions never finish?
    </h3>
    <p>
      This usually means there is no credential management configured in Git and
      you&#39;re not getting credential prompts for some reason.
    </p>
    <p>
      You can always set up a credential helper in order to pull and push from a
      remote server without having AVAP<sup>TM</sup> Dev Studio prompt for your
      credentials each time.
    </p>
    <h3 id="how-can-i-sign-in-to-git-with-my-azure-devops-organization-that-requires-multi-factor-authentication">
      How can I sign in to Git with my Azure DevOps organization that requires
      multi-factor authentication?
    </h3>
    <p>
      Git Credential Manager &#40;GCM&#41; is the recommended Git credential
      helper for Windows, macOS, and Linux. If you&#39;re running Git for
      Windows, GCM has already been installed and configured for you. If
      you&#39;re running on macOS or Linux, the GCM README has setup
      instructions.
    </p>
    <h3 id="i-have-github-desktop-installed-on-my-computer-but-avap-dev-studio-ignores-it">
      I have GitHub Desktop installed on my computer but AVAP Dev Studio ignores
      it
    </h3>
    <p>
      AVAP™ Dev Studio only supports the official Git distribution for its Git
      integration.
    </p>
    <h3 id="i-keep-getting-git-authentication-dialogs-whenever-avap-dev-studio-is-running">
      I keep getting Git authentication dialogs whenever AVAP Dev Studio is
      running
    </h3>
    <p>
      AVAP<sup>TM</sup> Dev Studio automatically fetches changes from the server
      in order to present you with a summary of incoming changes. The Git
      authentication dialog is independent from AVAP<sup>TM</sup> Dev Studio
      itself and is a part of your current Git credential helper.
    </p>
    <p>
      One way to avoid these prompts is to set up a credential helper that
      remembers your credentials.
    </p>
    <p>
      Another option is to disable the auto fetch feature by changing the
      following setting: &quot;<code>git.autofetch&quot;:</code>{' '}
      <code>false</code>.
    </p>
    <h3 id="why-is-avap-dev-studio-warning-me-that-the-git-repository-is-potentially-unsafe">
      Why is AVAP Dev Studio warning me that the git repository is potentially
      unsafe?
    </h3>
    <p>
      AVAP™ Dev Studio uses <code>git.exe</code> for executing all Git
      operations. Starting with Git 2.35.2, users are prevented from running Git
      operations in a repository that is in a folder that owned by a user other
      than the current user as the repository is deemed to be potentially
      unsafe.
    </p>
    <p>
      If you try to open such a repository, AVAP<sup>TM</sup> Dev Studio will
      show a welcome view in the Source Control view or an error notification.
      Both the welcome view and the notification contain the{' '}
      <strong>Manage Unsafe Repositories</strong> command that lets you review
      the list of potentially unsafe repositories, mark them as safe, and open
      them. The <strong>Manage Unsafe Repositories</strong> command is also
      available in the Command Palette &#40;<code>Ctrl+Shift+P</code>&#41;.
      Marking a repository as safe will add the repository location to the
      safe.directory git configuration.
    </p>
    <p>
      On Windows, a common scenario where this can occur is when a repository is
      cloned using an application &#40;for example, Windows Terminal or AVAP
      <sup>TM</sup> Dev Studio&#41; that runs &quot;as administrator&quot;, but
      the repository is opened using another application or instance &#40;for
      example, AVAP<sup>TM</sup> Dev Studio&#41; that does not run &quot;as
      administrator&quot;.
    </p>
    <h3 id="why-isnt-avap™-dev-studio-discovering-git-repositories-in-parent-folders-of-workspaces-or-open-files">
      Why isn&#39;t AVAP™ Dev Studio discovering Git repositories in parent
      folders of workspaces or open files?
    </h3>
    <p>
      AVAP<sup>TM</sup> Dev Studio uses
      <code>git rev-parse --show-toplevel</code> to determine the root of a Git
      repository. In most cases, the root of the Git repository is inside the
      workspace, but there are scenarios where the root of the Git repository is
      in the parent folders of the workspace or the open file&#40;s&#41;. While
      opening Git repositories in parent folders of workspaces or open files is
      a great feature for advanced users, it can be confusing for new users. We
      have seen cases where this confusion resulted in discarding changes from
      these Git repositories causing data loss.
    </p>
    <p>
      To avoid confusion, and to reduce the risk of data loss, AVAP<sup>TM</sup>{' '}
      Dev Studio will display a notification and a new welcome view in the
      Source Control view, and will not automatically open Git repositories from
      the parent folders of workspaces and open files.
    </p>
    <p>
      You can control how Git repositories from parent folders are handled using
      the <code>git.openRepositoryInParentFolders</code> setting. If you would
      like to restore the old behavior, set the{' '}
      <code>git.openRepositoryInParentFolders</code> setting to{' '}
      <code>always</code>.
    </p>
    <h3 id="can-i-use-ssh-git-authentication-with-avap™-dev-studio">
      Can I use SSH Git authentication with AVAP™ Dev Studio?
    </h3>
    <p>
      Yes, though AVAP<sup>TM</sup> Dev Studio works most easily with SSH keys
      without a passphrase. If you have an SSH key with a passphrase, you&#39;ll
      need to launch AVAP<sup>TM</sup> Dev Studio from a Git Bash prompt to
      inherit its SSH environment.
    </p>
    <h2 id="github">GitHub</h2>
    <h3 id="is-github-enterprise-supported">Is GitHub Enterprise supported?</h3>
    <p>
      AVAP<sup>TM</sup> Dev Studio has official support for authentication with
      GitHub Enterprise Servers. Open a local checkout of a GHES repository and
      you will be prompted to sign in with your GitHub Enterprise Server
      account.
    </p>
  </Fragment>
);
export default SOURCE_CONTROL_FAQ;
