import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';

const Enviar_SMS_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": <boolean>, 
          "signed": <boolean>, 
          "invoice": <integer>, 
          "currency_data": {
            "abbreviation": <string>, 
            "suffix": <string>, 
            "format": <string>, 
            "symbol": <string>, 
            "prefix": <string>, 
            "decimals": <decimal>, 
            "id": <integer> 
          }, 
          "elapsed": <float>, 
          "raw_amount": <decimal>, 
          "commission_user_card": <decimal>, 
          "currency": <string>, 
          "codtran": <string>, 
          "other_data": { 
            "failed": <integer>, 
            "sent": <integer>, 
            "message": <string> }, 
            "user_commission": <decimal>,
             "amount": <string> 
            }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": false, 
          "level": <string>, 
          "message": <string>, 
          "error": <string> 
        }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/movimientos.py/enviarsms" 
        payload = {'country_code': 'MX', 'user_id': '4532', 'session_id': '416-AK3e76KRzD3CQIYoHODFLA==', 'phones': '7229063245,7229063244,7229063243', 'message': 'Lorem ipsum dolor sit amet.'} 
        files = [] 
        headers= {} 
        response = requests.request("POST", url, headers=headers, data = payload, files = files) 
        print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests url = "URL_BASE/ws/movimientos.py/enviarsms" 
          payload = {'country_code': 'MX', 'user_id': '4532', 'session_id': '416-AK3e76KRzD3CQIYoHODFLA==', 'phones': '7229063245,7229063244,7229063243', 'message': 'Lorem ipsum dolor sit amet.'} 
          files = [] 
          headers= {} 
          response = requests.request("POST", url, headers=headers, data = payload, files = files) 
          print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'POST',
              'url': 'URL_BASE/ws/movimientos.py/enviarsms',
              'headers': {},
              formData: {
              'country_code': 'MX',
              'user_id': '4532',
              'session_id': '416-AK3e76KRzD3CQIYoHODFLA==',
              'phones': '7229063245,7229063244,7229063243',
              'message': 'Lorem ipsum dolor sit amet.'
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var formdata = new FormData();
              formdata.append("country_code", "MX");
              formdata.append("user_id", "4532");
              formdata.append("session_id", "416-AK3e76KRzD3CQIYoHODFLA==");
              formdata.append("phones", "7229063245,7229063244,7229063243");
              formdata.append("message", "Lorem ipsum dolor sit amet.");
              var requestOptions = {
              method: 'POST',
              body: formdata,
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/movimientos.py/enviarsms", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request POST 'URL_BASE/ws/movimientos.py/enviarsms' \ --form 'country_code=MX' \ --form 'user_id=4532' \ --form 'session_id=416-AK3e76KRzD3CQIYoHODFLA==' \ --form 'phones=7229063245,7229063244,7229063243' \ --form 'message=Lorem ipsum dolor sit amet.'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="send-sms">Send SMS</h1>
        <p>This service is used to send SMS.</p>
        <p>
          <span className="label post">POST:</span>
          <span> </span> <code>URL_BASE + /ws/movimientos.py/enviarsms</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <div className='overflow-auto'>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
              <td align="center"></td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Identifier of the user making the request &#40;Admin or
                Affiliate&#41;
              </td>
              <td align="center">N/A</td>
              <td align="center"></td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
              <td align="center"></td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
              <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
            </tr>
            <tr>
              <td align="left">phones</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                Telephone numbers to which we want to send the SMS
              </td>
              <td align="center">N/A</td>
              <td align="center">7229063245,7229063244,7229063243</td>
            </tr>
            <tr>
              <td align="left">message</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">Text in the SMS</td>
              <td align="center">N/A</td>
              <td align="center">&quot;Lorem ipsum dolor sit amet.&quot;</td>
            </tr>
          </tbody>
        </table>
        </div>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>signed:</code> Indicates that the call is signed.
          </li>
          <li>
            <code>invoice:</code> Invoice/page number.
          </li>
          <li>
            <code>currency_data:</code> Contains the different details of the
            currency used in the operation.
          </li>
          <li>
            <code>abbreviation:</code> The abbreviated name of the currency
            &#40;EUR, MXN&#41;.
          </li>
          <li>
            <code>suffix:</code> The suffix that is applied in the currency
            format&#40;pesos, euros&#41;.
          </li>
          <li>
            <code>format:</code> The full format that applies to the currency, it
            includes the suffix and the prefix.
          </li>
          <li>
            <code>symbol:</code> The symbol associated to the currency &#40;€, ¢,
            $&#41;.
          </li>
          <li>
            <code>prefix:</code> The prefix that is applied in the currency format.
          </li>
          <li>
            <code>decimals:</code> The maximum number of decimal places that will be
            included in the currency format.
          </li>
          <li>
            <code>id:</code> Identifier of the currency in BBDD
          </li>
          <li>
            <code>elapsed:</code> Operation execution time.
          </li>
          <li>
            <code>raw_amount:</code> Negative transaction amount without applying
            the format.
          </li>
          <li>
            <code>currency:</code> Short name of the currency used in the operation,
            it matches the abbreviation.
          </li>
          <li>
            <code>commission_user_card:</code> Commission that would be applied if
            the payment is made by card.
          </li>
          <li>
            <code>codtran:</code> Transaction code that identifies the executed
            operation.
          </li>
          <li>
            <code>other_data:</code> Data of the sms sent.
          </li>
          <li>
            <code>failed:</code> Number of sms that have not been sent correctly.
          </li>
          <li>
            <code>sent:</code> Number of sms that have been sent successfully.
          </li>
          <li>
            <code>message:</code> Text sent in SMS.
          </li>
          <li>
            <code>user_commission:</code> Commission that will be applied to the
            user.
          </li>
          <li>
            <code>amount:</code> Negative transaction amount applying the format.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>level:</code> Error importance level.
          </li>
          <li>
            <code>message:</code> Error message.
          </li>
          <li>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          By means of this service, it is possible to send an SMS to several
          telephone numbers.
        </p>
        </div>
        
      <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
        </div>
      </Fragment>
    );
  }
export default Enviar_SMS_EN;
