import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { Form } from 'react-bootstrap';

type ApiResultProps = {};

const ApiTestResult: FunctionComponent<ApiResultProps> = () => {
  const [apiResult, setApiResult] = useState([]);

  const [from, setFrom] = useState('');
  const [until, setUntil] = useState('');
  const [id, setid] = useState('');
  const [api_product, setApiProduct] = useState('');
  const [api_service, setApiService] = useState('');
  const [country, setCountry] = useState('');

  const getApiResult = async () => {
    try {
      const payload = {
        id,
        from,
        until,
        api_product,
        api_service,
        country
      };
      const result = (await ObexRequestHandler.post('/api_unittest_results', payload)).data;
      setApiResult(result);
    } catch (error) {
      console.error('Error fetching API results:', error);
    }
  };

  useEffect(() => {
    getApiResult();
  }, [from, until, id, api_product]);

    // Función para permitir solo números en el campo de ID
    const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Utilizamos una expresión regular para permitir solo números
    const numericValue = inputValue.replace(/[^0-9]/g, '');
    setid(numericValue);
  };
  const handleApiProductChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setApiProduct(e.target.value);
  };
  const handleApiServiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setApiService(e.target.value);
  };
  const handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCountry(e.target.value);
  };
  

  const renderOutputProperties = (outputItem) => {
    // Verifica si outputItem y outputItem.output están definidos
    if (!outputItem || !outputItem.output || outputItem.output.status === undefined) {
      return <p>No se encontraron datos de salida</p>;
    }
  
    //The output is shown in pretty json format, so all info is available
    return (
      <>
      <p><strong>Output:</strong></p>
        <p>
        <pre style={{fontSize: '12px'}}>{JSON.stringify(outputItem, undefined, 4)}</pre> 
        </p>
      </>
    );

    // Ahora puedes acceder a las propiedades de outputItem.output
    //const { status, codtran, resultado, elapsed } = outputItem.output;
  
    // return (
    //   <>
    //     <p>
    //       <code>Status:</code> {status.toString()}<br />
    //       <strong>Codtran:</strong> {codtran}<br />
    //       {/* Agrega más propiedades según la estructura real de tu "output" */}
    //     </p>
    //   </>
    // );
  };
  
  

  return (
    <DashLayout sider={DashRouters} active="developers">
      <h3>API Results</h3>
      <Form.Group><Form.Label>ID:</Form.Label><Form.Control type="text"value={id}onChange={handleIdChange}placeholder="Enter ID"/></Form.Group>
      <Form.Group>
        <Form.Label>API Product:</Form.Label>
        <Form.Control
          type="text"
          value={api_product}
          onChange={handleApiProductChange}
          placeholder="Enter API Product"
        /></Form.Group>

        <Form.Group>
        <Form.Label>API Service:</Form.Label>
        <Form.Control
          type="text"
          value={api_service}
          onChange={handleApiServiceChange}
          placeholder="Enter API Service"
        /></Form.Group>

        <Form.Group>
        <Form.Label>Country:</Form.Label>
        <Form.Control
          type="text"
          value={country}
          onChange={handleCountryChange}
          placeholder="Enter Country"
        /></Form.Group>

      <Form.Group>
      <Form.Label>Select a date range:</Form.Label>
      <Form.Control type="date" value={from} onChange={(e) => setFrom(e.target.value)} />
      <Form.Control type="date" value={until} onChange={(e) => setUntil(e.target.value)} />
      </Form.Group>
      <ul>
        {apiResult.map((testResult, index) => (
          <li key={index}>
            <p>ID: {testResult.id}</p>
            <p>API Product: {testResult.api_product}</p>
            <p>API Service: {testResult.api_service}</p>
            <p>Date: {`${new Date(testResult.date).toLocaleDateString('en-US')}`}</p>
            <p>Time: {new Date(testResult.date).toLocaleTimeString('en-US')}</p>
            <p>API Country: {testResult.country}</p>
            <h4>Result:</h4>
              {Array.isArray(testResult.output) ? (
                testResult.output.map((outputItem, outputIndex) => (
                  <div key={outputIndex}>
                    {renderOutputProperties(outputItem)}
                  </div>
                ))
              ) : (
                <div>
                  {renderOutputProperties(testResult.output)}
                </div>
              )}

          </li>
        ))}
      </ul>
    </DashLayout>
  );
};

export default ApiTestResult;
