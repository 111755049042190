import React, { Fragment } from 'react';

const USER_GUIDE_IntelliSense = () => (
  <Fragment>
    <h1 id="intellisense">IntelliSense</h1>
    <p>
      IntelliSense is a general term for various code editing features
      including: code completion, parameter info, quick info, and member lists.
      IntelliSense features are sometimes called by other names such as
      &quot;code completion&quot;, &quot;content assist&quot;, and &quot;code
      hinting.&quot;
    </p>
    <h2 id="intellisense-for-your-programming-language">
      IntelliSense for your programming language
    </h2>
    <p>
      AVAP™ Dev Studio 2024 IntelliSense is provided for JavaScript, TypeScript,
      JSON, HTML, CSS, SCSS, and Less out of the box. AVAP™ DS Code supports
      word based completions for any programming language but can also be
      configured to have richer IntelliSense by installing a language extension.
    </p>
    <p>
      Below are the most popular language extensions in the{' '}
      <a href="https://marketplace.visualstudio.com/avapcode">Marketplace</a>.
      Select an extension tile below to read the description and reviews to
      decide which extension is best for you.
    </p>
    <h2 id="intellisense-features">IntelliSense features</h2>
    <p>
      AVAP™ DS Code IntelliSense features are powered by a language service. A
      language service provides intelligent code completions based on language
      semantics and an analysis of your source code. If a language service knows
      possible completions, the IntelliSense suggestions will pop up as you
      type. If you continue typing characters, the list of members
      &#40;variables, methods, etc.&#41; is filtered to only include members
      containing your typed characters. Pressing Tab or Enter will insert the
      selected member.
    </p>
    <p>
      You can trigger IntelliSense in any editor window by typing Ctrl+Space or
      by typing a trigger character &#40;such as the dot character &#40;.&#41;
      in JavaScript&#41;.
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> The suggestions widget supports CamelCase
        filtering, meaning you can type the letters which are upper cased in a
        method name to limit the suggestions. For example, &quot;cra&quot; will
        quickly bring up &quot;createApplication&quot;.
      </p>
    </blockquote>
    <p>
      If you prefer, you can turn off IntelliSense while you type. See{' '}
      <a href="/docs/editor/intellisense#_customizing-intellisense">
        Customizing IntelliSense
      </a>{' '}
      below to learn how to disable or customize VS Code&#39;s IntelliSense
      features.
    </p>
    <p>
      As provided by the language service, you can see{' '}
      <strong>quick info</strong> for each method by either pressing Ctrl+Space
      or clicking the info icon. The accompanying documentation for the method
      will now expand to the side. The expanded documentation will stay so and
      will update as you navigate the list. You can close this by pressing
      Ctrl+Space again or by clicking on the close icon.
    </p>
    <p>
      After choosing a method you are provided with{' '}
      <strong>parameter info</strong>.
    </p>
    <p>
      When applicable, a language service will surface the underlying types in
      the quick info and method signatures. In the image above, you can see
      several any types. Because JavaScript is dynamic and doesn&#39;t need or
      enforce types, any suggests that the variable can be of any type.
    </p>
    <h2 id="types-of-completions">Types of completions</h2>
    <p>
      The JavaScript code below illustrates IntelliSense completions.
      IntelliSense gives both inferred proposals and the global identifiers of
      the project. The inferred symbols are presented first, followed by the
      global identifiers &#40;shown by the Word icon&#41;.
    </p>
    <p>
      VS Code IntelliSense offers different types of completions, including
      language server suggestions, snippets, and simple word based textual
      completions.
    </p>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Icon</strong>
          </th>
          <th align="center">
            <strong>Name</strong>
          </th>
          <th align="center">
            <strong>Symbol type</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">![method icon][IntelliSense demo]</td>
          <td align="center">Methods and Functions</td>
          <td align="center">method, function, constructor</td>
        </tr>
        <tr>
          <td align="center">![variable icon][IntelliSense demo]</td>
          <td align="center">Variables</td>
          <td align="center">variable</td>
        </tr>
        <tr>
          <td align="center">![field icon][IntelliSense demo]</td>
          <td align="center">Fields</td>
          <td align="center">field</td>
        </tr>
        <tr>
          <td align="center">![type parameter][IntelliSense demo]</td>
          <td align="center">Type parameters</td>
          <td align="center">typeParameter</td>
        </tr>
        <tr>
          <td align="center">![constant][IntelliSense demo]</td>
          <td align="center">Constants</td>
          <td align="center">constant</td>
        </tr>
        <tr>
          <td align="center">![class][IntelliSense demo]</td>
          <td align="center">Classes</td>
          <td align="center">class</td>
        </tr>
        <tr>
          <td align="center">![interface][IntelliSense demo]</td>
          <td align="center">Interfaces</td>
          <td align="center">interface</td>
        </tr>
        <tr>
          <td align="center">![structure][IntelliSense demo]</td>
          <td align="center">Structures</td>
          <td align="center">struct</td>
        </tr>
        <tr>
          <td align="center">![event][IntelliSense demo]</td>
          <td align="center">Events</td>
          <td align="center">event</td>
        </tr>
        <tr>
          <td align="center">![operator][IntelliSense demo]</td>
          <td align="center">Operators</td>
          <td align="center">operator</td>
        </tr>
        <tr>
          <td align="center">![module][IntelliSense demo]</td>
          <td align="center">Modules</td>
          <td align="center">module</td>
        </tr>
        <tr>
          <td align="center">![property][IntelliSense demo]</td>
          <td align="center">Properties and Attributes</td>
          <td align="center">property</td>
        </tr>
        <tr>
          <td align="center">![enumeration icon][IntelliSense demo]</td>
          <td align="center">Values and Enumerations</td>
          <td align="center">value, enum</td>
        </tr>
        <tr>
          <td align="center">![reference][IntelliSense demo]</td>
          <td align="center">References</td>
          <td align="center">reference</td>
        </tr>
        <tr>
          <td align="center">![keyword][IntelliSense demo]</td>
          <td align="center">Keywords</td>
          <td align="center">keyword</td>
        </tr>
        <tr>
          <td align="center">![file][IntelliSense demo]</td>
          <td align="center">Files</td>
          <td align="center">file</td>
        </tr>
        <tr>
          <td align="center">![folder][IntelliSense demo]</td>
          <td align="center">Folders</td>
          <td align="center">folder</td>
        </tr>
        <tr>
          <td align="center">![color][IntelliSense demo]</td>
          <td align="center">Colors</td>
          <td align="center">color</td>
        </tr>
        <tr>
          <td align="center">![unit][IntelliSense demo]</td>
          <td align="center">Unit</td>
          <td align="center">unit</td>
        </tr>
        <tr>
          <td align="center">
            ![a square with ellipses forming the bottom show snippet
            prefix][IntelliSense demo]
          </td>
          <td align="center">Snippet prefixes</td>
          <td align="center">snippet</td>
        </tr>
        <tr>
          <td align="center">
            ![a square with letters abc word completion][IntelliSense demo]
          </td>
          <td align="center">Words</td>
          <td align="center">text</td>
        </tr>
      </tbody>
    </table>
    <h2 id="customizing-intellisense">Customizing IntelliSense</h2>
    <p>
      You can customize your IntelliSense experience in settings and key
      bindings.
    </p>
    <h3 id="settings">Settings</h3>
    <p>
      The settings shown below are the default settings. You can change these
      settings in your settings.json file as described in{' '}
      <a href="/docs/getstarted/settings">User and Workspace Settings</a>.
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />
        <br />` `// Controls if quick suggestions should show up while typing
        <br />
        <br />` `"editor.quickSuggestions": &#123;
        <br />
        <br />` `"other": true,
        <br />
        <br />` `"comments": false,
        <br />
        <br />` `"strings": false
        <br />
        <br />` `&#125;,
        <br />
        <br />` `// Controls whether suggestions should be accepted on commit
        characters. For example, in JavaScript, the semi-colon &#40;`;`&#41; can
        be a commit character that accepts a suggestion and types that
        character.
        <br />
        <br />` `"editor.acceptSuggestionOnCommitCharacter": true,
        <br />
        <br />` `// Controls if suggestions should be accepted on 'Enter' - in
        addition to 'Tab'. Helps to avoid ambiguity between inserting new lines
        or accepting suggestions. The value 'smart' means only accept a
        suggestion with Enter when it makes a textual change
        <br />
        <br />` `"editor.acceptSuggestionOnEnter": "on",
        <br />
        <br />` `// Controls the delay in ms after which quick suggestions will
        show up.
        <br />
        <br />` `"editor.quickSuggestionsDelay": 10,
        <br />
        <br />` `// Controls if suggestions should automatically show up when
        typing trigger characters
        <br />
        <br />` `"editor.suggestOnTriggerCharacters": true,
        <br />
        <br />` `// Controls if pressing tab inserts the best suggestion and if
        tab cycles through other suggestions
        <br />
        <br />` `"editor.tabCompletion": "off",
        <br />
        <br />` `// Controls whether sorting favours words that appear close to
        the cursor
        <br />
        <br />` `"editor.suggest.localityBonus": true,
        <br />
        <br />` `// Controls how suggestions are pre-selected when showing the
        suggest list
        <br />
        <br />` `"editor.suggestSelection": "first",
        <br />
        <br />` `// Enable word based suggestions
        <br />
        <br />` `"editor.wordBasedSuggestions": "matchingDocuments",
        <br />
        <br />` `// Enable parameter hints
        <br />
        <br />` `"editor.parameterHints.enabled": true,
        <br />
        <br />
        &#125;
      </code>
    </pre>
    <h3 id="tab-completion">Tab Completion</h3>
    <p>
      The editor supports &quot;tab completion&quot; which inserts the best
      matching completion when pressing Tab. This works regardless of the
      suggest widget showing or not. Also, pressing Tab after inserting a
      suggestions will insert the next best suggestion.
    </p>
    <p>
      By default, tab completion is disabled. Use the editor.tabCompletion
      setting to enable it. These values exist:
    </p>
    <ul>
      <li>off - &#40;default&#41; Tab completion is disabled.</li>
      <li>
        on - Tab completion is enabled for all suggestions and repeated
        invocations insert the next best suggestion.
      </li>
      <li>
        onlySnippets - Tab completion only inserts static snippets which prefix
        match the current line prefix.
      </li>
    </ul>
    <h3 id="locality-bonus">Locality Bonus</h3>
    <p>
      Sorting of suggestions depends on extension information and on how well
      they match the current word you are typing. In addition, you can ask the
      editor to boost suggestions that appear closer to the cursor position,
      using the editor.suggest.localityBonus setting.
    </p>
    <p>
      In above images you can see that count, context, and colocated are sorted
      based on the scopes in which they appear &#40;loop, function, file&#41;.
    </p>
    <h3 id="suggestion-selection">Suggestion selection</h3>
    <p>
      By default, AVAP™ DS Code pre-selects the first suggestion in the
      suggestion list. If you&#39;d like different behavior, for example, to
      always select the most recently used item in the suggestion list, you can
      use the editor.suggestSelection setting.
    </p>
    <p>The available editor.suggestSelection values are:</p>
    <ul>
      <li>first - &#40;default&#41; Always select the top list item.</li>
      <li>
        recentlyUsed - The previously used item is selected unless a prefix
        &#40;type to select&#41; selects a different item.
      </li>
      <li>
        recentlyUsedByPrefix - Select items based on previous prefixes that have
        completed those suggestions.
      </li>
    </ul>
    <p>
      Selecting the most recently used item is very useful as you can quickly
      insert the same completion multiple times.
    </p>
    <p>
      &quot;Type to select&quot; means that the current prefix &#40;roughly the
      text left of the cursor&#41; is used to filter and sort suggestions. When
      this happens and when its result differs from the result of recentlyUsed,
      it will be given precedence.
    </p>
    <p>
      When using the last option, recentlyUsedByPrefix, AVAP™ DS Code remembers
      which item was selected for a specific prefix &#40;partial text&#41;. For
      example, if you typed co and then selected console, the next time you
      typed co, the suggestion console would be pre-selected. This lets you
      quickly map various prefixes to different suggestions, for example co
      -&gt; console and con -&gt; const.
    </p>
    <h3 id="snippets-in-suggestions">Snippets in suggestions</h3>
    <p>
      By default, AVAP™ DS Code shows snippets and completion proposals in one
      widget. You can control the behavior with the editor.snippetSuggestions
      setting. To remove snippets from the suggestions widget, set the value to
      &quot;none&quot;. If you&#39;d like to see snippets, you can specify the
      order relative to suggestions; at the top &#40;&quot;top&quot;&#41;, at
      the bottom &#40;&quot;bottom&quot;&#41;, or inline ordered alphabetically
      &#40;&quot;inline&quot;&#41;. The default is &quot;inline&quot;.
    </p>
    <h3 id="key-bindings">Key bindings</h3>
    <p>
      The key bindings shown below are the default key bindings. You can change
      these in your keybindings.json file as described in{' '}
      <a href="/docs/getstarted/keybindings">Key Bindings</a>.
    </p>
    <p>
      <strong>Note:</strong> There are many more key bindings relating to
      IntelliSense. Open the <strong>Default Keyboard Shortcuts</strong> &#40;
      <strong>File</strong> &gt; <strong>Preferences</strong> &gt;{' '}
      <strong>Keyboard Shortcuts</strong>&#41; and search for
      &quot;suggest&quot;.
    </p>

    <pre className="">
      <code className="">
        [<br />
        <br />` `&#123;
        <br />
        <br />` `"key": "ctrl+space",
        <br />
        <br />` `"command": "editor.action.triggerSuggest",
        <br />
        <br />` `"when": "editorHasCompletionItemProvider &amp;&amp;
        editorTextFocus &amp;&amp; !editorReadonly"
        <br />
        <br />` `&#125;,
        <br />
        <br />` `&#123;
        <br />
        <br />` `"key": "ctrl+space",
        <br />
        <br />` `"command": "toggleSuggestionDetails",
        <br />
        <br />` `"when": "editorTextFocus &amp;&amp; suggestWidgetVisible"
        <br />
        <br />` `&#125;,
        <br />
        <br />` `&#123;
        <br />
        <br />` `"key": "ctrl+alt+space",
        <br />
        <br />` `"command": "toggleSuggestionFocus",
        <br />
        <br />` `"when": "editorTextFocus &amp;&amp; suggestWidgetVisible"
        <br />
        <br />` `&#125;
        <br />
        <br />]
      </code>
    </pre>
    <h2 id="enhance-completions-with-ai">Enhance completions with AI</h2>
    <p>
      In AVAP™ DS Code, you can enhance your coding with artificial intelligence
      &#40;AI&#41;, such as suggestions for lines of code or entire functions,
      fast documentation creation, and help creating code-related artifacts like
      tests.
    </p>
    <p>
      <a href="https://copilot.github.com/">GitHub Copilot</a> is an AI-powered
      code completion tool that helps you write code faster and smarter. You can
      use the{' '}
      <a href="https://marketplace.visualstudio.com/items?itemName=GitHub.copilot">
        GitHub Copilot extension
      </a>{' '}
      in VS Code to generate code, or to learn from the code it generates.
    </p>
    <p>
      ![GitHub Copilot extension in the VS Code Marketplace][IntelliSense demo]
      <a href="https://marketplace.visualstudio.com/items?itemName=GitHub.copilot"></a>
    </p>
    <p>
      You can learn more about how to get started with Copilot in the{' '}
      <a href="/docs/editor/github-copilot">Copilot documentation</a>.
    </p>
    <h2 id="troubleshooting">Troubleshooting</h2>
    <p>
      If you find IntelliSense has stopped working, the language service may not
      be running. Try restarting VS Code and this should solve the issue. If you
      are still missing IntelliSense features after installing a language
      extension, open an issue in the repository of the language extension.
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> For configuring and troubleshooting JavaScript
        IntelliSense, see the{' '}
        <a href="/docs/languages/javascript#_intellisense">
          JavaScript documentation
        </a>
        .
      </p>
    </blockquote>
    <p>
      A particular language extension may not support all the AVAP™ DS Code
      IntelliSense features. Review the extension&#39;s README to find out what
      is supported. If you think there are issues with a language extension, you
      can usually find the issue repository for an extension through the{' '}
      <a href="https://marketplace.visualstudio.com/vscode">
        AVAP™ DS Code Marketplace
      </a>
      . Navigate to the extension&#39;s Details page and select the{' '}
      <strong>Support</strong> link.
    </p>
    <h2 id="next-steps">Next steps</h2>
    <p>
      IntelliSense is just one of AVAP™ DS Code&#39;s powerful features. Read on
      to learn more:
    </p>
    <ul>
      <li>
        <a href="/docs/languages/javascript">JavaScript</a> - Get the most out
        of your JavaScript development, including configuring IntelliSense.
      </li>
      <li>
        <a href="/docs/nodejs/nodejs-tutorial">Node.js</a> - See an example of
        IntelliSense in action in the Node.js walkthrough.
      </li>
      <li>
        <a href="/docs/editor/debugging">Debugging</a> - Learn how to set up
        debugging for your application.
      </li>
      <li>
        <a href="/api/language-extensions/programmatic-language-features">
          Creating Language extensions
        </a>{' '}
        - Learn how to create extensions that add IntelliSense for new
        programming languages.
      </li>
      <li>
        <a href="/docs/editor/github-copilot">
          GitHub Copilot in AVAP™ DS Code
        </a>{' '}
        - Learn how to use AI with GitHub Copilot to enhance your coding.
      </li>
    </ul>
    <h2 id="common-questions">Common questions</h2>
    <h3 id="why-am-i-not-getting-any-suggestions">
      Why am I not getting any suggestions?
    </h3>
    <p>
      This can be caused by a variety of reasons. First, try restarting AVAP™ DS
      Code. If the problem persists, consult the language extension&#39;s
      documentation. For JavaScript specific troubleshooting, please see the{' '}
      <a href="/docs/languages/javascript#_intellisense">
        JavaScript language topic
      </a>
      .
    </p>
    <h3 id="why-am-i-not-seeing-method-and-variable-suggestions">
      Why am I not seeing method and variable suggestions?
    </h3>
    <p>
      This issue is caused by missing type declaration &#40;typings&#41; files
      in JavaScript. You can check if a type declaration file package is
      available for a specific library by using the{' '}
      <a href="https://microsoft.github.io/TypeSearch">TypeSearch</a> site.
      There is more information about this issue in the{' '}
      <a href="/docs/languages/javascript#_intellisense">
        JavaScript language topic
      </a>
      . For other languages, please consult the extension&#39;s documentation.
    </p>
  </Fragment>
);
export default USER_GUIDE_IntelliSense;
