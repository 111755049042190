import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { Accordion, Nav, Row, Col, Image, Button } from 'react-bootstrap';
import ficha_user from '../../assets/img/pastilla2a.png';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SupportModal from '../Modals/supportModal';
import SessionStorageHandler from '../../handlers/SessionStorageHandler';
import SessionService from '../../services/SessionsService';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom';

const cookies = new Cookies();

const analyticsTags = [
  'G-MQZQJN7TQL',
  'G-98LF8608ZM',
  'G-KKCW4SZLW4',
  'G-EL6DCZWCM7',
  'G-5LKNTQFGD8',
  'G-11ZHMK4Y4Z',
  'G-PDYER55NCM'
];

//ReactGA.initialize('UA-170480071-2');
//analyticsTags.forEach(tag => {
//  ReactGA.initialize(tag);
//});

const handleLogout = (e) => {
  e.preventDefault();
  e.stopPropagation();
  SessionService.logout();
  const history = useHistory();
  history.push('/login');

}

const SupportBanner:FunctionComponent<{}> = () => {
  const [isLoggedIn, setLogged] = useState(false);
  const [showBannerOrSupportButton, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activedSupport, setActivedSupport] = useState(false);

  const checkForActivedSupport = async () => {
    try {
      const actived = (await ObexRequestHandler.get('/active_support_plan')).data;
      setActivedSupport(actived);

      

      


      if (actived) {
        const supportScript = document.getElementById('ze-snippet') as HTMLIFrameElement;

        

        if (!supportScript) { // si no existe el script
         
          const script = document.createElement('iframe');

          script.id = 'ze-snippet-2';
          script.src = 'https://cdn.smooch.io/message-us/index.html?channel=whatsapp&color=teal&size=standard&radius=4px&label=ISSUE360 on WhatsApp&number=525544593433';
          //script.async = true;
          
          //document.body.appendChild(script);
          const wast = document.getElementById('issue360') as HTMLDivElement;
          wast.style.display = 'unset';
          
        }
      } else {
        const wast = document.getElementById('issue360') as HTMLDivElement;
        wast.style.display = 'none';
        
      }
      setShow(true);
    } catch (error) {
      console.error('ERROR GETTING ACTIVED SUPPORT ', error);
    }
  }

  useEffect(() => {
    
    ReactGA.pageview(window.location.pathname + window.location.search);

    const user = cookies.get('101Obex');
    const elem2 = document.getElementById('SUPPORT_TITLE');
    //console.log('AQUI EN EFFECTS');
    //console.log(elem2);
    if (user){
      if (performance.navigation.type == performance.navigation.TYPE_RELOAD) 
      {
        SessionService.renewSession();
      }
      setLogged(true);} else setLogged(false)

    checkForActivedSupport();  
    return () => {
      try {        
        //const supportScript = document.getElementById('ze-snippet') as HTMLScriptElement;
        //supportScript.parentElement.removeChild(supportScript);
        //const supportButton = document.getElementById('launcher') as HTMLIFrameElement;
        //supportButton.parentElement.removeChild(supportButton);
      } catch (error) {
        console.error('error removing support button')
      }
    }
  }, []);


  return (
    <Fragment >
      { (!activedSupport && showBannerOrSupportButton) &&
        <Fragment>

      {cookies.get('internal_banner') == 'true'?

          <><div id="SUPPORT" className="box-support mt-auto text-center px-4" style={{ marginLeft:'-14px',position: 'fixed', bottom: 0, width: '281px', height:'25px', zIndex:999999999 }}>
              
              <span id="SUPPORT_TITLE" style={{ fontSize: '20px', color: 'white', cursor: 'pointer', /*visibility: 'hidden'*/ }}>Support ISSUE360</span>
<span
  onClick={() => {

    const elem = document.getElementById('SUPPORT');
    const elem1 = document.getElementById('SUPPORT_TITLE');
    const elem2 = document.getElementById('logo_support');

    //console.log(elem.style.height);
    if (elem.style.height == '25px') {
      elem1.style.visibility = 'hidden'
      elem.style.height = '';
      elem2.style.visibility = 'true';
      cookies.remove('external_banner');
      cookies.set('internal_banner', 'false');
    } else {
      elem.style.height = '25px';
      elem2.style.visibility = 'false';
      cookies.remove('external_banner');
      cookies.set('internal_banner', 'true');
      elem1.style.visibility = 'visible'
    }


    
  } }
  style={{ fontSize: '20px', color: 'white', cursor: 'pointer', marginLeft: '40px', marginRight: '-40px', display: 'absolute' }}>-</span>
              <Col md="5" className="my-3 mx-auto">
                <Image id='logo_support' src={ficha_user} fluid />
              </Col>
              <Col md="12" className="title-box mb-3">
                <span style={{color:'black'}} >ISSUE360<br></br></span>
                <span style={{color:'black'}} >Official Support</span>
              </Col>
              <Col md="12" className="text-box mb-3">
                <span>Service and support from the people who know 101OBeX products best.</span>
              </Col>
              <Col md="12" className="mb-3">
                <Button className="custom-button-blue w-100 textBold" onClick={() => setShowModal(true)}>Get Support</Button>
              </Col>
            </div>{ false && <SupportModal
                shown={showModal}
                onCloseAction={() => setShowModal(false)}
                onSuccess={checkForActivedSupport} />}</>

          :

<><div id="SUPPORT" className="box-support mt-auto text-center px-4" style={{ marginLeft:'-14px',zIndex:999999999,position: 'fixed', bottom: 0, width: '281px', backgroundColor:'#f4f4f4f4' }}>
<span id="SUPPORT_TITLE" style={{ fontSize: '20px', color: 'white', cursor: 'pointer', visibility: 'hidden' }}>Support ISSUE360</span>
<span
  onClick={() => {

    const elem = document.getElementById('SUPPORT');
    const elem1 = document.getElementById('SUPPORT_TITLE');
    const elem2 = document.getElementById('logo_support');

    //console.log(elem.style.height);
    if (elem.style.height == '25px') {
      elem1.style.visibility = 'hidden'
      elem.style.height = '';
      elem2.style.visibility = 'true';
      cookies.remove('external_banner');
      cookies.set('internal_banner', 'false');
    } else {
      elem.style.height = '25px';
      elem2.style.visibility = 'false';
      cookies.remove('external_banner');
      cookies.set('internal_banner', 'true');
      elem1.style.visibility = 'visible'
    }


    
  } }
  style={{ fontSize: '20px', color: 'black', cursor: 'pointer', marginLeft: '40px', marginRight: '-40px', display: 'absolute' }}>-</span>
<Col md="8" className="my-3 mx-auto">
  <Image id='logo_support' src={ficha_user} fluid />
</Col>
<Col md="12" className="title-box mb-3">
  <span style={{color:'black'}}>ISSUE360<br></br></span>
  <span style={{color:'black'}}>Official Support</span>
</Col>
<Col md="12" className="text-box mb-3">
  <span style={{color:'gray'}}>Service and support from the people who know AVAP products best.</span>
</Col>
<Col md="12" className="mb-3">
  <Button className="custom-button-blue w-100 textBold" onClick={() => setShowModal(true)}>Get Support</Button>
</Col>
</div>{false && <SupportModal
  shown={showModal}
  onCloseAction={() => setShowModal(false)}
  onSuccess={checkForActivedSupport} />}</>

}
        </Fragment>
      }
    </Fragment>
    
  )
};

export default SupportBanner;