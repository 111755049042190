import React, { Fragment } from 'react';

const GET_STARTED_Tips_and_tricks = () => (
  <Fragment>
    <h1 id="avap™-dev-studio-2024-tips-and-tricks">
      AVAP™ Dev Studio 2024 Tips and Tricks
    </h1>
    <p>
      &quot;Tips and Tricks&quot; lets you jump right in and learn how to be
      productive with AVAP™ Dev Studio 2024. You&#39;ll become familiar with its
      powerful editing, code intelligence, and source code control features and
      learn useful keyboard shortcuts. This topic goes pretty fast and provides
      a broad overview, so be sure to look at the other in-depth topics in
      Getting Started and the User Guide to learn more.
    </p>
    <blockquote>
      <p>
        If you don&#39;t have AVAP<sup>TM</sup> Dev Studio installed, go to the
        Download page. You can find platform specific setup instructions at
        Running AVAP<sup>TM</sup> Dev Studio on Linux, macOS, and Windows.
      </p>
    </blockquote>
    <h3 id="basics">Basics</h3>
    <h4 id="getting-started">Getting started.</h4>
    <p>
      The best way of exploring AVAP<sup>TM</sup> Dev Studio hands-on is to open
      the Welcome page. You will get an overview of AVAP<sup>TM</sup> Dev
      Studio&#39;s customizations and features. Help &gt; Welcome.
    </p>
    <p>
      <img src="http://101obex.static.mooo.com/03-K-Help.png" alt="03-K-Help" />
    </p>
    <p>
      Pick a <strong>Walkthrough</strong> for a self-guided tour through the
      setup steps, features, and deeper customizations that AVAP<sup>TM</sup>{' '}
      Dev Studio offers. As you discover and learn, the walkthroughs track your
      progress.
    </p>
    <p>
      If you are looking to improve your code editing skills open the{' '}
      <strong>Interactive Editor Playground</strong>. Try out AVAP<sup>TM</sup>{' '}
      Dev Studio&#39;s code editing features, like multi-cursor editing,
      IntelliSense, Snippets, Emmet, and many more.{' '}
      <strong>Help &gt; Editor Playground</strong>.
    </p>
    <p>
      <img
        src="http://101obex.static.mooo.com/03-K-playground.png"
        alt="03-K-playground"
      />
    </p>
    <h4 id="command-palette">Command Palette</h4>
    <p>Access all available commands based on your current context.</p>
    <p>Keyboard Shortcut: Ctrl+Shift+P</p>
    <p>
      <img
        src="http://101obex.static.mooo.com/03-K-shortcutGif.gif"
        alt="shortcut"
      />
    </p>
    <h4 id="default-keyboard-shortcuts">Default keyboard shortcuts</h4>
    <p>
      All of the commands are in the Command Palette with the associated key
      binding &#40;if it exists&#41;. If you forget a keyboard shortcut, use the
      Command Palette to help you out.
      <img
        src="http://101obex.static.mooo.com/03-K-shortcut.png"
        alt="shortcut"
      />
    </p>
    <h4 id="keyboard-reference-sheets">Keyboard reference sheets</h4>
    <p>
      Download the keyboard shortcut reference sheet for your platform
      &#40;macOS, Windows, Linux&#41;.
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/KeyboardReferenceSheet.png"
        alt=""
      />
    </p>
    <h4 id="quick-open">Quick Open</h4>
    <p>Quickly open files.</p>
    <p>
      Keyboard Shortcut: <code>Ctrl+P</code>
      <img
        src="http://101obex.static.mooo.com/03-K-openfile.gif"
        alt="openfile"
      />
    </p>
    <p>
      <strong>Tip</strong>: Type <code>?</code> to view command suggestions.
    </p>
    <p>
      <img
        src="http://101obex.static.mooo.com/03-K-comandsuggestions.png"
        alt=""
      />
    </p>
    <p>
      Typing commands such as edt and term followed by a space will bring up
      dropdown lists.
    </p>
    <p>
      <img src="http://101obex.static.mooo.com/03-K-term.png" alt="" />
    </p>
    <h4 id="navigate-between-recently-opened-files">
      Navigate between recently opened files
    </h4>
    <p>
      Repeat the <strong> Quick Open</strong> keyboard shortcut to cycle quickly
      between recently opened files.
    </p>
    <h4 id="open-multiple-files-from-quick-open">
      Open multiple files from Quick Open
    </h4>
    <p>
      You can open multiple files from <strong>Quick Open</strong> by pressing
      the Right arrow key. This will open the currently selected file in the
      background and you can continue selecting files from{' '}
      <strong>Quick Open</strong>.
    </p>
    <h4 id="navigate-between-recently-opened-folders-and-workspaces">
      Navigate between recently opened folders and workspaces
    </h4>
    <p>Open Recent</p>
    <p>
      Keyboard Shortcut: <code>Ctrl+R</code>
    </p>
    <p>
      Displays a Quick Pick dropdown with the list from <strong>File</strong>{' '}
      &gt; <strong>Open Recent</strong> with recently opened folders and
      workspaces followed by files.
    </p>
    <h3 id="command-line">Command line</h3>
    <p>
      AVAP<sup>TM</sup> Dev Studio has a powerful command line interface
      &#40;CLI&#41; which allows you to customize how the editor is launched to
      support various scenarios.
    </p>
    <blockquote>
      <p>
        Make sure the AVAP<sup>TM</sup> Dev Studio binary is on your path so you
        can simply type &#39;code&#39; to launch AVAP<sup>TM</sup> Dev Studio.
        See the platform specific setup topics if AVAP<sup>TM</sup> Dev Studio
        is added to your environment path during installation &#40;Running AVAP
        <sup>TM</sup> Dev Studio on Linux, macOS, Windows&#41;.
      </p>
    </blockquote>

    <pre className="">
      <code className="">
        # open code with current directory
        <br />
        code .<br />
        <br /># open the current directory in the most recently used code window
        <br />
        code -r .<br />
        <br /># create a new window
        <br />
        code -n
        <br />
        <br /># change the language
        <br />
        code --locale=es
        <br />
        <br /># open diff editor
        <br />
        code --diff &lt;file1&gt; &lt;file2&gt;
        <br />
        <br /># open file at specific line and column &lt;file:line[:character]&gt;
        <br />
        code --goto package.json:10:5
        <br />
        <br /># see help options
        <br />
        code --help
        <br />
        <br /># disable all extensions
        <br />
        code --disable-extensions .
      </code>
    </pre>
    <h4 id="avapcode-folder">.avapcode folder</h4>
    <p>
      Workspace specific files are in a .avapcode folder at the root. For
      example, tasks.json for the Task Runner and launch.json for the debugger.{' '}
    </p>
    <h3 id="status-bar">Status Bar</h3>
    <h4 id="errors-and-warnings">Errors and warnings</h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+Shift+M</code>
    </p>
    <p>Quickly jump to errors and warnings in the project.</p>
    <p>
      Cycle through errors with <code>F8</code> or <code>Shift+F8</code>
    </p>
    <p>
      <img
        src="http://101obex.static.mooo.com/03-K-errorsAndWarnings.gif"
        alt=""
      />
      You can filter problems either by type &#40;&#39;errors&#39;,
      &#39;warnings&#39;&#41; or text matching.
    </p>
    <h4 id="change-language-mode">Change language mode</h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+K M</code>
      <img
        src="http://101obex.static.mooo.com/03-K-lenguagemode.gif"
        alt="lenguagemode"
      />
    </p>
    <p>
      If you want to persist the new language mode for that file type, you can
      use the <strong>Configure File Association</strong> for command to
      associate the current file extension with an installed language.
    </p>
    <h3 id="customization">Customization</h3>
    <p>
      There are many things you can do to customize AVAP<sup>TM</sup> Dev .
    </p>
    <ul>
      <li>Change your theme</li>
      <li>Change your keyboard shortcuts</li>
      <li>Tune your settings</li>
      <li>Add JSON validation</li>
      <li>Create snippets</li>
      <li>Install extensions</li>
    </ul>
    <h4 id="change-your-theme">Change your theme</h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+K Ctrl+T</code>
    </p>
    <p>
      You can install more themes from the AVAP<sup>TM</sup> Dev Studio
      extension Marketplace.
      <img src="http://101obex.static.mooo.com/03-K-theme.gif" alt="theme" />
    </p>
    <h4 id="keymaps">Keymaps</h4>
    <p>
      Are you used to keyboard shortcuts from another editor? You can install a
      Keymap extension that brings the keyboard shortcuts from your favorite
      editor to AVAP<sup>TM</sup> Dev Studio. Go to <strong>Preferences</strong>{' '}
      &gt; <strong>Migrate Keyboard Shortcuts from</strong>... to see the
      current list on the Marketplace. Some of the more popular ones:
    </p>
    <ul>
      <li>Vim</li>
      <li>Sublime Text Keymap</li>
      <li>Emacs Keymap</li>
      <li>Atom Keymap</li>
      <li>Brackets Keymap</li>
      <li>Eclipse Keymap</li>
      <li>AVAP™ Dev Studio Keymap</li>
    </ul>
    <h4 id="customize-your-keyboard-shortcuts">
      Customize your keyboard shortcuts
    </h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+K Ctrl+S</code>
      <img
        src="http://101obex.static.mooo.com/03-K-customizeKeyboard.png"
        alt=""
      />
    </p>
    <p>
      You can search for shortcuts and add your own keybindings to the
      keybindings.json file.
    </p>
    <p>
      <img
        src="http://101obex.static.mooo.com/03-K-shortcuts-json.gif"
        alt=""
      />
    </p>
    <p>
      See more in Key Bindings for AVAP<sup>TM</sup> Dev Studio.
    </p>
    <h4 id="tune-your-settings">Tune your settings</h4>
    <p>
      By default AVAP<sup>TM</sup> Dev Studio shows the Settings editor, you can
      find settings listed below in a search bar, but you can still edit the
      underlying settings.json file by using the{' '}
      <strong>Open User Settings &#40;JSON&#41;</strong> command or by changing
      your default settings editor with the workbench.settings.editor setting.
    </p>
    <p>Open User Settings settings.json</p>
    <p>
      Keyboard Shortcut: <code>Ctrl+,</code>
    </p>
    <p>Change the font size of various UI elements</p>

    <pre className="">
      <code className="">
        // Main editor
        <br />
        "editor.fontSize": 18,
        <br />
        // Terminal panel
        <br />
        "terminal.integrated.fontSize": 14,
        <br />
        // Output panel
        <br />
        "[Log]": &#123;
        <br /> "editor.fontSize": 15
        <br />
        &#125;
      </code>
    </pre>
    <p>Change the zoom level</p>

    <pre className="">
      <code className="">"window.zoomLevel": 5</code>
    </pre>
    <p>Font ligatures</p>

    <pre className="">
      <code className="">
        "editor.fontFamily": "Fira Code",
        <br />
        "editor.fontLigatures": true
      </code>
    </pre>
    <blockquote>
      <p>
        <strong>Tip</strong>: You will need to have a font installed that
        supports font ligatures. FiraCode is a popular font on the AVAP DS Code
        team.
      </p>
    </blockquote>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/font-ligatures-annotated.png"
        alt=""
      />
    </p>
    <p>Auto Save</p>

    <pre className="">
      <code className="">"files.autoSave": "afterDelay"</code>
    </pre>
    <p>
      You can also toggle Auto Save from the top-level menu with the File &gt;
      Auto Save.
    </p>
    <p>Format on save</p>

    <pre className="">
      <code className="">"editor.formatOnSave": true</code>
    </pre>
    <p>Format on paste</p>

    <pre className="">
      <code className="">"editor.formatOnPaste": true</code>
    </pre>
    <p>Change the size of Tab characters</p>

    <pre className="">
      <code className="">"editor.tabSize": 4</code>
    </pre>
    <p>Spaces or Tabs</p>

    <pre className="">
      <code className="">"editor.insertSpaces": true</code>
    </pre>
    <p>Render whitespace</p>

    <pre className="">
      <code className="">"editor.renderWhitespace": "all"</code>
    </pre>
    <p>Whitespace characters are rendered by default in text selection.</p>
    <p>Ignore files / folders</p>
    <p>Removes these files / folders from your editor window.</p>

    <pre className="">
      <code className="">
        "files.exclude": &#123;
        <br /> "somefolder/": true,
        <br /> "somefile": true
        <br />
        &#125;
      </code>
    </pre>
    <p>Remove these files / folders from search results.</p>

    <pre className="">
      <code className="">
        "search.exclude": &#123;
        <br /> "someFolder/": true,
        <br /> "somefile": true
        <br />
        &#125;
      </code>
    </pre>
    <p>And many, many other customizations.</p>
    <h4 id="language-specific-settings">Language specific settings</h4>
    <p>
      You can scope the settings that you only want for specific languages by
      the language identifier. You can find a list of commonly used language IDs
      in the Language Identifiers reference.
    </p>

    <pre className="">
      <code className="">
        "[languageid]": &#123;
        <br />
        <br />
        &#125;
      </code>
    </pre>
    <blockquote>
      <p>
        <strong>Tip</strong>: You can also create language specific settings
        with the Configure Language Specific Settings command.
      </p>
    </blockquote>
    <p>
      <img
        src="http://101obex.static.mooo.com/03-K-LanguageSpecificSettings.png"
        alt=""
      />
    </p>
    <h4 id="add-json-validation">Add JSON validation</h4>
    <p>
      Enabled by default for many file types. Create your own schema and
      validation in settings.json
    </p>

    <pre className="">
      <code className="">
        "json.schemas": [<br /> &#123;
        <br /> "fileMatch": [<br /> "/bower.json"
        <br /> ],
        <br /> "url": "https://json.schemastore.org/bower"
        <br /> &#125;
        <br />]
      </code>
    </pre>
    <p>or for a schema defined in your workspace</p>

    <pre className="">
      <code className="">
        "json.schemas": [<br /> &#123;
        <br /> "fileMatch": [<br /> "/foo.json"
        <br /> ],
        <br /> "url": "./myschema.json"
        <br /> &#125;
        <br />]
      </code>
    </pre>
    <p>or a custom schema</p>

    <pre className="">
      <code className="">
        "json.schemas": [<br /> &#123;
        <br /> "fileMatch": [<br /> "/.myconfig"
        <br /> ],
        <br /> "schema": &#123;
        <br /> "type": "object",
        <br /> "properties": &#123;
        <br /> "name" : &#123;
        <br /> "type": "string",
        <br /> "description": "The name of the entry"
        <br /> &#125;
        <br /> &#125;
        <br /> &#125;
        <br /> &#125;
        <br />]
      </code>
    </pre>
    <p>See more in the JSON documentation.</p>
    <h3 id="extensions">Extensions</h3>
    <p>
      Keyboard Shortcut: <code>Ctrl+Shift+X</code>
    </p>
    <h4 id="find-extensions">Find extensions</h4>
    <ol>
      <li>
        In the AVAP<sup>TM</sup> Dev Studio Marketplace.
      </li>
      <li>
        Search inside AVAP<sup>TM</sup> Dev Studio in the{' '}
        <strong>Extensions</strong> view.
      </li>
      <li>View extension recommendations</li>
      <li>Community curated extension lists, such as awesome-avapdscode.</li>
    </ol>
    <h4 id="install-extensions">Install extensions</h4>
    <p>
      In the <strong>Extensions</strong> view, you can search via the search bar
      or click the <strong>More Actions</strong> &#40;...&#41; button to filter
      and sort by install count.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/show-popular-extensions.png"
        alt=""
      />
    </p>
    <h4 id="extension-recommendations">Extension recommendations</h4>
    <p>
      In the <strong>Extensions</strong> view, click{' '}
      <strong>Show Recommended Extensions</strong> in the{' '}
      <strong>More Actions</strong> &#40;...&#41; button menu.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/show-recommended-extensions.png"
        alt=""
      />
    </p>
    <h4 id="creating-my-own-extension">Creating my own extension</h4>
    <p>
      Are you interested in creating your own extension? You can learn how to do
      this in the Extension API documentation, specifically check out the
      documentation on contribution points.
    </p>
    <ul>
      <li>configuration</li>
      <li>commands</li>
      <li>keybindings</li>
      <li>languages</li>
      <li>debuggers</li>
      <li>grammars</li>
      <li>themes</li>
      <li>snippets</li>
      <li>jsonValidation</li>
    </ul>
    <h3 id="files-and-folders">Files and folders</h3>
    <h4 id="integrated-terminal">Integrated Terminal</h4>
    <p>Keyboard Shortcut: Ctrl+`</p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/integrated_terminal.png"
        alt=""
      />
    </p>
    <p>Further reading:</p>
    <ul>
      <li>Integrated Terminal documentation</li>
      <li>Mastering AVAP™ DS&#39;s Terminal article</li>
    </ul>
    <h4 id="toggle-sidebar">Toggle Sidebar</h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+B</code>
    </p>
    <p>
      <img src="http://101obex.static.mooo.com/03-K-ToggleSidebar.gif" alt="" />
    </p>
    <h4 id="toggle-panel">Toggle Panel</h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+J</code>
    </p>
    <h4 id="zen-mode">Zen mode</h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+K Z</code>
    </p>
    <p>
      <img src="http://101obex.static.mooo.com/03-K-zenmode.gif" alt="" />
    </p>
    <p>Enter distraction free Zen mode.</p>
    <p>
      Press <code>Esc</code> twice to exit Zen Mode.
    </p>
    <h4 id="side-by-side-editing">Side by side editing</h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+\</code>
    </p>
    <p>
      You can also drag and drop editors to create new editor groups and move
      editors between groups.
    </p>
    <p>
      <img
        src="http://101obex.static.mooo.com/03-K-SideBySideEditing.gif"
        alt=""
      />
    </p>
    <h4 id="switch-between-editors">Switch between editors</h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+1</code>, <code>Ctrl+2</code>,{' '}
      <code>Ctrl+3</code>
    </p>
    <p>
      <img
        src="http://101obex.static.mooo.com/03-K-switchBetweenEditors.gif"
        alt=""
      />
    </p>
    <h4 id="move-to-explorer-window">Move to Explorer window</h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+Shift+E</code>
    </p>
    <h4 id="create-or-open-a-file">Create or open a file</h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+click</code> &#40;<code>Cmd+click</code> on
      macOS&#41;
    </p>
    <p>
      You can quickly open a file or image or create a new file by moving the
      cursor to the file link and using <code>Ctrl+click</code>.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/create_open_file.gif"
        alt=""
      />
    </p>
    <h4 id="close-the-currently-opened-folder">
      Close the currently opened folder
    </h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+K F</code>
    </p>
    <h4 id="navigation-history">Navigation history</h4>
    <p>
      Navigate entire history: <code>Ctrl+Tab</code>
    </p>
    <p>
      Navigate back: <code>Alt+Left</code>
    </p>
    <p>
      Navigate forward: <code>Alt+Right</code>
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/navigate_history.gif"
        alt=""
      />
    </p>
    <h4 id="file-associations">File associations</h4>
    <p>
      Create language associations for files that aren&#39;t detected correctly.
      For example, many configuration files with custom file extensions are
      actually JSON.
    </p>

    <pre className="">
      <code className="">
        "files.associations": &#123;
        <br /> ".database": "json"
        <br />
        &#125;
      </code>
    </pre>
    <h4 id="preventing-dirty-writes">Preventing dirty writes</h4>
    <p>
      AVAP<sup>TM</sup> Dev Studio will show you an error message when you try
      to save a file that cannot be saved because it has changed on disk. AVAP
      <sup>TM</sup> Dev Studio blocks saving the file to prevent overwriting
      changes that have been made outside of the editor.
    </p>
    <p>
      In order to resolve the save conflict, click the Compare action in the
      error message to open a diff editor that will show you the contents of the
      file on disk &#40;to the left&#41; compared to the contents in AVAP
      <sup>TM</sup> Dev Studio &#40;on the right&#41;:
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/dirty-write.png"
        alt=""
      />
    </p>
    <p>
      Use the actions in the editor toolbar to resolve the save conflict. You
      can either <strong>Accept</strong> your changes and thereby overwriting
      any changes on disk, or <strong>Revert</strong> to the version on disk.
      Reverting means that your changes will be lost.
    </p>
    <p>
      <strong>Note</strong>: The file will remain dirty and cannot be saved
      until you pick one of the two actions to resolve the conflict.
    </p>
    <h3 id="editing-hacks">Editing Hacks</h3>
    <p>
      Here is a selection of common features for editing code. If the keyboard
      shortcuts aren&#39;t comfortable for you, consider installing a keymap
      extension for your old editor.
    </p>
    <p>
      <strong>Tip</strong>: You can see recommended keymap extensions in the{' '}
      <strong>Extensions</strong> view by filtering the search to
      @recommended:keymaps.
    </p>
    <h4 id="multi-cursor-selection">Multi cursor selection</h4>
    <p>
      To add cursors at arbitrary positions, select a position with your mouse
      and use <code>Alt+Click</code> &#40;<code>Option+Click</code> on
      macOS&#41;.
    </p>
    <p>To set cursors above or below the current position use:</p>
    <p>
      Keyboard Shortcut: <code>Ctrl+Alt+Up</code> or <code>Ctrl+Alt+Down</code>
    </p>
    <p>
      <img
        src="http://101obex.static.mooo.com/03-K-multicursorSelection.gif"
        alt=""
      />
    </p>
    <p>
      You can add additional cursors to all occurrences of the current selection
      with Ctrl+Shift+L.
    </p>
    <p>
      <img
        src="http://101obex.static.mooo.com/03-K-add_cursor_current_selection.gif"
        alt=""
      />
    </p>
    <blockquote>
      <p>
        Note: You can also change the modifier to Ctrl/Cmd for applying multiple
        cursors with the editor.multiCursorModifier setting . See Multi-cursor
        Modifier for details.
      </p>
    </blockquote>
    <p>
      If you do not want to add all occurrences of the current selection, you
      can use Ctrl+D instead. This only selects the next occurrence after the
      one you selected so you can add selections one by one.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/add_cursor_current_selection_one_by_one.gif"
        alt=""
      />
    </p>
    <h4 id="column-box-selection">Column &#40;box&#41; selection</h4>
    <p>
      You can select blocks of text by holding <code>Shift+Alt</code> &#40;
      <code>Shift+Option</code> on macOS&#41; while you drag your mouse. A
      separate cursor will be added to the end of each selected line.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/column-select.gif"
        alt=""
      />
    </p>
    <p>You can also use keyboard shortcuts to trigger column selection.</p>
    <h4 id="vertical-rulers">Vertical rulers</h4>
    <p>
      You can add vertical column rulers to the editor with the editor.rulers
      setting, which takes an array of column character positions where
      you&#39;d like vertical rulers.
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br /> "editor.rulers": [20, 40, 60]
        <br />
        &#125;
      </code>
    </pre>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/editor-rulers.png"
        alt=""
      />
    </p>
    <h4 id="fast-scrolling">Fast scrolling</h4>
    <p>
      Pressing the Alt key enables fast scrolling in the editor and Explorers.
      By default, fast scrolling uses a 5X speed multiplier but you can control
      the multiplier with the *<em>Editor: Fast Scroll Sensitivity *</em>
      &#40;editor.fastScrollSensitivity&#41; setting.
    </p>
    <h4 id="copy-line-up--down">Copy line up / down</h4>
    <p>
      Keyboard Shortcut: <code>Shift+Alt+Up</code> or{' '}
      <code>Shift+Alt+Down</code>
    </p>
    <blockquote>
      <p>
        The commands <strong>Copy Line Up/Down</strong> are unbound on Linux
        because the AVAP<sup>TM</sup> Dev Studio default keybindings would
        conflict with Ubuntu keybindings, see Issue #509. You can still set the
        commands editor.action.copyLinesUpAction and
        editor.action.copyLinesDownAction to your own preferred keyboard
        shortcuts.
        <img
          src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/copy_line_down.gif"
          alt=""
        />
      </p>
    </blockquote>
    <h4 id="move-line-up-and-down">Move line up and down</h4>
    <p>
      Keyboard Shortcut: <code>Alt+Up</code> or <code>Alt+Down</code>
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/move_line.gif"
        alt=""
      />
    </p>
    <h4 id="shrink--expand-selection">Shrink / expand selection</h4>
    <p>
      Keyboard Shortcut: <code>Shift+Alt+Left</code> or{' '}
      <code>Shift+Alt+Right</code>
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/shrink_expand_selection.gif"
        alt=""
      />
    </p>
    <p>You can learn more in the Basic Editing documentation.</p>
    <h4 id="go-to-symbol-in-file">Go to Symbol in File</h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+Shift+O</code>
    </p>
    <p>
      <img
        src="http://101obex.static.mooo.com/03-K-find_by_symbol.gif"
        alt=""
      />
    </p>
    <p>You can group the symbols by kind by adding a colon, @:.</p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/group_symbols_by_kind.png"
        alt=""
      />
    </p>
    <h4 id="go-to-symbol-in-workspace">Go to Symbol in Workspace</h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+T</code>
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/go_to_symbol_in_workspace.png"
        alt=""
      />
    </p>
    <h4 id="outline-view">Outline view</h4>
    <p>
      The Outline view in the File Explorer &#40;default collapsed at the
      bottom&#41; shows you the symbols of the currently open file.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/outline-view.png"
        alt=""
      />
    </p>
    <p>
      You can sort by symbol name, category, and position in the file and allows
      quick navigation to symbol locations.
    </p>
    <h4 id="navigate-to-a-specific-line">Navigate to a specific line</h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+G</code>
    </p>
    <h4 id="undo-cursor-position">Undo cursor position</h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+U</code>
    </p>
    <h4 id="trim-trailing-whitespace">Trim trailing whitespace</h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+K Ctrl+X</code>
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/trim_whitespace.gif"
        alt=""
      />
    </p>
    <h4 id="code-formatting">Code formatting</h4>
    <p>
      Currently selected source code: <code>Ctrl+K Ctrl+F</code>
    </p>
    <p>
      Whole document format: <code>Shift+Alt+F</code>
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/code_formatting.gif"
        alt=""
      />
    </p>
    <h4 id="code-folding">Code folding</h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+Shift+[</code> and <code>Ctrl+Shift+]</code>
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/code_folding.gif"
        alt=""
      />
    </p>
    <p>
      You can also fold/unfold all regions in the editor with Fold All &#40;
      <code>Ctrl+K Ctrl+0</code>&#41; and Unfold All &#40;
      <code>Ctrl+K Ctrl+J</code>&#41;.
    </p>
    <p>
      You can fold all block comments with Fold All Block Comments &#40;
      <code>Ctrl+K Ctrl+/</code>&#41;.
    </p>
    <h4 id="select-current-line">Select current line</h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+L</code>
    </p>
    <h4 id="navigate-to-beginning-and-end-of-file">
      Navigate to beginning and end of file
    </h4>
    <p>
      Keyboard Shortcut: <code>Ctrl+Home</code> and <code>Ctrl+End</code>
    </p>
    <h4 id="open-markdown-preview">Open Markdown preview</h4>
    <p>In a Markdown file, use</p>
    <p>
      Keyboard Shortcut: <code>Ctrl+Shift+V</code>
    </p>
    <h4 id="side-by-side-markdown-edit-and-preview">
      Side by side Markdown edit and preview
    </h4>
    <p>In a Markdown file, use</p>
    <p>
      Keyboard Shortcut: <code>Ctrl+K V</code>
    </p>
    <p>
      The preview and editor will synchronize with your scrolling in either
      view.
    </p>
    <h3 id="intellisense">IntelliSense</h3>
    <p>
      <code>Ctrl+Space</code> to trigger the Suggestions widget.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/intellisense.gif"
        alt=""
      />
    </p>
    <p>
      You can view available methods, parameter hints, short documentation, etc.
    </p>
    <h4 id="peek">Peek</h4>
    <p>
      Select a symbol then type <code>Alt+F12</code>. Alternatively, you can use
      the context menu.
    </p>
    <h4 id="go-to-definition">Go to Definition</h4>
    <p>
      Select a symbol then type <code>F12</code>. Alternatively, you can use the
      context menu or <code>Ctrl+click</code> &#40;<code>Cmd+click</code> on
      macOS&#41;.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/goto_definition.gif"
        alt=""
      />
    </p>
    <p>
      You can go back to your previous location with the <strong>Go</strong>{' '}
      &gt; <strong>Back</strong> command or <code>Alt+Left</code>.
    </p>
    <p>
      You can also see the type definition if you press <code>Ctrl</code> &#40;
      <code>Cmd</code> on macOS&#41; when you are hovering over the type.
    </p>
    <h4 id="go-to-references">Go to References</h4>
    <p>
      Select a symbol then type <code>Shift+F12</code>. Alternatively, you can
      use the context menu.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/find_all_references.gif"
        alt=""
      />
    </p>
    <h4 id="find-all-references-view">Find All References view</h4>
    <p>
      Select a symbol then type <code>Shift+Alt+F12</code> to open the
      References view showing all your file&#39;s symbols in a dedicated view.
    </p>
    <h4 id="rename-symbol">Rename Symbol</h4>
    <p>
      Select a symbol then type <code>F2</code>. Alternatively, you can use the
      context menu.
    </p>
    <p>rename symbol</p>
    <h4 id="search-and-modify">Search and modify</h4>
    <p>
      Besides searching and replacing expressions, you can also search and reuse
      parts of what was matched, using regular expressions with capturing
      groups. Enable regular expressions in the search box by clicking the{' '}
      <strong>Use Regular Expression</strong> .* button &#40;<code>Alt+R</code>
      &#41; and then write a regular expression and use parentheses to define
      groups. You can then reuse the content matched in each group by using $1,
      $2, etc. in the Replace field.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/search_and_modify.png"
        alt=""
      />
    </p>
    <h4 id="eslintrcjson">.eslintrc.json</h4>
    <p>
      Install the ESLint extension. Configure your linter however you&#39;d
      like. Consult the ESLint specification for details on its linting rules
      and options.
    </p>
    <p>Here is configuration to use ES6.</p>

    <pre className="">
      <code className="">
        &#123;
        <br /> "env": &#123;
        <br /> "browser": true,
        <br /> "commonjs": true,
        <br /> "es6": true,
        <br /> "node": true
        <br /> &#125;,
        <br /> "parserOptions": &#123;
        <br /> "ecmaVersion": 6,
        <br /> "sourceType": "module",
        <br /> "ecmaFeatures": &#123;
        <br /> "jsx": true,
        <br /> "classes": true,
        <br /> "defaultParams": true
        <br /> &#125;
        <br /> &#125;,
        <br /> "rules": &#123;
        <br /> "no-const-assign": 1,
        <br /> "no-extra-semi": 0,
        <br /> "semi": 0,
        <br /> "no-fallthrough": 0,
        <br /> "no-empty": 0,
        <br /> "no-mixed-spaces-and-tabs": 0,
        <br /> "no-redeclare": 0,
        <br /> "no-this-before-super": 1,
        <br /> "no-undef": 1,
        <br /> "no-unreachable": 1,
        <br /> "no-use-before-define": 0,
        <br /> "constructor-super": 1,
        <br /> "curly": 0,
        <br /> "eqeqeq": 0,
        <br /> "func-names": 0,
        <br /> "valid-typeof": 1<br /> &#125;
        <br />
        &#125;
      </code>
    </pre>
    <h4 id="packagejson">package.json</h4>
    <p>See IntelliSense for your package.json file.</p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/package_json_intellisense.gif"
        alt=""
      />
    </p>
    <h4 id="emmet-syntax">Emmet syntax</h4>
    <p>Support for Emmet syntax.</p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/emmet_syntax.gif"
        alt=""
      />
    </p>
    <h3 id="snippets">Snippets</h3>
    <h4 id="create-custom-snippets">Create custom snippets</h4>
    <p>
      <strong>File</strong> &gt; <strong>Preferences</strong> &gt;{' '}
      <strong>Configure User Snippets</strong>, select the language, and create
      a snippet.
    </p>

    <pre className="">
      <code className="">
        "create component": &#123;
        <br /> "prefix": "component",
        <br /> "body": [<br /> "class $1 extends React.Component &#123;",
        <br /> "",
        <br /> "\trender&#40;&#41; &#123;",
        <br /> "\t\treturn &#40;$2&#41;;",
        <br /> "\t&#125;",
        <br /> "",
        <br /> "&#125;"
        <br /> ]<br />
        &#125;,
      </code>
    </pre>
    <p>See more details in Creating your own Snippets.</p>
    <h3 id="git-integration">Git integration</h3>
    <p>
      Keyboard Shortcut: <code>Ctrl+Shift+G</code>
    </p>
    <p>
      Git integration comes with AVAP<sup>TM</sup> Dev Studio
      &quot;out-of-the-box&quot;. You can install other SCM providers from the
      Extension Marketplace. This section describes the Git integration but much
      of the UI and gestures are shared by other SCM providers.
    </p>
    <h4 id="diffs">Diffs</h4>
    <p>From the Source Control view, select a file to open the diff.</p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/msee-changes.gif"
        alt=""
      />
    </p>
    <p>
      Alternatively, click the <strong>Open Changes</strong> button in the top
      right corner to diff the current open file.
    </p>
    <p>
      <strong>Views</strong>
    </p>
    <p>
      The default view for diffs is the <strong>side by side view</strong>.
    </p>
    <p>
      Toggle <strong>inline view</strong> by clicking the{' '}
      <strong>More Actions</strong> &#40;...&#41; button in the top right and
      selecting <strong>Toggle Inline View</strong>.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/mdiff-switch-to-inline.png"
        alt=""
      />
    </p>
    <p>
      If you prefer the inline view, you can set
      &quot;diffEditor.renderSideBySide&quot;: false.
    </p>
    <p>
      <strong>Accessible Diff Viewer</strong>
    </p>
    <p>
      Navigate through diffs with <code>F7</code> and <code>Shift+F7</code>.
      This will present them in a unified patch format. Lines can be navigated
      with arrow keys and pressing <code>Enter</code> will jump back in the diff
      editor and the selected line.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/diff_review_pane.png"
        alt=""
      />
    </p>
    <p>
      <strong>Edit pending changes</strong>
      You can make edits directly in the pending changes of the diff view.
    </p>
    <h4 id="branches">Branches</h4>
    <p>Easily switch between Git branches via the Status Bar.</p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/mswitch-branch.gif"
        alt=""
      />
    </p>
    <h4 id="staging">Staging</h4>
    <p>
      <strong>Stage file changes</strong>
    </p>
    <p>Hover over the number of files and click the plus button.</p>
    <p>Click the minus button to unstage changes.</p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/mstage-unstage.gif"
        alt=""
      />
    </p>
    <p>
      <strong>Stage selected</strong>
    </p>
    <p>
      Stage a portion of a file by selecting that file &#40;using the
      arrows&#41; and then choosing <strong>Stage Selected Ranges</strong> from
      the <strong>Command Palette</strong>.
    </p>
    <h4 id="undo-last-commit">Undo last commit</h4>
    <p>
      Click the &#40;...&#41; button and then select{' '}
      <strong>Undo Last Commit</strong> to undo the previous commit. The changes
      are added to the Staged Changes section.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/mundo-last-commit.gif"
        alt=""
      />
    </p>
    <h4 id="see-git-output">See Git output</h4>
    <p>
      AVAP<sup>TM</sup> Dev Studio makes it easy to see what Git commands are
      actually running. This is helpful when learning Git or debugging a
      difficult source control issue.
    </p>
    <p>
      Use the <strong>Toggle Output</strong> command &#40;
      <code>Ctrl+Shift+U</code>&#41; and select <strong>Git</strong> in the
      dropdown.
    </p>
    <h4 id="gutter-indicators">Gutter indicators</h4>
    <p>View diff decorations in editor. See documentation for more details.</p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/mgutter_icons.gif"
        alt=""
      />
    </p>
    <h4 id="resolve-merge-conflicts">Resolve merge conflicts</h4>
    <p>
      During a merge, go to the <strong>Source Control</strong> view &#40;
      <code>Ctrl+Shift+G</code>&#41; and make changes in the diff view.
    </p>
    <p>
      You can resolve merge conflicts with the inline CodeLens which lets you{' '}
      <strong>Accept Current Change</strong>,{' '}
      <strong>Accept Incoming Change</strong>,{' '}
      <strong>Accept Both Changes</strong>, and <strong>Compare Changes</strong>
      .
    </p>
    <h4 id="set-avap-code-as-default-merge-tool">
      Set AVAP Code as default merge tool
    </h4>

    <pre className="">
      <code className="">
        git config --global merge.tool vscode
        <br />
        git config --global mergetool.vscode.cmd 'code --wait $MERGED'
      </code>
    </pre>
    <h4 id="set-avap-code-as-default-diff-tool">
      Set AVAP Code as default diff tool
    </h4>

    <pre className="">
      <code className="">
        git config --global diff.tool vscode
        <br />
        git config --global difftool.vscode.cmd 'code --wait --diff $LOCAL
        $REMOTE'
      </code>
    </pre>
    <h3 id="debugging">Debugging</h3>
    <h4 id="configure-debugger">Configure debugger</h4>
    <p>
      From the Run and Debug view &#40;<code>Ctrl+Shift+D</code>&#41;, select{' '}
      <strong>create a launch.json file</strong>, which will prompt you to
      select the environment that matches your project &#40;Node.js, Python,
      C++, etc&#41;. This will generate a launch.json file. Node.js support is
      built-in and other environments require installing the appropriate
      language extensions. See the debugging documentation for more details.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/configure-debug.png"
        alt=""
      />
    </p>
    <h4 id="breakpoints-and-stepping-through">
      Breakpoints and stepping through
    </h4>
    <p>
      Place breakpoints next to the line number. Navigate forward with the Debug
      widget.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/node_debug.gif"
        alt=""
      />
    </p>
    <h4 id="breakpoints-and-stepping-through-1">
      Breakpoints and stepping through
    </h4>
    <p>
      Place breakpoints next to the line number. Navigate forward with the Debug
      widget.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/node_debug.gif"
        alt=""
      />
    </p>
    <h4 id="data-inspection">Data inspection</h4>
    <p>
      Inspect variables in the <strong>Run</strong> panels and in the console.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/debug_data_inspection.gif"
        alt=""
      />
    </p>
    <h4 id="logpoints">Logpoints</h4>
    <p>
      Logpoints act much like breakpoints but instead of halting the debugger
      when they are hit, they log a message to the console. Logpoints are
      especially useful for injecting logging while debugging production servers
      that cannot be modified or paused.
    </p>
    <p>
      Add a logpoint with the <strong>Add Logpoint</strong> command in the left
      editor gutter and it will be displayed as a &quot;diamond&quot; shaped
      icon. Log messages are plain text but can include expressions to be
      evaluated within curly braces &#40;&#39;&#123;&#125;&#39;&#41;.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/logpoint.png"
        alt=""
      />
    </p>
    <h4 id="triggered-breakpoints">Triggered breakpoints</h4>
    <p>
      A trigged breakpoint is a breakpoint that is automatically enabled once
      another breakpoint is hit. They can be very useful when diagnosing failure
      cases in code that happen only after a certain precondition.
    </p>
    <p>
      Triggered breakpoints can be set by right-clicking on the glyph margin,
      selecting <strong>Add Triggered Breakpoint</strong>, and then choosing
      which other breakpoint enables the breakpoint.
    </p>
    <p>
      <a href="http://https://code.visualstudio.com/assets/docs/editor/debugging/debug-triggered-breakpoint.mp4">
        http://https://code.visualstudio.com/assets/docs/editor/debugging/debug-triggered-breakpoint.mp4
      </a>
    </p>
    <h3 id="task-runner">Task runner</h3>
    <h4 id="auto-detect-tasks">Auto detect tasks</h4>
    <p>
      Select <strong>Terminal</strong> from the top-level menu, run the command{' '}
      <strong>Configure Tasks</strong>, then select the type of task you&#39;d
      like to run. This will generate a tasks.json file with content like the
      following. See the Tasks documentation for more details.
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br /> // See https://go.microsoft.com/fwlink/?LinkId=733558
        <br /> // for the documentation about the tasks.json format
        <br /> "version": "2.0.0",
        <br /> "tasks": [<br /> &#123;
        <br /> "type": "npm",
        <br /> "script": "install",
        <br /> "group": &#123;
        <br /> "kind": "build",
        <br /> "isDefault": true
        <br /> &#125;
        <br /> &#125;
        <br /> ]<br />
        &#125;
      </code>
    </pre>
    <p>
      There are occasionally issues with auto generation. Check out the
      documentation for getting things to work properly.
    </p>
    <h4 id="run-tasks-from-the-terminal-menu">
      Run tasks from the Terminal menu
    </h4>
    <p>
      Select <strong>Terminal</strong> from the top-level menu, run the command{' '}
      <strong>Run Task</strong>, and select the task you want to run. Terminate
      the running task by running the command <strong>Terminate Task</strong>
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/task_runner.gif"
        alt=""
      />
    </p>
    <h4 id="define-keyboard-shortcuts-for-tasks">
      Define keyboard shortcuts for tasks
    </h4>
    <p>
      You can define a keyboard shortcut for any task. From the{' '}
      <strong>Command Palette</strong> &#40;<code>Ctrl+Shift+P</code>&#41;,
      select <strong>Preferences: Open Keyboard Shortcuts File</strong>, bind
      the desired shortcut to the workbench.action.tasks.runTask command, and
      define the <strong>Task</strong> as args.
    </p>
    <p>
      For example, to bind <code>Ctrl+H</code> to the Run tests task, add the
      following:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br /> "key": "ctrl+h",
        <br /> "command": "workbench.action.tasks.runTask",
        <br /> "args": "Run tests"
        <br />
        &#125;
        <br />
        Run npm s
      </code>
    </pre>
    <h4 id="run-npm-scripts-as-tasks-from-the-explorer">
      Run npm scripts as tasks from the Explorer
    </h4>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/tips-and-tricks/script_explorer.png"
        alt=""
      />
    </p>
    <p>
      From the explorer you can open a script in the editor, run it as a task,
      and launch it with the node debugger &#40;when the script defines a debug
      option like --inspect-brk&#41;. The default action on click is to open the
      script. To run a script on a single click, set npm.scriptExplorerAction to
      &quot;run&quot;. Use the setting npm.exclude to exclude scripts in
      package.json files contained in particular folders.
    </p>
    <p>
      With the setting npm.enableRunFromFolder, you can enable to run npm
      scripts from the File Explorer&#39;s context menu for a folder. The
      setting enables the command <strong>Run NPM Script in Folder</strong>...
      when a folder is selected. The command shows a Quick Pick list of the npm
      scripts contained in this folder and you can select the script to be
      executed as a task.
    </p>
    <h3 id="portable-mode">Portable mode</h3>
    <p>
      AVAP<sup>TM</sup> Dev Studio has a Portable mode which lets you keep
      settings and data in the same location as your installation, for example,
      on a USB drive.
    </p>
    <h3 id="insiders-builds">Insiders builds</h3>
    <p>
      The AVAP™ Dev Studio Code team uses the Insiders version to test the
      latest features and bug fixes of AVAP™ DS. You can also use the Insiders
      version by downloading it here.
    </p>
    <ul>
      <li>
        For Early Adopters - Insiders has the most recent code changes for users
        and extension authors to try out.
      </li>
      <li>
        Frequent Builds - New builds every day with the latest bug fixes and
        features.
      </li>
      <li>
        Side-by-side install - Insiders installs next to the Stable build
        allowing you to use either independently.
      </li>
    </ul>
  </Fragment>
);
export default GET_STARTED_Tips_and_tricks;
