import React, { Fragment } from 'react';

const SETUP_MacOS = () => (
  <Fragment>
    <h1 id="avap™-dev-studio-2024-on-macos">AVAP™ Dev Studio 2024 on macOS</h1>
    <h2 id="installation">Installation</h2>
    <ol>
      <li>Download AVAP™ Dev Studio 2024 for macOS.</li>
      <li>
        Open the browser&#39;s download list and locate the downloaded app or
        archive.
      </li>
      <li>
        If archive, extract the archive contents. Use double-click for some
        browsers or select the &#39;magnifying glass&#39; icon with Safari.
      </li>
      <li>
        Drag <code>AVAP™ Dev Studio 2024.app</code> to the{' '}
        <strong>Applications</strong> folder, making it available in the macOS
        Launchpad.
      </li>
      <li>
        Open AVAP™ Dev Studio from the <strong>Applications</strong> folder, by
        double clicking the icon.
      </li>
      <li>
        Add AVAP™ Dev Studio to your Dock by right-clicking on the icon, located
        in the Dock, to bring up the context menu and choosing{' '}
        <strong>Options, Keep in Dock.</strong>
      </li>
    </ol>
    <h2 id="launching-from-the-command-line">
      Launching from the command line
    </h2>
    <p>
      You can also run AVAP™ Dev Studio from the terminal by typing
      &#39;code&#39; after adding it to the path:
    </p>
    <ul>
      <li>Launch AVAP™ Dev Studio.</li>
      <li>
        Open the <strong>Command Palette</strong>{' '}
        <code>&#40;Cmd+Shift+P&#41;</code> and type &#39;shell command&#39; to
        find the{' '}
        <strong>Shell Command: Install &#39;code&#39; command in PATH</strong>{' '}
        command.
      </li>
    </ul>
    <p>##IMAGEN##</p>
    <ul>
      <li>
        Restart the terminal for the new <code>$PATH</code> value to take
        effect. You&#39;ll be able to type &#39;code .&#39; in any folder to
        start editing files in that folder.
      </li>
    </ul>
    <blockquote>
      <p>
        <strong>Note:</strong> If you still have the old <code>code</code> alias
        in your <code>.bash_profile</code> &#40;or equivalent&#41; from an early
        AVAP™ DS 2024 version, remove it and replace it by executing the{' '}
        <strong>Shell Command: Install &#39;code&#39; command in PATH</strong>{' '}
        command.
      </p>
    </blockquote>
    <h4 id="alternative-manual-instructions">
      Alternative manual instructions
    </h4>
    <p>
      Instead of running the command above, you can manually add AVAP™ Dev
      Studio to your path, to do so run the following commands:
    </p>

    <pre className="">
      <code className="">
        cat &lt;&lt; EOF &gt;&gt; ~/.bash_profile
        <br />
        Add AVAP™ Dev Studio 2024 &#40;code&#41;
        <br />
        export PATH="\$PATH:/Applications/AVAP™ Dev Studio
        2024.app/Contents/Resources/app/bin"
        <br />
        EOF
      </code>
    </pre>
    <p>
      Start a new terminal to pick up your <code>.bash_profile</code> changes.
    </p>
    <p>
      <strong>Note:</strong> The leading slash \ is required to prevent{' '}
      <code>$PATH</code> from expanding during the concatenation. Remove the
      leading slash if you want to run the export command directly in a
      terminal.
    </p>
    <p>
      <strong>Note:</strong> Since <code>zsh</code> became the default shell in
      macOS Catalina, run the following commands to add AVAP™ Dev Studio to your
      path:
    </p>

    <pre className="">
      <code className="">
        `cat &lt;&lt; EOF &gt;&gt; ~/.zprofile
        <br />
        Add AVAP™ Dev Studio 2024 &#40;code&#41;
        <br />
        export PATH="\$PATH:/Applications/AVAP™ Dev Studio
        2024.app/Contents/Resources/app/bin"
        <br />
        EOF`
      </code>
    </pre>
    <h2 id="touch-bar-support">Touch Bar support</h2>
    <p>
      Out of the box AVAP™ Dev Studio adds actions to navigate in editor history
      as well as the full Debug tool bar to control the debugger on your Touch
      Bar:
    </p>
    <p>##IMAGEN## </p>
    <h2 id="mojave-privacy-protections">Mojave privacy protections</h2>
    <p>
      After upgrading to macOS Mojave version, you may see dialogs saying
      &quot;AVAP™ Dev Studio 2024 would like to access your
      &#123;calendar/contacts/photos&#125;.&quot; This is due to the new privacy
      protections in Mojave and is not specific to AVAP™ Dev Studio. The same
      dialogs may be displayed when running other applications as well. The
      dialog is shown once for each type of personal data and it is fine to
      choose <strong>Don&#39;t Allow</strong> since AVAP™ Dev Studio does not
      need access to those folders.
    </p>
    <h2 id="updates">Updates</h2>
    <p>
      AVAP™ Dev Studio ships monthly releases and supports auto-update when a
      new release is available. If you&#39;re prompted by AVAP™ Dev Studio,
      accept the newest update and it will get installed &#40;you won&#39;t need
      to do anything else to get the latest bits&#41;.
    </p>
    <blockquote>
      <p>
        Note: You can disable auto-update if you prefer to update AVAP™ Dev
        Studio on your own schedule.
      </p>
    </blockquote>
    <h2 id="preferences-menu">Preferences menu</h2>
    <p>
      You can configure AVAP™ Dev Studio through settings, color themes, and
      custom keybindings available through the{' '}
      <strong>Code &gt; Preferences</strong> menu group.
    </p>
    <h2 id="next-steps">Next steps</h2>
    <p>
      Once you have installed AVAP™ Dev Studio, these topics will help you learn
      more about AVAP™ Dev Studio:
    </p>
    <ul>
      <li>
        Additional Components - Learn how to install Git, Node.js, TypeScript,
        and tools like Yeoman.
      </li>
      <li>User Interface - A quick orientation around AVAP™ Dev Studio.</li>
      <li>
        User/Workspace Settings - Learn how to configure AVAP™ Dev Studio to
        your preferences settings.
      </li>
    </ul>
    <h2 id="common-questions">Common questions</h2>
    <h4 id="why-do-i-see-avap™-dev-studio-2024-would-like-access-to-your-calendar">
      Why do I see &quot;AVAP™ Dev Studio 2024 would like access to your
      calendar.&quot;
    </h4>
    <p>
      If you are running macOS Mojave version, you may see dialogs saying
      &quot;AVAP™ Dev Studio 2024 would like to access your
      &#123;calendar/contacts/photos&#125;.&quot; This is due to the new privacy
      protections in Mojave discussed above. It is fine to choose{' '}
      <strong>Don&#39;t Allow</strong> since AVAP™ Dev Studio does not need
      access to those folders.
    </p>
    <h4 id="avap™-dev-studio-fails-to-update">
      AVAP™ Dev Studio fails to update
    </h4>
    <p>
      If VS Code doesn&#39;t update once it restarts, it might be set under
      quarantine by macOS. Follow the steps in this issue for resolution.
    </p>
    <h4 id="does-avap™-ds-2024-run-on-apple-silicon-machines">
      Does AVAP™ DS 2024 run on Apple silicon machines?
    </h4>
    <p>
      Yes, AVAP™ Dev Studio supports macOS Arm64 builds that can run on Macs
      with the Apple silicon chipsets. You can install the Universal build,
      which includes both Intel and Apple silicon builds, or one of the platform
      specific builds.
    </p>
  </Fragment>
);
export default SETUP_MacOS;
