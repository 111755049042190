import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Leer_notificaciones_del_usuario_EN = () => 
 {
  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{ 
        "status": <boolean>, 
        "codtran": <string>, 
        "result": { 
          "messages": [
             { "hour": <string>, 
             "destination": {
               "user_id": <integer>, 
               "name": <string> }, 
               "read": <boolean>, 
               "origin": { 
                "user_id": <integer>, 
                "name": <string> }, "date": <string>, 
                "subject": <string>, "id": <integer>, 
                "hourdate": <string>, "text": <string> } ]
                 "there_is_more": <boolean> }, "elapsed": <float> 
                }`,
      copied: false
    },
    answe_ko1: {
      title:"Answer ok",
      type: "json",
      code: `{
         "status": false,
          "level": <string>,
           "message": <string>, 
           "error": <string> 
          }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: `import requests url = "URL_BASE/ws/notificaciones.py/leer_notificaciones" payload = { 'country_code': 'MX', 'user_id': '4532', 'session_id': '418-VC6Et5UdloC7Q3ia6aP9Ng==', 'only_pending': 'true', 'mark_as_read': 'true' }
       files = [] 
       headers= {} 
       response = requests.request("POST", url, headers=headers, data = payload, files = files) 
       print(response.text.encode('utf8'))`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests url = "URL_BASE/ws/notificaciones.py/leer_notificaciones" payload = { 'country_code': 'MX', 'user_id': '4532', 'session_id': '418-VC6Et5UdloC7Q3ia6aP9Ng==', 'only_pending': 'true', 'mark_as_read': 'true' }
        files = [] 
        headers= {} 
        response = requests.request("POST", url, headers=headers, data = payload, files = files) 
        print(response.text.encode('utf8'))`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: `var request = require('request');
            var options = {
            'method': 'POST',
            'url': 'URL_BASE/ws/notificaciones.py/leer_notificaciones',
            'headers': {},
            formData: {
            'country_code': 'MX',
            'user_id': '4532',
            'session_id': '418-VC6Et5UdloC7Q3ia6aP9Ng==',
            'only_pending': 'true',
            'mark_as_read': 'true'
            }
            };
            request(options, function (error, response) { 
            if (error) throw new Error(error);
            console.log(response.body);
            });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: `var formdata = new FormData();
            formdata.append("country_code", "MX");
            formdata.append("user_id", "4532");
            formdata.append("session_id", "418-VC6Et5UdloC7Q3ia6aP9Ng==");
            formdata.append("only_pending", "true");
            formdata.append("mark_as_read", "true");
            var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
            };
            fetch("URL_BASE/ws/notificaciones.py/leer_notificaciones", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: `curl --location --request POST 'URL_BASE/ws/notificaciones.py/leer_notificaciones' \ --form 'country_code=MX' \ --form 'user_id=4532' \ --form 'session_id=418-VC6Et5UdloC7Q3ia6aP9Ng==' \ --form 'only_pending=true' \ --form 'mark_as_read=true'`
      }
    ]
  }
  return(
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
      <h1 id="read-all-users-notifications">Read All Users&#39; Notifications</h1>
      <p>This service is used to read all or several user notifications.</p>
      <p>
        <span className="label post">POST:</span>
        <span> </span>
        <code>URL_BASE + /ws/notificaciones.py/leer_notificaciones</code>
      </p>
      <h2 id="receives">Receives:</h2>
      <p>
        All the parameters that the service receives must be indicated in the body
        of the request.
      </p>
      <div className='overflow-auto'>
      <table>
        <thead>
          <tr>
            <th align="left">Parameter</th>
            <th align="center">Format</th>
            <th align="center">Obligatory</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
            <th align="center">Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="left">country_code</td>
            <td align="center">
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                ISO 3166 alpha-2
              </a>
            </td>
            <td align="center">Yes</td>
            <td align="center">Country code in which the user resides</td>
            <td align="center">N/A</td>
            <td align="center"></td>
          </tr>
          <tr>
            <td align="left">user_id</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">
              Identifier of the user making the request &#40;Admin or
              Affiliate&#41;
            </td>
            <td align="center">N/A</td>
            <td align="center"></td>
          </tr>
          <tr>
            <td align="left">session_id</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send signature to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Obtained by identifying the user with ID user_id on the platform
            </td>
            <td align="center">N/A</td>
            <td align="center"></td>
          </tr>
          <tr>
            <td align="left">signature</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send session_id to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Enables you to sign the request without having to log in
            </td>
            <td align="center">N/A</td>
            <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
          </tr>
          <tr>
            <td align="left">only_pending</td>
            <td align="center">Boolean</td>
            <td align="center">No</td>
            <td align="center">Returns pending user notifications</td>
            <td align="center">true</td>
            <td align="center">true</td>
          </tr>
          <tr>
            <td align="left">mark_as_read</td>
            <td align="center">Boolean</td>
            <td align="center">No</td>
            <td align="center">Sets pending user notifications as read</td>
            <td align="center">true</td>
            <td align="center">true</td>
          </tr>
          <tr>
            <td align="left">from</td>
            <td align="center">
              String dd/mm/yyyy, yyyy/mm/dd, dd-mm-yyyy, yyyy-mm-dd
            </td>
            <td align="center">Yes</td>
            <td align="center">
              Notifications are obtained from the indicated date
            </td>
            <td align="center">N/A</td>
            <td align="center">2019-10-12</td>
          </tr>
          <tr>
            <td align="left">until</td>
            <td align="center">
              String dd/mm/yyyy, yyyy/mm/dd, dd-mm-yyyy, yyyy-mm-dd
            </td>
            <td align="center">Yes</td>
            <td align="center">
              Notifications are obtained until the indicated date
            </td>
            <td align="center">N/A</td>
            <td align="center">2020-06-9</td>
          </tr>
          <tr>
            <td align="left">page</td>
            <td align="center">Integer</td>
            <td align="center">No</td>
            <td align="center">Requested page number</td>
            <td align="center">1</td>
            <td align="center">1</td>
          </tr>
          <tr>
            <td align="left">maximum_per_page</td>
            <td align="center">Integer</td>
            <td align="center">No</td>
            <td align="center">Number of results per page</td>
            <td align="center">10</td>
            <td align="center">10</td>
          </tr>
        </tbody>
      </table>
      </div>
      <h2 id="returns">Returns:</h2>
      <p>
        Depending on the result of the operation, this service can return two
        different JSON:
      </p>
      <h3 id="answer-json-ok">Answer JSON OK:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
      <h2 id="where">Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;
        </li>
        <li>
          <code>codtran:</code> Transaction code that identifies the executed
          operation
        </li>
        <li>
          <code>result:</code> Answer of the executed service
        </li>
        <li>
          <code>messages:</code> List of notifications sent.
        </li>
        <li>
          <code>hour:</code> Notification creation timestamp .
        </li>
        <li>
          <code>destination:</code> Container object for the data of the
          notification target group.
        </li>
        <li>
          <code>user_id:</code> Identifier of the user to whom the notification
          has been delivered.
        </li>
        <li>
          <code>name:</code> Name of the user to whom the notification has been
          delivered.
        </li>
        <li>
          <code>read:</code> Indicates whether or not the user has read the
          notification.
        </li>
        <li>
          <code>origin:</code> Container object for the claim creator data.
        </li>
        <li>
          <code>user_id:</code> Identifier of the user who created the
          notification.
        </li>
        <li>
          <code>name:</code> Name of the user who created the notification.
        </li>
        <li>
          <code>date:</code> Notification creation timestamp.
        </li>
        <li>
          <code>subject:</code> Notification subject.
        </li>
        <li>
          <code>id:</code> Notification identifier.
        </li>
        <li>
          <code>hourdate:</code> Notification creation timestamp.
        </li>
        <li>
          <code>text:</code> Notification message.
        </li>
        <li>
          <code>there_is_more:</code> Indicates if there are notifications left,
          to request another page.
        </li>
        <li>
          <code>elapsed:</code> Operation execution time.
        </li>
      </ul>
      <h3 id="answer-json-ko">Answer JSON KO:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
      <h2 id="where-1">Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li>
          <code>level:</code> Error importance level.
        </li>
        <li>
          <code>message:</code> Error message.
        </li>
        <li>
          <code>error:</code> Sole error code.
        </li>
      </ul>
      <h2 id="business-logic">Business logic:</h2>
      <p>
        By means of this service, notifications can be marked as read by the user
        who makes the request.
      </p>
      </div>

<div className='col-md-5'>
      <h2 id="example-requests">Example requests:</h2>
      <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
      
      </div>
      </div>
    </Fragment>
  );
 }
export default Leer_notificaciones_del_usuario_EN;
