import React, { Fragment } from 'react';

const SOURCE_CONTROL_CollaborateOnGitHub = () => (
  <Fragment>
    <h1 id="working-with-github-in-avap™-dev-studio">
      Working with GitHub in AVAP™ Dev Studio
    </h1>
    <p>
      GitHub is a cloud-based service for storing and sharing source code. Using
      GitHub with AVAP™ Dev Studio lets you share your source code and
      collaborate with others right within your editor. There are many ways to
      interact with GitHub, for example, via their website at{' '}
      <a href="https://github.com">https://github.com</a> or the [Git]
      command-line interface &#40;CLI&#41;, but in AVAP™ Dev Studio, the rich
      GitHub integration is provided by the GitHub Pull Requests and Issues
      extension.
    </p>
    <p>[Install the GitHub Pull Requests and Issues extension]</p>
    <p>
      To get started with the GitHub in AVAP<sup>TM</sup> Dev Studio, you&#39;ll
      need to install Git, create a GitHub account and install the GitHub Pull
      Requests and Issues extension. In this topic, we&#39;ll demonstrate how
      you can use some of your favorite parts of GitHub without leaving AVAP™
      Dev Studio.
    </p>
    <p>
      If you&#39;re new to source control or want to learn more about AVAP™ Dev
      Studio&#39;s basic Git support, you can start with the Source Control
      topic.
    </p>
    <h2 id="getting-started-with-github-pull-requests-and-issues">
      Getting started with GitHub Pull Requests and Issues
    </h2>
    <p>
      Once you&#39;ve installed the GitHub Pull Requests and Issues extension,
      you&#39;ll need to sign in. Follow the prompts to authenticate with GitHub
      in the browser and return to AVAP™ Dev Studio.
    </p>
    <p>
      If you are not redirected to AVAP<sup>TM</sup> Dev Studio, you can add
      your authorization token manually. In the browser window, you will receive
      your authorization token. Copy the token, and switch back to AVAP
      <sup>TM</sup> Dev Studio. Select{' '}
      <strong>Signing in to github.com...</strong> in the Status bar, paste the
      token, and hit Enter.
    </p>
    <h2 id="setting-up-a-repository">Setting up a repository</h2>
    <h3 id="cloning-a-repository">Cloning a repository</h3>
    <p>
      You can search for and clone a repository from GitHub using the{' '}
      <strong>Git: Clone</strong> command in the Command Palette
      &#40;Ctrl+Shift+P&#41; or by using the <strong>Clone Repository</strong>{' '}
      button in the Source Control view &#40;available when you have no folder
      open&#41;.
    </p>
    <p>
      From the GitHub repository dropdown you can filter and pick the repository
      you want to clone locally.
    </p>
    <h3 id="authenticating-with-an-existing-repository">
      Authenticating with an existing repository
    </h3>
    <p>
      Enabling authentication through GitHub happens when you run any Git action
      in AVAP<sup>TM</sup> Dev Studio that requires GitHub authentication, such
      as pushing to a repository that you&#39;re a member of or cloning a
      private repository. You don&#39;t need to have any special extensions
      installed for authentication; it is built into AVAP<sup>TM</sup> Dev
      Studio so that you can efficiently manage your repository.
    </p>
    <p>
      When you do something that requires GitHub authentication, you&#39;ll see
      a prompt to sign in:
    </p>
    <p>
      Follow the steps to sign into GitHub and return to AVAP<sup>TM</sup> Dev
      Studio. If authenticating with an existing repository doesn&#39;t work
      automatically, you may need to manually provide a personal access token.
      See About authentication to GitHub for more information and details about
      each option.{' '}
    </p>
    <p>
      Note that there are several ways to authenticate to GitHub, including
      using your username and password with two-factor authentication
      &#40;2FA&#41;, a personal access token, or an SSH key. See About
      authentication to GitHub for more information and details about each
      option.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: If you&#39;d like to work on a repository without
        cloning the contents to your local machine, you can install the GitHub
        Repositories extension to browse and edit directly on GitHub. You can
        learn more below in the GitHub Repositories extension section.
      </p>
    </blockquote>
    <h2 id="editor-integration">Editor integration</h2>
    <h3 id="hovers">Hovers</h3>
    <p>
      When you have a repository open and a user is @-mentioned, you can hover
      over that username and see a GitHub-style hover.
    </p>
    <p>
      There is a similar hover for #-mentioned issue numbers, full GitHub issue
      URLs, and repository specified issues.
    </p>
    <h3 id="suggestions">Suggestions</h3>
    <p>
      User suggestions are triggered by the &quot;@&quot; character and issue
      suggestions are triggered by the &quot;#&quot; character. Suggestions are
      available in the editor and in the <strong>Source Control</strong>{' '}
      view&#39;s input box.
    </p>
    <p>
      The issues that appear in the suggestion can be configured with the{' '}
      <strong>GitHub Issues: Queries</strong> &#40;githubIssues.queries&#41;
      setting. The queries use the GitHub search syntax.
    </p>
    <p>
      You can also configure which files show these suggestions using the
      settings <strong>GitHub Issues: Ignore Completion Trigger</strong> &#40;
      <code>githubIssues.ignoreCompletionTrigger</code>&#41; and{' '}
      <strong>GitHub Issues: Ignore User Completion Trigger</strong> &#40;
      <code>githubIssues.ignoreUserCompletionTrigger</code>&#41;. These settings
      take an array of language identifiers to specify the file types.
    </p>

    <pre className="">
      <code className="">
        // Languages that the '#' character should not be used to trigger issue
        completion suggestions.
        <br />
        "githubIssues.ignoreCompletionTrigger": [<br />` `"python"
        <br />]
      </code>
    </pre>
    <h2 id="pull-requests">Pull requests</h2>
    <p>
      From the <strong>Pull Requests</strong> view you can view, manage, and
      create pull requests.
    </p>
    <p>
      The queries used to display pull requests can be configured with the{' '}
      <strong>GitHub Pull Requests: Queries</strong> &#40;
      <code>githubPullRequests.queries</code>&#41; setting and use the GitHub
      search syntax.
    </p>

    <pre className="">
      <code className="">
        "githubPullRequests.queries": [<br />` `&#123;
        <br />` `"label": "Assigned To Me",
        <br />` `"query": "is:open assignee:$&#123;user&#125;"
        <br />` `&#125;,
      </code>
    </pre>
    <h3 id="creating-pull-requests">Creating Pull Requests</h3>
    <p>
      Once you have committed changes to your fork or branch, you can use the{' '}
      <strong>GitHub Pull Requests: Create Pull Request</strong> command or the{' '}
      <strong>Create Pull Request</strong> button in the{' '}
      <strong>Pull Requests</strong> view to create a pull request.
    </p>
    <p>
      A new <strong>Create</strong> view will be displayed where you can select
      the base repository and base branch you&#39;d like your pull request to
      target as well as fill in the title and description. If your repository
      has a pull request template, this will automatically be used for the
      description.
    </p>
    <p>
      Use the buttons in the action bar at the top to add{' '}
      <strong>Assignees</strong>, <strong>Reviewers</strong>,{' '}
      <strong>Labels</strong> and a <strong>Milestone</strong>.
    </p>
    <p>
      The <strong>Create</strong> button menu allows you to select alternative
      create options, such as <strong>Create Draft</strong> or enable an{' '}
      <strong>Auto-Merge</strong> method.
    </p>
    <p>
      Once you select <strong>Create</strong>, if you have not already pushed
      your branch to a GitHub remote, the extension will ask if you&#39;d like
      to publish the branch and provides a dropdown to select the specific
      remote.
    </p>
    <p>
      The <strong>Create Pull Request</strong> view now enters{' '}
      <strong>Review Mode</strong>, where you can review the details of the PR,
      add comments, and merge the PR once it&#39;s ready. After the PR is
      merged, you&#39;ll have the option to delete both the remote and local
      branch.
    </p>
    <h3 id="reviewing">Reviewing</h3>
    <p>
      Pull requests can be reviewed from the <strong>Pull Requests</strong>{' '}
      view. You can assign reviewers and labels, add comments, approve, close,
      and merge all from the pull request <strong>Description</strong>.
    </p>
    <p>
      From the <strong>Description</strong> page, you can also easily checkout
      the pull request locally using the <strong>Checkout</strong> button. This
      will switch AVAP™ Dev Studio to open the fork and branch of the pull
      request &#40;visible in the Status bar&#41; in Review Mode and add a new{' '}
      <strong>Changes in Pull Request</strong> view from which you can view
      diffs of the current changes as well as all commits and the changes within
      these commits. Files that have been commented on are decorated with a
      diamond icon. To view the file on disk, you can use the{' '}
      <strong>Open File</strong> inline action.
    </p>
    <p>
      The diff editors from this view use the local file, so file navigation,
      IntelliSense, and editing work as normal. You can add comments within the
      editor on these diffs. Both adding single comments and creating a whole
      review is supported.
    </p>
    <p>
      When you are done reviewing the pull request changes you can merge the PR
      or select <strong>Exit Review Mode</strong> to go back to the previous
      branch you were working on.
    </p>
    <h2 id="issues">Issues</h2>
    <h3 id="creating-issues">Creating issues</h3>
    <p>
      Issues can be created from the <strong>+</strong> button in the{' '}
      <strong>Issues</strong> view and by using the{' '}
      <strong>GitHub Issues: Create Issue from Selection</strong> and{' '}
      <strong>GitHub Issues: Create Issue from Clipboard</strong> commands. They
      can also be created using a Code Action for &quot;TODO&quot; comments.
      When creating issues, you can take the default description or select the{' '}
      <strong>Edit Description</strong> pencil icon in the upper right to bring
      up an editor for the issue body.
    </p>
    <p>
      You can configure the trigger for the Code Action using the{' '}
      <strong>GitHub Issues: Create Issue Triggers</strong>{' '}
      &#40;githubIssues.createIssueTriggers&#41; setting.
    </p>
    <p>The default issue triggers are:</p>

    <pre className="">
      <code className="">
        "githubIssues.createIssueTriggers": [<br />` `"TODO",
        <br />` `"todo",
        <br />` `"BUG",
        <br />` `"FIXME",
        <br />` `"ISSUE",
        <br />` `"HACK"
        <br />]
      </code>
    </pre>
    <h3 id="working-on-issues">Working on issues</h3>
    <p>
      From the <strong>Issues</strong> view, you can see your issues and work on
      them.
    </p>
    <p>
      By default, when you start working on an issue &#40;
      <strong>Start Working on Issue</strong> context menu item&#41;, a branch
      will be created for you, as shown in the Status bar in the image below.
    </p>
    <p>
      The Status bar also shows the active issue and if you select that item, a
      list of issue actions are available such as opening the issue on the
      GitHub website or creating a pull request.
    </p>
    <p>
      You can configure the name of the branch using the{' '}
      <strong>GitHub Issues: Issue Branch Title</strong> &#40;
      <code>githubIssues.issueBranchTitle</code>&#41; setting. If your workflow
      doesn&#39;t involve creating a branch, or if you want to be prompted to
      enter a branch name every time, you can skip that step by turning off the{' '}
      <strong>GitHub Issues: Use Branch For Issues</strong> &#40;
      <code>githubIssues.useBranchForIssues</code>&#41; setting.
    </p>
    <p>
      Once you are done working on the issue and want to commit a change, the
      commit message input box in the <strong>Source Control</strong> view will
      be populated with a message, which can be configured with{' '}
      <strong>GitHub Issues: Working Issue Format SCM</strong> &#40;
      <code>githubIssues.workingIssueFormatScm</code>&#41;.
    </p>
    <h2 id="github-repositories-extension">GitHub Repositories extension</h2>
    <p>
      The GitHub Repositories extension lets you quickly browse, search, edit,
      and commit to any remote GitHub repository directly from within AVAP
      <sup>TM</sup> Dev Studio, without needing to clone the repository locally.
      This can be fast and convenient for many scenarios, where you just need to
      review source code or make a small change to a file or asset.
    </p>
    <h3 id="opening-a-repository">Opening a repository</h3>
    <p>
      Once you have installed the GitHub Repositories extension, you can open a
      repository with the{' '}
      <strong>GitHub Repositories: Open Repository...</strong> command from the
      Command Palette &#40;Ctrl+Shift+P&#41; or by clicking the Remote indicator
      in the lower left of the Status bar.
    </p>
    <p>
      When you run the <strong>Open Repository</strong> command, you then choose
      whether to open a repository from GitHub, open a Pull Request from GitHub,
      or reopen a repository that you had previously connected to.
    </p>
    <p>
      If you haven&#39;t logged into GitHub from AVAP<sup>TM</sup> Dev Studio
      before, you&#39;ll be prompted to authenticate with your GitHub account.
    </p>
    <p>
      You can provide the repository URL directly or search GitHub for the
      repository you want by typing in the text box.
    </p>
    <p>
      Once you have selected a repository or Pull Request, the AVAP™ Dev Studio
      window will reload and you will see the repository contents in the File
      Explorer. You can then open files &#40;with full syntax highlighting and
      bracket matching&#41;, make edits, and commit changes, just like you would
      working on a local clone of a repository.
    </p>
    <p>
      One difference from working with a local repository is that when you
      commit a change with the GitHub Repository extension, the changes are
      pushed directly to the remote repository, similar to if you were working
      in the GitHub web interface.
    </p>
    <p>
      Another feature of the GitHub Repositories extension is that every time
      you open a repository or branch, you get the up-to-date sources available
      from GitHub. You don&#39;t need to remember to pull to refresh as you
      would with a local repository.
    </p>
    <p>
      The GitHub Repositories extension supports viewing and even committing
      LFS-tracked files without needing to install Git LFS &#40;Large File
      System&#41; locally. Add the file types you want tracked with LFS to a
      .gitattributes file, then commit your changes directly to GitHub using the
      Source Control view.
    </p>
    <h3 id="switching-branches">Switching branches</h3>
    <p>
      You can easily switch between branches by clicking on the branch indicator
      in the Status bar. One great feature of the GitHub Repositories extension
      is that you can switch branches without needing to stash uncommitted
      changes. The extension remembers your changes and reapplies them when you
      switch branches.
    </p>
    <h3 id="remote-explorer">Remote Explorer</h3>
    <p>
      You can quickly reopen remote repositories with the Remote Explorer
      available on the Activity bar. This view shows you the previously opened
      repositories and branches.
    </p>
    <h3 id="create-pull-requests">Create Pull Requests</h3>
    <p>
      If your workflow uses Pull Requests, rather than direct commits to a
      repository, you can create a new PR from the Source Control view.
      You&#39;ll be prompted to provide a title and create a new branch.
    </p>
    <p>
      Once you have created a Pull Request, you can use the GitHub Pull Request
      and Issues extension to review, edit, and merge your PR as described
      earlier in this topic.
    </p>
    <h3 id="virtual-file-system">Virtual file system</h3>
    <p>
      Without a repository&#39;s files on your local machine, the GitHub
      Repositories extension creates a virtual file system in memory so you can
      view file contents and make edits. Using a virtual file system means that
      some operations and extensions which assume local files are not enabled or
      have limited functionality. Features such as tasks, debugging, and
      integrated terminals are not enabled and you can learn about the level of
      support for the virtual file system via the{' '}
      <strong>features are not available</strong> link in the Remote indicator
      hover.
    </p>
    <p>
      Extension authors can learn more about running in a virtual file system
      and workspace in the Virtual Workspaces extension author&#39;s guide.
    </p>
    <h3 id="continue-working-on">Continue Working On...</h3>
    <p>
      Sometimes you&#39;ll want to switch to working on a repository in a
      development environment with support for a local file system and full
      language and development tooling. The GitHub Repositories extension makes
      it easy for you to:
    </p>
    <ul>
      <li>
        Create a GitHub codespace &#40;if you have the GitHub Codespaces
        extension&#41;.
      </li>
      <li>Clone the repository locally.</li>
      <li>
        Clone the repository into a Docker container &#40;if you have Docker and
        the Microsoft Docker extension installed&#41;.
      </li>
    </ul>
    <p>
      To switch development environments, use the{' '}
      <strong>Continue Working On...</strong> command, available from the
      Command Palette &#40;<code>Ctrl+Shift+P</code>&#41; or by clicking on the
      Remote indicator in the Status bar.
    </p>
    <p>
      If you are using the browser-based editor, the{' '}
      <strong>&quot;Continue Working On...&quot;</strong> command has the
      options to open the repository locally or within a cloud-hosted
      environment in GitHub Codespaces.
    </p>
    <p>
      The first time that you use <strong>Continue Working On</strong> with
      uncommitted changes, you will have the option to bring your edits to your
      selected development environment using <strong>Cloud Changes</strong>,
      which uses a AVAP™ Dev Studio service to store your pending changes.
    </p>
    <p>
      These changes are deleted from our service once they are applied to your
      target development environment. If you choose to continue without your
      uncommitted changes, you can always change this preference later by
      configuring the setting &quot;
      <code>workbench.cloudChanges.continueOn</code>&quot;: &quot;
      <code>prompt</code>&quot;.
    </p>
    <h2 id="github-copilot">GitHub Copilot</h2>
    <p>
      In AVAP<sup>TM</sup> Dev Studio, you can enhance your coding with
      artificial intelligence &#40;AI&#41;, such as suggestions for lines of
      code or entire functions, fast documentation creation, and help creating
      code-related artifacts like tests.
    </p>
    <p>
      GitHub Copilot is an AI-powered code completion tool that helps you write
      code faster and smarter. You can use the GitHub Copilot extension in AVAP
      <sup>TM</sup> Dev Studio to generate code, or to learn from the code it
      generates.
    </p>
    <p>
      You can learn more about how to get started with Copilot in the Copilot
      documentation.
    </p>
  </Fragment>
);
export default SOURCE_CONTROL_CollaborateOnGitHub;
