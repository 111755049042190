import React, { Fragment } from 'react';

const Themes = () => (
  <Fragment>
    <h1 >Color Themes</h1>
    <p>
      Color themes let you modify the colors in AVAP™ Dev Studio&#39;s user
      interface to suit your preferences and work environment.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/themes/themes_hero.gif"
        alt="1"
        title="1"
        style={{maxWidth:"50%"}}
      />
    </p>
    <h3 >Selecting the Color Theme</h3>
    <ol>
      <li>
        In AVAP<sup>TM</sup> Dev Studio, open the Color Theme picker with{' '}
        <strong>File</strong> &gt; <strong>Preferences</strong> &gt;{' '}
        <strong>Theme</strong> &gt; <strong>Color Theme</strong>.
      </li>
      <li>
        You can also use the keyboard shortcut <code>Ctrl+K Ctrl+T</code> to
        display the picker.
      </li>
      <li>Use the cursor keys to preview the colors of the theme.</li>
      <li>
        Select the theme you want and press <code>Enter</code>.
      </li>
    </ol>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/themes/colorthemes.png"
        alt="2"
        title="2"
      />
    </p>
    <p>
      The active color theme is stored in your user settings &#40;keyboard
      shortcut <code>Ctrl+,</code>&#41;.
    </p>

    <pre className="">
      <code className="">
        // Specifies the color theme used in the workbench.
        <br /> "workbench.colorTheme": "Solarized Dark"
      </code>
    </pre>
    <blockquote>
      <p>
        <strong>Tip</strong>: By default, the theme is stored in your user
        settings and applies globally to all workspaces. You can also configure
        a workspace specific theme. To do so, set a theme in the Workspace
        settings.
      </p>
    </blockquote>
    <h3 >
      Color Themes from the Marketplace
    </h3>
    <p>
      There are several out-of-the-box color themes in AVAP<sup>TM</sup> Dev
      Studio for you to try.
    </p>
    <p>
      Many more themes have been uploaded to the AVAP<sup>TM</sup> Dev Studio
      Extension Marketplace by the community. If you find one you want to use,
      install it and restart AVAP<sup>TM</sup> Dev Studio and the new theme will
      be available.
    </p>
    <p>
      You can search for themes in the Extensions view &#40;
      <code>Ctrl+Shift+X</code>&#41; search box using the
      @category:&quot;themes&quot; filter.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/themes/category-themes.png"
        alt="3"
        title="3"
      />
    </p>
    <h3 >
      Auto switch based on OS color scheme
    </h3>
    <p>
      Windows and macOS support light and dark color schemes. There is a
      setting, window.autoDetectColorScheme, that instructs AVAP<sup>TM</sup>{' '}
      Dev Studio to listen to changes to the OS&#39;s color scheme and switch to
      a matching theme accordingly.
    </p>
    <p>
      To customize the themes that are used when a color scheme changes, you can
      set the preferred light, dark, and high contrast themes in the Settings
      editor:
    </p>
    <ul>
      <li>
        <strong>Workbench: Preferred Dark Color Theme</strong> - defaults to
        &quot;Dark Modern&quot;
      </li>
      <li>
        <strong>Workbench: Preferred Light Color Theme</strong> - defaults to
        &quot;Light Modern&quot;
      </li>
      <li>
        <strong>Workbench: Preferred High Contrast Color Theme</strong> -
        defaults to &quot;Dark High Contrast&quot;
      </li>
      <li>
        <strong>Workbench: Preferred High Contrast Light Color Theme</strong> -
        defaults to &quot;Light High Contrast&quot;
      </li>
    </ul>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/themes/preferred-color-themes.png"
        alt="4"
        title="4"
      />
    </p>
    <h3 >Customizing a Color Theme</h3>
    <h4 >Workbench colors</h4>
    <p>
      You can customize your active color theme with the
      workbench.colorCustomizations and editor.tokenColorCustomizations user
      settings.
    </p>
    <p>
      To set the colors of AVAP<sup>TM</sup> Dev Studio UI elements such as list
      &amp; trees &#40;File Explorer, suggestions widget&#41;, diff editor,
      Activity Bar, notifications, scroll bar, split view, buttons, and more,
      use workbench.colorCustomizations.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/themes/theme-activitybar.gif"
        alt="5"
        title="5"
        style={{maxWidth:"50%"}}
      />
    </p>
    <p>
      You can use IntelliSense while setting workbench.colorCustomizations
      values or, for a list of all customizable colors, see the Theme Color
      Reference.
    </p>
    <p>To customize a specific theme only, use the following syntax:</p>

    <pre className="">
      <code className="">
        "workbench.colorCustomizations": &#123;
        <br /> "[Monokai]": &#123;
        <br /> "sideBar.background": "#347890"
        <br /> &#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>
      If a customization applies to more than one themes, you can name multiple
      themes or use * as wildcard at the beginning and the end of the name:
    </p>

    <pre className="">
      <code className="">
        "workbench.colorCustomizations": &#123;
        <br /> "[Abyss][Red]": &#123;
        <br /> "activityBar.background": "#ff0000"
        <br /> &#125;,
        <br /> "[Monokai*]": &#123;
        <br /> "activityBar.background": "#ff0000"
        <br /> &#125;
        <br />
        &#125;
      </code>
    </pre>
    <h4 >Editor syntax highlighting</h4>
    <p>
      To tune the editor&#39;s syntax highlighting colors, use
      editor.tokenColorCustomizations in your user settings settings.json file:
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/themes/token_color_customization.png"
        alt="6"
        title="6"
      />
    </p>
    <p>
      A pre-configured set of syntax tokens &#40;&#39;comments&#39;,
      &#39;strings&#39;, ...&#41; is available for the most common constructs.
      If you want more, you can do so by directly specifying TextMate theme
      color rules:
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/themes/token_color_customization_advanced.png"
        alt="7"
        title="7"
      />
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: Directly configuring TextMate rules is an
        advanced skill as you need to understand on how TextMate grammars work.
        Go to the Color Theme guide for more information.
      </p>
    </blockquote>
    <p>
      Again, to customize specific themes, you can do this in one of the
      following ways:
    </p>

    <pre className="">
      <code className="">
        "editor.tokenColorCustomizations": &#123;
        <br /> "[Monokai]": &#123;
        <br /> "comments": "#229977"
        <br /> &#125;,
        <br /> "[*Dark*]": &#123;
        <br /> "variables": "#229977"
        <br /> &#125;,
        <br /> "[Abyss][Red]": &#123;
        <br /> "keywords": "#f00"
        <br /> &#125;
        <br />
        &#125;
      </code>
    </pre>
    <h4 >Editor semantic highlighting</h4>
    <p>
      Some languages &#40;currently: TypeScript, JavaScript, Java&#41; provide
      semantic tokens. Semantic tokens are based on the language service&#39;s
      symbol understanding and are more accurate than the syntax tokens coming
      from the TextMate grammars that are driven by regular expressions. The
      semantic highlighting that is computed from the semantic tokens goes on
      top of syntax highlighting and can correct and enrich the highlighting as
      seen in the following example:
    </p>
    <p>
      The &quot;Tomorrow Night Blue&quot; color theme without semantic
      highlighting:
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/themes/no-semantic-highlighting.png"
        alt="8"
        title="8"
      />
    </p>
    <p>
      The &quot;Tomorrow Night Blue&quot; color theme with semantic
      highlighting:
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/themes/with-semantic-highlighting.png"
        alt="9"
        title="9"
      />
    </p>
    <p>
      Notice the color differences based on language service symbol
      understanding:
    </p>
    <ul>
      <li>line 10: languageModes is colored as a parameter.</li>
      <li>
        line 11: Range and Position are colored as classes and document as a
        parameter.
      </li>
      <li>line 13: getFoldingRanges is colored as a function.</li>
    </ul>
    <p>
      The settings editor.semanticHighlighting.enabled serves as the main
      control on whether semantic highlighting is applied. It can have values
      true, false, and configuredByTheme.
    </p>
    <ul>
      <li>
        true and false turn semantic highlighting on or off for all themes.
      </li>
      <li>
        configuredByTheme is the default and lets each theme control whether
        semantic highlighting is enabled or not. All the themes that ship with
        AVAP<sup>TM</sup> Dev Studio &#40;for example, the &quot;Dark+&quot;
        default&#41; have semantic highlighting enabled by default.
      </li>
    </ul>
    <p>Users can override the theme setting by:</p>

    <pre className="">
      <code className="">
        "editor.semanticTokenColorCustomizations": &#123;
        <br /> "[Rouge]": &#123;
        <br /> "enabled": true
        <br /> &#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>
      When semantic highlighting is enabled and available for a language, it is
      up to the theme to configure whether and how semantic tokens are colored.
      Some semantic tokens are standardized and map to well-established TextMate
      scopes. If the theme has a coloring rule for these TextMate scopes, the
      semantic token will be rendered with that color, without the need for any
      additional coloring rules.
    </p>
    <p>
      Additional styling rules can be configured by the user in
      editor.semanticTokenColorCustomizations&quot;:
    </p>

    <pre className="">
      <code className="">
        "editor.semanticTokenColorCustomizations": &#123;
        <br /> "[Rouge]": &#123;
        <br /> "enabled": true,
        <br /> "rules": &#123;
        <br /> "*.declaration": &#123; "bold": true &#125;
        <br /> &#125;
        <br /> &#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>
      To see what semantic tokens are computed and how they are styled, users
      can use the scope inspector &#40;<strong>Developer</strong>:{' '}
      <strong>Inspect Editor Tokens</strong> and <strong>Scopes</strong>&#41;,
      which displays information for the text at the current cursor position.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/themes/semantic-highlighting-scope-inspector.png"
        alt="10"
        title="10"
      />
    </p>
    <p>
      If semantic tokens are available for the language at the given position
      and enabled by theme, the inspect tool shows a section semantic token
      type. The section shows the semantic token information &#40;type and any
      number of modifiers&#41; as well as the styling rules that apply.
    </p>
    <p>
      More information on semantic tokens and styling rule syntax can be found
      in the Semantic Highlighting Guide.
    </p>
    <h3 >Creating your own Color Theme</h3>
    <p>
      Creating and publishing a theme extension is easy. Customize your colors
      in your user settings then generate a theme definition file with the{' '}
      <strong>Developer: Generate Color Theme From Current Settings</strong>{' '}
      command.
    </p>
    <p>
      VS Code&#39;s Yeoman extension generator will help you generate the rest
      of the extension.
    </p>
    <p>
      See the Create a new Color Theme topic in our Extension API section to
      learn more.
    </p>
    <h3 >Remove default Color Themes</h3>
    <p>
      If you&#39;d like to remove some of the default themes shipped with AVAP™
      DS from the Color Theme picker, you can disable them from the Extensions
      view &#40;<code>Ctrl+Shift+X</code>&#41;. Click the{' '}
      <strong>Filter Extensions</strong> button from the top of the Extensions
      view, select the <strong>Built-in</strong> option, and you&#39;ll see a{' '}
      <strong>THEMES</strong> section listing the default themes.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/themes/built-in-themes.png"
        alt="11"
        title="11"
      />
    </p>
    <p>
      You can disable a built-in theme extension as you would any other AVAP
      <sup>TM</sup> Dev Studio extension with the <strong>Disable</strong>{' '}
      command on the gear context menu.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/themes/disable-theme.png"
        alt="12"
        title="12"
      />
    </p>
    <h3 >File Icon Themes</h3>
    <p>
      File icon themes can be contributed by extensions and selected by users as
      their favorite set of file icons. File icons are shown in the File
      Explorer and tabbed headings.
    </p>
    <h4 >Selecting the File Icon Theme</h4>
    <ol>
      <li>
        In AVAP<sup>TM</sup> Dev Studio, open the File Icon Theme picker with{' '}
        <strong>File</strong> &gt; <strong>Preferences</strong> &gt;{' '}
        <strong>Theme</strong> &gt; <strong>File Icon Theme</strong>.
      </li>
      <li>
        You can also use the <strong>Preferences</strong>:{' '}
        <strong>File Icon Theme</strong> command from the{' '}
        <strong>Command Palette</strong> &#40;<code>Ctrl+Shift+P</code>&#41;.
      </li>
      <li>Use the cursor keys to preview the icons of the theme.</li>
      <li>
        Select the theme you want and hit <code>Enter</code>.
      </li>
    </ol>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/themes/file-icon-theme-dropdown.png"
        alt="13"
        title="13"
      />
    </p>
    <p>
      By default, the <strong>Seti</strong> file icon set is used and those are
      the icons you see in the File Explorer. Once a file icon theme is
      selected, the selected theme will be remembered and appear again whenever
      AVAP<sup>TM</sup> Dev Studio is restarted. You can disable file icons by
      selecting <strong>None</strong>.
    </p>
    <p>
      AVAP™ DS Code ships with two file icon themes; <strong>Minimal</strong>{' '}
      and <strong>Seti</strong>. To install more file icon themes, select the{' '}
      <strong>Install Additional File Icon Themes</strong> item in the file icon
      theme picker and you&#39;ll see a query for file icon themes
      &#40;tag:icon-theme&#41; in the Extensions view.
    </p>
    <p>
      You can also browse the AVAP<sup>TM</sup> Dev Studio Marketplace site
      directly to find available themes.
    </p>
    <p>
      The active File Icon theme is persisted in your user settings
      &#40;keyboard shortcut <code>Ctrl+,</code>&#41;.
    </p>

    <pre className="">
      <code className="">
        // Specifies the file icon theme used in the workbench.
        <br /> "workbench.iconTheme": "avap-seti"
      </code>
    </pre>
    <h3 >
      Creating your own File Icon Theme
    </h3>
    <p>
      You can create your own File Icon Theme from icons &#40;preferably
      SVG&#41;, see the File Icon Theme topic in our Extension API section for
      details.
    </p>
    <h3 >AVAP Code for the Web</h3>
    <p>
      AVAP<sup>TM</sup> Dev Studio for the Web provides a free, zero-install
      AVAP™ DS Code experience running entirely in your browser at{' '}
      <a href="https://vscode.dev">https://vscode.dev</a>.
    </p>
    <p>
      You can share and experience color themes through AVAP<sup>TM</sup> Dev
      Studio for the Web through the URL schema:{' '}
      <a href="https://vscode.dev/editor/theme/">
        https://vscode.dev/editor/theme/
      </a>
      .
    </p>
    <p>
      For instance, you can go to{' '}
      <a href="https://vscode.dev/editor/theme/sdras.night-owl">
        https://vscode.dev/editor/theme/sdras.night-owl
      </a>{' '}
      to experience the Night Owl theme without having to go through the
      download and install process.
    </p>
    <p>
      You can learn more about previewing and sharing themes with AVAP
      <sup>TM</sup> Dev Studio for the Web in its documentation.
    </p>
    <h3 >Next steps</h3>
    <p>
      Themes are just one way to customize AVAP<sup>TM</sup> Dev Studio. If
      you&#39;d like to learn more about AVAP<sup>TM</sup> Dev Studio
      customization and extensibility, try these topics:
    </p>
    <ul>
      <li>
        Settings - Learn how to configure AVAP<sup>TM</sup> Dev Studio to your
        preferences through user and workspace settings.
      </li>
      <li>Snippets - Add additional snippets to your favorite language.</li>
      <li>
        Extension API - Learn about other ways to extend AVAP<sup>TM</sup> Dev
        Studio.
      </li>
      <li>Color Theme - Color Theme extension API.</li>
      <li>File Icon Theme - File Icon Theme extension API.</li>
    </ul>
  </Fragment>
);
export default Themes;
