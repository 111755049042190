import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Servicio_de_notificaciones_Listar_Grupo_EN = () => 
 {
  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{ 
        "status": <boolean>, 
        "codtran": <string>, 
        "result": { 
          "group_notifications": [ 
            { "hour": <string>, 
            "destination": {
               "profile_id": <integer>, 
               "type": <string> }, 
               "origin": { 
                "user_id": <integer>, 
                "name": <string> }, 
                "number_of_read": <integer>, 
                "number_of_sent": <integer>, "date": <string>, 
                "issue": <string>, "id": <integer>, "hourdate": <string>, 
                "text": <string> 
              } ] }, 
              "elapsed": <float> 
            }`,
      copied: false
    },
    answe_ko1: {
      title:"Answer ok",
      type: "json",
      code: `{ 
        "status": <boolean>, 
        "level": <string>, 
        "message": <string>, 
        "error": <string> 
      }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: `import requests url = "URL_BASE/ws/notificaciones.py/gestor_notificaciones?country_code=MX&user_id=4532&session_id=397-GuC6yCVxioNNrCTqpxgCgA==&command=listado_grupo" 
      payload = {} 
      files = {} 
      headers= {} 
      response = requests.request("GET", url, headers=headers, data = payload, files = files) 
      print(response.text.encode('utf8'))`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/notificaciones.py/gestor_notificaciones?country_code=MX&user_id=4532&session_id=397-GuC6yCVxioNNrCTqpxgCgA==&command=listado_grupo" 
        payload = {} 
        files = {} 
        headers= {} 
        response = requests.request("GET", url, headers=headers, data = payload, files = files) 
        print(response.text.encode('utf8'))`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: `var request = require('request');
            var options = {
            'method': 'GET',
            'url': 'URL_BASE/ws/notificaciones.py/gestor_notificaciones?country_code=MX&user_id=4532&session_id=397-GuC6yCVxioNNrCTqpxgCgA==&command=listado_grupo',
            'headers': {},
            formData: {}
            };
            request(options, function (error, response) { 
            if (error) throw new Error(error);
            console.log(response.body);
            });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: `var formdata = new FormData();
            var requestOptions = {
            method: 'GET',
            body: formdata,
            redirect: 'follow'
            };
            fetch("URL_BASE/ws/notificaciones.py/gestor_notificaciones?country_code=MX&user_id=4532&session_id=397-GuC6yCVxioNNrCTqpxgCgA==&command=listado_grupo", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: `curl --location --request GET 'URL_BASE/ws/notificaciones.py/gestor_notificaciones?country_code=MX&user_id=4532&session_id=397-GuC6yCVxioNNrCTqpxgCgA%3D%3D&command=listado_grupo'`
      }
    ]
  }
  return(
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
      <h1 id="notification-service-list-of-groups">
        Notification Service &#40;List of Groups&#41;
      </h1>
      <p>This service is used to obtain a list of group notifications.</p>
      <p>
        <span className="label get">GET:</span>
        <span> </span>{' '}
        <code>URL_BASE + /ws/notificaciones.py/gestor_notificaciones</code>
      </p>
      <h2 id="receives">Receives:</h2>
      <p>
        All parameters are sent in the querystring of the call, so a percentage
        encoding for URI must be applied &#40;aka URL encoding&#41;.
      </p>
      <div className='overflow-auto'>
      <table>
        <thead>
          <tr>
            <th align="left">Parameter</th>
            <th align="center">Format</th>
            <th align="center">Obligatory</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
            <th align="center">Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="left">country_code</td>
            <td align="center">
              ISO 3166 alpha-2 &#40;
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                https://www.iso.org/glossary-for-iso-3166.html
              </a>
              &#41;
            </td>
            <td align="center">
              Yes &#40;if you do not send signature to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Obtained by identifying the user with ID user_id on the platform
            </td>
            <td align="center">N/A</td>
            <td align="center">ES &#40;Spain&#41;</td>
          </tr>
          <tr>
            <td align="left">user_id</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">Identifier of the user making the request</td>
            <td align="center">N/A</td>
            <td align="center">4532</td>
          </tr>
          <tr>
            <td align="left">session_id</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send signature to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Obtained by identifying the user with ID user_id on the platform
            </td>
            <td align="center">N/A</td>
            <td align="center">385-XadgYoWixbzN_wqWc3Yyog==</td>
          </tr>
          <tr>
            <td align="left">signature</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send session_id to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Enables you to sign the request without having to log in
            </td>
            <td align="center">N/A</td>
            <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
          </tr>
          <tr>
            <td align="left">command</td>
            <td align="center">String</td>
            <td align="center">Yes</td>
            <td align="center">
              It must contain value <code>listado_grupo</code>
            </td>
            <td align="center">N/A</td>
            <td align="center">listado_grupo</td>
          </tr>
        </tbody>
      </table>
      </div>
      <h2 id="returns">Returns:</h2>
      <p>
        Depending on the result of the operation, this service can return two
        different JSON:
      </p>
      <h3 id="answer-json-ok">Answer JSON OK:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
      <h2 id="where">Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;
        </li>
        <li>
          <code>codtran:</code> Transaction code that identifies the executed
          operation
        </li>
        <li>
          <code>result:</code> Executed service answer
        </li>
        <li>
          <code>group_notifications:</code> List of group notifications created.
        </li>
        <li>
          <code>hour:</code> Group notification of timestamp creation.
        </li>
        <li>
          <code>destination:</code> Container object for the data of the
          notification target group.
        </li>
        <li>
          <code>profile_id:</code> Profile identifier on which the notification
          applies.
        </li>
        <li>
          <code>type:</code> Type of the notification destination.
        </li>
        <li>
          <code>origin:</code> Container object for the notification creator data.
        </li>
        <li>
          <code>user_id:</code> Identifier of the user who created the
          notification.
        </li>
        <li>
          <code>name:</code> Name of the user who created the notification.
        </li>
        <li>
          <code>number_of_read:</code> Number of users who have read the
          notification.
        </li>
        <li>
          <code>number_of_sent:</code> Number of users who have been sent the
          notification.
        </li>
        <li>
          <code>date:</code> Group notification of timestamp creation.
        </li>
        <li>
          <code>issue:</code> Notification object.
        </li>
        <li>
          <code>id:</code> Identificator of group notification.
        </li>
        <li>
          <code>hourdate:</code> Group notification of timestamp creation.
        </li>
        <li>
          <code>text:</code> Notification message.
        </li>
        <li>
          <code>elapsed:</code> Operation execution time.
        </li>
      </ul>
      <h3 id="answer-json-ko">Answer JSON KO:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
      <h2 id="where-1">Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li>
          <code>level:</code> Error importance level.
        </li>
        <li>
          <code>message:</code> Error message.
        </li>
        <li>
          <code>error:</code> Sole error code.
        </li>
      </ul>
      <h2 id="business-logic">Business logic:</h2>
      <p>
        In order to run this service, it is necessary to do it from a user logged
        into the system who has an administrator profile. With this endpoint all
        group notifications created by the user making the request are obtained.
        <br></br>
      </p>
      </div>

      <div className='col-md-5'>
      <h2 id="example-requests">Example requests:</h2>
      <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
      
      </div>
      </div>
    </Fragment>
  );
 }
export default Servicio_de_notificaciones_Listar_Grupo_EN;
