import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Spei_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `
        { 
          "status": <boolean>, 
          "invoice": <integer>, 
          "signature": <boolean>, 
          "currency_data": {
             "abbreviation": <string>, "suffix": <string>, 
             "format": <string>, "symbol": <string>, 
             "prefix": <string>, "decimals": <decimal>, 
             "id": <integer> }, "raw_amount": <decimal>, 
             "currency": <string>, "commission_user_card": <decimal>, 
             "codtran": <string>, "user_commission": <decimal>,
              "amount": <string> "elapsed": <float> 
            }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `
        { 
          "status": false, 
          "level": <string>, 
          "message": <string>, 
          "error": <string>
        }`,
        copied: false
      },
      bussineslogic: {
        title:"Answer ok",
        type: "json",
        code: `
        { 
          "amount": <string>, "total_registrations": <string>, 
          "amount_sum": <string>, 
          "transactions": [ { 
            "CLABE_account": <string>, 
            "originator_name": <string>, 
            "beneficiary_name": <string>, 
            "amount": <string>, 
            "rfc_beneficiary": <string>, 
          } ] }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requestsurl = "URL_BASE/ws/pagomovil.py/pagomovil"payload = { 'country_code': 'MX', 'user_id': '4532', 'session_id': '431-VJGiA5oBnkQlHPX8IAVsYg==', 'operation_type': 'spei', 'operation_parameters': '{}' }
        files = []
        headers= {}
        response = requests.request("POST", url, headers=headers, data = payload, files = files)
        print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requestsurl = "URL_BASE/ws/pagomovil.py/pagomovil"payload = { 'country_code': 'MX', 'user_id': '4532', 'session_id': '431-VJGiA5oBnkQlHPX8IAVsYg==', 'operation_type': 'spei', 'operation_parameters': '{}' }
          files = []
          headers= {}
          response = requests.request("POST", url, headers=headers, data = payload, files = files)
          print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'POST',
              'url': 'URL_BASE/ws/pagomovil.py/pagomovil',
              'headers': {
              },
              formData: {
              'country_code': 'MX',
              'user_id': '4532',
              'session_id': '431-VJGiA5oBnkQlHPX8IAVsYg==',
              'operation_type': 'spei',
              'operation_parameters': '{}'
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var formdata = new FormData();
              formdata.append("country_code", "MX");
              formdata.append("user_id", "4532");
              formdata.append("session_id", "431-VJGiA5oBnkQlHPX8IAVsYg==");
              formdata.append("operation_type", "spei");
              formdata.append("operation_parameters", "{}");
              var requestOptions = {
              method: 'POST',
              body: formdata,
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/pagomovil.py/pagomovil", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request POST 'URL_BASE/ws/pagomovil.py/pagomovil' \
            --form 'country_code=MX' \
            --form 'user_id=4532' \
            --form 'session_id=431-VJGiA5oBnkQlHPX8IAVsYg==' \
            --form 'operation_type=spei' \
            --form 'operation_parameters={}'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="spei-transfer">Spei Transfer</h1>
        <p>This service is used to send a Spei transfer order.</p>
        <p>
          <span className="label get">POST:</span> URL_BASE +
          /ws/pagomovil.py/pagomovil
          <br></br>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <h3 id="parameters">parameters:</h3>
        <table>
          <thead>
            <tr>
               <th className='textBold'>Parameter</th>
               <th className='textBold'>Format</th>
               <th className='textBold'>Obligatory</th>
               <th className='textBold'>Description</th>
               <th className='textBold'>Default value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>country_code</td>
              <td>
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td>Yes</td>
              <td>Country code in which the user resides</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>user_id</td>
              <td>Integer</td>
              <td>Yes</td>
              <td>Identifier of the user making the request</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>session_id</td>
              <td>String</td>
              <td>
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td>
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>signature</td>
              <td>String</td>
              <td>Yes</td>
              <td>Enables you to sign the request without having to log in</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>operation_type</td>
              <td>String</td>
              <td>Yes</td>
              <td>Spei value must appear</td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>operation_parameters</td>
              <td>JSON</td>
              <td>Yes</td>
              <td>This json is explained in business logic.</td>
              <td>N/A</td>
            </tr>
          </tbody>
        </table>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h3 id="where">Where:</h3>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>signature:</code> Indicates if the signature has been processed
            correctly &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>invoice:</code> Receipt number associated to the operation.
          </li>
          <li>
            <code>currency_data:</code> Contains the different details of the
            currency used for the operation.
          </li>
          <li>
            <code>abbreviation:</code> The abbreviated name of the currency
            &#40;EUR, MXN&#41;.
          </li>
          <li>
            <code>suffix:</code> Indicates the suffix that is applied in the
            currency format &#40;pesos, euros&#41;.
          </li>
          <li>
            <code>format:</code> The full format that is applied to the currency, it
            includes the suffix and the prefix.
          </li>
          <li>
            <code>symbol:</code> The symbol associated to the currency &#40;€, ¢,
            $&#41;.
          </li>
          <li>
            <code>prefix:</code> The prefix that is applied in the currency format.
          </li>
          <li>
            <code>decimals:</code> The maximum number of decimal places to be
            included in the currency format.
          </li>
          <li>
            <code>id:</code> Currency identifier in BBDD.
          </li>
          <li>
            <code>raw_amount:</code> Amount of the operation in negative without
            applying the format.
          </li>
          <li>
            <code>currency:</code> Short name of the currency used in the operation,
            it matches the abbreviation.
          </li>
          <li>
            <code>commission_user_card:</code> Commission that would be applied if
            the payment is made by card.
          </li>
          <li>
            <code>codtran:</code> Transaction code that identifies the executed
            operation.
          </li>
          <li>
            <code>user_commission:</code> Commission that will be applied to the
            user.
          </li>
          <li>
            <code>amount:</code> Negative transaction amount with the applied
            format.
          </li>
          <li>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <h2 id="answer-json-ko">Answer JSON KO:</h2>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h3 id="where-1">Where:</h3>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>level:</code> Error importance level.
          </li>
          <li>
            <code>message:</code> Error message.
          </li>
          <li>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          By means of this service the chosen amount of money can be withdrawn from
          the ATM.
        </p>
        <p>
          The &#39;operation_parameters&#39; parameter is a JSON with the following
          structure:
        </p>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.bussineslogic}/>
          </div>
        <ul>
          <li>
            <code>amount:</code> Amount to withdraw at the ATM, without decimals.
            Example: &quot;1&quot;
          </li>
          <li>
            <code>total_registrations:</code> Number of transactions to perform. It
            must be &quot;1&quot;.
          </li>
          <li>
            <code>amount_sum:</code> Sum of the transaction amounts, it must bear
            the value of the amount. Example: &quot;1&quot;
          </li>
          <li>
            <code>transactions:</code> List with the transaction to be carried out.
          </li>
          <li>
            <code>CLABE_account:</code> CLABE account number, to which to carry out
            the spei.
          </li>
          <li>
            <code>originator_name:</code> Name of the originator of the spei.
          </li>
          <li>
            <code>beneficiary_name:</code> Name of the beneficiary of the money.
          </li>
          <li>
            <code>amount:</code> Transaction amount, must bear the value of the
            amount. Example: &quot;1&quot;
          </li>
          <li>
            <code>rfc_beneficiary:</code> RFC of the beneficiary of the money.
          </li>
        </ul>
        </div>

      <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
        </div>
      </Fragment>
    );
  }
export default Spei_EN;
