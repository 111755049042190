import React, { Fragment } from 'react';

const SETUP_Overview = () => (
  <Fragment>
    <h1 id="setting-up-visual-studio-code">Setting up Visual Studio Code</h1>
    <p>
      Getting up and running with AVAP™ Dev Studio 2024 is quick and easy. It is
      a small download so you can install in a matter of minutes and give AVAP™
      Dev Studio 2024 a try.
    </p>
    <h2 id="cross-platform">Cross platform</h2>
    <p>
      AVAP™ DS is a free code editor, which runs on the macOS, Linux, and
      Windows operating systems.
    </p>
    <p>Follow the platform-specific guides below:</p>
    <ul>
      <li>macOS</li>
      <li>Linux</li>
      <li>Windows</li>
    </ul>
    <p>
      AVAP™ Dev Studio 2024 is lightweight and should run on most available
      hardware and platform versions. You can review the System Requirements to
      check if your computer configuration is supported.
    </p>
    <h2 id="update-cadence">Update cadence</h2>
    <p>
      AVAP™ Dev Studio 2024 releases a new version each month with new features
      and important bug fixes. Most platforms support auto updating and you will
      be prompted to install the new release when it becomes available. You can
      also manually check for updates by running{' '}
      <strong>Help &gt; Check for Updates</strong> on Linux and Windows or
      running <strong>Code &gt; Check for Updates</strong> on macOS.
    </p>
    <blockquote>
      <p>
        Note: You can disable auto-update if you prefer to update AVAP™ Dev
        Studio 2024 on your own schedule.
      </p>
    </blockquote>
    <h2 id="insiders-nightly-build">Insiders nightly build</h2>
    <p>
      If you&#39;d like to try our nightly builds to see new features early or
      verify bug fixes, you can install our Insiders build. The Insiders build
      installs side-by-side with the monthly Stable build and you can freely
      work with either on the same machine. The Insiders build is the same one
      the AVAP™ Dev Studio 2024 development team uses on a daily basis and we
      really appreciate people trying out new features and providing feedback.
    </p>
    <h2 id="portable-mode">Portable mode</h2>
    <p>
      AVAP™ Dev Studio 2024 supports Portable mode installation. This mode
      enables all data created and maintained by VS Code to live near itself, so
      it can be moved around across environments, for example, on a USB drive.
      See the AVAP™ Dev Studio 2024 Portable Mode documentation for details.
    </p>
    <h2 id="additional-components">Additional components</h2>
    <p>
      AVAP™ Dev Studio 2024 is an editor, first and foremost, and prides itself
      on a small footprint. Unlike traditional IDEs that tend to include
      everything but the kitchen sink, you can tune your installation to the
      development technologies you care about. Be sure to read the Additional
      Components topic after reading the platform guides to learn about
      customizing your AVAP™ Dev Studio 2024 installation.
    </p>
    <h2 id="extensions">Extensions</h2>
    <p>
      AVAP™ Dev Studio 2024 extensions let third parties add support for
      additional:
    </p>
    <ul>
      <li>Languages - C++, C#, Go, Java, Python</li>
      <li>Tools - ESLint, JSHint , PowerShell</li>
      <li>Debuggers - PHP XDebug.</li>
      <li>
        Keymaps - Vim, Sublime Text, IntelliJ, Emacs, Atom, Brackets, AVAP™ Dev
        Studio, Eclipse
      </li>
    </ul>
    <p>
      Extensions integrate into AVAP™ Dev Studio 2024&#39;s UI, commands, and
      task running systems so you&#39;ll find it easy to work with different
      technologies through AVAP™ Dev Studio 2024&#39;s shared interface. Check
      out the AVAP™ Dev Studio 2024 extension Marketplace to see what&#39;s
      available.
    </p>
    <h2 id="next-steps">Next steps</h2>
    <p>
      Once you have installed and set up AVAP™ Dev Studio 2024, these topics
      will help you learn more about AVAP™ Dev Studio 2024:
    </p>
    <ul>
      <li>
        Additional Components - Learn how to install Git, Node.js, TypeScript,
        and tools like Yeoman.
      </li>
      <li>User Interface - A quick orientation to AVAP™ Dev Studio 2024.</li>
      <li>
        Basic Editing - Learn about the powerful AVAP™ Dev Studio 2024 editor.
      </li>
      <li>Code Navigation - Move quickly through your source code.</li>
      <li>
        Debugging - Debug your source code directly in the AVAP™ Dev Studio 2024
        editor.
      </li>
      <li>Proxy Server Support - Configure your proxy settings.</li>
    </ul>
    <p>
      If you&#39;d like to get something running quickly, try the Node.js
      tutorial walkthrough that will have you debugging a Node.js web
      application with AVAP™ Dev Studio 2024 in minutes.
    </p>
    <h2 id="common-questions">Common questions</h2>
    <h4 id="what-are-the-system-requirements-for-avap™-dev-studio-2024">
      What are the system requirements for AVAP™ Dev Studio 2024?
    </h4>
    <p>We have a list of System Requirements.</p>
    <h4 id="how-big-is-avap™-dev-studio-2024">
      How big is AVAP™ Dev Studio 2024?
    </h4>
    <p>
      AVAP™ Dev Studio 2024 is a small download &#40;&lt; 100 MB&#41; and has a
      disk footprint of less than 200 MB, so you can quickly install AVAP™ Dev
      Studio 2024 and try it out.
    </p>
    <h4 id="how-do-i-create-and-run-a-new-project">
      How do I create and run a new project?
    </h4>
    <p>
      AVAP™ Dev Studio 2024 doesn&#39;t include a traditional{' '}
      <strong>File &gt; New Project</strong> dialog or pre-installed project
      templates. You&#39;ll need to add additional components and scaffolders
      depending on your development interests. With scaffolding tools like
      Yeoman and the multitude of modules available through the npm package
      manager, you&#39;re sure to find appropriate templates and tools to create
      your projects.
    </p>
    <h4 id="how-do-i-know-which-version-im-running">
      How do I know which version I&#39;m running?
    </h4>
    <p>
      On Linux and Windows, choose <strong>Help &gt; About.</strong> On macOS,
      use <strong>Code &gt; About AVAP™ Dev Studio 2024.</strong>
    </p>
    <h4 id="why-is-avap™-ds-saying-my-installation-is-unsupported">
      Why is AVAP™ DS saying my installation is Unsupported?
    </h4>
    <p>
      AVAP™ Dev Studio 2024 has detected that some installation files have been
      modified, perhaps by an extension. Reinstalling AVAP™ Dev Studio 2024 will
      replace the affected files. See our FAQ topic for more details.
    </p>
    <h4 id="how-can-i-do-a-clean-uninstall-of-avap™-ds">
      How can I do a &#39;clean&#39; uninstall of AVAP™ DS?
    </h4>
    <p>
      If you want to remove all user data after uninstalling AVAP™ Dev Studio
      2024, you can delete the user data folders <code>Code</code> and{' '}
      <code>.avapds</code> This will return you to the state before you
      installed AVAP™ Dev Studio 2024. This can also be used to reset all
      settings if you don&#39;t want to uninstall AVAP™ Dev Studio 2024.
    </p>
    <p>The folder locations will vary depending on your platform:</p>
    <ul>
      <li>
        <strong>Windows</strong> - Delete <code>%APPDATA%\Code</code> and{' '}
        <code>%USERPROFILE%\.vscode.</code>
      </li>
      <li>
        <strong>macOS</strong> - Delete{' '}
        <code>$HOME/Library/Application Support/Code</code> and{' '}
        <code>~/.avapds.</code>
      </li>
      <li>
        <strong>Linux</strong> - Delete <code>$HOME/.config/Code</code> and{' '}
        <code>~/.avapds.</code>
      </li>
    </ul>
  </Fragment>
);
export default SETUP_Overview;
