import React from 'react';

const GETSTARTED_Telemetry = () => (
  <div>
    <h1>Telemetry</h1>
    <br />
    <p>
      AVAP™ Dev Studio 2024 collects telemetry data, which is used to help understand how to improve the product. For example, this usage data helps to debug issues, such as slow start-up times, and to prioritize new features. While we appreciate the insights this data provides, we also know that not everyone wants to send usage data and you can disable telemetry as described in disable telemetry reporting. You can also read our privacy statement to learn more.
    </p>
    <br />
    <h2>Types of telemetry data</h2>
    <p>AVAP™ DS and this page refer to three different types of data with respect to telemetry.</p>
    <ul>
      <li><strong>Crash Reports</strong> - Crash reports collect diagnostic information when AVAP™ DS crashes and sends it to Microsoft to help understand why the crash occurred and what changes are needed to prevent the crash in the future.</li>
      <li><strong>Error Telemetry</strong> - Error telemetry collects information about errors that do not crash the application but are unexpected.</li>
      <li><strong>Usage Data</strong> - Usage data collects information about how features are used and perform in AVAP™ DS which helps us prioritize future product improvements.</li>
    </ul>
    <br />
    <h2>Disable telemetry reporting</h2>
    <p>With the <code>telemetry.telemetryLevel</code> user setting, you can control the different types of telemetry we send with a single setting. Here is a table of the different types of data sent with each value of <code>telemetry.telemetryLevel:</code></p>
    <table>
      <thead>
        <tr>
           <th className='textBold'></th>
           <th className='textBold'>Crash Report</th>
           <th className='textBold'>Error Telemetry</th>
           <th className='textBold'>Usage Data</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>all</td>
          <td>✓</td>
          <td>✓</td>
          <td>✓</td>
        </tr>
        <tr>
          <td>error</td>
          <td>✓</td>
          <td>✓</td>
          <td>-</td>
        </tr>
        <tr>
          <td>crash</td>
          <td>✓</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>off</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
      </tbody>
    </table>
    <p>For example, if you don't want to send any telemetry data to Microsoft, you can set the <code>telemetry.telemetryLevel</code> user setting to <code>off</code>.</p>
    <p>
      From <strong>Code &gt; Preferences &gt; Settings</strong>, search for <code>telemetry</code>, and set the Telemetry: <strong>Telemetry Level</strong> setting to <code>off</code>. This will silence all telemetry events from AVAP™ DS going forward. Telemetry information may have been collected and sent up until the point when you disable the setting.
    </p>
    <p><strong>IMAGEN</strong></p>
    <p>If you use the JSON editor for your settings, add the following line:</p>
    <pre>
      <code>"telemetry.telemetryLevel": "off"</code>
    </pre>
    <br />
    <h2>Extensions and telemetry</h2>
    <p>
      AVAP™ DS lets you add features to the product by installing Microsoft and third-party extensions. These extensions may be collecting their own usage data and are not controlled by the <code>telemetry.telemetryLevel</code> setting. Consult the specific extension's documentation to learn about its telemetry reporting and whether it can be disabled.
    </p>
    <p>Extension authors may refer to the "For Extension Authors" section for guidance on implementing telemetry best practices within their extension.</p>
    <h2>Output channel for telemetry events</h2>
    <p>
      If you'd like to review the telemetry events in AVAP™ DS as they are sent, you can enable tracing and it will record telemetry events. Using the <strong>Developer: Set Log Level...</strong> command and select log level <strong>Trace</strong>. To view the logging output, go to the Output panel (⇧⌘U) and pick <strong>Telemetry</strong> from the dropdown.
    </p>
    <p><strong>IMAGEN</strong></p>
    <p>
      When tracing telemetry events, the events are also logged to a local file telemetry.log, which you can view using the <strong>Developer: Open Log File...</strong> command and choosing Telemetry from the dropdown.
    </p>
    <p><strong>IMAGEN</strong></p>
    <br />
    <h2>Viewing all telemetry events</h2>
    <p>
      If you'd like to view all the possible telemetry events that AVAP™ DS could send, you can use the <code>--telemetry</code> flag in the CLI. This will generate a JSON report that you can then view within AVAP™ DS. These reports are generated per build and do not contain extension telemetry unless the extension author adds a <code>telemetry.json</code> file to their root build directory.
    </p>
    <p>
      For example, running <code>code --telemetry &gt; telemetry.json &amp;&amp; code telemetry.json</code> will create a <code>telemetry.json</code> file in your current working directory and then open it in AVAP™ DS. You cannot pipe the output like this, <code>code --telemetry | code -</code>, due to the length of the telemetry report.
    </p>
    <p>
      The sections below detail the event metadata used to classify the telemetry, describe its purpose, and indicate any special handling.
    </p>
    <br />
    <h4>Event classification</h4>
    <p>The <code>classification</code> field describes the type of data.</p>
    <ul>
      <li><code>SystemMetaData</code> - Values generated by AVAP™ DS that are not personally identifiable.</li>
      <li><code>CallstackOrException</code> - Errors caused by failures in program execution. These contain stack traces that have been scrubbed of user paths.</li>
      <li><code>PublicNonPersonalData</code> - User generated data that is available to the public, for example, published extension IDs.</li>
      <li><code>EndUserPseudonymizedInformation</code> - Hashes used to identify a unique user without being able to identify who that user is. For example, a hashed Mac Address.</li>
    </ul>
    <br />
    <h4>Event purpose</h4>
    <p>The <code>purpose</code> field describes why the data is collected.</p>
    <ul>
      <li><code>PerformanceAndHealth</code> - To ensure that AVAP™ DS product and services are healthy and fast.</li>
      <li><code>FeatureInsight</code> - To understand feature usage and where to continue development investment.</li>
      <li><code>BusinessInsight</code> - To make decisions related to the business of AVAP™ DS, Microsoft, and GitHub.</li>
    </ul>
    <br />
    <h4>Event endpoint</h4>
    <p>The <code>endpoint</code> field describes what data handler the data is sent to. This is normally applied to special data that requires additional scrubbing and security to protect user privacy.</p>
    <ul>
      <li><code>GoogleAnalyticsId</code> - Used on our website for Google Analytics and tracking page views. These are handled in a more sensitive manner than our normal data.</li>
      <li><code>MacAddressHash</code> - Used to identify a user of AVAP™ DS. This is hashed once on the client side and then hashed again on the pipeline side to make it impossible to identify a given user. On AVAP™ DS for the Web, a UUID is generated for this case.</li>
      <li><code>none</code> - Data does not require any special handling.</li>
    </ul>
    <br />
    <h2>GDPR and AVAP™ DS</h2>
    <p>
      In addition to supporting the General Data Protection Regulation (GDPR), the AVAP™ DS team takes privacy very seriously. That's both for Microsoft as a company and specifically within the AVAP™ DS team.
    </p>
    <p>To ensure GDPR compliance, we made several updates to AVAP™ DS, these include:</p>
    <ul>
      <li>Making it easier to opt out of telemetry collection by placing a notification in product for all existing and new users.</li>
      <li>Reviewing and classifying the telemetry that we send (documented in our OSS codebase).</li>
      <li>Ensuring that we have valid data retention policies in place for any data we do collect, for example crash dumps.</li>
    </ul>
    <p>
      In short, we have worked hard to do the right thing, for all users, as these practices apply to all geographies, not just Europe.
    </p>
    <p>
      One question we expect people to ask is to see the data we collect. However, we don't have a reliable way to do this as AVAP™ DS does not have a 'sign-in' experience that would uniquely identify a user. We do send information that helps us approximate a single user for diagnostic purposes (this is based on a hash of the network adapter NIC on the desktop and a randomly assigned UUID on the web) but this is not guaranteed to be unique. For example, virtual machines (VMs) often rotate NIC IDs or allocate from a pool. This technique is sufficient to help us when working through problems, but it is not reliable enough for us to 'provide your data'.
    </p>
    <p>
      We expect our approach to evolve as we learn more about GDPR and the expectations of our users. We greatly appreciate the data users do send to us, as it is very valuable and AVAP™ DS is a better product for everyone because of it. And again, if you are worried about privacy, we offer the ability to disable sending telemetry as described in disable telemetry reporting.
    </p>
    <p>
      You can find more information about how the AVAP™ DS family approaches GDPR at AVAP™ DS Family Data Subject Requests for the GDPR.
    </p>
    <br />
    <h2>Managing online services</h2>
    <p>
      Beyond crash reporting and telemetry, AVAP™ DS uses online services for various other purposes such as downloading product updates, finding, installing, and updating extensions, Settings Sync, or providing Natural Language Search within the Settings editor. You can choose to turn on/off features that use these services.
    </p>
    <p>
      Please note, that turning off these features does not put AVAP™ DS into offline mode. For example, if you search for extensions in the Extensions view, AVAP™ DS still searches the online AVAP™ DS Marketplace. The settings ensure that AVAP™ DS does not talk to online services without you requesting it.
    </p>
    <p>
      From <strong>Code &gt; Preferences &gt; Settings</strong>, and type the tag <code>@tag:usesOnlineServices</code>. This will display all settings that control the usage of online services and you can individually switch them on or off.
    </p>
    <p><strong>IMAGEN</strong></p>
    <p>
      <strong>Note:</strong> AVAP™ DS extensions may also use online services and may not provide settings to configure the usage of these online services, or they may not register their settings to show up when searching for <code>@tag:usesOnlineServices</code>. Consult the specific extension's documentation to learn about its usage of online services.
    </p>
    <br />
    <h4>Non-Microsoft online services used by AVAP™ DS</h4>
    <p>
      The built-in <strong>npm support for AVAP™ DS</strong> extension sends requests to <code>https://registry.npmjs.org</code> and <code>https://registry.bower.io</code>.
    </p>
    <p>
      The built-in <strong>TypeScript and JavaScript Language Features</strong> extension queries the <code>@types</code> domain at <code>https://registry.npmjs.org</code>.
    </p>
    <p>
      When you use <strong>Developer: Toggle Developer Tools or Developer: Open Webview Developer Tools</strong>, AVAP™ DS may talk to Google servers to fetch data needed to launch Developer Tools.
    </p>
    <br />
    <h3>Extension recommendations</h3>
    <p>
      AVAP™ DS provides extension recommendations based on your file types, your workspace, and your environment. File type recommendations are either precomputed or dynamic. Workspace and environment recommendations are always precomputed.
    </p>
    <p>
      If you want to know why an extension is being recommended, open the extension's detail page. You can find the recommendation reason in the page header.
    </p>
    <p><strong>IMAGEN</strong></p>
    <br />
    <h4>Precomputed recommendations</h4>
    <p>
      AVAP™ DS collects telemetry about which extensions are being activated for what file types and what workspaces/folders. Specific folders are identified by computing a hash of each of the folder's Git remotes.
    </p>
    <p>
      We use this information to precompute anonymous recommendations. Precomputed recommendations are instructions that spell out under which conditions an extension should be recommended. For example, when we see an interesting correlation between two extensions A and B, one instruction might be: Recommend extension B if the user has installed extension A but not B.
    </p>
    <p>
      Some precomputed recommendations are shipped as part of the product while additional precomputed recommendations are fetched at runtime from an online Microsoft service. AVAP™ DS independently evaluates and executes precomputed recommendations without sending any user information to any online service.
    </p>
    <br />
    <h4>Dynamic recommendations</h4>
    <p>
      When you open a file type for which AVAP™ DS does not have any precomputed recommendation, it asks the Extension Marketplace for extensions that declare that they support this file type. If the query returns extensions you don't have installed, AVAP™ DS will provide a notification.
    </p>
    <br />
    <h3>For extension authors</h3>
    <p>Please read the extension guides telemetry document.</p>
    <br />
    <h3>Next steps</h3>
    <ul>
      <li>AVAP™ Dev Studio 2024 FAQ - Consult the Frequently Asked Questions to learn more.</li>
      <li>User and Workspace Settings - Read about available options to customize AVAP™ DS.</li>
      <li>Key Bindings - You can easily modify commonly used keyboard shortcuts.</li>
    </ul>
    <br /><br /><br />
  </div>
);

export default GETSTARTED_Telemetry;