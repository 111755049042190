import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Transacciones_EN = () => 
 {
  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `
      { "status": true, 
      "codtran": "1128c992797f3b4972626b86f339cb15", 
      "result": { "data": [ [ <string> ] ], "number_of_results": <integer>, "totals": <integer>, "columns": [<string>], "result_limit": <integer>, }, 
      "elapsed": <float> }`,
      copied: false
    },
    answe_ko1: {
      title:"Answer ok",
      type: "json",
      code: `{ 
        "status": <boolean>, 
        "level": <string>, 
        "message": <string>, 
        "error": <string> }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: ` import requests url = "URL_BASE/ws/admin.py/transacciones?country_code=MX&user_id=4532&session_id=407-W-VF2OoTRSE7JAcsk4UKkA%3D%3D&startdate=2019-10-12&enddate=2020-06-9&nick=prueba" 
      payload = {} 
      files = {} 
      headers= {} 
      response = requests.request("GET", url, headers=headers, data = payload, files = files) 
      print(response.text.encode('utf8'))`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests url = "URL_BASE/ws/admin.py/transacciones?country_code=MX&user_id=4532&session_id=407-W-VF2OoTRSE7JAcsk4UKkA%3D%3D&startdate=2019-10-12&enddate=2020-06-9&nick=prueba" 
        payload = {} 
        files = {} 
        headers= {} 
        response = requests.request("GET", url, headers=headers, data = payload, files = files) 
        print(response.text.encode('utf8'))`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: `var request = require('request');
            var options = {
            'method': 'GET',
            'url': 'URL_BASE/ws/admin.py/transacciones?country_code=MX&user_id=4532&session_id=407-W-VF2OoTRSE7JAcsk4UKkA%3D%3D&startdate=2019-10-12&enddate=2020-06-9&nick=prueba',
            'headers': {
            },
            formData: {}
            };
            request(options, function (error, response) { 
            if (error) throw new Error(error);
            console.log(response.body);
            });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: `var formdata = new FormData();
            var requestOptions = {
            method: 'GET',
            body: formdata,
            redirect: 'follow'
            };
            fetch("URL_BASE/ws/admin.py/transacciones?country_code=MX&user_id=4532&session_id=407-W-VF2OoTRSE7JAcsk4UKkA%3D%3D&startdate=2019-10-12&enddate=2020-06-9&nick=prueba", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: `curl --location --request GET 'URL_BASE/ws/admin.py/transacciones?country_code=MX&user_id=4532&session_id=407-W-VF2OoTRSE7JAcsk4UKkA%3D%3D&startdate=2019-10-12&enddate=2020-06-9&nick=prueba'`
      }
    ]
  }
  return(
    
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
      <h1 id="transactions">Transactions</h1>
      <p>
        Everything required to show a list of transactions made in the system is
        obtained.
      </p>
      <p>
        <span className="label get">GET:</span>{' '}
        <code>URL_BASE + /ws/admin.py/transacciones</code>
      </p>
      <h2 id="receives">Receives:</h2>
      <p>
        All parameters are sent in the querystring of the call, so a percentage
        encoding for URI has to be applied &#40;also known as URL encoding&#41;.
      </p>
      <table>
        <thead>
          <tr>
            <th align="left">Parameter</th>
            <th align="center">Format</th>
            <th align="center">Obligatory</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="left">country_code</td>
            <td align="center">
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                ISO 3166 alpha-2
              </a>
            </td>
            <td align="center">Yes</td>
            <td align="center">Country code in which the user resides</td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">user_id</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">Identifier of the user making the request</td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">session_id</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send signature to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Obtained by identifying the user with ID user_id on the platform
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">signature</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send session_id to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Enables you to sing the request without having to log in
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">startdate</td>
            <td align="center">String</td>
            <td align="center">Yes</td>
            <td align="center">
              Operations from the indicated date are obtained
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">enddate</td>
            <td align="center">String</td>
            <td align="center">Yes</td>
            <td align="center">
              Operations up to the indicated date are obtained
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">nick</td>
            <td align="center">String</td>
            <td align="center">No</td>
            <td align="center">
              User name&#40;Nick&#41;, to show only your operations
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">csv</td>
            <td align="center">Boolean</td>
            <td align="center">No</td>
            <td align="center">Result sent by email in CSV format.</td>
            <td align="center">N/A</td>
          </tr>
        </tbody>
      </table>
      <h2 id="returns">Returns:</h2>
      <p>
        Depending on the result of the operation, this service can return two
        different JSON:
      </p>
      <h3 id="answer-json-ok">Answer JSON OK:</h3>
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
      
      <h2 id="where">Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li>
          <code>codtran:</code> Transaction code that identifies the executed
          operation.
        </li>
        <li>
          <code>result:</code> Service answer.
        </li>
        <li>
          <code>data:</code> they are a great list in which each one contains the
          values for each of the columns.
        </li>
        <li>
          <code>number_of_results:</code> Result number appeared in &#39;data&#39;
        </li>
        <li>
          <code>totals:</code> Total number of results.
        </li>
        <li>
          <code>columns:</code> Name of each of the values found in each of the
          great data list.
        </li>
        <li>
          <code>result_limit:</code> Maximum number of results that appear in the
          query.
        </li>
        <li>
          <code>elapsed:</code> Operation execution time.
        </li>
      </ul>
      <h3 id="answer-json-ko">Answer JSON KO:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
      <h2 id="donde">Donde:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;
        </li>
        <li>
          <code>level:</code> Error importance level.
        </li>
        <li>
          <code>message:</code> Error message.
        </li>
        <li>
          <code>error:</code> Sole error code.
        </li>
      </ul>
      <h2 id="business-logic">Business logic:</h2>
      <p>
        In order to run this service, it is necessary to have a user logged into
        the system who has an administrator profile. With this endpoint, the
        transactions carried out on the platform are obtained, enabling you to
        filter them by user and dates. You can also request the response in CSV
        format and receive it in the email of the user making the request.
      </p>
      </div>

      <div className='col-md-5'>
      <h2 id="example-requests-de-ejemplo">Example requests de ejemplo:</h2>
      <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
      
      </div>
      </div>
    </Fragment>
  );
 }
export default Transacciones_EN;
