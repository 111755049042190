import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Servicio_de_notificaciones_Modificar_Grupo_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": <boolean>, 
          "codtran": <string>, 
          "result": { 
            "group_notification_id": <integer> 
          }, 
          "elapsed": <float> 
        }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": <boolean>, 
          "level": <string>,
           "message": <string>,
            "error": <string> 
          }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/notificaciones.py/gestor_notificaciones"
         payload = { 'country_code': 'MX', 'user_id': '4532', 'session_id': '418-VC6Et5UdloC7Q3ia6aP9Ng==', 'command': 'modificar_grupo', 'issue': 'Lorem ipsum dolor sit amet', 'text': 'Lorem ipsum dolor sit amet', 'group_notification_id': '6' } 
         files = [] 
         headers= {} 
         response = requests.request("POST", url, headers=headers, data = payload, files = files) 
        print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests url = "URL_BASE/ws/notificaciones.py/gestor_notificaciones"
          payload = { 'country_code': 'MX', 'user_id': '4532', 'session_id': '418-VC6Et5UdloC7Q3ia6aP9Ng==', 'command': 'modificar_grupo', 'issue': 'Lorem ipsum dolor sit amet', 'text': 'Lorem ipsum dolor sit amet', 'group_notification_id': '6' } 
          files = [] 
          headers= {} 
          response = requests.request("POST", url, headers=headers, data = payload, files = files) 
         print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'POST',
              'url': 'URL_BASE/ws/notificaciones.py/gestor_notificaciones',
              'headers': { },
              formData: {
              'country_code': 'MX',
              'user_id': '4532',
              'session_id': '418-VC6Et5UdloC7Q3ia6aP9Ng==',
              'command': 'modificar_grupo',
              'issue': 'Lorem ipsum dolor sit amet',
              'text': 'Lorem ipsum dolor sit amet',
              'group_notification_id': '6'
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var formdata = new FormData();
              formdata.append("country_code", "MX");
              formdata.append("user_id", "4532");
              formdata.append("session_id", "418-VC6Et5UdloC7Q3ia6aP9Ng==");
              formdata.append("command", "modificar_grupo");
              formdata.append("issue", "Lorem ipsum dolor sit amet");
              formdata.append("text", "Lorem ipsum dolor sit amet");
              formdata.append("group_notification_id", "6");
              var requestOptions = {
              method: 'POST',
              body: formdata,
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/notificaciones.py/gestor_notificaciones", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request POST 'URL_BASE/ws/notificaciones.py/gestor_notificaciones' \ --form 'country_code=MX' \ --form 'user_id=4532' \ --form 'session_id=418-VC6Et5UdloC7Q3ia6aP9Ng==' \ --form 'command=modificar_grupo' \ --form 'issue=Lorem ipsum dolor sit amet' \ --form 'text=Lorem ipsum dolor sit amet' \ --form 'group_notification_id=6'`
        }
      ]
    }
    return(
      
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="notification-service-modify-group">
          Notification service &#40;Modify Group&#41;
        </h1>
        <p>
          This service is used to modify a notification sent to all the users in a
          group.
        </p>
        <p>
          <span className="label post">POST:</span>
          <span> </span>{' '}
          <code>URL_BASE + /ws/notificaciones.py/gestor_notificaciones</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request
        </p>
        <div className='overflow-auto'>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
              <td align="center">ES &#40;Spain&#41;</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">Identifier of the user making the request</td>
              <td align="center">N/A</td>
              <td align="center">4532</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
              <td align="center">385-XadgYoWixbzN_wqWc3Yyog==</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
              <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
            </tr>
            <tr>
              <td align="left">command</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                It must contain the value <code>modificar_grupo</code>
              </td>
              <td align="center">N/A</td>
              <td align="center">modificar_grupo</td>
            </tr>
            <tr>
              <td align="left">issue</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">Text to show in the object of the notification</td>
              <td align="center">N/A</td>
              <td align="center">Lorem ipsum dolor sit amet</td>
            </tr>
            <tr>
              <td align="left">text</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">Text to be shown in the notification</td>
              <td align="center">N/A</td>
              <td align="center">Lorem ipsum dolor sit amet</td>
            </tr>
            <tr>
              <td align="left">group_notification_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">Identificator of the group notification</td>
              <td align="center">N/A</td>
              <td align="center">6</td>
            </tr>
          </tbody>
        </table>
        </div>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="respuesta-json-ok">Respuesta JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;
          </li>
          <li>
            <code>codtran:</code> Transaction code the identifies the executed
            operation
          </li>
          <li>
            <code>result:</code> Executed service answer
          </li>
          <li>
            <code>editor:</code> Object that contains the actions assigned to the
            profile, and those that can be added
          </li>
          <li>
            <code>group_notification_id:</code> Identifier of the group notification
            created.
          </li>
          <li>
            <code>elapsed:</code> Executed operation time.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>level:</code> Error importance level.
          </li>
          <li>
            <code>message:</code> Error message.
          </li>
          <li>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          To be able to run this service, it is necessary to do it from a user
          logged into the system who has an administrator profile. With this service
          you can change the subject or the text of the indicated group
          notification.
        </p>
        </div>
        <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
       
        </div>
      </div>
      </Fragment>
    );
  }
export default Servicio_de_notificaciones_Modificar_Grupo_EN;
