import React, { FunctionComponent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container, Image, Form, Button, Spinner, Alert, Modal, Nav, Navbar } from 'react-bootstrap';
import PasswordRecovery from '../Modals/passwordRecovery';
import { useParams } from 'react-router-dom';

import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import Footer from '../functionalComponents/DashboardLayout/FooterMenu';
import Cookies from 'universal-cookie';
import { info } from 'console';
import FooterMenu from '../functionalComponents/DashboardLayout/FooterMenu';
//import Logo101Obex from '../../../assets/img/newDashboard/Logotipo_101OBEX_2024.png';
import Logo101Obex from '../../../src/assets/img/newDashboard/Logotipo_101OBEX_2024.png';
import icon_x from '../../../assets/img/newDashboard/Icon ion-close-circle-outline.png';
import icon_confirm_email from '../../assets/img/newDashboard/Asset_icono_confirm_email.png';
//import icon_confirm_email from '../../../assets/img/newDashboard/Asset_icono_confirm_email.png';
//import Logo from '../../../assets/img/logotipo_ID_1.png';
import Logo from '../../../src/assets/img/logotipo_ID_1.png';
//import info from '../../../assets/img/newDashboard/informacion.png';
import infol from '../../../src/assets/img/newDashboard/informacion.png';

type ChangePasswordProps = {
  userLabel?: string,
  passLabel?: string,
  rememberCheckboxLabel?: string,
  submitButtonLabel?: string,
  missingUserMsg?: string,
  missingPassMsg?: string,
  loginFailedMsg?: string
}

const ChangePassword:FunctionComponent<{}> = () => {

  const { token } = useParams();

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');


  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      setSuccess('');
      setError('');
      if (password !== repeatPassword) throw { message: 'Passwords do not match, try again' };
      const payload = { token, password };
      const result = await ObexRequestHandler.post('/reset_password', payload);
      const { success, message, data } = result;
      if (!success) throw { message };
      setSuccess(data);

      setTimeout(function() { 
        const path = '/login';
        window.location.href = path;
        }.bind(this), 4000)


    } catch (error) {
      console.error('ERROR WHILE CHANGING PASSWORD ', error);
      setError(error.message || error);
    }
    setLoading(false);
  };
  
  const handleFirstPass = (e) => {
    const { value } = e.target;
    setPassword(value);
  }

  const handleSecondPass = (e) => {
    const { value } = e.target;
    setRepeatPassword(value);
  }

  function setShowModal(arg0: boolean) {
    throw new Error('Function not implemented.');
  }

  return(
    <div className="text-center d-flex flex-column containerLogin">
      <Navbar className='navbar'>
        <Container className="">
          <Navbar.Brand href="#home" style={{}}>
            <Image src={Logo101Obex}  style={{marginBottom:"5px"}} width="" alt="Logo" />
          </Navbar.Brand>
          <Nav style={{ width: "80%" }}>
            <Nav.Link href="#section1" style={{marginRight:"10px", marginLeft:"10px"}}>Products</Nav.Link>
            <Nav.Link href="#section2" style={{marginRight:"10px", marginLeft:"10px"}}>Partners</Nav.Link>
            <Nav.Link href="#section3" style={{marginRight:"10px", marginLeft:"10px"}}>Resource</Nav.Link>
            <Nav.Link href="#section4" style={{marginRight:"10px", marginLeft:"10px"}}>Company</Nav.Link>
            <Nav.Link href="#section4" style={{marginRight:"10px", marginLeft:"10px"}}>Contact Sales</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      
        <>
          <div className="justify-content-center  rowLogin" style={{ marginBottom: "74px" }}>
            <div className="login-form px-5 pt-4">
              <Row className="justify-content-center mt-5">
                <Col>
                  <Image src={Logo}  fluid
                    width="180"
                    style={{ marginBottom: '60px' }}
                  />
                  <h3 className='text-boldLogin mb-4'>Reset your password</h3>
                  <h6 className='text-boldLogin mb-3'>Enter your email addressthat you use with your account to continue.</h6>
                </Col>
              </Row>

              <Form onSubmit={handleSubmit} className="obex-form theForm">
          

                <Form.Group controlId="formBasicPassword">
                  <div className='d-flex'>
                    <Form.Control onChange={handleFirstPass} required type="password" placeholder="New password" className="borderRadiusLeftLogin" value={password}  />
                    <div className="borderRadiusRightLogin btnNew btn-block info-icon-container">
                    <img
                        src={infol}
                        alt="Info"
                        width="15px"
                        height="15px"
                        className="info-icon"
                        style={{ marginTop: "10px" }}
                      />
                      <div className="tooltip-text" style={{zIndex: 9999999999}}>
                        <ul>
                          <li>Your password must have:</li>
                          <li>8 or more characters</li>
                          <li>Upper & lowercase letters</li>
                          <li>At least one number</li>
                        </ul>
                      </div>
          
                    </div>
                  </div>
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control onChange={handleSecondPass} required type="password" placeholder="Confirm new password" className="borderRadiusLogin" />
                </Form.Group>
                <Button type="submit" className="custom-button textBold mt-4"  style={{ display: "block", borderRadius:"9px" }}>
                  Continue
                  {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                </Button>
                {error.length > 0 && (
                <Alert variant='danger'>
                  {error}
                </Alert>)}
                {success.length > 0 && (
                  <Alert variant='success'>
                    {success}
                  </Alert>)}
              </Form>
            </div>
          </div>


          <Row className="mt-auto">
            <Col className="px-0">
              {/* <Footer /> */}
            </Col>
          </Row>
        </>
      
      <div>
        <FooterMenu />
      </div>
    </div>
  );
}


export default ChangePassword;


/*
<Col>
<Form onSubmit={handleSubmit}>
  <Form.Group controlId="formBasicEmail">
    <Form.Control onChange={handleFirstPass} required type="password" placeholder="New Password"/>
  </Form.Group>
  <Form.Group controlId="formBasicPassword">
    <Form.Control onChange={handleSecondPass} required type="password" placeholder="Repeat new Password"/>
  </Form.Group>
  
</Form>
</Col>
*/
