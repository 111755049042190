import React, { Fragment } from 'react';

const TERMINAL_Shell_Integration = () => (
  <Fragment>
    <h1 id="terminal-shell-integration">Terminal Shell Integration</h1>
    <p>
      AVAP<sup>TM</sup> Dev Studio has the ability to integrate with common
      shells, allowing the terminal to understand more about what&#39;s actually
      happening inside the shell. This additional information enables some
      useful features such as{' '}
      <a href="#_current-working-directory-detection">
        working directory detection
      </a>{' '}
      and command detection,{' '}
      <a href="#_command-decorations-and-the-overview-ruler">decorations</a>,
      and <a href="#_command-navigation">navigation</a>.
    </p>
    <p>Supported shells:</p>
    <ul>
      <li>Linux/macOS: bash, fish, pwsh, zsh</li>
      <li>Windows: pwsh</li>
    </ul>
    <h2 id="installation">Installation</h2>
    <h3 id="automatic-script-injection">Automatic script injection</h3>
    <p>
      By default, the shell integration script should automatically activate on
      supported shells launched from AVAP<sup>TM</sup> Dev Studio. This is done
      by injecting arguments and/or environment variables when the shell session
      launches. This automatic injection can be disabled by setting{' '}
      <code>terminal.integrated.shellIntegration.enabled</code> to{' '}
      <code>false</code>.
    </p>
    <p>
      This standard, easy way will not work for some advanced use cases like in
      sub-shells, through a regular ssh session &#40;when not using the{' '}
      <a href="/docs/remote/ssh">Remote - SSH extension</a>&#41; or for some
      complex shell setups. The recommended way to enable shell integration for
      those is <a href="#_manual-installation">manual installation</a>.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: Automatic injection may not work on old versions
        of the shell, for example older versions of fish do not support the
        $XDG_DATA_DIRS environment variable which is how injection works. You
        may still be able to manually install to get it working.
      </p>
    </blockquote>
    <h3 id="manual-installation">Manual installation</h3>
    <p>
      To manually install shell integration, the AVAP<sup>TM</sup> Dev Studio
      shell integration script needs to run during your shell&#39;s
      initialization. Where and how to do this depends on the shell and OS
      you&#39;re using. When using manual install it&#39;s recommended to set{' '}
      <code>terminal.integrated.shellIntegration.enabled</code> to false, though
      not mandatory.
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> When using the [Insiders build], replace{' '}
        <code>code</code> with <code>code-insiders</code> below.
      </p>
    </blockquote>
    <p>
      <strong>bash</strong>
    </p>
    <p>
      Add the following to your <code>~/.bashrc</code> file. Run{' '}
      <code>code ~/.bashrc</code> in bash to open the file in AVAP<sup>TM</sup>{' '}
      Dev Studio.
    </p>

    <pre className="">
      <code className="">
        [[ "$TERM\_PROGRAM" == "vscode" ]] &amp;&amp; . "$&#40;code
        --locate-shell-integration-path bash&#41;"
      </code>
    </pre>
    <p>
      <strong>fish</strong>
    </p>
    <p>
      Add the following to your <code>config.fish</code>. Run{' '}
      <code>code $\_\_fish\_config\_dir/config.fish</code> in fish to open the
      file in AVAP<sup>TM</sup> Dev Studio.
    </p>

    <pre className="">
      <code className="">
        string match -q "$TERM\_PROGRAM" "vscode"
        <br />
        and . &#40;code --locate-shell-integration-path fish&#41;
      </code>
    </pre>
    <p>
      <strong>pwsh</strong>
    </p>
    <p>
      Add the following to your [PowerShell profile]. Run{' '}
      <code>code $Profile</code> in pwsh to open the file in AVAP<sup>TM</sup>{' '}
      Dev Studio.
    </p>

    <pre className="">
      <code className="">
        if &#40;$env:TERM\_PROGRAM -eq "vscode"&#41; &#123; . "$&#40;code
        --locate-shell-integration-path pwsh&#41;" &#125;
      </code>
    </pre>
    <p>
      <strong>zsh</strong>
    </p>
    <p>
      Add the following to your <code>~/.zshrc</code> file. Run{' '}
      <code>code ~/.zshrc</code> in bash to open the file in AVAP<sup>TM</sup>{' '}
      Dev Studio.
    </p>

    <pre className="">
      <code className="">
        [[ "$TERM\_PROGRAM" == "vscode" ]] &amp;&amp; . "$&#40;code
        --locate-shell-integration-path zsh&#41;"
      </code>
    </pre>
    <p>
      <strong>Git Bash</strong>
    </p>
    <p>
      âš ï¸ This is currently experimental and automatic injection is not
      supported
    </p>
    <p>
      Add the following to your <code>~/.bashrc</code> file. Run code{' '}
      <code>~/.bashrc</code> in Git Bash to open the file in AVAP<sup>TM</sup>{' '}
      Dev Studio.
    </p>

    <pre className="">
      <code className="">
        [[ "$TERM\_PROGRAM" == "vscode" ]] &amp;&amp; . "$&#40;code
        --locate-shell-integration-path bash&#41;"
      </code>
    </pre>
    <h4 id="portability-versus-performance">Portability versus performance</h4>
    <p>
      The above shell integration installation is cross-platform and compatible
      with any installation type if code is in the <code>$PATH</code>. However,
      this recommended approach starts Node.js to fetch the script path, leading
      to a slight delay in shell startup. To mitigate this delay, inline the
      script above by resolving the path ahead of time and adding it directly
      into your init script.
    </p>

    <pre className="">
      <code className="">
        \# Output the executable's path first:
        <br />
        code --locate-shell-integration-path bash
        <br />
        <br />
        \# Add the result of the above to the source statement:
        <br />
        [[ "$TERM\_PROGRAM" == "vscode" ]] &amp;&amp; .
        "/path/to/shell/integration/script.sh"
      </code>
    </pre>
    <h2 id="command-decorations-and-the-overview-ruler">
      Command decorations and the overview ruler
    </h2>
    <p>
      One of the things that shell integration enables is the ability to get the
      exit codes of the commands run within the terminal. Using this
      information, decorations are added to the left of the line to indicate
      whether the command succeeded or failed. These decorations also show up in
      the relatively new overview ruler in the scroll bar, just like in the
      editor.
    </p>
    <p>
      The decorations can be interacted with to give some contextual actions
      like re-running the command:
    </p>
    <p>
      The command and overview ruler decorations can be configured with the{' '}
      <code>terminal.integrated.shellIntegration.decorationsEnabled</code>{' '}
      setting.
    </p>
    <h2 id="command-navigation">Command navigation</h2>
    <p>
      The commands detected by shell integration feed into the command
      navigation feature &#40;<code>Ctrl/Cmd+Up, Ctrl/Cmd+Down</code>&#41; to
      give it more reliable command positions. This feature allows for quick
      navigation between commands and selection of their output. To select from
      the current position to the command, you can also hold down{' '}
      <code>Shift</code>, pressing <code>Shift+Ctrl/Cmd+Up</code> and{' '}
      <code>Shift+Ctrl/Cmd+Down</code>.
    </p>
    <h2 id="sticky-scroll">Sticky scroll</h2>
    <p>
      The sticky scroll feature will &quot;stick&quot; the command that is
      partially showing at the top of the terminal, making it much easier to see
      what command that output belongs to. Clicking on the sticky scroll
      component will scroll to the command&#39;s location in the terminal
      buffer.
    </p>
    <p>
      This can be enabled with the{' '}
      <code>terminal.integrated.stickyScroll.enabled</code> setting.
    </p>
    <h2 id="quick-fixes">Quick fixes</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio scans the output of a command and presents a
      Quick Fix with actions that have a high likelihood of being what the user
      will want to do next.
    </p>
    <p>Here are some of the built-in Quick Fixes:</p>
    <ul>
      <li>
        When it&#39;s detected that a port is already being listened to, suggest
        to kill the process and re-run the previous command.
      </li>
      <li>
        When <code>git push</code> fails due to an upstream not being set,
        suggest to push with the upstream set.
      </li>
      <li>
        When a <code>git</code> subcommand fails with a similar command error,
        suggest to use the similar command&#40;s&#41;.
      </li>
      <li>
        When <code>git push</code> results in a suggestion to create a GitHub
        PR, suggest to open the link.
      </li>
      <li>
        When a <code>General</code> or <code>cmd-not-found</code> PowerShell
        feedback provider triggers, suggest each suggestion.
      </li>
    </ul>
    <p>
      The Quick Fix feature also supports{' '}
      <a href="/docs/editor/accessibility#_audio-cues">audio cues</a> for
      additional feedback when a Quick Fix is available.
    </p>
    <h2 id="run-recent-command">Run recent command</h2>
    <p>
      The <strong>Terminal: Run Recent Command</strong> command surfaces history
      from various sources in a Quick Pick, providing similar functionality to a
      shell&#39;s reverse search &#40;<code>Ctrl+R</code>&#41;. The sources are
      the current session&#39;s history, previous session history for this shell
      type and the common shell history file.
    </p>
    <p>Some other functionality of the command:</p>
    <ul>
      <li>
        By default the search mode is &quot;contiguous search&quot;, meaning the
        search term must exactly match. The button on the right of the search
        input allows switching to fuzzy search.
      </li>
      <li>
        In the current session section, there is a clipboard icon in the right
        of the Quick Pick that will open the command output in an editor.
      </li>
      <li>
        The pin action in the right of the Quick Pick can pin the command to the
        top of the list.
      </li>
      <li>
        Alt can be held to write the text to the terminal without running it.
      </li>
      <li>
        The amount of history stored in the previous session section is
        determined by the terminal.integrated.shellIntegration.history setting.
      </li>
    </ul>
    <p>
      The default keybinding for this command is <code>Ctrl+Alt+R</code>.
      However, when accessibility mode is on these are reversed;{' '}
      <code>Ctrl+R</code> runs a recent command and <code>Ctrl+Alt+R</code>{' '}
      sends Ctrl+R to the shell.
    </p>
    <p>
      The keybindings can be flipped when accessibility mode is off with the
      following keybindings:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"key": "ctrl+r",
        <br />` `"command": "workbench.action.terminal.runRecentCommand",
        <br />` `"when": "terminalFocus"
        <br />
        &#125;,
        <br />
        &#123;
        <br />` `"key": "ctrl+alt+r",
        <br />` `"command": "workbench.action.terminal.sendSequence",
        <br />` `"args": &#123; "text": "\u0012"/\*^R\*/ &#125;,
        <br />` `"when": "terminalFocus"
        <br />
        &#125;
      </code>
    </pre>
    <h2 id="go-to-recent-directory">Go to recent directory</h2>
    <p>
      Similar to the run recent command feature, the{' '}
      <strong>Terminal: Go to Recent Directory</strong> command keeps track of
      directories that have been visited and allows quick filtering and
      navigating &#40;<code>cd</code>&#41; to them. <code>Alt</code> can be held
      to write the text to the terminal without running it.
    </p>
    <p>
      The default keybinding for this command is <code>Ctrl+G</code> as it
      behaves similar to the <strong>Go to Line/Column</strong> command in the
      editor. Ctrl+G can be send to the shell with <code>Ctrl+Alt+G</code>.
    </p>
    <h2 id="current-working-directory-detection">
      Current working directory detection
    </h2>
    <p>
      Shell integration tells AVAP<sup>TM</sup> Dev Studio what the current
      working directory of the shell is. This information is not possible to get
      on Windows without trying to detect the prompt through regex and requires
      polling on macOS and Linux, which isn&#39;t good for performance.
    </p>
    <p>
      One of the biggest features this enables is enhanced resolving of links in
      the terminal. Take a link <code>package.json</code> for example, when the
      link is activated while shell integration is disabled this will open a
      search quick pick with <code>package.json</code> as the filter if there
      are multiple <code>package.json</code> files in the workspace. When shell
      integration is enabled however, it will open the <code>package.json</code>{' '}
      file in the current folder directly because the current location is known.
      This allows the output of <code>ls</code> for example to reliably open the
      correct file.
    </p>
    <p>
      The current working directory is also used to show the directory in the
      terminal tab, in the run recent command quick pick and for the{' '}
      <code>&quot;terminal.integrated.splitCwd&quot;</code>:{' '}
      <code>&quot;inherited&quot;</code> feature.
    </p>
    <h2 id="extended-powershell-keybindings">
      Extended PowerShell keybindings
    </h2>
    <p>
      Windows&#39; console API allows for more keybindings than Linux/macOS
      terminals, since AVAP<sup>TM</sup> Dev Studio&#39;s terminal emulates the
      latter even on Windows there are some PowerShell keybindings that
      aren&#39;t possible using the standard means due to lack of VT encoding
      such as <code>Ctrl+Space</code>. Shell integration allows AVAP
      <sup>TM</sup> Dev Studio to attach a custom keybindings to send a special
      sequence to PowerShell that then gets handled in the shell integration
      script and forwarded to the proper key handler.
    </p>
    <p>
      The following keybindings should work in PowerShell when shell integration
      is enabled:
    </p>
    <ul>
      <li>
        Ctrl+Space: Defaults to <code>MenuComplete</code> on Windows only
      </li>
      <li>
        Alt+Space: Defaults to <code>SetMark</code> on all platforms
      </li>
      <li>
        Shift+Enter: Defaults to <code>AddLine</code> on all platforms
      </li>
      <li>
        Shift+End: Defaults to <code>SelectLine</code> on all platforms
      </li>
      <li>
        Shift+Home: Defaults to <code>SelectBackwardsLine</code> on all
        platforms
      </li>
    </ul>
    <h2 id="enhanced-accessibility">Enhanced accessibility</h2>
    <p>
      The information that shell integration provides to AVAP<sup>TM</sup> Dev
      Studio is used to improve{' '}
      <a href="/docs/editor/accessibility#_terminal-accessibility">
        accessibility in the terminal
      </a>
      . Some examples of enhancements are:
    </p>
    <ul>
      <li>
        Navigation through detected commands in the accessible buffer
        &#40;Alt+F2&#41;
      </li>
      <li>
        An <a href="/docs/editor/accessibility#_audio-cues">audio cue</a> plays
        when a command fails.
      </li>
      <li>
        Underlying text box synchronizing such that using the arrow and
        backspace keys behave more correctly.
      </li>
    </ul>
    <h2 id="supported-escape-sequences">Supported escape sequences</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio supports several custom escape sequences:
    </p>
    <h3 id="avapsuptmsup-dev-studio-custom-sequences-osc-633---st">
      AVAP<sup>TM</sup> Dev Studio custom sequences &#39;OSC 633 ; ... ST&#39;
    </h3>
    <p>
      AVAP<sup>TM</sup> Dev Studio has a set of custom escape sequences designed
      to enable the shell integration feature when run in AVAP<sup>TM</sup> Dev
      Studio&#39;s terminal. These are used by the built-in scripts but can also
      be used by any application capable of sending sequences to the terminal,
      for example the{' '}
      <a href="https://marketplace.visualstudio.com/items?itemName=julialang.language-julia">
        Julia extension
      </a>{' '}
      uses these to support shell integration in the Julia REPL.
    </p>
    <p>
      These sequences should be ignored by other terminals, but unless other
      terminals end up adopting the sequences more widely, it&#39;s recommended
      to check that <code>$TERM\_PROGRAM</code> is <code>vscode</code> before
      writing them.
    </p>
    <ul>
      <li>
        <p>
          <code>OSC 633 ; A ST</code> - Mark prompt start.
        </p>
      </li>
      <li>
        <p>
          <code>OSC 633 ; B ST</code> - Mark prompt end.
        </p>
      </li>
      <li>
        <p>
          <code>OSC 633 ; C ST</code> - Mark pre-execution.
        </p>
      </li>
      <li>
        <p>
          <code>OSC 633 ; D [; &lt;exitcode&gt;] ST</code> - Mark execution
          finished with an optional exit code.
        </p>
      </li>
      <li>
        <p>
          <code>OSC 633 ; E ; &lt;commandline&gt; ST</code> - Explicitly set the
          command line.
        </p>
        <p>
          The E sequence allows the terminal to reliably get the exact command
          line interpreted by the shell. When this is not specified, the
          terminal may fallback to using the A, B and C sequences to get the
          command, or disable the detection all together if it&#39;s unreliable.
        </p>
        <p>
          The optional nonce can be used to verify the sequence came from the
          shell integration script to prevent command spoofing. When the nonce
          is verified successfully, some protections before using the commands
          will be removed for an improved user experience.
        </p>
        <p>
          The command line can escape ASCII characters using the{' '}
          <code>\xAB</code> format, where AB are the hexadecimal representation
          of the character code &#40;case insensitive&#41;, and escape the \
          character using \. It&#39;s required to escape semi-colon &#40;
          <code>0x3b</code>&#41; and characters 0x20 and below and this is
          particularly important for new line and semi-colon.
        </p>
        <p>Some examples:</p>
        <p> &quot;&quot; -&gt; &quot;\&quot;</p>

        <pre className="">
          <code className="">
            "\n" -&gt; "\x0a"
            <br />
            ";" -&gt; "\x3b"
          </code>
        </pre>
      </li>
      <li>
        <p>
          <code>OSC 633 ; P ; &lt;Property&gt;=&lt;Value&gt; ST</code> - Set a
          property on the terminal, only known properties will be handled.
        </p>
        <p>Known properties:</p>
        <ul>
          <li>
            <code>Cwd</code> - Reports the current working directory to the
            terminal.
          </li>
          <li>
            <code>IsWindows</code> - Indicates whether the terminal is using a
            Windows backend like winpty or conpty. This may be used to enable
            additional heuristics as the positioning of the shell integration
            sequences are not guaranteed to be correct. Valid values are True
            and False.
          </li>
        </ul>
      </li>
    </ul>
    <h3 id="final-term-shell-integration">Final Term shell integration</h3>
    <p>
      AVAP<sup>TM</sup> Dev Studio supports Final Term&#39;s shell integration
      sequences, which allow non-AVAP<sup>TM</sup> Dev Studio shell integration
      scripts to work in AVAP<sup>TM</sup> Dev Studio. This results in a
      somewhat degraded experience as it doesn&#39;t support as many features as{' '}
      <code>OSC 633</code>. Here are the specific sequences that are supported:
    </p>
    <ul>
      <li>
        <code>OSC 133 ; A ST</code> - Mark prompt start.
      </li>
      <li>
        <code>OSC 133 ; B ST</code> - Mark prompt end.
      </li>
      <li>
        <code>OSC 133 ; C ST</code> - Mark pre-execution.
      </li>
      <li>
        <code>OSC 133 ; D [; &lt;exitcode&gt;] ST</code> - Mark execution
        finished with an optional exit code.
      </li>
    </ul>
    <h3 id="setmark-osc-1337--setmark-st">
      SetMark &#39;OSC 1337 ; SetMark ST&#39;
    </h3>
    <p>
      This sequence adds a mark to the left of the line it was triggered on and
      also adds an annotation to the scroll bar:
    </p>
    <p>
      These marks integrate with command navigation to make them easy to
      navigate to via ctrl/cmd+up and ctrl/cmd+down by default.
    </p>
    <h2 id="common-questions">Common questions</h2>
    <h3 id="when-does-automatic-injection-not-work">
      When does automatic injection not work?
    </h3>
    <p>
      There are several cases where automatic injection doesn&#39;t work, here
      are some common cases:
    </p>
    <ul>
      <li>
        <p>
          <code>$PROMPT\_COMMAND</code> is in an unsupported format, changing it
          to point to a single function is an easy way to work around this. For
          example:
        </p>
        <p> prompt&#40;&#41; &#123;</p>

        <pre className="">
          <code className="">
            ` `printf "\033]0;%s@%s:%s\007" "$&#123;USER&#125;"
            "$&#123;HOSTNAME%%.\*&#125;" "$&#123;PWD/#$HOME/\~&#125;"
            <br />
            &#125;
            <br />
            PROMPT\_COMMAND=prompt
          </code>
        </pre>
      </li>
      <li>
        <p>
          Some shell plugins may disable AVAP<sup>TM</sup> Dev Studio&#39;s
          shell integration explicitly by unsetting{' '}
          <code>$VSCODE\_SHELL\_INTEGRATION</code> when they initialize.
        </p>
      </li>
    </ul>
    <h3 id="why-are-command-decorations-showing-when-the-feature-is-disabled">
      Why are command decorations showing when the feature is disabled?
    </h3>
    <p>
      The likely cause of this is that your system has shell integration for
      another terminal installed that{' '}
      <a href="#_final-term-shell-integration">
        AVAP<sup>TM</sup> Dev Studio understands
      </a>
      . If you don&#39;t want any decorations, you can hide them with the
      following setting:
    </p>

    <pre className="">
      <code className="">
        "terminal.integrated.shellIntegration.decorationsEnabled": never
      </code>
    </pre>
    <p>
      Alternatively, you could remove the shell integration script from your
      shell rc/startup script but you will lose access to command-aware features
      like <a href="#_command-navigation">command navigation</a>.
    </p>
    <h3 id="why-does-the-command-decoration-jump-around-on-windows">
      Why does the command decoration jump around on Windows?
    </h3>
    <p>
      Windows uses an emulated pseudoterminal &#40;pty&#41; backend called
      ConPTY. It works a little differently to a regular pty because it needs to
      maintain compatibility with the Windows Console API. One of the impacts of
      this is the pty handles rendering specially in such a way that the shell
      integration sequences that identify the commands in the terminal buffer
      may be misplaced. When the command jumps around it&#39;s typically after a
      command has run, and AVAP<sup>TM</sup> Dev Studio&#39;s heuristics have
      kicked in to improve the position of the command decorations.
    </p>
  </Fragment>
);
export default TERMINAL_Shell_Integration;
