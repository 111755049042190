import React, { Fragment } from 'react';

const Settings = () => (
  <Fragment>
    <h1 >User and Workspace Settings</h1>
    <p>
      You can configure AVAP<sup>TM</sup> Dev Studio to your liking through its
      various settings. Nearly every part of AVAP<sup>TM</sup> Dev Studio&#39;s
      editor, user interface, and functional behavior has options you can
      modify.
    </p>
    <p>
      AVAP<sup>TM</sup> Dev Studio provides several different scopes for
      settings. When you open a workspace, you will see at least the following
      two scopes:
    </p>
    <ul>
      <li>
        <strong>User Settings</strong> - Settings that apply globally to any
        instance of AVAP<sup>TM</sup> Dev Studio you open.
      </li>
      <li>
        <strong>Workspace Settings</strong> - Settings stored inside your
        workspace and only apply when the workspace is opened.
      </li>
    </ul>
    <p>
      In this article, we&#39;ll first describe user settings as these are your
      personal settings for customizing AVAP<sup>TM</sup> Dev Studio. Later
      we&#39;ll cover Workspace settings, which will be specific to the project
      you&#39;re working on.
    </p>
    <h3 >Settings editor</h3>
    <p>
      To modify user settings, you&#39;ll use the Settings editor to review and
      change AVAP<sup>TM</sup> Dev Studio settings.
    </p>
    <p>
      To open the Settings editor, navigate to <strong>File</strong> &gt;{' '}
      <strong>Preferences</strong> &gt; <strong>Settings</strong>.
    </p>
    <p>
      You can also open the Settings editor from the{' '}
      <strong>Command Palette</strong> &#40;<code>Ctrl+Shift+P</code>&#41; with{' '}
      <strong>Preferences</strong>: <strong>Open Settings</strong> or use the
      keyboard shortcut &#40;<code>Ctrl+,</code>&#41;.
    </p>
    <p>
      When you open the Settings editor, you can search and discover the
      settings you are looking for. When you search using the Search bar, it
      will not only show and highlight the settings matching your criteria, but
      also filter out those which are not matching. This makes finding settings
      quick and easy.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/settings-search.png"
        alt="1"
        title="1"
      />
    </p>
    <p>
      In the example below, the Side Bar location and file icon theme have been
      changed.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/settings-modified.png"
        alt="2"
        title="2"
      />
    </p>
    <p>
      Changes to settings are applied by AVAP<sup>TM</sup> Dev Studio as you
      change them. Modified settings are indicated with a blue line similar to
      modified lines in the editor.
    </p>
    <p>
      The gear icon &#40;<strong>More Actions</strong>... <code>Shift+F9</code>
      &#41; opens a context menu with options to reset the setting to its
      default value as well as copy the setting ID or JSON name-value pair.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/more-actions-context-menu.png"
        alt="3"
        title="3"
      />
    </p>
    <h4 >Edit settings</h4>
    <p>
      Each setting can be edited by either a <strong>checkbox</strong>, an{' '}
      <strong>input</strong> or a <strong>dropdown</strong>. Edit the text or
      select the option you want to change to the desired settings.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/settings-edit.png"
        alt="4"
        title="4"
      />
    </p>
    <h4 >Settings groups</h4>
    <p>
      Settings are represented in groups so that you can navigate them easily.
      There is a <strong>Commonly Used</strong> group at the top, which shows
      popular customizations.
    </p>
    <p>
      Below, the <strong>Source Control</strong> settings are focused by
      selecting Source Control in the tree view.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/settings-groups.png"
        alt="5"
        title="5"
      />
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: AVAP<sup>TM</sup> Dev Studio extensions can also
        add their own custom settings, and those settings will be visible under
        an Extensions section.
      </p>
    </blockquote>
    <h3 >Changing a setting</h3>
    <p>
      As an example, let&#39;s hide the Activity Bar from AVAP<sup>TM</sup> Dev
      Studio. The Activity Bar is the wide border on the left with various icons
      for different views such as the File Explorer, Search, Source Control, and
      Extensions. You might want to hide the Activity Bar to give the editor a
      little more room, or if you prefer to open views via the{' '}
      <strong>View</strong> menu or Command Palette.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/activity-bar.png"
        alt="6"
        title="6"
      />
    </p>
    <p>
      Open the Settings Editor &#40;<code>Ctrl+,</code>&#41; and type
      &quot;activity&quot; in the Search bar. You should see at least five
      settings.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/search-for-activity.png"
        alt="7"
        title="7"
      />
    </p>
    <p>
      You can further limit the scope to just those settings under the{' '}
      <strong>Appearance</strong> group in the table of contents on the left.
      There should now be just three settings.
    </p>
    <p>
      You can now check and uncheck the <strong>Workbench</strong> &gt;{' '}
      <strong>Activity Bar: Visible</strong> setting to hide and unhide the
      Activity Bar. Notice that when you have changed the setting value to be
      different than the default value, you see a blue line to the left.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/activity-bar-hidden.png"
        alt="8"
        title="8"
      />
    </p>
    <p>
      You can always reset a setting to the default value by hovering over a
      setting to show the gear icon, clicking on the gear icon, and then
      selecting the <strong>Reset Setting</strong> action.
    </p>
    <h3 >Settings editor filters</h3>
    <p>
      The Settings editor Search bar has several filters to make it easier to
      manage your settings. To the right of the Search bar is a filter button
      with a funnel icon that provides some options to easily add a filter to
      the Search bar.
    </p>
    <h4 >Modified settings</h4>
    <p>
      To check which settings you have configured, there is a @modified filter
      in the Search bar. A setting shows up under this filter if its value
      differs from the default value, or if its value is explicitly set in the
      respective settings JSON file. This filter can be useful if you have
      forgotten whether you configured a setting, or if the editor is not
      behaving as you expect because you accidentally configured a setting.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/modified-filter-settings.png"
        alt="9"
        title="9"
      />
    </p>
    <h4 >Other filters</h4>
    <p>
      There are several other handy filters to help with searching through
      settings.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/settings-editor-filters.png"
        alt="10"
        title="10"
      />
    </p>
    <p>Here are some of the filters available:</p>
    <ul>
      <li>
        @ext - Settings specific to an extension. You provide the extension ID
        such as @ext:ms-python.python.
      </li>
      <li>
        @feature - Settings specific to a <strong>Features</strong> subgroup.
        For example, @feature:explorer shows settings of the File Explorer.
      </li>
      <li>
        @id - Find a setting based on the setting ID. For example,
        @id:workbench.activityBar.visible.
      </li>
      <li>
        @lang - Apply a language filter based on a language ID. For example,
        @lang:typescript. See Language-specific editor settings for more
        details.
      </li>
      <li>
        @tag - Settings specific to a system of AVAP<sup>TM</sup> Dev Studio.
        For example, @tag:workspaceTrust for settings related to Workspace
        Trust, or @tag:accessibility for settings related to accessibility.
      </li>
    </ul>
    <p>
      The Search bar remembers your settings search queries and supports
      Undo/Redo &#40;<code>Ctrl+Z/Ctrl+Y</code>&#41;. You can quickly clear a
      search term or filter with the{' '}
      <strong>Clear Settings Search Input</strong> button at the right of the
      Search bar.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/clear-search-input-button.png"
        alt="11"
        title="11"
      />
    </p>
    <h3 >Extension settings</h3>
    <p>
      Installed AVAP<sup>TM</sup> Dev Studio extensions can also contribute
      their own settings, which you can review under the Extensions section of
      the Settings editor.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/cpp-extension-settings.png"
        alt="12"
        title="12"
      />
    </p>
    <p>
      You can also review an extension&#39;s settings from the Extensions view
      &#40;<code>Ctrl+Shift+X</code>&#41; by selecting the extension and
      reviewing the <strong>Feature Contributions</strong> tab.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/python-feature-contributions.png"
        alt="13"
        title="13"
      />
    </p>
    <p>
      Extension authors can learn more about adding custom settings in the
      configuration contribution point documentation.
    </p>
    <h3 >settings.json</h3>
    <p>
      The Settings editor is the UI that lets you review and modify setting
      values that are stored in a settings.json file. You can review and edit
      this file directly by opening it in the editor with the{' '}
      <strong>Preferences: Open User Settings &#40;JSON&#41;</strong> command.
      Settings are written as JSON by specifying the setting ID and value.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/settings-json-in-editor.png"
        alt="14"
        title="14"
      />
    </p>
    <p>
      The settings.json file has full IntelliSense with smart completions for
      settings and values and description hovers. Errors due to incorrect
      setting names or JSON formatting are also highlighted.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/settings-json-intellisense.png"
        alt="15"
        title="15"
      />
    </p>
    <p>
      Some settings can only be edited in settings.json such as{' '}
      <strong>Workbench: Color Customizations</strong> and show a{' '}
      <strong>Edit in settings.json</strong> link in the Settings editor.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/edit-in-settings-json-link.png"
        alt="16"
        title="16"
      />
    </p>
    <h4 >Changing settings.json</h4>
    <p>
      As an example, lets change the editor line number color. Click the Edit in
      settings.json link and add the following JSON:
    </p>

    <pre className="">
      <code className="">
        "workbench.colorCustomizations": &#123;
        <br /> "editorLineNumber.foreground": "#00ff00"
        <br /> &#125;
      </code>
    </pre>
    <p>
      Here the line numbers in the editor for the settings.json file are now
      green.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/color-customization-example.png"
        alt="17"
        title="17"
      />
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: The example above changes the editor line number
        for all Color Themes, but you can tune colors per specific Color Theme
        or even create your own Color Theme extension.
      </p>
    </blockquote>
    <p>
      If you prefer to always work directly with settings.json, you can set
      &quot;workbench.settings.editor&quot;: &quot;json&quot; so that{' '}
      <strong>File</strong> &gt; <strong>Preferences</strong> &gt;{' '}
      <strong>Settings</strong> and the keybinding <code>Ctrl+,</code> always
      opens the settings.json file and not the Setting editor UI.
    </p>
    <h4 >Settings file locations</h4>
    <p>Depending on your platform, the user settings file is located here:</p>
    <ul>
      <li>Windows %APPDATA%\Code\User\settings.json</li>
      <li>macOS $HOME/Library/Application\ Support/Code/User/settings.json</li>
      <li>Linux $HOME/.config/Code/User/settings.json</li>
    </ul>
    <h4 >Reset all settings</h4>
    <p>
      While you can reset settings individually via the Settings editor{' '}
      <strong>Reset Setting</strong> command, you can reset all changed settings
      by opening settings.json and deleting the entries between the braces
      &#123;&#125;. Be careful since there will be no way to recover your
      previous setting values.
    </p>
    <h3 >Workspace settings</h3>
    <p>
      Workspace settings are specific to a project and can be shared across
      developers on a project. Workspace settings override user settings.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: A AVAP<sup>TM</sup> Dev Studio
        &quot;workspace&quot; is usually just your project root folder.
        Workspace settings as well as debugging and task configurations are
        stored at the root in a .vscode folder. You can also have more than one
        root folder in a AVAP<sup>TM</sup> Dev Studio workspace through a
        feature called Multi-root workspaces. You can learn more in the What is
        a AVAP<sup>TM</sup> Dev Studio &quot;workspace&quot;? article.
      </p>
    </blockquote>
    <p>
      You can edit via the Settings editor <strong>Workspace</strong> tab or
      open that tab directly with the{' '}
      <strong>Preferences: Open Workspace Settings</strong> command.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/settings-editor-workspace-tab.png"
        alt="18"
        title="18"
      />
    </p>
    <p>
      All features of the Settings editor such as settings groups, search, and
      filtering behave the same for Workspace settings. Not all User settings
      are available as Workspace settings. For example, application-wide
      settings related to updates and security can not be overridden by
      Workspace settings.
    </p>
    <h4 >
      Workspace settings.json location
    </h4>
    <p>
      Similar to User Settings, Workspace Settings are also stored in a
      settings.json file, which you can edit directly via the{' '}
      <strong>Preferences: Open Workspace Settings &#40;JSON&#41;</strong>{' '}
      command.
    </p>
    <p>
      The workspace settings file is located under the .vscode folder in your
      root folder.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/settings-json-under-vscode.png"
        alt="19"
        title="19"
      />
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: For a Multi-root Workspace, workspace settings
        are located inside the workspace configuration file.
      </p>
    </blockquote>
    <p>
      When you add a Workspace Settings settings.json file to your project or
      source control, the settings for the project will be shared by all users
      of that project.
    </p>
    <h3 >
      Language specific editor settings
    </h3>
    <p>
      One way to customize language-specific settings is by opening the Settings
      editor, pressing on the filter button, and selecting the language option
      to add a language filter. Alternatively, one can directly type a language
      filter of the form @lang:languageId into the search widget. The settings
      that show up will be configurable for that specific language, and will
      show the setting value specific to that language, if applicable.
    </p>
    <p>
      When modifying a setting while there is a language filter in place, the
      setting will be configured in the given scope for that language. For
      example, when modifying the user-scope diffEditor.codeLens setting while
      there is a @lang:css filter in the search widget, the Settings editor will
      save the new value to the CSS-specific section of the user settings file.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/settings-css-example.png"
        alt="20"
        title="20"
      />
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: If you enter more than one language filter in the
        search widget, the current behavior is that only the first language
        filter will be used.
      </p>
    </blockquote>
    <p>
      Another way to customize your editor by language is by running the global
      command <strong>Preferences: Configure Language Specific Settings</strong>{' '}
      &#40;command ID: workbench.action.configureLanguageBasedSettings&#41; from
      the Command Palette &#40;<code>Ctrl+Shift+P</code>&#41; which opens the
      language picker. Select the language you want. Then, the Settings editor
      opens with a language filter for the selected language, which allows you
      to modify language-specific settings for that language. Though, if you
      have the workbench.settings.editor setting set to json, then the
      settings.json file opens with a new language entry where you can add
      applicable settings.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/pref-config-lang-settings.png"
        alt="21"
        title="21"
      />
    </p>
    <p>Select the language via the dropdown:</p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/lang-selection.png"
        alt="22"
        title="22"
      />
    </p>
    <p>Now you can start editing settings specifically for that language:</p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/lang-based-settings-editor.png"
        alt="23"
        title="23"
      />
    </p>
    <p>
      Or, if workbench.settings.editor is set to json, now you can start adding
      language-specific settings to your user settings:
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/lang-based-settings.png"
        alt="24"
        title="24"
      />
    </p>
    <p>
      If you have a file open and you want to customize the editor for this file
      type, select the Language Mode in the Status Bar to the bottom-right of
      the AVAP<sup>TM</sup> Dev Studio window. This opens the Language Mode
      picker with an option{' '}
      <strong>Configure &#39;language_name&#39; language based settings</strong>
      . Selecting this opens your user settings.json with the language entry
      where you can add applicable settings.
    </p>
    <p>
      Language-specific editor settings always override non-language-specific
      editor settings, even if the non-language-specific setting has a narrower
      scope. For example, language-specific user settings override
      non-language-specific workspace settings.
    </p>
    <p>
      You can scope language-specific settings to the workspace by placing them
      in the workspace settings just like other settings. If you have settings
      defined for the same language in both user and workspace scopes, then they
      are merged by giving precedence to the ones defined in the workspace.
    </p>
    <p>
      The following example can be pasted into a settings JSON file to customize
      editor settings for the typescript and markdown language modes.
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br /> "[typescript]": &#123;
        <br /> "editor.formatOnSave": true,
        <br /> "editor.formatOnPaste": true
        <br /> &#125;,
        <br /> "[markdown]": &#123;
        <br /> "editor.formatOnSave": true,
        <br /> "editor.wordWrap": "on",
        <br /> "editor.renderWhitespace": "all",
        <br /> "editor.acceptSuggestionOnEnter": "off"
        <br /> &#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>
      You can use IntelliSense in settings.json to help you find
      language-specific settings. All editor settings and some non-editor
      settings are supported. Some languages have default language-specific
      settings already set, which you can review in defaultSettings.json by
      running the <strong>Preferences: Open Default Settings</strong> command.
    </p>
    <h4 >
      Multiple language-specific editor settings
    </h4>
    <p>
      You can configure language specific editor settings for multiple languages
      at once. The following example shows how you can customize settings for
      javascript and typescript languages together in your settings.json file:
    </p>

    <pre className="">
      <code className="">
        "[javascript][typescript]": &#123;
        <br /> "editor.maxTokenizationLineLength": 2500
        <br />
        &#125;
      </code>
    </pre>
    <h3 >Settings precedence</h3>
    <p>
      Configurations can be overridden at multiple levels by the different
      setting scopes. In the following list,{' '}
      <strong>later scopes override earlier scopes</strong>:
    </p>
    <ul>
      <li>
        Default settings - This scope represents the default unconfigured
        setting values.
      </li>
      <li>
        User settings - Apply globally to all AVAP<sup>TM</sup> Dev Studio
        instances.
      </li>
      <li>Remote settings - Apply to a remote machine opened by a user.</li>
      <li>Workspace settings - Apply to the open folder or workspace.</li>
      <li>
        Workspace Folder settings - Apply to a specific folder of a multi-root
        workspace.
      </li>
      <li>
        Language-specific default settings - These are language-specific default
        values that can be contributed by extensions.
      </li>
      <li>
        Language-specific user settings - Same as User settings, but specific to
        a language.
      </li>
      <li>
        Language-specific remote settings - Same as Remote settings, but
        specific to a language.
      </li>
      <li>
        Language-specific workspace settings - Same as Workspace settings, but
        specific to a language.
      </li>
      <li>
        Language-specific workspace folder settings - Same as Workspace Folder
        settings, but specific to a language.
      </li>
      <li>
        Policy settings - Set by the system administrator, these values always
        override other setting values.
      </li>
    </ul>
    <p>Setting values can be of various types:</p>
    <ul>
      <li>String - &quot;files.autoSave&quot;: &quot;afterDelay&quot;</li>
      <li>Boolean - &quot;editor.minimap.enabled&quot;: true</li>
      <li>Number - &quot;files.autoSaveDelay&quot;: 1000</li>
      <li>Array - &quot;editor.rulers&quot;: []</li>
      <li>
        Object - &quot;search.exclude&quot;: &#123; &quot;
        <strong>/node_modules&quot;: true, &quot;</strong>
        /bower_components&quot;: true &#125;
      </li>
    </ul>
    <p>
      Values with primitive types and Array types are overridden, meaning a
      configured value in a scope that takes precedence over another scope is
      used instead of the value in the other scope. But, values with Object
      types are merged.
    </p>
    <p>
      For example, workbench.colorCustomizations takes an Object that specifies
      a group of UI elements and their desired colors. If your user settings set
      the editor backgrounds to blue and green:
    </p>

    <pre className="">
      <code className="">
        "workbench.colorCustomizations": &#123;
        <br /> "editor.background": "#000088",
        <br /> "editor.selectionBackground": "#008800"
        <br /> &#125;
      </code>
    </pre>
    <p>And your open workspace settings set the editor foreground to red:</p>

    <pre className="">
      <code className="">
        "workbench.colorCustomizations": &#123;
        <br /> "editor.foreground": "#880000",
        <br /> "editor.selectionBackground": "#00FF00"
        <br /> &#125;
      </code>
    </pre>
    <p>
      The result, when that workspace is open, is the combination of those two
      color customizations, as if you had specified:
    </p>

    <pre className="">
      <code className="">
        "workbench.colorCustomizations": &#123;
        <br /> "editor.background": "#000088",
        <br /> "editor.selectionBackground": "#00FF00",
        <br /> "editor.foreground": "#880000"
        <br /> &#125;
      </code>
    </pre>
    <p>
      If there are conflicting values, such as editor.selectionBackground in the
      example above, the usual override behavior occurs, with workspace values
      taking precedence over user values, and language-specific values taking
      precedence over non-language-specific values.
    </p>
    <h4 >
      Note about multiple language specific settings
    </h4>
    <p>
      If you are using multiple language-specific settings, be aware that
      language-specific settings are merged and precedence is set based on the
      full language string &#40;for example
      &quot;[typescript][javascript]&quot;&#41; and not the individual language
      IDs &#40;typescript and javascript&#41;. This means that for example, a
      &quot;[typescript][javascript]&quot; workspace setting will not override a
      &quot;[javascript]&quot; user setting.
    </p>
    <h3 >Settings and security</h3>
    <p>
      Some settings allow you to specify an executable that AVAP<sup>TM</sup>{' '}
      Dev Studio will run to perform certain operations. For example, you can
      choose which shell the Integrated Terminal should use. For enhanced
      security, such settings can only be defined in user settings and not at
      workspace scope.
    </p>
    <p>Here is the list of settings not supported in workspace settings:</p>
    <ul>
      <li>git.path</li>
      <li>terminal.external.windowsExec</li>
      <li>terminal.external.osxExec</li>
      <li>terminal.external.linuxExec</li>
    </ul>
    <p>
      The first time you open a workspace that defines any of these settings,
      AVAP<sup>TM</sup> Dev Studio will warn you and then always ignore the
      values after that.
    </p>
    <h3 >Settings Sync</h3>
    <p>
      You can share your user settings across your AVAP<sup>TM</sup> Dev Studio
      instances with the Settings Sync feature. This feature lets you share
      settings, keyboard shortcuts, and installed extensions across your AVAP
      <sup>TM</sup> Dev Studio installs on various machines. You can enable
      Settings Sync via the <strong>Turn on Settings Sync</strong> command on
      the right of the Settings editor or on the <strong>Accounts</strong>{' '}
      Activity Bar context menu.
    </p>
    <p>
      <img
        src="https://code.visualstudio.com/assets/docs/getstarted/settings/accounts-context-menu.png"
        alt="25"
        title="25"
      />
    </p>
    <p>
      You can learn more about turning on and configuring Settings Sync in the
      Settings Sync user guide.
    </p>
    <h3 >Common questions</h3>
    <h4 >
      AVAP Code says &quot;Unable to write settings.&quot;
    </h4>
    <p>
      If you try to change a setting &#40;for example turning on Auto Save or
      selecting a new Color Theme&#41; and you see &quot;Unable to write into
      user settings. Please open user settings to correct errors/warnings in it
      and try again.&quot;, it means your settings.json file is ill-formed or
      has errors. The error can be as simple as a missing comma or incorrect
      setting value. Open the settings.json file with the{' '}
      <strong>Preferences: Open User Settings &#40;JSON&#41;</strong> command
      and you should see the error highlighted with red squiggles.
    </p>
    <h4 >
      How can I reset my user settings?
    </h4>
    <p>
      The easiest way to reset VS Code back to the default settings is to clear
      your user settings.json file. You can open the settings.json file with the
      Preferences: Open User Settings &#40;JSON&#41; command in the Command
      Palette &#40;Ctrl+Shift+P&#41;. Once the file is open in an editor, delete
      everything between the two curly braces &#123;&#125;, save the file, and
      AVAP<sup>TM</sup> Dev Studio will go back to using the default values.
    </p>
    <h4 >
      When does it make sense to use workspace settings?
    </h4>
    <p>
      If you&#39;re using a workspace that needs custom settings but you
      don&#39;t want to apply them to your other AVAP<sup>TM</sup> Dev Studio
      projects. A good example is language-specific linting rules.
    </p>
    <h4 >
      Where can I find extension settings?
    </h4>
    <p>
      In general, AVAP<sup>TM</sup> Dev Studio extensions store their settings
      in your user or workspaces settings files and they are available through
      the Settings editor UI &#40;
      <strong>Preferences: Open Settings &#40;UI&#41;</strong>&#41; or via
      IntelliSense in your settings.json file &#40;
      <strong>Preferences: Open User Settings &#40;JSON&#41;</strong>&#41;.
      Searching by the extension name &#40;for example gitlens or python&#41;
      can help filter down settings to just those contributed by an extension.
    </p>
    <h3 >Default settings</h3>
    <p>
      Below are the AVAP<sup>TM</sup> Dev Studioe default settings and their
      values. You can also view the default values in the Settings editor or see
      a read-only version of the defaultSettings.json via{' '}
      <strong>Preferences: Open Default Settings &#40;JSON&#41;</strong>.
    </p>

  
  </Fragment>
);
export default Settings;
