import React, { Fragment } from 'react';

const USER_GUIDE_Debugging = () => (
  <Fragment>
    <h1 id="debugging">Debugging</h1>
    <p>
      One of the key features of AVAP<sup>TM</sup> Dev Studio is its great
      debugging support. AVAP<sup>TM</sup> Dev Studio&#39;s built-in debugger
      helps accelerate your edit, compile, and debug loop.
    </p>
    <h2 id="debugger-extensions">Debugger extensions</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio has built-in debugging support for the
      Node.js runtime and can debug JavaScript, TypeScript, or any other
      language that gets transpiled to JavaScript.
    </p>
    <p>
      For debugging other languages and runtimes &#40;including PHP, Ruby, Go,
      C#, Python, C++, PowerShell and many others&#41;, look for{' '}
      <code>Debuggers</code> extensions in the AVAP™ Dev Studio Marketplace or
      select Install Additional Debuggers in the top-level Run menu.
    </p>
    <p>Below are several popular extensions which include debugging support:</p>
    <blockquote>
      <p>
        Tip: The extensions shown above are dynamically queried. Select an
        extension tile above to read the description and reviews to decide which
        extension is best for you.
      </p>
    </blockquote>
    <h2 id="start-debugging">Start debugging</h2>
    <p>
      The following documentation is based on the built-in Node.js debugger, but
      most of the concepts and features are applicable to other debuggers as
      well.
    </p>
    <p>
      It is helpful to first create a sample Node.js application before reading
      about debugging. You can follow the Node.js walkthrough to install Node.js
      and create a simple &quot;Hello World&quot; JavaScript application &#40;
      <code>app.js</code>&#41;. Once you have a simple application set up, this
      page will take you through AVAP<sup>TM</sup> Dev Studio debugging
      features.
    </p>
    <h2 id="run-and-debug-view">Run and Debug view</h2>
    <p>
      To bring up the <strong>Run and Debug</strong> view, select the{' '}
      <strong>Run and Debug</strong> icon in the <strong>Activity Bar</strong>{' '}
      on the side of AVAP<sup>TM</sup> Dev Studio. You can also use the keyboard
      shortcut Ctrl+Shift+D.
    </p>
    <p>
      The <strong>Run and Debug</strong> view displays all information related
      to running and debugging and has a top bar with debugging commands and
      configuration settings.
    </p>
    <p>
      If running and debugging is not yet configured &#40;no
      <code>launch.json</code> has been created&#41;, AVAP<sup>TM</sup> Dev
      Studio shows the Run start view.
    </p>
    <h2 id="run-menu">Run menu</h2>
    <p>
      The top-level <strong>Run</strong> menu has the most common run and debug
      commands:
    </p>
    <h2 id="launch-configurations">Launch configurations</h2>
    <p>
      To run or debug a simple app in AVAP<sup>TM</sup> Dev Studio, select{' '}
      <strong>Run and Debug</strong> on the Debug start view or press F5 and
      AVAP<sup>TM</sup> Dev Studio will try to run your currently active file.
    </p>
    <p>
      However, for most debugging scenarios, creating a launch configuration
      file is beneficial because it allows you to configure and save debugging
      setup details. AVAP<sup>TM</sup> Dev Studio keeps debugging configuration
      information in a <code>launch.json</code> file located in a{' '}
      <code>.vscode</code> folder in your workspace &#40;project root
      folder&#41; or in your user settings or workspace settings.
    </p>
    <p>
      To create a launch.json file, click the{' '}
      <strong>create a launch.json file</strong> link in the Run start view.
    </p>
    <p>
      AVAP<sup>TM</sup> Dev Studio will try to automatically detect your debug
      environment, but if this fails, you will have to choose it manually:
    </p>
    <p>Here is the launch configuration generated for Node.js debugging:</p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"version": "0.2.0",
        <br />` `"configurations": [<br />` `&#123;
        <br />` `"type": "node",
        <br />` `"request": "launch",
        <br />` `"name": "Launch Program",
        <br />` `"skipFiles": ["&lt;node\_internals&gt;/\*\*"],
        <br />` `"program": "$&#123;workspaceFolder&#125;\\app.js"
        <br />` `&#125;
        <br />` `]
        <br />
        &#125;
      </code>
    </pre>
    <p>
      If you go back to the File Explorer view &#40;Ctrl+Shift+E&#41;,
      you&#39;ll see that AVAP<sup>TM</sup> Dev Studio has created a{' '}
      <code>.vscode</code> folder and added the <code>launch.json</code> file to
      your workspace.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: You can debug a simple application even if you
        don&#39;t have a folder open in AVAP<sup>TM</sup> Dev Studio, but it is
        not possible to manage launch configurations and set up advanced
        debugging. The AVAP<sup>TM</sup> Dev Studio Status Bar is purple if you
        do not have a folder open.
      </p>
    </blockquote>
    <p>
      Note that the attributes available in launch configurations vary from
      debugger to debugger. You can use IntelliSense suggestions
      &#40;Ctrl+Space&#41; to find out which attributes exist for a specific
      debugger. Hover help is also available for all attributes.
    </p>
    <p>
      Do not assume that an attribute that is available for one debugger
      automatically works for other debuggers too. If you see red squiggles in
      your launch configuration, hover over them to learn what the problem is
      and try to fix them before launching a debug session.
    </p>
    <p>
      Review all automatically generated values and make sure that they make
      sense for your project and debugging environment
    </p>
    <h3 id="launch-versus-attach-configurations">
      Launch versus attach configurations
    </h3>
    <p>
      In AVAP<sup>TM</sup> Dev Studio, there are two core debugging modes,{' '}
      <strong>Launch</strong> and <strong>Attach</strong>, which handle two
      different workflows and segments of developers. Depending on your
      workflow, it can be confusing to know what type of configuration is
      appropriate for your project.
    </p>
    <p>
      If you come from a browser Developer Tools background, you might not be
      used to &quot;launching from your tool,&quot; since your browser instance
      is already open. When you open DevTools, you are simply{' '}
      <strong>attaching</strong> DevTools to your open browser tab. On the other
      hand, if you come from a server or desktop background, it&#39;s quite
      normal to have your editor <strong>launch</strong> your process for you,
      and your editor automatically attaches its debugger to the newly launched
      process.
    </p>
    <p>
      The best way to explain the difference between <strong>launch</strong> and{' '}
      <strong>attach</strong> is to think of a <strong>launch</strong>{' '}
      configuration as a recipe for how to start your app in debug mode{' '}
      <strong>before</strong> AVAP<sup>TM</sup> Dev Studio attaches to it, while
      an <strong>attach</strong> configuration is a recipe for how to connect
      AVAP<sup>TM</sup> Dev Studio&#39;s debugger to an app or process
      that&#39;s <strong>already</strong> running.
    </p>
    <p>
      AVAP<sup>TM</sup> Dev Studio debuggers typically support launching a
      program in debug mode or attaching to an already running program in debug
      mode. Depending on the request &#40;attach or launch&#41;, different
      attributes are required, and AVAP<sup>TM</sup> Dev Studio&#39;s
      launch.json validation and suggestions should help with that.
    </p>
    <h3 id="add-a-new-configuration">Add a new configuration</h3>
    <p>
      To add a new configuration to an existing <code>launch.json</code>, use
      one of the following techniques:
    </p>
    <ul>
      <li>
        Use IntelliSense if your cursor is located inside the configurations
        array.
      </li>
      <li>
        Press the <strong>Add Configuration</strong> button to invoke snippet
        IntelliSense at the start of the array.
      </li>
      <li>
        Choose <strong>Add Configuration</strong> option in the Run menu.
      </li>
    </ul>
    <p>
      AVAP<sup>TM</sup> Dev Studio also supports compound launch configurations
      for starting multiple configurations at the same time; for more details,
      please read this section.
    </p>
    <p>
      In order to start a debug session, first select the configuration named{' '}
      <strong>Launch Program</strong> using the{' '}
      <strong>Configuration dropdown</strong> in the{' '}
      <strong>Run and Debug</strong> view. Once you have your launch
      configuration set, start your debug session with F5.
    </p>
    <p>
      Alternatively, you can run your configuration through the{' '}
      <strong>Command Palette</strong> &#40;Ctrl+Shift+P&#41; by filtering on{' '}
      <strong>Debug: Select and Start Debugging</strong> or typing &#39;debug
      &#39; and selecting the configuration you want to debug.
    </p>
    <p>
      As soon as a debugging session starts, the <strong>DEBUG CONSOLE</strong>{' '}
      panel is displayed and shows debugging output, and the Status Bar changes
      color &#40;orange for default color themes&#41;:
    </p>
    <p>
      In addition, the <strong>debug status</strong> appears in the Status Bar
      showing the active debug configuration. By selecting the debug status, a
      user can change the active launch configuration and start debugging
      without needing to open the <strong>Run and Debug</strong> view.
    </p>
    <h2 id="debug-actions">Debug actions</h2>
    <p>
      Once a debug session starts, the <strong>Debug toolbar</strong> will
      appear on the top of the editor.
    </p>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Action</strong>
          </th>
          <th align="center">
            <strong>Explanation</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">Continue / Pause &lt;&gt;F5</td>
          <td align="center">
            <strong>Continue</strong>: Resume normal program/script execution
            &#40;up to the next breakpoint&#41;. &lt;&gt;<strong>Pause</strong>:
            Inspect code executing at the current line and debug line-by-line.
          </td>
        </tr>
        <tr>
          <td align="center">Step Over &lt;&gt;F10</td>
          <td align="center">
            Execute the next method as a single command without inspecting or
            following its component steps.
          </td>
        </tr>
        <tr>
          <td align="center">Step Into &lt;&gt;F11</td>
          <td align="center">
            Enter the next method to follow its execution line-by-line.
          </td>
        </tr>
        <tr>
          <td align="center">Step Out &lt;&gt;Shift+F11</td>
          <td align="center">
            When inside a method or subroutine, return to the earlier execution
            context by completing remaining lines of the current method as
            though it were a single command.
          </td>
        </tr>
        <tr>
          <td align="center">Restart &lt;&gt;Ctrl+Shift+F5</td>
          <td align="center">
            Terminate the current program execution and start debugging again
            using the current run configuration.
          </td>
        </tr>
        <tr>
          <td align="center">Stop &lt;&gt;Shift+F5</td>
          <td align="center">Terminate the current program execution.</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p>
        <strong>Tip</strong>: Use the setting debug.toolBarLocation to control
        the location of the debug toolbar. It can be the default floating,
        docked to the <strong>Run and Debug</strong> view, or hidden. A floating
        debug toolbar can be dragged horizontally and also down to the editor
        area.
      </p>
    </blockquote>
    <h3 id="run-mode">Run mode</h3>
    <p>
      In addition to debugging a program, AVAP<sup>TM</sup> Dev Studio supports{' '}
      <strong>running</strong> the program. The{' '}
      <strong>Debug: Run &#40;Start Without Debugging&#41;</strong> action is
      triggered with Ctrl+F5 and uses the currently selected launch
      configuration. Many of the launch configuration attributes are supported
      in &#39;Run&#39; mode. AVAP<sup>TM</sup> Dev Studio maintains a debug
      session while the program is running, and pressing the{' '}
      <strong>Stop</strong> button terminates the program.
    </p>
    <blockquote>
      <p>
        <strong>Tip</strong>: The <strong>Run</strong> action is always
        available, but not all debugger extensions support &#39;Run&#39;. In
        this case, &#39;Run&#39; will be the same as &#39;Debug&#39;.
      </p>
    </blockquote>
    <h2 id="breakpoints">Breakpoints</h2>
    <p>
      Breakpoints can be toggled by clicking on the{' '}
      <strong>editor margin</strong> or using F9 on the current line. Finer
      breakpoint control &#40;enable/disable/reapply&#41; can be done in the{' '}
      <strong>Run and Debug</strong> view&#39;s <strong>BREAKPOINTS</strong>{' '}
      section.
    </p>
    <ul>
      <li>
        Breakpoints in the editor margin are normally shown as red filled
        circles.
      </li>
      <li>Disabled breakpoints have a filled gray circle.</li>
      <li>
        When a debugging session starts, breakpoints that cannot be registered
        with the debugger change to a gray hollow circle. The same might happen
        if the source is edited while a debug session without live-edit support
        is running.
      </li>
    </ul>
    <p>
      If the debugger supports breaking on different kinds of errors or
      exceptions, those will also be available in the{' '}
      <strong>BREAKPOINTS</strong> view.
    </p>
    <p>
      The <strong>Reapply All Breakpoints</strong> command sets all breakpoints
      again to their original location. This is helpful if your debug
      environment is &quot;lazy&quot; and &quot;misplaces&quot; breakpoints in
      source code that has not yet been executed.
    </p>
    <p>
      Optionally, breakpoints can be shown in the editor&#39;s overview ruler by
      enabling the setting <code>debug.showBreakpointsInOverviewRuler</code>:
    </p>
    <h2 id="logpoints">Logpoints</h2>
    <p>
      A Logpoint is a variant of a breakpoint that does not &quot;break&quot;
      into the debugger but instead logs a message to the console. Logpoints are
      especially useful for injecting logging while debugging production servers
      that cannot be paused or stopped.
    </p>
    <p>
      A Logpoint is represented by a &quot;diamond&quot; shaped icon. Log
      messages are plain text but can include expressions to be evaluated within
      curly braces &#40;&#39;&#123;&#125;&#39;&#41;.
    </p>
    <p>
      Just like regular breakpoints, Logpoints can be enabled or disabled and
      can also be controlled by a condition and/or hit count.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: Logpoints are supported by AVAP<sup>TM</sup> Dev
        Studio&#39;s built-in Node.js debugger, but can be implemented by other
        debug extensions. The Python extensions, for example, support Logpoints.
      </p>
    </blockquote>
    <h3 id="triggered-breakpoints">Triggered breakpoints</h3>
    <p>
      A trigged breakpoint is a breakpoint that is automatically enabled once
      another breakpoint is hit. They can be very useful when diagnosing failure
      cases in code that happen only after a certain precondition.
    </p>
    <p>
      Triggered breakpoints can be set by right-clicking on the glyph margin,
      selecting <strong>Add Triggered Breakpoint</strong>, and then choosing
      which other breakpoint enables the breakpoint.
    </p>
    <p>
      Triggered breakpoints work for all languages, and conditional breakpoints
      may also be used as the trigger.
    </p>
    <h2 id="data-inspection">Data inspection</h2>
    <p>
      Variables can be inspected in the <strong>VARIABLES</strong> section of
      the <strong>Run and Debug</strong> view or by hovering over their source
      in the editor. Variable values and expression evaluation are relative to
      the selected stack frame in the <strong>CALL STACK</strong> section.
    </p>
    <p>
      Variable values can be modified with the <strong>Set Value</strong> action
      from the variable&#39;s context menu. Additionally, you can use the{' '}
      <strong>Copy Value</strong> action to copy the variable&#39;s value, or{' '}
      <strong>Copy as Expression</strong> action to copy an expression to access
      the variable.
    </p>
    <p>
      Variables and expressions can also be evaluated and watched in the{' '}
      <strong>Run and Debug</strong> view&#39;s <strong>WATCH</strong> section.
    </p>
    <p>
      Variable names and values can be filtered by typing while the focus is on
      the <strong>VARIABLES</strong> section.
    </p>
    <h2 id="launchjson-attributes">Launch.json attributes</h2>
    <p>
      There are many <code>launch.json</code> attributes to help support
      different debuggers and debugging scenarios. As mentioned above, you can
      use IntelliSense &#40;Ctrl+Space&#41; to see the list of available
      attributes once you have specified a value for the <code>type</code>{' '}
      attribute.
    </p>
    <p>
      The following attributes are mandatory for every launch configuration:
    </p>
    <ul>
      <li>
        <code>type</code> - the type of debugger to use for this launch
        configuration. Every installed debug extension introduces a type: node
        for the built-in Node debugger, for example, or php and go for the PHP
        and Go extensions.
      </li>
      <li>
        <code>request</code> - the request type of this launch configuration.
        Currently, launch and attach are supported.
      </li>
      <li>
        <code>name</code> - the reader-friendly name to appear in the Debug
        launch configuration dropdown.
      </li>
    </ul>
    <p>
      Here are some optional attributes available to all launch configurations:
    </p>
    <ul>
      <li>
        <code>presentation</code> - using the order, group, and hidden
        attributes in the presentation object, you can sort, group, and hide
        configurations and compounds in the Debug configuration dropdown and in
        the Debug quick pick.
      </li>
      <li>
        <code>preLaunchTask</code> - to launch a task before the start of a
        debug session, set this attribute to the label of a task specified in
        tasks.json &#40;in the workspace&#39;s .vscode folder&#41;. Or, this can
        be set to $&#123;defaultBuildTask&#125; to use your default build task.
      </li>
      <li>
        <code>postDebugTask</code> - to launch a task at the very end of a debug
        session, set this attribute to the name of a task specified in
        tasks.json &#40;in the workspace&#39;s .vscode folder&#41;.
      </li>
      <li>
        <code>internalConsoleOptions</code> - this attribute controls the
        visibility of the Debug Console panel during a debugging session.
      </li>
      <li>
        <code>debugServer</code> -{' '}
        <strong>for debug extension authors only</strong>: this attribute allows
        you to connect to a specified port instead of launching the debug
        adapter.
      </li>
      <li>
        <code>serverReadyAction</code> - if you want to open a URL in a web
        browser whenever the program under debugging outputs a specific message
        to the debug console or integrated terminal. For details see section
        Automatically open a URI when debugging a server program below.
      </li>
    </ul>
    <p>Many debuggers support some of the following attributes:</p>
    <ul>
      <li>
        <code>program</code> - executable or file to run when launching the
        debugger
      </li>
      <li>
        <code>args</code> - arguments passed to the program to debug
      </li>
      <li>
        <code>env</code> - environment variables &#40;the value null can be used
        to &quot;undefine&quot; a variable&#41;
      </li>
      <li>
        <code>envFile</code> - path to dotenv file with environment variables
      </li>
      <li>
        <code>cwd</code> - current working directory for finding dependencies
        and other files
      </li>
      <li>
        <code>port</code> - port when attaching to a running process
      </li>
      <li>
        <code>stopOnEntry</code> - break immediately when the program launches
      </li>
      <li>
        <code>console</code> - what kind of console to use, for example,{' '}
        <code>internalConsole</code>, <code>integratedTerminal</code>, or{' '}
        <code>externalTerminal</code>
      </li>
    </ul>
    <h2 id="variable-substitution">Variable substitution</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio makes commonly used paths and other values
      available as variables and supports variable substitution inside strings
      in <code>launch.json</code>. This means that you do not have to use
      absolute paths in debug configurations. For example,{' '}
      <code>$&#123;workspaceFolder&#125;</code> gives the root path of a
      workspace folder, <code>$&#123;file&#125;</code> the file open in the
      active editor, and $&#123;env:Name&#125; the environment variable
      &#39;Name&#39;. You can see a full list of predefined variables in the
      Variables Reference or by invoking IntelliSense inside the{' '}
      <code>launch.json</code> string attributes.
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"type": "node",
        <br />` `"request": "launch",
        <br />` `"name": "Launch Program",
        <br />` `"program": "$&#123;workspaceFolder&#125;/app.js",
        <br />` `"cwd": "$&#123;workspaceFolder&#125;",
        <br />` `"args": ["$&#123;env:USERNAME&#125;"]
        <br />
        &#125;
      </code>
    </pre>
    <h2 id="platform-specific-properties">Platform-specific properties</h2>
    <p>
      <code>Launch.json</code> supports defining values &#40;for example,
      arguments to be passed to the program&#41; that depend on the operating
      system where the debugger is running. To do so, put a platform-specific
      literal into the <code>launch.json</code> file and specify the
      corresponding properties inside that literal.
    </p>
    <p>
      Below is an example that passes &quot;<code>args</code>&quot; to the
      program differently on Windows:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"version": "0.2.0",
        <br />` `"configurations": [<br />` `&#123;
        <br />` `"type": "node",
        <br />` `"request": "launch",
        <br />` `"name": "Launch Program",
        <br />` `"program":
        "$&#123;workspaceFolder&#125;/node\_modules/gulp/bin/gulpfile.js",
        <br />` `"args": ["myFolder/path/app.js"],
        <br />` `"windows": &#123;
        <br />` `"args": ["myFolder\\path\\app.js"]
        <br />` `&#125;
        <br />` `&#125;
        <br />` `]
        <br />
        &#125;
      </code>
    </pre>
    <p>
      Valid operating properties are &quot;<code>windows</code>&quot; for
      Windows, &quot;<code>linux</code>&quot; for Linux, and &quot;
      <code>osx</code>&quot; for macOS. Properties defined in an operating
      system specific scope override properties defined in the global scope.
    </p>
    <p>
      Please note that the <code>type</code> property cannot be placed inside a
      platform-specific section, because <code>type</code> indirectly determines
      the platform in remote debugging scenarios, and that would result in a
      cyclic dependency.
    </p>
    <p>
      In the example below, debugging the program always{' '}
      <strong>stops on entry</strong> except on macOS:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"version": "0.2.0",
        <br />` `"configurations": [<br />` `&#123;
        <br />` `"type": "node",
        <br />` `"request": "launch",
        <br />` `"name": "Launch Program",
        <br />` `"program":
        "$&#123;workspaceFolder&#125;/node\_modules/gulp/bin/gulpfile.js",
        <br />` `"stopOnEntry": true,
        <br />` `"osx": &#123;
        <br />` `"stopOnEntry": false
        <br />` `&#125;
        <br />` `&#125;
        <br />` `]
        <br />
        &#125;
      </code>
    </pre>
    <h2 id="global-launch-configuration">Global launch configuration</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio supports adding a &quot;launch&quot; object
      inside your User settings. This &quot;launch&quot; configuration will then
      be shared across your workspaces. For example:
    </p>

    <pre className="">
      <code className="">
        "launch": &#123;
        <br />` `"version": "0.2.0",
        <br />` `"configurations": [&#123;
        <br />` `"type": "node",
        <br />` `"request": "launch",
        <br />` `"name": "Launch Program",
        <br />` `"program": "$&#123;file&#125;"
        <br />` `&#125;]
        <br />
        &#125;
      </code>
    </pre>
    <h2 id="advanced-breakpoint-topics">Advanced breakpoint topics</h2>
    <h3 id="conditional-breakpoints">Conditional breakpoints</h3>
    <p>
      A powerful AVAP<sup>TM</sup> Dev Studio debugging feature is the ability
      to set conditions based on expressions, hit counts, or a combination of
      both.
    </p>
    <ul>
      <li>
        <strong>Expression condition</strong>: The breakpoint will be hit
        whenever the expression evaluates to <code>true</code>.
      </li>
      <li>
        <strong>Hit count</strong>: The &#39;hit count&#39; controls how many
        times a breakpoint needs to be hit before it will &#39;break&#39;
        execution. Whether a &#39;hit count&#39; is respected and the exact
        syntax of the expression vary among debugger extensions.
      </li>
    </ul>
    <p>
      You can add a condition and/or hit count when creating a source breakpoint
      &#40;with the <strong>Add Conditional Breakpoint</strong> action&#41; or
      when modifying an existing one &#40;with the{' '}
      <strong>Edit Condition</strong> action&#41;. In both cases, an inline text
      box with a dropdown menu opens where you can enter expressions:
    </p>
    <p>
      Condition and hit count editing support is also supported for{' '}
      <strong>function</strong> and <strong>exception</strong> breakpoints. You
      can initiate condition editing from the context menu or the new inline{' '}
      <strong>Edit Condition</strong> action.
    </p>
    <p>
      An example of condition editing in the <strong>BREAKPOINTS</strong> view:
    </p>
    <p>
      If a debugger does not support conditional breakpoints, the{' '}
      <strong>Add Conditional Breakpoint</strong> and{' '}
      <strong>Edit Condition</strong> actions will be missing.
    </p>
    <h3 id="inline-breakpoints">Inline breakpoints</h3>
    <p>
      Inline breakpoints will only be hit when the execution reaches the column
      associated with the inline breakpoint. This is particularly useful when
      debugging minified code which contains multiple statements in a single
      line.
    </p>
    <p>
      An inline breakpoint can be set using Shift+F9 or through the context menu
      during a debug session. Inline breakpoints are shown inline in the editor.
    </p>
    <p>
      Inline breakpoints can also have conditions. Editing multiple breakpoints
      on a line is possible through the context menu in the editor&#39;s left
      margin.
    </p>
    <h3 id="function-breakpoints">Function breakpoints</h3>
    <p>
      Instead of placing breakpoints directly in source code, a debugger can
      support creating breakpoints by specifying a function name. This is useful
      in situations where source is not available but a function name is known.
    </p>
    <p>
      A function breakpoint is created by pressing the <strong>+</strong> button
      in the <strong>BREAKPOINTS</strong> section header and entering the
      function name. Function breakpoints are shown with a red triangle in the{' '}
      <strong>BREAKPOINTS</strong> section.
    </p>
    <h3 id="data-breakpoints">Data breakpoints</h3>
    <p>
      If a debugger supports data breakpoints, they can be set from the context
      menu in the <strong>VARIABLES</strong> view. The{' '}
      <strong>Break on Value Change/Read/Access</strong> commands will add a
      data breakpoint that is hit when the value of the underlying variable
      changes/is read/is accessed. Data breakpoints are shown with a red hexagon
      in the <strong>BREAKPOINTS</strong> section.
    </p>
    <h2 id="debug-console-repl">Debug Console REPL</h2>
    <p>
      Expressions can be evaluated with the <strong>Debug Console</strong> REPL
      &#40;Read-Eval-Print Loop&#41; feature. To open the Debug Console, use the{' '}
      <strong>Debug Console</strong> action at the top of the Debug pane or use
      the <strong>View: Debug Console</strong> command &#40;Ctrl+Shift+Y&#41;.
      Expressions are evaluated after you press Enter and the Debug Console REPL
      shows suggestions as you type. If you need to enter multiple lines, use
      Shift+Enter between the lines and then send all lines for evaluation with
      Enter. Debug Console input uses the mode of the active editor, which means
      that the Debug Console input supports syntax coloring, indentation, auto
      closing of quotes, and other language features.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: You must be in a running debug session to use the
        Debug Console REPL.
      </p>
    </blockquote>
    <h2 id="redirect-inputoutput-tofrom-the-debug-target">
      Redirect input/output to/from the debug target
    </h2>
    <p>
      Redirecting input/output is debugger/runtime specific, so AVAP
      <sup>TM</sup> Dev Studio does not have a built-in solution that works for
      all debuggers.
    </p>
    <p>Here are two approaches you might want to consider:</p>
    <ol>
      <li>
        Launch the program to debug &#40;&quot;debug target&quot;&#41; manually
        in a terminal or command prompt and redirect input/output as needed.
        Make sure to pass the appropriate command line options to the debug
        target so that a debugger can attach to it. Create and run an
        &quot;attach&quot; debug configuration that attaches to the debug
        target.
      </li>
      <li>
        If the debugger extension you are using can run the debug target in AVAP
        <sup>TM</sup> Dev Studio&#39;s Integrated Terminal &#40;or an external
        terminal&#41;, you can try to pass the shell redirect syntax &#40;for
        example, &quot;&lt;&quot; or &quot;&gt;&quot;&#41; as arguments.
      </li>
    </ol>
    <p>
      Here&#39;s an example <code>launch.json</code>configuration:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"name": "launch program that reads a file from stdin",
        <br />` `"type": "node",
        <br />` `"request": "launch",
        <br />` `"program": "program.js",
        <br />` `"console": "integratedTerminal",
        <br />` `"args": ["&lt;", "in.txt"]
        <br />
        &#125;
      </code>
    </pre>
    <p>
      This approach requires that the &quot;&lt;&quot; syntax is passed through
      the debugger extension and ends up unmodified in the Integrated Terminal.
    </p>
    <h2 id="multi-target-debugging">Multi-target debugging</h2>
    <p>
      For complex scenarios involving more than one process &#40;for example, a
      client and a server&#41;, AVAP<sup>TM</sup> Dev Studio supports
      multi-target debugging.
    </p>
    <p>
      Using multi-target debugging is simple: after you&#39;ve started a first
      debug session, you can just launch another session. As soon as a second
      session is up and running, the AVAP<sup>TM</sup> Dev Studio UI switches to{' '}
      <em>multi-target mode</em>:
    </p>
    <ul>
      <li>
        The individual sessions now show up as top-level elements in the{' '}
        <strong>CALL STACK</strong> view.
      </li>
      <li>
        The debug toolbar shows the currently <strong>active session</strong>{' '}
        &#40;and all other sessions are available in a dropdown menu&#41;.
      </li>
      <li>
        Debug actions &#40;for example, all actions in the debug toolbar&#41;
        are performed on the active session. The active session can be changed
        either by using the dropdown menu in the debug toolbar or by selecting a
        different element in the <strong>CALL STACK</strong> view.
      </li>
    </ul>
    <h3 id="compound-launch-configurations">Compound launch configurations</h3>
    <p>
      An alternative way to start multiple debug sessions is by using a{' '}
      <strong>compound</strong> launch configuration. A compound launch
      configuration lists the names of two or more launch configurations that
      should be launched in parallel. Optionally a preLaunchTask can be
      specified that is run before the individual debug sessions are started.
      The boolean flag stopAll controls whether manually terminating one session
      will stop all of the compound sessions.
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"version": "0.2.0",
        <br />` `"configurations": [<br />` `&#123;
        <br />` `"type": "node",
        <br />` `"request": "launch",
        <br />` `"name": "Server",
        <br />` `"program": "$&#123;workspaceFolder&#125;/server.js"
        <br />` `&#125;,
        <br />` `&#123;
        <br />` `"type": "node",
        <br />` `"request": "launch",
        <br />` `"name": "Client",
        <br />` `"program": "$&#123;workspaceFolder&#125;/client.js"
        <br />` `&#125;
        <br />` `],
        <br />` `"compounds": [<br />` `&#123;
        <br />` `"name": "Server/Client",
        <br />` `"configurations": ["Server", "Client"],
        <br />` `"preLaunchTask": "$&#123;defaultBuildTask&#125;",
        <br />` `"stopAll": true
        <br />` `&#125;
        <br />` `]
        <br />
        &#125;
      </code>
    </pre>
    <p>
      Compound launch configurations are displayed in the launch configuration
      dropdown menu.
    </p>
    <h2 id="remote-debugging">Remote debugging</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio does not itself support remote debugging:
      this is a feature of the debug extension you are using, and you should
      consult the extension&#39;s page in the Marketplace for support and
      details.
    </p>
    <p>
      There is, however, one exception: the Node.js debugger included in AVAP
      <sup>TM</sup> Dev Studio supports remote debugging. See the Node.js
      Debugging topic to learn how to configure this.
    </p>
    <h2 id="automatically-open-a-uri-when-debugging-a-server-program">
      Automatically open a URI when debugging a server program
    </h2>
    <p>
      Developing a web program typically requires opening a specific URL in a
      web browser in order to hit the server code in the debugger. AVAP
      <sup>TM</sup> Dev Studio has a built-in feature &quot;
      <strong>serverReadyAction</strong>&quot; to automate this task.
    </p>
    <p>Here is an example of a simple Node.js Express application:</p>

    <pre className="">
      <code className="">
        var express = require&#40;'express'&#41;;
        <br />
        var app = express&#40;&#41;;
        <br />
        <br />
        app.get&#40;'/', function&#40;req, res&#41; &#123;
        <br />` `res.send&#40;'Hello World!'&#41;;
        <br />
        &#125;&#41;;
        <br />
        <br />
        app.listen&#40;3000, function&#40;&#41; &#123;
        <br />` `console.log&#40;'Example app listening on port 3000!'&#41;;
        <br />
        &#125;&#41;;
      </code>
    </pre>
    <p>
      This application first installs a &quot;Hello World&quot; handler for the
      &quot;/&quot; URL and then starts to listen for HTTP connections on port
      3000. The port is announced in the Debug Console, and typically, the
      developer would now type &#40;LINK&#41; into their browser application.
    </p>
    <p>
      The <strong>serverReadyAction</strong> feature makes it possible to add a
      structured property <code>serverReadyAction</code> to any launch config
      and select an &quot;action&quot; to be performed:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"type": "node",
        <br />` `"request": "launch",
        <br />` `"name": "Launch Program",
        <br />` `"program": "$&#123;workspaceFolder&#125;/app.js",
        <br />
        <br />` `"serverReadyAction": &#123;
        <br />` `"pattern": "listening on port &#40;[0-9]+&#41;",
        <br />` `"uriFormat": "&#40;LINK&#41;",
        <br />` `"action": "openExternally"
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>
      Here the <code>pattern</code> property describes the regular expression
      for matching the program&#39;s output string that announces the port. The
      pattern for the port number is put into parenthesis so that it is
      available as a regular expression capture group. In this example, we are
      extracting only the port number, but it is also possible to extract a full
      URI.
    </p>
    <p>
      The <code>uriFormat</code> property describes how the port number is
      turned into a URI. The first %s is substituted by the first capture group
      of the matching pattern.
    </p>
    <p>
      The resulting URI is then opened outside of AVAP<sup>TM</sup> Dev Studio
      &#40;&quot;externally&quot;&#41; with the standard application configured
      for the URI&#39;s scheme.
    </p>
    <h3 id="trigger-debugging-via-edge-or-chrome">
      Trigger Debugging via Edge or Chrome
    </h3>
    <p>
      Alternatively, the action can be set to debugWithEdge or debugWithChrome.
      In this mode, a webRoot property can be added that is passed to the Chrome
      or Edge debug session.
    </p>
    <p>
      To simplify things a bit, most properties are optional and we use the
      following fallback values:
    </p>
    <ul>
      <li>
        <strong>pattern</strong>:{' '}
        <code>&quot;listening on.\* &#40;https?://\\S+|[0-9]+&#41;&quot;</code>{' '}
        which matches the commonly used messages &quot;listening on port
        3000&quot; or &quot;Now listening on: &#40;LINK&#41;&quot;.
      </li>
      <li>
        <strong>uriFormat</strong>: &quot;&#40;LINK&#41;&quot;
      </li>
      <li>
        <strong>webRoot</strong>:{' '}
        <code>&quot;$&#123;workspaceFolder&#125;&quot;</code>
      </li>
    </ul>
    <h3 id="triggering-an-arbitrary-launch-config">
      Triggering an Arbitrary Launch Config
    </h3>
    <p>
      In some cases, you may need to configure additional options for the
      browser debug session--or use a different debugger entirely. You can do
      this by setting <code>action</code> to <code>startDebugging</code> with a{' '}
      <code>name</code> property set to the name of the launch configuration to
      start when the <code>pattern</code> is matched.
    </p>
    <p>
      The named launch configuration must be in the same file or folder as the
      one with the <code>serverReadyAction</code>.
    </p>
    <p>
      Here the <strong>serverReadyAction</strong> feature in action:
    </p>
    <h2 id="next-steps">Next steps</h2>
    <p>
      To learn about AVAP<sup>TM</sup> Dev Studio&#39;s Node.js debugging
      support, take a look at:
    </p>
    <ul>
      <li>
        Node.js - Describes the Node.js debugger, which is included in AVAP
        <sup>TM</sup> Dev Studio.
      </li>
      <li>
        TypeScript - The Node.js debugger also supports TypeScript debugging.
      </li>
    </ul>
    <p>
      To see tutorials on the basics of Node.js debugging, check out these
      videos:
    </p>
    <ul>
      <li>Intro Video - Debugging - Showcases the basics of debugging.</li>
      <li>
        Getting started with Node.js debugging - Shows how to attach a debugger
        to a running Node.js process.
      </li>
    </ul>
    <p>
      To learn about debugging support for other programming languages via AVAP
      <sup>TM</sup> Dev Studio extensions:
    </p>
    <ul>
      <li>C++</li>
      <li>Python</li>
      <li>Java</li>
    </ul>
    <p>
      To learn about AVAP<sup>TM</sup> Dev Studio&#39;s task running support, go
      to:
    </p>
    <ul>
      <li>
        Tasks - Describes how to run tasks with Gulp, Grunt, and Jake and how to
        show errors and warnings.
      </li>
    </ul>
    <p>To write your own debugger extension, visit:</p>
    <ul>
      <li>
        Debugger Extension - Uses a mock sample to illustrate the steps required
        to create a AVAP<sup>TM</sup> Dev Studio debug extension.
      </li>
    </ul>
    <h2 id="common-questions">Common questions</h2>
    <h3 id="what-are-the-supported-debugging-scenarios">
      What are the supported debugging scenarios?
    </h3>
    <p>
      Debugging of Node.js-based applications is supported on Linux, macOS, and
      Windows out of the box with AVAP<sup>TM</sup> Dev Studio. Many other
      scenarios are supported by AVAP<sup>TM</sup> Dev Studio extensions
      available in the Marketplace.
    </p>
    <h3 id="i-do-not-see-any-launch-configurations-in-the-run-and-debug-view-dropdown-what-is-wrong">
      I do not see any launch configurations in the Run and Debug view dropdown.
      What is wrong?
    </h3>
    <p>
      The most common problem is that you did not set up launch.json or there is
      a syntax error in that file. Alternatively, you might need to open a
      folder, since no-folder debugging does not support launch configurations.
    </p>
  </Fragment>
);
export default USER_GUIDE_Debugging;
