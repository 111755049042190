import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';

const Consultar_Saldo_Monedero_Prepago_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `
        { 
          "status": <boolean>, 
          "balance": <decimal>, 
          "elapsed": <float> 
        }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `
        { 
          "status": false,
           "level": <string>,
            "message": <string>, 
            "error": <string> 
          }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/pademobile_prepago.py/consultar_saldo_prepago" 
        payload = {'country_code': 'MX', 'user_id': '4532', 'origin_id': '555444', 'signature': '00d4c87a1dd9c87ccda8518bb007835a71233069'} 
        files = [] 
        headers= {} 
        response = requests.request("POST", url, headers=headers, data = payload, files = files)
         print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests url = "URL_BASE/ws/pademobile_prepago.py/consultar_saldo_prepago" 
          payload = {'country_code': 'MX', 'user_id': '4532', 'origin_id': '555444', 'signature': '00d4c87a1dd9c87ccda8518bb007835a71233069'} 
          files = [] 
          headers= {} 
          response = requests.request("POST", url, headers=headers, data = payload, files = files)
           print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'POST',
              'url': 'URL_BASE/ws/pademobile_prepago.py/consultar_saldo_prepago',
              'headers': {},
              formData: {
              'country_code': 'MX',
              'user_id': '4532',
              'origin_id': '555444',
              'signature': '00d4c87a1dd9c87ccda8518bb007835a71233069'
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var formdata = new FormData();
              formdata.append("country_code", "MX");
              formdata.append("user_id", "4532");
              formdata.append("origin_id", "555444");
              formdata.append("signature", "00d4c87a1dd9c87ccda8518bb007835a71233069");
              var requestOptions = {
              method: 'POST',
              body: formdata,
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/pademobile_prepago.py/consultar_saldo_prepago", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request POST 'URL_BASE/ws/pademobile_prepago.py/consultar_saldo_prepago' \ --form 'country_code=MX' \ --form 'user_id=4532' \ --form 'origin_id=555444' \ --form 'signature=00d4c87a1dd9c87ccda8518bb007835a71233069'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="check-prepaid-wallet-balance">Check Prepaid Wallet Balance</h1>
        <p>
          This service is used to obtain the balance of a wallet from your other
          account.
        </p>
        <p>
          <span className="label post">POST:</span>
          <span> </span>{' '}
          <code>URL_BASE + /ws/pademobile_prepago.py/consultar_saldo_prepago</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <div className='overflow-auto'>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
              <td align="center">ES &#40;Spain&#41;</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">Identifier of the user making the request</td>
              <td align="center">N/A</td>
              <td align="center">4532</td>
            </tr>
            <tr>
              <td align="left">origin_id</td>
              <td align="center">Integer</td>
              <td align="center">No</td>
              <td align="center">
                Identifier of origin of funds from which we want to obtain its
                balance
              </td>
              <td align="center">N/A</td>
              <td align="center">555444</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                Allows you to sign the petition, making it unnecessary to log in
              </td>
              <td align="center">N/A</td>
              <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
            </tr>
          </tbody>
        </table>
        </div>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>balance:</code> Available balance of the source of funds.
          </li>
          <li>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>level:</code> Error importance level.
          </li>
          <li>
            <code>message:</code> Error message.
          </li>
          <li>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          With this service we obtain the balance of the source of funds that
          belongs to another account. To make this query, it is advisable to take
          advantage of the private key of the external user that was returned in the
          query to register the wallet and make this query with a signature.
        </p>
        </div>

      <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
        </div>
      </Fragment>
    );
  }
export default Consultar_Saldo_Monedero_Prepago_EN;
