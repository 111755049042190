import React, { Fragment } from 'react';

const USER_GUIDE_SettingsSync = () => (
  <Fragment>
    <h1 id="settings-sync">Settings Sync</h1>
    <p>
      Settings Sync lets you share your AVAP<sup>TM</sup> Dev Studio
      configurations such as settings, keybindings, and installed extensions
      across your machines so you are always working with your favorite setup.
    </p>
    <h2 id="turning-on-settings-sync">Turning on Settings Sync</h2>
    <p>
      You can turn on Settings Sync using the{' '}
      <strong>Turn On Settings Sync...</strong> entry in the{' '}
      <strong>Manage</strong> gear menu at the bottom of the Activity Bar.
    </p>
    <p>
      You will be asked to sign in and what preferences you would like to sync;
      currently Settings, Keyboard Shortcuts, Extensions, User Snippets, and UI
      State are supported.
    </p>
    <p>
      Selecting the <strong>Sign in &amp; Turn on</strong> button will ask you
      to choose between signing in with your Microsoft or GitHub account.
    </p>
    <p>
      After making this selection, the browser will open so that you can sign in
      to your Microsoft or GitHub account. When a Microsoft account is chosen,
      you can use either personal accounts, such as Outlook accounts, or Azure
      accounts, and you can also link a GitHub account to a new or existing
      Microsoft account.
    </p>
    <p>
      After signing in, Settings Sync will be turned on and continue to
      synchronize your preferences automatically in the background.
    </p>
    <h2 id="merge-or-replace">Merge or Replace*</h2>
    <p>
      If you already synced from a machine and turning on sync from another
      machine, you will be shown with following{' '}
      <strong>Merge or Replace</strong> dialog.
    </p>
    <ul>
      <li>
        <strong>Merge</strong>: Selecting this option will merge{' '}
        <strong>local</strong> settings with <strong>remote</strong> settings
        from the cloud.
      </li>
      <li>
        <strong>Replace Local</strong>: Selecting this option will overwrite{' '}
        <strong>local</strong> settings with remote settings from the cloud.
      </li>
      <li>
        <strong>Merge Manually...</strong>: Selecting this option will open{' '}
        <strong>Merges</strong> view where you can merge preferences one by one.
      </li>
    </ul>
    <h2 id="configuring-synced-data">Configuring synced data</h2>
    <p>
      Machine settings &#40;with <code>machine</code> or{' '}
      <code>machine-overridable</code> scopes&#41; are not synchronized by
      default, since their values are specific to a given machine. You can also
      add or remove settings you want to this list from the Settings editor or
      using the <code>setting settingsSync.ignoredSettings</code>.
    </p>
    <p>
      Keyboard Shortcuts are synchronized per platform by default. If your
      keyboard shortcuts are platform-agnostic, you can synchronize them across
      platforms by disabling the setting{' '}
      <code>settingsSync.keybindingsPerPlatform</code>.
    </p>
    <p>
      All built-in and installed extensions are synchronized along with their
      global enablement state. You can skip synchronizing an extension, either
      from the Extensions view &#40;Ctrl+Shift+X&#41; or using the{' '}
      <code>setting settingsSync.ignoredExtensions</code>.
    </p>
    <p>Following UI State is synchronized currently:</p>
    <ul>
      <li>Display Language</li>
      <li>Activity Bar entries</li>
      <li>Panel entries</li>
      <li>Views layout and visibility</li>
      <li>Recently used commands</li>
      <li>Do not show again notifications</li>
    </ul>
    <p>
      You can always change what is synced via the{' '}
      <strong>Settings Sync: Configure</strong> command or by opening the{' '}
      <strong>Manage</strong> gear menu, selecting{' '}
      <strong>Settings Sync is On</strong>, and then{' '}
      <strong>Settings Sync: Configure</strong>.
    </p>
    <h2 id="conflicts">Conflicts</h2>
    <p>
      When synchronizing settings between multiple machines, there may
      occasionally be conflicts. Conflicts can happen when first setting up sync
      between machines or when settings change while a machine is offline. When
      conflicts occur, you will be presented with the following options:
    </p>
    <ul>
      <li>
        <strong>Accept Local</strong>: Selecting this option will overwrite{' '}
        <strong>remote</strong> settings in the cloud with your local settings.
      </li>
      <li>
        <strong>Accept Remote</strong>: Selecting this option will overwrite{' '}
        <strong>local</strong> settings with remote settings from the cloud.
      </li>
      <li>
        <strong>Show Conflicts</strong>: Selecting this will display a diff
        editor similar to the Source Control diff editor, where you can preview
        the local and remote settings and choose to either accept local or
        remote or manually resolve the changes in your local settings file and
        then accept the local file.
      </li>
    </ul>
    <h2 id="switching-accounts">Switching Accounts</h2>
    <p>
      If at any time you want to sync your data to a different account, you can
      turn off and turn on Settings Sync again with different account.
    </p>
    <h2 id="syncing-stable-versus-insiders">Syncing Stable versus Insiders</h2>
    <p>
      By default, the AVAP<sup>TM</sup> Dev Studio Stable and Insiders builds
      use different Settings Sync services, and therefore do not share settings.
      You can sync your Insiders with Stable by selecting the Stable sync
      service while turning on Settings Sync. This option is only available in
      AVAP<sup>TM</sup> Dev Studio Insiders.
    </p>
    <blockquote>
      <p>
        <strong>Note:</strong> Since Insiders builds are newer than Stable
        builds, syncing them can sometimes lead to data incompatibility. In such
        cases, Settings sync will be disabled automatically on stable to prevent
        data inconsistencies. Once newer version of Stable build is released,
        you can upgrade your stable client and turn on sync to continue syncing.
      </p>
    </blockquote>
    <h2 id="restoring-data">Restoring data</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio always stores local and remote backups of
      your preferences while syncing and provides views for accessing these. In
      case something goes wrong, you can restore your data from these views.
    </p>
    <p>
      You can open these views using{' '}
      <strong>Settings Sync: Show Synced Data</strong> command from the Command
      Palette. The Local Sync activity view is hidden by default and you can
      enable it using <strong>Views</strong> submenu under{' '}
      <strong>Settings Sync</strong> view overflow actions.
    </p>
    <p>
      Local backups folder in the disk can be accessed via the{' '}
      <strong>Settings Sync: Open Local Backups Folder</strong> command. The
      folder is organized by the type of preference and contains versions of
      your JSON files, named with a timestamp of when the backup occurred.
    </p>
    <p>
      <strong>Note</strong>: Local backups are automatically deleted after 30
      days. For remote backups the latest 20 versions of each individual
      resource &#40;settings, extensions, etc.&#41; is retained.
    </p>
    <h2 id="synced-machines">Synced Machines</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio keeps track of the machines synchronizing
      your preferences and provides a view to access them. Every machine is
      given a default name based on the type of AVAP<sup>TM</sup> Dev Studio
      &#40;Insiders or Stable&#41; and the platform it is on. You can always
      update the machine name using the edit action available on the machine
      entry in the view. You can also disable sync on another machine using{' '}
      <strong>Turn off Settings Sync</strong> context menu action on the machine
      entry in the view.
    </p>
    <p>
      You can open this view using{' '}
      <strong>Settings Sync: Show Synced Data</strong> command from the Command
      Palette.
    </p>
    <h2 id="extension-authors">Extension authors</h2>
    <p>
      If you are an extension author, you should make sure your extension
      behaves appropriately when users enable Setting Sync. For example, you
      probably don&#39;t want your extension to display the same dismissed
      notifications or welcome pages on multiple machines.
    </p>
    <h3 id="sync-user-global-state-between-machines">
      Sync user global state between machines
    </h3>
    <p>
      If your extension needs to preserve some user state across different
      machines then provide the state to Settings Sync using{' '}
      <code>vscode.ExtensionContext.globalState.setKeysForSync.</code> Sharing
      state such as UI dismissed or viewed flags across machines can provide a
      better user experience.
    </p>
    <p>
      There is an example of using <code>setKeysforSync</code> in the Extension
      Capabilities topic.
    </p>
    <h2 id="reporting-issues">Reporting issues</h2>
    <p>
      Settings Sync activity can be monitored in the{' '}
      <strong>Log &#40;Settings Sync&#41;</strong> output view. If you
      experience a problem with Settings Sync, include this log when creating
      the issue. If your problem is related to authentication, also include the
      log from the <strong>Account</strong> output view.
    </p>
    <h2 id="how-do-i-delete-my-data">How do I delete my data?</h2>
    <p>
      If you want to remove all your data from our servers, just turn off sync
      via <strong>Settings Sync is On</strong> menu available under{' '}
      <strong>Manage</strong> gear menu and select the checkbox to clear all
      cloud data. If you choose to re-enable sync, it will be as if you&#39;re
      signing in for the first time.
    </p>
    <h2 id="next-steps">Next steps</h2>
    <ul>
      <li>
        User and Workspace settings - Learn how to configure AVAP<sup>TM</sup>{' '}
        Dev Studio to your preferences through user and workspace settings.
      </li>
    </ul>
    <h2 id="common-questions">Common questions</h2>
    <h3 id="is-avap-tm-dev-studio-settings-sync-the-same-as-the-settings-sync-extension">
      Is AVAP TM Dev Studio Settings Sync the same as the Settings Sync
      extension?
    </h3>
    <p>
      No, the Settings Sync extension by Shan Khan uses a private Gist on GitHub
      to share your AVAP<sup>TM</sup> Dev Studio settings across different
      machines and is unrelated to the AVAP<sup>TM</sup> Dev Studio Settings
      Sync.
    </p>
    <h3 id="what-types-of-accounts-can-i-use-for-settings-sync-sign-in">
      What types of accounts can I use for Settings Sync sign in?
    </h3>
    <p>
      AVAP<sup>TM</sup> Dev Studio Settings Sync supports signing in with either
      a Microsoft account &#40;for example Outlook or Azure accounts&#41; or a
      GitHub account. Sign in with GitHub Enterprise accounts is not supported.
      Other authentication providers may be supported in the future and you can
      review the proposed Authentication Provider API in issue #88309.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: AVAP<sup>TM</sup> Dev Studio Settings Sync does
        not support using your Microsoft Sovereign Cloud account at this time.
        If this is something you would like, please let us know what kind of
        Microsoft Sovereign Cloud you would like to use in this GitHub issue.
      </p>
    </blockquote>
    <h3 id="can-i-use-a-different-backend-or-service-for-settings-sync">
      Can I use a different backend or service for Settings Sync?
    </h3>
    <p>
      Settings Sync uses a dedicated service to store settings and coordinate
      updates. A service provider API may be exposed in the future to allow for
      custom Settings Sync backends.
    </p>
    <h2 id="troubleshooting-keychain-issues">
      Troubleshooting keychain issues
    </h2>
    <blockquote>
      <p>
        <strong>Note</strong>: This section applies to AVAP<sup>TM</sup> Dev
        Studio version <strong>1.80 and higher</strong>. In 1.80, we moved away
        from keytar, due to its archival, in favor of Electron&#39;s safeStorage
        API.
      </p>
    </blockquote>
    <blockquote>
      <p>
        <strong>Note</strong>: keychain, keyring, wallet, credential store are
        synonymous in this document.
      </p>
    </blockquote>
    <p>
      Settings Sync persists authentication information on desktop using the OS
      keychain for encryption. Using the keychain can fail in some cases if the
      keychain is misconfigured or the environment isn&#39;t recognized.
    </p>
    <p>
      To help diagnose the problem, you can restart AVAP<sup>TM</sup> Dev Studio
      with the following flags to generate a verbose log:
    </p>

    <pre className="">
      <code className="">
        code --verbose --vmodule="\*/components/os\_crypt/\*=1"
      </code>
    </pre>
    <h3 id="windows--macos">Windows &amp; macOS</h3>
    <p>
      At this time, there are no known configuration issues on Windows or macOS
      but, if you suspect something is wrong, you can open an issue on AVAP
      <sup>TM</sup> Dev Studio with the verbose logs from above. This is
      important for us to support additional desktop configurations.
    </p>
    <h3 id="linux">Linux</h3>
    <p>
      Towards the top of the logs from the previous command, you will see
      something to the effect of:
    </p>
    <p>
      [9699:0626/093542.027629:VERBOSE1:key_storage_util_linux.cc&#40;54&#41;]
      Password storage detected desktop environment: GNOME
    </p>
    <p>
      [9699:0626/093542.027660:VERBOSE1:key_storage_linux.cc&#40;122&#41;]
      Selected backend for OSCrypt: GNOME_LIBSECRET
    </p>
    <p>
      We rely on Chromium&#39;s oscrypt module to discover and store encryption
      key information in the keyring. Chromium supports a number of different
      desktop environments. Outlined below are some popular desktop environments
      and troubleshooting steps that may help if the keyring is misconfigured.
    </p>
    <h4 id="gnome-or-unity-or-similar">GNOME or UNITY &#40;or similar&#41;</h4>
    <p>
      If the error you&#39;re seeing is &quot;Cannot create an item in a locked
      collection&quot;, chances are your keyring&#39;s <code>Login</code>{' '}
      keyring is locked. You should launch your OS&#39;s keyring &#40;Seahorse
      is the commonly used GUI for seeing keyrings&#41; and ensure the default
      keyring &#40;usually referred to as <code>Login</code> keyring&#41; is
      unlocked. This keyring needs to be unlocked when you log into your system.
    </p>
    <h4 id="kde">KDE</h4>
    <blockquote>
      <p>
        KDE 6 is not yet fully supported by AVAP<sup>TM</sup> Dev Studio. As a
        workaround: The latest kwallet6 is also accessible as kwallet5, so you
        can force it to use kwallet5 by setting the password store to{' '}
        <code>kwallet5</code> as explained below in Configure the keyring to use
        with AVAP<sup>TM</sup> Dev Studio.
      </p>
    </blockquote>
    <p>
      It&#39;s possible that your wallet &#40;aka keyring&#41; is closed. If you
      open KWalletManager, you can see if the default <code>kdewallet</code> is
      closed and if it is, make sure you open it.
    </p>
    <p>
      If you are using KDE5 or higher and are having trouble connecting to{' '}
      <code>kwallet5</code> &#40;like users of the unofficial AVAP<sup>TM</sup>{' '}
      Dev Studio Flatpak in issue #189672&#41;, you can try configuring the
      keyring to <code>gnome-libsecret</code> as this will use the Secret
      Service API to communicate with any valid keyring. <code>kwallet5</code>{' '}
      implements the Secret Service API and can be accessed using this method.
    </p>
    <h4 id="other-linux-desktop-environments">
      Other Linux desktop environments
    </h4>
    <p>
      First off, if your desktop environment wasn&#39;t detected, you can open
      an issue on AVAP<sup>TM</sup> Dev Studio with the verbose logs from above.
      This is important for us to support additional desktop configurations.
    </p>
    <h4 id="recommended-configure-the-keyring-to-use-with-avap-tm-dev-studio">
      &#40;recommended&#41; Configure the keyring to use with AVAP TM Dev Studio
    </h4>
    <p>
      You can manually tell AVAP<sup>TM</sup> Dev Studio which keyring to use by
      passing the <code>password-store</code> flag. Our recommended
      configuration is to first install gnome-keyring if you don&#39;t have it
      already and then launch AVAP<sup>TM</sup> Dev Studio with{' '}
      <code>code --password-store=&quot;gnome-libsecret&quot;</code>.
    </p>
    <p>
      If this solution works for you, you can persist the value of
      password-store by opening the Command Palette &#40;Ctrl+Shift+P&#41; and
      running the <strong>Preferences: Configure Runtime Arguments</strong>{' '}
      command. This will open the argv.json file where you can add the setting
      &quot;password-store&quot;:&quot;gnome-libsecret&quot;.
    </p>
    <p>
      Here are all the possible values of password-store if you would like to
      try using a different keyring than gnome-keyring:
    </p>
    <ul>
      <li>
        <code>kwallet5</code>: For use with kwalletmanager5.
      </li>
      <li>
        <code>gnome-libsecret</code>: For use with any package that implements
        the Secret Service API &#40;for example <code>gnome-keyring</code>,{' '}
        <code>kwallet5</code>, <code>KeepassXC</code>&#41;.
      </li>
      <li>
        <em>&#40;not recommended&#41;</em> kwallet: For use with older versions
        of kwallet.
      </li>
      <li>
        <em>&#40;not recommended&#41;</em> basic: See the section below on basic
        text for more details.
      </li>
    </ul>
    <p>
      Don&#39;t hesitate to open an issue on AVAP<sup>TM</sup> Dev Studio with
      the verbose logs if you run into any issues.
    </p>
    <h4 id="not-recommended-configure-basic-text-encryption">
      &#40;not recommended&#41; Configure basic text encryption
    </h4>
    <p>
      We rely on Chromium&#39;s oscrypt module to discover and store encryption
      key information in the keyring. Chromium offers an opt-in fallback
      encryption strategy that uses an in-memory key based on a string that is
      hardcoded in the Chromium source. Because of this, this fallback strategy
      is, at best, obfuscation, and should only be used if you are accepting of
      the risk that any process on the system could, in theory, decrypt your
      stored secrets.
    </p>
    <p>
      If you accept this risk, you can set <code>password-store</code>to basic
      by opening the Command Palette &#40;Ctrl+Shift+P&#41; and running the{' '}
      <strong>Preferences: Configure Runtime Arguments</strong> command. This
      will open the <code>argv.json</code> file where you can add the setting{' '}
      <code>&quot;password-store&quot;:&quot;basic&quot;</code>.
    </p>
    <h2 id="can-i-share-settings-between-avap-tm-dev-studio">
      Can I share settings between AVAP TM Dev Studio
    </h2>
    <p>
      {' '}
      Stable and Insiders? Yes. Please refer to the Syncing Stable versus
      Insiders section for more information.
    </p>
    <p>
      Please note that this can sometimes lead to data incompatibility because
      Insiders builds are newer than Stable builds. In such cases, Settings Sync
      will be disabled automatically on Stable to prevent data inconsistencies.
      Once a newer version of the Stable build is released, you can upgrade your
      client and turn on Settings Sync to continue syncing.
    </p>
  </Fragment>
);
export default USER_GUIDE_SettingsSync;
