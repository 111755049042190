import React, { Fragment } from 'react';

const USERGUIDE_BasicEditing = () => (
  <Fragment>
    <h1 id="basic-editing">Basic Editing</h1>
    <p>
      AVAP™ Dev Studio 2024 is an editor first and foremost, and includes the
      features you need for highly productive source code editing. This topic
      takes you through the basics of the editor and helps you get moving with
      your code.
    </p>
    <h2 id="keyboard-shortcuts">Keyboard shortcuts</h2>
    <p>
      Being able to keep your hands on the keyboard when writing code is crucial
      for high productivity. AVAP™ DS Code has a rich set of default keyboard
      shortcuts as well as allowing you to customize them.
    </p>
    <ul>
      <li>
        Keyboard Shortcuts Reference - Learn the most commonly used and popular
        keyboard shortcuts by downloading the reference sheet.
      </li>
      <li>
        Install a Keymap extension - Use the keyboard shortcuts of your old
        editor &#40;such as Sublime Text, Atom, and Vim&#41; in AVAP™ DS Code by
        installing a Keymap extension.
      </li>
      <li>
        Customize Keyboard Shortcuts - Change the default keyboard shortcuts to
        fit your style.
      </li>
    </ul>
    <h2 id="multiple-selections-multi-cursor">
      Multiple selections &#40;multi-cursor&#41;
    </h2>
    <p>
      AVAP™ DS Code supports multiple cursors for fast simultaneous edits. You
      can add secondary cursors &#40;rendered thinner&#41; with Alt+Click. Each
      cursor operates independently based on the context it sits in. A common
      way to add more cursors is with ⌥⌘↓ or ⌥⌘↑ that insert cursors below or
      above.
    </p>
    <p>
      Note: Your graphics card driver &#40;for example NVIDIA&#41; might
      overwrite these default shortcuts.
    </p>
    <p>
      <strong>IMAGEN</strong>
    </p>
    <p>
      <code>⌘D</code> selects the word at the cursor, or the next occurrence of
      the current selection.
    </p>
    <p>
      <strong>IMAGEN</strong>
    </p>
    <blockquote>
      <p>
        Tip: You can also add more cursors with ⇧⌘L, which will add a selection
        at each occurrence of the current selected text.
      </p>
    </blockquote>
    <h3 id="multi-cursor-modifier">Multi-cursor modifier</h3>
    <p>
      If you&#39;d like to change the modifier key for applying multiple cursors
      to Cmd+Click on macOS and Ctrl+Click on Windows and Linux, you can do so
      with the editor.multiCursorModifier setting. This lets users coming from
      other editors such as Sublime Text or Atom continue to use the keyboard
      modifier they are familiar with.
    </p>
    <p>The setting can be set to:</p>
    <ul>
      <li>ctrlCmd - Maps to Ctrl on Windows and Cmd on macOS.</li>
      <li>alt - The existing default Alt.</li>
    </ul>
    <p>
      There&#39;s also a menu item Use Ctrl+Click for Multi-Cursor in the
      Selection menu to quickly toggle this setting.
    </p>
    <p>
      The Go to Definition and Open Link gestures will also respect this setting
      and adapt such that they do not conflict. For example, when the setting is
      ctrlCmd, multiple cursors can be added with Ctrl/Cmd+Click, and opening
      links or going to definition can be invoked with Alt+Click.
    </p>
    <h3 id="shrinkexpand-selection">Shrink/expand selection</h3>
    <p>
      Quickly shrink or expand the current selection. Trigger it with ⌃⇧⌘← and
      ⌃⇧⌘→.
    </p>
    <p>Here&#39;s an example of expanding the selection with ⌃⇧⌘→:</p>
    <p>
      <strong>IMAGEN</strong>
    </p>
    <h2 id="column-box-selection">Column &#40;box&#41; selection</h2>
    <p>
      Place the cursor in one corner and then hold Shift+Alt while dragging to
      the opposite corner:
    </p>
    <p>
      <strong>IMAGEN</strong>
    </p>
    <p>
      Note: This changes to Shift+Ctrl/Cmd when using Ctrl/Cmd as multi-cursor
      modifier.
    </p>
    <p>
      There are also default key bindings for column selection on macOS and
      Windows, but not on Linux.
    </p>
    <table>
      <thead>
        <tr>
          <th align="left">Key</th>
          <th align="left">Command</th>
          <th align="left">Command ID</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="left">⇧↓</td>
          <td align="left">Column Select Down</td>
          <td align="left">
            <code>cursorColumnSelectDown</code>
          </td>
        </tr>
        <tr>
          <td align="left">⇧↑</td>
          <td align="left">Column Select Up</td>
          <td align="left">
            <code>cursorColumnSelectUp</code>
          </td>
        </tr>
        <tr>
          <td align="left">⇧←</td>
          <td align="left">Column Select Left</td>
          <td align="left">
            <code>cursorColumnSelectLeft</code>
          </td>
        </tr>
        <tr>
          <td align="left">⇧→</td>
          <td align="left">Column Select Right</td>
          <td align="left">
            <code>cursorColumnSelectRight</code>
          </td>
        </tr>
        <tr>
          <td align="left">⇧PageDown</td>
          <td align="left">Column Select Page Down</td>
          <td align="left">
            <code>cursorColumnSelectPageDown</code>
          </td>
        </tr>
        <tr>
          <td align="left">⇧PageUp</td>
          <td align="left">Column Select Page Up</td>
          <td align="left">
            <code>cursorColumnSelectPageUp</code>
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      You can edit your <code>keybindings.json</code> to bind them to something
      more familiar if you want.
    </p>
    <h4 id="column-selection-mode">Column Selection mode</h4>
    <p>
      The user setting Editor: Column Selection controls this feature. Once this
      mode is entered, as indicated in the Status bar, the mouse gestures and
      the arrow keys will create a column selection by default. This global
      toggle is also accessible via the Selection &gt; Column Selection Mode
      menu item. In addition, one can also disable Column Selection mode from
      the Status bar.
    </p>
    <h2 id="save--auto-save">Save / Auto Save</h2>
    <p>
      By default, AVAP™ DS requires an explicit action to save your changes to
      disk, ⌘S.
    </p>
    <p>
      However, it&#39;s easy to turn on <code>Auto Save</code>, which will save
      your changes after a configured delay or when focus leaves the editor.
      With this option turned on, there is no need to explicitly save the file.
      The easiest way to turn on <code>Auto Save</code> is with the** File &gt;
      Auto Save **toggle that turns on and off save after a delay.
    </p>
    <p>
      For more control over <code>Auto Save</code>, open User or Workspace
      settings and find the associated settings:
    </p>
    <ul>
      <li>
        <p>files.autoSave: Can have the values:</p>
        <ul>
          <li>off - to disable auto save.</li>
          <li>
            afterDelay - to save files after a configured delay &#40;default
            1000 ms&#41;.
          </li>
          <li>
            onFocusChange - to save files when focus moves out of the editor of
            the dirty file.
          </li>
          <li>
            onWindowChange - to save files when the focus moves out of the AVAP™
            DS window.
          </li>
        </ul>
      </li>
      <li>
        <p>
          files.autoSaveDelay: Configures the delay in milliseconds when
          files.autoSave is configured to afterDelay. The default is 1000 ms.
        </p>
      </li>
    </ul>
    <h2 id="hot-exit">Hot Exit</h2>
    <p>
      AVAP™ DS will remember unsaved changes to files when you exit by default.
      Hot exit is triggered when the application is closed via File &gt; Exit
      &#40;Code &gt; Quit on macOS&#41; or when the last window is closed.
    </p>
    <p>
      You can configure hot exit by setting files.hotExit to the following
      values:
    </p>
    <ul>
      <li>&quot;off&quot;: Disable hot exit.</li>
      <li>
        &quot;onExit&quot;: Hot exit will be triggered when the application is
        closed, that is when the last window is closed on Windows/Linux or when
        the workbench.action.quit command is triggered &#40;from the Command
        Palette, keyboard shortcut or menu&#41;. All windows without folders
        opened will be restored upon next launch.
      </li>
      <li>
        &quot;onExitAndWindowClose&quot;: Hot exit will be triggered when the
        application is closed, that is when the last window is closed on
        Windows/Linux or when the workbench.action.quit command is triggered
        &#40;from the Command Palette, keyboard shortcut or menu&#41;, and also
        for any window with a folder opened regardless of whether it is the last
        window. All windows without folders opened will be restored upon next
        launch. To restore folder windows as they were before shutdown, set
        window.restoreWindows to all.
      </li>
    </ul>
    <p>
      If something happens to go wrong with hot exit, all backups are stored in
      the following folders for standard install locations:
    </p>
    <ul>
      <li>Windows %APPDATA%\Code\Backups</li>
      <li>macOS $HOME/Library/Application Support/Code/Backups</li>
      <li>Linux $HOME/.config/Code/Backups</li>
    </ul>
    <h3 id="find-and-replace">Find and Replace</h3>
    <p>
      AVAP™ DS allows you to quickly find text and replace in the currently
      opened file. Press ⌘F to open the Find Widget in the editor, search
      results will be highlighted in the editor, overview ruler and minimap.
    </p>
    <p>
      If there are more than one matched result in the current opened file, you
      can press Enter and ⇧Enter to navigate to next or previous result when the
      find input box is focused.
    </p>
    <h4 id="seed-search-string-from-selection">
      Seed Search String From Selection
    </h4>
    <p>
      When the Find Widget is opened, it will automatically populate the
      selected text in the editor into the find input box. If the selection is
      empty, the word under the cursor will be inserted into the input box
      instead.
    </p>
    <p>
      <strong>IMAGEN</strong>
    </p>
    <p>
      This feature can be turned off by setting{' '}
      <code>
        editor.find.seedSearchStringFromSelection to &quot;never&quot;.
      </code>
    </p>
    <h4 id="find-in-selection">Find In Selection</h4>
    <p>
      By default, the find operations are run on the entire file in the editor.
      It can also be run on selected text. You can turn this feature on by
      clicking the hamburger icon on the Find Widget.
    </p>
    <p>
      <strong>IMAGEN</strong>
    </p>
    <p>
      If you want it to be the default behavior of the Find Widget, you can set{' '}
      <code>editor.find.autoFindInSelection</code> to <code>always</code>, or to{' '}
      <code>multiline</code>, if you want it to be run on selected text only
      when multiple lines of content are selected.
    </p>
    <h4 id="advanced-find-and-replace-options">
      Advanced find and replace options
    </h4>
    <p>
      In addition to find and replace with plain text, the Find Widget also has
      three advanced search options:
    </p>
    <ul>
      <li>Match Case</li>
      <li>Match Whole Word</li>
      <li>Regular Expression</li>
    </ul>
    <p>
      The replace input box support case preserving, you can turn it on by
      clicking the Preserve Case &#40;<strong>AB</strong>&#41; button.
    </p>
    <h4 id="multiline-support-and-find-widget-resizing">
      Multiline support and Find Widget resizing
    </h4>
    <p>
      You can search multiple line text by pasting the text into the Find input
      box and Replace input box. Pressing <code>Ctrl+Enter</code> inserts a new
      line in the input box.
    </p>
    <p>
      <strong>IMAGEN</strong>
    </p>
    <p>
      While searching long text, the default size of Find Widget might be too
      small. You can drag the left sash to enlarge the Find Widget or double
      click the left sash to maximize it or shrink it to its default size.
    </p>
    <p>
      <strong>IMAGEN</strong>
    </p>
    <h2 id="search-across-files">Search across files</h2>
    <p>
      AVAP™ DS allows you to quickly search over all files in the currently
      opened folder. Press ⇧⌘F and enter your search term. Search results are
      grouped into files containing the search term, with an indication of the
      hits in each file and its location. Expand a file to see a preview of all
      of the hits within that file. Then single-click on one of the hits to view
      it in the editor.
    </p>
    <p>
      <strong>IMAGEN</strong>
    </p>
    <blockquote>
      <p>
        Tip: We support regular expression searching in the search box, too.
      </p>
    </blockquote>
    <p>
      You can configure advanced search options by clicking the ellipsis
      &#40;Toggle Search Details&#41; below the search box on the right &#40;or
      press ⇧⌘J&#41;. This will show additional fields to configure the search.
    </p>
    <h3 id="advanced-search-options">Advanced search options</h3>
    <p>
      <strong>IMAGEN</strong>
    </p>
    <p>
      In the two input boxes below the search box, you can enter patterns to
      include or exclude from the search. If you enter example, that will match
      every folder and file named example in the workspace. If you enter
      ./example, that will match the folder example/ at the top level of your
      workspace. Use , to separate multiple patterns. Paths must use forward
      slashes. You can also use glob pattern syntax, for example:
    </p>
    <ul>
      <li>
        <code>*</code> to match zero or more characters in a path segment
      </li>
      <li>
        <code>?</code> to match on one character in a path segment
      </li>
      <li>
        <code>**</code> to match any number of path segments, including none
      </li>
      <li>
        <code>&#123;&#125;</code>to group conditions &#40;for example &#123;
        <strong>.html,</strong>.txt&#125; matches all HTML and text
        files&#41;
      </li>
      <li>
        <code>[]</code> to declare a range of characters to match
        &#40;example.[0-9] to match on example.0, example.1, …&#41;
      </li>
      <li>
        <code>[!...]</code> to negate a range of characters to match
        &#40;example.[!0-9] to match on example.a, example.b, but not
        example.0&#41;
      </li>
    </ul>
    <p>
      AVAP™ DS excludes some folders by default to reduce the number of search
      results that you are not interested in &#40;for example:
      node_modules&#41;. Open settings to change these rules under the
      files.exclude and search.exclude section.
    </p>
    <p>
      Note that glob patterns in the Search view work differently than in
      settings such as files.exclude and search.exclude. In the settings, you
      must use *
      <em>
        /example to match a folder named example in subfolder folder1/example in
        your workspace. In the Search view, the *
      </em>{' '}
      prefix is assumed. The glob patterns in these settings are always
      evaluated relative to the path of the workspace folder.
    </p>
    <p>
      Also note the Use Exclude Settings and Ignore Files toggle button in the
      files to exclude box. The toggle determines whether to exclude files that
      are ignored by your .gitignore files and/or matched by your files.exclude
      and search.exclude settings.
    </p>
    <p>
      Tip: From the Explorer, you can right-click on a folder and select Find in
      Folder to search inside a folder only.
    </p>
    <p>
      Search and replace You can also Search and Replace across files. Expand
      the Search widget to display the Replace text box.
    </p>
    <p>search and replace</p>
    <p>
      When you type text into the Replace text box, you will see a diff display
      of the pending changes. You can replace across all files from the Replace
      text box, replace all in one file or replace a single change.
    </p>
    <p>search and replace diff view</p>
    <p>
      Tip: You can quickly reuse a previous search term by using ↓ and ↑ to
      navigate through your search term history.
    </p>
    <p>
      Case changing in regex replace AVAP™ DS supports changing the case of
      regex matching groups while doing Search and Replace in the editor or
      globally. This is done with the modifiers \u\U\l\L, where \u and \l will
      upper/lowercase a single character, and \U and \L will upper/lowercase the
      rest of the matching group.
    </p>
    <p>Example:</p>
    <p>Changing case while doing find and replace</p>
    <p>
      The modifiers can also be stacked - for example, \u\u\u$1 will uppercase
      the first three characters of the group, or \l\U$1 will lowercase the
      first character, and uppercase the rest. The capture group is referenced
      by $n in the replacement string, where n is the order of the capture
      group.
    </p>
    <p>
      Search Editor Search Editors let you view workspace search results in a
      full-sized editor, complete with syntax highlighting and optional lines of
      surrounding context.
    </p>
    <p>
      Below is a search for the word &#39;SearchEditor&#39; with two lines of
      text before and after the match for context:
    </p>
    <p>Search Editor overview</p>
    <p>
      The Open Search Editor command opens an existing Search Editor if one
      exists, or to otherwise create a new one. The New Search Editor command
      will always create a new Search Editor.
    </p>
    <p>
      In the Search Editor, results can be navigated to using Go to Definition
      actions, such as F12 to open the source location in the current editor
      group, or ⌘K F12 to open the location in an editor to the side.
      Additionally, double-clicking can optionally open the source location,
      configurable with the search.searchEditor.doubleClickBehaviour setting.
    </p>
    <p>
      You can also use the Open New Search Editor button at the top of the
      Search view, and can copy your existing results from a Search view over to
      a Search Editor with the Open in editor link at the top of the results
      tree, or the Search Editor: Open Results in Editor command.
    </p>
    <p>Search Editor Button</p>
    <p>
      The Search Editor above was opened by selecting the Open New Search Editor
      button &#40;third button&#41; on the top of the Search view.
    </p>
    <p>
      Search Editor commands and arguments search.action.openNewEditor - Opens
      the Search Editor in a new tab. search.action.openInEditor - Copy the
      current Search results into a new Search Editor.
      search.action.openNewEditorToSide - Opens the Search Editor in a new
      window next to the window you currently have opened. There are two
      arguments that you can pass to the Search Editor commands
      &#40;search.action.openNewEditor, search.action.openNewEditorToSide&#41;
      to allow keybindings to configure how a new Search Editor should behave:
    </p>
    <p>
      triggerSearch - Whether a search be automatically run when a Search Editor
      is opened. Default is true. focusResults - Whether to put focus in the
      results of a search or the query input. Default is true. For example, the
      following keybinding runs the search when the Search Editor is opened but
      leaves the focus in the search query control.
    </p>
    <p>
      &#123; &quot;key&quot;: &quot;ctrl+o&quot;, &quot;command&quot;:
      &quot;search.action.openNewEditor&quot;, &quot;args&quot;: &#123;
      &quot;query&quot;: &quot;AVAP™ DS&quot;, &quot;triggerSearch&quot;: true,
      &quot;focusResults&quot;: false &#125; &#125; Search Editor context
      default The search.searchEditor.defaultNumberOfContextLines setting has a
      default value of 1, meaning one context line will be shown before and
      after each result line in the Search Editor.
    </p>
    <p>
      Reuse last Search Editor configuration The
      search.searchEditor.reusePriorSearchConfiguration setting &#40;default is
      false&#41; lets you reuse the last active Search Editor&#39;s
      configuration when creating a new Search Editor.
    </p>
    <p>
      IntelliSense We&#39;ll always offer word completion, but for the rich
      languages, such as JavaScript, JSON, HTML, CSS, SCSS, Less, C# and
      TypeScript, we offer a true IntelliSense experience. If a language service
      knows possible completions, the IntelliSense suggestions will pop up as
      you type. You can always manually trigger it with ⌃Space. By default, Tab
      or Enter are the accept keyboard triggers but you can also customize these
      key bindings.
    </p>
    <p>
      Tip: The suggestions filtering supports CamelCase so you can type the
      letters which are upper cased in a method name to limit the suggestions.
      For example, &quot;cra&quot; will quickly bring up
      &quot;createApplication&quot;.
    </p>
    <p>
      Tip: IntelliSense suggestions can be configured via the
      editor.quickSuggestions and editor.suggestOnTriggerCharacters settings.
    </p>
    <p>
      JavaScript and TypeScript developers can take advantage of the npmjs type
      declaration &#40;typings&#41; file repository to get IntelliSense for
      common JavaScript libraries &#40;Node.js, React, Angular&#41;. You can
      find a good explanation on using type declaration files in the JavaScript
      language topic and the Node.js tutorial.
    </p>
    <p>Learn more in the IntelliSense document.</p>
    <p>
      Formatting AVAP™ DS has great support for source code formatting. The
      editor has two explicit format actions:
    </p>
    <p>
      Format Document &#40;⇧⌥F&#41; - Format the entire active file. Format
      Selection &#40;⌘K ⌘F&#41; - Format the selected text. You can invoke these
      from the Command Palette &#40;⇧⌘P&#41; or the editor context menu.
    </p>
    <p>
      AVAP™ DS has default formatters for JavaScript, TypeScript, JSON, HTML,
      and CSS. Each language has specific formatting options &#40;for example,
      html.format.indentInnerHtml&#41; which you can tune to your preference in
      your user or workspace settings. You can also disable the default language
      formatter if you have another extension installed that provides formatting
      for the same language.
    </p>
    <p>
      &quot;html.format.enable&quot;: false Along with manually invoking code
      formatting, you can also trigger formatting based on user gestures such as
      typing, saving or pasting. These are off by default but you can enable
      these behaviors through the following settings:
    </p>
    <p>
      editor.formatOnType - Format the line after typing. editor.formatOnSave -
      Format a file on save. editor.formatOnPaste - Format the pasted content.
      Note: Not all formatters support format on paste as to do so they must
      support formatting a selection or range of text.
    </p>
    <p>
      In addition to the default formatters, you can find extensions on the
      Marketplace to support other languages or formatting tools. There is a
      Formatters category so you can easily search and find formatting
      extensions. In the Extensions view search box, type &#39;formatters&#39;
      or &#39;category:formatters&#39; to see a filtered list of extensions
      within AVAP™ DS
    </p>
    <p>
      Folding You can fold regions of source code using the folding icons on the
      gutter between line numbers and line start. Move the mouse over the gutter
      and click to fold and unfold regions. Use Shift + Click on the folding
      icon to fold or unfold the region and all regions inside.
    </p>
    <p>Folding</p>
    <p>You can also use the following actions:</p>
    <p>
      Fold &#40;⌥⌘[&#41; folds the innermost uncollapsed region at the cursor.
      Unfold &#40;⌥⌘]&#41; unfolds the collapsed region at the cursor. Toggle
      Fold &#40;⌘K ⌘L&#41; folds or unfolds the region at the cursor. Fold
      Recursively &#40;⌘K ⌘[&#41; folds the innermost uncollapsed region at the
      cursor and all regions inside that region. Unfold Recursively &#40;⌘K
      ⌘]&#41; unfolds the region at the cursor and all regions inside that
      region. Fold All &#40;⌘K ⌘0&#41; folds all regions in the editor. Unfold
      All &#40;⌘K ⌘J&#41; unfolds all regions in the editor. Fold Level X
      &#40;⌘K ⌘2 for level 2&#41; folds all regions of level X, except the
      region at the current cursor position. Fold All Block Comments &#40;⌘K
      ⌘/&#41; folds all regions that start with a block comment token. Folding
      regions are by default evaluated based on the indentation of lines. A
      folding region starts when a line has a smaller indent than one or more
      following lines, and ends when there is a line with the same or smaller
      indent.
    </p>
    <p>
      Folding regions can also be computed based on syntax tokens of the
      editor&#39;s configured language. The following languages already provide
      syntax aware folding: Markdown, HTML, CSS, LESS, SCSS, and JSON.
    </p>
    <p>
      If you prefer to switch back to indentation-based folding for one &#40;or
      all&#41; of the languages above, use:
    </p>
    <p>
      {' '}
      &quot;[html]&quot;: &#123; &quot;editor.foldingStrategy&quot;:
      &quot;indentation&quot; &#125;, Regions can also be defined by markers
      defined by each language. The following languages currently have markers
      defined:
    </p>
    <p>
      Language Start region End region Bat ::#region or REM #region ::#endregion
      or REM #endregion C# #region #endregion C/C++ #pragma region #pragma
      endregion CSS/Less/SCSS /<em>#region</em>/ /<em>#endregion</em>/
      Coffeescript #region #endregion F# //#region or &#40;#_region&#41;
      //#endregion or &#40;#_endregion&#41; Java //#region or //
       //#endregion or //
      Markdown
      <br />
      Perl5 #region or =pod #endregion or =cut PHP #region #endregion PowerShell
      #region #endregion Python #region or # region #endregion or # endregion
      TypeScript/JavaScript //#region //#endregion Visual Basic #Region #End
      Region To fold and unfold only the regions defined by markers use:
    </p>
    <p>
      Fold Marker Regions &#40;⌘K ⌘8&#41; folds all marker regions. Unfold
      Marker Regions &#40;⌘K ⌘9&#41; unfolds all marker regions. Fold selection
      The command Create Manual Folding Ranges from Selection &#40;⌘K ⌘,&#41;
      creates a folding range from the currently selected lines and collapses
      it. That range is called a manual folding range that goes on top of the
      ranges computed by folding providers.
    </p>
    <p>
      Manual folding ranges can be removed with the command Remove Manual
      Folding Ranges &#40;⌘K ⌘.&#41;.
    </p>
    <p>
      Manual folding ranges are especially useful for cases when there isn&#39;t
      programming language support for folding.
    </p>
    <p>
      Indentation AVAP™ DS lets you control text indentation and whether
      you&#39;d like to use spaces or tab stops. By default, AVAP™ DS inserts
      spaces and uses 4 spaces per Tab key. If you&#39;d like to use another
      default, you can modify the editor.insertSpaces and editor.tabSize
      settings.
    </p>

    <pre className="">
      <code className="">
        "editor.insertSpaces": true,
        <br />
        "editor.tabSize": 4,
      </code>
    </pre>
    <p>
      Auto-detection VS Code analyzes your open file and determines the
      indentation used in the document. The auto-detected indentation overrides
      your default indentation settings. The detected setting is displayed on
      the right side of the Status Bar:
    </p>
    <p>auto detect indentation</p>
    <p>
      You can click on the Status Bar indentation display to bring up a dropdown
      with indentation commands allowing you to change the default settings for
      the open file or convert between tab stops and spaces.
    </p>
    <p>indentation commands</p>
    <p>
      Note: AVAP™ DS auto-detection checks for indentations of 2, 4, 6 or 8
      spaces. If your file uses a different number of spaces, the indentation
      may not be correctly detected. For example, if your convention is to
      indent with 3 spaces, you may want to turn off editor.detectIndentation
      and explicitly set the tab size to 3.
    </p>

    <pre className="">
      <code className="">
        "editor.detectIndentation": false,
        <br />
        "editor.tabSize": 3,
      </code>
    </pre>
    <p>
      File encoding support Set the file encoding globally or per workspace by
      using the files.encoding setting in User Settings or Workspace Settings.
    </p>
    <p>files.encoding setting</p>
    <p>You can view the file encoding in the status bar.</p>
    <p>Encoding in status bar</p>
    <p>
      Click on the encoding button in the status bar to reopen or save the
      active file with a different encoding.
    </p>
    <p>Reopen or save with a different encoding</p>
    <p>Then choose an encoding.</p>
    <p>Select an encoding</p>
    <p>
      Next steps You&#39;ve covered the basic user interface - there is a lot
      more to AVAP™ DS. Read on to find out about:
    </p>
    <p>
      Intro Video - Setup and Basics - Watch a tutorial on the basics of AVAP™
      DS. User/Workspace Settings - Learn how to configure AVAP™ DS to your
      preferences through user and workspace settings. Code Navigation - Peek
      and Goto Definition, and more. Integrated Terminal - Learn about the
      integrated terminal for quickly performing command-line tasks from within
      AVAP™ DS. IntelliSense - AVAP™ DS brings smart code completions. Debugging
      - This is where AVAP™ DS really shines. Common questions Is it possible to
      globally search and replace? Yes, expand the Search view text box to
      include a replace text field. You can search and replace across all the
      files in your workspace. Note that if you did not open AVAP™ DS on a
      folder, the search will only run on the currently open files.
    </p>
    <p>global search and replace</p>
    <p>
      How do I turn on word wrap? You can control word wrap through the
      editor.wordWrap setting. By default, editor.wordWrap is off but if you set
      to it to on, text will wrap on the editor&#39;s viewport width.
    </p>

    <pre className="">
      <code className="">"editor.wordWrap": "on"</code>
    </pre>
    <p>You can toggle word wrap for the AVAP™ DS session with ⌥Z.</p>
    <p>
      You can also add vertical column rulers to the editor with the
      editor.rulers setting, which takes an array of column character positions
      where you&#39;d like vertical rulers.
    </p>
    <p>
      As in other editors, commands such as Cut and Copy apply to the whole
      wrapped line. Triple-click selects the whole wrapped line. Pressing Home
      twice moves the cursor to the very beginning of the line. Pressing End
      twice moves the cursor to the very end of the line.
    </p>
    <p>
      How can I avoid placing extra cursors in word wrapped lines? If you&#39;d
      like to ignore line wraps when adding cursors above or below your current
      selection, you can pass in &#123; &quot;logicalLine&quot;: true &#125; to
      args on the keybinding like this:
    </p>
    <p>
      &#123; &quot;key&quot;: &quot;shift+alt+down&quot;, &quot;command&quot;:
      &quot;editor.action.insertCursorBelow&quot;, &quot;when&quot;:
      &quot;textInputFocus&quot;, &quot;args&quot;: &#123;
      &quot;logicalLine&quot;: true &#125;, &#125;, &#123; &quot;key&quot;:
      &quot;shift+alt+up&quot;, &quot;command&quot;:
      &quot;editor.action.insertCursorAbove&quot;, &quot;when&quot;:
      &quot;textInputFocus&quot;, &quot;args&quot;: &#123;
      &quot;logicalLine&quot;: true &#125;, &#125;,
    </p>
  </Fragment>
);
export default USERGUIDE_BasicEditing;
