import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Transferencias_masivas_de_dinero_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": <boolean>, 
          "resultado": { 
            "codtran": "f1c52ac37d90d7d235af842752bedbfa", 
            "user_commission": "$214.51 pesos", 
            "number_of_transfers": 3, 
            "amount": "$3,698.50 pesos" 
          }, 
          "elapsed": <float> 
        }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": false, 
          "level": <string>,
           "message": <string>,
            "error": <string>
           }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/movimientos.py/transferenciasmasivas" 
        payload = { 'country_code': 'MX', 'user_id': '4532', 'session_id': '423-yUYOoPNao_8-jV9OI5SVDQ==', 'archive_name': 'trial10.xlsx', 'concept': 'Lorem' } 
        files = [ ('archive', open('trial.xlsx','rb')) ] 
        headers= {} 
        response = requests.request("POST", url, headers=headers, data = payload, files = files) 
        print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests url = "URL_BASE/ws/movimientos.py/transferenciasmasivas" 
          payload = { 'country_code': 'MX', 'user_id': '4532', 'session_id': '423-yUYOoPNao_8-jV9OI5SVDQ==', 'archive_name': 'trial10.xlsx', 'concept': 'Lorem' } 
          files = [ ('archive', open('trial.xlsx','rb')) ] 
          headers= {} 
          response = requests.request("POST", url, headers=headers, data = payload, files = files) 
          print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var fs = require('fs');
              var options = {
              'method': 'POST',
              'url': 'URL_BASE/ws/movimientos.py/transferenciasmasivas',
              'headers': {},
              formData: {
              'country_code': 'MX',
              'user_id': '4532',
              'session_id': '423-yUYOoPNao_8-jV9OI5SVDQ==',
              'archive_name': 'trial10.xlsx',
              'concept': 'Lorem',
              'archive': {
              'value': fs.createReadStream('trial.xlsx'),
              'options': {
              'filename': 'trial.xlsx',
              'contentType': null
              }
              }
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var formdata = new FormData();
              formdata.append("country_code", "MX");
              formdata.append("user_id", "4532");
              formdata.append("session_id", "423-yUYOoPNao_8-jV9OI5SVDQ==");
              formdata.append("archive_name", "trial10.xlsx");
              formdata.append("concept", "Lorem");
              formdata.append("archive", fileInput.files[0], "trial.xlsx");
              var requestOptions = {
              method: 'POST',
              body: formdata,
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/movimientos.py/transferenciasmasivas", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request POST 'URL_BASE/ws/movimientos.py/transferenciasmasivas' \ --form 'country_code=MX' \ --form 'user_id=4532' \ --form 'session_id=423-yUYOoPNao_8-jV9OI5SVDQ==' \ --form 'archive_name=trial10.xlsx' \ --form 'concept=Lorem' \ --form 'archive=@trial.xlsx'`
        }
      ]
    }
  
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="multiple-money-transfers">Multiple Money Transfers</h1>
        <p>
          This service is aimed at making money transfers to different system users.
        </p>
        <p>
          <span className="label post">POST:</span>
          <span> </span>{' '}
          <code>URL_BASE + /ws/movimientos.py/transferenciasmasivas</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <div className='overflow-auto'>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
              <td align="center">ES &#40;Spain&#41;</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Identifier of the user making the request&#40;member&#41;
              </td>
              <td align="center">N/A</td>
              <td align="center">4532</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
              <td align="center">385-XadgYoWixbzN_wqWc3Yyog==</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sing the request without having to log in
              </td>
              <td align="center">N/A</td>
              <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
            </tr>
            <tr>
              <td align="left">archive_name</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">File name with extension.xlsx</td>
              <td align="center">N/A</td>
              <td align="center">test.xlsx</td>
            </tr>
            <tr>
              <td align="left">concept</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">Concept associated to request</td>
              <td align="center">N/A</td>
              <td align="center"></td>
            </tr>
            <tr>
              <td align="left">archive</td>
              <td align="center">File</td>
              <td align="center">Yes</td>
              <td align="center">
                xlsx document containing necessary data to make the money transfer
              </td>
              <td align="center">N/A</td>
              <td align="center"></td>
            </tr>
          </tbody>
        </table>
        </div>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>number_of_transfers:</code> Number of lines in the excel sent.
          </li>
          <li>
            <code>codtran:</code> Transaction code that identifies the executed
            operation.
          </li>
          <li>
            <code>user_commission:</code> Bank commission charged to the user.
          </li>
          <li>
            <code>amount:</code> Operation amount in the suitable format.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="donde">Donde:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>level:</code> Error importance level.
          </li>
          <li>
            <code>message:</code> Error message.
          </li>
          <li>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          This service must be consumed with a user who has an administrator or
          affiliate profile, and once the response is obtained, the transaction must
          be signed with the signature service indicating the codtran instead of the
          telephone number.
        </p>
        </div>

<div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
        </div>
      </Fragment>
    );
  }
export default Transferencias_masivas_de_dinero_EN;
