import React, { Fragment } from 'react';

const Communities = () => (
  <Fragment>
    <h1 >Communities</h1>
    <p>
      101OBeX organizes and groups the clients of a node or project into
      communities.
    </p>
    <p>
      Communities are groups of users or clients of a project whose main common
      element or union is the community to which they belong. This is
      determined, in most cases, although other criteria may apply, by
      affiliates &#40;corporations&#41; responsible for registering users or
      clients within the system.
    </p>
    <p>
      Users or clients in a project that do not have a specific community will
      belong to the community of the node or project. Importantly, end users or
      customers retain the flexibility to switch between communities as needed.
      Grouping users or customers by communities offers many advantages at the
      operation and data analysis level, since it allows us to undertake actions
      on a specific set of users or end customers based on the community to
      which they belong. Moreover, it greatly enhances capabilities for data
      mining and reporting activities.
    </p>
  </Fragment>
);
export default Communities;
