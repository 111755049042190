import React, { Fragment } from 'react';

const USER_GUIDE_MultiRootWorkspaces = () => (
  <Fragment>
    <h1 id="multi-root-workspaces">Multi-root Workspaces</h1>
    <p>
      You can work with multiple project folders in AVAP™ Dev Studio 2024 with
      multi-root workspaces. This can be helpful when you are working on several
      related projects at one time. For example, you might have a repository
      with a product&#39;s documentation that you like to keep current when you
      update the product source code.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: If you&#39;d like to learn more about the AVAP
        <sup>TM</sup> Dev Studio &quot;workspace&quot; concept, you can review
        What is a AVAP<sup>TM</sup> Dev Studio &quot;workspace&quot;? Unless you
        are explicitly creating a multi-root workspace, a &quot;workspace&quot;
        is just your project&#39;s single root folder.
      </p>
    </blockquote>
    <h2 id="adding-folders">Adding folders</h2>
    <p>
      It is easy to add another folder to your existing workspace. There are
      several gestures for adding folders:
    </p>
    <h3 id="add-folder-to-workspace">Add Folder to Workspace</h3>
    <p>
      The <strong>File</strong> &gt; <strong>Add Folder to Workspace</strong>{' '}
      command brings up an Open Folder dialog to select the new folder.
    </p>
    <p>
      Once a root folder is added, the Explorer will show the new folder as a
      root in the File Explorer. You can right-click on any of the root folders
      and use the context menu to add or remove folders.
    </p>
    <p>
      The File Explorer should work and behave as before. You can move files
      between root folders and use any of the typical file operation actions
      provided in the context menu and the Explorer view.
    </p>
    <p>
      Settings like files.exclude are supported for each root folder if
      configured, and across all folders if configured as global user setting.
    </p>
    <h3 id="drag-and-drop">Drag and drop</h3>
    <p>
      You can use drag and drop to add folders to a workspace. Drag a folder to
      the File Explorer to add it to the current workspace. You can even select
      and drag multiple folders.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: Dropping a single folder into the editor region
        of AVAP<sup>TM</sup> Dev Studio will still open the folder in single
        folder mode. If you drag and drop multiple folders into the editor
        region, a new multi-root workspace will be created.
      </p>
    </blockquote>
    <p>You can also use drag and drop to reorder folders in the workspace.</p>
    <h3 id="multiple-selection-native-file-open-dialogs">
      Multiple selection native file open dialogs
    </h3>
    <p>
      Opening multiple folders with your platform&#39;s native file open dialog
      will create a multi-root workspace.
    </p>
    <h3 id="command-line---add">command line --add</h3>
    <p>
      Add a folder or multiple folders to the last active AVAP<sup>TM</sup> Dev
      Studio instance for a multi-root workspace.
    </p>

    <pre className="">
      <code className="">` `code --add vscode vscode-docs</code>
    </pre>
    <h3 id="removing-folders">Removing folders</h3>
    <p>
      You can remove a folder from a Workspace with the{' '}
      <strong>Remove Folder from Workspace</strong> context menu command.
    </p>
    <h2 id="workspace-file">Workspace file</h2>
    <p>
      When you add multiple folders, they are initially placed in a Workspace
      titled <strong>UNTITLED WORKSPACE</strong> and that name will remain until
      you save the workspace. You do not need to save a Workspace until you want
      to have it in a permanent location, for example, on your Desktop. Untitled
      Workspaces are present as long as the AVAP<sup>TM</sup> Dev Studio
      instance they are using is open. Once you completely close an instance
      with an untitled workspace, you will be asked to save it if you plan to
      open it again in the future:
    </p>
    <p>
      When you save your workspace, it will create a .code-workspace file and
      the file name will be displayed in the File Explorer.
    </p>
    <h3 id="save-workspace-as">Save Workspace As...</h3>
    <p>
      If you want to move your Workspace file to a new location, you can use the{' '}
      <strong>File</strong> &gt; <strong>Save Workspace As</strong> command,
      which will automatically set the correct folder paths relative to the new
      Workspace file location.
    </p>
    <h3 id="opening-workspace-files">Opening workspace files</h3>
    <p>To reopen a Workspace, you can:</p>
    <ul>
      <li>
        Double-click the .code-workspace file in your platform&#39;s Explorer.
      </li>
      <li>
        Use the <strong>File</strong> &gt; <strong>Open Workspace</strong>{' '}
        command and select the Workspace file.
      </li>
      <li>
        Select the Workspace from the <strong>File</strong> &gt;{' '}
        <strong>Open Recent</strong> &#40;Ctrl+R&#41; list.{' '}
        <ul>
          <li>
            Workspaces have a <strong>&#40;Workspace&#41;</strong> suffix to
            differentiate them from folders.
          </li>
        </ul>
      </li>
    </ul>
    <p>
      Just like <strong>Close Folder</strong> when a single folder is open in
      AVAP<sup>TM</sup> Dev Studio, there is a <strong>Close Workspace</strong>{' '}
      &#40;Ctrl+K F&#41; command to close the active Workspace.
    </p>
    <h3 id="workspace-file-schema">Workspace file schema</h3>
    <p>
      The schema of <code>.code-workspace</code> is fairly straightforward. You
      have an array of folders with either absolute or relative paths. Relative
      paths are better when you want to share Workspace files.
    </p>
    <p>
      You can override the display <code>name</code> of your folders with the
      name attribute, to give more meaningful names to folders in the Explorer.
      For example, you could name your project folders such as &#39;Product&#39;
      and &#39;Documentation&#39; to easily identify the content by folder name:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"folders": [<br />` `&#123;
        <br />` `// Source code
        <br />` `"name": "Product",
        <br />` `"path": "vscode"
        <br />` `&#125;,
        <br />` `&#123;
        <br />` `// Docs and release notes
        <br />` `"name": "Documentation",
        <br />` `"path": "vscode-docs"
        <br />` `&#125;,
        <br />` `&#123;
        <br />` `// Yeoman extension generator
        <br />` `"name": "Extension generator",
        <br />` `"path": "vscode-generator-code"
        <br />` `&#125;
        <br />` `]
        <br />
        &#125;
      </code>
    </pre>
    <p>which will result in the following Explorer display:</p>
    <p>
      As you can see from the example above, you can add comments to your
      Workspace files.
    </p>
    <p>
      The Workspace file can also contain Workspace global settings under{' '}
      <code>settings</code> and extension recommendations under{' '}
      <code>extensions</code>, which we will discuss below.
    </p>
    <h2 id="general-ui">General UI</h2>
    <h3 id="editor">Editor</h3>
    <p>
      There are only a few changes to the AVAP<sup>TM</sup> Dev Studio UI when
      you are using multi-root workspaces, primarily to disambiguate files
      between folders. For example, if there is a name collision between files
      in multiple folders, AVAP<sup>TM</sup> Dev Studio will include the folder
      name in tabbed headers.
    </p>
    <p>
      If you&#39;d always like to see the folder displayed in the tabbed header,
      you can use the <code>workbench.editor.labelFormat</code> setting
      &quot;medium&quot; or &quot;long&quot; values to show the folder or full
      paths.
    </p>

    <pre className="">
      <code className="">"workbench.editor.labelFormat": "medium"</code>
    </pre>
    <p>
      AVAP<sup>TM</sup> Dev Studio UI such as the <strong>OPEN EDITORS</strong>{' '}
      and <strong>Quick Open</strong> &#40;Ctrl+P&#41; lists include the folder
      name.
    </p>
    <p>
      If you are using an File Icon Theme and the active theme supports it, you
      will see a special Workspace icon.
    </p>
    <p>
      Below you can see the Workspace icons from the built-in{' '}
      <strong>Minimal &#40;Visual Studio Code&#41;</strong> file icon theme:
    </p>
    <h3 id="search">Search</h3>
    <p>
      AVAP<sup>TM</sup> Dev Studio features like global search work across all
      folders and group the search results by folder.
    </p>
    <p>
      When you have a multi-root workspace open, you can choose to search in a
      single root folder by using the ./ syntax in the{' '}
      <strong>files to include</strong> box. For example, if you enter{' '}
      <code>./project1/\*\*/\*.txt</code>, that will search for all{' '}
      <code>.txt</code> files under the <code>project1/</code> root folder.
    </p>
    <h2 id="settings">Settings</h2>
    <p>
      With multiple root folders in one workspace, it is possible to have a
      .vscode folder in each root folder defining the settings that should apply
      for that folder. To avoid setting collisions, only resource &#40;file,
      folder&#41; settings are applied when using a multi-root workspace.
      Settings that affect the entire editor &#40;for example, UI layout&#41;
      are ignored. For example, two projects cannot both set the zoom level.
    </p>
    <p>
      User settings are supported as with single folder projects and you can
      also set global Workspace settings that will apply to all folders in your
      multi-root Workspace. Global Workspace settings will be stored in your
      <code>.code-workspace</code> file.
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"folders": [<br />` `&#123;
        <br />` `"path": "vscode"
        <br />` `&#125;,
        <br />` `&#123;
        <br />` `"path": "vscode-docs"
        <br />` `&#125;,
        <br />` `&#123;
        <br />` `"path": "vscode-generator-code"
        <br />` `&#125;
        <br />` `],
        <br />` `"settings": &#123;
        <br />` `"window.zoomLevel": 1,
        <br />` `"files.autoSave": "afterDelay"
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>
      When you go from a single folder instance to multiple folders, AVAP
      <sup>TM</sup> Dev Studio will add the appropriate editor-wide settings
      from the first folder to the new global Workspace settings.
    </p>
    <p>
      You can easily review and modify the different settings files through the
      Settings editor. The Settings editor tabs let you select your User
      settings, global Workspace settings, and individual folder settings.
    </p>
    <p>You can also open specific settings files with the commands:</p>
    <ul>
      <li>
        <strong>Preferences: Open User Settings</strong> - Open your global User
        settings
      </li>
      <li>
        <strong>Preferences: Open Workspace Settings</strong> - Open the
        settings section of your Workspace file.
      </li>
      <li>
        <strong>Preferences: Open Folder Settings</strong> - Open the settings
        for the active folder.
      </li>
    </ul>
    <p>
      Global Workspace settings override User settings and folder settings can
      override Workspace or User settings.
    </p>
    <h3 id="unsupported-folder-settings">Unsupported folder settings</h3>
    <p>
      Unsupported editor-wide folder settings will be shown as grayed out in
      your folder settings and are filtered out of the{' '}
      <strong>DEFAULT FOLDER SETTINGS</strong> list. You will also see an
      information icon in front of the setting.
    </p>
    <h2 id="debugging">Debugging</h2>
    <p>
      With multi-root workspaces, AVAP<sup>TM</sup> Dev Studio searches across
      all folders for <code>launch.json</code> debug configuration files and
      displays them with the folder name as a suffix. Additionally AVAP
      <sup>TM</sup> Dev Studio will also display launch configurations defined
      in the workspace configuration file.
    </p>
    <p>
      The example above shows the debugging configurations for the TSLint
      extension. There is a <code>launch</code> configuration from the{' '}
      <code>tslint</code> extension folder to start the extension running in the
      AVAP<sup>TM</sup> Dev Studio Extension Host and also an{' '}
      <code>attach</code> configuration from the <code>tslint-server</code>{' '}
      folder to attach the debugger to a running TSLint server.
    </p>
    <p>
      You can also see the three <strong>Add Config</strong> commands for the
      folders, <code>tslint</code>, <code>tslint-server</code>, and{' '}
      <code>tslint-tests</code>, in the vscode-tslint Workspace. The{' '}
      <strong>Add Config</strong> command will either open an existing
      launch.json file in the folder&#39;s .vscode subfolder or create a new one
      and display the debugging configuration template dropdown.
    </p>
    <p>
      Variables used in a configuration &#40;for example{' '}
      <code>$&#123;workspaceFolder&#125;</code> or the now deprecated{' '}
      <code>$&#123;workspaceRoot&#125;</code>&#41; are resolved relative to the
      folder they belong to. It is possible to scope a variable per workspace
      folder by appending the root folder&#39;s name to a variable
      &#40;separated by a colon&#41;.
    </p>
    <h3 id="workspace-launch-configurations">
      Workspace launch configurations
    </h3>
    <p>
      Workspace scoped launch configurations live in the &quot;
      <code>launch</code>&quot; section of the workspace configuration file
      &#40;<strong>Workspaces: Open Workspace Configuration File</strong> in the
      Command Palette&#41;:
    </p>
    <p>
      Alternatively, new launch configurations can be added via the &quot;Add
      Config &#40;workspace&#41;&quot; entry of the Launch Configuration
      dropdown menu:
    </p>
    <p>
      A compound launch configuration can reference the individual launch
      configurations by name as long as the names are unique within the
      workspace, for example:
    </p>

    <pre className="">
      <code className="">
        ` `"compounds": [&#123;
        <br />` `"name": "Launch Server &amp; Client",
        <br />` `"configurations": [<br />` `"Launch Server",
        <br />` `"Launch Client"
        <br />` `]
        <br />` `&#125;]
      </code>
    </pre>
    <p>
      If the individual launch configuration names are not unique, the
      qualifying folder can be specified with a more verbose &quot;folder&quot;
      syntax:
    </p>

    <pre className="">
      <code className="">
        ` `"compounds": [&#123;
        <br />` `"name": "Launch Server &amp; Client",
        <br />` `"configurations": [<br />` `"Launch Server",
        <br />` `&#123;
        <br />` `"folder": "Web Client",
        <br />` `"name": "Launch Client"
        <br />` `&#125;,
        <br />` `&#123;
        <br />` `"folder": "Desktop Client",
        <br />` `"name": "Launch Client"
        <br />` `&#125;
        <br />` `]
        <br />` `&#125;]
      </code>
    </pre>
    <p>
      In addition to <code>compounds</code>, the <code>launch</code> section of
      the workspace configuration file can contain regular launch configurations
      too. Make sure that all used variables are explicitly scoped to a specific
      folder because otherwise they are not valid for the workspace. You can
      find more details about explicitly scoped variables in the Variables
      Reference.
    </p>
    <p>
      Here is an example for a launch configuration where the program lives in a
      folder &quot;Program&quot; and where all files from a folder
      &quot;Library&quot; should be skipped when stepping:
    </p>

    <pre className="">
      <code className="">
        "launch": &#123;
        <br />` `"configurations": [&#123;
        <br />` `"type": "node",
        <br />` `"request": "launch",
        <br />` `"name": "Launch test",
        <br />` `"program": "$&#123;workspaceFolder:Program&#125;/test.js",
        <br />` `"skipFiles": [<br />`
        `"$&#123;workspaceFolder:Library&#125;/out/\*\*/\*.js"
        <br />` `]
        <br />` `&#125;]
        <br />
        &#125;
      </code>
    </pre>
    <h2 id="tasks">Tasks</h2>
    <p>
      Similar to how AVAP<sup>TM</sup> Dev Studio searches for debugging
      configurations, AVAP<sup>TM</sup> Dev Studio will also try to autodetect
      tasks from gulp, grunt, npm, and TypeScript project files across all
      folders in a workspace as well as search for tasks defined in{' '}
      <code>tasks.json</code> files. The location of tasks is indicated by a
      folder name suffix. Note that tasks defined in <code>tasks.json</code>{' '}
      must be version 2.0.0.
    </p>
    <p>
      From the TSLint extension Workspace example above, you can see that there
      are two <strong>configured tasks</strong> from <code>tasks.json</code>{' '}
      files in the <code>tslint</code> and <code>tslint-tests</code> folders and
      numerous autodetected npm and TypeScript compiler{' '}
      <strong>detected tasks</strong>.
    </p>
    <h3 id="workspace-task-configuration">Workspace task configuration</h3>
    <p>
      Workspace scoped tasks live in the &quot;<code>tasks</code>&quot; section
      of the workspace configuration file &#40;
      <strong>Workspaces: Open Workspace Configuration File</strong> in the
      Command Palette&#41;. Only &quot;<code>shell</code>&quot; and &quot;
      <code>process</code>&quot; type tasks can be defined in the workspace
      configuration file.
    </p>
    <h2 id="source-control">Source Control</h2>
    <p>
      With multi-root workspaces, there is a{' '}
      <strong>SOURCE CONTROL PROVIDERS</strong> section that gives you an
      overview when you have multiple active repositories. These can be
      contributed by several SCM providers; for example, you can have Git
      repositories side-by-side with Azure DevOps Server workspaces. As you
      select repositories in this view, you can see the source control details
      below.
    </p>
    <p>
      You can use Ctrl+Click or Shift+Click to select multiple repositories.
      Their details will appear as separate regions underneath.
    </p>
    <h2 id="extensions">Extensions</h2>
    <p>
      If you are an extension author, you can review our Adopting Multi Root
      Workspace APIs guide to learn about AVAP<sup>TM</sup> Dev Studio
      multi-root workspace APIs and how to make your extension work well across
      multiple folders.
    </p>
    <p>
      Below are some of the popular extensions that have adopted the multi-root
      workspace APIs.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: If an extension doesn&#39;t yet support multiple
        folders, it will still work in the first folder of your multi-root
        workspace.
      </p>
    </blockquote>
    <h3 id="extension-recommendations">Extension recommendations</h3>
    <p>
      AVAP<sup>TM</sup> Dev Studio supports folder level extension
      recommendations through the <code>extensions.json</code> files under the
      folder&#39;s <code>.vscode</code> subfolder. You can also provide global
      Workspace extension recommendations by adding them to your{' '}
      <code>.code-workspace</code> file. You can use the{' '}
      <strong>
        Extensions: Configure Recommended Extensions &#40;Workspace Folder&#41;
      </strong>{' '}
      command to open your Workspace file and add extension identifiers
      &#40;&#123;publisherName&#125;.&#123;extensionName&#125;&#41; to the{' '}
      <code>extensions.recommendations</code> array.
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"folders": [<br />` `&#123;
        <br />` `"path": "vscode"
        <br />` `&#125;,
        <br />` `&#123;
        <br />` `"path": "vscode-docs"
        <br />` `&#125;
        <br />` `],
        <br />` `"extensions": &#123;
        <br />` `"recommendations": ["eg2.tslint", "dbaeumer.vscode-eslint",
        "esbenp.prettier-vscode"]
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <h2 id="next-steps">Next steps</h2>
    <ul>
      <li>
        What is a AVAP<sup>TM</sup> Dev Studio &quot;workspace&quot;? - More
        about single-folder and multi-root workspaces.
      </li>
      <li>Debugging - Learn how to set up debugging for your application.</li>
      <li>
        Tasks - Tasks let you run external tools like compilers within AVAP
        <sup>TM</sup> Dev Studio.
      </li>
    </ul>
    <h2 id="common-questions">Common questions</h2>
    <h3 id="how-can-i-go-back-to-working-with-a-single-project-folder">
      How can I go back to working with a single project folder?
    </h3>
    <p>
      You can either close the Workspace and open the folder directly or remove
      the folder from Workspace.
    </p>
    <h3 id="as-an-extension-author-what-do-i-need-to-do">
      As an extension author what do I need to do?
    </h3>
    <p>
      See our Adopting Multi Root Workspace APIs guide. Most extensions can
      easily support multi-root workspaces.
    </p>
  </Fragment>
);
export default USER_GUIDE_MultiRootWorkspaces;
