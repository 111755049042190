import React, { Fragment } from 'react';

const How_To_Work_With_Point_Redemption = () => (
  <Fragment>
    <h1>
      How to Work with Point Redemption
    </h1>
    <p>
      In your project, you can work with loyalty wallets to which you can assign
      a FIAT currency, a non-FIAT currency or a new currency created by you, to
      which you give a purchase and sale values. This is referred to as a
      personalized wallet by default.
    </p>
    <p>
      As a concept, a personalized or loyalty wallet accumulates balance or
      points that can later be exchanged for products, but that in no case can
      be transformed into liquid money regardless of whether it is the result of
      an ATM withdrawal, bank account, purchase of prepaid cards, or any other
      concept that facilitates cashing out from a loyalty wallet.
    </p>
    <p>
      Transfers between users are not allowed unless the destination or wallet
      of the beneficiary of the transfer is a wallet of the same type with the
      same limitations and the configuration of the wallet allows such
      transfers.
    </p>
    <p>
      Personalized or loyalty wallets rely on the currency table of the FX
      Exchange service to carry out the operations of buying and selling
      products from loyalty wallets, thus allowing customers to buy any type of
      product, if they meet the conditions to be acquired by a source of
      loyalty-type funds regardless of the type of currency held by the product
      to be purchased.
    </p>
    <p>
      In this way, a customer with a loyalty wallet in USD can purchase products
      published in EUR or MXN without any problem. Just like a customer with a
      loyalty wallet in My_Coin, can purchase the same products mentioned above.
      It is in the configuration of the FX Exchange currency table where the
      purchase and sale price of My_Coin is determined, with USD serving as the
      reference.
    </p>
  </Fragment>
);
export default How_To_Work_With_Point_Redemption;
