import React, { FunctionComponent, useEffect, useState, Fragment } from 'react';
import Logo from '../../../assets/img/logodeveloper-pequeño.png';
import faUserCircle2 from '../../../assets/img/icono.png';
import { Row, Col, Dropdown, Form, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTerminal, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from 'react-router-dom';
import SessionExpired from '../../Modals/sessionExpired';
import InactivityModal from '../../Modals/inactivityModal';
import Cookies from 'universal-cookie';
import SessionService from '../../../services/SessionsService';
import ReactGA from 'react-ga';
import AddOrganizationModal from '../../Modals/addOrganizationModal';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import "./style.css"

import logo2 from "../../../assets/img/newAvapCloud/logo_Cloud@2x.png";
import logo1 from "../../../assets/img/AVAP_DeveloperPortal2.png"
import AddOrganizationSlots from '../../Modals/addOrganizationSlots';
import config from '../../../configs/appConfig.json'
interface HeaderProps {
  isDashboardBlue?: any; // Aquí especificas que 'sider' puede ser de cualquier tipo
}

const Header: FunctionComponent<HeaderProps> = ({ isDashboardBlue = false }) => {

  const history = useHistory();
  const [isLoggedIn, setLogged] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [inactivity, setInactivity] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [nombreOrganizacion, setNameOrg] = useState('');
  const [currentOrganizations, setCurrentOrganizations] = useState(0);
  const [maxOrganizations, setMaxOrganizations] = useState(0);
  const cookies = new Cookies();

  const [usersuscriptor, setusersuscriptor] = useState(true);
  const [developer, setDeveloper] = useState(false);
  const [client, setClient] = useState(false);

  const [addingOrg, setaddingOrg] = useState(false)


  const handleOrg = () => {
   
    console.log('RECUPERANDO PROYECTOS');
    SessionService.ORGANIZATIONS = null;
    SessionService.dispatchOrganizationChangedEvent();
    //await getProjects();
    setaddingOrg(!addingOrg);

  }


  const analyticsTags = [
    'G-MQZQJN7TQL',
    'G-98LF8608ZM',
    'G-KKCW4SZLW4',
    'G-EL6DCZWCM7',
    'G-5LKNTQFGD8',
    'G-11ZHMK4Y4Z',
    'G-PDYER55NCM'
  ];
  //ReactGA.initialize('UA-170480071-2');
  //analyticsTags.forEach(tag => {
  //  ReactGA.initialize(tag);
  //});
  
  const handleLogout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    SessionService.logout();
    history.push('/cloud_login');

  }

  const getProjects = async () => {
    try {
      console.log("PROG")
      setProjects([]);
      console.log("PROG1")
      let projects = null;
      console.log("PROG2")
      if (SessionService.ORGANIZATIONS == null) {
        console.log('HACIENDO LLAMADA')
        projects = (await ObexRequestHandler.get('/organizations', {}, true)).data || [];
        SessionService.ORGANIZATIONS = projects
      }
      else {
        projects = SessionService.ORGANIZATIONS;
      }
      if (projects.length == 0) {
        setNameOrg('My Company');
        SessionService.ORGANIZATION_NAME = 'My Company';
        SessionService.ORGANIZATION = -1;
      } else {
        if (projects.result.length == 0) {
          setNameOrg('My Company');
          SessionService.ORGANIZATION_NAME = 'My Company';
          SessionService.ORGANIZATION = -1;
        }
      }
 
      setCurrentOrganizations(projects['result'].length);
      setMaxOrganizations(projects['max']);

      setProjects(projects['result']);

      if (projects['result'].length > 0) {
        if (SessionService.ORGANIZATION_NAME == null) {
          setNameOrg(projects['result'][0]['name']);
          SessionService.ORGANIZATION_NAME = projects['result'][0]['name'];
          SessionService.ORGANIZATION = projects['result'][0]['id'];
        } else {
          setNameOrg(SessionService.ORGANIZATION_NAME);
        }
      } else {
        SessionService.ORGANIZATION_NAME = 'Sin Organización';
        SessionService.ORGANIZATION = -1;
      }

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);


    }

  }


  useEffect(() => {
    if (config.url != 'https://avs-primary-pre.101obex.mooo.com') ReactGA.pageview(window.location.pathname + window.location.search);

    const user = cookies.get('101Obex');
    if (user) {
      //console.log('User data')
      //console.log(user);
      setDeveloper(SessionService.Developer);
      setusersuscriptor(SessionService.User);
      setClient(SessionService.Client);
      if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
        SessionService.renewSession();
      }
      setLogged(true);
      getProjects();
      //console.log("header", isDashboardBlue);

    }
  }, [])

  // Como este componente siempre está presente ponemos aquí el listener del inactivityEvent para que lancé el modal
  document.addEventListener('inactivityEvent', (e) => {

    e.preventDefault();
    e.stopPropagation();
    setInactivity(true);
  }, false);

  document.addEventListener('organizationsChange', (e) => {

    e.preventDefault();
    e.stopPropagation();
    getProjects();

  }, false);

  //organizationsChange

  // Como este componente siempre está presente ponemos aquí el listener del sessionExpiredEvent para que lancé el modal
  document.addEventListener('sessionExpiredEvent', (e) => {

    if (sessionExpired) return;
    e.preventDefault();
    e.stopPropagation();
    setSessionExpired(true);
  }, false);

  const setName = (orga) => {

    setNameOrg(orga.name);
    SessionService.ORGANIZATION_NAME = orga.name;
    SessionService.ORGANIZATION = orga.id;
    history.push('/cloud_dashboard');
    return orga.name;
  }



  const renewSession = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      await SessionService.renewSession();
      setInactivity(false);
    } catch (error) {
      console.error(error);
    }
  }
  const handleModal = () => {

    setShowModal(!showModal);
    SessionService.ORGANIZATIONS = null;
    SessionService.dispatchOrganizationChangedEvent();
  };

  const on_Change = (event) => {
    const value = event.target.value;

    // this will return C:\fakepath\somefile.ext
    console.log(value);

    const files = event.target.files;

    //this will return an ARRAY of File object
    console.log(files);


    const input = event.target;

    const reader = new FileReader();
    reader.onload = function () {
      const dataURL = reader.result;
      const output = document.getElementById('output') as HTMLImageElement;
      //document.getElementById('output').setAttribute( 'src',dataURL);
      output.src = dataURL.toString();
      console.log(dataURL)
    };
    reader.readAsDataURL(input.files[files.length - 1]);

    //setImage(files[(files.length)-1].name);

    //setNoUpload(false);

  }

  return (
    <Fragment>
      <SessionExpired show={sessionExpired} handleHide={handleLogout} />
      <InactivityModal show={inactivity && !sessionExpired} renewSession={renewSession} closeSession={handleLogout} />
      {!isDashboardBlue && (
        <>
          <Row style={{ justifyContent: 'center', marginLeft:'0px' }} className={'py-0 fixed-top px-2 position-fixed'} id="header">

            <Row style={{ width: '100%' }}>

              {/* IM REMOVE THIS */}
              {/* <Col md={3} className={'pl-3 my-auto'}>
    <a href="/dashboard">
      <img className="logo" src={Logo}/>
    </a>
</Col> */}
              <div className={' my-auto'} style={{ width: "237px", display:'flex', justifyContent:'center' }}>
                <a href="/default">
                  <img className="logo" src={logo1} height={"30px"} style={{marginLeft:'8px'}}/>
                </a>
              </div>

              <Col className="my-auto">
                <Row className="menu-header justify-content-end align-items-center">
                  <Col sm={1}><a className="link link-header fonsize14" onClick={() => history.push('/docs')} style={{ textAlign: 'center' }}>Docs</a></Col>
                  <Col sm={1}><a className="link link-header fonsize14" onClick={() => history.push('/wiki')} style={{ textAlign: 'center' }}>Wiki</a></Col>
                  <Col sm={1}><a className="link link-header fonsize14" onClick={() => history.push('/apis')} style={{ textAlign: 'center' }}>APIs</a></Col>


                  {/*    <Col><a className="link link-header" onClick={() => history.push('/downloads')}style={{textAlign: "center"}}>Downloads & GitHub</a></Col>  */}


                  {/* IM REMOVE THIS */}
                  {/* <Col><a target="_blank" rel="noopener noreferrer" href="https://status.101obex.com/" className="link link-header" style={{ textAlign: 'center' }}>Status</a></Col> */}

                  {isLoggedIn ?
                    //     <Dropdown>
                    //     <Dropdown.Toggle className="button-support" >Community Support</Dropdown.Toggle>
                    //     <Dropdown.Menu>
                    //       <Dropdown.Item onClick={() => (history.push('https://101obexworkspace.slack.com/'))}>Technical Support</Dropdown.Item>
                    //
                    //     </Dropdown.Menu>{' '}
                    //   </Dropdown>
                    <Col sm={1}><a target="_blank" rel="noopener noreferrer" href="https://101obexworkspace.slack.com/" className="link link-header fonsize14" style={{ textAlign: 'center' }}>Support</a></Col>
                    :
                    <Col sm={1}> <a target="_blank" rel="noopener noreferrer" href="https://101obexworkspace.slack.com/" className="link link-header fonsize14" style={{ textAlign: 'center' }}>Support</a></Col>

                  }

                  <Col sm={2}><a className="link link-header fonsize14" onClick={() => history.push('/login')} style={{ textAlign: 'center' }}>
                    <div>
                      Sign In <span className='fonsize12'>→</span>
                    </div>
                  </a></Col>
                </Row>
              </Col>



              {isLoggedIn ?
              <span style={{paddingRight:'20px', paddingTop:'10px', cursor:'pointer'}}onClick={()=>{SessionService.eventEmitter.emit('panel', 'Bob');}}><FontAwesomeIcon icon={faTerminal}/></span>
                // <Col md={1} className="user text-right my-auto">
                //   <Dropdown className="mr-1">
                //     <Dropdown.Toggle className="link-user" id="obex-user-menu">{/*<FontAwesomeIcon icon={faUserCircle} size="2x"/>*/}<div style={{ height:'30px' }}><Image id='AvatarImageHeader' style={{ height:'30px' }} src={SessionService.imageContent}/></div></Dropdown.Toggle>
                //     <Dropdown.Menu>
                //       { usersuscriptor && <Dropdown.Item onClick={() => {
                //         history.push('/dashboard')}}><h5>Administrator Dashboard</h5></Dropdown.Item>}
                //       { developer && <Dropdown.Item onClick={() => {
                //         SessionService.ORGANIZATION_NAME = null;
                //         history.push('/devdashboard')}}>Developer Dashboard</Dropdown.Item>}
                //       { client && <Dropdown.Item onClick={() => {
                //         SessionService.ORGANIZATION_NAME = null;
                //         history.push('/clientdashboard')}}>Client Dashboard</Dropdown.Item>}
                //       <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                //     </Dropdown.Menu>{' '}
                //   </Dropdown>
                // </Col>
                
                :
                // <Col md={2} className="login text-right my-auto">
                //   <a style={{ height:'47px', width:'200px', marginRight:'-20px', paddingTop:'12px', fontSize:'16px' }} href="/login" className="link-login">Log into Developer Portal</a>
                // </Col>
                <></>
              }


              {/* <Col md={1}>
  <SelectLanguage/>
</Col>*/}


            </Row>
          </Row>
        </>
      )}

      {isDashboardBlue  && (
        <>
          <Row style={{ justifyContent: 'center', marginLeft:'0px' }} className={'py-0 fixed-top px-2'} id="header">

            <Row style={{ width: '100%' }}>

              {/* IM REMOVE THIS */}
              <div className={'pl-3 my-auto'} style={{ width: "320px" }}>
                <a href="/dashboard">
                  <img className="logo sizeAvapCloud" src={logo2} height={"30px"} />
                </a>
              </div>

              <div className="my-auto col mx-0 px-0">
                <div className="d-flex justify-content-between align-items-center">
                  <div className='d-flex align-items-center'>
                    {/*}
                  <div className="pr-5"><a className="link link-header fonsize14 px-0 mx-0" onClick={() => history.push('/docs/download/download_101cli')} style={{ textAlign: 'center', color: "#000" }}>Docs</a></div>
                  <div className="pr-5"><a className="link link-header fonsize14 px-0 mx-0" onClick={() => history.push('/wiki')} style={{ textAlign: 'center', color: "#000" }}>Wiki</a></div>
                  <div className="pr-5"><a className="link link-header fonsize14 px-0 mx-0" onClick={() => history.push('/apis/login/Accesos_EN')} style={{ textAlign: 'center', color: "#000" }}>APIs</a></div>
*/}

                  {/*    <Col><a className="link link-header" onClick={() => history.push('/downloads')}style={{textAlign: "center"}}>Downloads & GitHub</a></Col>  */}


                  {/* IM REMOVE THIS */}

                  
               {/*}   <div className="pr-5"><a target="_blank" rel="noopener noreferrer" href="https://status.101obex.com/" className="link link-header px-0 mx-0" style={{ color: "#000" }}>Status</a></div> */}


{/*}
                  {isLoggedIn ?
                    //     <Dropdown>
                    //     <Dropdown.Toggle className="button-support" >Community Support</Dropdown.Toggle>
                    //     <Dropdown.Menu>
                    //       <Dropdown.Item onClick={() => (history.push('https://101obexworkspace.slack.com/'))}>Technical Support</Dropdown.Item>
                    //
                    //     </Dropdown.Menu>{' '}
                    //   </Dropdown>
                    <div className="pr-5"><a target="_blank" rel="noopener noreferrer" href="https://101obexworkspace.slack.com/" className="link link-header fonsize14 px-0 mx-0" style={{ color: "#000" }}>Support</a></div>
                    :
                    <div className="pr-5"> <a target="_blank" rel="noopener noreferrer" href="https://101obexworkspace.slack.com/" className="link link-header fonsize14 px-0 mx-0" style={{ color: "#000" }}>Support</a></div>

                  }
                    */}
                  </div>

                  <div className='d-flex align-items-center'>
                    <div>
                      {isLoggedIn ?
                        <Dropdown className='my-auto pr-4'>
                          <Dropdown.Toggle className="organizations pl-0 ml-0 fonsize14 " id='fonto'>{nombreOrganizacion}</Dropdown.Toggle>
                          <Dropdown.Menu className='fonsize14'>

                            {projects.map(x => {

                              return (<Dropdown.Item key={x.id} onClick={() => { setName(x) }} className="fonsize14">{x.name}</Dropdown.Item>);

                            })}


                            {currentOrganizations < maxOrganizations ?
                              <Dropdown.Item onClick={() => { setShowModal(true) }}>Create Organization</Dropdown.Item>
                              :
                              <Dropdown.Item onClick={() => { setaddingOrg(true) }}>Create Organization</Dropdown.Item>

                            }

                          </Dropdown.Menu>{' '}
                        </Dropdown>

                        :
                        <span></span>

                      }


                    </div>

                    <div>

{/*}
                      {isLoggedIn ? (
                        <>
                          <div className='pr-4'><a className="link link-header fonsize14" onClick={() => history.push('/login')} style={{ textAlign: 'center', color: "#000" }}>
                            <div onClick={handleLogout}>
                              Sign out
                            </div>
                          </a></div>
                        </>
                      ) :
                        <>
                          <div ><a className="link link-header fonsize14" onClick={() => history.push('/login')} style={{ textAlign: 'center' }}>
                            <div>
                              Sign In <span className='fonsize12'>→</span>
                            </div>
                          </a></div>
                        </>
                      }

*/}



                    </div>

                    <div>
                      {isLoggedIn ?
                        <>
                          <div  className="user text-right my-auto pr-5">
                            <Dropdown className="mr-1">
                              <Dropdown.Toggle className="link-user" id="obex-user-menu"><div style={{ height: '30px', width:"30px", borderRadius:'50% !important' }}><Image id='AvatarImageHeader' style={{ height: '30px', borderRadius:'50%' }} src={SessionService.imageContent} /></div></Dropdown.Toggle>
                              {/*}
                              <Dropdown.Menu>
                                {usersuscriptor && <Dropdown.Item onClick={() => {
                                  history.push('/cloud_dashboard')
                                }}><h5>Administrator Dashboard</h5></Dropdown.Item>}
                                {developer && <Dropdown.Item onClick={() => {
                                  SessionService.ORGANIZATION_NAME = null;
                                  history.push('/devdashboard')
                                }}>Developer Dashboard</Dropdown.Item>}
                                {client && <Dropdown.Item onClick={() => {
                                  SessionService.ORGANIZATION_NAME = null;
                                  history.push('/clientdashboard')
                                }}>Client Dashboard</Dropdown.Item>}
                                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                              </Dropdown.Menu>{' '}
                              */}
                            </Dropdown>
                          </div>
                        </>
                        :
                        <>
                          <div  className="login text-right my-auto">
                            <a style={{ height: '47px', width: '200px', marginRight: '-20px', paddingTop: '12px', fontSize: '16px' }} href="/login" className="link-login">Log into Developer Portal</a>
                          </div>
                        </>
                      }

                    </div>

                  </div>






                </div>
              </div>





              {/* <Col md={1}>
  <SelectLanguage/>
</Col>*/}


            </Row>
          </Row>
        </>
      )}

{(addingOrg) && <AddOrganizationSlots shown={addingOrg} onCloseAction={handleOrg} onSuccess={handleOrg} MaxDBSize={1} ActualDBSize={0}/>}

      {showModal && <AddOrganizationModal show={showModal} handleClose={handleModal} />}


    </Fragment>
  );
}

export default Header;