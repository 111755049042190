import React, { Fragment } from 'react';

const SOURCE_CONTROL_Overview = () => (
  <Fragment>
    <h1 id="using-git-source-control-in-avap™-dev-studio-2024">
      Using Git source control in AVAP™ Dev Studio 2024
    </h1>
    <p>
      AVAP<sup>TM</sup> Dev Studio has integrated source control management
      &#40;SCM&#41; and includes Git support out-of-the-box. Many other source
      control providers are available through extensions on the AVAP
      <sup>TM</sup> Dev Studio Marketplace.
    </p>
    <h2 id="working-in-a-git-repository">Working in a Git repository</h2>
    <blockquote>
      <p>
        <strong>Just getting started with Git?</strong> The git-scm website is a
        good place to start, with a popular online book, Getting Started videos
        and cheat sheets. The AVAP<sup>TM</sup> Dev Studio documentation assumes
        you are already familiar with Git.
      </p>
    </blockquote>
    <blockquote>
      <p>
        <strong>Make sure Git is installed.</strong> AVAP<sup>TM</sup> Dev
        Studio will use your machine&#39;s Git installation &#40;at least
        version 2.0.0&#41;, so you need to install Git first before you get
        these features.
      </p>
    </blockquote>
    <p>
      The Source Control icon in the Activity Bar on the left will always
      indicate an <strong>overview of how many changes</strong> you currently
      have in your repository. Selecting the icon will show you the details of
      your current repository changes: <strong>CHANGES</strong>,{' '}
      <strong>STAGED CHANGES</strong> and <strong>MERGE CHANGES</strong>.
    </p>
    <p>
      Clicking each item will show you in detail{' '}
      <strong>the textual changes within each file</strong>. Note that for
      unstaged changes, the editor on the right still lets you edit the file:
      feel free to use it!
    </p>
    <p>
      You can also find indicators of the{' '}
      <strong>status of your repository</strong> in the bottom-left corner of
      AVAP<sup>TM</sup> Dev Studio: the <strong>current branch</strong>,{' '}
      <strong>dirty indicators</strong>, and the number of{' '}
      <strong>incoming and outgoing commits</strong> of the current branch. You
      can <strong>checkout</strong> any branch in your repository by clicking
      that status indicator and selecting the Git reference from the list.
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> You can open AVAP<sup>TM</sup> Dev Studio in a
        sub-directory of a Git repository. AVAP<sup>TM</sup> Dev Studio&#39;s
        Git services will still work as usual, showing all changes within the
        repository, but file changes outside of the scoped directory are shaded
        with a tool tip indicating they are located outside the current
        workspace.
      </p>
    </blockquote>
    <h2 id="commit">Commit</h2>
    <p>
      <strong>Staging</strong> &#40;git add&#41; and <strong>unstaging</strong>{' '}
      &#40;git reset&#41; can be done via contextual actions in the files or by
      drag-and-drop.
    </p>
    <p>
      <strong>Configure your Git username and email.</strong> When you commit,
      be aware that if your username and/or email is not set in your Git
      configuration, Git will fall back to using information from your local
      machine. You can find the details in Git commit information.
    </p>
    <p>
      You can type a commit message above the changes and press{' '}
      <code>Ctrl+Enter</code> &#40;macOS: âŒ˜<code>+Enter</code>&#41; to commit
      them. If there are any staged changes, only those changes will be
      committed. Otherwise, you&#39;ll get a prompt asking you to select what
      changes you&#39;d like to commit and get the option to change your commit
      settings.
    </p>
    <p>
      We&#39;ve found this to be a great workflow. For example, in the earlier
      screenshot, only the staged changes to <code>overview.png</code> will be
      included in the commit. Later staging and commit actions could include the
      changes to <code>versioncontrol.md</code> and the two other{' '}
      <code>.png</code> images as a separate commit.
    </p>
    <p>
      More specific <strong>Commit</strong> actions can be found in the{' '}
      <strong>Views and More Actions</strong> ... menu on the top of the Source
      Control view.
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> If you commit your change to the wrong branch,
        undo your commit using the <strong>Git: Undo Last Commit</strong>{' '}
        command in the <strong>Command Palette</strong> &#40;
        <code>Ctrl+Shift+P</code>&#41;.
      </p>
    </blockquote>
    <h2 id="cloning-a-repository">Cloning a repository</h2>
    <p>
      If you haven&#39;t opened a folder yet, the Source Control view will give
      you the options to <strong>Open Folder</strong> from your local machine or{' '}
      <strong>Clone Repository</strong>.
    </p>
    <p>
      If you select <strong>Clone Repository</strong>, you will be asked for the
      URL of the remote repository &#40;for example on GitHub&#41; and the
      parent directory under which to put the local repository.
    </p>
    <p>
      For a GitHub repository, you would find the URL from the GitHub{' '}
      <strong>Code</strong> dialog.
    </p>
    <p>
      You would then paste that URL into the <strong>Git: Clone</strong> prompt.
    </p>
    <p>
      You&#39;ll also see the option to <strong>Clone from GitHub</strong>. Once
      you authenticate with your GitHub account in AVAP<sup>TM</sup> Dev
      Studioe, you&#39;ll be able to search through repositories by name, and
      select any repo to clone it. You can also start the flow to clone a Git
      repository with the <strong>Git: Clone</strong> command in the{' '}
      <strong>Command Palette</strong> &#40;Ctrl+Shift+P&#41;. To see a
      step-by-step walkthrough, check out our Clone repos from AVAP<sup>TM</sup>{' '}
      Dev Studio video.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: If you&#39;d like to work on a repository without
        cloning the contents to your local machine, you can install the GitHub
        Repositories extension to browse and edit directly on GitHub. You can
        learn more in the GitHub Repositories extension section.
      </p>
    </blockquote>
    <h2 id="branches-and-tags">Branches and Tags</h2>
    <p>
      You can create and checkout branches directly within AVAP<sup>TM</sup> Dev
      Studio through the <strong>Git: Create Branch</strong> and{' '}
      <strong>Git: Checkout to</strong> commands in the{' '}
      <strong>Command Palette</strong> &#40;<code>Ctrl+Shift+P</code>&#41;.
    </p>
    <p>
      If you run <strong>Git: Checkout to</strong>, you will see a dropdown list
      containing all of the branches or tags in the current repository. It will
      also give you the option to create a new branch if you decide that&#39;s a
      better option, or checkout a branch in detached mode.
    </p>
    <p>
      The <strong>Git: Create Branch</strong> command lets you quickly create a
      new branch. Just provide the name of your new branch and AVAP<sup>TM</sup>{' '}
      Dev Studio will create the branch and switch to it. If you choose to{' '}
      <strong>Create new branch from...</strong>, you&#39;ll get an extra prompt
      that allows you to specify which commit the new branch should be pointing
      to.
    </p>
    <h2 id="remotes">Remotes</h2>
    <p>
      Given that your repository is connected to some remote and that your
      checked out branch has an upstream link to a branch in that remote, AVAP
      <sup>TM</sup> Dev Studio offers you useful actions to{' '}
      <strong>push</strong>, <strong>pull</strong>, and <strong>sync</strong>{' '}
      that branch &#40;the latter will run a <strong>pull</strong> command
      followed by a <strong>push</strong> command&#41;. You can find these
      actions in the <strong>Views and More Actions</strong> ... menu, along
      with the option to <strong>add or remove a remote</strong>.
    </p>
    <p>
      AVAP<sup>TM</sup> Dev Studio is able to periodically fetch changes from
      your remotes. This enables AVAP<sup>TM</sup> Dev Studio to show how many
      changes your local repository is ahead or behind the remote. This feature
      is disabled by default and you can use the git.autofetch
      setting&#40;/docs/getstarted/settings&#41; to enable it.
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> You should set up a credential helper to avoid
        getting asked for credentials every time AVAP<sup>TM</sup> Dev Studio
        talks to your Git remotes. If you don&#39;t do this, you may want to
        consider disabling automatic fetching via the git.autofetch setting to
        reduce the number of prompts you get.
      </p>
    </blockquote>
    <h2 id="git-status-bar-actions">Git Status Bar actions</h2>
    <p>
      There is a <strong>Synchronize Changes</strong> action in the Status Bar,
      next to the branch indicator, when the current checked out branch has an
      upstream branch configured. <strong>Synchronize Changes</strong> will pull
      remote changes down to your local repository and then push local commits
      to the upstream branch.
    </p>
    <p>
      If there is no upstream branch configured and the Git repository has
      remotes set up, the <strong>Publish</strong> action is enabled. This will
      let you publish the current branch to a remote.
    </p>
    <h2 id="gutter-indicators">Gutter indicators</h2>
    <p>
      If you open a folder that is a Git repository and begin making changes,
      AVAP<sup>TM</sup> Dev Studio will add useful annotations to the gutter and
      to the overview ruler.
    </p>
    <ul>
      <li>A red triangle indicates where lines have been deleted</li>
      <li>A green bar indicates new added lines</li>
      <li>A blue bar indicates modified lines</li>
    </ul>
    <h2 id="merge-conflicts">Merge conflicts</h2>
    <p>
      Merge conflicts are recognized by AVAP<sup>TM</sup> Dev Studio.
      Differences are highlighted and there are inline actions to accept either
      one or both changes. Once the conflicts are resolved, stage the
      conflicting file so you can commit those changes.
    </p>
    <h2 id="3-way-merge-editor">3-way merge editor</h2>
    <p>
      To help you resolve merge conflicts, AVAP<sup>TM</sup> Dev Studio provides
      a 3-way merge editor where you can interactively accept incoming and
      current changes and view and edit the resulting merged file. The 3-way
      merge editor is opened by selecting the{' '}
      <strong>Resolve in Merge Editor</strong> button in the bottom right corner
      of a file with Git merge conflicts.
    </p>
    <p>
      The 3-way merge editor displays separate views for{' '}
      <strong>Incoming</strong> changes &#40;on the left&#41;,{' '}
      <strong>Current</strong> changes &#40;on the right&#41;, and the{' '}
      <strong>Result</strong> of the merge &#40;at the bottom&#41;. Conflicts
      are highlighted and can be resolved by using the CodeLens buttons.
    </p>
    <h3 id="resolving-conflicts">Resolving conflicts</h3>
    <p>
      The 3-way merge editor allows you to resolve conflicts by accepting either
      one or both changes. You can also manually edit the result of the merge.
    </p>
    <p>
      For some conflicts, the merge editor shows an{' '}
      <strong>Accept Combination</strong> button. Accepting the combination
      resolves the current conflict by smartly merging both changes. This is
      especially useful for changes in the same line that don&#39;t touch the
      same characters.
    </p>
    <p>
      Use the <strong>Ignore</strong> buttons to accept neither the incoming nor
      current change, but mark the conflict as resolved. This resets the
      conflicting area to the state before any changes were made.
    </p>
    <h3 id="completing-the-merge">Completing the merge</h3>
    <p>
      You can use the conflict counter in the right of the result editor to keep
      track of how many unresolved conflicts are remaining. Clicking on the
      counter jumps to the next unresolved conflict. Once all conflicts are
      resolved, you can complete the merge by selecting{' '}
      <strong>Complete Merge</strong> in the bottom right corner. This stages
      the file and closes the merge editor.
    </p>
    <h3 id="alternative-layouts-and-more">Alternative layouts and more</h3>
    <p>
      Selecting the three dots &#40;<strong>···</strong>&#41; in the top right
      corner of the merge editor opens a context menu with additional options.
      You can switch to a vertical layout and display the base view, which shows
      the state of the file before any changes were made.
    </p>
    <p>
      The three dots next to <strong>Incoming</strong>, <strong>Current</strong>
      , and <strong>Result</strong> offer options for each view, such as showing
      a side-by-side diff against base, accepting all changes, or resetting the
      result.
    </p>
    <h3 id="understanding-conflicts">Understanding conflicts</h3>
    <p>
      If you want to learn more about the details of how 3-way merge editors
      work, we can recommend the following video:
    </p>
    <h2 id="viewing-diffs">Viewing diffs</h2>
    <p>
      Our Git tooling supports viewing of diffs within AVAP<sup>TM</sup> Dev
      Studio.
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> You can diff any two files by first right clicking
        on a file in the Explorer or <strong>OPEN EDITORS</strong> list and
        selecting <strong>Select for Compare</strong> and then right-click on
        the second file to compare with and select{' '}
        <strong>Compare with &#39;file_name_you_chose&#39;</strong>.
        Alternatively from the keyboard hit Ctrl+Shift+P and select{' '}
        <strong>File: Compare Active File With</strong> and you will be
        presented with a list of recent files.
      </p>
    </blockquote>
    <h3 id="accessible-diff-viewer">Accessible Diff Viewer</h3>
    <p>
      There is an Accessible Diff Viewer in the Diff editor that presents
      changes in a unified patch format. You can navigate between changes with{' '}
      <strong>Go to Next Difference</strong> &#40;<code>F7</code>&#41; and{' '}
      <strong>Go to Previous Difference</strong> &#40;<code>Shift+F7</code>
      &#41;. Lines can be navigated with arrow keys and pressing{' '}
      <code>Enter</code> will jump back in the Diff editor and the selected
      line.
    </p>
    <blockquote>
      <p>
        <strong>Note:</strong> This experience is especially helpful for screen
        reader users.
      </p>
    </blockquote>
    <h2 id="timeline-view">Timeline view</h2>
    <p>
      The Timeline view, accessible at the bottom of the File Explorer by
      default, is a unified view for visualizing time-series events &#40;for
      example, Git commits&#41; for a file.
    </p>
    <p>
      AVAP<sup>TM</sup> Dev Studio&#39;s built-in Git support provides the Git
      commit history of the specified file. Selecting a commit will open a diff
      view of the changes introduced by that commit. When you right-click on a
      commit, you&#39;ll get options to <strong>Copy Commit ID</strong> and{' '}
      <strong>Copy Commit Message</strong>.
    </p>
    <p>
      AVAP<sup>TM</sup> Dev Studio supports more Git history workflows through{' '}
      <a href="/docs/editor/extension-marketplace">extensions</a> available on
      the AVAP<sup>TM</sup> Dev Studio Marketplace.
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> Click on an extension tile to read the description
        and reviews in the Marketplace.
      </p>
    </blockquote>
    <h2 id="git-output-window">Git output window</h2>
    <p>
      You can always peek under the hood to see the Git commands we are using.
      This is helpful if something strange is happening or if you are just
      curious. :&#41;
    </p>
    <p>
      To open the Git output window, run <strong>View</strong> &gt;{' '}
      <strong>Output</strong> and select <strong>Log &#40;Git&#41;</strong> from
      the dropdown list.
    </p>
    <h2 id="initialize-a-repository">Initialize a repository</h2>
    <p>
      If your workspace is on your local machine, you can enable Git source
      control by creating a Git repository with the{' '}
      <strong>Initialize Repository</strong> command. When AVAP<sup>TM</sup> Dev
      Studio doesn&#39;t detect an existing Git repository, the Source Control
      view will give you the options to <strong>Initialize Repository</strong>{' '}
      or <strong>Publish to GitHub</strong>.
    </p>
    <p>
      You can also run the <strong>Git: Initialize Repository</strong> and{' '}
      <strong>Publish to GitHub</strong> commands from the{' '}
      <strong>Command Palette</strong> &#40;<code>Ctrl+Shift+P</code>&#41;.
      Running <strong>Initialize Repository</strong> will create the necessary
      Git repository metadata files and show your workspace files as untracked
      changes ready to be staged. <strong>Publish to GitHub</strong> will
      directly publish your workspace folder to a GitHub repository, allowing
      you to choose between private and public repositories. Check out our
      publishing repos video for more information about publishing to GitHub.
    </p>
    <h2 id="avap-tm-dev-studio-as-git-editor">
      AVAP TM Dev Studio as Git editor
    </h2>
    <p>
      When you launch AVAP<sup>TM</sup> Dev Studio from the command line, you
      can pass the <code>--wait</code> argument to make the launch command wait
      until you have closed the new AVAP<sup>TM</sup> Dev Studio instance. This
      can be useful when you configure AVAP<sup>TM</sup> Dev Studio as your Git
      external editor so Git will wait until you close the launched AVAP
      <sup>TM</sup> Dev Studio instance.
    </p>
    <p>Here are the steps to do so:</p>
    <ol>
      <li>
        Make sure you can run <code>code --help</code> from the command line and
        you get help.{' '}
        <ol>
          <li>
            if you do not see help, please follow these steps:{' '}
            <ul>
              <li>
                macOS: Select{' '}
                <strong>
                  Shell Command: Install &#39;Code&#39; command in path
                </strong>{' '}
                from the <strong>Command Palette</strong>.
              </li>
              <li>
                Windows: Make sure you selected <strong>Add to PATH</strong>{' '}
                during the installation.
              </li>
              <li>
                Linux: Make sure you installed Code via our new{' '}
                <code>.deb</code> or <code>.rpm</code> packages.
              </li>
            </ul>
          </li>
        </ol>
      </li>
      <li>
        From the command line, run{' '}
        <code>git config --global core.editor &quot;code --wait</code>&quot;
      </li>
    </ol>
    <p>
      Now you can run<code>git config --global -e</code> and use AVAP
      <sup>TM</sup> Dev Studio as editor for configuring Git.
    </p>
    <h3 id="avap-tm-dev-studio-as-git-difftool-and-mergetool">
      AVAP TM Dev Studio as Git difftool and mergetool
    </h3>
    <p>
      You can use AVAP<sup>TM</sup> Dev Studio&#39;s diff and merge capabilities
      even when using Git from command-line. Add the following to your Git
      configurations to use AVAP<sup>TM</sup> Dev Studio as the diff and merge
      tool:
    </p>

    <pre className="">
      <code className="">
        [diff]
        <br />` `tool = default-difftool
        <br />
        [difftool "default-difftool"]
        <br />` `cmd = code --wait --diff $LOCAL $REMOTE
        <br />
        [merge]
        <br />` `tool = code
        <br />
        [mergetool "code"]
        <br />` `cmd = code --wait --merge $REMOTE $LOCAL $BASE $MERGED
      </code>
    </pre>
    <p>
      This uses the <code>--diff</code> option that can be passed to AVAP
      <sup>TM</sup> Dev Studio to compare two files side by side. The merge tool
      will be used the next time Git discovers a merge conflict.
    </p>
    <p>
      To summarize, here are some examples of where you can use AVAP
      <sup>TM</sup> Dev Studio as the editor:
    </p>
    <ul>
      <li>
        <code>git rebase HEAD~3 -i</code> do interactive rebase using AVAP
        <sup>TM</sup> Dev Studio
      </li>
      <li>
        <code>git commit</code> use AVAP<sup>TM</sup> Dev Studio for the commit
        message
      </li>
      <li>
        <code>git add -p</code> followed by e for interactive add
      </li>
      <li>
        <code>git difftool &lt;commit&gt;^ &lt;commit&gt;</code> use AVAP
        <sup>TM</sup> Dev Studio as the diff editor for changes
      </li>
    </ul>
    <h2 id="working-with-github-pull-requests-and-issues">
      Working with GitHub Pull Requests and Issues
    </h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio can also bring in GitHub&#39;s pull requests
      and issues. Create your PRs in AVAP<sup>TM</sup> Dev Studio, review with
      comments, and approve them without switching context. Learn more about
      GitHub PRs and Issues in AVAP<sup>TM</sup> Dev Studio.
    </p>
    <h2 id="scm-providers">SCM Providers</h2>
    <blockquote>
      <p>
        <strong>Tip:</strong> Click on an extension tile to read the description
        and reviews in the Marketplace.
      </p>
    </blockquote>
    <p>
      AVAP<sup>TM</sup> Dev Studio has support for handling multiple Source
      Control providers simultaneously. For example, you can open multiple Git
      repositories alongside your Azure DevOps Server local workspace and
      seamlessly work across your projects. To turn on the{' '}
      <strong>Source Control Providers</strong> view, select the overflow menu
      in the <strong>Source Control</strong> view &#40;<code>Ctrl+Shift+G</code>
      &#41;, hover over <strong>Views</strong>, and make sure that{' '}
      <strong>Source Control Repositories</strong> is marked with a check. The{' '}
      <strong>Source Control Providers</strong> view shows the detected
      providers and repositories, and you can scope the display of your changes
      by selecting a specific provider.
    </p>
    <h3 id="scm-provider-extensions">SCM Provider extensions</h3>
    <p>
      If you would like to install another SCM provider, you can search on the{' '}
      <strong>scm providers</strong> extension category in the{' '}
      <strong>Extensions</strong> view &#40;<code>Ctrl+Shift+X</code>&#41;.
      Start typing &#39;@ca&#39; and you will see suggestions for extension
      categories like debuggers and linters. Select{' '}
      <code>@category:&quot;scm providers&quot;</code> to see available SCM
      providers.
    </p>
    <h2 id="next-steps">Next steps</h2>
    <ul>
      <li>
        Intro Video - Git Version Control - An introductory video providing an
        overview of AVAP<sup>TM</sup> Dev Studio Git support.
      </li>
      <li>
        Basic Editing - Learn about the powerful AVAP<sup>TM</sup> Dev Studio
        editor.
      </li>
      <li>Code Navigation - Move quickly through your source code.</li>
      <li>
        Debugging - This is where AVAP<sup>TM</sup> Dev Studio really shines
      </li>
      <li>
        Tasks - Running tasks with Gulp, Grunt, and Jake. Showing Errors and
        Warnings
      </li>
      <li>
        Source Control API - If you want to integrate another Source Control
        provider into AVAP<sup>TM</sup> Dev Studio, see our Source Control API.
      </li>
    </ul>
  </Fragment>
);
export default SOURCE_CONTROL_Overview;
