import React, { Fragment } from 'react';

const DisplayLanguage = () => (
  <Fragment>
    <h1 >Display Language</h1>
    <p>
      AVAP™ Dev Studio 2024 ships by default with English as the display
      language and other languages rely on Language Pack extensions available
      from the Marketplace.
    </p>
    <p>
      AVAP™ DS detects the operating system&#39;s UI language and will prompt
      you to install the appropriate Language Pack, if available on the
      Marketplace. Below is an example recommending a Simplified Chinese
      Language Pack:
    </p>
    <p>
      <strong>IMAGEN</strong>
    </p>
    <p>
      After installing the Language Pack extension and following the prompt to
      restart, AVAP™ DS will use the Language Pack matching your operating
      system&#39;s UI language.
    </p>
    <blockquote>
      <p>
        <strong>Note:</strong> This topic explains how to change the display
        language in the AVAP™ DS UI via Language Packs such as French or
        Chinese. If you want to add programming language support, for example
        for C++ or Java, refer to the Programming Languages section of the
        documentation.
      </p>
    </blockquote>
    <h2 >Changing the Display Language</h2>
    <p>
      You can also override the default UI language by explicitly setting the
      AVAP™ DS display language using the{' '}
      <strong>Configure Display Language</strong> command.
    </p>
    <p>
      Press <code>⇧⌘P</code>to bring up the <strong>Command Palette</strong>{' '}
      then start typing &quot;display&quot; to filter and display the{' '}
      <strong>Configure Display Language</strong> command.
    </p>
    <p>
      <strong>IMAGEN</strong>
    </p>
    <p>
      Press <code>Enter</code> and a list of installed languages by locale is
      displayed, with the current <code>locale</code> highlighted.
    </p>
    <p>
      <img
        src="http://101obex.static.mooo.com/03-P-lenguage.png"
        alt="display"
      />
    </p>
    <p>
      Use the <strong>Install additional languages...</strong> option to install
      more Language Packs from the Marketplace, or select a different{' '}
      <code>locale</code> from the list. Changing the <code>locale</code>{' '}
      requires a restart of AVAP™ DS. You will be prompted to restart when you
      select a locale.
    </p>
    <p>
      The <strong>Configure Display Language</strong> command writes to the
      Runtime Configuration Arguments file <code>argv.json</code> in your user
      AVAP™ DS folder &#40;<code>.vscode</code>&#41;.
    </p>
    <p>
      The <code>locale</code> can also be changed by editing the{' '}
      <code>argv.json</code> file directly &#40;
      <strong>Preferences: Configure Runtime Arguments</strong>&#41; and
      restarting AVAP™ DS.
    </p>
    <h2 >Available locales</h2>
    <table>
      <thead>
        <tr>
           <th className='textBold'>Display Language</th>
          <th align="right">Locale</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>English &#40;US&#41;</td>
          <td align="right">
            <code>en</code>
          </td>
        </tr>
        <tr>
          <td>Simplified Chinese</td>
          <td align="right">
            <code>zh-cn</code>
          </td>
        </tr>
        <tr>
          <td>Traditional Chinese</td>
          <td align="right">
            <code>zh-tw</code>
          </td>
        </tr>
        <tr>
          <td>French</td>
          <td align="right">
            <code>fr</code>
          </td>
        </tr>
        <tr>
          <td>German</td>
          <td align="right">
            <code>de</code>
          </td>
        </tr>
        <tr>
          <td>Italian</td>
          <td align="right">
            <code>it</code>
          </td>
        </tr>
        <tr>
          <td>Spanish</td>
          <td align="right">
            <code>es</code>
          </td>
        </tr>
        <tr>
          <td>Japanese</td>
          <td align="right">
            <code>ja</code>
          </td>
        </tr>
        <tr>
          <td>Korean</td>
          <td align="right">
            <code>ko</code>
          </td>
        </tr>
        <tr>
          <td>Russian</td>
          <td align="right">
            <code>ru</code>
          </td>
        </tr>
        <tr>
          <td>Portuguese &#40;Brazil&#41;</td>
          <td align="right">
            <code>pt-br</code>
          </td>
        </tr>
        <tr>
          <td>Turkish</td>
          <td align="right">
            <code>tr</code>
          </td>
        </tr>
        <tr>
          <td>Polish</td>
          <td align="right">
            <code>pl</code>
          </td>
        </tr>
        <tr>
          <td>Czech</td>
          <td align="right">
            <code>cs</code>
          </td>
        </tr>
        <tr>
          <td>Hungarian</td>
          <td align="right">
            <code>hu</code>
          </td>
        </tr>
      </tbody>
    </table>
    <h2 >Marketplace Language Packs</h2>
    <p>
      As described above, AVAP™ DSe ships with English as the default display
      language, but other languages are available through Marketplace Language
      Packs.
    </p>
    <p>
      You can search for Language Packs in the Extensions view &#40;⇧⌘X&#41; by
      typing the language you are looking for along with{' '}
      <code>category:&quot;Language Packs&quot;</code>.
    </p>
    <p>
      <strong>IMAGEN</strong>
    </p>
    <p>
      You can have multiple Language Packs installed and select the current
      display language with the <strong>Configure Display Language</strong>{' '}
      command.
    </p>
    <h2 >Setting the Language</h2>
    <p>
      If you want to use a specific language for a AVAP™ DS session, you can use
      the command-line switch <code>--locale</code> to specify a locale when you
      launch AVAP™ DS.
    </p>
    <p>
      Below is an example of using the<code>--locale</code> command-line switch
      to set the AVAP™ DS display language to French:
    </p>

    <pre className="">
      <code className="">code . --locale=fr</code>
    </pre>
    <p>
      <strong>Note:</strong> You must have the appropriate Language Pack
      installed for the language you specify with the command-line switch. If
      the matching Language Pack is not installed, AVAP™ DS will display
      English.
    </p>
    <h2 >Common questions</h2>
    <h4 >
      Unable to write to file because the file is dirty
    </h4>
    <p>
      This notification may mean that your<code>argv.json</code> file wasn&#39;t
      saved after a previous change. Check if there are any errors in the file
      &#40;<strong>Preferences: Configure Runtime Arguments</strong>&#41;, make
      sure the file is saved, and try to install the Language Pack again.
    </p>
    <h4 >
      Can I contribute to a language pack&#39;s translations?
    </h4>
    <p>
      Yes, the AVAP™ Dev Studio Code Community Localization Project is open to
      anyone, where contributors can provide new translations, vote on existing
      translations, or suggest process improvements.
    </p>
    <h4 >
      How can I enable a programming language like Python?
    </h4>
    <p>
      Refer to the Programming Languages section to learn how to install support
      for programming languages, such as PHP, Python, and Java.
    </p>
  </Fragment>
);
export default DisplayLanguage;
