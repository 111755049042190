import React, { Fragment, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import "./../../assets/css/effectStickySections.css"

const Encrypted_Nodes_Or_Projects = () => {

  const [codes, setCodes] = useState({
    decrypted_call: {
      id: "curl",
      type: "bash",
      code: `https://api.101obex.com:8000/servicio?parameters`,
      copied: false
    },
    encrypted_call: {
      id: "curl",
      type: "bash",
      code: `https://api.101obex.com:5000/servicio?encripted_data=(encripted parameters)`,
      copied: false
    },
    decrypted_answer: {
      id: "curl",
      type: "json",
      code: ` {
        "status":true,
        "e_mail":"test.user@waynnovate.com",
        "elapsed":0.18008685111999512,
        "datos_certificacion":{
           "codtran":"0075f16df4b053a5d10502ffb01e9cd8"
        },
        "session_id":"e9b7945dcbd5d18a6239acc7acafe8e9",
        "type_of_user":"impulso bu00e1sico",
        "profile_id":137,
        "code_profile":"USUARIO",
        "user_id":50,
        "status":null,
        "phone_lenght":10,
        "menu":[
           [
              "Acceso Ru00e1pido",
              [
                 "Movements",
                 "movements",
                 false
              ],
              [
                 "Add a card",
                 "gestor_origenes_propios/crear",
                 false
              ],
              [
                 "Recharge cellphone minutes",
                 "Rechargecellphoneminutes",
                 false
              ],
              [
                 "Transfer between clients",
                 "moneysending",
                 false
              ],
              [
                 "Request money",
                 "requestmoney",
                 false
              ],
              [
                 "Services payment",
                 "payexpresspay",
                 false
              ]
           ]
        ],
        "user_affiliate_id":1,
        "currency":"MXN",
        "name":"qwertyuio qwertyui",
        "certificate":false,
        "phone":"9876543212"
     }`,
      copied: false
    },
    encrypted_answer: {
      id: "curl",
      type: "json",
      code: ` {
        "status":true,
        "encrypted_data":"k8DoQ9ADDph2o3oHdzeW0wO-FITgfGQD4xy9GcfuBtQy8IVazicD4J66kZ-HTlgWpCkXn7xlGDqCcXUNV
      TW9T7Ww1DpPXPyoilI2GPhOFliAWGpip_R56WVYr07qGmMUJy_n2I3si___hBb9MPEI3KBh9eupUO2gKDT
      bULimM_cpCtRHsqFdTZIpRedC0W_HdTgcCrZ_CItCoxAoyiCjx6knaH9dbaUV1GoywBWfuh3Dh4iqHGejH
      RbYi7Apm1PjCj5WNPEEN-UlfNj9hvurwTgCjBXilBg19ld3LUJj-1Yh48It_gLkna12ZqBiuUnQ3Rpj1hH
      vz7CkTjxStkigCyKA4lPh94cK_cJgaiv7c1Uyb54cB8N2bUTBhD4ojOSfR88bN-4wYiIEspinuKDmpHXO8
      HP_IgJSfgkU4QiTfbBKQ8u-2Hxe2x1JgbKIvjpiBNK0H3GNnaPrtciFf88EeQun5oZwOJiFtZBQHv-V4fd
      kfuOYBAWaOm13I9_PYiJir9BE145mIQOuugnebLASKju5UA-NHEclZ7fUF1fNyCeFxGW-6oYfadBanzpIM
      5PjRUODa92gF4X0pPcLy4v1jcegJSMSpTW0DH_vM14gV56OJ0Dvyf52OB2e3LDlfP7TwYmbY7YWwj5MpR1
      uoieOwbGsqbXqKvOOCmlwGIvAc-vowoTLRpviT1_fymNHyRqtb89Gjy_2rvsTgBLoZavKBOv5Wvu1Dil5u
      0wVzo7pqk5XV3lnTCi-t7kLiH7SfXtuIBhPQzPTO40btxpZwC2V4QBsx1BcBMs_cb7Kmcy53exgpQQQkRN
      bTU6jkSnTcccaCPzT9WGhxiHrS1U5bXXW4BM1j9aHFDjhBp6uT9_2QAh0oh-uljLTnw6r6KH69VFJyO2oK
      jG2Qttu-L95ynxW94ecMuLlU26O7F-j9IO1FpI-c8cfKAQs6tbUnv_cU49nTwpX5TZI1ZfCDOb042-KiCJ
      qOfP61FWZtEQrMw7VZwUxMylcku_In9caUUYgpvJhHwqE6GKdS0XuKEcGUV-tfMvBcnewCgobcZhIeTYKh
      KSoaA1AHR7IYHaf8U4isTCzcexJL_mnwHlvWGVEXmM2Ywy_y9Y6nIDFTXPsUG4aYjw="
     }`,
      copied: false
    },
    python_code: {
      id: "curl",
      type: "python",
      code: ` from Crypto.Cipher import AES
 from Crypto.Random import new as Random
 from base64 import urlsafe_b64encode, urlsafe_b64decode
    class CipherByAES:
        def __init__(self):
        self.block_size = 16
        self.key = 'cedb3fb962255b1aafd033cabe831530'
        self.pad = lambda s: s + (self.block_size - len(s) % self.block_size) *
        chr(self.block_size - len(s) % self.block_size)
        self.unpad = lambda s: s[:-ord(s[len(s) - 1:])]
        def encrypt(self, data):
        plain_text = self.pad(data)
        iv = Random().read(AES.block_size)
        cipher = AES.new(self.key, AES.MODE_OFB, iv)
        return urlsafe_b64encode(iv + cipher.encrypt(plain_text.encode())).decode()
        def decrypt(self, data):
        cipher_text = urlsafe_b64decode(data.encode())
        iv = cipher_text[:self.block_size]
        cipher = AES.new(self.key, AES.MODE_OFB, iv)
        return self.unpad(cipher.decrypt(cipher_text[self.block_size])).decode()`,
      copied: false
    },
  });


  return (

    <Fragment>

      <div className='container-fluid'>

        <div className='row'>

          <div className='col-12 col-md-7'>
            <div className='effectStickySections'>
            <h1 >Encrypted Nodes or Projects</h1>
            <p>
              101OBeX offers the possibility of working with encrypted nodes or
              projects. All services that are exposed through the API Manager can be
              consumed in an encrypted manner, provided this preference is established
              during project creation.
            </p>
            <p>
              IT IS IMPORTANT TO UNDERSTAND THAT ONCE A PROJECT IS CREATED, THIS
              ENCRYPTION SETTING CANNOT BE ALTERED. THEREFORE, IT IS CRITICAL TO
              CAREFULLY CONSIDER WHETHER YOUR PROJECT REQUIRES ENCRYPTION TO AVOID
              SUBSEQUENT DATA LOSS.
            </p>
            <p>
              When you indicate that you want to be able to consume an encrypted
              project, you will be assigned an encryption key for it &#40;cipher
              key&#41; which can be consulted in the project data.
            </p>
            <p>
              Once this key has been obtained, the calls can be encrypted under the
              AES256 algorithm with said key and the response will be encrypted with the
              same encryption key.
            </p>
            <p>The nomenclature of the calls will be as follows:</p>
            <p>The nomenclature of the calls will be as follows.</p>
            </div>
          </div>

          <div className='col-12 col-md-5'>
            <p>Decrypted call:</p>

            <div className='my-3'>
              <div className="backgroundHeader rounded-top p-2 px-3 d-flex justify-content-between">
                <div>Decrypted call</div>
                <div>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top"> {codes.decrypted_call.copied ? "copied" : "copy"}</Tooltip>}
                  >
                    <CopyToClipboard text={codes.decrypted_call.code} onCopy={() => setCodes({
                      ...codes,
                      decrypted_call: {
                        ...codes.decrypted_call,
                        copied: true
                      }
                    })}>
                      <div className='sizeClipBoard'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#ffffff"><path d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM96 424c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm96-192c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm128 368c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z" /></svg>
                      </div>
                    </CopyToClipboard>
                  </OverlayTrigger>

                </div>
              </div>
              <SyntaxHighlighter style={darcula} language={codes.decrypted_call.type} className="rounded-bottom m-0 backgroundCode">
                {codes.decrypted_call.code}
              </SyntaxHighlighter>
            </div>
            <p>Encrypted call:</p>

            <div className='my-3'>
              <div className="backgroundHeader rounded-top p-2 px-3 d-flex justify-content-between">
                <div>Decrypted call</div>
                <div>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top"> {codes.encrypted_call.copied ? "copied" : "copy"}</Tooltip>}
                  >
                    <CopyToClipboard text={codes.encrypted_call.code} onCopy={() => setCodes({
                      ...codes,
                      encrypted_call: {
                        ...codes.encrypted_call,
                        copied: true
                      }
                    })}>
                      <div className='sizeClipBoard'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#ffffff"><path d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM96 424c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm96-192c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm128 368c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z" /></svg>
                      </div>
                    </CopyToClipboard>
                  </OverlayTrigger>

                </div>
              </div>
              <SyntaxHighlighter style={darcula} language={codes.encrypted_call.type} className="rounded-bottom m-0 backgroundCode">
                {codes.encrypted_call.code}
              </SyntaxHighlighter>
            </div>
            <p>
              This adds an additional encryption layer that guarantees the security of
              the transferred data.
            </p>
            <p>
              The response will be encrypted and its morphology will be as detailed
              below
            </p>
            <p>Decrypted answer:</p>

            <div className='my-3'>
              <div className="backgroundHeader rounded-top p-2 px-3 d-flex justify-content-between">
                <div>Decrypted Answer</div>
                <div>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top"> {codes.decrypted_answer.copied ? "copied" : "copy"}</Tooltip>}
                  >
                    <CopyToClipboard text={codes.decrypted_answer.code} onCopy={() => setCodes({
                      ...codes,
                      decrypted_answer: {
                        ...codes.decrypted_answer,
                        copied: true
                      }
                    })}>
                      <div className='sizeClipBoard'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#ffffff"><path d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM96 424c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm96-192c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm128 368c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z" /></svg>
                      </div>
                    </CopyToClipboard>
                  </OverlayTrigger>

                </div>
              </div>
              <SyntaxHighlighter style={darcula} language={codes.encrypted_call.type} className="rounded-bottom m-0 backgroundCode" showLineNumbers={true} showInlineLineNumbers={true}>
                {codes.decrypted_answer.code}
              </SyntaxHighlighter>
            </div>
            <p>Encrypted answer:</p>

            <div className='my-3'>
              <div className="backgroundHeader rounded-top p-2 px-3 d-flex justify-content-between">
                <div>Encrypted Answer</div>
                <div>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top"> {codes.encrypted_answer.copied ? "copied" : "copy"}</Tooltip>}
                  >
                    <CopyToClipboard text={codes.encrypted_answer.code} onCopy={() => setCodes({
                      ...codes,
                      encrypted_answer: {
                        ...codes.encrypted_answer,
                        copied: true
                      }
                    })}>
                      <div className='sizeClipBoard'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#ffffff"><path d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM96 424c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm96-192c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm128 368c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z" /></svg>
                      </div>
                    </CopyToClipboard>
                  </OverlayTrigger>

                </div>
              </div>
              <SyntaxHighlighter style={darcula} language={codes.encrypted_answer.type} className="rounded-bottom m-0 backgroundCode" showLineNumbers={true} showInlineLineNumbers={true}>
                {codes.encrypted_answer.code}
              </SyntaxHighlighter>
            </div>


            <p>
              Python code example to encrypt and decrypt &#40;encryption key
              highlighted&#41;
            </p>

            <div className='my-3'>
              <div className="backgroundHeader rounded-top p-2 px-3 d-flex justify-content-between">
                <div>Python code example</div>
                <div>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top"> {codes.python_code.copied ? "copied" : "copy"}</Tooltip>}
                  >
                    <CopyToClipboard text={codes.python_code.code} onCopy={() => setCodes({
                      ...codes,
                      python_code: {
                        ...codes.python_code,
                        copied: true
                      }
                    })}>
                      <div className='sizeClipBoard'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#ffffff"><path d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM96 424c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm96-192c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm128 368c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z" /></svg>
                      </div>
                    </CopyToClipboard>
                  </OverlayTrigger>

                </div>
              </div>
              <SyntaxHighlighter style={darcula} language={codes.python_code.type} className="rounded-bottom m-0 backgroundCode" showLineNumbers={true} showInlineLineNumbers={true}>
                {codes.python_code.code}
              </SyntaxHighlighter>
            </div>

          </div>

        </div>
      </div>



    </Fragment>
  )
};
export default Encrypted_Nodes_Or_Projects;
