import React, { Fragment } from 'react';

const KeyBindings = () => (
  <Fragment>
    <h1 id="key-bindings-for-avap™-dev-studio">
      Key Bindings for AVAP™ Dev Studio
    </h1>
    <p>
      AVAP<sup>TM</sup> Dev Studio 2024 lets you perform most tasks directly
      from the keyboard. This page lists out the default bindings &#40;keyboard
      shortcuts&#41; and describes how you can update them.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: If you visit this page on a Mac, you will see the
        key bindings for the Mac. If you visit using Windows or Linux, you will
        see the keys for that platform. If you need the key bindings for another
        platform, hover your mouse over the key you are interested in.
      </p>
    </blockquote>
    <h3 id="keyboard-shortcuts-editor">Keyboard Shortcuts editor</h3>
    <p>
      AVAP<sup>TM</sup> Dev Studio provides a rich and easy keyboard shortcuts
      editing experience using <strong>Keyboard Shortcuts</strong> editor. It
      lists all available commands with and without keybindings and you can
      easily change / remove / reset their keybindings using the available
      actions. It also has a search box on the top that helps you in finding
      commands or keybindings. You can open this editor by going to the menu
      under <strong>File</strong> &gt; <strong>Preferences</strong> &gt;{' '}
      <strong>Keyboard Shortcuts</strong>.
    </p>
    <p>
      Most importantly, you can see keybindings according to your keyboard
      layout. For example, key binding <code>Cmd+\</code> in US keyboard layout
      will be shown as <code>Ctrl+Shift+Alt+Cmd+7</code> when layout is changed
      to German. The dialog to enter key binding will assign the correct and
      desired key binding as per your keyboard layout.
    </p>
    <p>
      For doing more advanced keyboard shortcut customization, read Advanced
      Customization.
    </p>
    <h3 id="keymap-extensions">Keymap extensions</h3>
    <p>
      Keyboard shortcuts are vital to productivity and changing keyboarding
      habits can be tough. To help with this, <strong>File</strong> &gt;{' '}
      <strong>Preferences</strong> &gt;{' '}
      <strong>Migrate Keyboard Shortcuts</strong> from... shows you a list of
      popular keymap extensions. These extensions modify the AVAP<sup>TM</sup>{' '}
      Dev Studio shortcuts to match those of other editors so you don&#39;t need
      to learn new keyboard shortcuts. There is also a Keymaps category of
      extensions in the Marketplace.
    </p>
    <blockquote>
      <p>
        Tip: Click on an extension tile above to read the description and
        reviews to decide which extension is best for you. See more in the
        Marketplace.
      </p>
    </blockquote>
    <h3 id="keyboard-shortcuts-reference">Keyboard Shortcuts Reference</h3>
    <p>
      We also have a printable version of these keyboard shortcuts.{' '}
      <strong>Help</strong> &gt; <strong>Keyboard Shortcut Reference</strong>{' '}
      displays a condensed PDF version suitable for printing as an easy
      reference.
    </p>
    <p>
      Below are links to the three platform-specific versions &#40;US English
      keyboard&#41;:
    </p>
    <ul>
      <li>Windows</li>
      <li>macOS</li>
      <li>Linux</li>
    </ul>
    <h3 id="detecting-keybinding-conflicts">Detecting keybinding conflicts</h3>
    <p>
      If you have many extensions installed or you have customized your keyboard
      shortcuts, you can sometimes have keybinding conflicts where the same
      keyboard shortcut is mapped to several commands. This can result in
      confusing behavior, especially if different keybindings are going in and
      out of scope as you move around the editor.
    </p>
    <p>
      The Keyboard Shortcuts editor has a context menu command{' '}
      <strong>Show Same Keybindings</strong>, which will filter the keybindings
      based on a keyboard shortcut to display conflicts.
    </p>
    <p>
      Pick a command with the keybinding you think is overloaded and you can see
      if multiple commands are defined, the source of the keybindings and when
      they are active.
    </p>
    <h3 id="troubleshooting-keybindings">Troubleshooting keybindings</h3>
    <p>
      To troubleshoot keybindings problems, you can execute the command{' '}
      <strong>Developer: Toggle Keyboard Shortcuts Troubleshooting</strong>.
      This will activate logging of dispatched keyboard shortcuts and will open
      an output panel with the corresponding log file.
    </p>
    <p>
      You can then press your desired keybinding and check what keyboard
      shortcut AVAP™ DS detects and what command is invoked.
    </p>
    <p>
      For example, when pressing <code>cmd+/</code> in a code editor on macOS,
      the logging output would be:
    </p>

    <pre className="">
      <code className="">
        [KeybindingService]: / Received keydown event - modifiers: [meta], code:
        MetaLeft, keyCode: 91, key: Meta
        <br />
        [KeybindingService]: | Converted keydown event - modifiers: [meta],
        code: MetaLeft, keyCode: 57 &#40;'Meta'&#41;
        <br />
        [KeybindingService]: \ Keyboard event cannot be dispatched.
        <br />
        [KeybindingService]: / Received keydown event - modifiers: [meta], code:
        Slash, keyCode: 191, key: /<br />
        [KeybindingService]: | Converted keydown event - modifiers: [meta],
        code: Slash, keyCode: 85 &#40;'/'&#41;
        <br />
        [KeybindingService]: | Resolving meta+[Slash]
        <br />
        [KeybindingService]: \ From 2 keybinding entries, matched
        editor.action.commentLine, when: editorTextFocus &amp;&amp;
        !editorReadonly, source: built-in.
      </code>
    </pre>
    <p>
      The first keydown event is for the MetaLeft key &#40;cmd&#41; and cannot
      be dispatched. The second keydown event is for the Slash key &#40;/&#41;
      and is dispatched as meta+[Slash]. There were two keybinding entries
      mapped from meta+[Slash] and the one that matched was for the command
      editor.action.commentLine, which has the when condition editorTextFocus
      &amp;&amp; !editorReadonly and is a built-in keybinding entry.
    </p>
    <h3 id="viewing-modified-keybindings">Viewing modified keybindings</h3>
    <p>
      You can view any user modified keyboard shortcuts in AVAP<sup>TM</sup> Dev
      Studio in the <strong>Keyboard Shortcuts</strong> editor with the Show
      User Keybindings command in the More Actions &#40;...&#41; menu. This
      applies the @source:user filter to the Keyboard Shortcuts editor
      &#40;Source is &#39;User&#39;&#41;.
    </p>
    <h3 id="advanced-customization">Advanced customization</h3>
    <p>
      All keyboard shortcuts in AVAP<sup>TM</sup> Dev Studio can be customized
      via the keybindings.json file.
    </p>
    <p>
      To configure keyboard shortcuts through the JSON file, open{' '}
      <strong>Keyboard Shortcuts</strong> editor and select the{' '}
      <strong>Open Keyboard Shortcuts &#40;JSON&#41;</strong> button on the
      right of the editor title bar. This will open your keybindings.json file
      where you can overwrite the Default Keyboard Shortcuts.
    </p>
    <p>
      You can also open the keybindings.json file from the Command Palette
      &#40;Ctrl+Shift+P&#41; with the Preferences: Open Keyboard Shortcuts
      &#40;JSON&#41; command.
    </p>
    <h3 id="keyboard-rules">Keyboard rules</h3>
    <p>Each rule consists of:</p>
    <ul>
      <li>a key that describes the pressed keys.</li>
      <li>a command containing the identifier of the command to execute.</li>
      <li>
        an optional when clause containing a boolean expression that will be
        evaluated depending on the current context.
      </li>
    </ul>
    <p>
      Chords &#40;two separate keypress actions&#41; are described by separating
      the two keypresses with a space. For example, <code>Ctrl+K Ctrl+C</code>.
    </p>
    <p>When a key is pressed:</p>
    <ul>
      <li>
        the rules are evaluated from <strong>bottom</strong> to{' '}
        <strong>top</strong>.
      </li>
      <li>
        the first rule that matches, both the key and in terms of when, is
        accepted.
      </li>
      <li>no more rules are processed.</li>
      <li>
        if a rule is found and has a command set, the command is executed.
      </li>
    </ul>
    <p>
      The additional keybindings.json rules are appended at runtime to the
      bottom of the default rules, thus allowing them to overwrite the default
      rules. The keybindings.json file is watched by AVAP™ DS so editing it
      while AVAP<sup>TM</sup> Dev Studio is running will update the rules at
      runtime.
    </p>
    <p>
      The keyboard shortcuts dispatching is done by analyzing a list of rules
      that are expressed in JSON. Here are some examples:
    </p>

    <pre className="">
      <code className="">
        // Keybindings that are active when the focus is in the editor
        <br />
        &#123; "key": "home", "command": "cursorHome", "when": "editorTextFocus"
        &#125;,
        <br />
        &#123; "key": "shift+home", "command": "cursorHomeSelect", "when":
        "editorTextFocus" &#125;,
        <br />
        <br />
        // Keybindings that are complementary
        <br />
        &#123; "key": "f5", "command": "workbench.action.debug.continue",
        "when": "inDebugMode" &#125;,
        <br />
        &#123; "key": "f5", "command": "workbench.action.debug.start", "when":
        "!inDebugMode" &#125;,
        <br />
        <br />
        // Global keybindings
        <br />
        &#123; "key": "ctrl+f", "command": "actions.find" &#125;,
        <br />
        &#123; "key": "alt+left", "command": "workbench.action.navigateBack"
        &#125;,
        <br />
        &#123; "key": "alt+right", "command": "workbench.action.navigateForward"
        &#125;,
        <br />
        <br />
        // Global keybindings using chords &#40;two separate keypress
        actions&#41;
        <br />
        &#123; "key": "ctrl+k enter", "command": "workbench.action.keepEditor"
        &#125;,
        <br />
        &#123; "key": "ctrl+k ctrl+w", "command":
        "workbench.action.closeAllEditors" &#125;,
      </code>
    </pre>
    <h3 id="accepted-keys">Accepted keys</h3>
    <p>The key is made up of modifiers and the key itself.</p>
    <p>The following modifiers are accepted:</p>
    <table>
      <thead>
        <tr>
           <th className='textBold'>
            <strong>Platform</strong>
          </th>
           <th className='textBold'>
            <strong>Modifiers</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>macOS</td>
          <td>
            <code>Ctrl+</code>, <code>Shift+</code>, <code>Alt+</code>,{' '}
            <code>Cmd+</code>
          </td>
        </tr>
        <tr>
          <td>Windows</td>
          <td>
            <code>Ctrl+</code>, <code>Shift+</code>, <code>Alt+</code>,{' '}
            <code>Win+</code>
          </td>
        </tr>
        <tr>
          <td>Linux</td>
          <td>
            <code>Ctrl+</code>, <code>Shift+</code>, <code>Alt+</code>,{' '}
            <code>Meta+</code>
          </td>
        </tr>
      </tbody>
    </table>
    <p>The following keys are accepted:</p>
    <ul>
      <li>
        <p>
          <code>f1-f19</code>, <code>a-z</code>, <code>0-9</code>
        </p>
      </li>
      <li>
        <p>
          ```, <code>-</code>, <code>=</code>, <code>[</code>, <code>]</code>,{' '}
          <code>\</code>, <code>;</code>, <code>&#39;</code>, <code>,</code>,{' '}
          <code>.</code>, <code>/</code>
        </p>
      </li>
      <li>
        <p>
          <code>left</code>, <code>up</code>, <code>right</code>,{' '}
          <code>down</code>, <code>pageup</code>, <code>pagedown</code>,{' '}
          <code>end</code>, <code>home</code>
        </p>
      </li>
      <li>
        <p>
          <code>tab</code>, <code>enter</code>, <code>escape</code>,{' '}
          <code>space</code>, <code>backspace</code>, <code>delete</code>
        </p>
      </li>
      <li>
        <p>
          <code>pausebreak</code>, <code>capslock</code>, <code>insert</code>
        </p>
      </li>
      <li>
        <p>
          <code>numpad0-numpad9</code>, <code>numpad_multiply</code>,{' '}
          <code>numpad_add</code>, <code>numpad_separator</code>
        </p>
      </li>
      <li>
        <p>
          <code>numpad_subtract</code>, <code>numpad_decimal</code>,{' '}
          <code>numpad_divide</code>
        </p>
      </li>
    </ul>
    <h3 id="command-arguments">Command arguments</h3>
    <p>
      You can invoke a command with arguments. This is useful if you often
      perform the same operation on a specific file or folder. You can add a
      custom keyboard shortcut to do exactly what you want.
    </p>
    <p>
      The following is an example overriding the <code>Enter</code> key to print
      some text:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br /> "key": "enter",
        <br /> "command": "type",
        <br /> "args": &#123; "text": "Hello World" &#125;,
        <br /> "when": "editorTextFocus"
        <br />
        &#125;
      </code>
    </pre>
    <p>
      The type command will receive &#123;&quot;text&quot;: &quot;Hello
      World&quot;&#125; as its first argument and add &quot;Hello World&quot; to
      the file instead of producing the default command.
    </p>
    <p>
      For more information on commands that take arguments, refer to Built-in
      Commands.
    </p>
    <h3 id="running-multiple-commands">Running multiple commands</h3>
    <p>
      It is possible to create a keybinding that runs several other commands
      sequentially using the command runCommands.
    </p>
    <p>
      Run several commands without arguments: copy current line down, mark the
      current line as comment, move cursor to copied line
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br /> "key": "ctrl+alt+c",
        <br /> "command": "runCommands",
        <br /> "args": &#123;
        <br /> "commands": [<br /> "editor.action.copyLinesDownAction",
        <br /> "cursorUp",
        <br /> "editor.action.addCommentLine",
        <br /> "cursorDown"
        <br /> ]<br /> &#125;
        <br />
        &#125;,
      </code>
    </pre>
    <p>
      It is also possible to pass arguments to commands: create a new untitled
      TypeScript file and insert a custom snippet
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br /> "key": "ctrl+n",
        <br /> "command": "runCommands",
        <br /> "args": &#123;
        <br /> "commands": [<br /> &#123;
        <br /> "command": "workbench.action.files.newUntitledFile",
        <br /> "args": &#123;
        <br /> "languageId": "typescript"
        <br /> &#125;
        <br /> &#125;,
        <br /> &#123;
        <br /> "command": "editor.action.insertSnippet",
        <br /> "args": &#123;
        <br /> "langId": "typescript",
        <br /> "snippet": "class $&#123;1:ClassName&#125;
        &#123;\n\tconstructor&#40;&#41; &#123;\n\t\t$0\n\t&#125;\n&#125;"
        <br /> &#125;
        <br /> &#125;
        <br /> ]<br /> &#125;
        <br />
        &#125;,
      </code>
    </pre>
    <p>
      Note that commands run by runCommands receive the value of
      &quot;args&quot; as the first argument. So in the example above,
      workbench.action.files.newUntitledFile receives
      &#123;&quot;languageId&quot;: &quot;typescript&quot; &#125; as its first
      and only argument.
    </p>
    <p>
      To pass several arguments, one needs to have &quot;args&quot; as an array:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br /> "key": "ctrl+shift+e",
        <br /> "command": "runCommands",
        <br /> "args": &#123;
        <br /> "commands": [<br /> &#123;
        <br /> // command invoked with 2 arguments:
        vscode.executeCommand&#40;"myCommand", "arg1", "arg2"&#41;
        <br /> "command": "myCommand",
        <br /> "args": ["arg1", "arg2"]
        <br /> &#125;
        <br /> ]<br /> &#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>
      To pass an array as the first argument, one needs to wrap the array in
      another array: &quot;args&quot;: [ [1, 2, 3] ].
    </p>
    <h3 id="removing-a-specific-key-binding-rule">
      Removing a specific key binding rule
    </h3>
    <p>
      You can write a key binding rule that targets the removal of a specific
      default key binding. With the keybindings.json, it was always possible to
      redefine all the key bindings of AVAP<sup>TM</sup> Dev Studio, but it can
      be difficult to make a small tweak, especially around overloaded keys,
      such as <code>Tab</code> or <code>Escape</code>. To remove a specific key
      binding, add a - to the command and the rule will be a removal rule.
    </p>
    <p>Here is an example:</p>

    <pre className="">
      <code className="">
        // In Default Keyboard Shortcuts
        <br />
        ...
        <br />
        &#123; "key": "tab", "command": "tab", "when": ... &#125;,
        <br />
        &#123; "key": "tab", "command": "jumpToNextSnippetPlaceholder", "when":
        ... &#125;,
        <br />
        &#123; "key": "tab", "command": "acceptSelectedSuggestion", "when": ...
        &#125;,
        <br />
        ...
        <br />
        <br />
        // To remove the second rule, for example, add in keybindings.json:
        <br />
        &#123; "key": "tab", "command": "-jumpToNextSnippetPlaceholder" &#125;
      </code>
    </pre>
    <p>
      To override a specific key binding rule with an empty action, you can
      specify an empty command:
    </p>

    <pre className="">
      <code className="">
        // To override and disable any `tab` keybinding, for example, add in
        keybindings.json:
        <br />
        &#123; "key": "tab", "command": "" &#125;
      </code>
    </pre>
    <h3 id="keyboard-layouts">Keyboard layouts</h3>
    <blockquote>
      <p>
        <strong>Note</strong>: This section relates only to key bindings, not to
        typing in the editor.
      </p>
    </blockquote>
    <p>
      The keys above are string representations for virtual keys and do not
      necessarily relate to the produced character when they are pressed. More
      precisely:
    </p>
    <ul>
      <li>Reference: Virtual-Key Codes &#40;Windows&#41;</li>
      <li>
        <code>tab</code> for VK_TAB &#40;0x09&#41;
      </li>
      <li>; for VK_OEM_1 &#40;0xBA&#41;</li>
      <li>
        <code>=</code> for VK_OEM_PLUS &#40;0xBB&#41;
      </li>
      <li>
        <code>,</code> for VK_OEM_COMMA &#40;0xBC&#41;
      </li>
      <li>
        <code>-</code> for VK_OEM_MINUS &#40;0xBD&#41;
      </li>
      <li>
        <code>.</code> for VK_OEM_PERIOD &#40;0xBE&#41;
      </li>
      <li>
        <code>/</code> for VK_OEM_2 &#40;0xBF&#41;
      </li>
      <li>` for VK_OEM_3 &#40;0xC0&#41;</li>
      <li>
        <code>[</code> for VK_OEM_4 &#40;0xDB&#41;
      </li>
      <li>
        <code>\</code> for VK_OEM_5 &#40;0xDC&#41;
      </li>
      <li>
        <code>]</code> for VK_OEM_6 &#40;0xDD&#41;
      </li>
      <li>
        <code>&#39;</code> for VK_OEM_7 &#40;0xDE&#41;
      </li>
      <li>etc.</li>
    </ul>
    <p>
      Different keyboard layouts usually reposition the above virtual keys or
      change the characters produced when they are pressed. When using a
      different keyboard layout than the standard US, AVAP<sup>TM</sup> Dev
      Studio does the following:
    </p>
    <p>
      All the key bindings are rendered in the UI using the current system&#39;s
      keyboard layout. For example, Split Editor when using a French
      &#40;France&#41; keyboard layout is now rendered as <code>Ctrl+*</code>:
    </p>
    <p>
      When editing keybindings.json, AVAP<sup>TM</sup> Dev Studio highlights
      misleading key bindings, those that are represented in the file with the
      character produced under the standard US keyboard layout, but that need
      pressing keys with different labels under the current system&#39;s
      keyboard layout. For example, here is how the{' '}
      <strong>Default Keyboard Shortcuts</strong> rules look like when using a
      French &#40;France&#41; keyboard layout:
    </p>
    <p>
      There is also a widget that helps input the key binding rule when editing
      keybindings.json. To launch the Define Keybinding widget, press{' '}
      <code>Ctrl+K Ctrl+K</code>. The widget listens for key presses and renders
      the serialized JSON representation in the text box and below it, the keys
      that AVAP<sup>TM</sup> Dev Studio has detected under your current keyboard
      layout. Once you&#39;ve typed the key combination you want, you can press{' '}
      <code>Enter</code> and a rule snippet will be inserted.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: On Linux, AVAP<sup>TM</sup> Dev Studio detects
        your current keyboard layout on start-up and then caches this
        information. For a good experience, we recommend restarting AVAP
        <sup>TM</sup> Dev Studio if you change your keyboard layout.
      </p>
    </blockquote>
    <h3 id="keyboard-layout-independent-bindings">
      Keyboard layout-independent bindings
    </h3>
    <p>
      Using scan codes, it is possible to define keybindings which do not change
      with the change of the keyboard layout. For example:
    </p>

    <pre className="">
      <code className="">
        &#123; "key": "cmd+[Slash]", "command": "editor.action.commentLine",
        "when": "editorTextFocus" &#125;
      </code>
    </pre>
    <p>Accepted scan codes:</p>
    <ul>
      <li>
        <p>
          <code>[F1]-[F19]</code>, <code>[KeyA]-[KeyZ]</code>,{' '}
          <code>[Digit0]-[Digit9]</code>
        </p>
      </li>
      <li>
        <p>
          <code>[Backquote]</code>, <code>[Minus]</code>, <code>[Equal]</code>,{' '}
          <code>[BracketLeft]</code>, <code>[BracketRight]</code>,{' '}
          <code>[Backslash]</code>, <code>[Semicolon]</code>,{' '}
          <code>[Quote]</code>, <code>[Comma]</code>, <code>[Period]</code>,{' '}
          <code>[Slash]</code>
        </p>
      </li>
      <li>
        <p>
          <code>[ArrowLeft]</code>, <code>[ArrowUp]</code>,{' '}
          <code>[ArrowRight]</code>, <code>[ArrowDown]</code>,{' '}
          <code>[PageUp]</code>, <code>[PageDown]</code>, <code>[End]</code>,{' '}
          <code>[Home]</code>
        </p>
      </li>
      <li>
        <p>
          <code>[Tab]</code>, <code>[Enter]</code>, <code>[Escape]</code>,{' '}
          <code>[Space]</code>, <code>[Backspace]</code>, <code>[Delete]</code>
        </p>
      </li>
      <li>
        <p>
          <code>[Pause]</code>, <code>[CapsLock]</code>, <code>[Insert]</code>
        </p>
      </li>
      <li>
        <p>
          <code>[Numpad0]-[Numpad9]</code>, <code>[NumpadMultiply]</code>,{' '}
          <code>[NumpadAdd]</code>, <code>[NumpadComma]</code>
        </p>
      </li>
      <li>
        <p>
          <code>[NumpadSubtract]</code>, <code>[NumpadDecimal]</code>,{' '}
          <code>[NumpadDivide]</code>
        </p>
      </li>
    </ul>
    <h3 id="when-clause-contexts">when clause contexts</h3>
    <p>
      AVAP<sup>TM</sup> Dev Studio gives you fine control over when your key
      bindings are enabled through the optional when clause. If your key binding
      doesn&#39;t have a when clause, the key binding is globally available at
      all times. A when clause evaluates to either Boolean true or false for
      enabling key bindings.
    </p>
    <p>
      AVAP<sup>TM</sup> Dev Studio sets various context keys and specific values
      depending on what elements are visible and active in the AVAP<sup>TM</sup>{' '}
      Dev Studio UI. For example, the built-in Start Debugging command has the
      keyboard shortcut <code>F5</code>, which is only enabled when there is an
      appropriate debugger available &#40;context debuggersAvailable is
      true&#41; and the editor isn&#39;t in debug mode &#40;context inDebugMode
      is false&#41;:
    </p>
    <p>
      You can also view a keybinding&#39;s when clause directly in the Default
      Keybindings JSON &#40;
      <strong>
        Preferences: Open Default Keyboard Shortcuts &#40;JSON&#41;
      </strong>
      &#41;:
    </p>

    <pre className="">
      <code className="">
        &#123; "key": "f5", "command": "workbench.action.debug.start",
        <br /> "when": "debuggersAvailable &amp;&amp; !inDebugMode" &#125;,
      </code>
    </pre>
    <h4 id="conditional-operators">Conditional operators</h4>
    <p>
      For when clause conditional expressions, the following conditional
      operators are useful for keybindings:
    </p>
    <table>
      <thead>
        <tr>
          <th align="left">
            <strong>Operator</strong>
          </th>
          <th align="center">
            <strong>Symb</strong>
          </th>
          <th align="right">
            <strong>Example</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="left">Equality</td>
          <td align="center">==</td>
          <td align="right">&quot;editorLangId == typescript&quot;</td>
        </tr>
        <tr>
          <td align="left">Inequality</td>
          <td align="center">!=</td>
          <td align="right">&quot;resourceExtname != .js&quot;</td>
        </tr>
        <tr>
          <td align="left">Or</td>
          <td align="center"></td>
          <td align="right"></td>
        </tr>
        <tr>
          <td align="left">And</td>
          <td align="center">&amp;&amp;</td>
          <td align="right">
            &quot;textInputFocus &amp;&amp; !editorReadonly&quot;
          </td>
        </tr>
        <tr>
          <td align="left">Matches</td>
          <td align="center">=~</td>
          <td align="right">&quot;resourceScheme =~ /^untitled$</td>
        </tr>
      </tbody>
    </table>
    <p>
      You can find the full list of when clause conditional operators in the
      when clause contexts reference.
    </p>
    <h4 id="available-contexts">Available contexts</h4>
    <p>
      You can find some of the available when clause contexts in the when clause
      context reference.
    </p>
    <p>
      The list there isn&#39;t exhaustive and you can find other when clause
      contexts by searching and filtering in the Keyboard Shortcuts editor &#40;
      <strong>Preferences: Open Keyboard Shortcuts</strong> &#41; or reviewing
      the Default Keybindings JSON file &#40;
      <strong>
        Preferences: Open Default Keyboard Shortcuts &#40;JSON&#41;
      </strong>
      &#41;.
    </p>
    <h3 id="custom-keybindings-for-refactorings">
      Custom keybindings for refactorings
    </h3>
    <p>
      The editor.action.codeAction command lets you configure keybindings for
      specific Refactorings &#40;Code Actions&#41;. For example, the keybinding
      below triggers the <strong>Extract function</strong> refactoring Code
      Actions:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br /> "key": "ctrl+shift+r ctrl+e",
        <br /> "command": "editor.action.codeAction",
        <br /> "args": &#123;
        <br /> "kind": "refactor.extract.function"
        <br /> &#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>
      This is covered in depth in the Refactoring topic where you can learn
      about different kinds of Code Actions and how to prioritize them in the
      case of multiple possible refactorings.
    </p>
    <h3 id="default-keyboard-shortcuts">Default Keyboard Shortcuts</h3>
    <p>
      You can view all default keyboard shortcuts in AVAP Dev Studio in the{' '}
      <strong>Keyboard Shortcuts</strong> editor with the{' '}
      <strong>Show Default Keybindings</strong> command in the{' '}
      <strong>More Actions</strong> &#40;...&#41; menu. This applies the
      @source:default filter to the <strong>Keyboard Shortcuts</strong> editor
      &#40;<strong>Source</strong> is &#39;Default&#39;&#41;.
    </p>
    <p>
      You can view the default keyboard shortcuts as a JSON file using the
      command{' '}
      <strong>
        Preferences: Open Default Keyboard Shortcuts &#40;JSON&#41;
      </strong>
      .
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: The following keys are rendered assuming a
        standard US keyboard layout. If you use a different keyboard layout,
        please read below. You can view the currently active keyboard shortcuts
        in VS Code in the <strong>Command Palette</strong> &#40;
        <strong>View</strong> -&gt; <strong>Command Palette</strong>&#41; or in
        the <strong>Keyboard Shortcuts</strong> editor &#40;
        <strong>File &gt; Preferences &gt; Keyboard Shortcuts</strong>&#41;.
      </p>
    </blockquote>
    <p>
      Some commands included below do not have default keyboard shortcuts and so
      are displayed as unassigned but you can assign your own keybindings.
    </p>
    <h4 id="basic-editing">Basic Editing</h4>
    <table>
      <thead>
        <tr>
          <th align="left">
            <strong>Command</strong>
          </th>
          <th align="center">
            <strong>Key</strong>
          </th>
          <th align="right">
            <strong>Command id</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="left">Cut line &#40;empty selection&#41;</td>
          <td align="center">Ctrl+X</td>
          <td align="right">editor.action.clipboardCutAction</td>
        </tr>
        <tr>
          <td align="left">Copy line &#40;empty selection&#41;</td>
          <td align="center">Ctrl+C</td>
          <td align="right">editor.action.clipboardCopyAction</td>
        </tr>
        <tr>
          <td align="left">Paste</td>
          <td align="center">Ctrl+V</td>
          <td align="right">editor.action.clipboardPasteAction</td>
        </tr>
        <tr>
          <td align="left">Delete Line</td>
          <td align="center">Ctrl+Shift+K</td>
          <td align="right">editor.action.deleteLines</td>
        </tr>
        <tr>
          <td align="left">Insert Line Below</td>
          <td align="center">Ctrl+Enter</td>
          <td align="right">editor.action.insertLineAfter</td>
        </tr>
        <tr>
          <td align="left">Insert Line Above</td>
          <td align="center">Ctrl+Shift+Enter</td>
          <td align="right">editor.action.insertLineBefore</td>
        </tr>
        <tr>
          <td align="left">Move Line Down</td>
          <td align="center">Alt+Down</td>
          <td align="right">editor.action.moveLinesDownAction</td>
        </tr>
        <tr>
          <td align="left">Move Line Up</td>
          <td align="center">Alt+Up</td>
          <td align="right">editor.action.moveLinesUpAction</td>
        </tr>
        <tr>
          <td align="left">Copy Line Down</td>
          <td align="center">Shift+Alt+Down</td>
          <td align="right">editor.action.copyLinesDownAction</td>
        </tr>
        <tr>
          <td align="left">Copy Line Up</td>
          <td align="center">Shift+Alt+Up</td>
          <td align="right">editor.action.copyLinesUpAction</td>
        </tr>
        <tr>
          <td align="left">Undo</td>
          <td align="center">Ctrl+Z</td>
          <td align="right">undo</td>
        </tr>
        <tr>
          <td align="left">Redo</td>
          <td align="center">areCtrl+Y</td>
          <td align="right">redo</td>
        </tr>
        <tr>
          <td align="left">Add Selection To Next Find Match</td>
          <td align="center">Ctrl+D</td>
          <td align="right">editor.action.addSelectionToNextFindMatch</td>
        </tr>
        <tr>
          <td align="left">Move Last Selection To Next Find Match</td>
          <td align="center">Ctrl+K Ctrl+D</td>
          <td align="right">editor.action.moveSelectionToNextFindMatch</td>
        </tr>
        <tr>
          <td align="left">Undo last cursor operation</td>
          <td align="center">Ctrl+U</td>
          <td align="right">cursorUndo</td>
        </tr>
        <tr>
          <td align="left">Insert cursor at end of each line selected</td>
          <td align="center">Shift+Alt+I</td>
          <td align="right">
            editor.action.insertCursorAtEndOfEachLineSelected
          </td>
        </tr>
        <tr>
          <td align="left">Select all occurrences of current selection</td>
          <td align="center">Ctrl+Shift+L</td>
          <td align="right">editor.action.selectHighlights</td>
        </tr>
        <tr>
          <td align="left">Select all occurrences of current word</td>
          <td align="center">Ctrl+F2</td>
          <td align="right">editor.action.changeAll</td>
        </tr>
        <tr>
          <td align="left">Select current line</td>
          <td align="center">Ctrl+L</td>
          <td align="right">expandLineSelection</td>
        </tr>
        <tr>
          <td align="left">Insert Cursor Below</td>
          <td align="center">Ctrl+Alt+Down</td>
          <td align="right">editor.action.insertCursorBelow</td>
        </tr>
        <tr>
          <td align="left">Insert Cursor Above</td>
          <td align="center">Ctrl+Alt+Up</td>
          <td align="right">editor.action.insertCursorAbove</td>
        </tr>
        <tr>
          <td align="left">Jump to matching bracket</td>
          <td align="center">Ctrl+Shift+\</td>
          <td align="right">editor.action.jumpToBracket</td>
        </tr>
        <tr>
          <td align="left">Indent Line</td>
          <td align="center">Ctrl+]</td>
          <td align="right">editor.action.indentLines</td>
        </tr>
        <tr>
          <td align="left">Outdent Line</td>
          <td align="center">Ctrl+[</td>
          <td align="right">editor.action.outdentLines</td>
        </tr>
        <tr>
          <td align="left">Go to Beginning of Line</td>
          <td align="center">Home</td>
          <td align="right">cursorHome</td>
        </tr>
        <tr>
          <td align="left">Go to End of Line</td>
          <td align="center">End</td>
          <td align="right">cursorEnd</td>
        </tr>
        <tr>
          <td align="left">Go to End of File</td>
          <td align="center">Ctrl+End</td>
          <td align="right">cursorBottom</td>
        </tr>
        <tr>
          <td align="left">Go to Beginning of File</td>
          <td align="center">Ctrl+Home</td>
          <td align="right">cursorTop</td>
        </tr>
        <tr>
          <td align="left">Scroll Line Down</td>
          <td align="center">Ctrl+Down</td>
          <td align="right">scrollLineDown</td>
        </tr>
        <tr>
          <td align="left">Scroll Line Up</td>
          <td align="center">Ctrl+Up</td>
          <td align="right">scrollLineUp</td>
        </tr>
        <tr>
          <td align="left">Scroll Page Down</td>
          <td align="center">Alt+PageDown</td>
          <td align="right">scrollPageDown</td>
        </tr>
        <tr>
          <td align="left">Scroll Page Up</td>
          <td align="center">Alt+PageUp</td>
          <td align="right">scrollPageUp</td>
        </tr>
        <tr>
          <td align="left">Fold &#40;collapse&#41; region</td>
          <td align="center">Ctrl+Shift+[</td>
          <td align="right">editor.fold</td>
        </tr>
        <tr>
          <td align="left">Unfold &#40;uncollapse&#41; region</td>
          <td align="center">Ctrl+Shift+]</td>
          <td align="right">editor.unfold</td>
        </tr>
        <tr>
          <td align="left">Fold &#40;collapse&#41; all subregions</td>
          <td align="center">Ctrl+K Ctrl+[</td>
          <td align="right">editor.foldRecursively</td>
        </tr>
        <tr>
          <td align="left">Unfold &#40;uncollapse&#41; all subregions</td>
          <td align="center">Ctrl+K Ctrl+]</td>
          <td align="right">editor.unfoldRecursively</td>
        </tr>
        <tr>
          <td align="left">Unfold &#40;uncollapse&#41; all subregions</td>
          <td align="center">Ctrl+K Ctrl+]</td>
          <td align="right">editor.unfoldRecursively</td>
        </tr>
        <tr>
          <td align="left">Fold &#40;collapse&#41; all regions</td>
          <td align="center">Ctrl+K Ctrl+0</td>
          <td align="right">editor.foldAll</td>
        </tr>
        <tr>
          <td align="left">Unfold &#40;uncollapse&#41; all regions</td>
          <td align="center">Ctrl+K Ctrl+J</td>
          <td align="right">editor.unfoldAll</td>
        </tr>
        <tr>
          <td align="left">Add Line Comment</td>
          <td align="center">Ctrl+K Ctrl+C</td>
          <td align="right">editor.action.addCommentLine</td>
        </tr>
        <tr>
          <td align="left">Remove Line Comment</td>
          <td align="center">Ctrl+K Ctrl+U</td>
          <td align="right">editor.action.removeCommentLine</td>
        </tr>
        <tr>
          <td align="left">Toggle Line Comment</td>
          <td align="center">Ctrl+/</td>
          <td align="right">editor.action.commentLine</td>
        </tr>
        <tr>
          <td align="left">Toggle Block Comment</td>
          <td align="center">Shift+Alt+A</td>
          <td align="right">editor.action.blockComment</td>
        </tr>
        <tr>
          <td align="left">Find</td>
          <td align="center">Ctrl+F</td>
          <td align="right">actions.find</td>
        </tr>
        <tr>
          <td align="left">Replace</td>
          <td align="center">Ctrl+H</td>
          <td align="right">editor.action.startFindReplaceAction</td>
        </tr>
        <tr>
          <td align="left">Find Next</td>
          <td align="center">Enter</td>
          <td align="right">editor.action.nextMatchFindAction</td>
        </tr>
        <tr>
          <td align="left">Find Previous</td>
          <td align="center">Shift+Enter</td>
          <td align="right">editor.action.previousMatchFindAction</td>
        </tr>
        <tr>
          <td align="left">Select All Occurrences of Find Match</td>
          <td align="center">Alt+Enter</td>
          <td align="right">editor.action.selectAllMatches</td>
        </tr>
        <tr>
          <td align="left">Toggle Find Case Sensitive</td>
          <td align="center">Alt+C</td>
          <td align="right">toggleFindCaseSensitive</td>
        </tr>
        <tr>
          <td align="left">Toggle Find Regex</td>
          <td align="center">Alt+R</td>
          <td align="right">toggleFindRegex</td>
        </tr>
        <tr>
          <td align="left">Toggle Find Whole Word</td>
          <td align="center">Alt+W</td>
          <td align="right">toggleFindWholeWord</td>
        </tr>
        <tr>
          <td align="left">Toggle Use of Tab Key for Setting Focus</td>
          <td align="center">Ctrl+M</td>
          <td align="right">editor.action.toggleTabFocusMode</td>
        </tr>
        <tr>
          <td align="left">Toggle Render Whitespace</td>
          <td align="center">unassigned</td>
          <td align="right">toggleRenderWhitespace</td>
        </tr>
        <tr>
          <td align="left">Toggle Word Wrap</td>
          <td align="center">Alt+Z</td>
          <td align="right">editor.action.toggleWordWrap</td>
        </tr>
      </tbody>
    </table>
    <h4 id="rich-languages-editing">Rich Languages Editing</h4>
    <table>
      <thead>
        <tr>
          <th align="left">
            <strong>Command</strong>
          </th>
          <th align="center">
            <strong>Key</strong>
          </th>
          <th align="right">
            <strong>Command id</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="left">Trigger Suggest</td>
          <td align="center">Ctrl+Space</td>
          <td align="right">editor.action.triggerSuggest</td>
        </tr>
        <tr>
          <td align="left">Trigger Parameter Hints</td>
          <td align="center">Ctrl+Shift+Space</td>
          <td align="right">editor.action.triggerParameterHints</td>
        </tr>
        <tr>
          <td align="left">Format Document</td>
          <td align="center">Shift+Alt+F</td>
          <td align="right">editor.action.formatDocument</td>
        </tr>
        <tr>
          <td align="left">Format Selection</td>
          <td align="center">Ctrl+K Ctrl+F</td>
          <td align="right">editor.action.formatSelection</td>
        </tr>
        <tr>
          <td align="left">Go to Definition</td>
          <td align="center">F12</td>
          <td align="right">editor.action.revealDefinition</td>
        </tr>
        <tr>
          <td align="left">Show Hover</td>
          <td align="center">Ctrl+K Ctrl+I</td>
          <td align="right">editor.action.showHover</td>
        </tr>
        <tr>
          <td align="left">Peek Definition</td>
          <td align="center">Alt+F12</td>
          <td align="right">editor.action.peekDefinition</td>
        </tr>
        <tr>
          <td align="left">Open Definition to the Side</td>
          <td align="center">Ctrl+K F12</td>
          <td align="right">editor.action.revealDefinitionAside</td>
        </tr>
        <tr>
          <td align="left">Quick Fix</td>
          <td align="center">Ctrl+.</td>
          <td align="right">editor.action.quickFix</td>
        </tr>
        <tr>
          <td align="left">Go to References</td>
          <td align="center">Shift+F12</td>
          <td align="right">editor.action.goToReferences</td>
        </tr>
        <tr>
          <td align="left">Rename Symbol</td>
          <td align="center">F2</td>
          <td align="right">editor.action.rename</td>
        </tr>
        <tr>
          <td align="left">Replace with Next Value</td>
          <td align="center">Ctrl+Shift+.</td>
          <td align="right">editor.action.inPlaceReplace.down</td>
        </tr>
        <tr>
          <td align="left">Replace with Previous Value</td>
          <td align="center">Ctrl+Shift+,</td>
          <td align="right">editor.action.inPlaceReplace.up</td>
        </tr>
        <tr>
          <td align="left">Expand AST Selection</td>
          <td align="center">Shift+Alt+Right</td>
          <td align="right">editor.action.smartSelect.expand</td>
        </tr>
        <tr>
          <td align="left">Shrink AST Selection</td>
          <td align="center">Shift+Alt+Left</td>
          <td align="right">editor.action.smartSelect.shrink</td>
        </tr>
        <tr>
          <td align="left">Trim Trailing Whitespace</td>
          <td align="center">Ctrl+K Ctrl+X</td>
          <td align="right">editor.action.trimTrailingWhitespace</td>
        </tr>
        <tr>
          <td align="left">Change Language Mode</td>
          <td align="center">Ctrl+K M</td>
          <td align="right">workbench.action.editor.changeLanguageMode</td>
        </tr>
      </tbody>
    </table>
    <h4 id="navigation">Navigation</h4>
    <table>
      <thead>
        <tr>
          <th align="left">
            <strong>Command</strong>
          </th>
          <th align="center">
            <strong>Key</strong>
          </th>
          <th align="right">
            <strong>Command id</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="left">Show All Symbols</td>
          <td align="center">Ctrl+T</td>
          <td align="right">workbench.action.showAllSymbols</td>
        </tr>
        <tr>
          <td align="left">Go to Line...</td>
          <td align="center">Ctrl+G</td>
          <td align="right">workbench.action.gotoLine</td>
        </tr>
        <tr>
          <td align="left">Go to File..., Quick Open</td>
          <td align="center">Ctrl+P</td>
          <td align="right">workbench.action.quickOpen</td>
        </tr>
        <tr>
          <td align="left">Go to Symbol...</td>
          <td align="center">Ctrl+Shift+O</td>
          <td align="right">workbench.action.gotoSymbol</td>
        </tr>
        <tr>
          <td align="left">Show Problems</td>
          <td align="center">Ctrl+Shift+M</td>
          <td align="right">workbench.actions.view.problems</td>
        </tr>
        <tr>
          <td align="left">Go to Next Error or Warning</td>
          <td align="center">F8</td>
          <td align="right">editor.action.marker.nextInFiles</td>
        </tr>
        <tr>
          <td align="left">Go to Previous Error or Warning</td>
          <td align="center">Shift+F8</td>
          <td align="right">editor.action.marker.prevInFiles</td>
        </tr>
        <tr>
          <td align="left">Show All Commands</td>
          <td align="center">Ctrl+Shift+P or F1</td>
          <td align="right">workbench.action.showCommands</td>
        </tr>
        <tr>
          <td align="left">Navigate Editor Group History</td>
          <td align="center">Ctrl+Tab</td>
          <td align="right">
            workbench.action.quickOpenPreviousRecentlyUsedEditorInGroup
          </td>
        </tr>
        <tr>
          <td align="left">Go Back</td>
          <td align="center">Alt+Left</td>
          <td align="right">workbench.action.navigateBack</td>
        </tr>
        <tr>
          <td align="left">Go back in Quick Input</td>
          <td align="center">Alt+Left</td>
          <td align="right">workbench.action.quickInputBack</td>
        </tr>
        <tr>
          <td align="left">Go Forward</td>
          <td align="center">Alt+Right</td>
          <td align="right">workbench.action.navigateForward</td>
        </tr>
        <tr>
          <td align="left">Focus Breadcrumbs</td>
          <td align="center">Ctrl+Shift+;</td>
          <td align="right">breadcrumbs.focus</td>
        </tr>
        <tr>
          <td align="left">Focus and Select Breadcrumbs</td>
          <td align="center">Ctrl+Shift+.</td>
          <td align="right">breadcrumbs.focusAndSelect</td>
        </tr>
      </tbody>
    </table>
    <h4 id="editorwindow-management">Editor/Window Management</h4>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Command</strong>
          </th>
          <th align="center">
            <strong>Key</strong>
          </th>
          <th align="center">
            <strong>Command id</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">New Window</td>
          <td align="center">Ctrl+Shift+N</td>
          <td align="center">workbench.action.newWindow</td>
        </tr>
        <tr>
          <td align="center">Close Window</td>
          <td align="center">Alt+F4</td>
          <td align="center">workbench.action.closeWindow</td>
        </tr>
        <tr>
          <td align="center">Close Editor</td>
          <td align="center">Ctrl+W</td>
          <td align="center">workbench.action.closeActiveEditor</td>
        </tr>
        <tr>
          <td align="center">Close Folder</td>
          <td align="center">Ctrl+K F</td>
          <td align="center">workbench.action.closeFolder</td>
        </tr>
        <tr>
          <td align="center">Cycle Between Editor Groups</td>
          <td align="center">unassigned</td>
          <td align="center">workbench.action.navigateEditorGroups</td>
        </tr>
        <tr>
          <td align="center">Split Editor</td>
          <td align="center">Ctrl+|workbench.action.splitEditor</td>
          <td align="center"></td>
        </tr>
        <tr>
          <td align="center">Focus into First Editor Group</td>
          <td align="center">Ctrl+1</td>
          <td align="center">workbench.action.focusFirstEditorGroup</td>
        </tr>
        <tr>
          <td align="center">Focus into Second Editor Group</td>
          <td align="center">Ctrl+2</td>
          <td align="center">workbench.action.focusSecondEditorGroup</td>
        </tr>
        <tr>
          <td align="center">Focus into Third Editor Group</td>
          <td align="center">Ctrl+3</td>
          <td align="center">workbench.action.focusThirdEditorGroup</td>
        </tr>
        <tr>
          <td align="center">Focus into Editor Group on the Left</td>
          <td align="center">unassigned</td>
          <td align="center">workbench.action.focusPreviousGroup</td>
        </tr>
        <tr>
          <td align="center">Focus into Editor Group on the Right</td>
          <td align="center">unassigned</td>
          <td align="center">workbench.action.focusNextGroup</td>
        </tr>
        <tr>
          <td align="center">Move Editor Left</td>
          <td align="center">Ctrl+Shift+PageUp</td>
          <td align="center">workbench.action.moveEditorLeftInGroup</td>
        </tr>
        <tr>
          <td align="center">Move Editor Right</td>
          <td align="center">Ctrl+Shift+PageDown</td>
          <td align="center">workbench.action.moveEditorRightInGroup</td>
        </tr>
        <tr>
          <td align="center">Move Active Editor Group Left</td>
          <td align="center">Ctrl+K Left</td>
          <td align="center">workbench.action.moveActiveEditorGroupLeft</td>
        </tr>
        <tr>
          <td align="center">Move Active Editor Group Right</td>
          <td align="center">Ctrl+K Right</td>
          <td align="center">workbench.action.moveActiveEditorGroupRight</td>
        </tr>
        <tr>
          <td align="center">Move Editor into Next Group</td>
          <td align="center">Ctrl+Alt+Right</td>
          <td align="center">workbench.action.moveEditorToNextGroup</td>
        </tr>
        <tr>
          <td align="center">Move Editor into Previous Group</td>
          <td align="center">Ctrl+Alt+Left</td>
          <td align="center">workbench.action.moveEditorToPreviousGroup</td>
        </tr>
      </tbody>
    </table>
    <h4 id="file-management">File Management</h4>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Command</strong>
          </th>
          <th align="center">
            <strong>Key</strong>
          </th>
          <th align="center">
            <strong>Command id</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">New File</td>
          <td align="center">Ctrl+N</td>
          <td align="center">workbench.action.files.newUntitledFile</td>
        </tr>
        <tr>
          <td align="center">Open File...</td>
          <td align="center">Ctrl+O</td>
          <td align="center">workbench.action.files.openFile</td>
        </tr>
        <tr>
          <td align="center">Save</td>
          <td align="center">Ctrl+S</td>
          <td align="center">workbench.action.files.save</td>
        </tr>
        <tr>
          <td align="center">Save All</td>
          <td align="center">Ctrl+K S</td>
          <td align="center">saveAll</td>
        </tr>
        <tr>
          <td align="center">Save As...</td>
          <td align="center">Ctrl+Shift+S</td>
          <td align="center">workbench.action.files.saveAs</td>
        </tr>
        <tr>
          <td align="center">Close</td>
          <td align="center">Ctrl+W</td>
          <td align="center">workbench.action.closeActiveEditor</td>
        </tr>
        <tr>
          <td align="center">Close Others</td>
          <td align="center">unassigned</td>
          <td align="center">workbench.action.closeOtherEditors</td>
        </tr>
        <tr>
          <td align="center">Close Group</td>
          <td align="center">Ctrl+K W</td>
          <td align="center">workbench.action.closeEditorsInGroup</td>
        </tr>
        <tr>
          <td align="center">Close Other Groups</td>
          <td align="center">unassigned</td>
          <td align="center">workbench.action.closeEditorsInOtherGroups</td>
        </tr>
        <tr>
          <td align="center">Close Group to Left</td>
          <td align="center">unassigned</td>
          <td align="center">workbench.action.closeEditorsToTheLeft</td>
        </tr>
        <tr>
          <td align="center">Close Group to Right</td>
          <td align="center">unassigned</td>
          <td align="center">workbench.action.closeEditorsToTheRight</td>
        </tr>
        <tr>
          <td align="center">Close All</td>
          <td align="center">Ctrl+K Ctrl+W</td>
          <td align="center">workbench.action.closeAllEditors</td>
        </tr>
        <tr>
          <td align="center">Reopen Closed Editor</td>
          <td align="center">Ctrl+Shift+T</td>
          <td align="center">workbench.action.reopenClosedEditor</td>
        </tr>
        <tr>
          <td align="center">Keep Open</td>
          <td align="center">Ctrl+K Enter</td>
          <td align="center">workbench.action.keepEditor</td>
        </tr>
        <tr>
          <td align="center">Copy Path of Active File</td>
          <td align="center">Ctrl+K P</td>
          <td align="center">workbench.action.files.copyPathOfActiveFile</td>
        </tr>
        <tr>
          <td align="center">Reveal Active File in Windows</td>
          <td align="center">Ctrl+K R</td>
          <td align="center">
            workbench.action.files.revealActiveFileInWindows
          </td>
        </tr>
        <tr>
          <td align="center">Show Opened File in New Window</td>
          <td align="center">unassigned</td>
          <td align="center">
            workbench.action.files.showOpenedFileInNewWindow
          </td>
        </tr>
        <tr>
          <td align="center">Compare Opened File With</td>
          <td align="center">unassigned</td>
          <td align="center">workbench.files.action.compareFileWith</td>
        </tr>
      </tbody>
    </table>
    <h4 id="display">Display</h4>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Command</strong>
          </th>
          <th align="center">
            <strong>Key</strong>
          </th>
          <th align="center">
            <strong>Command id</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">Toggle Full Screen</td>
          <td align="center">F11</td>
          <td align="center">workbench.action.toggleFullScreen</td>
        </tr>
        <tr>
          <td align="center">Toggle Zen Mode</td>
          <td align="center">Ctrl+K Z</td>
          <td align="center">workbench.action.toggleZenMode</td>
        </tr>
        <tr>
          <td align="center">Leave Zen Mode</td>
          <td align="center">Escape Escape</td>
          <td align="center">workbench.action.exitZenMode</td>
        </tr>
        <tr>
          <td align="center">Zoom in</td>
          <td align="center">Ctrl+=</td>
          <td align="center">workbench.action.zoomIn</td>
        </tr>
        <tr>
          <td align="center">Zoom out</td>
          <td align="center">Ctrl+-</td>
          <td align="center">workbench.action.zoomOut</td>
        </tr>
        <tr>
          <td align="center">Reset Zoom</td>
          <td align="center">Ctrl+Numpad0</td>
          <td align="center">workbench.action.zoomReset</td>
        </tr>
        <tr>
          <td align="center">Toggle Sidebar Visibility</td>
          <td align="center">Ctrl+B</td>
          <td align="center">workbench.action.toggleSidebarVisibility</td>
        </tr>
        <tr>
          <td align="center">Show Explorer / Toggle Focus</td>
          <td align="center">Ctrl+Shift+E</td>
          <td align="center">workbench.view.explorer</td>
        </tr>
        <tr>
          <td align="center">Show Search</td>
          <td align="center">Ctrl+Shift+F</td>
          <td align="center">workbench.view.search</td>
        </tr>
        <tr>
          <td align="center">Show Source Control</td>
          <td align="center">Ctrl+Shift+G</td>
          <td align="center">workbench.view.scm</td>
        </tr>
        <tr>
          <td align="center">Show Run</td>
          <td align="center">Ctrl+Shift+D</td>
          <td align="center">workbench.view.debug</td>
        </tr>
        <tr>
          <td align="center">Show Extensions</td>
          <td align="center">Ctrl+Shift+X</td>
          <td align="center">workbench.view.extensions</td>
        </tr>
        <tr>
          <td align="center">Show Output</td>
          <td align="center">Ctrl+Shift+U</td>
          <td align="center">workbench.action.output.toggleOutput</td>
        </tr>
        <tr>
          <td align="center">Quick Open View</td>
          <td align="center">Ctrl+Q</td>
          <td align="center">workbench.action.quickOpenView</td>
        </tr>
        <tr>
          <td align="center">Open New Command Prompt</td>
          <td align="center">Ctrl+Shift+C</td>
          <td align="center">workbench.action.terminal.openNativeConsole</td>
        </tr>
        <tr>
          <td align="center">Toggle Markdown Preview</td>
          <td align="center">Ctrl+Shift+V</td>
          <td align="center">markdown.showPreview</td>
        </tr>
        <tr>
          <td align="center">Open Preview to the Side</td>
          <td align="center">Ctrl+K V</td>
          <td align="center">markdown.showPreviewToSide</td>
        </tr>
        <tr>
          <td align="center">Toggle Integrated Terminal</td>
          <td align="center">Ctrl+`</td>
          <td align="center">workbench.action.terminal.toggleTerminal</td>
        </tr>
      </tbody>
    </table>
    <h4 id="search">Search</h4>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Command</strong>
          </th>
          <th align="center">
            <strong>Key</strong>
          </th>
          <th align="center">
            <strong>Command id</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">Show Search</td>
          <td align="center">Ctrl+Shift+F</td>
          <td align="center">workbench.view.search</td>
        </tr>
        <tr>
          <td align="center">Replace in Files</td>
          <td align="center">Ctrl+Shift+H</td>
          <td align="center">workbench.action.replaceInFiles</td>
        </tr>
        <tr>
          <td align="center">Toggle Match Case</td>
          <td align="center">Alt+C</td>
          <td align="center">toggleSearchCaseSensitive</td>
        </tr>
        <tr>
          <td align="center">Toggle Match Whole Word</td>
          <td align="center">Alt+W</td>
          <td align="center">toggleSearchWholeWord</td>
        </tr>
        <tr>
          <td align="center">Toggle Use Regular Expression</td>
          <td align="center">Alt+R</td>
          <td align="center">toggleSearchRegex</td>
        </tr>
        <tr>
          <td align="center">Toggle Search Details</td>
          <td align="center">Ctrl+Shift+J</td>
          <td align="center">workbench.action.search.toggleQueryDetails</td>
        </tr>
        <tr>
          <td align="center">Focus Next Search Result</td>
          <td align="center">F4</td>
          <td align="center">search.action.focusNextSearchResult</td>
        </tr>
        <tr>
          <td align="center">Focus Previous Search Result</td>
          <td align="center">Shift+F4</td>
          <td align="center">search.action.focusPreviousSearchResult</td>
        </tr>
        <tr>
          <td align="center">Show Next Search Term</td>
          <td align="center">Down</td>
          <td align="center">history.showNext</td>
        </tr>
        <tr>
          <td align="center">Show Previous Search Term</td>
          <td align="center">Up</td>
          <td align="center">history.showPrevious</td>
        </tr>
      </tbody>
    </table>
    <h4 id="search-editor">Search Editor</h4>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Command</strong>
          </th>
          <th align="center">
            <strong>Key</strong>
          </th>
          <th align="center">
            <strong>Command id</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">Open Results In Editor</td>
          <td align="center">Alt+Enter</td>
          <td align="center">search.action.openInEditor</td>
        </tr>
        <tr>
          <td align="center">Focus Search Editor Input</td>
          <td align="center">Escape</td>
          <td align="center">search.action.focusQueryEditorWidget</td>
        </tr>
        <tr>
          <td align="center">Search Again</td>
          <td align="center">Ctrl+Shift+R</td>
          <td align="center">rerunSearchEditorSearch</td>
        </tr>
        <tr>
          <td align="center">Delete File Results</td>
          <td align="center">Ctrl+Shift+Backspace</td>
          <td align="center">search.searchEditor.action.deleteFileResults</td>
        </tr>
      </tbody>
    </table>
    <h4 id="preferences">Preferences</h4>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Command</strong>
          </th>
          <th align="center">
            <strong>Key</strong>
          </th>
          <th align="center">
            <strong>Command id</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">Open Settings</td>
          <td align="center">Ctrl+,</td>
          <td align="center">workbench.action.openSettings</td>
        </tr>
        <tr>
          <td align="center">Open Workspace Settings</td>
          <td align="center">unassigned</td>
          <td align="center">workbench.action.openWorkspaceSettings</td>
        </tr>
        <tr>
          <td align="center">Open Keyboard Shortcuts</td>
          <td align="center">Ctrl+K Ctrl+S</td>
          <td align="center">workbench.action.openGlobalKeybindings</td>
        </tr>
        <tr>
          <td align="center">Open User Snippets</td>
          <td align="center">unassigned</td>
          <td align="center">workbench.action.openSnippets</td>
        </tr>
        <tr>
          <td align="center">Select Color Theme</td>
          <td align="center">Ctrl+K Ctrl+T</td>
          <td align="center">workbench.action.selectTheme</td>
        </tr>
        <tr>
          <td align="center">Configure Display Language</td>
          <td align="center">unassigned</td>
          <td align="center">workbench.action.configureLocale</td>
        </tr>
      </tbody>
    </table>
    <h4 id="debug">Debug</h4>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Command</strong>
          </th>
          <th align="center">
            <strong>Key</strong>
          </th>
          <th align="center">
            <strong>Command id</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">Toggle Breakpoint</td>
          <td align="center">F9</td>
          <td align="center">editor.debug.action.toggleBreakpoint</td>
        </tr>
        <tr>
          <td align="center">Start</td>
          <td align="center">F5</td>
          <td align="center">workbench.action.debug.start</td>
        </tr>
        <tr>
          <td align="center">Continue</td>
          <td align="center">F5</td>
          <td align="center">workbench.action.debug.continue</td>
        </tr>
        <tr>
          <td align="center">Start &#40;without debugging&#41;</td>
          <td align="center">Ctrl+F5</td>
          <td align="center">workbench.action.debug.run</td>
        </tr>
        <tr>
          <td align="center">Pause</td>
          <td align="center">F6</td>
          <td align="center">workbench.action.debug.pause</td>
        </tr>
        <tr>
          <td align="center">Step Into</td>
          <td align="center">F11</td>
          <td align="center">workbench.action.debug.stepInto</td>
        </tr>
      </tbody>
    </table>
    <h4 id="tasks">Tasks</h4>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Command</strong>
          </th>
          <th align="center">
            <strong>Key</strong>
          </th>
          <th align="center">
            <strong>Command id</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">Run Build Task</td>
          <td align="center">Ctrl+Shift+B</td>
          <td align="center">workbench.action.tasks.build</td>
        </tr>
        <tr>
          <td align="center">Run Test Task</td>
          <td align="center">unassigned</td>
          <td align="center">workbench.action.tasks.test</td>
        </tr>
      </tbody>
    </table>
    <h4 id="extensions">Extensions</h4>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Command</strong>
          </th>
          <th align="center">
            <strong>Key</strong>
          </th>
          <th align="center">
            <strong>Command id</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">Install Extension</td>
          <td align="center">unassigned</td>
          <td align="center">workbench.extensions.action.installExtension</td>
        </tr>
        <tr>
          <td align="center">Show Installed Extensions</td>
          <td align="center">unassigned</td>
          <td align="center">
            workbench.extensions.action.showInstalledExtensions
          </td>
        </tr>
        <tr>
          <td align="center">Show Outdated Extensions</td>
          <td align="center">unassigned</td>
          <td align="center">
            workbench.extensions.action.listOutdatedExtensions
          </td>
        </tr>
        <tr>
          <td align="center">Show Recommended Extensions</td>
          <td align="center">unassigned</td>
          <td align="center">
            workbench.extensions.action.showRecommendedExtensions
          </td>
        </tr>
        <tr>
          <td align="center">Show Popular Extensions</td>
          <td align="center">unassigned</td>
          <td align="center">
            workbench.extensions.action.showPopularExtensions
          </td>
        </tr>
        <tr>
          <td align="center">Update All Extensions</td>
          <td align="center">unassigned</td>
          <td align="center">
            workbench.extensions.action.updateAllExtensions
          </td>
        </tr>
      </tbody>
    </table>
    <h3 id="next-steps">Next steps</h3>
    <p>Now that you know about our Key binding support, what&#39;s next...</p>
    <ul>
      <li>
        Language Support - Our Good, Better, Best language grid to see what you
        can expect
      </li>
      <li>Debugging - This is where AVAP™ DS really shines</li>
      <li>Node.js - End to end Node.js scenario with a sample app</li>
    </ul>
    <h3 id="common-questions">Common questions</h3>
    <h4 id="how-can-i-find-out-what-command-is-bound-to-a-specific-key">
      How can I find out what command is bound to a specific key?
    </h4>
    <p>
      In the <strong>Keyboard Shortcut</strong> editor, you can filter on
      specific keystrokes to see which commands are bound to which keys. Below
      you can see that Ctrl+Shift+P is bound to{' '}
      <strong>Show All Commands</strong> to bring up the Command Palette.
    </p>
    <h4 id="how-to-add-a-key-binding-to-an-action-for-example-add-ctrld-to-delete-lines">
      How to add a key binding to an action, for example, add Ctrl+D to Delete
      Lines
    </h4>
    <p>
      Find a rule that triggers the action in the{' '}
      <strong>Default Keyboard Shortcuts</strong> and write a modified version
      of it in your keybindings.json file:
    </p>

    <pre className="">
      <code className="">
        // Original, in Default Keyboard Shortcuts
        <br />
        &#123; "key": "ctrl+shift+k", "command": "editor.action.deleteLines",
        <br /> "when": "editorTextFocus" &#125;,
        <br />
        // Modified, in User/keybindings.json, Ctrl+D now will also trigger this
        action
        <br />
        &#123; "key": "ctrl+d", "command": "editor.action.deleteLines",
        <br /> "when": "editorTextFocus" &#125;,
      </code>
    </pre>
    <h4 id="how-can-i-add-a-key-binding-for-only-certain-file-types">
      How can I add a key binding for only certain file types?
    </h4>
    <p>Use the editorLangId context key in your when clause:</p>

    <pre className="">
      <code className="">
        &#123; "key": "shift+alt+a", "command": "editor.action.blockComment",
        <br /> "when": "editorTextFocus &amp;&amp; editorLangId == csharp"
        &#125;,
      </code>
    </pre>
    <h4 id="i-have-modified-my-key-bindings-in-keybindingsjson-why-dont-they-work">
      I have modified my key bindings in keybindings.json; why don&#39;t they
      work?
    </h4>
    <p>
      The most common problem is a syntax error in the file. Otherwise, try
      removing the when clause or picking a different key. Unfortunately, at
      this point, it is a trial and error process.
    </p>
  </Fragment>
);
export default KeyBindings;
