import React, { Fragment } from 'react';

const TERMINAL_Advanced = () => (
  <Fragment>
    <h1 id="terminal-advanced">Terminal Advanced</h1>
    <p>
      AVAP<sup>TM</sup> Dev Studio&#39;s integrated terminal has many advanced
      features and settings, such as Unicode and emoji support, custom
      keybindings, and automatic replies. This topic explains these advanced
      features in detail. If you are new to AVAP<sup>TM</sup> Dev Studio or the
      integrated terminal, you may want to review the{' '}
      <a href="/docs/terminal/basics">Terminal Basics</a> topic first.
    </p>
    <h2 id="persistent-sessions">Persistent sessions</h2>
    <p>The terminal supports two different types of persistent sessions:</p>
    <ul>
      <li>
        Process reconnection: When reloading a window &#40;for example, after
        installing an extension&#41;, <strong>reconnect</strong> to the previous
        process and restore its content.
      </li>
      <li>
        Process revive: When restarting AVAP<sup>TM</sup> Dev Studio, a
        terminal&#39;s content is restored and the process is{' '}
        <strong>relaunched</strong> using its original environment.
      </li>
    </ul>
    <p>
      Both of these persistent sessions can be disabled by setting
      terminal.integrated.enablePersistentSessions to false, and the amount of
      scrollback restored is controlled by
      theterminal.integrated.persistentSessionScrollback setting. Process revive
      can be configured independently with
      terminal.integrated.persistentSessionReviveProcess.
    </p>
    <h3 id="moving-terminals-between-windows">
      Moving terminals between windows
    </h3>
    <p>
      Terminal tabs can be dragged and dropped between AVAP<sup>TM</sup> Dev
      Studio windows. This can also be done manually through the Command Palette
      and the <strong>Terminal: Detach Session</strong> and{' '}
      <strong>Terminal: Attach to Session</strong> commands.
    </p>
    <h3 id="configure-how-the-terminal-behaves-on-start-up">
      Configure how the terminal behaves on start up
    </h3>
    <p>
      When opening a window, if the terminal view is visible it will either
      reconnect to the terminal using persistent sessions, or create a new
      shell. This behavior can be fine tuned with the
      terminal.integrated.hideOnStartup setting.
    </p>
    <ul>
      <li>never &#40;default&#41;: Never hide the terminal view on startup.</li>
      <li>
        whenEmpty: Only hide the terminal when there are no persistent sessions
        restored.
      </li>
      <li>
        always: Always hide the terminal, even when there are persistent
        sessions restored.
      </li>
    </ul>
    <h2 id="keybinding-and-the-shell">Keybinding and the shell</h2>
    <p>
      As an embedded application, the integrated terminal should intercept some,
      but not all, keybindings dispatched within AVAP<sup>TM</sup> Dev Studio.
    </p>
    <p>
      The configurable terminal.integrated.commandsToSkipShell setting
      determines which command&#39;s keybindings should always &quot;skip the
      shell&quot; and instead be handled by AVAP<sup>TM</sup> Dev Studio&#39;s
      keybinding system. By default, it contains a hard-coded list of commands
      that are integral to the AVAP<sup>TM</sup> Dev Studio experience but you
      can add or remove specific commands:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"terminal.integrated.commandsToSkipShell": [<br />` `// Ensure
        the toggle sidebar visibility keybinding skips the shell
        <br />` `"workbench.action.toggleSidebarVisibility",
        <br />` `// Send quick open's keybinding to the shell
        <br />` `"-workbench.action.quickOpen",
        <br />` `]
        <br />
        &#125;
      </code>
    </pre>
    <p>
      Look at the terminal.integrated.commandsToSkipShell setting details to see
      the complete list of default commands.
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> terminal.integrated.sendKeybindingsToShell can be
        configured to override terminal.integrated.commandsToSkipShell and
        dispatch most keybindings to the shell. Note that this will disable
        keybindings like Ctrl+F to open{' '}
        <a href="/docs/terminal/basics#find">find</a> though.
      </p>
    </blockquote>
    <h3 id="chords">Chords</h3>
    <p>
      Chord keybindings are made up of two keybindings, for example Ctrl+K
      followed by Ctrl+C to change the line to a comment. Chords always skip the
      shell by default but can be disabled with terminal.integrated.allowChords.
    </p>
    <h3 id="macos-clear-screen">macOS clear screen</h3>
    <p>
      On macOS, Cmd+K is a common keybindings in terminals to clear the screen
      so AVAP<sup>TM</sup> Dev Studio also respects that, which means Cmd+K
      chords will not work. Cmd+K chords can be enabled by{' '}
      <a href="/docs/getstarted/keybindings#_removing-a-specific-key-binding-rule">
        removing the clear keybinding
      </a>
      :
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"key": "cmd+k",
        <br />` `"command": "-workbench.action.terminal.clear"
        <br />
        &#125;
      </code>
    </pre>
    <p>
      Additionally, this keyboard shortcut will be overridden automatically if
      any extensions contribute Cmd+K keybindings due to how keybinding priority
      works. To re-enable the Cmd+K clear keybinding in this case, you can
      redefine it in user keybindings, which have a higher priority than
      extension keybindings:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"key": "cmd+k",
        <br />` `"command": "workbench.action.terminal.clear",
        <br />` `"when": "terminalFocus &amp;&amp; terminalHasBeenCreated ||
        terminalFocus &amp;&amp; terminalProcessSupported"
        <br />
        &#125;
      </code>
    </pre>
    <h3 id="mnemonics">Mnemonics</h3>
    <p>
      Using mnemonics to access AVAP<sup>TM</sup> Dev Studio&#39;s menu &#40;for
      example, Alt+F for File menu&#41; is disabled by default in the terminal
      as these key events are often important hotkeys in shells. Set
      terminal.integrated.allowMnemonics to enable mnemonics, but note that this
      will disallow any Alt key events to go to the shell. This setting does
      nothing on macOS.
    </p>
    <h3 id="custom-sequence-keybindings">Custom sequence keybindings</h3>
    <p>
      The workbench.action.terminal.sendSequence command can be used to send a
      specific sequence of text to the terminal, including escape sequences that
      are interpreted specially by the shell. The command enables you to send
      Arrow keys, Enter, cursor moves, etc.
    </p>
    <p>
      For example, the sequence below jumps over the word to the left of the
      cursor &#40;Ctrl+Left&#41; and then presses Backspace:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"key": "ctrl+u",
        <br />` `"command": "workbench.action.terminal.sendSequence",
        <br />` `"args": &#123;
        <br />` `"text": "\u001b[1;5D\u007f"
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>
      This feature supports{' '}
      <a href="/docs/editor/variables-reference">variable substitution</a>.
    </p>
    <p>
      The sendSequence command only works with the \u0000 format for using
      characters via their character code &#40;not \x00&#41;. Read more about
      these hex codes and terminal sequences in the following resources:
    </p>
    <ul>
      <li>
        <a href="https://invisible-island.net/xterm/ctlseqs/ctlseqs.html">
          XTerm Control Sequences
        </a>
      </li>
      <li>
        <a href="https://github.com/xtermjs/xterm.js/blob/0e45909c7e79c83452493d2cd46d99c0a0bb585f/src/common/data/EscapeSequences.ts">
          List of C0 and C1 control codes
        </a>
      </li>
    </ul>
    <h2 id="confirmation-dialogs">Confirmation dialogs</h2>
    <p>
      In order to avoid unnecessary output and user prompts, the terminal does
      not show warning dialogs when processes exit. If warnings are desirable,
      they can be configured with the following settings:
    </p>
    <ul>
      <li>
        terminal.integrated.confirmOnExit - Controls whether to confirm when the
        window closes if there are active debug sessions.
      </li>
      <li>
        terminal.integrated.confirmOnKill - Controls whether to confirm killing
        terminals when they have child processes.
      </li>
      <li>
        terminal.integrated.showExitAlert - Controls whether to show the alert
        &quot;The terminal process terminated with exit code&quot; when exit
        code is non-zero.
      </li>
    </ul>
    <h2 id="auto-replies">Auto replies</h2>
    <p>
      The terminal can automatically provide a configurable input response to
      the shell if an exact sequence of output is received. The most common use
      case is to automatically reply to a prompt when hitting Ctrl+C in batch
      scripts that ask whether the user wants to terminate the batch job. To
      automatically dismiss this message, add this setting:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"terminal.integrated.autoReplies": &#123;
        <br />` `"Terminate batch job &#40;Y/N&#41;": "Y\r"
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>
      Notice that the \r character used here means Enter, and much like{' '}
      <a href="#_custom-sequence-keybindings">custom sequence keybindings</a>,
      this feature supports sending escape sequences to the shell.
    </p>
    <p>
      No auto replies are configured by default as providing shell input should
      be an explicit action or configuration by the user.
    </p>
    <h2 id="change-tab-stop-width">Change tab stop width</h2>
    <p>
      The terminal.integrated.tabStopWidth setting allows configuring the tab
      stop width when a program running in the terminal outputs \t. This should
      typically not be needed as programs will often move the cursor instead of
      using the Tab character, but may be useful in some situations.
    </p>
    <h2 id="unicode-and-emoji-support">Unicode and emoji support</h2>
    <p>
      The terminal has both Unicode and emoji support. When these characters are
      used in the terminal, there are some caveats to that support:
    </p>
    <ul>
      <li>
        Some Unicode symbols have ambiguous width that may change between
        Unicode versions. Currently we support Unicode version 6 and 11 widths,
        which can be configured with the terminal.integrated.unicodeVersion
        setting. The version specified should match the Unicode version used by
        the shell/operating system, otherwise there could be rendering issues.
        Note that the Unicode version of the shell/OS may not match the
        font&#39;s actual width.
      </li>
      <li>
        Some emojis comprised of multiple characters may not render correctly,
        for example, skin tone modifiers.
      </li>
      <li>Emoji support is limited on Windows.</li>
    </ul>
    <h2 id="image-support">Image support</h2>
    <p>
      Images in the terminal work provided they use either the Sixel or iTerm
      inline image protocols. This feature is disabled by default and can be
      enabled with the terminal.integrated.enableImages setting.
    </p>
    <p>Current limitations:</p>
    <ul>
      <li>
        Serialization does not work, so reloading a terminal will not retain any
        images &#40;
        <a href="https://github.com/jerch/xterm-addon-image/issues/47">
          jerch/xterm-addon-image#47
        </a>
        &#41;.
      </li>
      <li>
        Copying the selection as HTML does not include the selected image &#40;
        <a href="https://github.com/jerch/xterm-addon-image/issues/50">
          jerch/xterm-addon-image#50
        </a>
        &#41;.
      </li>
      <li>
        Animated gifs don&#39;t work &#40;
        <a href="https://github.com/jerch/xterm-addon-image/issues/51">
          jerch/xterm-addon-image#51
        </a>
        &#41;.
      </li>
      <li>
        Images that are shorter than a cell will not work properly, this is a{' '}
        <a href="https://github.com/microsoft/vscode/issues/183840#issuecomment-1569345048">
          design flaw with the sequences and also occurs in XTerm
        </a>
        .
      </li>
    </ul>
    <h2 id="process-environment">Process environment</h2>
    <p>
      The process environment of the application running within the terminal is
      influenced by various settings and extensions and can cause the output in
      the AVAP<sup>TM</sup> Dev Studio terminal to look different than in other
      terminals.
    </p>
    <h3 id="environment-inheritance">Environment inheritance</h3>
    <p>
      When AVAP<sup>TM</sup> Dev Studio is opened, it launches a login shell
      environment in order to source a shell environment. This is done because
      developer tools are often added to the $PATH in a shell launch script like
      ~/.bash_profile. By default, the terminal inherits this environment,
      depending on your{' '}
      <a href="/docs/terminal/profiles#_configuring-profiles">
        profile shell arguments
      </a>
      , and means that multiple profile scripts may have run, which could cause
      unexpected behavior.
    </p>
    <p>
      This environment inheritance can be disabled on macOS and Linux via the
      terminal.integrated.inheritEnv setting.
    </p>
    <h3 id="interaction-with-lang">Interaction with $LANG</h3>
    <p>
      There is some special interaction with the $LANG environment variable,
      which determines how characters are presented in the terminal. This
      feature is configured with the terminal.integrated.detectLocale setting:
    </p>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Value</strong>
          </th>
          <th align="center">
            <strong>Behavior</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">on</td>
          <td align="center">
            Always set $LANG to the most commonly desired value. The chosen
            value is based on the operating system locale &#40;falling back to
            en-US&#41; with UTF-8 encoding.
          </td>
        </tr>
        <tr>
          <td align="center">auto &#40;default&#41;</td>
          <td align="center">
            Set $LANG similar to the on behavior if $LANG is not properly
            configured &#40;is not set to a UTF or EUC encoding&#41;.
          </td>
        </tr>
        <tr>
          <td align="center">off</td>
          <td align="center">Do not modify $LANG.</td>
        </tr>
      </tbody>
    </table>
    <h3 id="extension-environment-contributions">
      Extension environment contributions
    </h3>
    <p>
      Extensions are able to{' '}
      <a href="https://code.visualstudio.com/api/references/vscode-api#ExtensionContext.environmentVariableCollection">
        contribute to terminal environments
      </a>
      , allowing them to provide some integration with the terminal. For
      example, the built-in Git extension injects the GIT_ASKPASS environment
      variable to allow AVAP<sup>TM</sup> Dev Studio to handle authentication to
      a Git remote.
    </p>
    <p>
      If an extension changes the terminal environment, any existing terminals
      will be relaunched if it is safe to do so, otherwise a warning will show
      in the terminal status. More information about the change can be viewed in
      the hover, which also includes a relaunch button.
    </p>
    <h2 id="windows-and-conpty">Windows and ConPTY</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio&#39;s terminal is built on the{' '}
      <a href="https://github.com/xtermjs/xterm.js">xterm.js</a> project to
      implement a Unix-style terminal that serializes all data into a string and
      pipes it through a &quot;pseudoterminal&quot;. Historically, this was not
      how the terminal worked on Windows, which used the{' '}
      <a href="https://learn.microsoft.com/windows/console/console-functions">
        Console API
      </a>{' '}
      to implement its console called &#39;conhost&#39;.
    </p>
    <p>
      An open source project called{' '}
      <a href="https://github.com/rprichard/winpty">winpty</a> was created to
      try to fix this issue by providing an emulation/translation layer between
      a Unix-style terminal and a Windows console. AVAP<sup>TM</sup> Dev
      Studio&#39;s terminal was originally implemented using only winpty. This
      was great at the time, but in 2018, Windows 10 received{' '}
      <a href="https://devblogs.microsoft.com/commandline/windows-command-line-introducing-the-windows-pseudo-console-conpty/">
        the ConPTY API
      </a>
      , which took the idea pioneered by winpty and baked it into Windows,
      providing a more reliable and supported system to leverage Unix-style
      terminals and apps on Windows.
    </p>
    <p>
      AVAP<sup>TM</sup> Dev Studio defaults to ConPTY on Windows 10+ &#40;from
      build number 18309&#41; and falls back to winpty as a legacy option for
      older versions of Windows. ConPTY can be explicitly disabled via the
      terminal.integrated.windowsEnableConpty settings but this should normally
      be avoided.
    </p>
    <p>
      Since ConPTY is an emulation layer, it does come with some quirks. The
      most common is that ConPTY considers itself the owner of the viewport and
      because of that will sometimes reprint the screen. This reprinting can
      cause unexpected behavior such as old content displaying after running the{' '}
      <strong>Terminal: Clear</strong> command.
    </p>
    <h2 id="remote-development">Remote development</h2>
    <p>
      This section outlines topics specific to when AVAP<sup>TM</sup> Dev Studio
      is connected to a remote machine using a AVAP<sup>TM</sup> Dev Studio{' '}
      <a href="https://code.visualstudio.com/docs/remote/remote-overview">
        Remote Development
      </a>{' '}
      extension.
    </p>
    <h3 id="reducing-remote-input-latency">Reducing remote input latency</h3>
    <p>
      Local echo is a feature that helps mitigate the effect of input latency on
      remote windows. It writes the keystrokes in the terminal in a dimmed color
      before the result is confirmed by the remote. By default, the feature
      start running when latency is detected to be above 30 ms and the timing
      can be configured with terminal.integrated.localEchoLatencyThreshold. The
      color of the unconfirmed characters is defined by
      terminal.integrated.localEchoStyle.
    </p>
    <p>
      Local echo disables itself dynamically depending on the active program in
      the terminal. This is controlled by
      terminal.integrated.localEchoExcludePrograms, which defaults to
      [&#39;vim&#39;, &#39;vi&#39;, &#39;nano&#39;, &#39;tmux&#39;]. It&#39;s
      recommended that you disable the feature for any application or shell that
      is highly dynamic and/or does a lot of reprinting of the screen when
      typing.
    </p>
    <p>To disable the feature completely, use:</p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"terminal.integrated.localEchoEnabled": false
        <br />
        &#125;
      </code>
    </pre>
    <h3 id="local-terminals-in-remote-windows">
      Local terminals in remote windows
    </h3>
    <p>
      The default <strong>local</strong> terminal profile can be launched in
      remote windows with the{' '}
      <strong>Terminal: Create New Integrated Terminal &#40;Local&#41;</strong>{' '}
      command via the Command Palette. Currently non-default profiles cannot be
      launched from remote windows.
    </p>
  </Fragment>
);
export default TERMINAL_Advanced;
