import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import icon_name from "../../../assets/img/newDashboard/icon_name.png";
import icono_birthday from "../../../assets/img/newDashboard/icono_birthday.png";
import icono_country from "../../../assets/img/newDashboard/icono_country.png";
import icono_language from "../../../assets/img/newDashboard/icono_language.png";
import moment from 'moment';
import "./PersonalInformation.css";
import "./../NewDashboard.css"
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import { Button, Modal, Toast } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { UpdatedName } from '../../../redux/slices/updateSidebarGreen';
// import { isUpdatedName } from '../../../redux/slices/updateSidebarGreen';

const PersonalInformation = () => {
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [days, setDays] = useState([]);
  const [locality, setLocality] = useState('');
  const [language_id, setLanguageId] = useState(0);
  const [day, setDay] = useState(0);
  const [years, setYears] = useState([]);
  const [year, setYear] = useState(moment().year());
  const [month, setMonth] = useState(1);
  const [first_name, setFirstName] = useState('');
  const [middle_name, setMiddleName] = useState('');
  const [last_name, setLastName] = useState('');
  const [newInfo, setNewInfo] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [whereWantGo, setWhereWantGo] = useState("");

  const dispatch = useDispatch();  

  const toggleShowToast = () => setShowToast(!showToast);

  const countriesList = countries.map(c => <option value={c.country_code} selected={locality === c.country_code}>{c.country}</option>);
  const languagesList = languages.map(c => c.id === 2 ? <option value={c.id} selected={language_id === c.id}>{c.language}</option>:<></>);
  const daysList = days.map(c => <option value={c} selected={day === c}>{c}</option>);
  const yearsList = years.map(c => <option value={c} selected={year === c}>{c}</option>);

  const { path, url } = useRouteMatch();
  const history = useHistory();

  const arrayRange = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    );

  const getPersonalInformation = async () => {
    try {
      const result = (await ObexRequestHandler.get('/get_personal_info')).data;
      const date_ = new Date(result.birthdate);
      
      setDay(date_.getDate());
      setMonth(date_.getMonth() + 1);
      setYear(date_.getFullYear());
      setLanguageId(result.language);
      setLocality(result.region);
      setFirstName(result.first_name ? result.first_name : "");
      setMiddleName(result.middle_name ? result.middle_name : "");
      setLastName(result.last_name ? result.last_name : "");
    } catch (error) {
      console.error('ERROR FETCHING PERSONAL INFO', error);
    }
  };

  const postNewInfo = async (newName, newMiddle, newLastName) => {
    try {
      const result = (await ObexRequestHandler.post('/set_name_info', {
        first_name: newName,
        middle_name: newMiddle,
        last_name: newLastName
      })).data;
      setNewInfo(result);
    } catch (error) {
      console.error('ERROR', error);
    }
  };

  const getLanguages = async () => {
    try {
      const result = (await ObexRequestHandler.get('/get_languages')).data;
      setLanguages(result);
    } catch (error) {
      console.error('ERROR FETCHING LANGUAGES', error);
    }
  };

  const getCountries = async () => {
    try {
      const result = (await ObexRequestHandler.get('/countries')).data;
      setCountries(result);
    } catch (error) {
      console.error('ERROR FETCHING COUNTRIES', error);
    }
  };

  const getDays = (manual_month = null, manual_year = null) => {
    if (manual_month === null) {
      manual_month = month;
    }
    if (manual_year === null) {
      manual_year = year;
    }
    let moment_str = manual_year + "-" + manual_month;
    var maxDays = moment(moment_str, "YYYY-MM").daysInMonth();
    let days_array = arrayRange(1, maxDays, 1);
    setDays(days_array);
  };

  const getYears = async () => {
    let years_array = arrayRange(moment().year(), 1900, -1);
    setYears(years_array);
  };

  const updateBirhtdate = async (day_ = day, month_ = month, year_ = year) => {
    try {
      var date_str = year_ + "-" + month_ + "-" + day_;
      const payload = { birthdate: date_str };
      await ObexRequestHandler.post('/set_birthday', payload);
    } catch (error) {
      alert('ERROR CHANGING BIRTHDATE');
      console.error('ERROR CHANGING BIRTHDATE', error);
    }
  };

  const updateCountry = async (country_) => {
    try {
      const payload = { region: country_ };
      await ObexRequestHandler.post('/set_region', payload);
    } catch (error) {
      alert('ERROR CHANGING REGION');
      console.error('ERROR CHANGING REGION', error);
    }
  };

  const updateLanguage = async (language_) => {
    try {
      const payload = { language_id: language_ };
      await ObexRequestHandler.post('/set_language', payload);
    } catch (error) {
      alert('ERROR CHANGING LANGUAGE');
      console.error('ERROR CHANGING LANGUAGE', error);
    }
  };

  const handleCountry = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    setLocality(value);
    setShowToast(true);
    setShouldBlockNavigation(true)
    
  };

  const handleLanguage = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    setLanguageId(value);
    setShowToast(true);
    setShouldBlockNavigation(true)
  };

  const handleDay = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    setShowToast(true);
    setShouldBlockNavigation(true)
    setDay(value);
  };

  const handleMonth = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    await setMonth(value);
    getDays(value);
    setShowToast(true);
    setShouldBlockNavigation(true)
  };

  const handleYear = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    await setYear(value);
    getDays(month, value);
    setShowToast(true);
    setShouldBlockNavigation(true)
  };

  const handleFirstNameChange = (e) => {
    const newValue = e.target.value;
    setShowToast(true);
    setShouldBlockNavigation(true)
    setFirstName(newValue);
  };

  const handleMiddleNameChange = (e) => {
    const newValue = e.target.value;
    setShowToast(true);
    setShouldBlockNavigation(true)
    setMiddleName(newValue);
  };

  const handleLastNameChange = (e) => {
    const newValue = e.target.value;
    setShowToast(true);
    setShouldBlockNavigation(true)
    setLastName(newValue);
  };

   const applyChanges = () => {
    
    postNewInfo(first_name, middle_name, last_name);
    updateBirhtdate(day, month, year);
    updateCountry(locality);
    updateLanguage(language_id);
    setShowToast(false);
    setShouldBlockNavigation(false)
    dispatch(UpdatedName(true));
  };

  const NoapplyChanges = async() => {
    
    setShowModal(false); // Ocultar el modal
    setShowToast(false);
    await setShouldBlockNavigation(false)
  };

  const OutOfInputWithEnter = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };


  const handleConfirmNavigation = async () => {
    // debugger
    setShowModal(false); // Ocultar el modal
    setShowToast(false);
    await setShouldBlockNavigation(false)
    history.push(whereWantGo);
  };



  useEffect(() => {
    getYears();
    getCountries();
    getLanguages();
    getDays();
    getPersonalInformation();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Verificar si se debe bloquear la navegación
    // debugger
    if (shouldBlockNavigation==true) {
      // Bloquear la navegación al componente
      const unblock = history.block((location, action) => {
        setShowModal(true); // Mostrar el modal al intentar navegar

        // Obtener la ruta hacia donde se intenta navegar
        const destinationPath = location.pathname;
        // console.log('Intento de navegación hacia:', destinationPath);

        setWhereWantGo(destinationPath);

        return false; // Devolver false para bloquear la navegación
      });

      return () => {
        // Desbloquear la navegación al salir del componente
        unblock(); // Llamar a unblock para desbloquear la navegación
      };
    }
  }, [history, showToast]);

  return (
    <div>
      
      <div className='custom_warning'>
        <Toast show={showToast} onClose={toggleShowToast} className="custom_toast">

        <Toast.Body className='custom_toast_body'>
            <div className='d-flex justify-content-end'>
              <div className='mr-2'>Data modified, would you like to save it?</div>
              <div className='mr-2 textBold' onClick={applyChanges} style={{cursor:"pointer"}}>Yes</div>
              <div className='textBold' onClick={NoapplyChanges} style={{cursor:"pointer"}}>No</div>
            </div>
          </Toast.Body>
        </Toast>
      </div>
      {/* <button onClick={blockNavigation}>Bloquear Navegación</button> */}
      <h2 className='textBold marginBottom2rem'>Personal Information
      </h2>
      <p className='fontText3 colorBlackPI marginBottom2rem'>Manage your information, including phone numbers and email addresses where you can be reached.</p>
      <div className="grid-container" style={{width:'65vw'}}>
        <div className="boxShadowCard borderRadius ">
          <div className='py-3 d-flex flex-column'>
            <div className='d-flex boxShadowCardBottomTitle pl-3 pb-3'>
              <div className='pr-4'>
                <div className='sizeImgTitleCard'>
                  <img src={icon_name} alt="" width={"100%"} height={"100%"} />
                </div>
              </div>
              <div className='fontSizeTitleCard textBold'>Name</div>
            </div>
            <div className='d-flex flex-column align-self-center widthContentCard pt-3'>
              <div className='d-flex flex-column boxShadowCardBottom'>
                <div className='fontText1'>First Name</div>
                <input
                  className='fontText2 colorBlackPI input-reset'
                  type="text"
                  placeholder={first_name}
                  value={first_name}
                  onChange={handleFirstNameChange}
                  onKeyDown={OutOfInputWithEnter}
                />
              </div>
              <div className='d-flex flex-column boxShadowCardBottom pt-3'>
                <div className='fontText1'>Middle Name (optional)</div>
                <input
                  className='fontText2 colorBlackPI input-reset'
                  type="text"
                  placeholder={middle_name}
                  value={middle_name}
                  onChange={handleMiddleNameChange}
                  onKeyDown={OutOfInputWithEnter}
                />
              </div>
              <div className='d-flex flex-column boxShadowCardBottom pt-3 '>
                <div className='fontText1'>Last Name</div>
                <input
                  className='fontText2 colorBlackPI input-reset'
                  type="text"
                  placeholder={last_name}
                  value={last_name}
                  onChange={handleLastNameChange}
                  onKeyDown={OutOfInputWithEnter}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="boxShadowCard borderRadius ">
          <div className='py-3 d-flex flex-column'>
            <div className='d-flex boxShadowCardBottomTitle pl-3 pb-3'>
              <div className='pr-4'>
                <div className='sizeImgTitleCard'>
                  <img src={icono_birthday} alt="" width={"100%"} height={"100%"} style={{ marginTop: "1px" }} />
                </div>
              </div>
              <div className='fontSizeTitleCard textBold'>Date of birth</div>
            </div>
            <div className='d-flex flex-column align-self-center widthContentCard pt-3'>
              <div className='d-flex flex-column mb-3'>
                <div className='fontText1'>Month</div>
                <div className="select-container">
                  <select className="form-select p-2 borderRadius backgroundSelect" aria-label="Default select example" onChange={handleMonth} value={month}>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
              </div>
              <div className='d-flex flex-row'>
                <div className='d-flex flex-column widthSelect1 mr-2'>
                  <div className='fontText1'>Day</div>
                  <div className="select-container">
                    <select className="form-select p-2 borderRadius backgroundSelect" aria-label="Default select example" onChange={handleDay} value={day}>
                      {daysList}
                    </select>
                  </div>
                </div>
                <div className='d-flex flex-column widthSelect1 '>
                  <div className='fontText1'>Year</div>
                  <div className="select-container">
                    <select className="form-select p-2 borderRadius backgroundSelect" aria-label="Default select example" onChange={handleYear} value={year}>
                      {yearsList}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="boxShadowCard borderRadius ">
          <div className='py-3 d-flex flex-column'>
            <div className='d-flex boxShadowCardBottomTitle pl-3 pb-3'>
              <div className='pr-4'>
                <div className='sizeImgTitleCard'>
                  <img src={icono_country} alt="" width={'100%'} height={'100%'} style={{ marginTop: '2px' }} />
                </div>
              </div>
              <div className='fontSizeTitleCard textBold'>Country/Region</div>
            </div>
            <div className='d-flex flex-column align-self-center widthContentCard pt-3'>
              <div className='d-flex flex-column'>
                <div style={{ height:'82px' }} className='colorBlackPI mb-3'>This is the country or region you selected for your account.</div>
                <div className="select-container">
                  <select className="form-select p-2 borderRadius backgroundSelect" aria-label="Default select example" onChange={handleCountry} value={locality}>
                    {countriesList}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="boxShadowCard borderRadius ">
          <div className='py-3 d-flex flex-column'>
            <div className='d-flex boxShadowCardBottomTitle pl-3 pb-3'>
              <div className='pr-4'>
                <div className='sizeImgTitleCard'>
                  <img src={icono_language} alt="" width={"100%"} height={"100%"} style={{ marginTop: "4px" }} />
                </div>
              </div>
              <div className='fontSizeTitleCard textBold'>Language</div>
            </div>
            <div className='d-flex flex-column align-self-center widthContentCard pt-3'>
              <div className='d-flex flex-column'>
                <div style={{ height:'82px' }} className='colorBlackPI mb-3 alingLanguage'>This language will be used for email and other communications from AVAP.</div>
                <div className="select-container">
                  <select className="form-select p-2 borderRadius backgroundSelect" aria-label="Default select example" onChange={handleLanguage} value={language_id}>
                    {languagesList}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal style={{ marginTop:'7rem' }} show={showModal} onHide={handleClose}>
        <Modal.Body className='colorBlack'>Do you want leave without saving your changes?</Modal.Body>
        <Modal.Footer>
          <button className="btn custom-button  textBold" onClick={handleConfirmNavigation}>
            Yes
          </button>

          <button className="btn custom-button  textBold" onClick={handleClose}>
            No
          </button>
          
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PersonalInformation;