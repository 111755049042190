import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Operaciones_EN = () => 
 {
  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `
      { 
        "status": <boolean>, 
        "codtran": <string>, 
        "result": { "number_of_results": <integer>, 
        "data": [ { "id": <integer>, "invoice": <integer>, 
        "state": <integer>, "state_name": <string>, 
        "error_id": <string>, "exception": <string>, 
        "channel": <string>, "action_id": <integer>,
         "action_name": <string>, "point_of_entry": <string>,
          "country_name": <string>, "country": <string>, 
          "currency": <string>, "reference": <string>,
           "concept": <string>, "amount": <decimal>, 
           "amount_fmt": <string>, "amount_fmt_abs": <string>, 
           "commissions": { "destinatary_commission": <decimal>,
            "cast_affiliate_user": <decimal>, 
            "affiliate_service_commission": <decimal>, 
            "user_affiliate_commission": <decimal>,
             "commission_user_card": <decimal>, 
             "delivery_affiliate_service": <decimal>, 
             "user_commission": <decimal>, 
             "commission_recipient_card": <decimal> }, 
             "user": { "name": <string>, 
             "phone": <string>,
              "type_of_source_of_funds": <string>, 
              "alias": <string> },
               "destination": { "name": <string>, "phone": <string>, 
               "type_of_source_of_funds": <string>, 
               "alias": <string> }, "user_affiliate": 
               { "name": <string>, "alias": <string> }, 
               "affiliate_service": { "name": <string>, "alias": <string> }, 
               "other_data": { }, "date": <string>, "codtran": <string> } ], 
               "result_limit": <integer> }, 
      "elapsed": <float> }`,
      copied: false
    },
    answe_ko1: {
      title:"Answer ok",
      type: "json",
      code: `
      { 
        "status": <boolean>, 
        "level": <string>,
         "message": <string>, 
         "error": <string> 
        }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: `import requests url = "URL_BASE/ws/admin.py/operaciones?country_code=MX&user_id=4532&session_id=407-W-VF2OoTRSE7JAcsk4UKkA%3D%3D&startdate=2019-10-12&enddate=2020-06-9&nick=prueba" 
      payload = {} 
      files = {} 
      headers= {} 
      response = requests.request("GET", url, headers=headers, data = payload, files = files) 
      print(response.text.encode('utf8'))`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/admin.py/operaciones?country_code=MX&user_id=4532&session_id=407-W-VF2OoTRSE7JAcsk4UKkA%3D%3D&startdate=2019-10-12&enddate=2020-06-9&nick=prueba" 
        payload = {} 
        files = {} 
        headers= {} 
        response = requests.request("GET", url, headers=headers, data = payload, files = files) 
        print(response.text.encode('utf8'))`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: `var request = require('request');
            var options = {
            'method': 'GET',
            'url': 'URL_BASE/ws/admin.py/operaciones?country_code=MX&user_id=4532&session_id=407-W-VF2OoTRSE7JAcsk4UKkA%3D%3D&startdate=2019-10-12&enddate=2020-06-9&nick=prueba',
            'headers': {
            },
            formData: {}
            };
            request(options, function (error, response) { 
            if (error) throw new Error(error);
            console.log(response.body);
            });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: `var formdata = new FormData();
            var requestOptions = {
            method: 'GET',
            body: formdata,
            redirect: 'follow'
            };
            fetch("URL_BASE/ws/admin.py/operaciones?country_code=MX&user_id=4532&session_id=407-W-VF2OoTRSE7JAcsk4UKkA%3D%3D&startdate=2019-10-12&enddate=2020-06-9&nick=prueba", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: `curl --location --request GET 'URL_BASE/ws/admin.py/operaciones?country_code=MX&user_id=4532&session_id=407-W-VF2OoTRSE7JAcsk4UKkA%3D%3D&startdate=2019-10-12&enddate=2020-06-9&nick=prueba'`
      }
    ]
  }
  return(
    
    <Fragment>
       <div className='container-fluid row'>
        <div className='col-md-7'>
      <h1 id="operations">Operations</h1>
      <p>
        You get everything you need to show the list of operations performed in
        the system.
      </p>
      <p>
        <span className="label get">GET:</span>
        <span> </span> <code>URL_BASE + /ws/admin.py/operaciones</code>
      </p>
      <h2 id="receives">Receives:</h2>
      <p>
        All parameters are sent in the querystring of the call, so a percentage
        encoding for URI must be applied &#40;aka URL encoding&#41;.
      </p>
      <table>
        <thead>
          <tr>
            <th align="left">Parameter</th>
            <th align="center">Format</th>
            <th align="center">Obligatory</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="left">country_code</td>
            <td align="center">
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                ISO 3166 alpha-2
              </a>
            </td>
            <td align="center">Yes</td>
            <td align="center">Country code in which the user resides</td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">user_id</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">Identifier of the user making the request</td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">session_id</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send signature to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Obtained by identifying the user with ID user_id on the platform
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">signature</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send session_id to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Enables you to sign the request without having to log in
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">startdate</td>
            <td align="center">String</td>
            <td align="center">Yes</td>
            <td align="center">
              Operations are obtained from the indicated date
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">enddate</td>
            <td align="center">String</td>
            <td align="center">Yes</td>
            <td align="center">
              Operations are obtained until the indicated date
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">nick</td>
            <td align="center">String</td>
            <td align="center">No</td>
            <td align="center">
              Username &#40;Nick&#41;, to show only your operations
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">csv</td>
            <td align="center">Boolean</td>
            <td align="center">No</td>
            <td align="center">Send the result by mail in CSV format</td>
            <td align="center">N/A</td>
          </tr>
        </tbody>
      </table>
      <h2 id="returns">Returns:</h2>
      <p>
        Depending on the result of the operation, this service can return two
        different JSON:
      </p>
      <h3 id="answer-json-ok">Answer JSON OK:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
      <h2 id="where">Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;
        </li>
        <li>
          <code>codtran:</code> Transaction code that identifies the executed
          operation
        </li>
        <li>
          <code>result:</code> Structure containing the response of the service
        </li>
        <li>
          <code>number_of_results:</code> Number of elements within data
        </li>
        <li>
          <code>data:</code> List of operations
        </li>
        <li>
          <code>id:</code> Identifier of the oepration
        </li>
        <li>
          <code>invoice:</code>Page number or invoice number generated with the
          operation.
        </li>
        <li>
          <code>state:</code> Identifier of the state in which the operation is
        </li>
        <li>
          <code>state_name:</code> Name of the state the operation is in
        </li>
        <li>
          <code>error_id:</code> Identifier of the error that occurred in the
          operation &#40;if any&#41;
        </li>
        <li>
          <code>exception:</code> Error message that occurred in the operation
          &#40;if any&#41;
        </li>
        <li>
          <code>channel:</code> Name of the channel from which the operation
          occurred
        </li>
        <li>
          <code>action_id:</code> Identifier of the action associated with the
          operation
        </li>
        <li>
          <code>action_name:</code> Name of the action associated with the
          operation
        </li>
        <li>
          <code>point_of_entry:</code> Name of the entry point on the platform.
        </li>
        <li>
          <code>country_name:</code> Name of the country in which the operation
          took place
        </li>
        <li>
          <code>country:</code> Abbreviation of the country in which the operation
          took place
        </li>
        <li>
          <code>currency:</code> Abbreviation of the currency in which the
          transaction has been processed
        </li>
        <li>
          <code>reference:</code> Operation reference number
        </li>
        <li>
          <code>concept:</code> Descriptive text of the operation carried out
        </li>
        <li>
          <code>amount:</code> Value of the amount taking as reference the start
          of the operation
        </li>
        <li>
          <code>amount_fmt:</code> Value of the amount formatted with the currency
        </li>
        <li>
          <code>amount_fmt_abs:</code> Value of the amount formatted with the
          currency, but in absolute value
        </li>
        <li>
          <code>commissions:</code> Object with the different commissions of the
          operation
        </li>
        <li>
          <code>user_commission:</code> Commission charged to the user who
          performs the operation
        </li>
        <li>
          <code>destinatary_commission:</code> Commission charged to the recipient
          of the operation
        </li>
        <li>
          <code>affiliate_service_commission:</code> Commission charged to the
          affiliate of the service used in the operation
        </li>
        <li>
          <code>user_affiliate_commission:</code> Commission charged to the
          affiliate of the user who performs the operation
        </li>
        <li>
          <code>commission_user_card:</code> Commission charged to the user for
          making the card payment.
        </li>
        <li>
          <code>commission_recipient_card:</code> Commission charged to the user
          for receiving the card payment.
        </li>
        <li>
          <code>cast_affiliate_user:</code> Commission that is paid to the
          user&#39;s affiliate for carrying out the operation
        </li>
        <li>
          <code>delivery_affiliate_service:</code> Commission that is paid to the
          affiliate of the service for carrying out the operation
        </li>
        <li>
          <code>user:</code> Data of the user who starts the operation
        </li>
        <li>
          <code>name:</code> User name
        </li>
        <li>
          <code>phone:</code> User phone number
        </li>
        <li>
          <code>type_of_source_of_funds:</code> Type of source of funds used
        </li>
        <li>
          <code>alias:</code> User name
        </li>
        <li>
          <code>destination:</code> Data of the user to whom the operation is
          directed
        </li>
        <li>
          <code>name:</code> User name
        </li>
        <li>
          <code>phone:</code> User phone number
        </li>
        <li>
          <code>type_of_source_of_funds:</code> Type of source of funds used
        </li>
        <li>
          <code>alias:</code> User name
        </li>
        <li>
          <code>user_affiliate:</code> Affiliate user who is responsible for users
        </li>
        <li>
          <code>name:</code> User name
        </li>
        <li>
          <code>alias:</code> User name
        </li>
        <li>
          <code>affiliate_service:</code> Affiliate user that provides a service
          to the platform
        </li>
        <li>
          <code>name:</code> User name
        </li>
        <li>
          <code>alias:</code> User name
        </li>
        <li>
          <code>other_data:</code> Other details of the operation
        </li>
        <li>
          <code>date:</code> Timestamp in which the date and time when the
          operation is performed is shown
        </li>
        <li>
          <code>codtran:</code> Transaction code that identifies the executed
          operation.
        </li>
        <li>
          <code>result_limit:</code> Number of elements within data
        </li>
        <li>
          <code>elapsed:</code> Operation execution time
        </li>
      </ul>
      <h3 id="answer-json-ko">Answer JSON KO:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
      <h2 id="where-1">Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li>
          <code>level:</code> Error importance level.
        </li>
        <li>
          <code>message:</code> Error message.
        </li>
        <li>
          <code>error:</code> Sole error code.
        </li>
      </ul>
      <h2 id="business-logic">Business logic:</h2>
      <p>
        In order to run this service it is necessary to do it from a user logged
        into the system who has an administrator profile. With this endpoint the
        operations carried out on the platform are obtained, being able to filter
        them by user, and dates. You can also request the response in CSV format
        and receive it in the email of the user making the request.
      </p>
      </div>

      <div className='col-md-5'>
      <h2 id="example-requests">Example requests:</h2>
      <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
      
      </div>
      </div>
    </Fragment>
  );
 }
export default Operaciones_EN;
