import React, { FunctionComponent } from 'react';
import { Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import icono_ubicacion from "../../../assets/img/icono_ubicacion.png";
import icono_mail from "../../../assets/img/icono-mail.png";
import iconos_familia from "../../../assets/img/iconos_familia.png";
import avap_logo from "../../../assets/img/avap_logo.png"

import twitterblue from "../../../assets/img/twittergrey.png"
import linkedinblue from "../../../assets/img/linkedingrey.png"

import yticon from "../../../assets/img/icon-yt.png"
import redicon from "../../../assets/img/icon-redd.png"
import fbicon from "../../../assets/img/icono_fb.png"


import "./style.css"

type color = {
  green?: boolean,
}

const FooterMenu: FunctionComponent<color> = props => {
  return (
    // <Nav id="footer-dashboard" className="justify-content-md-center ">
    //   <Nav.Item>
    //     <Nav.Link 
    //       target="_blank" 
    //       rel="noopener noreferrer" 
    //       href="https://101obex.com/termsconditions">Terms and Conditions1</Nav.Link>
    //   </Nav.Item>
    //   <Nav.Item>
    //     <Nav.Link 
    //       target="_blank" rel="noopener noreferrer" 
    //       href="https://101obex.com/privacypolicy">Privacy Policy</Nav.Link>
    //   </Nav.Item>
    // </Nav>
    <div id="footer-dashboard" className='pt-5' style={{ position:'relative', zIndex:99999 }}>


    <div style={{ display:'flex', height:'180px', zIndex:9999999999 }}>


            <div onClick={()=>{window.open('/')} } className='d-flex flex-column' style={{ cursor:'pointer', marginLeft:'19%', marginBottom:'50px' }}>
              <div className='pb-1'>
                <img src={avap_logo} alt="" width="271px" className='sizeLogo' />
              </div>
              <div>
                <img src={iconos_familia} width="271px" alt="" />
              </div>

            </div>

            {/*}
            <div style={{marginLeft:'9.5%', marginTop:'5px'}}>
            <input className='form-control' style={{width:'320px', height:'35px',borderColor:`${props.green ? '#316952':'#1890ff'}`, borderRadius:'12px', borderWidth:'2px'}}></input>
            </div>
            <div style={{marginLeft:'20px', marginTop:'5px'}}>
              <button className={`${props.green? 'custom-button-green':'custom-button-blue'} w-150 textBold`}>Suscribe to newsletter</button>
            </div>
*/}


    </div>





      <div className='newFooter d-flex justify-content-center ' style={{ width:'100%' }}>








        <div className='d-flex flex-column-reverse  width_row' style={{ width:'100%' }}>
          <div className='d-flex w-textLeft my-3 my-md-0' style={{ width:'64%', marginLeft:'19%' }}>
            <div className='w-textLeft-items' style={{ marginRight:'0px', width:'27.5%' }}>
              <div  className='d-flex flex-column'>
                <div  style={{ marginTop:'5em' }} className='text-left text-boldLogin mb-2 titlesText2'>Products & Services</div>
                <div style={{ cursor:'pointer' }} onClick={()=>{window.open('avap', '_blank')}} className='text2 text-left width_text2 mb-1'>
                AVAP
                </div>
                <div style={{ cursor:'pointer' }} onClick={()=>{window.open('avs', '_blank')}} className='text2 text-left width_text2 mb-1'>
                AVAP Virtual Server
                </div>
                <div style={{ cursor:'pointer' }} onClick={()=>{window.open('gateway', '_blank')}} className='text2 text-left width_text2 mb-1'>
                AVAP Gateway
                </div>
                <div style={{ cursor:'pointer' }} onClick={()=>{window.open('devstudio', '_blank')}} className='text2 text-left width_text2 mb-1'>
                AVAP Dev Studio 2024
                </div>
                <div style={{ cursor:'pointer' }} onClick={()=>{window.open('cloud', '_blank')}} className='text2 text-left width_text2 mb-1'>
                AVAP Cloud
                </div>
                <div style={{ cursor:'pointer' }} onClick={()=>{window.open('collaborative', '_blank')}} className='text2 text-left width_text2 mb-1'>
                AVAP Collaborative Network
                </div>
{/*}
                <div className='d-flex align-items-baseline' >
                  <div className='text2 mt-1'>sales@101obex.com</div>
                </div>

                <div className='d-flex mr-1 marginTopimg' style={{marginTop:'10px'}}>
                    <img src={linkedinblue} alt="" width={"16px"} height={"16px"} />
                    <img style={{marginLeft:'5px'}} src={twitterblue} alt="" width={"16px"} height={"16px"} />
                  </div>
*/}
              </div>

            </div>
            <div className='w-textLeft-items' style={{ width:'22%' }}>
              <div className='d-flex flex-column'>
              <div  style={{ marginTop:'5em' }} className='text-left text-boldLogin mb-2 titlesText2'>Partners</div>
                <div style={{ cursor:'pointer' }} onClick={()=>{window.open('https://speed-computing-1656.my.site.com/connect/s/', '_blank')}} className='text2 text-left width_text2 mb-1'>
                  AVAP Connect
                </div>
                {/*}
                <div className='text2 text-left width_text2 mb-1'>
                  Executive Leadership
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  News & Stories
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Invertor Relations
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Customer Stories
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Diversity, Equity, Inclusion
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Enviroment, Social & Governance
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  AI at VMware
                </div>
*/}


              </div>
            </div>


            <div className='w-textLeft-items' style={{ width:'22%' }}>
              <div className='d-flex flex-column'>
              <div  style={{ marginTop:'5em' }} className='text-left text-boldLogin mb-2 titlesText2'>Resources</div>
                <div style={{ cursor:'pointer' }} onClick={()=>{window.open('/cloud_login', '_blank')}} className='text2 text-left width_text2 mb-1'>
                AVAP Developer Portal
                </div>
                <div style={{ cursor:'pointer' }} onClick={()=>{window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US', '_blank')}} className='text2 text-left width_text2 mb-1'>
                AVAP Alexandria
                </div>
                <div style={{ cursor:'pointer' }} onClick={()=>{window.open('https://speed-computing-1656.my.site.com/communities/s/', '_blank')}} className='text2 text-left width_text2 mb-1'>
                AVAP Communities
                </div>
                {/*}
                <div className='text2 text-left width_text2 mb-1'>
                  Office Locations
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  VMware Cloud Trust Center
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  COVID-19 Resources
                </div>

*/}


              </div>
            </div>






            <div className='w-textLeft-items' style={{ width:'22%' }}>
              <div className='d-flex flex-column'>
              <div  style={{ marginTop:'5em' }} className='text-left text-boldLogin mb-2 titlesText2'>Company</div>
                <div style={{ cursor:'pointer' }} onClick={()=>{window.open('https://www.101obex.com', '_blank')}} className='text2 text-left width_text2 mb-1'>
                  About us
                </div>
                {/*}
                <div className='text2 text-left width_text2 mb-1'>
                  Support Policies
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Product Documentation
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Compatibility Guide
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Terms & Conditions
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  California Trnasparency Act Statement
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Hands-on Labs
                </div>
*/}

              </div>
            </div>



            <div className='w-textLeft-items' style={{width:'22%'}}>
              <div className='d-flex flex-column'>
              <div  style={{marginTop:'5em'}} className='text-left text-boldLogin mb-2 titlesText2'>Legal</div>
                <div className='text2 text-left width_text2 mb-1'>
                  T&C
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                Privacy notice
                </div>
                {/*}
                <div className='text2 text-left width_text2 mb-1'>
                  Support Policies
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Product Documentation
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Compatibility Guide
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Terms & Conditions
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  California Trnasparency Act Statement
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Hands-on Labs
                </div>
*/}

              </div>
            </div>



          </div>

          <div className=' d-flex justify-content-start justify-content-md-end ' style={{ marginTop:'60px', width: '60%' }}>


          </div>

        </div>
      </div>
<div style={{ marginLeft:'auto', width:'50%', marginRight:'auto', display:'flex', marginBottom:'30px', marginTop:'30px' }}>

  <div style={{ marginLeft:'auto', width:'45%', marginRight:'0px' }}>
    <div className='text-boldLogin' style={{textAlign:'start'}}>Corporative Head Office</div>

    <div className='d-flex'>
                  <div className='d-flexmarginTopimg'>
                    <img style={{ marginBottom:'auto', marginTop:'auto', marginRight:'5px' }} src={icono_ubicacion} alt="" width={"10px"} height={"12px"} />
                  </div>
                  <div className='text-left text2 '>101OBEX, CORP 440 N Wolfe Rd 94085 Sunnyvale, CA</div>
                </div>



  </div>
  <div style={{width:'2px', height:'48px', backgroundColor:'#ebebeb', marginRight:'10px', paddingTop:'10px'}}></div>
  <div style={{ marginLeft:'0px', width:'20%', marginRight:'auto', justifyContent:'start' }}>
      <div className='d-flex align-items-baseline' >
                  <div className=' '>
                    <img src={icono_mail} alt="" width={'12px'} height={'9px'} style={{ marginRight:'5px' }} />
                  </div>
                  <div className='text2 mt-1'>sales@101obex.com</div>
                </div>

      <div style={{ display:'flex' }}>
      <img onClick={()=>{window.open('https://www.linkedin.com/company/avap-framework', '_blank')}} style={{cursor:'pointer'}} src={linkedinblue} alt="" width={"16px"} height={"16px"} />
      <img onClick={()=>{window.open('https://x.com/AvapFramework', '_blank')}}style={{cursor:'pointer', marginLeft:'20px'}} src={twitterblue} alt="" width={"16px"} height={"16px"} />
      <img onClick={()=>{window.open('https://www.youtube.com/@AVAPFramework', '_blank')}}src={yticon} alt="" style={{cursor:'pointer', marginLeft:'20px'}} width={'16px'} height={'16px'} />
      <img onClick={()=>{window.open('https://www.reddit.com/user/AVAP_Framework/', '_blank')}}style={{ cursor:'pointer',marginLeft:'20px' }} src={redicon} alt="" width={'16px'} height={'16px'} />
      <img onClick={()=>{window.open('https://www.facebook.com/AVAPFramework')}}style={{ cursor:'pointer', marginLeft:'20px' }} src={fbicon} alt="" width={'16px'} height={'16px'} />
      </div>

  </div>
</div>
<div style={{ marginLeft:'auto', width:'62%', height:'2px', backgroundColor:'#ebebeb', marginRight:'auto' }}></div>
      <div className='pt-4 p-3-5' style={{ textAlign:'center' }}>
        © 2024 101OBEX, CORP, All rights reserved

      </div>

    </div>
  );
}


export default FooterMenu;