import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../components/DisplayCodeKo/DisplayCodeKo';

const Ingresar_Prepago_EN = () => {

  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status": <boolean>,
        "codtran": <string>,
        "result": {
            "invoice": <integer>,
            "signed": <boolean>,
            "currency_data": {
                "abbreviation": <string>,
                "suffix": <string>,
                "format": <string>,
                "symbol": <string>,
                "prefix": <string>,
                "decimals": <decimal>,
                "id": <integer>
            },
            "raw_amount": <decimal>,
            "currency": <string>,
            "card_user_commission": <decimal>,
            "codtran": <string>,
            "user_commission": <decimal>,
            "amount": <string>
        "elapsed": <float>
    }`,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
    url = "URL_BASE/ws/pademobile_prepago.py/ingresar_prepago"
    payload = {'country_code': 'MX',
    'user_id': '4532',
    'id_origen': '555444',
    'monto': '12.50',
    'signature': '00d4c87a1dd9c87ccda8518bb007835a71233069'}
    files = []
    headers= {}
    response = requests.request("POST", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
    url = "URL_BASE/ws/pademobile_prepago.py/ingresar_prepago"
    payload = {'country_code': 'MX',
    'user_id': '4532',
    'id_origen': '555444',
    'monto': '12.50',
    'signature': '00d4c87a1dd9c87ccda8518bb007835a71233069'}
    files = []
    headers= {}
    response = requests.request("POST", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
  var options = {
  'method': 'POST',
  'url': 'URL_BASE/ws/pademobile_prepago.py/ingresar_prepago',
  'headers': {},
  formData: {
  'country_code': 'MX',
  'user_id': '4532',
  'id_origen': '555444',
  'monto': '12.50',
  'signature': '00d4c87a1dd9c87ccda8518bb007835a71233069'
  }
  };
  request(options, function (error, response) { 
  if (error) throw new Error(error);
  console.log(response.body);
  });`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var formdata = new FormData();
  formdata.append("country_code", "MX");
  formdata.append("user_id", "4532");
  formdata.append("id_origen", "555444");
  formdata.append("monto", "12.50");
  formdata.append("signature", "00d4c87a1dd9c87ccda8518bb007835a71233069");
  var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
  };
  fetch("URL_BASE/ws/pademobile_prepago.py/ingresar_prepago", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request POST 'URL_BASE/ws/pademobile_prepago.py/ingresar_prepago' 
  --form 'country_code=MX' 
  --form 'user_id=4532' 
  --form 'id_origen=555444' 
  --form 'monto=12.50' 
  --form 'signature=00d4c87a1dd9c87ccda8518bb007835a71233069'`
      }
    ]
  }

  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 id="deposit-external-wallet">Deposit &#40;External Wallet&#41;</h1>
          <p>
            This service is used to make a deposit of money to an external wallet of
            the user.
          </p>
          <p>
            <span className="label post">POST:</span>
            <span> </span>{' '}
            <code>URL_BASE + /ws/pademobile_prepago.py/ingresar_prepago</code>
          </p>
          <h2 id="receives">receives:</h2>
          <p>
            All the parameters that the service receives must be indicated in the body
            of the request.
          </p>
          <div className='overflow-auto'>

            <table>
              <thead>
                <tr>
                  <th align="left">Parameter</th>
                  <th align="center">Format</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Description</th>
                  <th align="center">Default value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="left">country_code</td>
                  <td align="center">
                    <a href="https://www.iso.org/glossary-for-iso-3166.html">
                      ISO 3166 alpha-2
                    </a>
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">Country code in which the user resides</td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="left">user_id</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">
                    Identifier of the user making the request &#40;Admin or
                    Affiliate&#41;
                  </td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="left">session_id</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send signature to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Obtained by identifying the user with ID user_id on the platform
                  </td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="left">signature</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send session_id to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Enables you to sign the request without having to log in
                  </td>
                  <td align="center">N/A</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>
          <h3 >Answer JSON OK:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h2 >Where:</h2>
          <ul>
            <li>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li>
              <code>result:</code> Response of the service that manages the request.
            </li>
            <li>
              <code>signed:</code> Shows if the signature has been correctly processed
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li>
              <code>invoice:</code> Invoice number associated to the operation.
            </li>
            <li>
              <code>currency_data:</code> Contains the different details of the
              currency used in the operation.
            </li>
            <li>
              <code>abbreviation:</code> The abbreviated name of the currency
              &#40;EUR, MXN&#41;.
            </li>
            <li>
              <code>suffix:</code> Indicates the suffix that is applied in the
              currency format &#40;pesos, euros&#41;.
            </li>
            <li>
              <code>format:</code> The full format that applies to the currency, it
              includes the suffix and the prefix.
            </li>
            <li>
              <code>symbol:</code> The symbol associated to the currency&#40;€, ¢,
              $&#41;.
            </li>
            <li>
              <code>prefix:</code> The prefix that is applied in the currency format.
            </li>
            <li>
              <code>decimals:</code> The maximum number of decimal places that will be
              included in the currency format..
            </li>
            <li>
              <code>id:</code> Identifier of the currency in BBDD.
            </li>
            <li>
              <code>raw_amount:</code> Negative transaction amount without applying
              the format.
            </li>
            <li>
              <code>currency:</code> Short name of the currency used in the operation,
              it matches the abbreviation.
            </li>
            <li>
              <code>card_user_commission:</code> Commission that would be applied if
              the payment is made by card.
            </li>
            <li>
              <code>codtran:</code> Transaction code that identifies the executed
              operation.
            </li>
            <li>
              <code>user_commission:</code> Commission that will be applied to the
              user.
            </li>
            <li>
              <code>amount:</code> Negative transaction amount applying the format.
            </li>
            <li>
              <code>elapsed:</code> Operation execution time.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 >Business logic:</h2>
          <p>
            With this service we can make a deposit in the user&#39;s external wallet
            to make an entry or receive money in the wallet from a money transfer or
            request. It is advisable to use this service with signature so as not to
            keep two sessions open. The private key to calculate the signature is the
            one obtained from the call to registrar_monedero_prepago.
          </p>

        </div>
        <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>

        </div>

      </div>

    </Fragment>
  );
}
export default Ingresar_Prepago_EN;
