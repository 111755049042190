import React, { Fragment } from 'react';

const SETUP_Additionalcomponentsandtools = () => (
  <Fragment>
    <h1 id="additional-components-and-tools">
      Additional components and tools
    </h1>
    <p>
      AVAP™ Dev Studio 2024 is a small download by design and only includes the
      minimum number of components shared across most development workflows.
      Basic functionality like the editor, file management, window management,
      and preference settings are included. A JavaScript/TypeScript language
      service and Node.js debugger are also part of the base install.
    </p>
    <p>
      If you are used to working with larger, monolithic development tools
      &#40;IDEs&#41;, you may be surprised that your scenarios aren&#39;t
      completely supported out of the box. For example, there isn&#39;t a{' '}
      <strong>File</strong> &gt; <strong>New Project</strong> dialog with
      pre-installed project templates. Most AVAP™ Dev Studio users will need to
      install additional components depending on their specific needs.
    </p>
    <h3 id="commonly-used-components">Commonly used components</h3>
    <p>Here are a few commonly installed components:</p>
    <ul>
      <li>
        Git - AVAP™ Dev Studio has built-in support for source code control
        using Git but requires Git to be installed separately.
      </li>
      <li>
        Node.js &#40;includes npm&#41; - A cross platform runtime for building
        and running JavaScript applications.
      </li>
      <li>
        TypeScript - The TypeScript compiler, <code>tsc</code>, for transpiling
        TypeScript to JavaScript.
      </li>
    </ul>
    <p>
      You&#39;ll find the components above mentioned often in our documentation
      and walkthroughs.
    </p>
    <h3 id="avap™-dev-studio-extensions">AVAP™ Dev Studio extensions</h3>
    <p>
      You can extend the AVAP™ Dev Studio editor itself through extensions. The
      AVAP™ Dev Studio community has built hundreds of useful extensions
      available on the AVAP™ Dev Studio Marketplace.
    </p>
    <blockquote>
      <p>Imagenes con enlace &#40;shortcuts&#41;</p>
    </blockquote>
    <p>
      The extensions shown above are the current most popular on Marketplace.
      Click on an extension tile above to read the description and reviews of
      the extension.
    </p>
    <h3 id="additional-tools">Additional tools</h3>
    <p>
      AVAP™ Dev Studio 2024 integrates with existing tool chains. We think the
      following tools will enhance your development experiences.
    </p>
    <ul>
      <li>
        Yeoman - An application scaffolding tool, a command line version of File
        &gt; New Project.
      </li>
      <li>
        generator-hottowel - A Yeoman generator for quickly creating AngularJS
        applications.
      </li>
      <li>
        Express - An application framework for Node.js applications using the
        Pug template engine.
      </li>
      <li>
        Gulp - A streaming task runner system which integrates easily with AVAP™
        Dev Studio tasks.
      </li>
      <li>Mocha - A JavaScript test framework that runs on Node.js.</li>
      <li>Yarn - A dependency manager and alternative to npm.</li>
    </ul>
    <blockquote>
      <p>
        Note: Most of these tools require Node.js and the npm package manager to
        install and use.
      </p>
    </blockquote>
    <h3 id="next-steps">Next steps</h3>
    <ul>
      <li>User Interface - A quick orientation around AVAP™ Dev Studio.</li>
      <li>
        User/Workspace Settings - Learn how to configure AVAP™ Dev Studio to
        your preferences through settings.
      </li>
      <li>
        Languages - AVAP™ Dev Studio supports many programming languages
        out-of-the-box as well as many more through community created
        extensions.
      </li>
    </ul>
  </Fragment>
);
export default SETUP_Additionalcomponentsandtools;