import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';

const Get_blockchain_networks = () => 
  {
    const codes ={
      Ret: {
        title:"Answer ok",
        type: "json",
        code: `{
           "status": true, 
           "result": [
             { "total": {
               "total_euros": 0.0, 
               "total_dollar": 0.0 }, 
               "id": 4, 
               "hibrid_available": true, 
               "description": "Ethereum Rinkeby Testnet" }, 
               { "description": "Ethereum Mainnet", 
               "total": { "total_euros": 0.0, "total_dollar": 0.0 }, 
               "order": 0, "id": 1 
              }, { 
                "description": "Binance Mainnet", 
                "total": { 
                  "total_euros": 0.0, 
                  "total_dollar": 0.0 
                }, 
                "order": 5, "id": 6 
              }, { 
                "description": "Binance Testnet", 
                "total": { "total_euros": 0.0, "total_dollar": 0.0 }, 
                "order": 6, "id": 7 } 
              ], 
              "elapsed": 0.20901083946228027`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": false, 
          "level": <string>, 
          "message": <string>, 
          "error": <string> 
        }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requestsurl = "http://34.121.95.179:80/ws/users.py/set_blockchain_account?country_code=ES&id_usuario=95&id_sesion=629-M-ofq9Y060u3ja2uqLx1aQ==&account_id=23"
        payload={}
        headers = { '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'}
        response = requests.request("GET", url, headers=headers, data=payload)
        print(response.text)`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requestsurl = "http://34.121.95.179:80/ws/users.py/set_blockchain_account?country_code=ES&id_usuario=95&id_sesion=629-M-ofq9Y060u3ja2uqLx1aQ==&account_id=23"
          payload={}
          headers = { '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'}
          response = requests.request("GET", url, headers=headers, data=payload)
          print(response.text)`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
               'method': 'GET',
               'url': 'http://34.121.95.179:80/ws/users.py/set_blockchain_accounts?country_code=ES&id_usuario=95&id_sesion=629-M-ofq9Y060u3ja2uqLx1aQ==&network_id=23',
               'headers': {
               '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'
               }
              };
              request(options, function (error, response) {
               if (error) throw new Error(error);
               console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var myHeaders = new Headers();
              myHeaders.append("101ObexApiKey", "KcM6Vr7EfNnKScEi00jdritBzNjWAOkz");
              
              var requestOptions = {
               method: 'GET',
               headers: myHeaders,
               redirect: 'follow'
              };
              
              fetch("http://34.121.95.179:80/ws/users.py/set_blockchain_account?country_code=ES&id_usuario=95&id_sesion=629-M-ofq9Y060u3ja2uqLx1aQ==&account_id=23", requestOptions)
               .then(response => response.text())
               .then(result => console.log(result))
               .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request GET 'http://34.121.95.179:80/ws/users.py/set_blockchain_account?country_code=ES&id_usuario=95&id_sesion=629-M-ofq9Y060u3ja2uqLx1aQ==&account_id=23' \--header '101ObexApiKey: KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="set-blockchain-account-as-selected">
          Set Blockchain Account as selected
        </h1>
        <h2 id="wsuserspyset_blockchain_networks">
          ws/users.py/set_blockchain_networks
        </h2>
        <h3 id="receives">Receives</h3>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <div className='overflow-auto'>
        <table>
          <thead>
            <tr>
              <th align="center">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center">country_code</td>
              <td align="center">
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
              <td align="center">ES</td>
            </tr>
            <tr>
              <td align="center">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID id_usuario on the platform
              </td>
              <td align="center">N/A</td>
              <td align="center">34242-7M94x6WvLh7-b19HC6PM_g==</td>
            </tr>
            <tr>
              <td align="center">user_id</td>
              <td align="center">Int</td>
              <td align="center">Yes</td>
              <td align="center">Identifier of the user making the request</td>
              <td align="center">N/A</td>
              <td align="center">414</td>
            </tr>
            <tr>
              <td align="center">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session to &#39;Access Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
              <td align="center">328621ee3acda7a582338c5bba444c3094cd6f29</td>
            </tr>
            <tr>
              <td align="center">networks</td>
              <td align="center">Array&#40;int&#41;</td>
              <td align="center">Yes</td>
              <td align="center">List of network identifiers to consult</td>
              <td align="center">N/A</td>
              <td align="center">[4,7]</td>
            </tr>
          </tbody>
        </table>
        </div>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.Ret}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li>
            <p>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </p>
          </li>
          <li>
            <p>
              <code>message</code>: Confirmation message.
            </p>
          </li>
          <li>
            <p>
              <code>codtran</code>: Transaction code.
            </p>
          </li>
          <li>
            <p>
              <code>elapsed:</code> Operation execution time.
            </p>
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>level:</code> Error importance level.
          </li>
          <li>
            <code>message:</code> Error message.
          </li>
          <li>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          This service has as an objective to select one of the users blockchain
          accounts as active by default.
        </p>
        </div>
        <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
        </div>
      </Fragment>
      );
  }
  

export default Get_blockchain_networks;
