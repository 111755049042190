import React, { Fragment } from 'react';

const SETUP_Windows = () => (
  <Fragment>
    <h1 id="avap™-dev-studio-2024-on-windows">
      AVAP™ Dev Studio 2024 on Windows
    </h1>
    <h3 id="installation">Installation</h3>
    <ol>
      <li>Download the AVAP™ Dev Studio 2024 installer for Windows.</li>
      <li>
        Once it is downloaded, run the installer
        &#40;VSCodeUserSetup-&#123;version&#125;.exe&#41;. This will only take a
        minute.
      </li>
      <li>
        By default, AVAP™ Dev Studio 2024 is installed under{' '}
        <code>
          C:\Users\&#123;Username&#125;\AppData\Local\Programs\Microsoft AVAP™
          DS.
        </code>
      </li>
    </ol>
    <p>
      Alternatively, you can also download a Zip archive, extract it and run
      Code from there.
    </p>
    <blockquote>
      <p>
        Tip: Setup will add Visual Studio Code to your <code>%PATH%</code>, so
        from the console you can type &#39;code .&#39; to open AVAP™ Dev Studio
        2024 on that folder. You will need to restart your console after the
        installation for the change to the <code>%PATH%</code> environmental
        variable to take effect.
      </p>
    </blockquote>
    <h3 id="user-setup-versus-system-setup">User setup versus system setup</h3>
    <p>
      AVAP™ Dev Studio 2024 provides both Windows <strong>user</strong> and{' '}
      <strong>system</strong> level setups.
    </p>
    <p>
      The user setup does not require Administrator privileges to run as the
      location will be under your user Local AppData &#40;
      <code>LOCALAPPDATA</code>&#41; folder. Since it requires no elevation, the
      user setup is able to provide a smoother background update experience.
      This is the preferred way to install AVAP™ Dev Studio 2024 on Windows.
    </p>
    <blockquote>
      <p>
        Note: When running AVAP™ Dev Studio 2024 as Administrator in a user
        setup installation, updates will be disabled.
      </p>
    </blockquote>
    <p>
      The system setup requires elevation to Administrator privileges to run and
      will place the installation under the system&#39;s Program Files. The
      in-product update flow will also require elevation, making it less
      streamlined than the user setup. On the other hand, installing AVAP™ Dev
      Studio 2024 using the system setup means that it will be available to all
      users in the system.
    </p>
    <p>
      See the Download AVAP™ Dev Studio 2024 page for a complete list of
      available installation options.
    </p>
    <h3 id="updates">Updates</h3>
    <p>
      AVAP™ Dev Studio 2024 ships monthly releases and supports auto-update when
      a new release is available. If you&#39;re prompted by AVAP™ Dev Studio
      2024, accept the newest update and it will be installed &#40;you won&#39;t
      need to do anything else to get the latest bits&#41;.
    </p>
    <blockquote>
      <p>
        Note: You can disable auto-update if you prefer to update AVAP™ Dev
        Studio 2024 on your own schedule.
      </p>
    </blockquote>
    <h3 id="windows-subsystem-for-linux">Windows Subsystem for Linux</h3>
    <p>
      Windows is a popular operating system and it can be a great cross-platform
      development environment. This section describes cross-platform features
      such as the Windows Subsystem for Linux &#40;WSL&#41; and the new Windows
      Terminal.
    </p>
    <h4 id="recent-windows-build">Recent Windows build</h4>
    <p>
      Make sure you are on a recent Windows 10 build. Check{' '}
      <strong>Settings &gt; Windows Update</strong> to see if you are
      up-to-date.
    </p>
    <h4 id="windows-as-a-developer-machine">Windows as a developer machine</h4>
    <p>
      With WSL, you can install and run Linux distributions on Windows. This
      enables you to develop and test your source code on Linux while still
      working locally on your Windows machine.
    </p>
    <p>
      When coupled with the WSL extension, you get full AVAP™ DS editing and
      debugging support while running in the context of WSL.
    </p>
    <p>
      See the Developing in WSL documentation to learn more or try the Working
      in WSL introductory tutorial.
    </p>
    <h4 id="new-windows-terminal">New Windows Terminal</h4>
    <p>
      Available from the Microsoft Store, the Windows Terminal &#40;Preview&#41;
      lets you easily open PowerShell, Command Prompt, and WSL terminals in a
      multiple tab shell.
    </p>
    <h3 id="next-steps">Next steps</h3>
    <p>
      Once you have installed AVAP™ Dev Studio, these topics will help you learn
      more about AVAP™ Dev Studio:
    </p>
    <ul>
      <li>
        Additional Components - Learn how to install Git, Node.js, TypeScript,
        and tools like Yeoman.
      </li>
      <li>User Interface - A quick orientation to AVAP™ Dev Studio.</li>
      <li>
        User/Workspace Settings - Learn how to configure AVAP™ Dev Studio to
        your preferences through settings.
      </li>
      <li>
        Tips and Tricks - Lets you jump right in and learn how to be productive
        with AVAP™ Dev Studio.
      </li>
    </ul>
    <h3 id="common-questions">Common questions</h3>
    <h4 id="what-command-line-arguments-are-supported-by-the-windows-setup">
      What command-line arguments are supported by the Windows Setup?
    </h4>
    <p>
      AVAP™ Dev Studio uses Inno Setup to create its setup package for Windows.
      Thus, all the Inno Setup command-line switches are available for use.
    </p>
    <p>
      Additionally, you can prevent the Setup from launching AVAP™ Dev Studio
      after completion with <code>/mergetasks=!runcode</code>.
    </p>
    <h4 id="scrolling-is-laggy-and-not-smooth">
      Scrolling is laggy and not smooth
    </h4>
    <p>
      On certain devices, editor scrolling is not smooth but laggy for an
      unpleasant experience. If you notice this issue, make sure you install the
      Windows 10 October 2018 update where this issue is fixed.
    </p>
    <h4 id="im-having-trouble-with-the-installer">
      I&#39;m having trouble with the installer
    </h4>
    <p>
      Try using the zip file instead of the installer. To use this, unzip AVAP™
      Dev Studio in your <code>AppData\Local\Programs</code> folder.
    </p>
    <blockquote>
      <p>
        <strong>Note:</strong> When AVAP™ Dev Studio is installed via a Zip
        file, you will need to manually update it for each release.
      </p>
    </blockquote>
    <h4 id="icons-are-missing">Icons are missing</h4>
    <p>
      I installed AVAP™ Dev Studio on my Windows 8 machine. Why are some icons
      not appearing in the workbench and editor?
    </p>
    <p>
      AVAP™ Dev Studio uses SVG icons and we have found instances where the .SVG
      file extension is associated with something other than{' '}
      <code>image/svg+xml</code>. We&#39;re considering options to fix it, but
      for now here&#39;s a workaround:
    </p>
    <p>Using the Command Prompt:</p>
    <ol>
      <li>Open an Administrator Command Prompt.</li>
      <li>
        Type{' '}
        <code>
          REG ADD HKCR\.svg /f /v &quot;Content Type&quot; /t REG_SZ /d
          image/svg+xml
        </code>
        .
      </li>
    </ol>
    <p>Using the Registry Editor &#40;regedit&#41;:</p>
    <ol>
      <li>
        Start <code>regedit</code>.
      </li>
      <li>
        Open the <code>HKEY_CLASSES_ROOT</code> key.
      </li>
      <li>
        Find the <code>.svg</code> key.
      </li>
      <li>
        Set its <code>Content Type</code> Data value to{' '}
        <code>image/svg+xml</code>.
      </li>
      <li>
        Exit <code>regedit</code>.
      </li>
    </ol>
    <h4 id="unable-to-run-as-admin-when-applocker-is-enabled">
      Unable to run as admin when AppLocker is enabled
    </h4>
    <p>
      With the introduction of process sandboxing &#40;discussed in this blog
      post&#41; running as administrator is currently unsupported when AppLocker
      is configured due to a limitation of the runtime sandbox. If your work
      requires that you run AVAP™ Dev Studio from an elevated terminal, you can
      launch <code>code</code> with{' '}
      <code>--no-sandbox --disable-gpu-sandbox</code> as a workaround.
    </p>
    <p>Subscribe to issue #122951 to receive updates.</p>
    <h4 id="working-with-unc-paths">Working with UNC paths</h4>
    <p>
      Beginning with version <code>1.78.1</code>, AVAP™ Dev Studio on Windows
      will only allow to access UNC paths &#40;these begin with a leading{' '}
      <code>\\</code>&#41; that were either approved by the user on startup or
      where the host name is configured to be allowed via the new{' '}
      <code>security.allowedUNCHosts setting</code>.
    </p>
    <p>If you rely on using UNC paths in AVAP™ Dev Studio, you can either</p>
    <ul>
      <li>
        configure the host to be allowed via the{' '}
        <code>security.allowedUNCHosts</code> setting &#40;for example add{' '}
        <code>server-a</code> when you open a path such as{' '}
        <code>\\server-a\path</code>&#41;
      </li>
      <li>
        map the UNC path as network drive and use the drive letter instead of
        the UNC path &#40;documentation&#41;
      </li>
      <li>
        define a global environment variable{' '}
        <code>NODE_UNC_HOST_ALLOWLIST</code> with the backslash-separated list
        of hostnames to allow, for example: <code>server-a\server-b</code> to
        allow the hosts <code>server-a</code> and <code>server-b</code>.
      </li>
    </ul>
    <blockquote>
      <p>
        Note: if you are using any of the remote extensions to connect to a
        workspace remotely &#40;such as SSH&#41;, the{' '}
        <code>security.allowedUNCHosts</code> has to be configured on the remote
        machine and not the local machine.
      </p>
    </blockquote>
    <p>
      This change was done to improve the security when using AVAP™ Dev Studio
      with UNC paths. Please refer to the associated security advisory for more
      information.
    </p>
  </Fragment>
);
export default SETUP_Windows;
