import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';

const Interfaz_servicios_pagos_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{
            "status": true, 
            "result":{
                "token": "crd_dev_5bb253f8dd233", 
                "timestamp": "2019-10-11 12:00:00", 
                "codtran": "df850da1cee31731b6095fc533482b5c", 
              }, 
        "elapsed": <float>,}`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
           "status": false, 
           "level": <str>, 
           "message": <str>, 
           "error": { "code": "***", "message": "************" }
          }`,
        copied: false
      },
      answe_ok2: {
        title:"Answer ok",
        type: "json",
        code: `{  
          "status": true, 
          "result":{
              "timestamp": "11/10/2019 12:00:00", 
              "codtran": "8960b3b3ea0a64274fe3f0e2ee31d9b0", 
            }, 
            "elapsed": <float>
          }`,
        copied: false
      },
      answe_ko2: {
        title:"Answer ok",
        type: "json",
        code: `{
            "status": false, 
            "level": <str>, 
            "message": <str>, 
            "error": {
               "code": "***", 
               "message": "************" 
              }
            }`,
        copied: false
      },
      answe_request1: {
        title:"Answer ok",
        type: "json",
        code: `/ws/movimientos.py/comprartarjeta? \
        user_id=12345&country_code=MX \
        &card_data=N8eIGT5raHUXOTvC3jOgV9Hy7jug9omy7CP7f7OGHyPjTIDCTfCrFu7c1qQWMJFeEACAoyutzy4PVsMlWbsld8Z3TejkURvQZOxK1neHlFwK2cpMapYZqtyCFgU1mkU7ILbkixAPUmOmcVQsRnXRl2Aj1ZP2uzUx9wS4rRhidR6n5Orjq5c7tKwybaKGRiQMuG_ZlMx9G06NnlNmmaTRbVq_6Y2dLE6sLXDcgbG_6QT5Tbj7ZSwHnBcu0yqMAZvCQSi4zQZokRYv5fdzp75VYfqCnvQ9O-1Ha4nPssRjPsfD9qDrl55RzaPetT0yc-dq9VMAfpHkVVUqLu3JxCaBKQ== \
        &settlement_id=9999 \
        &runway=001 \
        &amount=1.5 \
        &concept=pago%20prueba \
        &latitude_location=41.40338 \
        &longitude_location=2.17403 \
        &signature=cc30e3efc7159bb10b910512ca441664c1578a4d`,
        copied: false
      },answe_request2: {
        title:"Answer ok",
        type: "json",
        code: `/ws/movimientos.py/comprartarjeta? \
        user_id=12345 \
        &country_code=MX \
        &token=crd_dev_5bb253f8dd233 \
        &settlement_id=9999 \
        &runway=001 \
        &amount=1.5 \
        &concept=pago%20prueba \
        &latitude_location=41.40338 \
        &longitude_location=2.17403 \
        &signature=cc30e3efc7159bb10b910512ca441664c1578a4d`,
        copied: false
      },
      
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="card-payment">
          <strong>Card Payment</strong>
        </h1>
        <p>
          By means of this EndPoint a payment will be made through our payment
          platform. To make a dummy call, the test field must be entered in the
          request querystring, if this field is not entered it will be taken as
          False.
          <br></br>
          <span className="label post">POST:</span>
          <span> </span> <code>URL_BASE + /ws/movimientos.py/comprartarjeta</code>
          <br></br>
        </p>
        <h2 id="receives">Receives:</h2>
        <table>
          <thead>
            <tr>
              <th align="center">Parameter</th>
              <th align="center">Obligatory</th>
              <th align="center">Format</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center">country_code</td>
              <td align="center">Yes</td>
              <td align="center">
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  ISO 3166 alpha-2
                </a>
              </td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="center">user_id</td>
              <td align="center">Yes</td>
              <td align="center">Numeric</td>
              <td align="center">
                Identifier of the user account making the request.
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="center">channel_id</td>
              <td align="center">No</td>
              <td align="center">Numeric</td>
              <td align="center">Channel from which the request is made.</td>
              <td align="center">1 &#40;Web&#41;</td>
            </tr>
            <tr>
              <td align="center">session_id</td>
              <td align="center">
                Yes, if the signature parameter is not reported
              </td>
              <td align="center">Alphanumeric</td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the plataform
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="center">signature</td>
              <td align="center">
                Yes, if the session_id parameter is not reported
              </td>
              <td align="center">Alphanumeric</td>
              <td align="center">
                Allows you to sign the petition, without having to log in
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="center">card_data</td>
              <td align="center">Yes, only the first time a payment is made.</td>
              <td align="center">Text</td>
              <td align="center">Encrypted card data. More information here</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="center">card_number_card</td>
              <td align="center">Yes, only the first time a payment is made.</td>
              <td align="center">Numeric</td>
              <td align="center">Credit / debit card number</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="center">card_cvv</td>
              <td align="center">Yes, only the first time a payment is made.</td>
              <td align="center">Numeric</td>
              <td align="center">Credit / debit card verification code</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="center">expiration_date</td>
              <td align="center">Yes, only the first time a payment is made.</td>
              <td align="center">Text</td>
              <td align="center">
                Credit / debit card expiration date &quot;YYMM&quot;
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="center">token</td>
              <td align="center">No, the first time a payment is made</td>
              <td align="center">Alphanumeric</td>
              <td align="center">
                Token that identifies the card already used in previous payments
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="center">settlement_id</td>
              <td align="center">No</td>
              <td align="center">Numeric</td>
              <td align="center">
                Identifier of the entity on which to make the settlement
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="center">runway</td>
              <td align="center">Yes</td>
              <td align="center">Numeric</td>
              <td align="center">Internal identifier of the gateway</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="center">amount</td>
              <td align="center">Yes</td>
              <td align="center">Numeric</td>
              <td align="center">Amount to be charged from credit / debit card</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="center">concept</td>
              <td align="center">No</td>
              <td align="center">Alphanumeric</td>
              <td align="center">Concept for charging the credit / debit card</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="center">latitude_location</td>
              <td align="center">Yes</td>
              <td align="center">Numeric</td>
              <td align="center">User latitude coordinate</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="center">longitude_location</td>
              <td align="center">Yes</td>
              <td align="center">Numeric</td>
              <td align="center">User longitude coordinate</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="center">test</td>
              <td align="center">No</td>
              <td align="center">Booleano</td>
              <td align="center">Option for testing</td>
              <td align="center">False</td>
            </tr>
          </tbody>
        </table>
        <h3 id="card-data-encryption">Card data encryption:</h3>
        <p>
          This section details the procedure to follow to encrypt the card data that
          will be used to make the payment. During the integration, a private key
          will be provided with which to encrypt the part of the querystring that
          contains all the card data necessary to make the payment.
          <br />
          The string resulting from said encryption process will be reported in the
          &quot;card_data&quot; parameter, detailed in the previous table. As the{' '}
          <em>hashing</em> algorithm, a SHA-256 will be used that will use private /
          public keys generated with RSA of 2048 bits in PEM format. The result of
          applying SHA-256 will be applied a Base 64 Url safe encoding, for example
          &#40;pseudocode&#41;:{' '}
        </p>
    
        <pre className="language-bash">
          <code className="language-bash">
            urlsafe_base64<span className="token punctuation">&#40;</span>sha_256
            <span className="token punctuation">&#40;</span>cadena,
            clave_publica.pem<span className="token punctuation">&#41;&#41;</span>
          </code>
        </pre>
        <p>
          <br></br>
        </p>
        <h3 id="example">
          <strong>Example:</strong>
        </h3>
        <p>
          The parameters to be encrypted in this section must follow the format _URL
          encoding _employed in the rest of the requests.
        </p>
        <p>Card data:</p>
        <ul>
          <li>
            <span >Nombre</span>: Payment test
          </li>
          <li>
            <span >Número</span>: 1111111111111111
          </li>
          <li>
            <span >CVV</span>: 123
          </li>
          <li>
            <span >Expiración</span>: 1910
          </li>
        </ul>
    
        <pre className="">
          <code className="">Parameters in format _URL encoding_:</code>
        </pre>
    
        <pre className="language-bash">
          <code className="language-bash">
            <span className="token assign-left variable">tarjeta_nombre</span>
            <span className="token operator">=</span>Prueba+pago
            <span className="token operator">&amp;</span>
            <span className="token assign-left variable">card_number_card</span>
            <span className="token operator">=</span>
            <span className="token number">1111111111111111</span>
            <span className="token operator">&amp;</span>
            <span className="token assign-left variable">card_cvv</span>
            <span className="token operator">=</span>
            <span className="token number">123</span>
            <span className="token operator">&amp;</span>
            <span className="token assign-left variable">expiration_date</span>
            <span className="token operator">=</span>
            <span className="token number">1910</span>
          </code>
        </pre>
        <p>
          After applying the process described above, the resulting string that will
          be reported in the &quot;card_data&quot; field, would be as follows:
        </p>
    
        <pre className="language-bash">
          <code className="language-bash">
            <span className="token assign-left variable">card_data</span>
            <span className="token operator">=</span>
            N8eIGT5raHUXOTvC3jOgV9Hy7jug9omy7CP7f7OGHyPjTIDCTfCrFu7c1qQWMJFeEACAoyutzy4PVsMlWbsld8Z3TejkURvQZOxK1neHlFwK2cpMapYZqtyCFgU1mkU7ILbkixAPUmOmcVQsRnXRl2Aj1ZP2uzUx9wS4rRhidR6n5Orjq5c7tKwybaKGRiQMuG_ZlMx9G06NnlNmmaTRbVq_6Y2dLE6sLXDcgbG_6QT5Tbj7ZSwHnBcu0yqMAZvCQSi4zQZokRYv5fdzp75VYfqCnvQ9O-1Ha4nPssRjPsfD9qDrl55RzaPetT0yc-dq9VMAfpHkVVUqLu3JxCaBKQ
            <span className="token operator">==</span>
          </code>
        </pre>
        <p>
          <br></br>
        </p>
        <h3 id="business-logic">Business Logic:</h3>
        <p>
          This section details some particularities related to this service that it
          is advisable to take into account:
        </p>
        <ol>
          <li>
            The <code>settlement_id</code> parameter is not mandatory but it is
            highly recommended to indicate the final recipient of the amount.
          </li>
          <li>
            The <code>test</code> parameter may or may not be included in the
            request URL. By sending this parameter, fixed data will be returned,
            without actually processing the actual payment.
          </li>
          <li>
            The <code>latitude_location</code> and <code>longitude_location</code>{' '}
            parameters are necessary to know the location of the person making the
            payment. These parameters must have the format of decimal degrees,
            latitude being a value between 90 and -90, and longitude a value between
            180 and -180, to separate the decimals, use a &quot;.&quot;.{' '}
          </li>
        </ol>
        </div>
        <div className='col-md-5'>
        <h3 id="example-requests">Example requests:</h3>
        <p>
          If you do not have a card token &#40;that is, it is the first payment made
          with a card&#41;, it is necessary to inform the parameter
          &quot;card_data&quot; as described in the previous section:
        </p>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_request1}/>
          </div>
        <h3 id="example-ok-answer">Example OK answer:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h3 id="example-ko-answer">Example KO answer:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <p>
          <br></br>
          If the card token is available, the call will be made based on it:
          <br></br>
        </p>
        <h3 id="example-request">Example request:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_request2}/>
          </div>
        <h3 id="example-ok-answer-1">Example OK answer:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok2}/>
          </div>
        <h3 id="example-ko-answer-1">Example KO answer:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko2}/>
          </div>
        <p>
          <br></br>
        </p>
        
        </div>
        </div>
      </Fragment>
    );
  }
export default Interfaz_servicios_pagos_EN;
