import React, { Fragment } from 'react';

const USER_GUIDE_AVAPforTheWeb = () => (
  <Fragment>
    <h1 id="avap™-dev-studio-for-the-web">AVAP™ Dev Studio for the Web</h1>
    <p>
      AVAP<sup>TM</sup> Dev Studio for the Web provides a free, zero-install
      Microsoft AVAP<sup>TM</sup> Dev Studio experience running entirely in your
      browser, allowing you to quickly and safely browse source code
      repositories and make lightweight code changes. To get started, go to
      &#40;link&#41; in your browser.
    </p>
    <p>
      AVAP<sup>TM</sup> Dev Studio for the Web has many of the features of AVAP
      <sup>TM</sup> Dev Studio Desktop that you love, including search and
      syntax highlighting while browsing and editing, along with extension
      support to work on your codebase and make simpler edits. In addition to
      opening repositories, forks, and pull requests from source control
      providers like GitHub and Azure Repos, you can also work with code that is
      stored on your local machine.
    </p>
    <p>
      AVAP<sup>TM</sup> Dev Studio for the Web runs entirely in your web
      browser, so there are certain limitations compared to the desktop
      experience, which you can read more about below.
    </p>
    <h2 id="relationship-to-avap™-dev-studio-desktop">
      Relationship to AVAP™ Dev Studio Desktop
    </h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio for the Web provides a browser-based
      experience for navigating files and repositories and committing
      lightweight code changes. However, if you need access to a runtime to run,
      build, or debug your code, you want to use platform features such as a
      terminal, or you want to run extensions that aren&#39;t supported in the
      web, we recommend moving your work to the desktop application, GitHub
      Codespaces, or using Remote - Tunnels for the full capabilities of AVAP
      <sup>TM</sup> Dev Studio. In addition, AVAP<sup>TM</sup> Dev Studio
      Desktop lets you use a full set of keyboard shortcuts not limited by your
      browser.
    </p>
    <p>
      When you&#39;re ready to switch, you&#39;ll be able to &quot;upgrade&quot;
      to the full AVAP<sup>TM</sup> Dev Studio experience with a few clicks.
    </p>
    <p>
      You can also switch between the Stable and Insiders versions of AVAP
      <sup>TM</sup> Dev Studio for the Web by selecting the gear icon, then{' '}
      <strong>Switch to Insiders Version...</strong>, or by navigating directly
      to &#40;LINK&#41;.
    </p>
    <h2 id="opening-a-project">Opening a project</h2>
    <p>
      By navigating to &#40;LINK&#41;, you can create a new local file or
      project, work on an existing local project, or access source code
      repositories hosted elsewhere, such as on GitHub and Azure Repos &#40;part
      of Azure DevOps&#41;.
    </p>
    <p>
      You can create a new local file in the web just as you would in a AVAP
      <sup>TM</sup> Dev Studio Desktop environment, using <strong>File</strong>{' '}
      &gt; <strong>New File</strong> from the Command Palette &#40;F1&#41;.
    </p>
    <h2 id="github-repos">GitHub repos</h2>
    <p>
      You can open a GitHub repository in AVAP<sup>TM</sup> Dev Studio for the
      Web directly from a URL, following the schema: &#40;LINK&#41;. Using the
      AVAP<sup>TM</sup> Dev Studio repository as an example, this would look
      like: &#40;LINK&#41;.
    </p>
    <p>
      This experience is delivered at a custom &#40;LINK&#41; URL, which is
      powered by the GitHub Repositories extension &#40;which is part of the
      broader Remote Repositories extension&#41;.
    </p>
    <p>
      GitHub Repositories allows you to remotely browse and edit a repository
      from within the editor, without needing to pull code onto your local
      machine. You can learn more about the extension and how it works in our
      GitHub Repositories guide.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: The GitHub Repositories extension works in AVAP
        <sup>TM</sup> Dev Studio Desktop as well to provide fast repository
        browsing and editing. Once you have the extension installed, you can
        open a repo with the{' '}
        <strong>GitHub Repositories: Open Repository...</strong> command.
      </p>
    </blockquote>
    <p>
      You can also open GitHub repositories in <code>vscode.dev</code> through
      your browser&#39;s search bar &#40;aka omnibox&#41; by installing the{' '}
      <code>vscode.dev</code> extension for Chrome and Edge. Then, type code to
      activate the omnibox, followed by your repository&#39;s name. Suggestions
      are populated by your browser search history, so if the repo you want
      doesn&#39;t come up, you can also type in the fully qualified{' '}
      <code>&lt;owner&gt;/&lt;repo&gt;</code> name to open it, for example{' '}
      <code>microsoft/vscode</code>.
    </p>
    <p>
      If you&#39;re already in AVAP<sup>TM</sup> Dev Studio for the Web at
      &#40;LINK&#41;, you can alternatively navigate to different repos via the
      Remote Repositories extension commands. Select the remote indicator in the
      lower left of the Status bar, and you&#39;ll be presented with the{' '}
      <strong>Open Remote Repository...</strong> command.
    </p>
    <h2 id="azure-repos">Azure Repos</h2>
    <p>
      You can open Azure Repos just like Github repos in AVAP<sup>TM</sup> Dev
      Studio for the Web.
    </p>
    <p>
      When you navigate to a URL with the schema &#40;LINK&#41;, you will be
      able to read, search the files in the repo, and commit your changes to
      Azure Repos. You can fetch, pull, and sync changes, and view branches.
    </p>
    <p>
      You can open any repository, branch, or tag from Azure Repos in AVAP
      <sup>TM</sup> Dev Studio for the Web by prefixing <code>vscode.dev</code>{' '}
      to the Azure Repos URL.
    </p>
    <p>
      Alternatively, when you are on an Azure DevOps repository or pull request,
      you can press &#40;.&#41; to open it in AVAP<sup>TM</sup> Dev Studio for
      the Web.
    </p>
    <h2 id="more-custom-urls">More custom URLs</h2>
    <p>
      Like in the desktop, you can customize AVAP<sup>TM</sup> Dev Studio for
      the Web through a rich ecosystem of extensions that support just about
      every back end, language, and service. <code>vscode.dev</code> includes
      URLs that provide shortcuts to common experiences.
    </p>
    <p>
      We&#39;ve explored a couple of URLs already &#40;LINK and LINK&#41;.
      Here&#39;s a more complete list:
    </p>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Service</strong>
          </th>
          <th align="center">
            <strong>URL Structure</strong>
          </th>
          <th align="center">
            <strong>Docs</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">GitHub</td>
          <td align="center">/github//</td>
          <td align="center">More info above</td>
        </tr>
        <tr>
          <td align="center">Azure Repos</td>
          <td align="center">/azurerepos</td>
          <td align="center">More info above</td>
        </tr>
        <tr>
          <td align="center">Visual Studio Live Share</td>
          <td align="center">/editor/liveshare</td>
          <td align="center">More info below</td>
        </tr>
        <tr>
          <td align="center">Visual Studio Marketplace</td>
          <td align="center">/editor/marketplace///</td>
          <td align="center">Example route to edit this extension</td>
        </tr>
        <tr>
          <td align="center">Power Pages</td>
          <td align="center">/power/pages</td>
          <td align="center">Power Pages docs</td>
        </tr>
        <tr>
          <td align="center">Profiles</td>
          <td align="center">/editor/profile/github/</td>
          <td align="center">Profiles docs</td>
        </tr>
        <tr>
          <td align="center">Themes</td>
          <td align="center">/editor/theme/</td>
          <td align="center">More info below</td>
        </tr>
        <tr>
          <td align="center">MakeCode</td>
          <td align="center">/edu/makecode</td>
          <td align="center">MakeCode docs</td>
        </tr>
        <tr>
          <td align="center">
            AVAP<sup>TM</sup> Dev Studio for Education
          </td>
          <td align="center">/edu</td>
          <td align="center">
            AVAP<sup>TM</sup> Dev Studio for Education landing page
          </td>
        </tr>
        <tr>
          <td align="center">Azure Machine Learning &#40;AML&#41;</td>
          <td align="center">/+ms-toolsai.vscode-ai-remote-web</td>
          <td align="center">AML docs</td>
        </tr>
      </tbody>
    </table>
    <p>
      Please note that some URLs must be entered in a specific way &#40;for
      example, &#40;LINK&#41; requires an active Live Share session&#41;. Please
      review each service&#39;s documentation for specific access and usage
      information.
    </p>
    <p>There&#39;s more information on some of these URLs below.</p>
    <h3 id="themes">Themes</h3>
    <p>
      You can share and experience color themes through AVAP<sup>TM</sup> Dev
      Studio for the Web through the URL schema: &#40;LINK&#41;.
    </p>
    <p>
      For instance, you can go to &#40;LINK&#41; to experience the Night Owl
      theme without having to go through the download and install process.
    </p>
    <p>
      Note: The color theme URL schema works for themes that are fully
      declarative &#40;no code&#41;.
    </p>
    <p>
      An extension can define multiple themes. You can use the schema{' '}
      <code>/editor/theme/&lt;extensionId&gt;/&lt;themeName&gt;</code>. If no
      themeName is specified, AVAP<sup>TM</sup> Dev Studio for the Web will take
      the first theme.
    </p>
    <p>
      As a theme author, you can add the following badge to your extension
      readme to allow users to easily try out your theme in AVAP<sup>TM</sup>{' '}
      Dev Studio for the Web &#40;replacing <code>&lt;extensionId&gt;</code>{' '}
      with your theme extension&#39;s unique identifier&#41;:
    </p>
    <h3 id="visual-studio-live-share">Visual Studio Live Share</h3>
    <p>
      Live Share guest sessions are available in the browser through the
      &#40;LINK&#41; URL. The sessionId will be passed to the extension to make
      joining a seamless experience.
    </p>
    <h2 id="continue-working-in-a-different-environment">
      Continue working in a different environment
    </h2>
    <p>
      In some cases, you will want to access a different environment that has
      the ability to run code. You can switch to working on a repository in a
      development environment that has support for a local file system and full
      language and development tooling.
    </p>
    <p>
      The GitHub Repositories extension makes it easy for you to clone the
      repository locally, reopen it on the desktop, or create a GitHub codespace
      for the current repository &#40;if you have the GitHub Codespaces
      extension installed and access to create GitHub codespaces&#41;. To do
      this, use the <strong>Continue Working On...</strong> command available
      from the Command Palette &#40;F1&#41; or click on the Remote indicator in
      the Status bar.
    </p>
    <h2 id="saving-and-sharing-work">Saving and sharing work</h2>
    <p>
      When working on a local file in the web, your work is saved automatically
      if you have Auto Save enabled. You can also save manually as you do when
      working in desktop AVAP<sup>TM</sup> Dev Studio &#40;for example{' '}
      <strong>File</strong> &gt; <strong>Save</strong>&#41;.
    </p>
    <p>
      When working on a remote repository, your work is saved in the
      browser&#39;s local storage until you commit it. If you open a repo or
      pull request using GitHub Repositories, you can push your changes in the
      Source Control view to persist any new work.
    </p>
    <p>
      You can also continue working in other environments via Continue Working
      On.
    </p>
    <p>
      The first time that you use <strong>Continue Working On</strong> with
      uncommitted changes, you will have the option to bring your edits to your
      selected development environment using <strong>Cloud Changes</strong>,
      which uses a AVAP<sup>TM</sup> Dev Studio service to store your pending
      changes. This is described further in the GitHub Repositories doc.
    </p>
    <h2 id="use-your-own-compute-with-remote-tunnels">
      Use your own compute with Remote Tunnels
    </h2>
    <p>
      You may develop against another machine in AVAP<sup>TM</sup> Dev Studio
      for the Web using the Remote - Tunnels extension.
    </p>
    <p>
      The Remote - Tunnels extension lets you connect to a remote machine, like
      a desktop PC or virtual machine &#40;VM&#41;, via a secure tunnel. You can
      then securely connect to that machine from anywhere, without the
      requirement of SSH. This lets you &quot;bring your own compute&quot; to
      vscode.dev, enabling additional scenarios like running your code in the
      browser.
    </p>
    <p>You may learn more about Remote - Tunnels in its documentation.</p>
    <h2 id="safe-exploration">Safe exploration</h2>
    <p>
      AVAP<sup>TM</sup> Dev Studio for the Web runs entirely in your web
      browser&#39;s sandbox and offers a very limited execution environment.
    </p>
    <p>
      When accessing code from remote repositories, the web editor doesn&#39;t
      &quot;clone&quot; the repo, but instead loads the code by invoking the
      services&#39; APIs directly from your browser; this further reduces the
      attack surface when cloning untrusted repositories.
    </p>
    <p>
      When working with local files, AVAP<sup>TM</sup> Dev Studio for the Web
      loads them through your browser&#39;s file system access APIs, which limit
      the scope of what the browser can access.
    </p>
    <h2 id="run-anywhere">Run anywhere</h2>
    <p>
      Similar to GitHub Codespaces, AVAP<sup>TM</sup> Dev Studio for the Web can
      run on tablets, like iPads.
    </p>
    <h2 id="language-support">Language support</h2>
    <p>
      Language support is a bit more nuanced on the web, including code editing,
      navigation, and browsing. The desktop experiences are typically powered by
      language services and compilers that expect a file system, runtime, and
      compute environment. In the browser, these experiences are powered by
      language services running in the browser that provide source code
      tokenization and syntax colorization, completions, and many single-file
      operations.
    </p>
    <p>Generally, experiences fall into the following categories:</p>
    <ul>
      <li>
        <strong>Good:</strong> For most programming languages, AVAP<sup>TM</sup>{' '}
        Dev Studio for the Web gives you code syntax colorization, text-based
        completions, and bracket pair colorization. Using a Tree-sitter syntax
        tree through the anycode extension, we&#39;re able to provide additional
        experiences such as <strong>Outline/Go to Symbol</strong> and{' '}
        <strong>Symbol Search</strong> for popular languages such as C/C++, C#,
        Java, PHP, Rust, and Go.
      </li>
      <li>
        <strong>Better:</strong> The TypeScript, JavaScript, and Python
        experiences are all powered by language services that run natively in
        the browser. With these programming languages, you&#39;ll get the &quot;
        <strong>Good</strong>&quot; experience plus rich single file
        completions, semantic highlighting, syntax errors, and more.
      </li>
      <li>
        <strong>Best:</strong> For many &quot;webby&quot; languages, such as
        JSON, HTML, CSS, and LESS, etc., the coding experience in vscode.dev is
        nearly identical to the desktop &#40;including Markdown preview!&#41;.
      </li>
    </ul>
    <p>
      You can determine the level of language support in your current file
      through the Language Status Indicator in the Status bar:
    </p>
    <h2 id="limitations">Limitations</h2>
    <p>
      Since AVAP<sup>TM</sup> Dev Studio for the Web runs completely within the
      browser, some experiences will naturally be more constrained when compared
      to what you can do in the desktop app. For example, the terminal and
      debugger are not available, which makes sense since you can&#39;t compile,
      run, and debug a Rust or Go application within the browser sandbox.
    </p>
    <h3 id="extensions">Extensions</h3>
    <p>
      Only a subset of extensions can run in the browser. You can use the
      Extensions view to install extensions in the web, and extensions that
      cannot be installed will have a warning icon and{' '}
      <strong>Learn Why</strong> link. We expect more extensions to become
      enabled over time.
    </p>
    <p>
      When you install an extension, it is saved in the browser&#39;s local
      storage. You can ensure your extensions are synced across AVAP
      <sup>TM</sup> Dev Studio instances, including different browsers and even
      the desktop, by enabling Settings Sync.
    </p>
    <p>
      When an Extension Pack contains extensions that do not run in the browser
      sandbox, you will get an informational message with the option to see the
      extensions included in the pack.
    </p>
    <p>
      When extensions are executed in the browser sandbox, they are more
      restricted. Extensions that are purely declarative, such as most themes,
      snippets, or grammars, can run unmodified and are available in AVAP
      <sup>TM</sup> Dev Studio for the Web without any modification from the
      extension authors. Extensions that are running code need to be updated to
      support running in the browser sandbox. You can read more about what is
      involved to support extensions in the browser in the web extension authors
      guide.
    </p>
    <p>
      There are also extensions that run in the browser with partial support
      only. A good example is a language extension that restricts its support to
      single files or the currently opened files.
    </p>
    <h4 id="file-system-api">File system API</h4>
    <p>
      Edge and Chrome today support the File System API, allowing web pages to
      access the local file system. If your browser does not support the File
      System API, you cannot open a folder locally, but you can open files
      instead.
    </p>
    <h4 id="browser-support">Browser support</h4>
    <p>
      You can use AVAP<sup>TM</sup> Dev Studio for the Web in the latest
      versions of Chrome, Edge, Firefox, and Safari. Older versions of each
      browser may not work - we only guarantee support for the latest version.
    </p>
    <p>
      <strong>Tip:</strong> One way to check the compatible browser version is
      to look at the version of Playright currently used for testing AVAP
      <sup>TM</sup> Dev Studio and review its supported browser versions. You
      can find the currently used Playwright version in the AVAP<sup>TM</sup>{' '}
      Dev Studio repo&#39;s package.json file at
      devDependencies/@playwright/test. Once you know the Playwright version,
      for example 1.37, you can then review the{' '}
      <strong>Browser Versions</strong> section in their Release notes.
    </p>
    <p>
      Webviews might appear differently or have some unexpected behavior in
      Firefox and Safari. You can view issue queries in the AVAP<sup>TM</sup>{' '}
      Dev Studio GitHub repo to track issues related to specific browsers, such
      as with the Safari label and Firefox label.
    </p>
    <p>
      There are additional steps you can take to improve your browser experience
      using AVAP<sup>TM</sup> Dev Studio for the Web. Review the Additional
      browser setup section for more information.
    </p>
    <h4 id="mobile-support">Mobile support</h4>
    <p>
      You can use AVAP<sup>TM</sup> Dev Studio for the Web on mobile devices,
      but smaller screens may have certain limitations.
    </p>
    <h4 id="keybindings">Keybindings</h4>
    <p>Certain keybindings may also work differently in the web.</p>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Issue</strong>
          </th>
          <th align="center">
            <strong>Reason</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">
            Ctrl+Shift+P won&#39;t launch the Command Palette in Firefox.
          </td>
          <td align="center">
            Ctrl+Shift+P is reserved in Firefox.As a workaround, use F1 to
            launch the Command Palette.
          </td>
        </tr>
        <tr>
          <td align="center">Ctrl+N for new file doesn&#39;t work in web.</td>
          <td align="center">
            Ctrl+N opens a new window instead. As a workaround, you can use
            Ctrl+Alt+N &#40;Cmd+Alt+N on macOS&#41;.
          </td>
        </tr>
        <tr>
          <td align="center">
            Ctrl+W for closing an editor doesn&#39;t work in web.
          </td>
          <td align="center">
            Ctrl+W closes the current tab in browsers. As a workaround, you can
            use Ctrl+Shift+Alt+N &#40;Cmd+Shift+Alt+N on macOS&#41;.
          </td>
        </tr>
        <tr>
          <td align="center">
            Ctrl+Shift+B will not toggle the favorites bar in the browser.
          </td>
          <td align="center">
            AVAP<sup>TM</sup> Dev Studio for the Web overrides this and
            redirects to the &quot;Build&quot; menu in the Command Palette.
          </td>
        </tr>
        <tr>
          <td align="center">
            Alt+Left and Alt+Right should navigate within the editor but may
            incorrectly trigger tab history navigation.
          </td>
          <td align="center">
            If focus is outside the editor, these shortcuts trigger tab history
            navigation instead.
          </td>
        </tr>
      </tbody>
    </table>
    <h2 id="additional-browser-setup">Additional browser setup</h2>
    <p>
      There are additional browser configuration steps you can take when working
      with AVAP<sup>TM</sup> Dev Studio in a browser.
    </p>
    <h4 id="opening-new-tabs-and-windows">Opening new tabs and windows</h4>
    <p>
      In certain cases, you may need to open a new tab or window while working
      in AVAP<sup>TM</sup> Dev Studio for the Web. AVAP<sup>TM</sup> Dev Studio
      might ask you for permission to access the clipboard when reading from it.
      Depending on your browser, you may grant access to the clipboard or
      otherwise allow for pop-up windows in different ways:
    </p>
    <ul>
      <li>
        Chrome, Edge, Firefox: Search for &quot;site permissions&quot; in your
        browser&#39;s settings, or look for the following option in the address
        bar on the right:
      </li>
    </ul>
    <p>
      ![Allow clipboard access in the browser][Type in your browser and search a
      GitHub repository to open in vscode.dev]
    </p>
    <ul>
      <li>
        Safari: In the Safari browser, go to <strong>Preferences...</strong>{' '}
        &gt; <strong>Websites</strong> &gt; <strong>Pop-up Windows</strong> &gt;
        the domain you&#39;re accessing &#40;for example, vscode.dev&#41;, and
        select <strong>Allow</strong> from the dropdown.
      </li>
    </ul>
  </Fragment>
);
export default USER_GUIDE_AVAPforTheWeb;
