import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Servicio_de_edicion_de_perfilesP = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{ "status": <boolean>, 
        "codtran": <string>, 
        "result": 
        { "editor": 
        { "menu": [ 
          { "name": <string>, 
          "type_of_action_id": <integer>, 
          "options": [ <accion> ] } ], 
          "available": [ { "type_of_action_id": <integer>, 
          "point_of_entry": <string>, 
          "editorprofiles_profiles": [ <string> ], 
          "action_description": <string>, 
          "action_id": <integer>, 
          "name": <string>, 
          "editorprofiles_ignore": <boolean> } ], 
          "profile_id": <integer> } }, 
          "elapsed": <float> }`,
        copied: false
      },
      answe_ko2: {
        title:"Answer ok",
        type: "json",
        code: `{ "status": <boolean>, 
        "nivel": <string>, 
        "message": <string>, 
        "error": <string> }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/admin.py/editorperfiles" 
              payload = { 'country_code': 'MX', 
              'user_id': '4532', 
              'session_id': '397-GuC6yCVxioNNrCTqpxgCgA==',
              'profile_id': '136', 
              'command': 'put', 
              'action_id': '9', 
              'type_of_action_id': '12' } 
            files = [] headers= {} response = requests.request("POST", url, headers=headers, data = payload, files = files) 
            print(response.text.encode('utf8'))
        `
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests url = "URL_BASE/ws/admin.py/editorperfiles" 
          payload = { 'country_code': 'MX', 
          'user_id': '4532', 
          'session_id': '397-GuC6yCVxioNNrCTqpxgCgA==',
          'profile_id': '136', 
          'command': 'put', 
          'action_id': '9', 
          'type_of_action_id': '12' } 
        files = [] headers= {} response = requests.request("POST", url, headers=headers, data = payload, files = files) 
        print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: ` var request = require('request');
              var options = {
              'method': 'POST',
              'url': 'URL_BASE/ws/admin.py/editorperfiles',
              'headers': {
              },
              formData: {
              'country_code': 'MX',
              'user_id': '4532',
              'session_id': '397-GuC6yCVxioNNrCTqpxgCgA==',
              'profile_id': '136',
              'command': 'put',
              'action_id': '9',
              'type_of_action_id': '12'
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: ` var formdata = new FormData();
              formdata.append("country_code", "MX");
              formdata.append("user_id", "4532");
              formdata.append("session_id", "397-GuC6yCVxioNNrCTqpxgCgA==");
              formdata.append("profile_id", "136");
              formdata.append("command", "put");
              formdata.append("action_id", "9");
              formdata.append("type_of_action_id", "12");
              var requestOptions = {
              method: 'POST',
              body: formdata,
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/admin.py/editorperfiles", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request POST 'URL_BASE/ws/admin.py/editorperfiles' \ --form 'country_code=MX' \ --form 'user_id=4532' \ --form 'session_id=397-GuC6yCVxioNNrCTqpxgCgA==' \ --form 'profile_id=136' \ --form 'command=put' \ --form 'action_id=9' \ --form 'type_of_action_id=12`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="modify-profile-add-action">Modify Profile &#40;Add Action&#41;</h1>
        <p>
          This service is used to modify actions available for a certain profile.
        </p>
        <p>
          <span className="label post">POST:</span>
          <span> </span> <code>URL_BASE + /ws/admin.py/editorperfiles</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request
        </p>
        <div className='overflow-auto'>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  ISO 3166 alpha-2
                </a>
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">Identifier of the user making the request</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">profile_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Identifier of the profile to modify &#40;obtained from{' '}
                <code>/ws/util.py/perfiles</code>&#41;
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">command</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                It must containe the value <code>put</code>
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">action_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Identifier of the action to be added to the profile
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">type_of_action_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Identifier of <code>type_of_action</code> to which the action is to
                be added
              </td>
              <td align="center">N/A</td>
            </tr>
          </tbody>
        </table>
        </div>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;
          </li>
          <li>
            <code>codtran:</code> Transaction code that identifies the executed
            operation
          </li>
          <li>
            <code>result:</code> Executed service action
          </li>
          <li>
            <code>editor:</code> Object that contains the actions assigned to the
            profile, and those that can be added
          </li>
          <li>
            <code>menu:</code> List with the different categories &#40;Types of
            action&#41;
          </li>
          <li>
            <code>name:</code> Category name
          </li>
          <li>
            <code>type_of_action_id:</code> Category identifier &#40;Type of
            action&#41;
          </li>
          <li>
            <code>options:</code> List of actions that belong to this category of
            the profile
          </li>
          <li>
            <code>available:</code> List of actions that are not associated to the
            profile
          </li>
          <li>
            <code>type_of_action_id:</code> Category identifier &#40;Type of
            action&#41;
          </li>
          <li>
            <code>point_of_entry:</code> Name of the entry point on the platform
          </li>
          <li>
            <code>editorprofiles_profiles:</code> List of user roles that can see
            this action
          </li>
          <li>
            <code>action_description:</code> Descriptve text of the action.
          </li>
          <li>
            <code>action_id:</code> Action identifier
          </li>
          <li>
            <code>name:</code> Action name
          </li>
          <li>
            <code>editorprofiles_ignore:</code>Boolean that indicates whether this
            action should be displayed in the editor or not.
          </li>
          <li>
            <code>elapsed:</code> Execution operation time.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko2}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>nivel:</code> Error importance level.
          </li>
          <li>
            <code>message:</code> Error message.
          </li>
          <li>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          In order to run this service it is necessary to do it from a user logged
          into the system who has an administrator profile. With this endpoint it
          allows you to add a new action to the selected profile with the desired
          type of action and it responds with the updated profile data.
        </p>
        </div>

      <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
        </div>
      </Fragment>
    );
  }
export default Servicio_de_edicion_de_perfilesP;
