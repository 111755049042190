import React, { Fragment } from 'react';

const Currencies_And_Fx_Exchange = () => (
  <Fragment>
    <h1>Currencies and FX Exchange</h1>
    <p>
      Within a node or project, two distinct modules exist, and understanding
      their differences is crucial to prevent confusion. We are talking about
      the Currencies and FX Exchange modules.
    </p>
    <p>
      A node or project works with a location and a currency, information that
      is provided at the time of creation of a node with the activation of a
      project.
    </p>
    <p>
      The currency selected when the node or project is created is the only
      currency with which the node will operate until the administrator decides
      to create or register new currencies.
    </p>
    <p>
      That is, a node can be configured in a specific location, the United
      States for example, and select USD as the currency for the node or
      project. From that moment on, all operations carried out in that node or
      project will be recorded with that currency. If later you want other
      currencies to exist, such as EUR, you must register the request in the
      node or project as an authorized currency.
    </p>
    <p>
      This task is carried out in the Currencies section which can be found in
      the node or project tab or in the side menu under the Projects section.
    </p>
    <p>
      In this same section you can create your own currencies and assign them a
      value with a purchase and sale price.
    </p>
    <p>
      Working with a loyalty solution based on the accumulation of customer
      points, based on their activity, requires registering those points as a
      form of currency and giving them a value. In this way, the client will
      always be able to use that points wallet in the operation network, thanks
      to the FX Exchange service.
    </p>
    <p>
      The mission of the FX Exchange service is to maintain a list of
      currencies, the reference price, the purchase price, and the sale price,
      thus allowing multi-currency operations.
    </p>
  </Fragment>
);
export default Currencies_And_Fx_Exchange;
