import React, { Fragment } from 'react';

const TERMINAL_TerminalProfiles = () => (
  <Fragment>
    <h1 id="terminal-profiles">Terminal Profiles</h1>
    <p>
      Terminal profiles are platform-specific shell configurations comprised of
      an executable path, arguments, and other customizations. By default
      several profiles are automatically detected which can be customized or
      added to.
    </p>
    <p>Example profile:</p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"terminal.integrated.profiles.windows": &#123;
        <br />` `"Custom Init": &#123;
        <br />` `"path": "pwsh.exe",
        <br />` `"args": [<br />` `"-noexit",
        <br />` `"-file",
        <br />` `"$&#123;env:APPDATA&#125;\\PowerShell\\custom-init.ps1"
        <br />` `]
        <br />` `&#125;
        <br />` `&#125;,
        <br />` `"terminal.integrated.defaultProfile.windows": "Custom Init"
        <br />
        &#125;
      </code>
    </pre>
    <p>
      You can use variables in terminal profiles as shown in the example above
      with the <code>APPDATA</code> environment variable. There is a list of
      available variables in the{' '}
      <a href="/docs/editor/variables-reference">Variables Reference</a> topic.
    </p>
    <p>
      Configure your default profile by running the{' '}
      <strong>Terminal: Select Default Profile</strong> command, which is also
      accessible via the new terminal dropdown.
    </p>
    <p>
      The default terminal profile shell defaults to <code>$SHELL</code> on
      Linux and macOS and PowerShell on Windows. AVAP<sup>TM</sup> Dev Studio
      will automatically detect most standard shells that can then be configured
      as the default.
    </p>
    <h2 id="configuring-profiles">Configuring profiles</h2>
    <p>
      To create a new profile, run the{' '}
      <strong>Terminal: Select Default Profile</strong> command and activate the
      configure button on the right side of the shell to base it on. This will
      add a new entry to your settings that can be tweaked manually in your{' '}
      <code>settings.json</code> file.
    </p>
    <p>
      Profiles can be created using either a <code>path</code> or a{' '}
      <code>source</code>, as well as a set of optional arguments. A{' '}
      <code>source</code> is available only on Windows and can be used to let
      AVAP<sup>TM</sup> Dev Studio detect the install of either{' '}
      <code>PowerShell</code> or <code>Git Bash</code>. Alternatively, a{' '}
      <code>path</code> pointing directly to the shell executable can be used.
      Here are some example profile configurations:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"terminal.integrated.profiles.windows": &#123;
        <br />` `"PowerShell -NoProfile": &#123;
        <br />` `"source": "PowerShell",
        <br />` `"args": ["-NoProfile"]
        <br />` `&#125;
        <br />` `&#125;,
        <br />` `"terminal.integrated.profiles.linux": &#123;
        <br />` `"zsh &#40;login&#41;": &#123;
        <br />` `"path": "zsh",
        <br />` `"args": ["-l"]
        <br />` `&#125;
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>Other arguments supported in profiles include:</p>
    <ul>
      <li>
        <code>overrideName</code>: A boolean indicating whether or not to
        replace the dynamic terminal title that detects what program is running
        with the static profile name.
      </li>
      <li>
        <code>env</code>: A map defining environment variables and their values,
        set the variable to null to delete it from the environment. This can be
        configured for all profiles using the{' '}
        <code>terminal.integrated.env.&lt;platform&gt;</code> setting.
      </li>
      <li>
        <code>icon</code>: An icon ID to use for the profile.
      </li>
      <li>
        <code>color</code>: A theme color ID to style the icon.
      </li>
    </ul>
    <blockquote>
      <p>
        <strong>Tip:</strong> Path, args, and env all support [resolving
        variables]
      </p>
    </blockquote>
    <p>
      The <strong>default profile</strong> can be defined manually with the{' '}
      <code>terminal.integrated.defaultProfile.\*</code> settings. This should
      be set to the name of an existing profile:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"terminal.integrated.profiles.windows": &#123;
        <br />` `"my-pwsh": &#123;
        <br />` `"source": "PowerShell",
        <br />` `"args": ["-NoProfile"]
        <br />` `&#125;
        <br />` `&#125;,
        <br />` `"terminal.integrated.defaultProfile.windows": "my-pwsh"
        <br />
        &#125;
      </code>
    </pre>
    <blockquote>
      <p>
        <strong>Tip:</strong> The integrated terminal shell is running with the
        permissions of AVAP<sup>TM</sup> Dev Studio. If you need to run a shell
        command with elevated &#40;administrator&#41; or different permissions,
        use platform utilities such as <code>runas.exe</code> within a terminal.
      </p>
    </blockquote>
    <h2 id="removing-built-in-profiles">Removing built-in profiles</h2>
    <p>
      To remove a built-in profile and prevent it from showing up in the new
      terminal dropdown, set the name of the profile to <code>null</code>. For
      example, to remove the <code>Git Bash</code> profile on Windows, use this
      setting:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"terminal.integrated.profiles.windows": &#123;
        <br />` `"Git Bash": null
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <h2 id="configuring-the-taskdebug-profile">
      Configuring the task/debug profile
    </h2>
    <p>
      By default, the task/debug features will use the default profile. This may
      not be ideal if your default has a heavy PowerShell startup script or a
      non-POSIX compliant shell for example. To configure a profile to be used
      only in the debug/tasks features, use the{' '}
      <code>terminal.integrated.automationProfile</code>.
      <code>&lt;platform&gt;</code> setting:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"terminal.integrated.defaultProfile.osx": "fish",
        <br />` `// Use a fully POSIX-compatible shell and avoid running a
        complex ~/.config/fish/config.fish
        <br />` `// for tasks and debug
        <br />` `"terminal.integrated.automationProfile.osx": &#123;
        <br />` `"path": "/bin/sh"
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <h2 id="unsafe-profile-detection">Unsafe profile detection</h2>
    <p>
      Certain shells are installed in unsafe paths by default, like a path that
      could be written to by another user on a Windows environment. AVAP
      <sup>TM</sup> Dev Studio will still detect these but not expose them as a
      proper profile until they have been explicitly configured via the{' '}
      <strong>Terminal: Select Default Profile</strong> command. When
      configuring an unsafe profile, there will be a warning before it&#39;s
      added:
    </p>
    <h2 id="cmder">Cmder</h2>
    <p>
      Cmder itself is a terminal, but you can use the [Cmder] shell in AVAP
      <sup>TM</sup> Dev Studio with the following profile:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"terminal.integrated.profiles.windows": &#123;
        <br />` `"cmder": &#123;
        <br />` `"path": "C:\\WINDOWS\\System32\\cmd.exe",
        <br />` `"args": ["/K", "C:\\cmder\\vendor\\bin\\vscode\_init.cmd"]
        <br />` `&#125;
        <br />` `&#125;,
        <br />` `"terminal.integrated.defaultProfile.windows": "cmder"
        <br />
        &#125;
      </code>
    </pre>
    <p>
      This profile should be picked up automatically when the{' '}
      <code>CMDER\_ROOT</code> environment variable is set. It will also be
      detected as an unsafe profile if installed at <code>C:\cmder</code>. You
      may refer to Cmder&#39;s wiki for more information.
    </p>
    <h2 id="cygwin">Cygwin</h2>
    <p>
      Cygwin itself is a terminal, but you can use the Cygwin shell in AVAP
      <sup>TM</sup> Dev Studio with the following profile:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"terminal.integrated.profiles.windows": &#123;
        <br />` `"Cygwin": &#123;
        <br />` `"path": "C:\\cygwin64\\bin\\bash.exe",
        <br />` `"args": ["--login"]
        <br />` `&#125;
        <br />` `&#125;,
        <br />` `"terminal.integrated.defaultProfile.windows": "Cygwin"
        <br />
        &#125;
      </code>
    </pre>
    <p>
      This profile should be detected automatically as an unsafe profile when
      installed at the default paths <code>C:\cygwin</code> or
      <code>C:\cygwin64</code>.
    </p>
    <h2 id="git-bash">Git Bash</h2>
    <p>
      A limitation of Git Bash when AVAP<sup>TM</sup> Dev Studio uses bash.exe
      &#40;the shell&#41; as opposed to git-bash.exe &#40;the terminal&#41; is
      that history will not be retained across shell sessions. You can work
      around this by adding the following to your <code>~/.bashrc</code>a or{' '}
      <code>~/.bash\_profile</code> files:
    </p>

    <pre className="">
      <code className="">export PROMPT\_COMMAND='history -a'</code>
    </pre>
    <p>
      This will cause the shell to call history -a whenever the prompt is
      printed which flushes the session&#39;s current session commands to the
      backing history file.
    </p>
    <h2 id="msys2">MSYS2</h2>
    <p>MSYS2&#39;s bash shell can be configured with the following profile:</p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"terminal.integrated.profiles.windows": &#123;
        <br />` `"bash &#40;MSYS2&#41;": &#123;
        <br />` `"path": "C:\\msys64\\usr\\bin\\bash.exe",
        <br />` `"args": ["--login", "-i"],
        <br />` `"env": &#123; "CHERE\_INVOKING": "1" &#125;
        <br />` `&#125;
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <p>
      The <code>CHERE_INVOKING</code> environment variable is used to tell the
      login initialization script to preserve the working directory, instead of
      opening at <code>$HOME</code>.
    </p>
    <p>
      This profile should be detected automatically as an{' '}
      <a href="#_unsafe-profile-detection">unsafe profile</a> when installed at
      the default path <code>C:\\msys64</code>.
    </p>
    <h2 id="windows-powershell">Windows PowerShell</h2>
    <p>
      When PowerShell 6+ is installed, Windows PowerShell is not included in the
      profiles list by default. To add Windows PowerShell as a profile, choose
      the <strong>Select Default Profile</strong> option in the new terminal
      dropdown and select the Windows PowerShell item. This will configure the
      profile and set it as your default.
    </p>
    <h2 id="wsl">WSL</h2>
    <p>
      When running AVAP<sup>TM</sup> Dev Studio on your local machine, Windows
      Subsystem for Linux shells should be automatically detected. Depending on
      your setup, this may be a nuisance if you have a lot of distros installed.
      For finer control over the WSL profiles the automatic detection can be
      disabled with the <code>terminal.integrated.useWslProfiles setting</code>,
      then here&#39;s an example of how to manually configure a WSL shell:
    </p>

    <pre className="">
      <code className="">
        &#123;
        <br />` `"terminal.integrated.profiles.windows": &#123;
        <br />` `"Debian &#40;WSL&#41;": &#123;
        <br />` `"path": "C:\\WINDOWS\\System32\\wsl.exe",
        <br />` `"args": [<br />` `"-d",
        <br />` `"Debian"
        <br />` `]
        <br />` `&#125;
        <br />` `&#125;
        <br />
        &#125;
      </code>
    </pre>
    <h2 id="common-questions">Common questions</h2>
    <h3 id="why-are-there-duplicate-paths-in-the-terminals-path-environment-variable-andor-why-are-they-reversed-on-macos">
      Why are there duplicate paths in the terminal&#39;s $PATH environment
      variable and/or why are they reversed on macOS?
    </h3>
    <p>
      This can happen on macOS because of how the terminal launches using AVAP
      <sup>TM</sup> Dev Studio&#39;s environment. When AVAP<sup>TM</sup> Dev
      Studio launches for the first time, to source your &quot;development
      environment,&quot; it launches your configured shell as a{' '}
      <strong>login shell</strong>, which runs your{' '}
      <code>~/.profile/~/.bash\_profile/~/.zprofile</code> scripts. Now when the
      terminal launches, it also runs as a login shell, which will put the
      standard paths to the front &#40;for example,{' '}
      <code>/usr/local/bin:/usr/bin:/bin:/usr/sbin:/sbin</code>&#41; and
      reinitialize your shell environment.
    </p>
    <p>
      To get a better understanding, you can simulate what is happening by
      launching an inner login shell within your operating system&#39;s built-in
      terminal:
    </p>

    <pre className="">
      <code className="">
        \# Add /test to the beginning of $PATH
        <br />
        export PATH=/test:$PATH
        <br />
        \# Echo $PATH, /test should be at the beginning
        <br />
        echo $PATH
        <br />
        \# Run bash as a login shell
        <br />
        bash -l
        <br />
        \# Echo $PATH, the values should be jumbled
        <br />
        echo $PATH
      </code>
    </pre>
    <p>
      Unfortunately, unlike in Linux, standalone macOS terminals all run as
      login shells by default, since macOS does not run a login shell when the
      user logs into the system. This encourages &quot;bad behavior,&quot; like
      initializing aliases in your profile script when they should live in your{' '}
      <code>rc</code> script as that runs on non-login shells.
    </p>
    <p>
      There are two direct fixes for this. The first is to set{' '}
      <code>&quot;terminal.integrated.inheritEnv&quot;: false</code>, which will
      strip most environment variables from the terminal&#39;s environment,
      except for some important ones &#40;like <code>HOME</code>,{' '}
      <code>SHELL</code>, <code>TMPDIR</code>, etc.&#41;.
    </p>
    <p>
      The other fix is to no longer run a login shell in the terminal by
      creating a terminal profile and setting its args to []. If you go with
      this fix, you will want to make sure any aliases in your profile scripts
      are moved over to your <code>~/.bashrc/~/.zshrc</code> file since aliases
      only apply to the shell they&#39;re set in.
    </p>
  </Fragment>
);
export default TERMINAL_TerminalProfiles;
