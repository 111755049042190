import React, { Fragment } from 'react';

const USER_GUIDE_CustomLayout = () => (
  <Fragment>
    <h1 id="custom-layout">Custom Layout</h1>
    <p>
      AVAP<sup>TM</sup> Dev Studio comes with a simple user interface and
      convenient default layout. At the same time, AVAP<sup>TM</sup> Dev Studio
      provides options and settings to let you customize the UI layout to suit
      your preferences and work style. In this topic, we&#39;ll highlight
      various UI customizations so you can display views, editors, and panels in
      the way that&#39;s most productive for you.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: If you are new to AVAP<sup>TM</sup> Dev Studio,
        you may want to start with the user interface overview or take a look at
        the Tips and Tricks article.
      </p>
    </blockquote>
    <p>
      This article starts by discussing Workbench customizations to rearrange UI
      elements such as the side bars, views, and panels. Later in the article,
      we&#39;ll cover customization of the Editor region with editor groups,
      split editors, and editor tabs.
    </p>
    <p>``</p>
    <h2 id="workbench">Workbench</h2>
    <h3 id="primary-side-bar">Primary side bar</h3>
    <p>
      The default &#40;Primary&#41; side bar shows views such as the File
      Explorer, Search, and Source Control on the left of the workbench. If
      you&#39;d prefer it on the right, you can:
    </p>
    <ul>
      <li>
        Right-click the Activity bar and select{' '}
        <strong>Move Primary Side Bar Right</strong>.
      </li>
      <li>
        Run <strong>View: Toggle Primary Side Bar Position</strong> to switch
        the Primary side bar right and left.
      </li>
      <li>
        Set the <strong>Workbench &gt; Side Bar: Location</strong>{' '}
        &#40;workbench.sideBar.location&#41; setting to right.
      </li>
    </ul>
    <h3 id="secondary-side-bar">Secondary side bar</h3>
    <p>
      By default, AVAP<sup>TM</sup> Dev Studio shows all views in the{' '}
      <strong>Primary Side Bar</strong> located to the left of the editor
      region. If you like another place to display views, you can open the{' '}
      <strong>Secondary Side Bar</strong> to the right and drag and drop views
      into that side bar.
    </p>
    <p>
      This can be useful if you&#39;d like to see two views at the same time,
      for example, the File Explorer on the left and Source Control view on the
      right:
    </p>
    <p>To display the Secondary side bar, you can:</p>
    <ul>
      <li>
        Run <strong>View: Toggle Secondary Side Bar Visibility</strong>{' '}
        &#40;Ctrl+Alt+B&#41;.
      </li>
      <li>
        Check the <strong>View</strong> &gt; <strong>Appearance</strong> &gt;{' '}
        <strong>Secondary Side Bar</strong> menu item.
      </li>
    </ul>
    <p>
      The Secondary side bar is initially empty but you can drag and drop views
      and panels into it and the layout is preserved across your AVAP
      <sup>TM</sup> Dev Studio sessions.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: You can reset views and panels back to their
        default locations with the <strong>View: Reset View Locations</strong>{' '}
        command.
      </p>
    </blockquote>
    <h3 id="activity-bar-position">Activity bar position</h3>
    <p>
      By default, the Activity bar moves with the Primary side bar and remains
      on the outer edge of the workbench. You can also choose to hide the
      Activity bar or move it to the top of the Primary side bar. The{' '}
      <strong>Activity Bar Position</strong> menu available from the Activity
      bar context menu or under <strong>View</strong> &gt;{' '}
      <strong>Appearance</strong> has the options <strong>Side</strong>,{' '}
      <strong>Top</strong>, or <strong>Hidden</strong>.
    </p>
    <p>
      When the Activity bar is in the top position, the <strong>Account</strong>{' '}
      and <strong>Manage</strong> buttons, usually at the bottom of the Activity
      bar, move to the right side of the title bar.
    </p>
    <h3 id="panel">Panel</h3>
    <p>
      The Panel region displays UI elements such as the Problems, Terminal, and
      Output panels and by default is located under the editor region.
    </p>
    <h3 id="panel-position">Panel position</h3>
    <p>
      You can also move the region to the left or right of the editor with the{' '}
      <strong>Move Panel</strong> commands:
    </p>
    <ul>
      <li>
        <strong>View: Move Panel Left</strong> &#40;
        <code>workbench.action.positionPanelLeft</code>&#41;
      </li>
      <li>
        <strong>View: Move Panel Right</strong> &#40;
        <code>workbench.action.positionPanelRight</code>&#41;
      </li>
      <li>
        <strong>View: Move Panel To Bottom</strong> &#40;
        <code>workbench.action.positionPanelBottom</code>&#41;
      </li>
    </ul>
    <p>
      You can configure these options in the menu under <strong>View</strong>{' '}
      &gt; <strong>Appearance</strong> &gt; <strong>Panel Position</strong>,
      Panel title bar context menu, or using the new{' '}
      <strong>View: Move Panel</strong> commands.
    </p>
    <h3 id="panel-alignment">Panel alignment</h3>
    <p>
      This option lets you configure how far the bottom Panel spans across your
      window. There are four options:
    </p>
    <ul>
      <li>
        <strong>Center</strong> - This is the default behavior. The panel spans
        the width of the editor area only.
      </li>
      <li>
        <strong>Justify</strong> - The panel spans the full width of the window.
      </li>
      <li>
        <strong>Left</strong> - The panel spans from the left edge of the window
        to the right edge of the editor area.
      </li>
      <li>
        <strong>Right</strong> - The panel spans from the right edge of the
        window to the left edge of the editor area.
      </li>
    </ul>
    <p>
      With all Panel alignment options, the Activity Bar is considered the edge
      of the window.
    </p>
    <p>
      You can configure these options in the menu under <strong>View</strong>{' '}
      &gt; <strong>Appearance</strong> &gt; <strong>Align Panel</strong>, Panel
      title context menu, or using the new{' '}
      <strong>Set Panel Alignment to...</strong> commands.
    </p>
    <h3 id="maximize-panel-size">Maximize Panel size</h3>
    <p>
      When the Panel alignment is <strong>Center</strong>, you can quickly
      toggle the Panel region to fill the entire editor area with the{' '}
      <strong>Maximize Panel Size</strong> chevron button in the upper right of
      the Panel region. The chevron button points downwards in the maximized
      panel to restore the panel to the original size.
    </p>
    <p>
      You can also maximize the Panel region via the{' '}
      <strong>View: Toggle Maximized Panel</strong> command.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: Besides customizing the overall Panel region
        display, individual panels may have their own layout customizations. For
        example, the Terminal lets you have multiple open tabs and split
        existing terminals.
      </p>
    </blockquote>
    <h3 id="customize-layout-control">Customize Layout control</h3>
    <p>
      The AVAP<sup>TM</sup> Dev Studio title bar also has buttons to toggle the
      visibility of the main UI elements &#40;Side bars and Panel region&#41;.
    </p>
    <p>
      The rightmost button brings up the <strong>Customize Layout</strong>{' '}
      dropdown, where you can further change the visibility and layout of
      various UI elements and includes several layout modes:
    </p>
    <p>The layout modes are:</p>
    <ul>
      <li>
        <strong>Full Screen</strong> - Set the editor to fill the full display
        screen. <strong>View: Toggle Full Screen</strong> &#40;<code>F11</code>
        &#41;.
      </li>
      <li>
        <strong>Zen Mode</strong> - Hide all UI except for the editor area.{' '}
        <strong>View: Toggle Zen Mode</strong> &#40;<code>Ctrl+K Z</code>&#41;.
      </li>
      <li>
        <strong>Centered Layout</strong> - Centers the editor inside the editor
        region. <strong>View: Toggle Centered Layout</strong>.
      </li>
    </ul>
    <h3 id="drag-and-drop-views-and-panels">Drag and drop views and panels</h3>
    <p>
      AVAP<sup>TM</sup> Dev Studio has a default layout of views and panels in
      the Primary Side bar and Panel region but you can drag and drop views and
      panels between these regions. For example, you can drag and drop the
      Source Control view into the Panel region or put the Problems panel into
      the Primary Side bar:
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: Remember that you can reset a view and panel back
        to its default location with the <strong>Reset Location</strong> context
        menu item or all views and panels with the general{' '}
        <strong>View: Reset View Locations</strong> command.
      </p>
    </blockquote>
    <p>
      You can also add views and panels to existing view or panel to create
      groups. For example, you could move the Output panel to the Explorer view
      group by dragging over the Explorer Activity bar item and then dropping
      into the view:
    </p>
    <p>
      You are not limited to using the mouse for moving views and panels. You
      can also customize layouts via the keyboard with the{' '}
      <strong>View: Move View</strong> and{' '}
      <strong>View: Move Focused View</strong> commands, where dropdowns let you
      pick the UI element to move and the destination, either a location like
      the Side bar or Panel region or an existing view or panel to create a
      group.
    </p>
    <h2 id="tool-bars">Tool bars</h2>
    <p>
      Most AVAP<sup>TM</sup> Dev Studio views and panels have tool bars
      displayed on the top right of their UI. For example, the Search view has a
      tool bar with actions such as <strong>Refresh</strong>,{' '}
      <strong>Clear Search Results</strong>, etc.:
    </p>
    <h3 id="hide-items-in-tool-bars">Hide items in tool bars</h3>
    <p>
      If you think a tool bar is too busy and you&#39;d like to hide less
      frequently used actions, you can right-click on any action and select its{' '}
      <strong>Hide</strong> command &#40;for example{' '}
      <strong>Hide &#39;Clear Search Results&#39;</strong>&#41; or uncheck any
      of the actions from the dropdown. Hidden actions are moved to the ...{' '}
      <strong>More Actions</strong> menu and can be invoked from there.
    </p>
    <p>
      To restore an action to the tool bar, right-click the tool bar button area
      and select the <strong>Reset Menu</strong> command or recheck the hidden
      action. To restore all menus in AVAP<sup>TM</sup> Dev Studio, run{' '}
      <strong>View: Reset All Menus</strong> from the Command Palette
      &#40;Ctrl+Shift+P&#41;.
    </p>
    <h2 id="editor">Editor</h2>
    <p>
      You can customize the layout of the AVAP<sup>TM</sup> Dev Studio editor
      region independently of the workbench user interface. By default, the
      editor region displays useful features such as the minimap, breadcrumbs,
      editor tabs, and has optional UI such as Sticky Scroll. You can also
      adjust the layout of the editors themselves.
    </p>
    <h3 id="minimap-and-breadcrumbs">Minimap and breadcrumbs</h3>
    <p>
      The <strong>View</strong> &gt; <strong>Appearance</strong> menu has a
      section for customizing the editor region. There you&#39;ll find toggles
      for:
    </p>
    <ul>
      <li>
        <strong>Minimap</strong> - A visual overview of your current file.{' '}
        <strong>View: Toggle Minimap</strong>.
      </li>
      <li>
        <strong>Breadcrumbs</strong> - Display folder, file, and current symbol
        information for the active file.{' '}
        <strong>View: Toggle Breadcrumbs</strong>.
      </li>
      <li>
        <strong>Sticky Scroll</strong> - Display nested symbol scopes in the
        active file. <strong>View: Toggle Sticky Scroll</strong>.
      </li>
    </ul>
    <h3 id="editor-groups">Editor groups</h3>
    <p>
      By default, each opened editor goes into the same{' '}
      <strong>editor group</strong> and adds a new editor tab to the right. You
      can create new editor groups in order to group similar or related files,
      or to allow side by side editing of the same file. Create a new editor
      group by dragging an editor to the side, or using one of the{' '}
      <strong>Split</strong> commands in the context menu to duplicate the
      current editor into a new editor group to the left, right, above, or
      below.
    </p>
    <p>
      The <strong>Split</strong> editor commands are also available from the{' '}
      <strong>View</strong> &gt; <strong>Editor Layout</strong> menu and through
      the Command Palette.
    </p>
    <p>
      If you&#39;d like to go quickly between vertical and horizontal editor
      group layout, you can use the{' '}
      <strong>Toggle Vertical/Horizontal Editor Layout</strong> command
      &#40;Shift+Alt+0&#41;.
    </p>
    <h3 id="split-in-group">Split in group</h3>
    <p>
      You can also split an editor in the same group for side by side editing
      with the <strong>View: Split Editor in Group</strong> command &#40;Ctrl+K
      Ctrl+Shift+&#41;.
    </p>
    <p>
      When using the split in group feature, there are specific commands for
      toggling this mode and navigating between the two split editors:
    </p>
    <ul>
      <li>
        <strong>View: Split Editor in Group</strong> - Split the current editor.
      </li>
      <li>
        <strong>View: Toggle Split Editor in Group</strong> - Toggle between
        split mode for the active editor.
      </li>
      <li>
        <strong>View: Join Editor in Group</strong> - Go back to a single editor
        for the active file.
      </li>
      <li>
        <strong>View: Toggle Layout of Split Editor in Group</strong> - Toggle
        between horizontal and vertical layout.
      </li>
    </ul>
    <p>To navigate between the sides:</p>
    <ul>
      <li>
        <strong>View: Focus First Side in Active Editor</strong> - Move focus to
        the first &#40;left or top&#41; side of split editor.
      </li>
      <li>
        <strong>View: Focus Second Side in Active Editor</strong> - Move focus
        to the second &#40;right or bottom&#41; side.
      </li>
      <li>
        <strong>View: Focus Other Side in Active Editor</strong> - Toggle
        between the split editor sides.
      </li>
    </ul>
    <p>
      The <strong>Workbench &gt; Editor: Split in Group Layout</strong>{' '}
      &#40;workbench.editor.splitInGroupLayout&#41; setting lets you set the
      preferred split editor layout to either horizontal &#40;default&#41; or
      vertical.
    </p>
    <h3 id="grid-layout">Grid layout</h3>
    <p>
      If you&#39;d like more control over the editor group layout, you can use
      the grid layout, where you can have multiple rows and columns of editor
      groups visible. The <strong>View</strong> &gt;{' '}
      <strong>Editor Layout</strong> menu lists various editor layout options
      &#40;for example, <strong>Two Columns</strong>,{' '}
      <strong>Three Columns</strong>, <strong>Grid &#40;2x2&#41;</strong>&#41;
      and you can adjust the group sizes by grabbing and moving the sash between
      them.
    </p>
    <h3 id="pinned-tabs">Pinned tabs</h3>
    <p>
      If you&#39;d like an editor tab to always be visible, you can pin it to
      the editor tab bar. You can pin an editor tab from either the context menu
      or using the command <strong>View: Pin Editor</strong> &#40;Ctrl+K
      Shift+Enter&#41;.
    </p>
    <p>Pinned tabs help access files that are important to you as:</p>
    <ul>
      <li>Pinned tabs always appear first before non-pinned tabs.</li>
      <li>They do not scroll out of view if you have many tabs opened.</li>
      <li>
        They do not close when using editor tab commands such as{' '}
        <strong>Close Others</strong> or <strong>Close All</strong>.
      </li>
      <li>
        They do not close even when you exceed a set limit of opened editors.
      </li>
    </ul>
    <p>
      Unpin an editor by clicking on the pin icon, using the{' '}
      <strong>Unpin</strong> editor tab context menu item, or the{' '}
      <strong>View: Unpin Editor</strong> command.
    </p>
    <p>
      You can choose how you&#39;d like to display pinned editors with the{' '}
      <strong>Workbench &gt; Editor: Pinned Tab Sizing</strong> &#40;
      <code>workbench.editor.pinnedTabSizing</code>&#41; setting. The options
      are:
    </p>
    <ul>
      <li>
        <code>normal</code>: A pinned tab inherits the look of other tabs
        &#40;default&#41;
      </li>
      <li>
        <code>shrink</code>: A pinned tab shrinks to a fixed size showing parts
        of the editor label.
      </li>
      <li>
        <code>compact</code>: A pinned tab will only show as icon or first
        letter of the editor label.
      </li>
    </ul>
    <p>
      You can also show pinned editor tabs on a separate row above the regular
      editor tab bar by setting{' '}
      <strong>Workbench &gt; Editor: Pinned Tabs On Separate Row</strong>. You
      can pin and unpin editors by dragging and dropping their tabs between the
      two rows.
    </p>
    <h3 id="locked-editor-groups">Locked editor groups</h3>
    <p>
      When using multiple editors, it&#39;s common to have one or more that you
      want to always keep visible. The locked editor group feature, where an
      entire editor group is locked and visible, provides a stable display and
      any request to open a new editor will create it in another group. You can
      tell whether an editor group is locked by the lock icon in the editor
      group tool bar.
    </p>
    <p>
      You can lock an editor group by selecting <strong>Lock Group</strong> from
      the editor tool bar <strong>More Actions</strong> ... dropdown or running
      the <strong>View: Lock Editor Group</strong> command.
    </p>
    <p>
      You can unlock an editor group by clicking on the lock icon or running the{' '}
      <strong>View: Unlock Editor Group</strong> command.
    </p>
    <p>Locked groups behave differently than unlocked groups:</p>
    <ul>
      <li>
        New editors will not open in a locked group unless explicitly moved
        there &#40;for example, via drag and drop&#41;.
      </li>
      <li>
        If a new editor skips a locked group, it will either open in the most
        recently used unlocked group or create a new group to the side of the
        locked one.
      </li>
      <li>
        The locked state of an editor group is persisted and restored across
        restarts.
      </li>
      <li>
        You can lock empty groups as well, allowing for a more stable editor
        layout.
      </li>
    </ul>
    <p>
      The primary use case is for terminals in the editor area For example, you
      might want to edit text on the left and have a terminal on the right that
      is always visible. When a terminal editor is created and moved to the
      side, it will automatically lock. This means that even when the terminal
      on the right is focused, opening a file will open it on the left side
      without needing to manually change focus first.
    </p>
    <p>
      Auto locking groups can be configured using the
      workbench.editor.autoLockGroups setting, which defaults to only terminal
      editors but any editor type can be added to get the same behavior.
    </p>
    <p>The commands related to editor group locking:</p>
    <ul>
      <li>
        <strong>View: Lock Editor Group</strong> - Lock the active editor group.
      </li>
      <li>
        <strong>View: Unlock Editor Group</strong> - Unlock the active locked
        editor group.
      </li>
      <li>
        <strong>View: Toggle Editor Group Lock</strong> - Lock or unlock the
        active editor group.
      </li>
    </ul>
    <p>
      You must have more that one editor group for these commands to be
      available.
    </p>
    <h2 id="next-steps">Next steps</h2>
    <p>Read on to find out about:</p>
    <ul>
      <li>
        AVAP<sup>TM</sup> Dev Studio User Interface - A quick orientation to
        AVAP<sup>TM</sup> Dev Studio.
      </li>
      <li>
        Basic Editing - Learn about the powerful AVAP<sup>TM</sup> Dev Studio
        editor.
      </li>
      <li>Code Navigation - Move quickly through your source code.</li>
    </ul>
  </Fragment>
);
export default USER_GUIDE_CustomLayout;
