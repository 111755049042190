import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Registrar_Monedero_Prepago_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": <boolean>, 
          "origins": [
             { "id": <integer>, 
             "currency_id_currencies": <integer>, 
             "balance_fmt": <string>, 
             "balance_fmt_compact": <string>, 
             "real_balance": <decimal>, 
             "balance": <decimal>, "retentions": <boolean>, 
             "balance_retentions": [], "image": <string>, 
             "registration_date": <string>, "class": <string>, 
             "authorize": <boolean>, "permit_errase": <boolean>, 
             "permit_income": <boolean>, "permit_charge": <boolean>, 
             "permit_various": <boolean>, "pending_of_revision": <boolean>, 
             "type": <integer>, "name": <string>, "base_class": <string>, 
            } ], "id_usuario": <integer>, "clave_privada": <string>, 
            "elapsed": <float> 
          }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{
           "status": false,
            "level": <string>,
             "message": <string>,
              "error": <string> 
            }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/pademobile_prepago.py/registrar_monedero_prepago" 
        payload = {'country_code': 'MX', 'nick': 'Prueba1', 'pin': 'Contraseña123'} 
        files = [] 
        headers= {} 
        response = requests.request("POST", url, headers=headers, data = payload, files = files) 
        print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests url = "URL_BASE/ws/pademobile_prepago.py/registrar_monedero_prepago" 
          payload = {'country_code': 'MX', 'nick': 'Prueba1', 'pin': 'Contraseña123'} 
          files = [] 
          headers= {} 
          response = requests.request("POST", url, headers=headers, data = payload, files = files) 
          print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'POST',
              'url': 'URL_BASE/ws/pademobile_prepago.py/registrar_monedero_prepago',
              'headers': {},
              formData: {
              'country_code': 'MX',
              'nick': 'Prueba1',
              'pin': 'Contraseña123'
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var formdata = new FormData();
              formdata.append("country_code", "MX");
              formdata.append("nick", "Prueba1");
              formdata.append("pin", "Contraseña123");
              var requestOptions = {
              method: 'POST',
              body: formdata,
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/pademobile_prepago.py/registrar_monedero_prepago", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request POST 'URL_BASE/ws/pademobile_prepago.py/registrar_monedero_prepago' \ --form 'country_code=MX' \ --form 'nick=Prueba1' \ --form 'pin=Contraseña123'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="prepaid-wallet-registration">Prepaid Wallet Registration</h1>
        <p>This service is used to obtain your wallets from another account.</p>
        <p>
          <span className="label post">POST:</span>
          <span> </span>{' '}
          <code>
            URL_BASE + /ws/pademobile_prepago.py/registrar_monedero_prepago
          </code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
              <td align="center">ES &#40;Spain&#41;</td>
            </tr>
            <tr>
              <td align="left">nick</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">User name from whom we want the funds source</td>
              <td align="center">N/A</td>
              <td align="center">Prueba1</td>
            </tr>
            <tr>
              <td align="left">pin</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                User password from whom we want the funds source
              </td>
              <td align="center">N/A</td>
              <td align="center">Contraseña123</td>
            </tr>
          </tbody>
        </table>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>origins:</code> List of the user funds source.
          </li>
          <li>
            <code>id:</code> Funds source identifier.
          </li>
          <li>
            <code>currency_id_currencies:</code> Identifier of the currency in which
            the source of funds is located.
          </li>
          <li>
            <code>balance_fmt:</code> Source of funds balance formatted in currency
            format.
          </li>
          <li>
            <code>balance_fmt_compact:</code> Balance of the source of funds
            formatted only with the currency symbol.
          </li>
          <li>
            <code>real_balance:</code> Total balance of the source of funds, that
            is, the available balance plus all the held balance.
          </li>
          <li>
            <code>balance:</code> Available balance of the source of funds.
          </li>
          <li>
            <code>retentions:</code> Specifies whether the source of funds has
            associated balance holds.
          </li>
          <li>
            <code>balance_retentions:</code> If the source of funds has associated
            balance holds, each item in this list will store both the amount of the
            hold and the hold ID.
          </li>
          <li>
            <code>image:</code> Image associated with the source of funds.
          </li>
          <li>
            <code>registration_date:</code> Date of creation of the source of funds.
          </li>
          <li>
            <code>class:</code> Indicates the type of source of funds.
          </li>
          <li>
            <code>authorize:</code> Allows to know if they are possible to operate
            with the source of funds or not.
          </li>
          <li>
            <code>permit_errase:</code> Each source of funds, depending on its
            configuration, may or may not be deleted by the user.
          </li>
          <li>
            <code>permit_income:</code> The source of funds may or may not admit
            income.
          </li>
          <li>
            <code>permit_charge:</code> The source of funds may or may not admit
            charges.
          </li>
          <li>
            <code>permit_various:</code> Specify whether multiple sources of funds
            of the same type are allowed to exist at the same time.
          </li>
          <li>
            <code>pending_of_revision:</code> Indicates whether or not the origin is
            to be reviewed by an administrator or operator.
          </li>
          <li>
            <code>type:</code> Source of funds type ID.
          </li>
          <li>
            <code>name:</code> Funds source name.
          </li>
          <li>
            <code>base_class:</code> The class of a source of funds defines its
            nature &#40;card, local source of funds…&#41;.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>level:</code> Error importance level.
          </li>
          <li>
            <code>message:</code> Error message.
          </li>
          <li>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          This service is run from a user who has another account and wants to have
          access to all the wallets of that other account. The user&#39;s private
          key is returned to be able to make the rest of the calls to the prepaid
          wallets. .
        </p>
        </div>

      <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
        </div>
      </Fragment>
    );
  }
export default Registrar_Monedero_Prepago_EN;
