import React, { Fragment } from 'react';

const USER_GUIDE_CodeNavigation = () => (
  <Fragment>
    <h1 id="code-navigation">Code Navigation</h1>
    <p>
      AVAP™ Dev Studio has a high productivity code editor which, when combined
      with programming language services, gives you the power of an IDE and the
      speed of a text editor. In this topic, we&#39;ll first describe AVAP™ DS
      Code&#39;s language intelligence features &#40;suggestions, parameter
      hints, smart code navigation&#41; and then show the power of the core text
      editor.
    </p>
    <h2 id="quick-file-navigation">Quick file navigation</h2>
    <blockquote>
      <p>
        <strong>Tip:</strong> You can open any file by its name when you type
        Ctrl+P &#40;<strong>Quick Open</strong>&#41;.
      </p>
    </blockquote>
    <p>
      The Explorer is great for navigating between files when you are exploring
      a project. However, when you are working on a task, you will find yourself
      quickly jumping between the same set of files. AVAP™ DS Code provides two
      powerful commands to navigate in and across files with easy-to-use key
      bindings.
    </p>
    <p>
      Hold Ctrl and press Tab to view a list of all files open in an editor
      group. To open one of these files, use Tab again to pick the file you want
      to navigate to, then release Ctrl to open it.
    </p>
    <p>
      Alternatively, you can use Alt+Left and Alt+Right to navigate between
      files and edit locations. If you are jumping around between different
      lines of the same file, these shortcuts allow you to navigate between
      those locations easily.
    </p>
    <h2 id="breadcrumbs">Breadcrumbs</h2>
    <p>
      The editor has a navigation bar above its contents called{' '}
      <a href="https://en.wikipedia.org/wiki/Breadcrumb_&#40;navigation&#41;">
        Breadcrumbs
      </a>
      . It shows the current location and allows you to quickly navigate between
      folders, files, and symbols.
    </p>
    <p>
      Breadcrumbs always show the file path and, with the help of language
      extensions, the symbol path up to the cursor position. The symbols shown
      are the same as in Outline view and Go to Symbol.
    </p>
    <p>
      Selecting a breadcrumb in the path displays a dropdown with that
      level&#39;s siblings so you can quickly navigate to other folders and
      files.
    </p>
    <p>
      If the current file type has language support for symbols, you will see
      the current symbol path and a dropdown of other symbols at the same level
      and below.
    </p>
    <p>
      You can turn off breadcrumbs with the <strong>View</strong> &gt;{' '}
      <strong>Show Breadcrumbs</strong> toggle or with the breadcrumbs.enabled{' '}
      <a href="/docs/getstarted/settings">setting</a>.
    </p>
    <h3 id="breadcrumb-customization">Breadcrumb customization</h3>
    <p>
      The appearance of breadcrumbs can be customized. If you have very long
      paths or are only interested in either file paths or symbols paths, you
      can use the breadcrumbs.filePath and breadcrumbs.symbolPath settings. Both
      support on, off, and last and they define if or what part of the path you
      see. By default, breadcrumbs show file and symbol icons to the left of the
      breadcrumb but you can remove the icons by setting breadcrumbs.icons to
      false.
    </p>
    <h3 id="symbol-order-in-breadcrumbs">Symbol order in Breadcrumbs</h3>
    <p>
      You can control how symbols are ordered in the Breadcrumbs dropdown with
      the breadcrumbs.symbolSortOrder settings.
    </p>
    <p>Allowed values are:</p>
    <ul>
      <li>position - position in the file &#40;default&#41;</li>
      <li>name - alphabetical order</li>
      <li>type - symbol type order</li>
    </ul>
    <h3 id="breadcrumb-keyboard-navigation">Breadcrumb keyboard navigation</h3>
    <p>
      To interact with breadcrumbs, use the <strong>Focus Breadcrumbs</strong>{' '}
      command or press Ctrl+Shift+.. It will select that last element and open a
      dropdown that allows you to navigate to a sibling file or symbol. Use the
      Left and Right keyboard shortcuts to go to elements before or after the
      current element. When the dropdown appears, start typing - all matching
      elements will be highlighted and the best match will be selected for quick
      navigation.
    </p>
    <p>
      You can also interact with breadcrumbs without the dropdown. Press
      Ctrl+Shift+; to focus the last element, use Left and Right to navigate,
      and use Space to reveal the element in the editor.
    </p>
    <h2 id="go-to-definition">Go to Definition</h2>
    <p>
      If a <a href="/docs/languages/overview">language</a> supports it, you can
      go to the definition of a symbol by pressing F12.
    </p>
    <p>
      If you press Ctrl and hover over a symbol, a preview of the declaration
      will appear:
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> You can jump to the definition with Ctrl+Click or
        open the definition to the side with Ctrl+Alt+Click.
      </p>
    </blockquote>
    <h2 id="go-to-type-definition">Go to Type Definition</h2>
    <p>
      Some <a href="/docs/languages/overview">languages</a> also support jumping
      to the type definition of a symbol by running the{' '}
      <strong>Go to Type Definition</strong> command from either the editor
      context menu or the <strong>Command Palette</strong>. This will take you
      to the definition of the type of a symbol. The command
      editor.action.goToTypeDefinition is not bound to a keyboard shortcut by
      default but you can add your own custom{' '}
      <a href="/docs/getstarted/keybindings">keybinding</a>.
    </p>
    <h2 id="go-to-implementation">Go to Implementation</h2>
    <p>
      <a href="/docs/languages/overview">Languages</a> can also support jumping
      to the implementation of a symbol by pressing Ctrl+F12. For an interface,
      this shows all the implementors of that interface and for abstract
      methods, this shows all concrete implementations of that method.
    </p>
    <h2 id="go-to-symbol">Go to Symbol</h2>
    <p>
      You can navigate symbols inside a file with Ctrl+Shift+O. By typing : the
      symbols will be grouped by category. Press Up or Down and navigate to the
      place you want.
    </p>
    <h2 id="open-symbol-by-name">Open symbol by name</h2>
    <p>
      Some languages support jumping to a symbol across files with Ctrl+T. Type
      the first letter of a type you want to navigate to, regardless of which
      file contains it, and press Enter.
    </p>
    <h2 id="peek">Peek</h2>
    <p>
      We think there&#39;s nothing worse than a big context switch when all you
      want is to quickly check something. That&#39;s why we support peeked
      editors. When you execute a <strong>Go to References</strong> search
      &#40;via Shift+F12&#41;, or a <strong>Peek Definition</strong> &#40;via
      Alt+F12&#41;, we embed the result inline:
    </p>
    <p>
      You can navigate between different references in the peeked editor and
      make quick edits right there. Clicking on the peeked editor filename or
      double-clicking in the result list will open the reference in the outer
      editor.
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> Additionally, the peek window is closed if you
        press Escape or double-click in the peek editor region. You can disable
        this behavior with the editor.stablePeek{' '}
        <a href="/docs/getstarted/settings">setting</a>.
      </p>
    </blockquote>
    <h2 id="bracket-matching">Bracket matching</h2>
    <p>
      Matching brackets will be highlighted as soon as the cursor is near one of
      them.
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> You can jump to the matching bracket with
        Ctrl+Shift+\
      </p>
    </blockquote>
    <h3 id="a-name_bracket-pair-colorizationabracket-pair-colorization">
      <a ></a>
      <a href="#_bracket-pair-colorization">
        <strong>Bracket Pair Colorization</strong>
      </a>
    </h3>
    <p>
      Matching bracket pairs can also be colorized by setting
      editor.bracketPairColorization.enabled to true.
    </p>
    <p>All colors are themeable and up to six colors can be configured.</p>
    <p>
      You can use workbench.colorCustomizations to override these
      theme-contributed colors in your settings:
    </p>

    <pre className="">
      <code className="">
        "workbench.colorCustomizations": &#123; <br />`
        `"editorBracketHighlight.foreground1": "#FFD700", <br />`
        `"editorBracketHighlight.foreground2": "#DA70D6", <br />`
        `"editorBracketHighlight.foreground3": "#179fff", <br />
        &#125;,
      </code>
    </pre>
    <h2 id="reference-information">Reference information</h2>
    <p>
      Some languages like C# support inline reference information, that is
      updated live. This allows you to quickly analyze the impact of your edit
      or the popularity of your specific method or property throughout your
      project:
    </p>
    <blockquote>
      <p>
        <strong>Tip:</strong> Directly invoke the{' '}
        <strong>Peek References</strong> action by clicking on these
        annotations.
      </p>
    </blockquote>
    <blockquote>
      <p>
        <strong>Tip:</strong> Reference information shown in CodeLens can be
        turned on or off through the editor.codeLens{' '}
        <a href="/docs/getstarted/settings">setting</a>.
      </p>
    </blockquote>
    <h2 id="rename-symbol">Rename symbol</h2>
    <p>
      Some languages support rename symbol across files. Press F2 and then type
      the new desired name and press Enter. All usages of the symbol will be
      renamed, across files.
    </p>
    <h2 id="errors--warnings">Errors &amp; warnings</h2>
    <p>
      Warnings or Errors can be generated either via{' '}
      <a href="/docs/editor/tasks">configured tasks</a>, by rich language
      services, or by linters, that constantly analyze your code in the
      background. Since we love bug-free code, warnings and errors show up in
      multiple places:
    </p>
    <ul>
      <li>
        In the Status Bar, there is a summary of all errors and warnings counts.
      </li>
      <li>
        You can click on the summary or press Ctrl+Shift+M to display the{' '}
        <strong>PROBLEMS</strong> panel with a list of all current errors.
      </li>
      <li>
        If you open a file that has errors or warnings, they will be rendered
        inline with the text and in the overview ruler.
      </li>
    </ul>
    <blockquote>
      <p>
        <strong>Tip:</strong> To loop through errors or warnings in the current
        file, you can press F8 or Shift+F8 which will show an inline zone
        detailing the problem and possible Code Actions &#40;if available&#41;:
      </p>
    </blockquote>
    <h2 id="code-action">Code Action</h2>
    <p>
      Warnings and Errors can provide Code Actions &#40;also known as Quick
      Fixes&#41; to help fix issues. These will be displayed in the editor in
      the left margin as a lightbulb. Clicking on the lightbulb will either
      display the Code Action options or perform the action.
    </p>
    <h2 id="inlay-hints">Inlay Hints</h2>
    <p>
      Some languages provide inlay hints: that is additional information about
      source code that is rendered inline. This is usually used to show inferred
      types. The sample below shows inlay hints that display the inferred types
      of JavaScript variables and function return types.
    </p>
    <p>
      Inlay hints can be enabled/disabled with the editor.inlayHints.enabled
      setting, the default is enabled. Extensions, like TypeScript or Rust, are
      needed to provide the actual inlay hint information.
    </p>
    <h2 id="outgoing-link-protection">Outgoing link protection</h2>
    <p>
      For your protection, AVAP™ DS Code displays a prompt before opening an
      outgoing website link from the editor.
    </p>
    <p>
      You can proceed to the external website in your browser or have the
      options to copy the link or cancel the request. If you choose{' '}
      <strong>Configure Trusted Domains</strong>, a dropdown lets you trust the
      exact URL, trust the URL domain and subdomains, or trust all domains to
      disable outgoing link protection.
    </p>
    <p>
      The option to <strong>Manage Trusted Domains</strong>, also available at
      any time from the Command Palette, brings up the{' '}
      <strong>Trusted Domains</strong> JSON file, where you can add, remove, or
      modify trusted domains.
    </p>

    <pre className="">
      <code className="">
        // You can use the "Manage Trusted Domains" command to open this file.
        <br />
        // Save this file to apply the trusted domains rules.
        <br />[ <br />` `"\*.twitter.com"
        <br />]
      </code>
    </pre>
    <p>
      The <strong>Trusted Domains</strong> JSON file also has comments with
      examples of the supported domain formats and a list of the domains trusted
      by default, such as https://*.visualstudio.com and
      https://*.microsoft.com.
    </p>
    <h2 id="next-steps">Next steps</h2>
    <p>
      Now that you know how the editor works, time to try a few other things...
    </p>
    <ul>
      <li>
        <a href="/docs/introvideos/codeediting">Intro Video - Code Editing</a> -
        Watch an introductory video on code editing features.
      </li>
      <li>
        <a href="/docs/getstarted/userinterface">User Interface</a> - In case
        you missed a basic orientation around AVAP™ DS Code.
      </li>
      <li>
        <a href="/docs/getstarted/keybindings">Key Bindings</a> - Learn how to
        modify key bindings to your preference.
      </li>
      <li>
        <a href="/docs/editor/debugging">Debugging</a> - This is where AVAP™ DS
        Code really shines.
      </li>
    </ul>
    <h2 id="common-questions">Common questions</h2>
    <h3 id="how-can-i-automatically-select-the-second-entry-in-quick-open-instead-of-the-first">
      How can I automatically select the second entry in Quick Open instead of
      the first?
    </h3>
    <p>
      With the command workbench.action.quickOpenPreviousEditor, you can have
      the second entry automatically selected in Quick Open. This can be useful
      if you want to select the previous entry from the list without having to
      invoke another keybinding:
    </p>

    <pre className="">
      <code className="">
        [<br />` `&#123;
        <br />` `"key": "ctrl+p",
        <br />` `"command": "workbench.action.quickOpenPreviousEditor"
        <br />` `&#125;,
        <br />` `&#123;
        <br />` `"key": "ctrl+p",
        <br />` `"command": "-workbench.action.quickOpen"
        <br />` `&#125;
        <br />]
      </code>
    </pre>
    <h3 id="how-can-i-configure-ctrltab-to-navigate-across-all-editors-of-all-groups">
      How can I configure Ctrl+Tab to navigate across all editors of all groups
    </h3>
    <p>
      By default, Ctrl+Tab navigates between editors of the same editor group.
      If you want to navigate across all opened editors in all groups, you can
      create keyboard shortcuts for the
      workbench.action.quickOpenPreviousRecentlyUsedEditor and
      workbench.action.quickOpenLeastRecentlyUsedEditor commands:
    </p>

    <pre className="">
      <code className="">
        [<br />` `&#123;
        <br />` `"key": "ctrl+tab",
        <br />` `"command":
        "workbench.action.quickOpenPreviousRecentlyUsedEditor",
        <br />` `"when": "!inEditorsPicker"
        <br />` `&#125;,
        <br />` `&#123;
        <br />` `"key": "ctrl+shift+tab",
        <br />` `"command": "workbench.action.quickOpenLeastRecentlyUsedEditor",
        <br />` `"when": "!inEditorsPicker"
        <br />` `&#125;
        <br />]
      </code>
    </pre>
    <h3 id="how-can-i-navigate-between-recently-used-editors-without-a-picker">
      How can I navigate between recently used editors without a picker
    </h3>
    <p>
      Here is a list of commands you can use to navigate in editors without
      opening a picker:
    </p>
    <table>
      <thead>
        <tr>
          <th align="center">
            <strong>Key</strong>
          </th>
          <th align="center">
            <strong>Command</strong>
          </th>
          <th align="center">
            <strong>Command ID</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">unassigned</td>
          <td align="center">Open Next Recently Used Editor</td>
          <td align="center">workbench.action.openNextRecentlyUsedEditor</td>
        </tr>
        <tr>
          <td align="center">unassigned</td>
          <td align="center">Open Previously Used Editor</td>
          <td align="center">
            workbench.action.openPreviousRecentlyUsedEditor
          </td>
        </tr>
        <tr>
          <td align="center">unassigned</td>
          <td align="center">Open Next Recently Used Editor in Group</td>
          <td align="center">
            workbench.action.openNextRecentlyUsedEditorInGroup
          </td>
        </tr>
        <tr>
          <td align="center">unassigned</td>
          <td align="center">Open Previously Used Editor in Group</td>
          <td align="center">
            workbench.action.openPreviousRecentlyUsedEditorInGroup
          </td>
        </tr>
      </tbody>
    </table>
  </Fragment>
);
export default USER_GUIDE_CodeNavigation;
