import React, { Fragment } from 'react';

const Marketplace = () => (
  <Fragment>
    <h1 >Marketplace</h1>
    <p>
      At 101OBeX we are committed to universality, integration, and
      collaboration with other companies who enrich and help us enhance and
      expand our array of services.
    </p>
    <p>
      Thanks to our marketplace, third parties can publish their APIs and add
      value to the platform.
    </p>
    <p>
      From the Marketplace section you can browse the different published
      plugins, install, configure and exploit each one of them.
    </p>
    <p>
      Keep in mind that plugins can be purchased, in which case the cost of the
      plug-in will be processed as part of the recurring plan charges with each
      collection period.
    </p>
    <p>
      Furthermore, a plug-in may require a pre-funded balance for its correct
      operation, in which case you must maintain a sufficient balance in your
      wallet. Adhering to the provided instructions for each plug-in to ensure
      its proper configuration and correct operation.
    </p>
  </Fragment>
);
export default Marketplace;
