import React, { Fragment } from 'react';

const USER_GUIDE_WorkspaceTrust = () => (
  <Fragment>
    <h1 id="workspace-trust">Workspace Trust</h1>
    <p>
      AVAP<sup>TM</sup> Dev Studio takes security seriously and wants to help
      you safely browse and edit code no matter the source or original authors.
      The Workspace Trust feature lets you decide whether code in your project
      folder can be executed by AVAP<sup>TM</sup> Dev Studio and extensions
      without your explicit approval.
    </p>
    <p>![Trust this folder dialog]</p>
    <blockquote>
      <p>
        <strong>Note</strong>: When in doubt, leave a folder in Restricted Mode.
        You can always enable trust later.
      </p>
    </blockquote>
    <h2 id="safe-code-browsing">Safe code browsing</h2>
    <p>
      It&#39;s great that there is so much source code available on public
      repositories and file shares. No matter the coding task or problem, there
      is probably already a good solution available somewhere. It is also great
      that there are so many powerful coding tools available to help you
      understand, debug, and optimize your code. However, using open-source code
      and tools does have risks, and you can leave yourself open to malicious
      code execution and exploits.
    </p>
    <p>
      Workspace Trust provides an extra layer of security when working with
      unfamiliar code, by preventing automatic code execution of any code in
      your workspace if the workspace is open in &quot;Restricted Mode&quot;.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: The terms &quot;workspace&quot; and
        &quot;folder&quot; are used widely in the AVAP<sup>TM</sup> Dev Studio
        UI and documentation. You can think of a{' '}
        <a href="/docs/editor/workspaces">&quot;workspace&quot;</a> as a folder
        with extra metadata created and used by AVAP<sup>TM</sup> Dev Studio.
      </p>
    </blockquote>
    <h2 id="restricted-mode">Restricted Mode</h2>
    <p>
      When prompted by the Workspace Trust dialog, if you choose{' '}
      <strong>No, I don&#39;t trust the authors</strong>, AVAP<sup>TM</sup> Dev
      Studio will go into Restricted Mode to prevent code execution. The
      workbench will display a banner at the top with links to{' '}
      <strong>Manage</strong> your folder via the Workspace Trust editor, and{' '}
      <strong>Learn More</strong> about Workspace Trust &#40;which takes you to
      back to this documentation&#41;.
    </p>
    <p>You will also see a Restricted Mode badge in the Status bar.</p>
    <p>
      Restricted Mode tries to prevent automatic code execution by disabling or
      limiting the operation of several AVAP<sup>TM</sup> Dev Studio features:
      tasks, debugging, workspace settings, and extensions.
    </p>
    <p>
      To see the full list of features disabled in Restricted Mode, you can open
      the Workspace Trust editor via the <strong>Manage</strong> link in the
      banner, or by clicking the Restricted Mode badge in the Status bar.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: Workspace Trust can not prevent a malicious
        extension from executing code and ignoring{' '}
        <strong>Restricted Mode</strong>. You should only install and run
        extensions that come from a well-known publisher that you trust.
      </p>
    </blockquote>
    <h4 id="tasks">Tasks</h4>
    <p>
      Tasks can run scripts and tool binaries, and because task definitions are
      defined in the workspace <code>.vscode</code> folder, they are part of the
      committed source code for a repo, and shared to every user of that repo.
      Were someone to create a malicious task, it could be unknowingly run by
      anyone who cloned that repository.
    </p>
    <p>
      If you try to run or even enumerate tasks &#40;<strong>Terminal</strong>{' '}
      &gt; <strong>Run Task...</strong>&#41; while in Restricted Mode, AVAP
      <sup>TM</sup> Dev Studio will display a prompt to trust the folder and
      continue executing the task. Cancelling the dialog leaves AVAP
      <sup>TM</sup> Dev Studio in Restricted Mode.
    </p>
    <h3 id="debugging">Debugging</h3>
    <p>
      Similar to running a AVAP<sup>TM</sup> Dev Studio task, debug extensions
      can run debugger binaries when launching a debug session. For that reason,
      debugging is also disabled when a folder is open in Restricted Mode.
    </p>
    <p>
      If you try to start a debug session &#40;<strong>Run</strong> &gt;{' '}
      <strong>Start Debugging</strong>&#41; while in Restricted Mode, AVAP
      <sup>TM</sup> Dev Studio will display a prompt to trust the folder and
      continue launching the debugger. Cancelling the dialog leaves AVAP
      <sup>TM</sup> Dev Studio in Restricted Mode, and does not start the debug
      session.
    </p>
    <h4 id="workspace-settings">Workspace settings</h4>
    <p>
      Workspace settings are stored in the .vscode folder at the root of your
      workspace, and are therefore shared by anyone who clones the workspace
      repository. Some settings contain paths to executables &#40;for example,
      linter binaries&#41;, which if set to point to malicious code, could do
      damage. For this reason, there is a set of workspace settings that are
      disabled when running in Restricted Mode.
    </p>
    <p>
      In the Workspace Trust editor, there is a link to display the workspace
      settings that aren&#39;t being applied. Clicking the link brings up the
      Settings editor scoped by the tag:requireTrustedWorkspace tag.
    </p>
    <h4 id="extensions">Extensions</h4>
    <p>
      The AVAP<sup>TM</sup> Dev Studio extensions ecosystem is incredibly rich
      and diverse. People have created extensions to help with just about any
      programming task or editor customization. Some extensions provide full
      programming language support &#40;IntelliSense, debugging, code
      analysis&#41;, and others let you play music or have virtual pets.
    </p>
    <p>
      Most extensions run code on your behalf and could potentially do harm.
      Some extensions have settings that could cause them to act maliciously if
      configured to run an unexpected executable. For this reason, extensions
      that have not explicitly opted into Workspace Trust are disabled by
      default in Restricted Mode.
    </p>
    <p>
      You can review an installed extension&#39;s status by clicking the{' '}
      <strong>extensions are disabled or have limited functionality</strong>{' '}
      link in the Workspace Trust editor, which displays the Extensions view
      scoped with the <code>@workspaceUnsupported</code> filter.
    </p>
    <p>
      <strong>Disabled in Restricted Mode</strong>
    </p>
    <p>
      Extensions that have either not explicitly indicated that they support
      running in Restricted Mode are shown in the{' '}
      <strong>Disabled in Restricted Mode</strong> section. An extension author
      can also indicate that they never want to be enabled in Restricted Mode if
      they determine that their extension could be misused by modifications
      &#40;settings or files&#41; in a workspace.
    </p>
    <p>
      <strong>Limited in Restricted Mode</strong>
    </p>
    <p>
      Extension authors can also evaluate their extensions for possible security
      vulnerabilities and declare that they have <strong>limited</strong>{' '}
      support when running in Restricted Mode. This mode means the extension may
      disable some features or functionality to prevent a possible exploit.
    </p>
    <p>
      Extensions can add custom text to the Extensions view Workspace Trust
      badge explaining the limitation when running in an untrusted folder.
    </p>
    <p>
      For example, the AVAP<sup>TM</sup> Dev Studio built-in PHP extension
      limits the use of the <code>php.validate.executablePath</code> setting to
      trusted folders since overriding this setting could run a malicious
      program.
    </p>
    <p>
      You can override an extension&#39;s Workspace Trust support level using
      the <code>extensions.supportUntrustedWorkspaces</code> setting described
      in the Enabling extensions section below.
    </p>
    <p>
      If you try to install an extension in Restricted Mode, you will be
      prompted to either trust the workspace or just install the extension. If
      the extension doesn&#39;t support Workspace Trust, it will be installed,
      but be disabled or running with limited functionality.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: Extension authors can learn how to update their
        extensions to support Workspace Trust by reading the Workspace Trust
        Extension Guide.
      </p>
    </blockquote>
    <h2 id="trusting-a-workspace">Trusting a workspace</h2>
    <p>
      If you trust the authors and maintainers of a project, you can trust the
      project&#39;s folder on your local machine. For example, it is usually
      safe to trust repositories from well-known GitHub organizations such as
      github.com/microsoft or github.com/docker.
    </p>
    <p>
      The initial Workspace Trust prompt when you open a new folder allows you
      to trust that folder and its subfolders.
    </p>
    <p>
      You can also bring up the Workspace Editor and quickly toggle a
      folder&#39;s trusted state.
    </p>
    <p>There are several ways to bring up the Workspace Editor dialog.</p>
    <p>When in Restricted Mode:</p>
    <ul>
      <li>
        Restricted Mode banner <strong>Manage</strong> link
      </li>
      <li>Restricted Mode Status bar item</li>
    </ul>
    <p>You can also at any time use:</p>
    <ul>
      <li>
        <strong>Workspaces: Manage Workspace Trust</strong> command from the
        Command Palette &#40;Ctrl+Shift+P&#41;
      </li>
      <li>
        <strong>Manage Workspace Trust</strong> from the <strong>Manage</strong>{' '}
        gear in the Activity bar
      </li>
    </ul>
    <h2 id="selecting-folders">Selecting folders</h2>
    <p>
      When you trust a folder, it is added to the{' '}
      <strong>Trusted Folders &amp; Workspaces</strong> list displayed in the
      Workspace Trust editor.
    </p>
    <p>
      You can manually add, edit, and remove folders from this list and the
      active folder enabling trust is highlighted in bold.
    </p>
    <h4 id="selecting-a-parent-folder">Selecting a parent folder</h4>
    <p>
      When you trust a folder via the Workspace Trust editor, you have the
      option to trust the parent folder. This will apply trust to the parent
      folder and all subfolders.
    </p>
    <p>
      This can be helpful if you have many folders with trusted content
      co-located under one folder.
    </p>
    <p>
      When opening a subfolder under a trusted parent, you won&#39;t see the
      usual <strong>Don&#39;t Trust</strong> button to put you back in
      Restricted Mode, instead there is text mentioning that your folder is
      trusted due to another folder.
    </p>
    <p>
      You can add, modify, and remove a parent folder entry from the{' '}
      <strong>Trusted Folders &amp; Workspaces</strong> list.
    </p>
    <h4 id="folder-configurations">Folder configurations</h4>
    <p>
      As mentioned above, you can trust a parent folder and all subfolders will
      be trusted. This allows you to control Workspace Trust via a
      repository&#39;s location on disk.
    </p>
    <p>
      For example, you could put all trusted repos under a
      &quot;TrustedRepos&quot; parent folder, and unfamiliar repos under another
      parent folder such as &quot;ForEvaluation&quot;. You would trust the
      &quot;TrustedRepos&quot; folder, and selectively trust folders under
      &quot;ForEvaluation&quot;.
    </p>

    <pre className="">
      <code className="">
        TrustedRepos - Clone trusted repositories under this parent folder
        <br />
        <br />
        ForEvaluation - Clone experimental or unfamiliar repositories under this
        parent folder
      </code>
    </pre>
    <p>
      You also group and set trust on your repositories by grouping them under
      organization-base parent folders.
    </p>

    <pre className="">
      <code className="">
        github/microsoft - Clone a specific organization's repositories under
        this parent folder
        <br />
        <br />
        github/&#123;myforks&#125; - Place your forked repositories under this
        parent folder
        <br />
        <br />
        local - Local un-published repositories
      </code>
    </pre>
    <h2 id="enabling-extensions">Enabling extensions</h2>
    <p>
      What happens if you want to use Restricted Mode but your favorite
      extension doesn&#39;t support Workspace Trust? This can happen if an
      extension, while useful and functional, isn&#39;t being actively
      maintained and hasn&#39;t declared their Workspace Trust support. To
      handle this scenario, you can override the extension&#39;s trust state
      with the <code>extensions.supportUntrustedWorkspaces setting</code>.
    </p>
    <blockquote>
      <p>
        <strong>Note</strong>: Be careful overriding an extension&#39;s
        Workspace Trust support. It may be that the extension author has a good
        reason for disabling their extension in Restricted Mode. If in doubt,
        reach out to the extension author or review recent changelogs to get
        more context.
      </p>
    </blockquote>
    <p>
      If you open the Settings editor &#40;Ctrl+,&#41; and search for
      &quot;trust extensions&quot;, you can find the{' '}
      <strong>Extensions: Support Untrusted Workspaces</strong> setting, which
      has an <strong>Edit in settings.json</strong> link.
    </p>
    <p>
      Select that link and you will go to your user settings.json file with a
      new entry for extensions.supportUntrustedWorkspaces. This setting takes an
      object that has a list of extension IDs and their support status and
      version. You can select any of your installed extensions via IntelliSense
      suggestions.
    </p>
    <p>Below you can see a settings.json entry for the Prettier extension.</p>

    <pre className="">
      <code className="">
        ` `"extensions.supportUntrustedWorkspaces": &#123;
        <br />` `"esbenp.prettier-vscode": &#123;
        <br />` `"supported": true,
        <br />` `"version": "6.4.0"
        <br />` `&#125;,
        <br />` `&#125;,
      </code>
    </pre>
    <p>
      You can either enable or disable Workspace Trust support with the
      supported attribute. The version attribute specifies the exact extension
      version applicable and you can remove the version field if you want to set
      the state for all versions.
    </p>
    <p>
      If you&#39;d like to learn more about how extension authors evaluate and
      determine which features to limit in Restricted Mode, you can read the
      Workspace Trust Extension Guide.
    </p>
    <h2 id="opening-untrusted-files">Opening untrusted files</h2>
    <p>
      If you open a file that is located outside of a trusted folder, AVAP
      <sup>TM</sup> Dev Studio will detect that the file comes from somewhere
      outside the folder root and prompt you with the option to continue to open
      the file or open the file in a new window in Restricted Mode. Opening in
      Restricted Mode is the safest option and you can always reopen the file in
      your original AVAP<sup>TM</sup> Dev Studio window once you determine the
      file is trustworthy.
    </p>
    <p>
      If you would prefer to not be prompted when opening files from outside
      trusted workspaces, you can set{' '}
      <code>security.workspace.trust.untrustedFiles</code> to <code>open</code>.
      You can also set <code>security.workspace.trust.untrustedFiles</code> to{' '}
      <code>newWindow</code> to always create a new window in Restricted Mode.
      Checking the <strong>Remember my decision for all workspaces</strong>{' '}
      option in the untrusted files dialog applies your choice to the{' '}
      <code>security.workspace.trust.untrustedFiles</code> user setting.
    </p>
    <h3 id="opening-untrusted-folders">Opening untrusted folders</h3>
    <p>
      When working with multi-root workspaces with multiple folders, if you try
      to add a new folder to a trusted multi-root workspace, you will be
      prompted to decide if you trust the files in that folder or if not, the
      entire workspace will switch to Restricted Mode.
    </p>
    <h3 id="empty-windows-no-open-folder">
      Empty windows &#40;no open folder&#41;
    </h3>
    <p>
      By default, if you open a new AVAP<sup>TM</sup> Dev Studio window
      &#40;instance&#41; without opening a folder or workspace, AVAP
      <sup>TM</sup> Dev Studio runs the window with full trust. All installed
      extensions are enabled and you can use the empty window without
      restrictions.
    </p>
    <p>
      When you open a file, you will be prompted whether you want to open an
      untrusted file since there is no folder to parent it.
    </p>
    <p>
      You can switch an empty window to Restricted Mode using the Workspace
      Trust editor &#40;select <strong>Manage Workspace Trust</strong> from the{' '}
      <strong>Manage</strong> gear button or the Command Palette&#41; and
      selecting <strong>Don&#39;t Trust</strong>. The empty window will remain
      in Restricted Mode for your current session but will go back to trusted if
      you restart or create a new window.
    </p>
    <p>
      If you want all empty windows to be in Restricted Mode, you can set{' '}
      <code>security.workspace.trust.emptyWindow</code> to false.
    </p>
    <h2 id="settings">Settings</h2>
    <p>Below are the available Workspace Trust settings:</p>
    <ul>
      <li>
        <code>security.workspace.trust.enabled</code> - Enable Workspace Trust
        feature. Default is true.
      </li>
      <li>
        <code>security.workspace.trust.startupPrompt</code> - Whether to show
        the Workspace Trust dialog on startup. Default is to only show once per
        distinct folder or workspace.
      </li>
      <li>
        <code>security.workspace.trust.emptyWindow</code> - Whether to always
        trust an empty window &#40;no open folder&#41;. Default is true.
      </li>
      <li>
        <code>security.workspace.trust.untrustedFiles</code> - Controls how to
        handle loose files in a workspace. Default is to prompt.
      </li>
      <li>
        <code>extensions.supportUntrustedWorkspaces</code> - Override extension
        Workspace Trust declarations. Either true or false.
      </li>
      <li>
        <code>security.workspace.trust.banner</code> - Controls when the
        Restricted Mode banner is displayed. Default is untilDismissed.
      </li>
    </ul>
    <h2 id="command-line-switch">Command-line switch</h2>
    <p>
      You can disable Workspace Trust via the AVAP<sup>TM</sup> Dev Studio
      command line by passing --disable-workspace-trust. This switch only
      affects the current session.
    </p>
    <h2 id="next-steps">Next steps</h2>
    <p>Learn more at:</p>
    <ul>
      <li>
        Workspace Trust Extension Guide - Learn how extension authors can
        support Workspace Trust.
      </li>
      <li>
        What is a AVAP<sup>TM</sup> Dev Studio &quot;workspace&quot;? - Find out
        more details about the AVAP<sup>TM</sup> Dev Studio
        &quot;workspace&quot; concept.
      </li>
      <li>
        GitHub Repositories extension - Work directly on a repository without
        cloning the source code to your local machine.
      </li>
    </ul>
    <h2 id="common-questions">Common questions</h2>
    <h4 id="can-i-still-edit-my-source-code-in-restricted-mode">
      Can I still edit my source code in Restricted Mode?
    </h4>
    <p>
      Yes, you can still browse and edit source code in Restricted Mode. Some
      language features may be disabled, but text editing is always supported.
    </p>
    <h4 id="where-did-my-installed-extensions-go">
      Where did my installed extensions go?
    </h4>
    <p>
      In Restricted Mode, any extension that doesn&#39;t support Workspace Trust
      will be disabled, and all UI elements such as Activity bar icons and
      commands will not be displayed.
    </p>
    <p>
      You can override an extension&#39;s Workspace Trust support level with the{' '}
      <code>extensions.supportUntrustedWorkspaces</code> setting but do so with
      care. Enabling extensions has more details.
    </p>
    <h4 id="can-i-disable-the-workspace-trust-feature">
      Can I disable the Workspace Trust feature?
    </h4>
    <p>
      You can but it is not recommended. If you don&#39;t want AVAP<sup>TM</sup>{' '}
      Dev Studio to check for Workspace Trust when opening a new folder or
      repository, you can set <code>security.workspace.trust.enabled</code> to
      false. AVAP<sup>TM</sup> Dev Studio will then behave as it did before the
      1.57 release.
    </p>
    <h4 id="how-do-i-untrust-a-folderworkspace">
      How do I untrust a folder/workspace?
    </h4>
    <p>
      Bring up Workspace Trust editor &#40;
      <strong>Workspaces: Manage Workspace Trust</strong> from the Command
      Palette&#41; and select the <strong>Don&#39;t Trust</strong> button. You
      can also remove the folder from the{' '}
      <strong>Trusted Folders &amp; Workspaces</strong> list.
    </p>
    <h4 id="why-dont-i-see-the-dont-trust-button">
      Why don&#39;t I see the &quot;Don&#39;t Trust&quot; button?
    </h4>
    <p>
      If you don&#39;t see the <strong>Don&#39;t Trust</strong> button in the
      Workspace Trust dialog, the folder&#39;s trust level may be inherited from
      a parent folder. Review the{' '}
      <strong>Trusted Folders &amp; Workspaces</strong> list to check if a
      parent folder has enabled Workspace Trust.
    </p>
    <p>
      Some workflows such as connecting to a GitHub Codespace or attaching to a
      running Docker container are automatically trusted since these are managed
      environments to which you should already have a high level of trust.
    </p>
    <h4 id="what-does-workspace-trust-protect-against">
      What does Workspace Trust protect against?
    </h4>
    <p>
      Many features of AVAP<sup>TM</sup> Dev Studio allow third-party tools and
      extensions to run automatically, such as linting or format on save, or
      when you do certain operations like compiling code or debugging. An
      unethical person could craft an innocent looking project that would run
      malicious code without your knowledge and harm your local machine.
      Workspace Trust provides an extra layer of security by trying to prevent
      code execution while you are evaluating the safety and integrity of
      unfamiliar source code.
    </p>
  </Fragment>
);
export default USER_GUIDE_WorkspaceTrust;
