import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';

const Alta_Admin_EN = () => 
 {
  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{ "status": true, 
      "signed": <boolean>, 
      "invoice": <integer>, 
      "currency_data": { 
        "abbreviation": <string>, 
        "suffix": <string>, "format": <string>, 
        "symbol": <string>, "prefix": <string>, 
        "decimals": <decimal>, "id": <integer> 
      }, 
      "elapsed": <float>, "raw_import": <decimal>, 
      "currency": <string>, "commission_user_card": <decimal>, 
      "codtran": <string>, 
      "user_commission": <decimal>, 
      "amount": <string> 
    }`,
      copied: false
    },
    answe_ko1: {
      title:"Answer ok",
      type: "json",
      code: `{ 
        "status": <boolean>, 
        "level": <string>, 
        "message": <string>, 
        "error": <string> 
      }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: `import requests url = "URL_BASE/ws/admin.py/prefondeo" 
      payload = {'country_code': 'MX', 'user_id': '4532', 'session_id': '437-XWPLYNDOyCybdkmWfPRPzw==', 'affiliate_id': '127', 'amount': '12.50'} 
      files = [] 
      headers= {} 
      response = requests.request("POST", url, headers=headers, data = payload, files = files) 
      print(response.text.encode('utf8'))`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/admin.py/prefondeo" 
        payload = {'country_code': 'MX', 'user_id': '4532', 'session_id': '437-XWPLYNDOyCybdkmWfPRPzw==', 'affiliate_id': '127', 'amount': '12.50'} 
        files = [] 
        headers= {} 
        response = requests.request("POST", url, headers=headers, data = payload, files = files) 
        print(response.text.encode('utf8'))`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: `var request = require('request');
            var options = {
            'method': 'POST',
            'url': 'URL_BASE/ws/admin.py/prefondeo',
            'headers': {},
            formData: {
            'country_code': 'MX',
            'user_id': '4532',
            'session_id': '437-XWPLYNDOyCybdkmWfPRPzw==',
            'affiliate_id': '127',
            'amount': '12.50'
            }
            };
            request(options, function (error, response) { 
            if (error) throw new Error(error);
            console.log(response.body);
            });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: `var formdata = new FormData();
            formdata.append("country_code", "MX");
            formdata.append("user_id", "4532");
            formdata.append("session_id", "437-XWPLYNDOyCybdkmWfPRPzw==");
            formdata.append("affiliate_id", "127");
            formdata.append("amount", "12.50");
            var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
            };
            fetch("URL_BASE/ws/admin.py/prefondeo", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: `curl --location --request POST 'URL_BASE/ws/admin.py/prefondeo' \ --form 'country_code=MX' \ --form 'user_id=4532' \ --form 'session_id=437-XWPLYNDOyCybdkmWfPRPzw==' \ --form 'affiliate_id=127' \ --form 'amount=12.50'`
      }
    ]
  }
  return(
    <Fragment>
      <div className='container-fluid row'>
          <div className='col-md-7'>
      <h1 id="admin-registration">Admin Registration</h1>
      <p>A user registration is executed.</p>
      <p>
        <span className="label post">POST:</span>{' '}
        <code>URL_BASE + /ws/admin.py/altaadmin</code>
      </p>
      <h2 id="receives">Receives:</h2>
      <p>
        All the parameters that the service receives must be indicated in the body
        of the request.
      </p>
      <table>
        <thead>
          <tr>
            <th align="left">Parameter</th>
            <th align="center">Format</th>
            <th align="center">Obligatory</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="left">country_code</td>
            <td align="center">
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                ISO 3166 alpha-2
              </a>
            </td>
            <td align="center">Yes</td>
            <td align="center">Country code in which the user resides</td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">user_id</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">
              Identifier of the user making the request &#40;Admin or
              Affiliate&#41;
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">session_id</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send signature to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Obtained by identifying the user with ID user_id on the platform
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">signature</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send session_id to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Enables you to sign the request without having to log in
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">affiliate_id</td>
            <td align="center">Integer</td>
            <td align="center">No</td>
            <td align="center">
              Identifier of the user to whom the deposit is to be made
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">amount</td>
            <td align="center">Decimal</td>
            <td align="center">Yes</td>
            <td align="center">Amount to send to the indicated affiliate</td>
            <td align="center">N/A</td>
          </tr>
        </tbody>
      </table>
      <h2 id="returns">Returns:</h2>
      <p>
        Depending on the result of the operation, this service can return two
        different JSON:
      </p>
      <h3 id="answer-json-ok">Answer JSON OK:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
      <h2 id="where">Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li>
          <code>signed:</code> Shows if the signature has been correctly processed
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li>
          <code>invoice:</code> Invoice number associated to the operation.
        </li>
        <li>
          <code>currency_data:</code> Contains the different details of the
          currency used in the operation.
        </li>
        <li>
          <code>abbreviation:</code> The abbreviated name of the currency
          &#40;EUR, MXN&#41;.
        </li>
        <li>
          <code>suffix:</code> Indicates the suffix that is applied in the
          currency format &#40;pesos, euros&#41;.
        </li>
        <li>
          <code>format:</code> The full format that applies to the currency, it
          includes the suffix and the prefix.
        </li>
        <li>
          <code>symbol:</code> The symbol associated to the currency&#40;€, ¢,
          $&#41;.
        </li>
        <li>
          <code>prefix:</code> The prefix that is applied in the currency format.
        </li>
        <li>
          <code>decimals:</code> The maximum number of decimal places that will be
          included in the currency format.
        </li>
        <li>
          <code>id:</code> Identifier of the currency in BBDD.
        </li>
        <li>
          <code>elapsed:</code> Operation execution time.
        </li>
        <li>
          <code>raw_import:</code> Negative transaction amount without applying
          the format.
        </li>
        <li>
          <code>currency:</code> Short name of the currency used in the operation,
          it matches the abbreviation.
        </li>
        <li>
          <code>commission_user_card:</code> Commission that would be applied if
          the payment is made by card.
        </li>
        <li>
          <code>codtran:</code> Transaction code that identifies the executed
          operation.
        </li>
        <li>
          <code>user_commission:</code> Commission that will be applied to the
          user.
        </li>
        <li>
          <code>amount:</code> Negative transaction amount applying the format.
        </li>
      </ul>
      <h3 id="answer-json-ko">Answer JSON KO:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
      <h2 id="where-1">Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li>
          <code>level:</code> Error importance level.
        </li>
        <li>
          <code>message:</code> Error message.
        </li>
        <li>
          <code>error:</code> Sole error code.
        </li>
      </ul>
      <h2 id="business-logic">Business logic:</h2>
      <p>
        In oder to run this service, it is necessary to do it from a user logged
        into the system who has an administrator profile.
      </p>
      </div>

      <div className='col-md-5'>
      <h2 id="example-requests">Example requests:</h2>
      <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
      
      </div>
      </div>
    </Fragment>
  );
 }
export default Alta_Admin_EN;
