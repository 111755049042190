import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';

const Get_blockchain_accounts = () => 
  {
    const codes ={
      Retur: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": true, 
          "total": { 
            "total_euros": 0.0, 
            "total_dollar": 0.0 
          }, 
          "result": [
             { 
              "private_key": "0x1916a49015f51346fa87a195dd4a20746afd2956f40cc2164761176ed5cd9a31", 
              "default": false, 
              "alias": "Account 2", 
              "address": "0xB2A2BAbF1D7C49C6A9141dF89354e5339b9C09A3", 
              "total": { 
                "total_euros": 0.0, 
                "total_dollar": 0.0 
              }, 
              "id": 126 
            }, { 
              "private_key": "0xb1275361d16ef7288d2f24760f59d82f3879a224d7c892fdf95992d0378798f1", 
              "default": true, 
              "alias": "Account 1", 
              "address": "0x9eA8F1a3940d7861f41D213aA1D47C5b479a062D", 
              "total": { 
                "total_euros": 0.0, 
                "total_dollar": 0.0 
              }, 
              "id": 123 
            } ], 
        "elapsed": 0.778839111328125`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": false, 
          "level": <string>, 
          "message": <string>,
          "error": <string> 
        }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requestsurl = "http://34.121.95.179:80/ws/users.py/get_blockchain_accounts?country_code=ES&id_usuario=134&id_sesion=2482-ISCK7yYIVMGVhLLQbdijtg=="
        payload={}
        headers = { '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'}
        response = requests.request("GET", url, headers=headers, data=payload)
        print(response.text)`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requestsurl = "http://34.121.95.179:80/ws/users.py/get_blockchain_accounts?country_code=ES&id_usuario=134&id_sesion=2482-ISCK7yYIVMGVhLLQbdijtg=="
          payload={}
          headers = { '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'}
          response = requests.request("GET", url, headers=headers, data=payload)
          print(response.text)`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
               'method': 'GET',
               'url': 'http://34.121.95.179:80/ws/users.py/get_blockchain_accounts?country_code=ES&id_usuario=134&id_sesion=2482-ISCK7yYIVMGVhLLQbdijtg==',
               'headers': {
               '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'
               }
              };
              request(options, function (error, response) {
               if (error) throw new Error(error);
               console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var myHeaders = new Headers();
              myHeaders.append("101ObexApiKey", "KcM6Vr7EfNnKScEi00jdritBzNjWAOkz");
              
              var requestOptions = {
               method: 'GET',
               headers: myHeaders,
               redirect: 'follow'
              };
              
              fetch("http://34.121.95.179:80/ws/users.py/get_blockchain_accounts?country_code=ES&id_usuario=134&id_sesion=2482-ISCK7yYIVMGVhLLQbdijtg==", requestOptions)
               .then(response => response.text())
               .then(result => console.log(result))
               .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request GET 'http://34.121.95.179:80/ws/users.py/get_blockchain_accounts?country_code=ES&id_usuario=134&id_sesion=2482-ISCK7yYIVMGVhLLQbdijtg==' \--header '101ObexApiKey: KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'`
        }
      ]
    }
  
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="inter-networks-movement">Inter Networks Movement</h1>
        <h2 id="wsuserspyget_blockchain_accounts">
          ws/users.py/get_blockchain_accounts
        </h2>
        <h3 id="receives">Receives</h3>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <div className='overflow-auto'>
        <table>
          <thead>
            <tr>
              <th align="center">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center">country_code</td>
              <td align="center">
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
              <td align="center">ES</td>
            </tr>
            <tr>
              <td align="center">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID id_usuario on the platform
              </td>
              <td align="center">N/A</td>
              <td align="center">34242-7M94x6WvLh7-b19HC6PM_g==</td>
            </tr>
            <tr>
              <td align="center">user_id</td>
              <td align="center">Int</td>
              <td align="center">Yes</td>
              <td align="center">Identifier of the user making the request</td>
              <td align="center">N/A</td>
              <td align="center">414</td>
            </tr>
            <tr>
              <td align="center">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session to &#39;Access Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
              <td align="center">328621ee3acda7a582338c5bba444c3094cd6f29</td>
            </tr>
          </tbody>
        </table>
        </div>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.Retur}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li>
            <p>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </p>
          </li>
          <li>
            <p>
              <code>total:</code> Summary of the account balances.
            </p>
            <ul>
              <li>
                <code>total_euros</code>: Total in euros.
              </li>
              <li>
                <code>total_dollar</code>s: Total in dollars.
              </li>
            </ul>
          </li>
          <li>
            <p>
              <code>result:</code> List of accounts the suer has in the network.
            </p>
            <ul>
              <li>
                <code>private_key:</code>Private key of the account
              </li>
              <li>
                <code>default</code>: Is the account by defect?
              </li>
              <li>
                <code>alias</code>: Alias of the account.
              </li>
              <li>
                <code>address</code>: Address of the account.
              </li>
              <li>
                <code>total</code>: Total balance of ethereum in account.
                <ul>
                  <li>
                    <code>total_euros</code>: total in euros
                  </li>
                  <li>
                    <code>total_dollars</code>: total in dollars
                  </li>
                </ul>
              </li>
              <li>
                <code>id</code>: Account identifier.
              </li>
            </ul>
          </li>
          <li>
            <p>
              <code>elapsed:</code> Operation execution time.
            </p>
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>level:</code> Error importance level.
          </li>
          <li>
            <code>message:</code> Error message.
          </li>
          <li>
            <code>error:</code> Sole error code.
          </li>
          <h2 id="business-logic">Business logic:</h2>
        <p>
          The objective of this service is to obtain a list of the accounts the user
          has in the selected network.
        </p>
        </ul>
        </div>
        <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        </div>
        </div>
      </Fragment>
    );
  }
  
export default Get_blockchain_accounts;
