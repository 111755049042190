import React, { Fragment } from 'react';

const Billing_System = () => (
  <Fragment>
    <h1 >
      Billing System: Plans and Transactions
    </h1>
    <p>101OBeX offers different plans:</p>
    <ul>
      <li>
        <p>Developer</p>
      </li>
      <li>
        <p>Startup</p>
      </li>
      <li>
        <p>Business</p>
      </li>
      <li>
        <p>Enterprise</p>
      </li>
    </ul>
    <h1>
      <strong>
        <em>Developer &#40;FREE&#41;</em>
      </strong>
    </h1>
    <p>
      The ideal plan to become familiar with 101OBeX and introduce yourself to
      the capabilities of the system. It provides complete access to APIs with a
      maximum allowance of 500 monthly transactions so you can start your
      project, with no a membership cost*.
    </p>
    <p>
      *There is no membership fee. Transactional costs, plug-ins and other
      services within the membership are not included. To exploit these
      services, it will be necessary to purchase a different plan.
    </p>
    <h1 >
      <strong>
        <em>Startup</em>
      </strong>
    </h1>
    <p>
      Starting at 50 $ per month, you will have 2 project slots with one active
      project, and 5,000 monthly transactions to start your project.
    </p>
    <h1 >
      <strong>
        <em>Business</em>
      </strong>
    </h1>
    <p>
      Starting at 150 $ per month, you can have up to 5 projects and 2
      pre-activated slots, along with 50,000 monthly transactions to launch your
      business at the highest level.
    </p>
    <h1 >
      <strong>
        <em>Enterprise</em>
      </strong>
    </h1>
    <p>
      Geared towards corporations requiring special configurations. Membership
      activation is done through the sales team:{' '}
      <a href="mailto:sales@101obex.com">sales@101obex.com</a>.
    </p>
    <h1 >
      <strong>
        <em>Which elements are included in my subscription?</em>
      </strong>
    </h1>
    <p>
      The chosen subscription type &#40;developer, startup, business or
      enterprise&#41; that determines the configuration of the set of available
      resources.
    </p>
    <ul>
      <li>
        <p>Total project slots.</p>
      </li>
      <li>
        <p>Pre-activated projects.</p>
      </li>
      <li>
        <p>Maximum transactional volume.</p>
      </li>
      <li>
        <p>Monthly transactions.</p>
      </li>
      <li>
        <p>Storage.</p>
      </li>
      <li>
        <p>Support.</p>
      </li>
    </ul>
    <h1 >
      <strong>
        <em>How is the subscription charged?</em>
      </strong>
    </h1>
    <p>
      If payment is established monthly, charges will be made on the first day
      of each month for the total membership amount, plus contracted add-ons and
      plugins. For the first month, a prorated amount will be charged from the
      plan&#39;s start date to the end of the month. If payment is established
      annually, a full year of service will be charged, and renewal will occur
      the day after the plan expires.
    </p>
    <h1 >
      <strong>
        <em>Limits and Surpluses</em>
      </strong>
    </h1>
    <p>
      101OBeX does not invoice exempt, since it is not a possibility
      contemplated in the service. If any of the elements that make up a plan
      exceed its limit, the service will stop being provided.
    </p>
    <p>
      To prevent your projects from being left without service, 101OBeX offers
      the possibility of configuring alarms that will allow you to receive
      notifications based on limits for each category. Although these alarms are
      configurable, they have pre-established minimums to ensure that you are
      always informed.
    </p>
    <p>
      The client always has the possibility of expanding the limits for each of
      the components that make up a membership through the purchasing of add-ons
      or by upgrading their plan.
    </p>
    <h1 >
      <strong>
        <em>Membership status monitoring</em>
      </strong>
    </h1>
    <p>
      Clients can check their membership status in the dashboard at any time,
      along with plan configuration in the Subscription Plan section of the menu
      bar.
    </p>
    <h1 >
      <strong>
        <em>Billing Tracking</em>
      </strong>
    </h1>
    <p>
      In the Settings section of the menu, an option is available to track
      transaction history linked to membership collections. How to change the
      payment method Payment methods can be changed from monthly to annual and
      vice versa at any time. How to change the payment method At present the
      only form of payment is by credit card. But you can add new cards and
      change your payment method at any time.
    </p>
    <h1 >
      <strong>
        <em>How to change the payment method</em>
      </strong>
    </h1>
    <p>
      You have the possibility to upgrade and downgrade your plan according to
      your needs.
    </p>
    <h1 >
      <strong>
        <em>How to change the payment method</em>
      </strong>
    </h1>
    <p>
      At present the only form of payment is by credit card. But you can add new
      cards and change your payment method at any time.
    </p>
    <h1 >
      <strong>
        <em>How to change the plan</em>
      </strong>
    </h1>
    <p>
      You have the possibility to upgrade and downgrade your plan according to
      your needs.
    </p>
  </Fragment>
);
export default Billing_System;
