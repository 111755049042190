import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, Navbar, Container, Nav, Image} from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import './NewLandingFramework.css'
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenu';
import Logo from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import icon_name from '../../assets/img/newDashboard/icon_name.png';
import icono_Infinito from '../../assets/img/newDashboard/icono-Infinito.png';
import logo_communities from '../../assets/img/newDashboard/logo_communities.png';
import logo_alexandria from '../../assets/img/newDashboard/logo-alexandria.png';
import logo_Connect from '../../assets/img/newDashboard/logo-Connect.png';
import Logo101Obex from '../../assets/img/newDashboard/icono_Framework.png';
import logo_framework from '../../assets/img/avap_logo.png'

import logo_framework_producto from '../../assets/img/AVAP_Framework_productoServicio.png'

import lenguaje from '../../assets/img/lenguaje.png'
import virtualidad from '../../assets/img/virtualidad.png'
import inteligencia from '../../assets/img/inteligencia.png'


import avs_1 from '../../assets/img/avs_1.png'
import avs_2 from '../../assets/img/avs_2.png'
import avs_3 from '../../assets/img/avs_3.png'
import avs_4 from '../../assets/img/avs_4.png'
import avs_5 from '../../assets/img/avs_5.png'
import avs_6 from '../../assets/img/avs_6.png'

import logo_framework_titulo from '../../assets/img/AVAP_VS_P.png'
import cabecera from '../../assets/img/foto_VS.png'
import cabecera2 from '../../assets/img/2cabecera.png'
import FooterMenuAccess from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';

import botonAvapActivo from '../../assets/img/boton_avap_activo.png'
import botonCloudActivo from '../../assets/img/boton_cloud_activo.png'
import botonDsActivo from '../../assets/img/boton_ds_activo.png'
import botonCnpActivo from '../../assets/img/boton_cn_activo.png'
import botonGActivo from '../../assets/img/boton_g_activo.png'
import botonVspActivo from '../../assets/img/boton_vs_activo.png'

import botonAvap from '../../assets/img/boton_avap.png'
import botonCloud from '../../assets/img/boton_cloud.png'
import botonDs from '../../assets/img/boton_ds.png'
import botonCnp from '../../assets/img/boton_cn.png'
import botonG from '../../assets/img/boton_g.png'
import botonVs from '../../assets/img/boton_vs.png'

import AVAPLanguage from '../../assets/img/AVAPLenguaje.png';
import AVAPVirtualServer from '../../assets/img/AVAPVS.png';
import AVAPGateway from '../../assets/img/AVAPGateway.png';
import AVAPDS from '../../assets/img/AVAPDS.png';
import AVAPCloudLogo from '../../assets/img/AVAPCloud.png';
import AVAPCollaborative from '../../assets/img/AVAPCollaborativeN.png'

import Virtualizacion from '../../assets/img/foto_virtualizacion.png'

import productividad from '../../assets/img/productividad.png' 
import rentabilidad from '../../assets/img/rentabilidad.png' 
import focoproyectos from '../../assets/img/focoproyectos.png' 
import tiemposlanzamiento from '../../assets/img/tiemposlanzamiento.png' 

import collaborative2 from '../../assets/img/collaborative2.png' 

import icon_collaborative from '../../assets/img/iconCollaborative.png' 
import connect from '../../assets/img/connect.png' 
import avapconect from '../../assets/img/AVAPConnect.png' 
import ReactGA from 'react-ga';
import config from '../../configs/appConfig.json'

import vineta_CN from '../../assets/img/vineta_cloud.png'
import gafico_vscode_i from '../../assets/img/grafico_avs_i.png'

type DashProps = {
    sider: any
};



const NewLandingAVS = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();

    const [ avapBut, setavapBut ] = useState(true);
    const [ vsBut, setvsBut ] = useState(false);
    const [ gBut, setgBut ] = useState(false);
    const [ dsBut, setdsBut ] = useState(false);
    const [ cloudBut, setcloudBut ] = useState(false);
    const [ CnBut, setcnBut ] = useState(false);

    const [ menu1, setmenu1 ] = useState(false);
    const [ menu2, setmenu2 ] = useState(false);
    const [ menu3, setmenu3 ] = useState(false);
    const [ menu4, setmenu4 ] = useState(false);
    const [ menu5, setmenu5 ] = useState(false);


    const cleanAll = ()=>{
        setavapBut(false);
        setvsBut(false);
        setgBut(false);
        setdsBut(false);
        setcloudBut(false);
        setcnBut(false);
    }

    const cleanMenuAll = ()=>{
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        //ReactGA.initialize('G-MQZQJN7TQL');
        if (config.url != 'https://avs-primary-pre.101obex.mooo.com'){
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework' });}

    }, []);


    const handleSignInClick = () => {
        // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario
    
        // Redirecciona a otra vista (por ejemplo, '/dashboard')
        history.push('/login');
      };



    return (

        <div className='d-flex flex-column' style={{ backgroundColor:'#ffffff' }}>

        <Navbar className='navbar' style={{ position:'fixed', width:'100%' }}>
            <Container className="">
                <Navbar.Brand href="/framework" style={{}}>
                    <Image src={logo_framework} style={{ marginBottom: '5px' }} height="33px" width="317px" alt="Logo" />
                </Navbar.Brand>
                <Nav style={{ width: '80%' }}>
                <Nav.Link onClick={()=>{cleanMenuAll(); setmenu1(!menu1)}} style={{ marginRight:'10px', marginLeft:'10px' }}>Products <div hidden={!menu1} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                <Nav.Link onClick={()=>{cleanMenuAll(); setmenu2(!menu2)}} style={{ marginRight:'10px', marginLeft:'10px' }}>Services <div hidden={!menu2} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                <Nav.Link onClick={()=>{window.open('https://speed-computing-1656.my.site.com/connect/s/','_blank');cleanMenuAll()}} style={{ marginRight:'10px', marginLeft:'10px' }}>Partners <div hidden={!menu3} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                <Nav.Link onClick={()=>{cleanMenuAll(); setmenu4(!menu4)}} style={{ marginRight:'10px', marginLeft:'10px' }}>Resources<div hidden={!menu4} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                <Nav.Link onClick={()=>{cleanMenuAll(); setmenu5(!menu5)}} style={{ marginRight:'10px', marginLeft:'10px' }}>Company  <div hidden={!menu5} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                </Nav>
                <button 
                    style={{ padding:'0px', width:'350px', height:'30px' }}
                    className='custom-button-green textBold'
                    onClick={()=>{history.push('/login')}}
                >
                        Sign in with your AVAP ID
                </button>
            </Container>

{ menu1 &&
            <><div style={{ 
                width: '80%',
                 marginLeft: '10%', 
                 marginRight: 'auto', 
                 marginTop:'52px', 
                 position: 'absolute', 
                 height:'2px', 
                 backgroundColor:'#ebebeb'
                }}></div>
                <div className='fade-in-image'  style={{
                        marginTop: '374px',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        position: 'absolute',
                        width: '80%',
                        backgroundColor: 'white',
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                    }}>


                        <div style={{
                            width: '100%',
                            display: 'flex'
                        }}>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Build Virtual APIs
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>

                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}onClick={()=>{history.push('/avap')}}>
                                    AVAP (Advanced Virtual API Programming)
                                </div>
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP API Extensions for Microsoft Visual Code
                                </div>
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}onClick={()=>{history.push('/devstudio')}}>
                                    AVAP Dev Studio
                                </div>



                            </div>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Test & Deliver APIs
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>

                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP API Version Control
                                </div>
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP API Publisher
                                </div>

                            </div>


                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Run APIs
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>
{/*}
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP Virtual Server
                                </div> */}
                            {/*}    <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP Community Edition
                                </div>*/}
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}onClick={()=>{history.push('/avs')}}>
                                    AVAP Virtual Server
                                </div>
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}onClick={()=>{history.push('/avap')}}>
                                    AVAP Interpreter & Language Server
                                </div>
                            </div>



                        </div>

                        <div style={{
                            width: '100%',
                            display: 'flex'
                        }}>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Power Up APIs
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>


                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP Plug Ins
                                </div>
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer',
                                    marginBottom: '34px',
                                }}
                                
                                >
                                    AVAP Connectors
                                </div>
                                {/*}    <div style={{
                paddingLeft:'20px',
                paddingTop:'10px',
                color:'#969696',
                marginBottom:'34px',
                cursor:'pointer'
            }}>
            AVAP Virtual Machine
        </div>*/}



                            </div>
                            {/*}
                    <div style={{
                        width:'33%'
                    }}>
                        <div className='landingFont3'
                            style={{
                                paddingLeft:'20px',
                                paddingTop:'30px'
                            }}
                        >
                            Extend APIs
                        </div>
                        <div style={{
                            height:'1px',
                            backgroundColor:'#d28e22',
                            marginLeft:'20px',
                            marginRight:'20px',
                                cursor:'pointer'
                        }}>
                        </div>
    
                        <div style={{
                                paddingLeft:'20px',
                                paddingTop:'10px',
                                color:'#969696',
                                cursor:'pointer'
                            }}>
                            Plug Ins
                        </div>
                        <div style={{
                                paddingLeft:'20px',
                                paddingTop:'10px',
                                color:'#969696',
                                marginBottom:'34px',
                                cursor:'pointer'
                            }}>
                            Connectors
                        </div>
    
    
                    </div>
    
    
                */}



                        </div>


                    </div></>
}

{menu2 &&
    <><div style={{
                        width: '80%',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        marginTop: '52px',
                        position: 'absolute',
                        height: '2px',
                        backgroundColor: '#ebebeb'
                    }}></div><div className='fade-in-image' style={{
                        marginTop: '215px',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        position: 'absolute',
                        width: '80%',
                        backgroundColor: 'white',
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                    }}>


                            <div style={{
                                width: '100%',
                                display: 'flex'
                            }}>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Professional
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>

                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}
                                    onClick={()=>{history.push('/collaborative')}}
                                    >
                                        AVAP Collaborative Network
                                    </div>
                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer',
                                        marginBottom: '34px'
                                    }}>
                                        AVAP Cloud Professional
                                    </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Monolith to Microservices
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Power OpenAI Applications
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer',
            marginBottom:'35px'
        }}>
        Mobile Application API Development
    </div>
*/}


                                </div>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Business
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>

                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                        AVAP Cloud Business
                                    </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Secure and Govern APIs
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        API Gateway for Istio
    </div>
*/}
                                </div>


                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Corporate
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>

                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                        AVAP Cloud Enterprise
                                    </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Decentralized Load Balancing
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Zero Trust Security
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Observability
    </div>
    */}
                                </div>

                            </div>
                        </div></>
}

{menu3 &&

                    <><div style={{
                        width: '80%',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        marginTop: '52px',
                        position: 'absolute',
                        height: '2px',
                        backgroundColor: '#ebebeb'
                    }}></div><div className='fade-in-image' style={{
                        marginTop: '148px',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        position: 'absolute',
                        width: '80%',
                        backgroundColor: 'white',
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                    }}>


                            <div style={{
                                width: '100%',
                                display: 'flex'
                            }}>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {/*} Partner Ecosystem*/}
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        display: 'none',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>



                                </div>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {/*Find a Partner*/}
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        display: 'none',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>



                                </div>


                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {/*Become a partner*/}
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        display: 'none',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>


                                </div>
                            </div>


                            <div style={{
                                width: '100%',
                                display: 'flex'
                            }}>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {/*Partner Login*/}
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: 'transparent',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer',
                                        marginBottom: '35px'
                                    }}>
                                    </div>
                                </div>
                            </div>
                        </div></>
}

{ menu4 &&
    <><div style={{
                        width: '80%',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        marginTop: '52px',
                        position: 'absolute',
                        height: '2px',
                        backgroundColor: '#ebebeb'
                    }}></div><div className='fade-in-image' style={{
                        marginTop: '214px',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        position: 'absolute',
                        width: '80%',
                        backgroundColor: 'white',
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                    }}>


                            <div style={{
                                width: '100%',
                                display: 'flex'
                            }}>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Developer
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>

                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}
                                    onClick={()=>{history.push('/cloud_login')}}
                                    >
                                        AVAP Developer Portal.
                                    </div>
                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer',
                                        marginBottom: '34px'
                                    }}
                                    onClick={()=>{window.open('https://speed-computing-1656.my.site.com/communities/s/','_blank')}}
                                    >
                                        AVAP Communities
                                    </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Case Studies
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Blog
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Content Library
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Learning Center
    </div>

*/}

                                </div>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Knowledge Center
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>

                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}
                                    onClick={()=>{window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US')}}
                                    >
                                        AVAP Alexandria
                                    </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Gateway
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Mesh
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP AI Gateway
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Insomnia
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Plugin Hub
    </div>
*/}
                                </div>

                                {/*}
        <div style={{
            width:'33%'
        }}>
            <div className='landingFont3'
                style={{
                    paddingLeft:'20px',
                    paddingTop:'30px',
                    cursor:'pointer'
                }}
            >
                Community
            </div>
            <div style={{
                height:'1px',
                backgroundColor:'#d28e22',
                marginLeft:'20px',
                marginRight:'20px',
                    cursor:'pointer'
            }}>
            </div>
    
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                About Us
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Join the Community
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Livestreams & Events
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Champions
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Discuss
            </div>
        </div>
    
        </div>
    
        <div style={{
            width:'100%',
            display:'flex'
            }}>
    
        <div style={{
            width:'33%'
        }}>
            <div className='landingFont3'
                style={{
                    paddingLeft:'20px',
                    paddingTop:'30px',
                    cursor:'pointer'
                }}
            >
                Developer
            </div>
            <div style={{
                height:'1px',
                backgroundColor:'#d28e22',
                marginLeft:'20px',
                marginRight:'20px',
                    cursor:'pointer'
            }}>
            </div>
    
    
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Contributor Program
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer',
                    marginBottom:'34px',
                }}>
                GitHub
            </div>
    
        </div>
    
        <div style={{
            width:'33%'
        }}>
            <div className='landingFont3'
                style={{
                    paddingLeft:'20px',
                    paddingTop:'30px'
                }}
            >
                Academy
            </div>
            <div style={{
                height:'1px',
                backgroundColor:'#d28e22',
                marginLeft:'20px',
                marginRight:'20px',
                    cursor:'pointer'
            }}>
            </div>
    
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Certification
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    marginBottom:'34px',
                    cursor:'pointer'
                }}>
                Education
            </div>
        </div> */}
                            </div>

                        </div></>
}
{ menu5 &&
    <><div style={{
                        width: '80%',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        marginTop: '52px',
                        position: 'absolute',
                        height: '2px',
                        backgroundColor: '#ebebeb'
                    }}></div><div className='fade-in-image' style={{
                        marginTop: '214px',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        position: 'absolute',
                        width: '80%',
                        backgroundColor: 'white',
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                    }}>


                            <div style={{
                                width: '100%',
                                display: 'flex'
                            }}>

                                <div style={{
                                    width: '33%',
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Company
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer',
                                    }}>
                                    </div>

                                    <div onClick={()=>{history.push('/about')}} 
                                        style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                        About Us
                                    </div>
                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer',
                                        marginBottom: '34px'
                                    }} onClick={()=>{window.open('mailto: info@101obex.com','_self')}}> 
                                        Contact Us
                                    </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Press Room
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Investors
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Contact Us
    </div>
*/}

                                </div>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Legal
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>

                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                        Terms & Conditions
                                    </div>
                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                        Privacy Notice
                                    </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Documentation
    </div>
    

</div>


<div style={{
    width:'33%'
}}>
    <div className='landingFont3'
        style={{
            paddingLeft:'20px',
            paddingTop:'30px',
            cursor:'pointer'
        }}
    >
        Events
    </div>
    <div style={{
        height:'1px',
        backgroundColor:'#d28e22',
        marginLeft:'20px',
        marginRight:'20px',
            cursor:'pointer'
    }}>
    </div>

    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        API Summit by AVAP
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Upcomming Events
    </div>
    
</div>

</div>

<div style={{
    width:'100%',
    display:'flex'
    }}>

<div style={{
    width:'33%'
}}>
    

</div>

<div style={{
    width:'33%'
}}>

</div>

<div style={{
    width:'33%',
    marginTop:'-100px',
    marginBottom:'35px'
}}>
    <div className='landingFont3'
        style={{
            paddingLeft:'20px',
            paddingTop:'30px'
        }}
    >
        Security
    </div>
    <div style={{
        height:'1px',
        backgroundColor:'#d28e22',
        marginLeft:'20px',
        marginRight:'20px',
            cursor:'pointer'
    }}>
    </div>

    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Trust and Compilance
    </div>
    
    */}

                                </div>

                            </div>
                        </div></>
}

        </Navbar>



      <div onClick={()=>{cleanMenuAll()}}>

            <div onClick={()=>{cleanMenuAll()}} className="d-flex flex-column align-items-center">


                <div className='d-flex flex-column  min-vh-100 align-items-center'>

                <Image src={cabecera} style={{ marginTop:'40px' }} width="" alt="Logo" />
                <Image src={logo_framework_titulo} style={{ 
                    marginTop:'80px', 
                    position:'relative', 
                    width:'500px'
                    }} width="" alt="Logo" />

                <div
                    className='landingFont0'
                    style={{
                        marginTop:'80px', 
                        position:'relative', 
                        color:'black',
                        lineHeight:'50px'
                    }}                
                >AVAP Virtual Server, a <span>REAL solution</span> for </div>

                
                <div
                    className='landingFont0'
                    style={{
                        marginTop:'0px', 
                        position:'relative', 
                        color:'black',
                        lineHeight:'50px'
                    }}                
                >the complete API lifecycle <span className='textBold'></span> <span className='textBold'></span></div>
<div className='landingFont0' style={{ color:'black' }}></div>
                <div
                    className='landingFont3'
                    style={{
                        marginTop:'80px', 
                        position:'relative', 
                        color:'black',
                        marginLeft:'25%',
                        marginRight:'25%',
                        textAlign:'center'
                    }}                
                >Manage and publish your APIs in both development and production environments using AVS (AVAP Virtual Server). Integrated with the AVAP code repository in the cloud, AVS can manage multiple versions of each API code developed in AVAP, and conduct necessary tests for deployment and publication across diverse environments.
AVS is compatible with all language and API servers. Maximize the benefits of AVAP technology without sacrificing your existing APIs.</div>
                
                </div>
            </div>
         
            <div style={{
                backgroundColor: '#ffffff',
                height: '100%',
                textAlign:'center',
                paddingTop:'30px',
                paddingBottom:'80px'
            }}>
                {/*}
                <div className='landingFont2 textBold'>
                Ready to transform API development?
                </div>
                <div className='landingFont3b textBold'>
                Register at AVAP ID
                </div>

            
                <button 
                onClick={()=>{history.push('/Register')}}
                    className='custom-button-red textBold'
                    style={{ marginTop:'20px' }}
                >
                    Create my AVAP ID
                </button>
*/}
            </div>
            <div style={{
                backgroundColor: '#fafafa',
                height: '100%',
                textAlign:'center',
                paddingTop:'30px',
                paddingBottom:'80px'
            }}>

                {/*}
                <div 
                    className='landingFont1'
                >
                    The total API solution
                </div>
                <div 
                    className='landingFont1'
                >
                   
                </div>
                <div 
                    className='landingFont3'
                    style={{ 
                        marginTop:'20px',
                        width:'33%', 
                        marginLeft:'auto', 
                        marginRight:'auto' 
                    }}
                >
                    Next-gen technology with AI embedded in its DNA, offering scalability and profitability through its Virtuality.
                </div>

*/}
                    <div 
                        id='bloque de columnas'
                        style={{
                            width:'50%',
                            marginLeft:'auto',
                            marginRight:'auto',
                            paddingTop:'80px',
                        }}
                    >
                        <div style={{ display:'flex' }}>
                            <div style={{ width:'20%' }}>
                            <Image src={avs_1} style={{ width:'50%' }} width="" />
                            </div>
                            <div style={{ width:'80%' }}>
                            <div style={{ marginTop:'0px', textAlign: 'start' }} className='landingFont2 textBold'>High level of optimization</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>Developed using Node.js and KOA.js, AVS is a lightweight, robust, and scalable server solution.</div>
                            </div>
                        </div>
                        <div style={{ display:'flex', paddingTop:'50px' }}>
                            <div style={{ width:'20%' }}>
                            <Image src={avs_2} style={{ width:'50%' }} width="" />
                            </div>
                            <div style={{ width:'80%' }}>
                            <div style={{ marginTop:'0px', textAlign: 'start' }} className='landingFont2 textBold'>API lifecycle management</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>Automatic execution of unit tests upon publishing different code versions in development and production environments.</div>
                            </div>
                        </div>
                        <div style={{ display:'flex', paddingTop:'50px' }}>
                            <div style={{ width:'20%' }}>
                            <Image src={avs_3} style={{ width:'50%' }} width="" />
                            </div>
                            <div style={{ width:'80%' }}>
                            <div style={{ marginTop:'0px', textAlign: 'start' }} className='landingFont2 textBold'>API Exposure Management</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>AVAP Virtual Server efficiently and securely manages access and usage of APIs.</div>
                            </div>
                        </div>
                        <div style={{ display:'flex', paddingTop:'50px' }}>
                            <div style={{ width:'20%' }}>
                            <Image src={avs_4} style={{ width:'50%' }} width="" />
                            </div>
                            <div style={{ width:'80%' }}>
                            <div style={{ marginTop:'0px', textAlign: 'start' }} className='landingFont2 textBold'>Virtual Server for programmable services</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>AVS implements core services for composing complex programmable workflows. Upon startup, it receives instructions and executes them accordingly.</div>
                            </div>
                        </div>
                        <div style={{ display:'flex', paddingTop:'50px' }}>
                            <div style={{ width:'20%' }}>
                            <Image src={avs_5} style={{ width:'50%' }} width="" />
                            </div>
                            <div style={{ width:'80%' }}>
                            <div style={{ marginTop:'0px', textAlign: 'start' }} className='landingFont2 textBold'>Intelligent Gateway Management</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>Traffic redirection to ensure optimal responses based on performance history, events, and parameters defined by administrators.</div>
                            </div>
                        </div>
                        <div style={{ display:'flex', paddingTop:'50px' }}>
                            <div style={{ width:'20%' }}>
                            <Image src={avs_6} style={{ width:'50%' }} width="" />
                            </div>
                            <div style={{ width:'80%' }}>
                            <div style={{ marginTop:'0px', textAlign: 'start' }} className='landingFont2 textBold'>Universal Platform</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>In addition to the seamlessly connection to AVAP language servers, AVS is compatible with Java, Python and any other API servers.</div>
                            </div>
                        </div>

                        {/*}
                        <div 
                            style={{
                                width:'33%',
                                paddingLeft:'10px',
                                paddingRight:'10px'
                            }}>
                            <Image src={lenguaje} style={{ width:'70%' }} width="" />
                            <div style={{ marginTop:'20px' }} className='landingFont2 textBold'>Lenguaje Alto Nivel</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>Foco en las necesidades de creación de APIs, implementando procesos básicos como comandos del lenguaje y gestionando las estructuras de datos de entrada y salida como estructuras de datos nativas.</div>
                        </div>

                        <div 
                            style={{
                                width:'33%',
                                paddingLeft:'10px',
                                paddingRight:'10px'
                            }}>
                            <Image src={virtualidad} style={{ width:'70%' }} width="" />
                            <div style={{ marginTop:'20px' }} className='landingFont2 textBold'>Virtualidad</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>El atributo de virtualidad del lenguaje AVAP se da ya que sus definiciones dinámicas se alojan en la nube. Por lo que, para el desarrollo, el servidor de lenguaje únicamente lee los comandos, recupera estas definiciones y compone un Virtual Code que ejecuta la máquina virtual (parseador)</div>
                        </div>


                        <div 
                            style={{
                                width:'33%',
                                paddingLeft:'10px',
                                paddingRight:'10px'
                            }}>
                            <Image src={inteligencia} style={{ width:'70%' }} width="" />
                            <div style={{ marginTop:'20px' }} className='landingFont2 textBold'>Inteligencia</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>Al es parte de nuestro ADN, integrándose en todas las funciones y capacidades de AVAP, desde la asistencia, desarrollo y hasta el enrutamiento de servidores.</div>
                        </div>
*/}



                    </div>
                    

            </div>


            <div style={{ backgroundColor:'#ffffff', height:'100%',
                                        paddingTop:'80px',
                                        paddingBottom:'80px',
                                        textAlign:'start'
             }}>
                <div                    className='landingFont0'
                    style={{
                        marginTop:'0px', 

                        position:'relative', 
                        color:'black',
                        lineHeight:'50px',
                        textAlign:'center'
                    }}                
                >Top AVAP Virtual Server <span className='textBold'>Benefits</span></div>
                <div                    className='landingFont0'
                    style={{
                        marginTop:'0px', 

                        position:'relative', 
                        color:'black',
                        lineHeight:'50px',
                        textAlign:'center'
                    }}                
                ></div>

                    <div style={{ paddingTop:'40px', width:'100%', display:'flex'}}>

                        <div style={{ width:'50%' }}>
                            <div className='landingFont3b' style={{ marginTop:'20px', width:'50%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Optimization of infrastructure investment due to maximum performance.</div>

                            <div className='landingFont3b' style={{ marginTop:'10px', width:'50%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Management of complex infrastructures through a modular and intelligent server.</div>

                            <div className='landingFont3b' style={{ marginTop:'10px', width:'50%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Low energy consumption.</div>

                            <div className='landingFont3b' style={{ marginTop:'10px', width:'50%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Easy installation.</div>

                            <div className='landingFont3b' style={{ marginTop:'10px', width:'50%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Simplified deployment creation.</div>

                            <div className='landingFont3b' style={{ marginTop:'10px', width:'50%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Complete API lifecycle management, including publishing and maintenance.</div>

                            <div className='landingFont3b' style={{ marginTop:'10px', width:'50%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Integration and compatibility with other API servers.</div>

                            <div className='landingFont3b' style={{ marginTop:'10px', width:'50%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>High-level security.</div>

                            <div className='landingFont3b' style={{ marginTop:'10px', width:'50%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Low latency.</div>

                            <div className='landingFont3b' style={{ marginTop:'10px', width:'50%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Efficient scalability to handle sudden traffic and demand growth.</div>

    {/*<div className='landingFont3b' style={{ marginTop:'10px', width:'60%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Ensure security and control over data and API access management.</div>

    <div className='landingFont3b' style={{ marginTop:'10px', width:'60%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Access detailed documentation and educational resources to facilitate integration and use of AVAP Framework.</div>*/}
                        </div>
                    <div style={{ width:'50%' }}>
                        <div style={{marginTop:'0px', paddingLeft:'10px'}} className='landingFont2 textBold'>AVS vs Kong - Gateway response time</div>
                        <div style={{marginTop:'0px', paddingLeft:'10px', paddingRight:'20%'}} className='landingFont3b'>The test was performed by executing identical
                        API calls on both Gateways using the same database server and backend. The result shows the time between the handshake and download, providing insight into the Gateway's performance in processing API requests.</div>
                            <Image src={gafico_vscode_i} style={{ width:'80%', marginLeft:'10px', marginTop:'20px', marginRight:'auto' }} width="" />

                    </div>

            </div>

        </div>
    



            <div>
                <FooterMenuAccess green={true}/>
            </div>

        </div>
        </div>
    )
}

export default NewLandingAVS;