import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Resumen_de_las_comisiones_del_afiliado_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{
          "status": <boolean>,
          "result": {
          "commissions": [
          {
          "currency": <string>,
          "date": <string>,
          "amount_fmt": <string>,
          "amount": <decimal>
          }
          ],
          "user_count": <integer>,
          "until": <string>,
          "from": <string>,
          "total_amount": <decimal>,
          "total_amount_fmt": <string>
          },
          "elapsed": <float>
          }`,
        copied: false
      },
      answe_KO1: {
        title:"Answer ok",
        type: "json",
        code: `{
          "status": <boolean>,
          "level": <string>,
          "message": <string>,
          "error": <string>
          }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests
        url = "URL_BASE/ws/afiliados.py/resumencommissionsafiliado"
        payload = {'country_code': 'MX',
        'user_id': '4532',
        'session_id': '443-L6Cc10xJ7rMGOM2tM9-v0g==',
        'from': '2019-10-12',
        'until': '2020-06-9'}
        files = []
        headers= {}
        response = requests.request("POST", url, headers=headers, data = payload, files = files)
        print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests
          url = "URL_BASE/ws/afiliados.py/resumencommissionsafiliado"
          payload = {'country_code': 'MX',
          'user_id': '4532',
          'session_id': '443-L6Cc10xJ7rMGOM2tM9-v0g==',
          'from': '2019-10-12',
          'until': '2020-06-9'}
          files = []
          headers= {}
          response = requests.request("POST", url, headers=headers, data = payload, files = files)
          print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'POST',
              'url': 'URL_BASE/ws/afiliados.py/resumencommissionsafiliado',
              'headers': {},
              formData: {
              'country_code': 'MX',
              'user_id': '4532',
              'session_id': '443-L6Cc10xJ7rMGOM2tM9-v0g==',
              'from': '2019-10-12',
              'until': '2020-06-9'
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var formdata = new FormData();
              formdata.append("country_code", "MX");
              formdata.append("user_id", "4532");
              formdata.append("session_id", "443-L6Cc10xJ7rMGOM2tM9-v0g==");
              formdata.append("from", "2019-10-12");
              formdata.append("until", "2020-06-9");
              var requestOptions = {
              method: 'POST',
              body: formdata,
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/afiliados.py/resumencommissionsafiliado", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request POST 'URL_BASE/ws/afiliados.py/resumencommissionsafiliado' \
            --form 'country_code=MX' \
            --form 'user_id=4532' \
            --form 'session_id=443-L6Cc10xJ7rMGOM2tM9-v0g==' \
            --form 'from=2019-10-12' \
            --form 'until=2020-06-9'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="summary-of-affiliate-fees">Summary of Affiliate Fees</h1>
        <p>
          Obtaining the commissions charged by the affiliate who makes the request.
        </p>
        <p>
          <span className="label post">POST:</span>
          <span> </span>{' '}
          <code>URL_BASE + /ws/afiliados.py/resumencommissionsafiliado</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <div className='overflow-auto'>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
              <td align="center">ES &#40;Spain&#41;</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Identifier of the affiliated user making the request
              </td>
              <td align="center">N/A</td>
              <td align="center">4532</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
              <td align="center">385-XadgYoWixbzN_wqWc3Yyog==</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
              <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
            </tr>
            <tr>
              <td align="left">from</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                Operations, commissions or compensation are obtained in the report
                from the indicated date
              </td>
              <td align="center">N/A</td>
              <td align="center">2019-10-12</td>
            </tr>
            <tr>
              <td align="left">until</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                Operations, commissions or compensation are obtained in the report
                from the indicated date
              </td>
              <td align="center">N/A</td>
              <td align="center">2020-06-9</td>
            </tr>
          </tbody>
        </table>
        </div>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok-if-the-csv-is-sent">
          Answer JSON OK, if the csv is sent:
        </h3>
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        
        <h2 id="where">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;
          </li>
          <li>
            <code>result:</code> Service answer
          </li>
          <li>
            <code>commissions:</code> List of the commissions found within the
            indicated dates.
          </li>
          <li>
            <code>currency:</code> Abbreviation for the currency in which the
            commission is located.
          </li>
          <li>
            <code>date:</code> Commission charge date.
          </li>
          <li>
            <code>amount_fmt:</code> Commission amount, in currency format.
          </li>
          <li>
            <code>amount:</code> Commisssion amount without format.
          </li>
          <li>
            <code>user_count:</code> Total number of users that belong to the
            affiliate community.
          </li>
          <li>
            <code>until:</code> Date until which the commissions have been searched.
          </li>
          <li>
            <code>from:</code> Date from which the commissions have been searched.
          </li>
          <li>
            <code>total_amount:</code> Sum of all the amounts of the commissions
            found.
          </li>
          <li>
            <code>total_amount_fmt:</code> Sum of all commission amounts found using
            the currency format.
          </li>
          <li>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_KO1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li>
            <code>level:</code> Error importance level.
          </li>
          <li>
            <code>message:</code> Error message.
          </li>
          <li>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          In order to run this service it is necessary to do it from a user logged
          into the system who has an administrator or affiliate profile.
        </p>
        </div>

      <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
       
        </div>
        </div>
      </Fragment>
    );
  }
export default Resumen_de_las_comisiones_del_afiliado_EN;
