import React, { FunctionComponent, useState, useEffect } from 'react'; 
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import Logo from '../../assets/img/avap_logo.png';
import ImgVerified from '../../assets/img/escudo-verified.png';


const VerifySuccess:FunctionComponent<{username:string}> = ({ username }) => {

  return(
    <Row className="pt-5">
      <Col md="6" className="border rounded px-5 pt-3 pb-4 login-form mx-auto">
        <Row className="justify-content-center my-4">
          <Col xs="6">
            <Image src={Logo} fluid/>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="my-2 text-center px-0">
            <h1 className="mb-2">Account Verified</h1>
            <p className="mb-0">
              Welcome to AVAP ID
            </p>
            <p>
            Your user has been verified succesfully
            </p>
          </Col>
        </Row>
        <Row>
          <Col md="5" className="mx-auto">
            <Image src={ImgVerified} fluid/>
          </Col>
        </Row>
        <Row  className="mt-5">
          <Col md="6" className="mx-auto">
            <Button href="/login" className="btn-block btn-green">Login</Button> 
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default VerifySuccess;